import React, { PropsWithChildren, ReactNode, useCallback } from "react";
import { useImmer } from "use-immer";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHotkeys } from "hooks/useHotkeys";
import { Button } from "components/Button/Button";
import _set from "lodash.set";
import { Collapse } from "components/Collapse/Collapse";
import {
  StyledCollapseContent,
  StyledCollapseHeader,
  StyledContainer,
  StyledEditButton,
} from "./CollapsableEditBox.styled";
import { EditIcon } from "theme/icons/EditIcon";

interface CollapsableEditBoxProps extends PropsWithChildren {
  collapseHeader: ReactNode;
  isExpanded: boolean;
  onCollapseToggle: () => void;
  data: any;
  isEditing?: boolean;
  disabled?: boolean;
  onEdit?: () => void;
  editView: any;
  editViewOptions?: object;
  onSave: (initialData: any) => void;
  onCancel: (initialData: any) => void;
  errors: {
    [k: string]: string;
  };
}

export interface EditViewProps<T> {
  data?: T;
  handleChange?: any;
  errors?: {
    [k: string]: string;
  };
}

export const CollapsableEditBox: React.FunctionComponent<CollapsableEditBoxProps> = ({
  collapseHeader,
  isExpanded,
  onCollapseToggle,
  errors,
  isEditing,
  disabled,
  onEdit,
  editView: EditView,
  editViewOptions = {},
  children,
  onSave,
  onCancel,
  data,
}) => {
  const [editedData, setEditedData] = useImmer<any>(data);
  const [initialData] = useImmer<any>(data);

  const handleUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();

      setEditedData((oldEditedData: any) => _set(oldEditedData, e.target.name, e.target.value));
    },
    [setEditedData]
  );

  const handleCancel = useCallback(() => {
    onCancel(initialData);
    setEditedData(() => initialData);
  }, [initialData, setEditedData, onCancel]);

  const handleEscapeKey = useCallback(() => {
    if (isEditing) {
      handleCancel();
    }
  }, [isEditing, handleCancel]);

  useHotkeys("Escape", handleEscapeKey);

  const { t } = useTranslation();
  return (
    <StyledContainer disabled={disabled}>
      <Collapse
        header={<StyledCollapseHeader>{collapseHeader}</StyledCollapseHeader>}
        isExpanded={isExpanded}
        onCollapseToggle={onCollapseToggle}
      >
        <StyledCollapseContent>
          {isEditing ? (
            <>
              <EditView data={editedData} errors={errors} handleChange={handleUpdate} {...editViewOptions} />
              <Flex mt="30px">
                <Button design="secondary" ml="auto" mr="20px" onClick={handleCancel}>
                  {t("Cancel")}
                </Button>
                <Button design="primary" onClick={() => onSave(editedData)}>
                  {t("Save changes")}
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <StyledEditButton onClick={onEdit}>
                <EditIcon />
                {t("Edit")}
              </StyledEditButton>
              {children}
            </>
          )}
        </StyledCollapseContent>
      </Collapse>
    </StyledContainer>
  );
};
