import React from "react";
import { MarginOperation } from "interfaces/data";
import { formatNumberToString } from "helpers/formatNumber";

export function OperationsCell(arg: any) {
  if (!arg.value) return null;
  const operations = arg.value as MarginOperation[];

  return (
    <>
      {operations.map((operation, index) => (
        <p key={index}>
          {operation.type === "WITHDRAW" ? "-" : ""}
          {formatNumberToString(operation.value.quantity)} {operation.value.currency}
        </p>
      ))}
    </>
  );
}
