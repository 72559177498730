import React, { PropsWithChildren, useState } from "react";
import { Collapse } from "../Collapse/Collapse";
import { StyledCollapseContent } from "../CollapsableEditBox/CollapsableEditBox.styled";
import { Divider } from "@chakra-ui/react";
import styled from "@emotion/styled";

interface CollapsableSectionProps extends PropsWithChildren {
  header: string;
  defaultOpen?: boolean;
}

export const CollapsableSection: React.FC<CollapsableSectionProps> = ({ header, children, defaultOpen = false }) => {
  const [isExpanded, setIsExpanded] = useState(defaultOpen);

  return (
    <>
      <Collapse
        header={<StyledCollapseHeader>{header}</StyledCollapseHeader>}
        isExpanded={isExpanded}
        onCollapseToggle={() => setIsExpanded((prevState) => !prevState)}
      >
        <StyledCollapseContent>{children}</StyledCollapseContent>
      </Collapse>

      <Divider my={34} />
    </>
  );
};

const StyledCollapseHeader = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  color: #252527;
`;
