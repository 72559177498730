import { Payment } from "../interfaces/data";
import { UsePayments } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const usePayments = ({ phrase, fields, orderBy, orderDirection, offset, limit }: UsePayments) => {
  const {
    data: payments,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Payment>({
    collection: "payments",
    phrase,
    fields,
    orderBy,
    orderDirection,
    offset,
    limit,
  });

  return { payments, totalCount, pageCount, loading, error, refetch };
};
