import React from "react";
import { Box } from "@chakra-ui/react";

import { Button } from "components/Button/Button";
import { StyledLabel, StyledNotes, StyledNoteSrc } from "./Comment.styled";
import { useTranslation } from "react-i18next";
import { EditIcon } from "theme/icons";

export type CommentProps = {
  comment?: string;
  onEdit?: () => void;
  label?: string;
  isIcon?: boolean;
};

export const Comment: React.FC<CommentProps> = ({ comment, onEdit, label, isIcon }) => {
  const { t } = useTranslation();

  if (label) {
    return (
      <StyledNotes column onClick={onEdit}>
        <Box alignItems="center" display="flex" justifyContent="space-between" mb="20px">
          <StyledLabel>{label}</StyledLabel>
          {comment && (
            <Button border="none" design="link" height="100%" p={0}>
              <EditIcon color="sk-gray" height="15px" />
            </Button>
          )}
        </Box>
        <StyledNoteSrc>{comment ? comment : t("transaction:Add note")}</StyledNoteSrc>
      </StyledNotes>
    );
  }

  return (
    <StyledNotes onClick={onEdit}>
      {comment ? (
        comment
      ) : isIcon ? (
        <Button border="none" design="link" height="100%" p={0}>
          <EditIcon color="sk-gray" height="15px" />
        </Button>
      ) : (
        t("transaction:Add note")
      )}
      {comment && (
        <Button border="none" design="link" height="100%" p={0}>
          <EditIcon color="sk-gray" height="15px" />
        </Button>
      )}
    </StyledNotes>
  );
};
