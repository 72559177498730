import React, { PropsWithChildren, useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { Table } from "components/Table/Table";
import { Box, Flex, InputLeftElement, Text } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import { Button } from "components/Button/Button";
import { useTransactions } from "hooks/useTransactions";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { TypeFilter } from "./Filters/TypeFilter";
import { StatusFilter } from "./Filters/StatusFilter";
import { CurrencyFilter } from "./Filters/CurrencyFilter";
import { DealFilter } from "./Filters/DealFilter";
import { BoldCell, CurrencyCell, DateCell, DateCellTransactionEndDate } from "helpers/tableCells";
import {
  getQuantityLeft,
  getTransactionStatusLabel,
  hasDeadline,
  hasHedgeDeadline,
  hasRisk,
  isCompanyDeactivated,
  isInactive,
  isInitialMarginPaid,
  isNotEvenlyHedged,
  TRANSACTION_STATUSES,
  TRANSACTION_TYPES,
} from "helpers/transaction";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { MoreFilters } from "./Filters/MoreFilters";
import { AgreementDateFilter } from "./Filters/AgreementDateFilter";
import {
  BaseTransaction,
  GlobalMarginCall,
  NbpRateRecord,
  Transaction,
  TransactionStatus,
  TransactionType,
} from "interfaces/data";
import { useNbpRates } from "hooks/useNbpRates";
import { SpecialFilter } from "./Filters/SpecialFilter";
import { PageHeader } from "components/PageHeader/PageHeader";
import { PageButtons } from "components/PageButtons/PageButtons";
import theme from "theme/theme";
import { Spinner } from "components/Spinner/Spinner";
import { useGMC } from "hooks/useGMC";
import { ConvertIcon, RefreshIcon, SearchIcon, TwoArrowsIcon } from "theme/icons";

const typeFilterInitialValue = [TRANSACTION_TYPES.FIXED.value] as Array<TransactionType>;

const sortByInitialValue = {
  id: "number",
  desc: "desc",
};

const statusFilterInitialValue = [
  TRANSACTION_STATUSES.CREATED.value,
  TRANSACTION_STATUSES.PARTIALLY_SETTLED.value,
] as Array<TransactionStatus>;

export type SpecialFilterTypes = "risk" | "hedge-deadline" | "partially-hedged" | "transaction-deadline" | string;

export const formatDate = (date: string): string => {
  const [year, month, day] = date.split("-");
  return `${day}.${month}.${year}`;
};

export const StyledTypeFilterContainer = styled.div`
  margin-right: 22px;
  margin-bottom: 30px;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  padding-right: 10px;
`;

export const StyledHedgeDeadlineLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 900;
  font-size: 16px;
  white-space: nowrap;
`;

export const StyledNotSettledExpired = styled.span`
  font-size: 16px;
  color: ${(props) => props.theme.colors["sk-dark-green"]};
  font-weight: 900;
  white-space: nowrap;
`;

export const StyledPartiallyHedged = styled.div`
  background: #0340b6;
  border-radius: 30px;
  padding: 4px 11px;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;

const StyledIconsContainer = styled.div`
  padding-top: 5px;
`;

export const StyledActionLink = styled.button`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  padding: 10px 20px;
  :hover {
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
  &:disabled {
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
`;

export const StyledActionsContainer = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

export const StyledTableContainer = styled.div`
  tr.order-row {
    background: #fff9e5;
  }
  tr.order-row:hover {
    background: #fff4cc;
  }
  tr.spot-row {
    background: #eaf6ff;
  }
  tr.spot-row:hover {
    background: #d5eeff;
  }
  tr.risk-row {
    background-color: #ff3b20;
    color: white;
  }
  tr.risk-row .trans-type {
    color: white;
  }
  tr.risk-row:hover {
    background-color: #f0361c;
  }
  tr.inactive-row {
    opacity: 0.5;
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
  tr.inactive-row label {
    pointer-events: none;
  }
  tr.inactive-row:hover {
    opacity: 0.6;
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }

  td.deadline-cell {
    background-color: #feea19;
    color: #252527;
  }
`;

export const getTransactionRowClassNames = async (
  transaction: Transaction,
  rates: NbpRateRecord,
  globalMarginCalls?: Promise<GlobalMarginCall[]>
) => {
  const classNames = [];

  if (hasRisk(transaction, rates, await globalMarginCalls)) {
    classNames.push("risk-row");
  }

  if (transaction.type === TRANSACTION_TYPES.ORDER.value) {
    classNames.push("order-row");
  }

  if (transaction.type === TRANSACTION_TYPES.SPOT.value) {
    classNames.push("spot-row");
  }

  if (
    [TRANSACTION_STATUSES.ROLLED.value, TRANSACTION_STATUSES.CLOSED.value, TRANSACTION_STATUSES.SETTLED.value].includes(
      transaction.status
    )
  ) {
    classNames.push("inactive-row");
  }

  return classNames.join(" ");
};

export function CompanyCell(arg: any) {
  const transaction = arg.row.original;
  if (!transaction) return "";

  const value = arg.value;

  const isSpotType = transaction.type === TRANSACTION_TYPES.SPOT.value;
  const notSettled = !isInactive(transaction);
  const currencyDateExpired = transaction.currencyDate
    ? dayjs().isAfter(dayjs(dayjs.unix(transaction.currencyDate._seconds)))
    : false;

  // Return label bolded and green if transaction is spot type
  // not settled and transaction end date has expired
  if (isSpotType && notSettled && currencyDateExpired) {
    return <StyledNotSettledExpired>{value}</StyledNotSettledExpired>;
  }

  return hasHedgeDeadline(transaction) ? (
    <StyledHedgeDeadlineLink to={`/transactions/${transaction.id}`}>{value}</StyledHedgeDeadlineLink>
  ) : (
    value
  );
}

export function IdCell(arg: any) {
  return `#${arg.value}`;
}

export const HeaderRight: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex justifyContent="flex-end" ml="auto" width="160px">
      {children}
    </Flex>
  );
};

export function TypeCell(arg: any) {
  const transaction = arg.row.original as Transaction;
  const hasIm = transaction.type === TRANSACTION_TYPES.FIXED.value && Number(transaction.initialMargin) !== 0;
  const isOrderHasIm = transaction.type === TRANSACTION_TYPES.ORDER.value && Number(transaction.initialMargin) !== 0;
  const isImPaid = isInitialMarginPaid(transaction);
  const transType: Array<string> = [];

  if (hasIm) transType.push("FR-IM");
  if (isOrderHasIm) transType.push("Order-IM");
  if (transaction.ignoreMarginCallRisk) transType.push("FR-NM");

  if (transType.length === 0) transType.push(transaction.type);

  return (
    <Box whiteSpace="nowrap">
      <Text
        className="trans-type"
        color={isImPaid ? "inherit" : theme.colors["sk-red"]}
        fontWeight={isOrderHasIm ? "900" : isImPaid ? "inherit" : "900"}
      >
        {transType.join(", ")}
      </Text>
    </Box>
  );
}

export function SettlementsCell(arg: any) {
  const transaction = arg.row.original;
  if (!transaction.from.quantity) return "";
  const value = transaction.from.quantity - getQuantityLeft(transaction);
  return (
    <Text as="div" textAlign="right">
      {formatNumberToString(value)} {arg.row.original.from.currency}
    </Text>
  );
}

export function StatusCell(arg: any) {
  const transaction = arg.row.original as BaseTransaction;
  if (!transaction.from.quantity) return "";
  const notEvenlyHedged = isNotEvenlyHedged(transaction);

  const prolongedNumber = transaction.createdFrom?.number;
  const prolongedId = transaction.createdFrom?.id;
  const isProlonged = prolongedId != null;

  const convertedNumber = transaction.convertedFrom?.number;
  const convertedId = transaction.convertedFrom?.id;
  const isConverted = convertedId != null;

  const convertedToNumber = transaction.convertedTo?.number;
  const convertedToId = transaction.convertedTo?.id;
  const isConvertedTo = convertedToId != null;

  const prolongedByNumber = transaction.prolongedBy?.number;
  const prolongedById = transaction.prolongedBy?.id;
  const isProlongedBy = prolongedById != null;

  return (
    <>
      {notEvenlyHedged ? (
        <StyledPartiallyHedged>{getTransactionStatusLabel(arg.value)}</StyledPartiallyHedged>
      ) : (
        <>{getTransactionStatusLabel(arg.value)}</>
      )}
      {(isProlonged || isConverted || isProlongedBy || isConvertedTo) && (
        <StyledIconsContainer>
          {isProlonged && (
            <StyledLink onClick={(e) => e.stopPropagation()} to={`/transactions/${prolongedId}`}>
              <RefreshIcon mr="5px" mt="-2px" />
              {prolongedNumber}
            </StyledLink>
          )}
          {isConverted && (
            <StyledLink onClick={(e) => e.stopPropagation()} to={`/transactions/${convertedId}`}>
              <ConvertIcon mr="5px" mt="-2px" />
              {convertedNumber}
            </StyledLink>
          )}
          {isConvertedTo && (
            <StyledLink onClick={(e) => e.stopPropagation()} to={`/transactions/${convertedToId}`}>
              <ConvertIcon mr="5px" mt="-2px" />
              {convertedToNumber}
            </StyledLink>
          )}
          {isProlongedBy && (
            <StyledLink onClick={(e) => e.stopPropagation()} to={`/transactions/${prolongedById}`}>
              <TwoArrowsIcon mr="5px" mt="-2px" />
              {prolongedByNumber}
            </StyledLink>
          )}
        </StyledIconsContainer>
      )}
    </>
  );
}

export function Transactions() {
  const { t } = useTranslation();
  const { rates } = useNbpRates();

  const columns = useMemo(
    () => [
      {
        Header: t("transaction:Company"),
        accessor: "company.name",
        Cell: CompanyCell,
        disableSortBy: true,
      },
      {
        Header: t("transaction:Trans type"),
        accessor: "type",
        Cell: TypeCell,
        disableSortBy: true,
      },
      {
        Header: t("transaction:ID"),
        accessor: "number",
        disableSortBy: true,
      },
      {
        Header: t("transaction:Agreement Date"),
        accessor: "agreement",
        Cell: DateCell,
        disableSortBy: true,
      },
      {
        Header: t("transaction:Client Rate"),
        accessor: "clientRate",
        Cell: (arg: any) => (
          <Text as="span" fontSize="15px" fontWeight="900">
            {formatRate(arg.value, 4)}
          </Text>
        ),
      },
      {
        Header: t("transaction:Our Rate"),
        accessor: "ourRate",
        Cell: (props: any) => <Box fontSize="9px">{formatRate(props.value, 4)}</Box>,
      },
      {
        Header: t("transaction:Type"),
        accessor: "dealType",
        Cell: BoldCell,
        disableSortBy: true,
      },
      {
        Header: () => <HeaderRight>{t("transaction:What")}</HeaderRight>,
        accessor: "from",
        Cell: (props: any) => {
          return (
            <CurrencyCell
              {...props}
              cellProps={{
                fontWeight: "900",
                textAlign: "right",
                whiteSpace: "nowrap",
                display: "block",
                fontSize: "15px",
              }}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: () => <HeaderRight>{t("transaction:For currency")}</HeaderRight>,
        accessor: "to",
        Cell: (props: any) => {
          return (
            <CurrencyCell
              {...props}
              cellProps={{
                textAlign: "right",
                whiteSpace: "nowrap",
                display: "block",
              }}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: () => <HeaderRight>{t("transaction:Settled")}</HeaderRight>,
        accessor: "settlements",
        Cell: SettlementsCell,
        disableSortBy: true,
      },
      {
        Header: t("transaction:End Date"),
        accessor: "end",
        Cell: DateCellTransactionEndDate,
        sortType: "datetime",
      },
      {
        Header: t("transaction:Status"),
        accessor: "status",
        Cell: StatusCell,
        disableSortBy: true,
      },
    ],
    [t]
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [sort, setSort] = useState(sortByInitialValue);
  const [typeFilter, setTypeFilter] = useState<Array<TransactionType>>(typeFilterInitialValue);
  const [specialFilter, setSpecialFilter] = useState<Array<SpecialFilterTypes>>([]);
  const [dealFilter, setDealFilter] = useState("");
  const [agreementDateFilter, setAgreementDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<Array<TransactionStatus>>(statusFilterInitialValue);
  const history = useHistory();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const { findAllForTransaction } = useGMC();

  const filtersOr = useMemo(() => {
    return typeFilter?.map((f) => "type:" + f);
  }, [typeFilter]);

  const filtersAnd = useMemo(() => {
    const newFiltersAnd = [];
    if (dealFilter) newFiltersAnd.push("dealType:" + dealFilter);
    if (statusFilter) newFiltersAnd.push("status:" + statusFilter.join(","));
    return newFiltersAnd;
  }, [dealFilter, statusFilter]);

  const filtersAndBetween = useMemo(() => {
    const newFiltersAndBetween = [];
    if (agreementDateFilter) newFiltersAndBetween.push("agreement:" + agreementDateFilter.split(",").map(formatDate));
    return newFiltersAndBetween;
  }, [agreementDateFilter]);

  const { transactions, loading, pageCount } = useTransactions({
    phrase: debouncedSearchQuery,
    filtersOr,
    filtersAnd,
    filtersAndBetween,
    filtersAndCustom: specialFilter,
    fields: "company.name,number",
    orderBy: sort.id,
    orderDirection: sort.desc ? "desc" : "asc",
    offset,
    limit,
  });

  const handleTableUpdate = useCallback(
    ({
      sortBy,
      pageSize,
      pageIndex,
    }: {
      sortBy: Array<{ id: string; desc: string }>;
      pageSize: number;
      pageIndex: number;
    }) => {
      if (sortBy.length) {
        setSort(sortBy[0]);
      } else {
        setSort(sortByInitialValue);
      }
      setOffset(pageIndex * pageSize);
      setLimit(pageSize);
    },
    []
  );

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const navigateToTransactionAdd = useCallback(() => {
    history.push("/transactions/add");
  }, [history]);

  if (!transactions || !rates) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("transaction:Transactions")}</Heading>
      </PageHeader>
      <PageButtons>
        <Button design="primary" onClick={navigateToTransactionAdd}>
          {t("transaction:New transaction")}
        </Button>
      </PageButtons>
      <StyledContainer>
        <StyledTableControls>
          <TypeFilter filterValue={typeFilter} onChange={setTypeFilter} />
          <SpecialFilter onChange={setSpecialFilter} specialFilters={specialFilter} />
        </StyledTableControls>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search") as string} value={searchQuery} />
          </StyledInputGroup>
          <DealFilter onChange={(newDealFilter) => setDealFilter(newDealFilter)} />
          <CurrencyFilter onChange={setSpecialFilter} specialFilters={specialFilter} />
          <StatusFilter filterValue={statusFilter} onChange={(newStatusFilter) => setStatusFilter(newStatusFilter)} />
          <MoreFilters activeFilter={agreementDateFilter} isActive={agreementDateFilter?.length !== 0}>
            <AgreementDateFilter
              agreementDateFilter={agreementDateFilter}
              defaultOpen
              header={t("transaction:Agreement date")}
              onChange={(newAgreementDateFilter) => setAgreementDateFilter(newAgreementDateFilter)}
            />
          </MoreFilters>
        </StyledTableControls>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={transactions}
            disableSortBy={false}
            getCellProps={(cellInfo) => {
              return cellInfo.column.id === "company.name" &&
                hasDeadline(cellInfo.row.original) &&
                !isCompanyDeactivated(cellInfo.row.original)
                ? { className: "deadline-cell" }
                : {};
            }}
            getRowProps={(rowInfo) => {
              const transaction = rowInfo.original as Transaction;
              const globalMarginCalls = findAllForTransaction(transaction);
              return {
                className: getTransactionRowClassNames(transaction, rates, globalMarginCalls),
                url: `/transactions/${transaction.id}`,
              };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUpdate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
}
