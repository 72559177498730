import React, { PropsWithChildren } from "react";
import {
  BoxProps,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "components/Button/Button";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export type ModalProps = PropsWithChildren & {
  isOpen?: boolean;
  onClose: () => void;
  title?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  design?: "danger" | "primary" | "secondary";
  onConfirm?: () => any;
  minWidth?: number;
  justifyButtons?: string;
  isChild?: boolean;
  bodyProps?: BoxProps;
  isDisabled?: boolean;
  backgroundColor?: string;
  closeOnOverlayClick?: boolean;
};

const StyledModalOverlay = styled(ModalOverlay)<{ isChild?: boolean }>`
  ${(props) => props.isChild && `z-index: 1400;`};
`;

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  title,
  confirmText,
  cancelText,
  design = "danger",
  onConfirm,
  minWidth = 550,
  justifyButtons = "center",
  isChild,
  bodyProps,
  isDisabled = false,
  backgroundColor = "#fff",
  closeOnOverlayClick = true,
}) => {
  const { t } = useTranslation();

  return (
    <ChakraModal closeOnOverlayClick={closeOnOverlayClick} isOpen={Boolean(isOpen)} onClose={onClose}>
      <StyledModalOverlay bg="blackAlpha.400" isChild={isChild} />
      <ModalContent borderRadius={16} minWidth={minWidth} p={0} style={{ background: backgroundColor }}>
        {title && (
          <ModalHeader fontSize={24} fontWeight="bold" pb={0} pt={30} px={30}>
            {title}
          </ModalHeader>
        )}
        <input style={{ width: 0, height: 0, opacity: 0 }}></input>
        <ModalBody padding={30} {...bodyProps}>
          {children}
        </ModalBody>

        <ModalFooter display="flex" justifyContent={justifyButtons} pb={30} pt={0} px={30}>
          <Button design="secondary" mr="10px" onClick={onClose} px="50px">
            {cancelText || t("Cancel")}
          </Button>

          <Button design={design} isDisabled={isDisabled} onClick={onConfirm} px="50px">
            {confirmText || t("Confirm")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};
