import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/Input/Input";
import { LabeledValue } from "./LabeledValue";
import { Transaction } from "interfaces/data";
import { StyledContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { calculateMarginsInPlnForRiskPanel } from "helpers/transaction";
import { Decimal } from "decimal.js";
import { CommitmentTotals } from "./CommitmentTotals";

interface ExchangeRateSimulationProps {
  transactions: Array<Transaction>;
  nbpRates: any;
}

export const StyledInfoContainer = styled.div`
  margin: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 46px;
`;

export const ExchangeRateSimulation: React.FC<ExchangeRateSimulationProps> = ({ transactions, nbpRates }) => {
  const { t } = useTranslation();

  const [eurpln, setEurpln] = useState<number>(nbpRates.rates["EUR"]);
  const [eurusd, setEurusd] = useState<number>(
    Number(new Decimal(nbpRates.rates["EUR"]).dividedBy(nbpRates.rates["USD"]).toDecimalPlaces(4))
  );
  const [usdpln, setUsdpln] = useState<number>(nbpRates.rates["USD"]);

  const simulatedData = useMemo(() => {
    if (!usdpln || !eurpln || !eurusd) return;

    const simulatedNbpRates1 = _cloneDeep(nbpRates);
    simulatedNbpRates1.rates["EUR"] = Number(eurpln);
    simulatedNbpRates1.rates["USD"] = Number(usdpln);

    const simulatedNbpRates2 = _cloneDeep(nbpRates);
    simulatedNbpRates2.rates["EUR"] = Number(eurpln) + 0.05;
    simulatedNbpRates2.rates["USD"] = Number(usdpln);

    const simulatedNbpRates3 = _cloneDeep(nbpRates);
    simulatedNbpRates3.rates["EUR"] = Number(eurpln) + 0.15;
    simulatedNbpRates3.rates["USD"] = Number(usdpln);

    const margins1 = calculateMarginsInPlnForRiskPanel(transactions, simulatedNbpRates1);
    const margins2 = calculateMarginsInPlnForRiskPanel(transactions, simulatedNbpRates2);
    const margins3 = calculateMarginsInPlnForRiskPanel(transactions, simulatedNbpRates3);

    return {
      rate1: {
        value: Number(eurpln),
        var: margins1.variableMargin,
        total: margins1.variableMargin + margins1.initialMargin,
      },
      rate2: {
        value: Number(eurpln) + 0.05,
        var: margins2.variableMargin,
        total: margins2.variableMargin + margins2.initialMargin,
      },
      rate3: {
        value: Number(eurpln) + 0.15,
        var: margins3.variableMargin,
        total: margins3.variableMargin + margins3.initialMargin,
      },
    };
  }, [usdpln, eurpln, eurusd, nbpRates, transactions]);

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("risk:Currency exchange rate simulation")}</StyledSectionName>
      </StyledSectionHeader>
      <StyledInfoContainer>
        <div>
          <LabeledValue label="EUR PLN" value={nbpRates.rates["EUR"]} />
          <Box mb="10px">
            <Input
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="eurpln"
              name="eurpln"
              onChange={(e: any) => {
                setEurpln(e.target.value);
                if (Number(e.target.value) && usdpln) {
                  setEurusd(Number(new Decimal(e.target.value).dividedBy(usdpln).toDecimalPlaces(4)));
                }
              }}
              type="number"
              value={eurpln || ""}
            />
          </Box>
          {simulatedData && (
            <>
              <Box mb="10px" mt="20px">
                <LabeledValue label={"EUR PLN"} value={simulatedData.rate1.value} />
              </Box>
              <LabeledValue label={"Var"} precistion={2} value={simulatedData.rate1.var} />
              <LabeledValue label={"Total"} precistion={2} value={simulatedData.rate1.total} />
            </>
          )}
        </div>
        <div>
          <LabeledValue label="EUR USD" value={Number(nbpRates.rates["EUR"] / nbpRates.rates["USD"])} />
          <Box mb="10px">
            <Input
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="eurusd"
              name="eurusd"
              onChange={(e: any) => {
                setEurusd(e.target.value);
                if (Number(e.target.value)) {
                  setUsdpln(Number(new Decimal(eurpln).dividedBy(e.target.value).toDecimalPlaces(4)));
                }
              }}
              type="number"
              value={eurusd || ""}
            />
          </Box>

          {simulatedData && (
            <>
              <Box mb="10px" mt="20px">
                <LabeledValue label={"EUR PLN"} value={simulatedData.rate2.value} />
              </Box>
              <LabeledValue label={"Var"} precistion={2} value={simulatedData.rate2.var} />
              <LabeledValue label={"Total"} precistion={2} value={simulatedData.rate2.total} />
            </>
          )}
        </div>
        <div>
          <LabeledValue label="USD PLN" value={nbpRates.rates["USD"]} />
          <Box mb="10px">
            <Input
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="usdpln"
              name="usdpln"
              onChange={(e: any) => {
                setUsdpln(e.target.value);
                if (Number(e.target.value) && eurpln) {
                  setEurusd(Number(new Decimal(eurpln).dividedBy(e.target.value).toDecimalPlaces(4)));
                }
              }}
              type="number"
              value={usdpln || ""}
            />
          </Box>

          {simulatedData && (
            <>
              <Box mb="10px" mt="20px">
                <LabeledValue label={"EUR PLN"} value={simulatedData.rate3.value} />
              </Box>
              <LabeledValue label={"Var"} precistion={2} value={simulatedData.rate3.var} />
              <LabeledValue label={"Total"} precistion={2} value={simulatedData.rate3.total} />
            </>
          )}
        </div>
      </StyledInfoContainer>

      <CommitmentTotals nbpRates={nbpRates} transactions={transactions} />
    </StyledContainer>
  );
};
