import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const StyledContainer = styled.section`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  margin-top: 20px;
  background: white;
  min-height: 80px;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${({ theme }) => theme.colors["sk-dark"]};
  padding: 0 30px;
`;

export const StyledTableControls = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
`;

export const StyledAddbutton = styled.button`
  text-align: center;
  color: ${(props) => props.theme.colors["sk-purple"]};
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
`;

export const StyledTableContainer = styled.div`
  tr.closed-row {
    opacity: 0.5;
  }
  tr td:last-child {
    width: 60px;
  }
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  min-height: 72px;
`;

export const StyledDeleteButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 13px;
  width: 15px;
  line-height: 110%;
  margin-top: 3px;
  svg {
    margin-top: -5px;
  }
`;

export const StyledButtonContainer = styled.div`
  text-align: right;
`;

export const StyledEditButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 13px;
  width: 15px;
  line-height: 110%;
  margin-top: 3px;
  svg {
    margin-top: -5px;
  }
`;

export const DeactivatedBox = styled(Box)`
  display: inline-flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 800;
  font-size: 11px;
  line-height: 110%;
  border-radius: 16px;
  color: white;
  background: ${(props) => props.theme.colors["sk-red"]};
  margin-left: 20px;
  position: relative;
`;

export const TwoColumnsLayout = styled.div<{
  withPaddingBottom?: boolean;
  leftEmpty?: boolean;
  skipColumnGap?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.withPaddingBottom && "10px"};
  ${(props) =>
    !props.skipColumnGap &&
    `
    grid-column-gap: 20px;
  `}
  ${(props) =>
    props.leftEmpty &&
    `
    &:before {
      content: '';
    }
  `}
`;
