import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface CompanyInfoProps {
  company: Company;
}

export function CompanyInfo({ company }: CompanyInfoProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("company:Name")} value={company.name} />
      <LabeledText label={t("company:Legal entity")} value={company.legalEntity} />
      <LabeledText label={t("company:NIP")} value={company.nip} />
      <LabeledText label={t("company:REGON")} value={company.regon} />
      <LabeledText label={t("company:KRS")} value={company.krs} />
      <LabeledText label={t("company:Phone")} value={company.phone} />
    </TwoColumnsLayout>
  );
}
