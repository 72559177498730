import { useCallback, useState } from "react";

type ValidationErrors = { [key: string]: string };

export const useValidation = (schema: any) => {
  const [errors, setErrors] = useState<any>({});

  const validate = useCallback(
    async (data: any, context?: any) => {
      errors && setErrors({});
      try {
        const isValid = await schema.validate(data, {
          abortEarly: false,
          recursive: true,
          context,
        });
        if (isValid) {
          setErrors({});
          return true;
        }
      } catch (err) {
        const validationErrors: ValidationErrors = {};

        // @ts-ignore
        err.inner.map((error: any) => {
          validationErrors[error.path] = error.type;
        });

        setErrors(validationErrors);
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schema]
  );

  const addError = useCallback((path: string, type: string) => {
    setErrors((err: any) => ({ ...err, [path]: type }));
  }, []);

  const clearErrors = useCallback(() => {
    setErrors({});
  }, []);

  return { validate, addError, clearErrors, errors };
};
