import React, { useCallback, useState } from "react";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button/Button";
import { useFirebase } from "../../../hooks/useFirebase";
import { useToast } from "../../../hooks/useToast";
import { useAuth } from "../../../hooks/useAuth";

export function PasswordReset() {
  const { t } = useTranslation();
  const { auth } = useFirebase();
  const { profile } = useAuth();
  const toast = useToast();
  const [passwordResetSent, setPasswordResetSent] = useState(false);

  const handlePasswordReset = useCallback(async () => {
    if (!profile) return;
    auth
      .sendPasswordResetEmail(profile.email)
      .then(function () {
        setPasswordResetSent(true);
        toast({
          type: "success",
          message: t("login:Password reset link sent. Check your mailbox."),
        });
      })
      .catch(function (error: Error) {
        toast({
          type: "error",
          message: t(`login:${error.message}`),
        });
      });
    setPasswordResetSent(true);
  }, [auth, profile, t, toast]);

  return (
    <TwoColumnsLayout>
      <Button design="secondary" isDisabled={passwordResetSent} onClick={handlePasswordReset}>
        {t("settings:Change password")}
      </Button>
    </TwoColumnsLayout>
  );
}
