import React, { useState } from "react";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Heading } from "components/Heading/Heading";
import { useFirebase } from "hooks/useFirebase";
import { Table } from "components/Table/Table";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { BatchActions, DbIdCell, LengthCell, Pagination } from "./components";
import { MenuItem, useTheme } from "@chakra-ui/react";
import { CompanySchema } from "schemas/company";
import { batchDelete, deleteCompany, fixCompany, saveCompany } from "./fixCompany";
import { ActionButtons } from "./components/ActionButtons";
import { ValidationModal } from "./components/ValidationModal";
import { ZodError } from "zod";
import { DataFixerNav } from "./components/DataFixerNav";
import { useValidationModal } from "./hooks/useValidationModal";

export function CompaniesDataFixer() {
  const { db } = useFirebase();
  const { colors } = useTheme();
  const { page: paramPage } = useParams<{ page?: string }>();
  const [page, setPage] = useState(Number(paramPage) || 1);
  const [ids, setIds] = useState<string[]>([]);
  const [validationData, setValidationData] = useValidationModal();
  const { token } = useAuth();
  const { data, isLoading } = useQuery(["companies", page], async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/data-fixer/companies?page=${page}&limit=50`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.ok ? response.json() : [];
  });
  const queryClient = useQueryClient();
  const history = useHistory();

  const changePage = (page: number) => {
    setPage(page);
    history.push(`/data-fixer/companies/${page}`);
  };

  const handleShowValidationModal = (e: MenuItemEvent, validationErrors: ZodError, company: any) => {
    e.stopPropagation();
    setValidationData({
      title: `Validation errors for company ${company.name}`,
      validationErrors,
      fixFunction: async () => {
        const fixedCompany = await fixCompany(company);
        await saveCompany(fixedCompany, db, true);
        await queryClient.invalidateQueries(["companies", page]);
      },
    });
  };

  const handleDelete = async (e: MenuItemEvent, company: any) => {
    e.stopPropagation();
    await deleteCompany(company, db);
    await queryClient.invalidateQueries(["companies", page]);
  };

  const columns = [
    {
      Header: "DB ID",
      accessor: "id",
      Cell: (arg: any) => DbIdCell(arg, "/companies"),
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Global Margins",
      accessor: "globalMargins",
      Cell: LengthCell,
    },
    {
      Header: "Global Margin Calls",
      accessor: "globalMarginCalls",
      Cell: LengthCell,
    },
    {
      Header: "Payment Entries",
      accessor: "paymentEntries",
      Cell: LengthCell,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (arg: any) => {
        const company = arg.row.original;
        const result = CompanySchema.safeParse(company);

        return (
          <ActionButtons>
            {!result.success && (
              <MenuItem onClick={(e) => handleShowValidationModal(e, result.error, company)}>Validate</MenuItem>
            )}
            <MenuItem onClick={(e) => handleDelete(e, company)}>Delete</MenuItem>
          </ActionButtons>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader>
        <Heading type="h1">Companies Data Fixer</Heading>
      </PageHeader>

      <DataFixerNav />

      {isLoading && <div>Loading...</div>}

      {validationData && (
        <ValidationModal
          onClose={() => setValidationData(undefined)}
          onConfirm={() => {
            validationData.fixFunction();
            setValidationData(undefined);
          }}
          title={validationData.title}
          validationErrors={validationData.validationErrors}
        />
      )}

      {data && (
        <>
          <Pagination changePage={changePage} data={data} page={page} />
          <Table
            columns={columns}
            data={data}
            getRowProps={(arg: any) => {
              const company = arg.original;
              if (!company) return {};
              const validation = CompanySchema.safeParse(company);
              const isSelected = ids.includes(company.id);
              const onClick = () =>
                setIds((ids) => (isSelected ? ids.filter((id) => id !== company.id) : [...ids, company.id]));
              if (!validation.success) {
                return {
                  style: {
                    background: isSelected ? colors.red["300"] : colors.red["200"],
                  },
                  onClick,
                };
              }
              return {
                onClick,
                style: {
                  background: isSelected ? colors.gray["100"] : "transparent",
                },
              };
            }}
          />
          <Pagination changePage={changePage} data={data} page={page} />
          <BatchActions
            deleteSelected={() => batchDelete(ids, db)}
            selectAll={() => setIds(data.map((d: any) => d.id))}
            selectNone={() => setIds([])}
          />
        </>
      )}
    </>
  );
}
