import styled from "@emotion/styled";

export const StyledDropdownButton = styled.button<{
  isActive?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 181px;
  height: 42px;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors[props.isActive ? "sk-purple" : "sk-light-gray"]};
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(236, 236, 236, 0.3);
  border-radius: 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  > label {
    margin-bottom: 20px;
  }
`;

export const StyledClearButton = styled.button`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: left;
  text-decoration: underline;
`;
