import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  white-space: nowrap;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 16px;
  padding: 4px;
  margin-right: 20px;
`;

export const StyledTag = styled.div<{
  color: "sk-red" | "sk-yellow" | "sk-light-green";
}>`
  padding: 7px 9px;
  border-radius: 16px;
  background: ${(props) => props.theme.colors[props.color]};
  color: white;
  display: inline-block;
  font-weight: 800;
  font-size: 11px;
`;

export const StyledBoldSpan = styled.span`
  font-weight: 900;
  font-size: 15px;
`;
