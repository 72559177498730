import React from "react";
import { Application } from "../../../interfaces/data";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { Select } from "../../../components/Select/Select";
import { COUNTRIES, CURRENCIES } from "../../../helpers/options";
import { Box } from "@chakra-ui/react";
import { Button } from "../../../components/Button/Button";
import { AutoCompleteSelect } from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { DeleteIcon } from "@chakra-ui/icons";

interface EditBankAccountProps extends EditViewProps<Application> {
  onAccountRemove: (index: number, { handler, data }: { handler: Function; data: Application }) => void;
}

export function EditBankAccount({ data, handleChange, errors, onAccountRemove }: EditBankAccountProps) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <>
      {data.bankAccounts?.map((account, index) => (
        <Box key={`bankAccount-${index}`} mb="30px">
          {index > 0 && (
            <Box display="flex" justifyContent="flex-end" mb="20px">
              <Button
                design="link"
                height="auto"
                minWidth="auto"
                onClick={() => onAccountRemove(index, { handler: handleChange, data })}
                px={0}
              >
                <DeleteIcon color="sk-red" />
              </Button>
            </Box>
          )}
          <TwoColumnsLayout withPaddingBottom>
            <Input
              error={errors?.[`bankAccounts[${index}].name`]}
              id={`bankAccounts[${index}].name`}
              label={t("company:Name")}
              name={`bankAccounts[${index}].name`}
              onChange={handleChange}
              type="text"
              value={account.name}
              withError={!!errors?.[`bankAccounts[${index}].name`]}
            />
            <Select
              error={errors?.[`bankAccounts[${index}].country`]}
              id={`bank-account-countries-${index}`}
              label={t("company:Country")}
              name={`bankAccounts[${index}].country`}
              onChange={handleChange}
              options={COUNTRIES}
              value={account.country}
            />
          </TwoColumnsLayout>
          <Box display="grid" gridColumnGap="40px" gridRowGap="30px" gridTemplateColumns="1fr 1fr" mb="20px">
            <Input
              error={errors?.[`bankAccounts[${index}].number`]}
              id={`bankAccounts[${index}].number`}
              label={t("company:Number")}
              name={`bankAccounts[${index}].number`}
              onChange={handleChange}
              type="text"
              value={account.number}
              withError={!!errors?.[`bankAccounts[${index}].number`]}
            />
            <Box display="grid" gridColumnGap="12px" gridTemplateColumns="1fr 1fr">
              <AutoCompleteSelect
                error={errors?.[`bankAccounts[${index}].currency`]}
                id={`bankAccounts[${index}].currency`}
                label={t("company:Currency")}
                name={`bankAccounts[${index}].currency`}
                onChange={handleChange}
                options={CURRENCIES}
                value={account.currency}
                withError={!!errors?.[`bankAccounts[${index}].currency`]}
              />
              <Input
                error={errors?.[`bankAccounts[${index}].swift`]}
                id={`bankAccounts[${index}].swift`}
                label={t("company:SWIFT/BIC")}
                name={`bankAccounts[${index}].swift`}
                onChange={handleChange}
                type="text"
                value={account.swift}
                withError={!!errors?.[`bankAccounts[${index}].swift`]}
              />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}
