import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const LetterIcon = createIcon({
  displayName: "LetterIcon",
  path: (
    <g fill="none">
      <rect
        fill="currentColor"
        fillOpacity="0.1"
        height="11"
        rx="3.5"
        stroke="currentColor"
        width="11"
        x="0.5"
        y="0.5"
      />
      <path
        d="M7.408 7.592H4.528L3.92 9H3.08L5.616 3.304H6.312L8.856 9H8.016L7.408 7.592ZM7.128 6.936L5.968 4.224L4.808 6.936H7.128Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 12 12",
});
