import React, { useCallback, useMemo, useState } from "react";
import { InputLeftElement, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button } from "components/Button/Button";
import { Heading } from "components/Heading/Heading";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { PageButtons } from "components/PageButtons/PageButtons";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Table } from "components/Table/Table";
import { BoldCell, CurrencyCell, DateCell, DateCellTransactionEndDate } from "helpers/tableCells";
import { hasDeadline, isCompanyDeactivated, TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import useDebounce from "hooks/useDebounce";
import { useNbpRates } from "hooks/useNbpRates";
import { useTransactions } from "hooks/useTransactions";
import { Transaction, TransactionStatus, TransactionType } from "interfaces/data";
import { AgreementDateFilter } from "views/Transactions/Filters/AgreementDateFilter";
import { CurrencyFilter } from "views/Transactions/Filters/CurrencyFilter";
import { DealFilter } from "views/Transactions/Filters/DealFilter";
import { MoreFilters } from "views/Transactions/Filters/MoreFilters";
import { SpecialFilter } from "views/Transactions/Filters/SpecialFilter";
import { StatusFilter } from "views/Transactions/Filters/StatusFilter";
import {
  CompanyCell,
  formatDate,
  getTransactionRowClassNames,
  HeaderRight,
  SettlementsCell,
  SpecialFilterTypes,
  StatusCell,
  StyledTableContainer,
  TypeCell,
} from "../Transactions/Transactions";
import { TypeFilter } from "views/Transactions/Filters/TypeFilter";
import { useCompany } from "hooks/useCompany";
import styled from "@emotion/styled";
import { formatRate } from "helpers/formatNumber";
import { SearchIcon } from "@chakra-ui/icons";

const typeFilterInitialValue = [
  TRANSACTION_TYPES.FIXED.value,
  TRANSACTION_TYPES.ORDER.value,
  TRANSACTION_TYPES.SPOT.value,
] as Array<TransactionType>;

export const statusFilterInitialValue = [
  TRANSACTION_STATUSES.CREATED.value,
  TRANSACTION_STATUSES.ROLLED.value,
  TRANSACTION_STATUSES.CLOSED.value,
  TRANSACTION_STATUSES.PARTIALLY_SETTLED.value,
  TRANSACTION_STATUSES.SETTLED.value,
] as Array<TransactionStatus>;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

export function CompanyTransactions() {
  const { t } = useTranslation();
  const { rates } = useNbpRates();
  const { id: companyId } = useParams<{ id: string }>();

  const { company } = useCompany({ id: companyId });

  const columns = useMemo(
    () => [
      {
        Header: t("transaction:ID"),
        accessor: "number",
        Cell: CompanyCell,
      },
      {
        Header: t("transaction:Trans type"),
        accessor: "type",
        Cell: TypeCell,
      },
      {
        Header: t("transaction:Agreement Date"),
        accessor: "agreement",
        Cell: DateCell,
      },
      {
        Header: t("transaction:Client Rate"),
        accessor: "clientRate",
        Cell: (arg: any) => (
          <Text as="span" fontSize="15px" fontWeight="900">
            {formatRate(arg.value)}
          </Text>
        ),
      },
      {
        Header: t("transaction:Our Rate"),
        accessor: "ourRate",
        Cell: (arg: any) => <Text as="span">{formatRate(arg.value)}</Text>,
      },
      {
        Header: t("transaction:Type"),
        accessor: "dealType",
        Cell: BoldCell,
      },
      {
        Header: () => <HeaderRight>{t("transaction:What")}</HeaderRight>,
        accessor: "from",
        Cell: (props: any) => {
          return (
            <CurrencyCell
              {...props}
              cellProps={{
                fontWeight: "900",
                textAlign: "right",
                whiteSpace: "nowrap",
                display: "block",
                fontSize: "15px",
              }}
            />
          );
        },
      },
      {
        Header: () => <HeaderRight>{t("transaction:For currency")}</HeaderRight>,
        accessor: "to",
        Cell: (props: any) => {
          return (
            <CurrencyCell
              {...props}
              cellProps={{
                textAlign: "right",
                whiteSpace: "nowrap",
                display: "block",
              }}
            />
          );
        },
      },
      {
        Header: () => <HeaderRight>{t("transaction:Settled")}</HeaderRight>,
        accessor: "settlements",
        Cell: SettlementsCell,
      },
      {
        Header: t("transaction:End Date"),
        accessor: "end",
        Cell: DateCellTransactionEndDate,
      },
      {
        Header: t("transaction:Status"),
        accessor: "status",
        Cell: StatusCell,
      },
    ],
    [t]
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [typeFilter, setTypeFilter] = useState<Array<TransactionType>>(typeFilterInitialValue);
  const [specialFilter, setSpecialFilter] = useState<Array<SpecialFilterTypes>>([]);
  const [dealFilter, setDealFilter] = useState("");
  const [agreementDateFilter, setAgreementDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<Array<TransactionStatus>>(statusFilterInitialValue);
  const history = useHistory();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const filtersOr = useMemo(() => {
    return typeFilter?.map((f) => "type:" + f);
  }, [typeFilter]);

  const filtersAnd = useMemo(() => {
    const newFiltersAnd = [`company.id:${companyId}`];
    if (dealFilter) newFiltersAnd.push("dealType:" + dealFilter);
    if (statusFilter) newFiltersAnd.push("status:" + statusFilter.join(","));
    return newFiltersAnd;
  }, [dealFilter, statusFilter, companyId]);

  const filtersAndBetween = useMemo(() => {
    const newFiltersAndBetween = [];
    if (agreementDateFilter) newFiltersAndBetween.push("agreement:" + agreementDateFilter.split(",").map(formatDate));
    return newFiltersAndBetween;
  }, [agreementDateFilter]);

  const { transactions, loading, pageCount } = useTransactions({
    phrase: debouncedSearchQuery,
    filtersOr,
    filtersAnd,
    filtersAndBetween,
    filtersAndCustom: specialFilter,
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc",
    offset,
    limit,
  });

  const handleTableUpdate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const navigateToTransactionAdd = useCallback(() => {
    history.push(`/companies/${companyId}/transactions/add`);
  }, [history, companyId]);

  if (!transactions || !rates || !company) {
    return null;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader>
        <Heading type="h1">
          <StyledLink to={`/companies/${companyId}`}>{company.name}</StyledLink> - transactions
        </Heading>
      </PageHeader>
      <PageButtons>
        <Button design="primary" onClick={navigateToTransactionAdd}>
          {t("transaction:New transaction")}
        </Button>
      </PageButtons>
      <StyledContainer>
        <StyledTableControls>
          <TypeFilter filterValue={typeFilter} onChange={setTypeFilter} />
          <SpecialFilter onChange={setSpecialFilter} specialFilters={specialFilter} />
        </StyledTableControls>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search") as string} value={searchQuery} />
          </StyledInputGroup>
          <DealFilter onChange={(newDealFilter) => setDealFilter(newDealFilter)} />
          <CurrencyFilter onChange={setSpecialFilter} specialFilters={specialFilter} />
          <StatusFilter filterValue={statusFilter} onChange={(newStatusFilter) => setStatusFilter(newStatusFilter)} />
          <MoreFilters isActive={agreementDateFilter?.length !== 0}>
            <AgreementDateFilter
              agreementDateFilter={agreementDateFilter}
              defaultOpen
              header={t("transaction:Agreement date")}
              onChange={(newAgreementDateFilter) => setAgreementDateFilter(newAgreementDateFilter)}
            />
          </MoreFilters>
        </StyledTableControls>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={transactions}
            getCellProps={(cellInfo) => {
              return cellInfo.column.id === "number" &&
                hasDeadline(cellInfo.row.original) &&
                !isCompanyDeactivated(cellInfo.row.original)
                ? { className: "deadline-cell" }
                : {};
            }}
            getRowProps={(rowInfo) => {
              const transaction = rowInfo.original as Transaction;
              return {
                className: getTransactionRowClassNames(transaction, rates),
                url: `/transactions/${transaction.id}`,
              };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUpdate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
}
