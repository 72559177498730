import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTransaction } from "hooks/useTransaction";
import { useTranslation } from "react-i18next";
import { SettlementFormModal } from "./SettlementFormModal/SettlementFormModal";
import { useToast } from "hooks/useToast";
import { useMail } from "hooks/useMail";
import { SETTLEMENT_MAIL } from "helpers/mails";
import { Settlement } from "../../interfaces/data";
import { SettlementSchema } from "../../schemas/settlement";

export function SettlementEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, settlementId } = useParams<{
    id: string;
    settlementId: string;
  }>();
  const { transaction, settlementUpdate, settlementErrors } = useTransaction({
    id,
  });
  const sendSettlementMail = useMail();
  const [isSending, setIsSending] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editedSettlement = useMemo(() => {
    if (transaction) {
      return transaction.settlements?.find((settlement) => settlement.id === settlementId);
    }
  }, [settlementId, transaction]);

  const handleOnSaveAction = useCallback(
    (newSettlement: Partial<Settlement>) => {
      if (!editedSettlement) return;
      setIsSending(true);
      const data = SettlementSchema.parse(newSettlement);
      settlementUpdate(data as Settlement, editedSettlement)
        .then((data) => {
          setIsSending(false);
          if (data) {
            if (transaction?.type === "FR" && sendEmail) {
              sendSettlementMail(SETTLEMENT_MAIL, data.transaction, {
                settlement: data.settlement,
                correction: "yes",
              });
            }

            toast({
              type: "success",
              message: t("settlement:Settlement has been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`settlement:${error.message}`),
          });
        });
    },
    [editedSettlement, settlementUpdate, transaction, sendEmail, toast, t, handleClose, sendSettlementMail]
  );

  if (!transaction || !editedSettlement) return null;

  return (
    <SettlementFormModal
      errors={settlementErrors}
      initialSettlement={editedSettlement}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      sendEmail={sendEmail}
      sendEmailChange={setSendEmail}
      title={t(`settlement:Edit settlement {{settlementId}} for transaction #{{transactionNumber}}`, {
        settlementId: settlementId,
        transactionNumber: transaction.number,
      })}
      transaction={transaction}
      type="edit"
    />
  );
}
