import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalMarginMoveModal } from "../../Margin/GlobalMarginMoveModal";

export function GlobalMarginMove() {
  const { id, marginId } = useParams<{
    id: string;
    marginId: string;
  }>();

  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <GlobalMarginMoveModal companyId={id} handleClose={handleClose} marginId={marginId} />;
}
