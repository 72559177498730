import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFirebase } from "hooks/useFirebase";
import { useToast } from "hooks/useToast";
import { MarginCallFormModal } from "../MarginCall/MarginCallFormModal";
import { useTransaction } from "hooks/useTransaction";
import { useMail } from "hooks/useMail";
import { MARGIN_CALL_MAIL } from "helpers/mails";
import { ZodOptionalIDMarginCall } from "schemas/marginCall";
import { MarginCall } from "interfaces/data";

export function MarginCallAdd() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { marginCallAdd, errors } = useTransaction({ skipFetching: true });
  const { timestamp } = useFirebase();
  const sendConfirmation = useMail();
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/calls/margin-calls`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveAction = useCallback(
    (newMarginCall: ZodOptionalIDMarginCall) => {
      setIsSending(true);
      marginCallAdd(newMarginCall as MarginCall)
        .then((data) => {
          setIsSending(false);
          if (data) {
            sendConfirmation(MARGIN_CALL_MAIL, data.transaction, {
              marginCall: data.marginCall,
            });
            toast({
              type: "success",
              message: t("marginCall:Margin Call has been added successfully"),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`marginCall:${error.message}`),
          });
        });
    },
    [handleClose, marginCallAdd, sendConfirmation, t, toast]
  );

  return (
    <MarginCallFormModal
      errors={errors}
      initialMarginCall={{
        callDate: timestamp(),
        transQuantity: 0,
        isClosed: "no",
      }}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("marginCall:New Margin Call")}
      type="add"
    />
  );
}
