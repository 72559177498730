import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { Box, Text } from "@chakra-ui/react";
import { Operation } from "interfaces/data";
import { StyledButton, StyledCollapsibleContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { CurrencyCell, DateCell } from "helpers/tableCells";
import { ButtonContainer } from "./Transactions/Transactions";
import styled from "@emotion/styled";
import { NoteIcon } from "theme/icons";

interface OperationsProps {
  operations?: Array<OperationExtended>;
}

export interface OperationExtended extends Operation {
  transaction?: {
    id: string;
    number: number;
  };
  comment?: string;
}

export const StyledTableContainer = styled.div`
  tr.inactive-row {
    opacity: 0.5;
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
  tr.inactive-row:hover {
    opacity: 0.6;
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
`;

function IdCell(arg: any) {
  const comment = arg.row.original.comment;
  return (
    <Box display="flex" whiteSpace="nowrap">
      {arg.value}
      {comment && (
        <Box alignItems="center" display="flex" ml="5px" title={comment}>
          <NoteIcon height="11px" />
        </Box>
      )}
    </Box>
  );
}

export const Operations: React.FC<OperationsProps> = ({ operations = [] }) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfCollapse = 3;

  const visibleOperations = useMemo(() => {
    if (isExpanded) {
      return operations;
    }
    return operations.slice(0, numberOfCollapse);
  }, [operations, isExpanded]);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("transaction:Creation date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: t("transaction:Settlement date"),
        accessor: "settlementDate",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {t("transaction:From Quantity")}
          </Text>
        ),
        accessor: "from",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {t("transaction:To Quantity")}
          </Text>
        ),
        accessor: "to",
        Cell: CurrencyCell,
      },
      {
        Header: t("transaction:Operation type"),
        accessor: "status",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <StyledCollapsibleContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("transaction:Recent operations")}</StyledSectionName>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={visibleOperations}
          getRowProps={(rowInfo) => {
            const row = rowInfo.original;
            return {
              url: row.transaction ? `/transactions/${row.transaction.id}` : undefined,
              fromPath: window.location.pathname,
            };
          }}
          paginated={false}
        />
      </StyledTableContainer>
      {operations.length > numberOfCollapse && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledCollapsibleContainer>
  );
};
