import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ConvertIcon = createIcon({
  displayName: "ConvertIcon",
  path: (
    <g fill="none">
      <path
        d="M10.1192 4.53627H0V3.57254H8.95593L6.06475 0.681356L6.7461 0L10.4598 3.71373C10.5976 3.85154 10.6391 4.05874 10.5644 4.23896C10.4897 4.41918 10.3138 4.53627 10.1192 4.53627Z"
        fill="currentColor"
      />
      <path
        d="M1.4468 6.46387H11.566V7.4276H2.61002L5.50121 10.3188L4.81985 11.0001L1.10612 7.28641C0.968308 7.1486 0.926868 6.94139 1.00156 6.76118C1.07625 6.58096 1.25213 6.46387 1.4468 6.46387Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 12 11",
});
