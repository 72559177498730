import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "components/Table/Table";
import { Box, InputLeftElement, Radio, RadioGroup, Text, useDisclosure } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { StyledInput, StyledInputGroup } from "components/ItemsListElements/ItemsListElements";
import { useCompanies } from "hooks/useCompanies";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { Company } from "interfaces/data";
import { useToast } from "hooks/useToast";
import {
  DeactivatedBox,
  StyledButtonContainer,
  StyledContainer,
  StyledEditButton,
  StyledSectionHeader,
  StyledSectionName,
  StyledTableControls,
  TwoColumnsLayout,
} from "./shared";
import { formatNumberToString } from "helpers/formatNumber";
import { useNbpRates } from "hooks/useNbpRates";
import {
  DEAL_TYPE_SELL,
  determineTransactionCurrency,
  getQuantityLeft,
  TRANSACTION_STATUSES,
} from "helpers/transaction";
import { useTransactions } from "hooks/useTransactions";
import Decimal from "decimal.js";
import { getFontColor } from "./utils";
import { Modal } from "components/Modal/Modal";
import { useScoring } from "hooks/useScoring";
import { useCompany } from "hooks/useCompany";
import styled from "@emotion/styled";
import { useCompanyScoring } from "hooks/useCompanyScoring";
import { EditIcon, SearchIcon } from "../../theme/icons";

const StyledRadioContainer = styled.div`
  display: flex;
  label {
    display: flex;
  }
`;

interface EditQuestionCellProps {
  arg: any;
  refetch: () => Promise<void>;
}

const EditQuestionCell = ({ arg, refetch }: EditQuestionCellProps) => {
  const keys = [-2, -1, 0, 1, 2];
  const company = arg.row.original as Company;
  const companyQuestions = company?.scoring?.questions;
  const { update } = useCompany({ id: company.id });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { activeQuestions } = useScoring();
  const toast = useToast();
  const [questionsScores, setQuestionsScores] = useState(
    activeQuestions.map((q) => ({
      questionId: q.id,
      score: 0,
    }))
  );

  useEffect(() => {
    setQuestionsScores(
      activeQuestions.map((q) => ({
        questionId: q.id,
        score: companyQuestions?.find((cq) => cq?.questionId === q.id)?.score || 0,
      }))
    );
  }, [activeQuestions, companyQuestions]);

  const handleClose = () => {
    onClose();
  };

  const handleEditQuestions = useCallback(
    async () => {
      const newCompany = {
        ...company,
        scoring: {
          questions: questionsScores,
        },
      };
      await update(newCompany);
      await refetch();
      toast({
        type: "success",
        message: t("scoring:Scores successfully edited."),
      });
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionsScores, update]
  );

  const handleFieldChange = (value: any, id: number) => {
    const newQuestionScore = questionsScores.map((qs) =>
      qs.questionId === id ? { questionId: id, score: Number(value) } : qs
    );
    setQuestionsScores(newQuestionScore);
  };

  const getFieldValue = (id: number) => {
    try {
      return questionsScores?.find((qs) => qs?.questionId === id)?.score || 0;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        confirmText={t("scoring:Edit")}
        design="primary"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleEditQuestions}
        title={t("scoring:Questionare")}
      >
        {activeQuestions.map((question) => (
          <TwoColumnsLayout key={question.id} withPaddingBottom>
            <Text as="span">{question.title}</Text>
            <StyledRadioContainer>
              <RadioGroup
                name={question.id.toString()}
                onChange={(e: any) => handleFieldChange(e.target.value, question.id)}
                value={getFieldValue(question.id)}
              >
                {keys.map((item) => (
                  <Radio colorScheme="purple" fontSize="13px" key={item} value={item}>
                    <Text as="span" marginLeft="3px">
                      {item}
                    </Text>
                  </Radio>
                ))}
              </RadioGroup>
            </StyledRadioContainer>
          </TwoColumnsLayout>
        ))}
      </Modal>
      <Text
        as="span"
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        <StyledButtonContainer>
          <StyledEditButton>
            <EditIcon />
          </StyledEditButton>
        </StyledButtonContainer>
      </Text>
    </>
  );
};

interface UsedLimitCellProps {
  arg: any;
  nbpRates: any;
}

const UsedLimitCell = ({ arg, nbpRates }: UsedLimitCellProps) => {
  const company = arg.row.original as Company;
  const companyCurrency = company.details.currency || "EUR";
  const { companyLimit = 0 } = useCompanyScoring(company);

  const filtersAnd = useMemo(
    () => [
      `company.id:${company.id}`,
      `status:${TRANSACTION_STATUSES.CREATED.value},${TRANSACTION_STATUSES.PARTIALLY_SETTLED.value},${TRANSACTION_STATUSES.SETTLED.value},${TRANSACTION_STATUSES.ROLLED.value},${TRANSACTION_STATUSES.CLOSED.value}`,
    ],
    [company.id]
  );

  const { transactions: allTransactions } = useTransactions({
    phrase: "",
    filtersAnd,
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 0,
    skipAutoUpdate: true,
  });

  const transactions = useMemo(() => {
    return allTransactions.filter((transaction) =>
      [TRANSACTION_STATUSES.CREATED.value, TRANSACTION_STATUSES.PARTIALLY_SETTLED.value].includes(transaction.status)
    );
  }, [allTransactions]);

  const used = useMemo(() => {
    if (!nbpRates || !transactions) return 0;
    return transactions.reduce((total, currentTransaction) => {
      if (currentTransaction.type === "Order") {
        return total;
      }

      const rate = currentTransaction.from.currency === companyCurrency ? 1 : currentTransaction.clientRate;

      const quantity =
        currentTransaction.dealType === DEAL_TYPE_SELL
          ? getQuantityLeft(currentTransaction)
          : getQuantityLeft(currentTransaction) * rate;

      const currency = determineTransactionCurrency(currentTransaction);

      const crossRate =
        currentTransaction.from.currency === companyCurrency
          ? 1
          : Number(nbpRates.rates[currency]) / Number(nbpRates.rates[companyCurrency]);

      const crossRateQuantity = quantity * crossRate;

      return total + Number(new Decimal(crossRateQuantity).toFixed(2));
    }, 0);
  }, [nbpRates, transactions, companyCurrency]);

  const availableFunds = companyLimit - used;

  const available = useMemo(() => {
    if (availableFunds <= 0) return new Decimal(0).toString();

    return new Decimal(availableFunds).dividedBy(companyLimit).times(100).toFixed(2).toString();
  }, [availableFunds, companyLimit]);

  return (
    <Text
      as="span"
      style={{
        fontWeight: 700,
        color: company.details?.nolimits ? "black" : getFontColor(100 - parseInt(available), false),
      }}
    >
      {company.details?.nolimits
        ? "No limits"
        : `${formatNumberToString(availableFunds.toFixed(0) || 0)} ${companyCurrency}`}
    </Text>
  );
};

const NameCell = (arg: any) => {
  const { t } = useTranslation();
  const company = arg.row.original as Company;
  return (
    <Box alignItems="center" display="flex">
      <Text as="span" fontSize="15px" fontWeight="900">
        <Link to={`/companies/${company.id}`}>{arg.value}</Link>
      </Text>
      {company.isDeactivated && <DeactivatedBox>{t("company:deactivated")}</DeactivatedBox>}
    </Box>
  );
};

const ScoreCell = (arg: any) => {
  const company = arg.row.original as Company;
  const { companyScore } = useCompanyScoring(company);
  return <Text as="span">{companyScore}</Text>;
};

const CompanyLimitCell = (arg: any) => {
  const company = arg.row.original as Company;
  const { companyLimit } = useCompanyScoring(company);
  return (
    <Text as="span">
      {company.details?.nolimits
        ? "No limits"
        : `${formatNumberToString(companyLimit)} ${company.details.currency || "EUR"}`}
    </Text>
  );
};

export const Companies = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "modifiedDesc",
        label: t("company:Recently updated"),
        field: "modifiedAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("company:Company name (A-Z)"),
        field: "name",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("company:Company name (Z-A)"),
        field: "name",
        order: "desc",
      },
      {
        value: "createdDesc",
        label: t("company:Creation date (newest first)"),
        field: "createdAt",
        order: "desc",
      },
      {
        value: "createdAsc",
        label: t("company:Creation date (oldest first)"),
        field: "createdAt",
        order: "asc",
      },
    ],
    [t]
  );
  const [sortingOption, setSortingOption] = useState(sortingOptions[0]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const history = useHistory();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const { rates: nbpRates } = useNbpRates();
  const toast = useToast();
  const {
    companies,
    loading: companiesLoading,
    pageCount,
    refetch,
  } = useCompanies({
    phrase: debouncedSearchQuery,
    fields: "name,phone,contacts[].firstName,contacts[].lastName",
    orderBy: `isDeactivated,${sortingOption.field}`,
    orderDirection: `desc,${sortingOption.order}`,
    offset,
    limit,
    skipAutoUpdate: true,
  });

  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: NameCell,
      },
      {
        Header: t("Scoring"),
        accessor: "scoring",
        Cell: ScoreCell,
      },
      {
        Header: t("Available funds"),
        accessor: "usedLimit",
        Cell: (arg: any) => <UsedLimitCell arg={arg} nbpRates={nbpRates} />,
      },
      {
        Header: t("Limit"),
        accessor: "details.limit",
        Cell: CompanyLimitCell,
      },
      {
        Header: "",
        accessor: "edit",
        id: "editAction",
        Cell: (arg: any) => <EditQuestionCell arg={arg} refetch={refetch} />,
        maxWidth: "5px",
      },
    ],
    [nbpRates, refetch, t]
  );

  useEffect(() => {
    const state = history.location.state as { restricted?: boolean };

    if (state?.restricted) {
      toast({
        message: t("You don't have access to see this page."),
        type: "error",
      });
      history.replace("/companies", {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSeachInputUdpate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleSortByUpdate = useCallback((newSelectedOption: TableSortOption) => {
    setSortingOption(newSelectedOption);
  }, []);

  return companies ? (
    <Box mt={20}>
      <StyledContainer>
        <StyledSectionHeader>
          <StyledSectionName>{t("scoring:Companies scoring")}</StyledSectionName>
        </StyledSectionHeader>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSeachInputUdpate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <TableSortSelect
            onSortByChange={handleSortByUpdate}
            options={sortingOptions}
            selectedOption={sortingOption}
          />
        </StyledTableControls>
        <Table
          columns={columns}
          data={companies}
          initialPageSize={5}
          loading={companiesLoading}
          onStateUpdate={handleTableUdpate}
          pageCount={pageCount}
          pageSizeOptions={[5, 25, 50, 100]}
        />
      </StyledContainer>
    </Box>
  ) : null;
};
