import React, { PropsWithChildren } from "react";
import {
  Avatar,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger as OrigPopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { StyledContainer, StyledLoggedInItem, StyledLoggedInPane } from "./UserAvatarWithMenu.styled";
import { useAuth } from "hooks/useAuth";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const PopoverTrigger: React.FC<PropsWithChildren> = OrigPopoverTrigger;

export const UserAvatarWithMenu: React.FC = () => {
  const { logout, profile } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();
  const fullName = profile?.name ? `${profile?.name} ${profile?.surname}` : "";

  return (
    <StyledContainer>
      <Popover placement="bottom-end">
        <PopoverTrigger>
          <StyledLoggedInPane>
            <Avatar mr="10px" name={fullName} size="sm" />
            <Box fontSize="xs-sm" fontWeight="bold">
              {fullName}
            </Box>
            <ChevronDownIcon color="sk-gray" height="20px" marginLeft="20px" width="20px" />
          </StyledLoggedInPane>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="56" zIndex={4}>
            <PopoverArrow ml="0" />
            <PopoverBody padding="0">
              <StyledLoggedInItem onClick={() => history.push("/settings")}>
                {t("Account & Settings")}
              </StyledLoggedInItem>
              <StyledLoggedInItem onClick={() => logout()}>{t("Sign Out")}</StyledLoggedInItem>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </StyledContainer>
  );
};
