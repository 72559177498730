import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { Table } from "components/Table/Table";
import { Margin } from "interfaces/data";
import {
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
  StyledSectionOption,
  StyledSectionOptions,
} from "views/Transaction/Transaction/shared.styled";
import { CurrencyCell, CurrencyCellProps, DateCell } from "helpers/tableCells";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router-dom";
import { MARGIN_TYPES } from "helpers/transaction";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { formatNumberToString } from "helpers/formatNumber";
import { AddIcon, EditIcon, RemoveIcon } from "theme/icons";
import { MinusIcon, RepeatIcon } from "@chakra-ui/icons";

const StyledType = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.colors["sk-light-gray"]};
  height: 30px;
  border-radius: 16px;
  font-weight: 800;
  font-size: 11px;
  color: #9c9c9d;
  max-width: 50px;
`;

const IdCell = (arg: any) => {
  const margin = arg.row.original as Margin;
  return (
    <Text as="span" display="block" whiteSpace="nowrap">
      {margin.id}
    </Text>
  );
};

const LeftQuantityCell: React.FC<CurrencyCellProps> = ({ value }) => {
  const currency = value ? `${formatNumberToString(value.quantity)} ${value.currency}` : "";
  return (
    <Text
      as="span"
      display="block"
      fontSize="0.9rem"
      fontWeight="bold"
      textAlign="left"
      whiteSpace="nowrap"
      width="100%"
    >
      {currency}
    </Text>
  );
};

export function TypeCell(arg: any) {
  return (
    <StyledType>
      {arg.value === MARGIN_TYPES.INITIAL.value ? MARGIN_TYPES.INITIAL.value : MARGIN_TYPES.VARIATION.value}
    </StyledType>
  );
}

interface MarginsProps {
  margins?: Array<Margin>;
  isDisabled?: boolean;
  transactionId: string;
}

export const Margins: React.FC<MarginsProps> = ({ margins = [], transactionId, isDisabled }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [selectedRow, setSelectedRow] = useState<Margin>();

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("Date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: t("Type"),
        accessor: "type",
        Cell: TypeCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("From Qty.")}
          </Text>
        ),
        accessor: "from",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("To Qty.")}
          </Text>
        ),
        accessor: "to",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Left Qty.")}
          </Text>
        ),
        accessor: "left",
        Cell: LeftQuantityCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleCancel = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margins/${selectedRow.id}/cancel`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedRow]);

  const handleEdit = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margins/${selectedRow.id}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedRow]);

  const handleMove = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margins/${selectedRow.id}/move`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedRow]);

  const handleWithdraw = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margins/${selectedRow.id}/withdraw`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedRow]);

  const handleAdd = useCallback(() => {
    if (isDisabled) return;
    history.push(`/transactions/${id}/margins/add`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDisabled]);

  return (
    <StyledContainer>
      <ActiveUserBorder collection="transactions" id={transactionId} sections="margins">
        <StyledSectionHeader>
          <StyledSectionName>{t("transaction:Margins")}</StyledSectionName>
          <RestrictAccess area={ADD_MARGIN_CALLS}>
            <StyledSectionOptions>
              <StyledSectionOption isActive={!!selectedRow} onClick={handleCancel}>
                <RemoveIcon mr="10px" /> {t("Cancel")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow} onClick={handleEdit}>
                <EditIcon mr="10px" /> {t("Edit")}
              </StyledSectionOption>
              <StyledSectionOption isActive={margins.length > 0 && !isDisabled && !!selectedRow} onClick={handleMove}>
                <RepeatIcon mr="10px" /> {t("Move")}
              </StyledSectionOption>
              <StyledSectionOption
                isActive={margins.length > 0 && !isDisabled && !!selectedRow}
                onClick={handleWithdraw}
              >
                <MinusIcon mr="10px" /> {t("Withdraw")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!isDisabled} onClick={handleAdd}>
                <AddIcon mr="10px" /> {t("margin:Add")}
              </StyledSectionOption>
            </StyledSectionOptions>
          </RestrictAccess>
        </StyledSectionHeader>
        <Table
          columns={columns}
          data={margins}
          onRowSelected={setSelectedRow}
          paginated={false}
          selectableRows={!isDisabled}
        />
      </ActiveUserBorder>
    </StyledContainer>
  );
};
