import { useEffect } from "react";

export const KeyboardFocusDetector = () => {
  const handleFirstTab = (e: KeyboardEvent): void => {
    // TAB key
    if (e.keyCode === 9) {
      document.body.classList.add("has-keyboard-focus");
      window.removeEventListener("keydown", handleFirstTab);
      window.addEventListener("mousedown", handleMouseDownOnce);
    }
  };

  const handleMouseDownOnce = (): void => {
    document.body.classList.remove("has-keyboard-focus");
    window.removeEventListener("mousedown", handleMouseDownOnce);
    window.addEventListener("keydown", handleFirstTab);
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);
    return () => {
      document.body.classList.remove("has-keyboard-focus");
      window.removeEventListener("keydown", handleFirstTab);
      window.removeEventListener("mousedown", handleMouseDownOnce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
