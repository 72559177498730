import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { Table } from "components/Table/Table";
import { DateCell, QuantityCell } from "helpers/tableCells";
import { formatNumberToString } from "helpers/formatNumber";
import { CommissionFee, Contact, Payment, Transaction } from "interfaces/data";
import { useNbpRates } from "hooks/useNbpRates";
import { useCommissionFeeSources } from "hooks/useCommissionFeeSources";
import { StyledSearchContainer } from "../CommissionFees";
import { Input } from "components/Input/Input";
import { SearchBox } from "components/SearchBox/SearchBox";
import { calculateProfit } from "helpers/payments";
import { isCommissionExpired } from "helpers/commissionFee";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useAuth } from "hooks/useAuth";
import { Spinner } from "components/Spinner/Spinner";
import { calculateQuantitiesTotal } from "helpers/360view";
import { SourceIcon } from "theme/icons";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

export const StyledEmptyContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const formatDate = (date: string): string | undefined => {
  if (date === "") return;
  const [year, month, day] = date.split("-");
  return `${day}.${month}.${year}`;
};

const CompanyCell = (arg: any) => {
  return <b>{arg.value}</b>;
};

const NumberCell = (arg: any) => {
  const transaction = arg.row.original.transaction as Transaction;
  return (
    <StyledLink to={`/transactions/${transaction.id}`}>
      <Text whiteSpace="nowrap">{arg.value}</Text>
    </StyledLink>
  );
};

const SettlementTotalFooterCell = (arg: any) => {
  const sources = arg.rows.map((row: any) => row.original);

  if (!sources.length) return "";

  const totalsPerCurrency = calculateQuantitiesTotal(sources);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      whiteSpace="nowrap"
      width="100%"
    >
      <Box alignItems="flex-end" display="flex" flexDirection="column" whiteSpace="nowrap">
        {totalsPerCurrency?.length
          ? totalsPerCurrency.map((currencyPair) => (
              <Box key={currencyPair.currency}>
                {formatNumberToString(currencyPair.total, 2)} {currencyPair.currency}
              </Box>
            ))
          : ""}
      </Box>
    </Box>
  );
};

export const SourcesTab = () => {
  const { t } = useTranslation();
  const { rates: nbpRates } = useNbpRates();
  const { profile } = useAuth();

  const calculateEarnValue = useCallback(
    (arg: any) => {
      const commissionFee = arg.row.original.commissionFee as CommissionFee;
      const payment = arg.row.original as Payment;
      if (!commissionFee || !commissionFee.fee) {
        return 0;
      }

      const isExpired = isCommissionExpired(commissionFee.expiration, payment.date);

      if (isExpired) {
        return 0;
      }

      const profit = calculateProfit(payment, nbpRates);
      const fee = Number(commissionFee.fee);
      return profit && fee ? Number((profit * fee) / 100) : 0;
    },
    [nbpRates]
  );

  const EarnValueCell = useCallback(
    (arg: any) => {
      const earnValue = calculateEarnValue(arg);
      return (
        <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
          {earnValue ? formatNumberToString(earnValue) : "-"}
        </Text>
      );
    },
    [calculateEarnValue]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("commissionFee:Company"),
        accessor: "transaction.company.name",
        Cell: CompanyCell,
        Footer: t("Total"),
      },
      {
        Header: t("commissionFee:Trans. ID"),
        accessor: "id",
        Cell: NumberCell,
      },
      {
        Header: t("commissionFee:Date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: t("commissionFee:Type"),
        accessor: "transaction.dealType",
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("commissionFee:Settlement quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg: any) => QuantityCell(arg, false, true),
        Footer: SettlementTotalFooterCell,
      },
      {
        Header: t("commissionFee:Settlement currency"),
        accessor: "transaction.from.currency",
        Cell: (arg: any) => (
          <Text as="span" fontWeight="900">
            {arg.value}
          </Text>
        ),
      },
      {
        Header: t("commissionFee:Fwd. points"),
        accessor: "fwdPoints",
        Cell: (arg) => formatNumberToString(arg.value, 4),
      },
      {
        Header: t("commissionFee:Rollback"),
        accessor: "rollback",
        Cell: (arg) => formatNumberToString(arg.value, 4),
      },
      {
        Header: t("commissionFee:Points"),
        accessor: "points",
        Cell: (arg) => formatNumberToString(arg.value, 4),
      },
      {
        Header: t("commissionFee:Commission fee"),
        accessor: "commissionFee",
        Cell: (arg) =>
          !isCommissionExpired(arg.value?.expiration, arg.row.original.date) && arg.value?.fee
            ? `${formatNumberToString(arg.value?.fee)}%`
            : "-",
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("commissionFee:Earn value")}
          </Text>
        ),
        accessor: "earnValue",
        Cell: EarnValueCell,
        Footer: (arg: any) => {
          const total = React.useMemo(
            () => arg.rows.reduce((sum: number, row: any) => calculateEarnValue({ row }) + sum, 0),
            [arg.rows]
          );
          return (
            <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
              {formatNumberToString(total, 2)} PLN
            </Text>
          );
        },
      },
    ],
    [t, EarnValueCell, calculateEarnValue]
  );

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedSource, setSelectedSource] = useState<
    Pick<Contact, "id" | "firstName" | "lastName"> & {
      hasLimitedAccess?: boolean;
    }
  >();

  const filtersAndBetween = useMemo(() => {
    let _startDate = formatDate(startDate);
    let _endDate = formatDate(endDate);
    const yearsMarginNumber = 100;
    if (startDate === "" && endDate === "") {
      return [];
    }
    if (startDate === "") {
      _startDate = dayjs(new Date()).subtract(yearsMarginNumber, "year").format("DD.MM.YYYY");
    }
    if (endDate === "") {
      _endDate = dayjs(new Date()).add(yearsMarginNumber, "year").format("DD.MM.YYYY");
    }

    return [`date:${_startDate},${_endDate}`];
  }, [startDate, endDate]);

  const { commissionFeeSources, loading } = useCommissionFeeSources({
    phrase: "",
    fields: "transaction.company.name",
    orderBy: "date",
    orderDirection: "desc",
    extraOptions: selectedSource ? `contactId=${selectedSource?.id}` : "",
    filtersAndBetween,
    offset: 0,
    limit: 1000000,
  });

  useEffect(() => {
    if (profile?.contactId) {
      setSelectedSource({
        id: profile?.contactId,
        ...profile?.connectedWith,
        hasLimitedAccess: profile?.role !== "Admin",
      } as Pick<Contact, "id" | "firstName" | "lastName"> & { hasLimitedAccess?: boolean });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  if (!nbpRates) return null;

  if (loading) return <Spinner />;

  return (
    <Box mb="-14px">
      <StyledTableControls>
        <StyledSearchContainer>
          {selectedSource?.hasLimitedAccess ? (
            <Input
              isDisabled
              value={selectedSource.id ? `${selectedSource.firstName} ${selectedSource.lastName}` : ""}
            />
          ) : (
            <SearchBox
              collection="sources"
              fields="firstName,lastName,email"
              item={(contact: Contact) => `${contact.firstName} ${contact.lastName} (${contact.email})`}
              onSelect={(contact) => setSelectedSource(contact)}
              orderBy="firstName"
              persist={(contact: Contact) => `${contact.firstName} ${contact.lastName}`}
              placeholder="Search"
            />
          )}
        </StyledSearchContainer>
        <Box alignItems="center" display="flex" justifyContent="space-between" ml="30px" mt="30px">
          <Input
            id="startDate"
            label={t("transaction:From")}
            name="startDate"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setStartDate(e.target.value);
            }}
            topLabel
            type="date"
            value={startDate}
            w={200}
          />
          <Box ml="30px">
            <Input
              id="endDate"
              label={t("transaction:To")}
              name="endDate"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEndDate(e.target.value);
              }}
              topLabel
              type="date"
              value={endDate}
              w={200}
            />
          </Box>
        </Box>
      </StyledTableControls>
      {commissionFeeSources ? (
        <Table
          columns={columns}
          data={commissionFeeSources}
          displayFooter={commissionFeeSources.length > 0}
          loading={loading}
          paginated={false}
        />
      ) : (
        <>
          {selectedSource ? (
            <Spinner />
          ) : (
            <StyledEmptyContainer>
              <SourceIcon height="64px" width="64px" />
              <Box>{t("commissionFee:Your account isn't source at any company")}</Box>
            </StyledEmptyContainer>
          )}
        </>
      )}
    </Box>
  );
};
