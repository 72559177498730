import React from "react";
import { BaseContact } from "../../../interfaces/data";
import { Tag } from "../../../components/Tag/Tag";
import { useTranslation } from "react-i18next";

interface ConfirmationTagProps {
  contact: BaseContact;
}

export function ConfirmationTag({ contact }: ConfirmationTagProps) {
  const { t } = useTranslation();
  return (
    <Tag
      color={contact.confirmed ? "green" : "grey"}
      content={contact.confirmed ? t("contact:confirmed") : t("contact:not confirmed")}
      ml="auto"
      mr="20px"
    />
  );
}
