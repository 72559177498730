import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Box, Divider } from "@chakra-ui/react";

export const StyledContainer = styled.section`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  margin-top: 20px;
  background: white;
  min-height: 80px;
`;

export const StyledCollapsibleContainer = styled(StyledContainer)`
  max-height: 590px;
  overflow-y: auto;
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  min-height: 72px;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
  padding: 0 30px;
`;

export const StyledSectionOptions = styled.div`
  display: flex;
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
`;

export const SectionTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 20px;
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
  text-decoration: underline;
`;

export const Section: React.FC<PropsWithChildren> = ({ children }) => (
  <Box mt={30}>
    {children}
    <Divider my={30} />
  </Box>
);
