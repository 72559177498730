import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import { EditComment } from "views/Transaction/Comment/EditComment";
import { useTransaction } from "hooks/useTransaction";
import { DashboardType } from "interfaces/data";
import { EditIcon } from "theme/icons";

interface CommentProps {
  transactionId: string;
  comment?: string;
  type: DashboardType;
}

const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledContainer = styled.div<{ minWidth: string }>`
  position: relative;
  padding-right: 20px;
  width: 100%;
  min-width: ${(props) => props.minWidth};
  max-width: 400px;
  min-height: 15px;
  ${StyledButton} {
    opacity: 0;
  }
  &:hover ${StyledButton} {
    opacity: 1;
  }
`;

const Container = styled.div<{ minWidth: string }>`
  position: relative;
  top: -80px;
  right: 15px;
  min-width: ${(props) => props.minWidth};
  width: calc(100% + 35px);

  > div {
    @media (min-width: 1400px) {
      left: 0;
      width: 400px;
      transform: translateX(-50%);
    }
    @media (min-width: 1700px) {
      left: initial;
      transform: translateX(0);
    }

    @media (min-width: 1900px) {
      width: 600px;
    }
  }
`;

export const EditableComment = ({ transactionId, comment, type }: CommentProps) => {
  const [commentText, setCommentText] = useState(comment);
  const { dashboardCommentsUpdate } = useTransaction({
    id: transactionId,
  });

  const handleCommentUpdate = useCallback(
    async (commentText: string) => {
      await dashboardCommentsUpdate(commentText, type);
      setCommentText(commentText);
      setIsEdited(false);
    },
    [dashboardCommentsUpdate, type]
  );

  const [isEdited, setIsEdited] = useState(false);

  return (
    <>
      {isEdited && (
        <Container minWidth="200px">
          <EditComment
            alignToRight
            asBox={false}
            comment={commentText}
            onEdit={handleCommentUpdate}
            onEditClose={() => setIsEdited(false)}
          />
        </Container>
      )}
      <StyledContainer minWidth="200px" onClick={() => setIsEdited(!isEdited)}>
        {commentText || comment || "-"}
        <StyledButton>
          <EditIcon ml="10px" mt="-2px" />
        </StyledButton>
      </StyledContainer>
    </>
  );
};
