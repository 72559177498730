import { Currency, NbpRateRecord } from "interfaces/data";
import { firebase } from "../Firebase";
import { RatesSchema } from "schemas/rates";

const COLLECTION = "rates";
export function getCrossRate(fromCurrency: Currency, toCurrency: Currency, nbpRates?: NbpRateRecord) {
  if (nbpRates) {
    return Number(Number(nbpRates.rates[fromCurrency]) / Number(nbpRates.rates[toCurrency]));
  }

  return 1;
}

export const getCurrentRates = async () => {
  const { db } = firebase;
  const snapshot = await db.collection(COLLECTION).orderBy("effectiveDate", "desc").limit(1).get();
  const [docRef] = snapshot.docs;
  const rates = docRef.data();
  rates.rates.PLN = 1;

  return RatesSchema.parse(rates);
};

export const getRates = async (date: string) => {
  const { db } = firebase;
  const snapshot = await db
    .collection(COLLECTION)
    .where("effectiveDate", "<=", date)
    .orderBy("effectiveDate", "desc")
    .limit(1)
    .get();
  const [docRef] = snapshot.docs;
  const rates = docRef.data();
  rates.rates.PLN = 1;

  return RatesSchema.parse(rates);
};
