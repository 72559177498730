import React from "react";
import { PageHeader as BasePageHeader } from "components/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const StyledList = styled.ul`\
  display: flex;
  gap: 2rem;
`;

const StyledListElement = styled.li<{ active?: boolean }>`
  padding: 0.5rem 0;
  list-style: none;

  &:hover {
    background-color: ${(props) => props.theme.colors["sk-lighter-gray"]};
  }
  border-bottom: 3px solid ${(props) => (props.active ? props.theme.colors["sk-blue"] : "transparent")};
`;

const Active = styled.span`
  font-weight: bold;
`;

export const PageHeader: React.FC<{ title: "Margin Calls" | "Global Margin Calls" }> = ({ title }) => {
  const { t } = useTranslation();
  const isMarginCalls = title === "Margin Calls";

  return (
    <BasePageHeader>
      <nav>
        <StyledList>
          <StyledListElement active={isMarginCalls}>
            {isMarginCalls ? (
              <Active>{t("company:Margin Calls")}</Active>
            ) : (
              <Link to="/calls/margin-calls">{t("company:Margin Calls")}</Link>
            )}
          </StyledListElement>
          <StyledListElement active={!isMarginCalls}>
            {!isMarginCalls ? (
              <>{t("company:Global Margin Calls")}</>
            ) : (
              <Link to="/calls/global-margin-calls">{t("company:Global Margin Calls")}</Link>
            )}
          </StyledListElement>
        </StyledList>
      </nav>
    </BasePageHeader>
  );
};
