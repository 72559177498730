import React from "react";
import { RiskPanel } from "views/RiskPanel/RiskPanel";
import NotFound from "views/NotFound/NotFound";
import { Companies } from "views/Companies/Companies";
import { Company } from "views/Company/Company";
import { CompanyAdd } from "views/CompanyAdd/CompanyAdd";
import { Redirect } from "react-router-dom";
import { Contacts } from "views/Contacts/Contacts";
import { Contact } from "views/Contact/Contact";
import { ContactAdd } from "views/ContactAdd/ContactAdd";
import { Applications } from "views/Applications/Applications";
import { Application } from "views/Application/Application";
import { Transactions } from "views/Transactions/Transactions";
import { Transaction } from "views/Transaction/Transaction/Transaction";
import { TransactionAdd } from "views/Transaction/TransactionAdd/TransactionAdd";
import { TransactionEdit } from "views/Transaction/TransactionEdit/TransactionEdit";
import { TransactionRoll } from "views/Transaction/TransactionRoll/TransactionRoll";
import { TransactionConvert } from "views/Transaction/TransactionConvert/TransactionConvert";
import { TransactionCancel } from "views/Transaction/TransactionCancel/TransactionCancel";
import { MarginAdd } from "views/Margin/MarginAdd";
import { MarginEdit } from "views/Margin/MarginEdit";
import { MarginWithdraw } from "views/Margin/MarginWithdraw";
import { MarginCancel } from "views/Margin/MarginCancel";
import { HedgeAdd } from "views/Hedge/HedgeAdd";
import { HedgeEdit } from "views/Hedge/HedgeEdit";
import { HedgeCancel } from "views/Hedge/HedgeCancel";
import { HedgeRoll } from "views/Hedge/HedgeRoll";
import { MarginCallAdd as MarginCallTransactionAdd } from "views/MarginCall/MarginCallAdd";
import { MarginCallEdit } from "views/MarginCall/MarginCallEdit";
import { MarginCallAdditionalEdit } from "views/MarginCall/MarginCallAdditionalEdit";
import { MarginCallCancel } from "views/MarginCall/MarginCallCancel";
import { GlobalMarginCallClose } from "views/Company360/Main/GlobalMarginCallCloseModal";
import { MarginCallResend } from "views/MarginCall/MarginCallResend";
import { MarginCallAdditionalAdd } from "views/MarginCall/MarginCallAdditionalAdd";
import { SettlementAdd } from "views/Settlement/SettlementAdd";
import { SettlementEdit } from "views/Settlement/SettlementEdit";
import { SettlementEditHedges } from "views/Settlement/SettlementEditHedges";
import { SettlementCancel } from "views/Settlement/SettlementCancel";
import { SettlementResend } from "views/Settlement/SettlementResend";
import { Company360 } from "views/Company360/Company360";
import { MarginCalls } from "views/MarginCalls/MarginCalls";
import { MarginCallsClose } from "views/MarginCalls/MarginCallsClose";
import { Payments } from "views/Payments/Payments";
import { CommissionFees } from "views/CommissionFees/CommissionFees";
import { MarginCallAdd } from "views/MarginCallAdd/MarginCallAdd";
import { CompanyTransactionAdd } from "views/Company360/Main/Transactions/CompanyTransactionAdd";
import { CompanyOrderAdd } from "views/Company360/Main/Transactions/CompanyOrderAdd";
import { Reports } from "views/Reports/Reports";
import { Settings } from "views/Settings/Settings";
import { CompanyDeactivate } from "views/Company/CompanyDeactivate";
import { CompanyActivate } from "views/Company/CompanyActivate";
import { CompanyDelete } from "views/Company/CompanyDelete";
import {
  ACTIVATE_COMPANY_GUARD,
  ADD_COMPANY_GUARD,
  ADD_MARGIN_CALLS,
  ADD_TRANSACTION,
  ADD_USERS,
  APPROVE_APPLICATION_GUARD,
  DELETE_COMPANY_GUARD,
  RISK_GUARD,
  RoleAreas,
  SHOW_OPERATIONS,
  SHOW_OWN_COMMISSION_FEE,
  SHOW_REPORTS,
  SHOW_SCORING,
} from "helpers/userRoles";
import { Dashboard } from "views/Dashboard/Dashboard";
import { CompanyMarginCallAdd } from "views/Company360/Main/CompanyMarginCallAdd";
import ConfirmResetPassword from "views/ResetPassword/ConfirmResetPassword";
import { GlobalMarginAdd } from "views/Company360/Main/GlobalMarginAdd";
import { MarginWithdrawLocal } from "views/Company360/Main/MarginWithdrawLocal";
import { MarginWithdrawGlobal } from "views/Company360/Main/MarginWithdrawGlobal";
import { MarginEditGlobal } from "views/Company360/Main/MarginEditGlobal";
import { MarginEditLocal } from "views/Company360/Main/MarginEditLocal";
import { MarginCancelGlobal } from "views/Company360/Main/MarginCancelGlobal";
import { MarginCancelLocal } from "views/Company360/Main/MarginCancelLocal";
import { MarginCall360MarginAdd } from "views/Company360/Main/MarginCall360MarginAdd";
import { MarginCall360MarginWithdraw } from "views/Company360/Main/MarginCall360MarginWithdraw";
import { MarginCallMarginAdd } from "views/Transaction/Transaction/MarginCallMarginAdd";
import { MarginCallMarginWithdraw } from "views/Transaction/Transaction/MarginCallMarginWithdraw";
import { MarginCallTableMarginAdd } from "views/MarginCalls/MarginCallTableMarginAdd";
import { MarginCallTableMarginWithdraw } from "views/MarginCalls/MarginCallTableMarginWithdraw";
import { CompanyTransactions } from "views/CompanyTransactions/CompanyTransactions";
import { MarginMove } from "views/Margin/MarginMove";
import { MarginMoveLocal } from "views/Company360/Main/MarginMoveLocal";
import { GlobalMarginMove } from "views/Company360/Main/GlobalMarginMove";
import { GlobalMarginMerge } from "views/Company360/Main/GlobalMarginMerge";
import Scoring from "views/Scoring/Scoring";
import { Operations } from "views/Operations/Operations";
import { CompanyAddGmc } from "views/Company360/Main/CompanyAddGmc/CompanyAddGmc";
import { MassHedgeRoll } from "views/Hedge/MassHedgeRoll/MassHedgeRoll";
import { GlobalMarginCall360MarginAdd } from "views/Company360/Main/GlobalMarginCall360MarginAdd";
import { GlobalMarginCall360MarginWithdraw } from "views/Company360/Main/GlobalMarginCall360MarginWithdraw";
import { MarginCallFinalAdd } from "views/MarginCall/MarginCallFinalAdd";
import { GlobalMarginCallFinalAdd } from "views/Company360/Main/GlobalMarginCallFinalAdd";
import { GlobalMarginCallView } from "views/Company360/Main/GlobalMarginCallView";
import { GlobalMarginsMigration } from "views/GlobalMargins/GlobalMarginsMigration";
import { TransactionsDataFixer } from "views/DataFixer/TransactionsDataFixer";
import { ContactsDataFixer } from "views/DataFixer/ContactsDataFixer";
import { CompaniesDataFixer } from "views/DataFixer/CompaniesDataFixer";
import { GlobalMarginCalls } from "views/MarginCalls/GlobalMarginCalls";
import { MarginCallClose } from "views/Company360/Main/MarginCallCloseModal";

const createRoute = (
  path: string,
  component: React.FC,
  options: {
    exact?: boolean;
    parent?: React.FC;
    guard?: RoleAreas;
    redirectTo?: string;
  } = {}
) => {
  const { exact = true, parent, guard, redirectTo } = options;

  return {
    path,
    component,
    exact,
    parent,
    guard,
    redirectTo,
  };
};

const RedirectToDashboard: React.FC = () => {
  return <Redirect to="/dashboard" />;
};

const RedirectToTransactionFixer: React.FC = () => {
  return <Redirect to="/data-fixer/transactions" />;
};

const RedirectToMarginCalls: React.FC = () => {
  return <Redirect to="/calls/margin-calls" />;
};

const routes = [
  createRoute("/", RedirectToDashboard, { exact: true }),
  createRoute("/login", RedirectToDashboard, { exact: true }),
  createRoute("/dashboard", Dashboard),
  createRoute("/companies", Companies),
  createRoute("/companies/add", CompanyAdd, { guard: ADD_COMPANY_GUARD }),
  createRoute("/companies/:id", Company360),
  createRoute("/companies/:id/add-transaction", CompanyTransactionAdd, {
    parent: Company360,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/companies/:id/add-order", CompanyOrderAdd, {
    parent: Company360,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/companies/:id/transactions", CompanyTransactions, {
    guard: ADD_TRANSACTION,
  }),
  createRoute("/companies/:id/transactions/add", CompanyTransactionAdd, {
    parent: CompanyTransactions,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/companies/:id/add-margin-call", CompanyMarginCallAdd, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/global-margins/add", GlobalMarginAdd, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/add-gmc", CompanyAddGmc, {
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/gmc/:marginCallId", GlobalMarginCallView, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/gmc-final-call/:marginCallId", GlobalMarginCallFinalAdd, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/transactions/:transactionId/margin-calls/:marginCallId/add", MarginCall360MarginAdd, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute(
    "/companies/:id/transactions/:transactionId/margin-calls/:marginCallId/withdraw",
    MarginCall360MarginWithdraw,
    {
      parent: Company360,
      guard: ADD_MARGIN_CALLS,
    }
  ),
  createRoute("/companies/:id/margin-calls/:marginCallId/add", GlobalMarginCall360MarginAdd, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/margin-calls/:marginCallId/withdraw", GlobalMarginCall360MarginWithdraw, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/margin-calls/:marginCallId/close", GlobalMarginCallClose, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/transactions/:transactionId/margins/:marginId/move", MarginMoveLocal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/margins/:marginId/move", GlobalMarginMove, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/margins/:marginId/merge", GlobalMarginMerge, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/transactions/:transactionId/margins/:marginId/withdraw", MarginWithdrawLocal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/transactions/:transactionId/margins/:marginId/edit", MarginEditLocal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/transactions/:transactionId/margins/:marginId/cancel", MarginCancelLocal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/global-margins/:marginId/withdraw", MarginWithdrawGlobal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/global-margins/:marginId/edit", MarginEditGlobal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/global-margins/:marginId/cancel", MarginCancelGlobal, {
    parent: Company360,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/companies/:id/preview", Company),
  createRoute("/companies/:id/deactivate", CompanyDeactivate, {
    parent: Company,
    guard: ACTIVATE_COMPANY_GUARD,
  }),
  createRoute("/companies/:id/activate", CompanyActivate, {
    parent: Company,
    guard: ACTIVATE_COMPANY_GUARD,
  }),
  createRoute("/companies/:id/delete", CompanyDelete, {
    parent: Company,
    guard: DELETE_COMPANY_GUARD,
  }),
  createRoute("/companies/:id/edit/:section", Company, {
    guard: ADD_COMPANY_GUARD,
  }),
  createRoute("/contacts/", Contacts),
  createRoute("/contacts/add", ContactAdd, {
    guard: ADD_COMPANY_GUARD,
  }),
  createRoute("/contacts/:id", Contact),
  createRoute("/contacts/:id/edit/:section", Contact, {
    guard: ADD_COMPANY_GUARD,
  }),
  createRoute("/applications/", Applications, {
    guard: APPROVE_APPLICATION_GUARD,
  }),
  createRoute("/applications/:id", Application, {
    guard: APPROVE_APPLICATION_GUARD,
  }),
  createRoute("/applications/:id/edit/:section", Application, {
    guard: APPROVE_APPLICATION_GUARD,
  }),
  createRoute("/applications/:id/edit/:section/:contactId", Application, {
    guard: APPROVE_APPLICATION_GUARD,
  }),
  createRoute("/transactions/", Transactions, {
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/add", TransactionAdd, {
    parent: Transactions,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id", Transaction, {
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/convert", TransactionConvert, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/edit", TransactionEdit, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/roll", TransactionRoll, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/cancel", TransactionCancel, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/margins/add", MarginAdd, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margins/:marginId/withdraw", MarginWithdraw, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margins/:marginId/move", MarginMove, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margins/:marginId/edit", MarginEdit, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margins/:marginId/cancel", MarginCancel, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/add", MarginCallTransactionAdd, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/edit", MarginCallEdit, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/edit-additional", MarginCallAdditionalEdit, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/resend", () => <MarginCallResend />, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/global-margin-call/:marginCallId/resend", () => <MarginCallResend isGmc />, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/cancel", MarginCallCancel, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/close", MarginCallClose, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/final-call", MarginCallFinalAdd, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/add-additional", MarginCallAdditionalAdd, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/add", MarginCallMarginAdd, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/margin-calls/:marginCallId/withdraw", MarginCallMarginWithdraw, {
    parent: Transaction,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/transactions/:id/hedges/add", HedgeAdd, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/hedges/:hedgeId/edit", HedgeEdit, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/hedges/:hedgeId/cancel", HedgeCancel, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/hedges/:hedgesIds/roll", HedgeRoll, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/mass-hedge-roll", MassHedgeRoll, {
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/settlements/add", SettlementAdd, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/settlements/:settlementId/edit", SettlementEdit, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/settlements/:settlementId/edit-hedges", SettlementEditHedges, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/settlements/:settlementId/cancel", SettlementCancel, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/transactions/:id/settlements/:settlementId/resend", SettlementResend, {
    parent: Transaction,
    guard: ADD_TRANSACTION,
  }),
  createRoute("/calls", RedirectToMarginCalls, {
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/margin-calls", MarginCalls, {
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/global-margin-calls", GlobalMarginCalls, {
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/margin-calls/:transactionId/:marginCallId/add", MarginCallTableMarginAdd, {
    parent: MarginCalls,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/margin-calls/:transactionId/:marginCallId/withdraw", MarginCallTableMarginWithdraw, {
    parent: MarginCalls,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/margin-calls/:transactionId/:marginCallId/close", MarginCallsClose, {
    parent: MarginCalls,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/calls/margin-calls/add", MarginCallAdd, {
    parent: MarginCalls,
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/payments", Payments),
  createRoute("/commission-fees", CommissionFees, {
    guard: SHOW_OWN_COMMISSION_FEE,
  }),
  createRoute("/commission-fees/:tab", CommissionFees, {
    guard: SHOW_OWN_COMMISSION_FEE,
  }),
  createRoute("/reports", Reports, {
    guard: SHOW_REPORTS,
  }),
  createRoute("/settings", Settings),
  createRoute("/settings/:tab", Settings),
  createRoute("/reset-password/confirm", ConfirmResetPassword),
  createRoute("/risk", RiskPanel, {
    guard: RISK_GUARD,
  }),
  createRoute("/scoring", Scoring, {
    guard: SHOW_SCORING,
  }),
  createRoute("/operations", Operations, {
    guard: SHOW_OPERATIONS,
  }),
  createRoute("/global-margins-migration", GlobalMarginsMigration, {
    guard: ADD_MARGIN_CALLS,
  }),
  createRoute("/data-fixer", RedirectToTransactionFixer, {
    guard: ADD_USERS,
  }),
  createRoute("/data-fixer/transactions/:page?", TransactionsDataFixer, {
    guard: ADD_USERS,
  }),
  createRoute("/data-fixer/contacts/:page?", ContactsDataFixer, {
    guard: ADD_USERS,
  }),
  createRoute("/data-fixer/companies/:page?", CompaniesDataFixer, {
    guard: ADD_USERS,
  }),
  createRoute("*", NotFound),
];

export default routes;
