import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledTableControls } from "../../../components/ItemsListElements/ItemsListElements";
import { Table } from "../../../components/Table/Table";
import useDebounce from "../../../hooks/useDebounce";
import { DateCell } from "../../../helpers/tableCells";
import { formatNumberToString, formatRate } from "../../../helpers/formatNumber";
import { Company, Payment, Transaction } from "../../../interfaces/data";
import { useNbpRates } from "../../../hooks/useNbpRates";
import { useCommissionFeeCompanies } from "../../../hooks/useCommissionFeeCompanies";
import { StyledSearchContainer } from "../CommissionFees";
import { calculateProfit } from "../../../helpers/payments";
import { SearchBox } from "../../../components/SearchBox/SearchBox";
import { Box, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Input } from "../../../components/Input/Input";
import dayjs from "dayjs";
import { formatDate } from "../SourcesTab/SourcesTab";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

function NumberCell(arg: any) {
  const transaction = arg.row.original.transaction as Transaction;
  return (
    <StyledLink to={`/transactions/${transaction.id}`}>
      <Text whiteSpace="nowrap">{arg.value}</Text>
    </StyledLink>
  );
}

export function CompaniesTab() {
  const { t } = useTranslation();
  const { rates: nbpRates } = useNbpRates();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const ProfitCell = useCallback(
    (arg: any) => {
      const payment = arg.row.original as Payment;
      const profit = calculateProfit(payment, nbpRates);
      return (
        <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
          {profit ? formatNumberToString(Math.round(profit)) : "-"}
        </Text>
      );
    },
    [nbpRates]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("commissionFee:Number"),
        accessor: "id",
        Cell: NumberCell,
      },
      {
        Header: t("commissionFee:Company"),
        accessor: "transaction.company.name",
      },
      {
        Header: t("commissionFee:Trans."),
        accessor: "transaction.type",
      },
      {
        Header: t("commissionFee:Settl. date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("commissionFee:Settl. Qty.")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.value)}
          </Text>
        ),
      },
      {
        Header: (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("commissionFee:Trans Qty.")}
          </Text>
        ),
        accessor: "transaction.from.quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.value)}
          </Text>
        ),
      },
      {
        Header: t("commissionFee:From"),
        accessor: "transaction.from.currency",
      },
      {
        Header: t("commissionFee:To"),
        accessor: "transaction.to.currency",
      },
      {
        Header: t("commissionFee:Action"),
        accessor: "transaction.dealType",
      },
      {
        Header: t("commissionFee:Qty. rate"),
        accessor: "transaction.clientRate",
        Cell: (arg) => formatRate(arg.value),
      },
      {
        Header: t("commissionFee:Quote rate"),
        accessor: "transaction.ourRate",
        Cell: (arg) => formatRate(arg.value),
      },
      {
        Header: t("commissionFee:Fw. pts"),
        accessor: "fwdPoints",
        Cell: (arg) => formatNumberToString(arg.value, 4),
      },
      {
        Header: t("commissionFee:Roll. pts"),
        accessor: "rollback",
        Cell: (arg) => formatNumberToString(arg.value, 4),
      },
      {
        Header: (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("commissionFee:Profit")}
          </Text>
        ),
        accessor: "profit",
        Cell: ProfitCell,
      },
    ],
    [t, ProfitCell]
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const filtersAndBetween = useMemo(() => {
    let _startDate = formatDate(startDate);
    let _endDate = formatDate(endDate);
    const yearsMarginNumber = 100;
    if (startDate === "" && endDate === "") {
      return [];
    }
    if (startDate === "") {
      _startDate = dayjs(new Date()).subtract(yearsMarginNumber, "year").format("DD.MM.YYYY");
    }
    if (endDate === "") {
      _endDate = dayjs(new Date()).add(yearsMarginNumber, "year").format("DD.MM.YYYY");
    }

    return [`date:${_startDate},${_endDate}`];
  }, [startDate, endDate]);

  const { commissionFeeCompanies, loading, pageCount } = useCommissionFeeCompanies({
    phrase: debouncedSearchQuery,
    fields: "transaction.company.id",
    orderBy: "date",
    orderDirection: "desc",
    filtersAndBetween,
    offset,
    limit,
  });

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  if (!commissionFeeCompanies || !nbpRates) return null;

  return (
    <>
      <StyledTableControls>
        <StyledSearchContainer>
          <SearchBox
            collection="companies"
            fields="name"
            item={(company: Company) => company.name}
            onSelect={(company: Company | undefined) => setSearchQuery(company?.id || "")}
            orderBy="name"
            persist={(company: Company) => company.name}
            placeholder="Search"
          />
        </StyledSearchContainer>
        <Box alignItems="center" display="flex" justifyContent="space-between" ml="30px" mt="30px">
          <Input
            id="startDate"
            label={t("transaction:From")}
            name="startDate"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setStartDate(e.target.value);
            }}
            topLabel
            type="date"
            value={startDate}
            w={200}
          />
          <Box ml="30px">
            <Input
              id="endDate"
              label={t("transaction:To")}
              name="endDate"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEndDate(e.target.value);
              }}
              topLabel
              type="date"
              value={endDate}
              w={200}
            />
          </Box>
        </Box>
      </StyledTableControls>
      <Table
        columns={columns}
        data={commissionFeeCompanies}
        initialPageSize={25}
        loading={loading}
        onStateUpdate={handleTableUdpate}
        pageCount={pageCount}
        pageSizeOptions={[25, 50, 100]}
      />
    </>
  );
}
