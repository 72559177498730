import React from "react";
import { Contact } from "./Contact";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface ContactsProps {
  company: Company;
}

export function Contacts({ company }: ContactsProps) {
  const { t } = useTranslation();

  const sortedContacts =
    company && company.contacts
      ? company.contacts?.slice().sort((a, b) => {
          const aName = `${a.firstName} ${a.lastName}`.toLowerCase();
          const bName = `${b.firstName} ${b.lastName}`.toLowerCase();
          return aName < bName ? -1 : 1;
        })
      : [];

  return (
    <>
      {sortedContacts.length > 0 ? (
        sortedContacts.map((contact, i) => <Contact key={i} {...contact} />)
      ) : (
        <>{t("contact:No contacts added yet")}</>
      )}
    </>
  );
}
