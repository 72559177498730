import React from "react";
import { Link } from "react-router-dom";
import { MarginInfo } from "../GlobalMarginsMigration";
import styled from "@emotion/styled";
import _truncate from "lodash/truncate";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

export function CompanyLinkCell(arg: any) {
  const margin = arg.row.original as MarginInfo;
  const value = arg.value;

  return (
    <StyledLink title={value} to={`/companies/${margin.transaction.company.id}`}>
      {_truncate(value, { length: 50 })}
    </StyledLink>
  );
}
