import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Grid } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Modal } from "../../../components/Modal/Modal";
import { Institution } from "../../../interfaces/data";
import { InputWithLabel } from "../../../components/InputWithLabel/InputWithLabel";

export const StyledCheckbox = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export const StyledCurrencySelectContainer = styled.div`
  display: grid;
  grid-template-columns: 110px 281px;
  grid-column-gap: 10px;
`;

interface InstitutionFormModalProps {
  title: string;
  initialInstitution: Institution;
  errors: any;
  onSave: (newInstitution: Institution) => void;
  onClose: () => void;
  isDisabled?: boolean;
}

export const InstitutionFormModal: React.FC<InstitutionFormModalProps> = ({
  title,
  initialInstitution,
  errors,
  onSave,
  onClose,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [editedInstitution, setEditedInstitution] = useState<Institution>(_cloneDeep(initialInstitution));

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditedInstitution((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, e.target.value);
      return newEditedData;
    });
  }, []);

  if (!editedInstitution) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isDisabled={isDisabled}
      isOpen
      justifyButtons="flex-end"
      onClose={onClose}
      onConfirm={() => onSave(editedInstitution)}
      title={title}
    >
      <Grid paddingLeft="30px" rowGap="10px">
        <InputWithLabel
          error={errors?.["name"]}
          id="name"
          label={t("settings:Institution name")}
          name="name"
          onChange={handleUpdate}
          type="text"
          value={editedInstitution?.name}
          withError={!!errors?.["name"]}
        />
        <InputWithLabel
          error={errors?.["securityDeposit"]}
          id="securityDeposit"
          label={t("settings:Security deposit")}
          name="securityDeposit"
          onChange={handleUpdate}
          rightAddon="%"
          type="number"
          value={editedInstitution?.securityDeposit}
          width="231px"
          withError={!!errors?.["securityDeposit"]}
        />
      </Grid>
    </Modal>
  );
};
