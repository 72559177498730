import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ADD_TRANSACTION } from "helpers/userRoles";
import { useLastActivity } from "hooks/useLastActivity";
import { useLocalStorage } from "hooks/useLocalStorage";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import {
  StyledActivityContainer,
  StyledButton,
  StyledContainer,
  StyledLink,
  StyledMoreInfo,
  StyledSectionHeader,
} from "./LastActivityWidget.styled";
import { MinimalizeIcon } from "theme/icons/MinimalizeIcon";

export interface ActivityInfo {
  id: string;
  title: string;
  url: string;
  info: string;
}

export const LastActivityWidget: React.FC = () => {
  const [localStorageIsOpen, setLocalStorageIsOpen] = useLocalStorage(`isWidgetOpen`, true);
  const [isOpen, setIsOpen] = useState(localStorageIsOpen);
  const { t } = useTranslation();

  const handleWidgetToggle = useCallback(() => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    setLocalStorageIsOpen(newIsOpen);
  }, [isOpen, setLocalStorageIsOpen]);

  const { visitedTransactionsInfo, visitedCompaniesInfo } = useLastActivity();

  return (
    <StyledContainer>
      <StyledButton onClick={handleWidgetToggle}>
        <MinimalizeIcon boxSize="12px" />
      </StyledButton>
      {isOpen && (
        <>
          <StyledSectionHeader>{t("sidebar:Recently viewed companies")}</StyledSectionHeader>
          {visitedCompaniesInfo.map((activity: ActivityInfo) => (
            <StyledActivityContainer key={activity.id}>
              <StyledLink to={activity.url}>{activity.title}</StyledLink>
              <StyledMoreInfo>{activity.info}</StyledMoreInfo>
            </StyledActivityContainer>
          ))}

          <RestrictAccess area={ADD_TRANSACTION}>
            <StyledSectionHeader>{t("sidebar:Recently viewed transactions")}</StyledSectionHeader>
            {visitedTransactionsInfo.map((activity: ActivityInfo) => (
              <StyledActivityContainer key={activity.id}>
                <StyledLink to={activity.url}>{activity.title}</StyledLink>
                <StyledMoreInfo>{activity.info}</StyledMoreInfo>
              </StyledActivityContainer>
            ))}
          </RestrictAccess>
        </>
      )}
    </StyledContainer>
  );
};
