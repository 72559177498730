import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Margin, MarginType } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { Modal } from "components/Modal/Modal";
import { useCompany } from "hooks/useCompany";
import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { Input } from "components/Input/Input";
import { formatNumberToString } from "helpers/formatNumber";
import { CURRENCIES } from "helpers/options";
import { Checkbox } from "components/Checkbox/Checkbox";
import { StyledMarginsList, StyledMarginsListItem, StyledShowAllButton } from "./MarginMove.styled";
import { useTransaction } from "hooks/useTransaction";
import { useTransactions } from "hooks/useTransactions";
import { Select } from "components/Select/Select";
import { getWithdrawValue } from "helpers/margin";

export function GlobalMarginMoveModal({
  companyId,
  marginId,
  handleClose,
}: {
  companyId: string;
  marginId: string;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { transactions } = useTransactions({
    phrase: "",
    fields: "number",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 9999999,
  });
  const { company, globalMarginUpdateUnsafe, globalMarginCancelUnsafe } = useCompany({
    id: companyId,
  });
  const [selectedTransactionId, setSelectedTransactionId] = useState<string | undefined>();
  const { marginAdd } = useTransaction({
    id: selectedTransactionId,
  });
  const [isSending, setIsSending] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [newMarginType, setNewMarginType] = useState<MarginType>("IM");
  const [quantity, setQuantity] = useState<number>(0);

  const numberOfCollapse = 5;

  const editedMargin = useMemo(() => {
    const margin = company?.globalMargins?.find((margin) => margin.id === marginId);
    if (margin) {
      const withdrawn = getWithdrawValue(margin);
      setQuantity(margin.from.quantity - withdrawn);
    }
    return margin;
  }, [company, marginId]);

  const handleOnSaveAction = useCallback(
    async (newMargin: Margin) => {
      setIsSending(true);
      try {
        // Substract from global margin and if it would be left with 0, cancel it
        if (!newMargin) return;
        if (quantity === Number(newMargin?.from.quantity)) {
          await globalMarginCancelUnsafe(newMargin.id);
        } else {
          const clonedEditedMargin = _cloneDeep(newMargin);
          clonedEditedMargin.from.quantity -= quantity;
          clonedEditedMargin.to.quantity -= quantity;
          clonedEditedMargin.left.quantity -= quantity;
          await globalMarginUpdateUnsafe(clonedEditedMargin);
        }
        // create new local margin with the selected quantity
        const clonedEditedMargin = _cloneDeep(newMargin);
        clonedEditedMargin.from.quantity = quantity;
        clonedEditedMargin.to.quantity = quantity;
        clonedEditedMargin.left.quantity = quantity;
        clonedEditedMargin.type = newMarginType;
        clonedEditedMargin.createdFromMovement = true;
        await marginAdd(clonedEditedMargin);

        setIsSending(false);
        toast({
          type: "success",
          message: t("margin:Global margin has been moved successfully"),
        });
        handleClose();
      } catch (error) {
        setIsSending(false);
        if (error instanceof Error) {
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        }
      }
    },
    [globalMarginCancelUnsafe, globalMarginUpdateUnsafe, newMarginType, handleClose, t, toast, quantity, marginAdd]
  );

  const visibleAvailableTransactions = useMemo(() => {
    if (isExpanded) {
      return transactions.filter((t) => t.company.id === companyId);
    }
    return transactions.filter((t) => t.company.id === companyId).slice(0, numberOfCollapse);
  }, [transactions, isExpanded, companyId]);

  if (!editedMargin) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isDisabled={isSending || !selectedTransactionId}
      isOpen
      justifyButtons="flex-end"
      minWidth={840}
      onClose={handleClose}
      onConfirm={() => handleOnSaveAction(editedMargin)}
      title={t("margin:Move global variation margin to local")}
    >
      Move to transaction:
      <StyledMarginsList>
        {visibleAvailableTransactions.map((transaction) => {
          return (
            <StyledMarginsListItem
              key={transaction.id}
              onClick={(e) => {
                setSelectedTransactionId(transaction.id);
                e.stopPropagation();
              }}
            >
              <Box display="flex">
                <Checkbox
                  checkboxSize="small"
                  isChecked={selectedTransactionId === transaction.id}
                  isReadOnly
                  mr="10px"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
                <Box fontWeight="bold" pr="15px">
                  Id: {transaction.number}
                </Box>
                <Box pr="15px">
                  Qty: {transaction.from != null ? formatNumberToString(transaction.from.quantity) : ""}
                  {transaction.from.currency}
                </Box>
              </Box>
            </StyledMarginsListItem>
          );
        })}
        {transactions.filter((t) => t.company.id === companyId).length > numberOfCollapse && !isExpanded && (
          <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
            {t("Show all")}
          </StyledShowAllButton>
        )}
      </StyledMarginsList>
      <Box display="flex" mb="10px">
        <Input
          error={quantity < 0 || quantity > editedMargin.from.quantity ? "error" : undefined}
          formatNumberOnBlur
          hideErrorMessage
          id="from.quantity"
          label="Quantity"
          name="from.quantity"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
          topLabel
          type="number"
          value={quantity}
          withError={quantity < 0 || quantity > editedMargin.from.quantity}
        />
        <Box ml="10px">
          <AutoCompleteSelect
            id="from.currency"
            isDisabled
            name="from.currency"
            options={CURRENCIES}
            value={editedMargin?.from.currency}
            width="85px"
          />
        </Box>
        <Box ml="10px">
          <Select
            id="newMarginType"
            name="newMarginType"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewMarginType(e.target.value as MarginType)}
            options={[
              { value: "IM", label: "Initial Margin" },
              { value: "VM", label: "Variation Margin" },
            ]}
            value={newMarginType}
          />
        </Box>
      </Box>
    </Modal>
  );
}
