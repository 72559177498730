import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const DisabledIcon = createIcon({
  displayName: "DisabledIcon",
  path: (
    <g fill="none">
      <path
        d="M7 13A6 6 0 107 1a6 6 0 000 12zM2.758 11.243l8.485-8.485"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 14 14",
});
