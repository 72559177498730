import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const MinimalizeIcon = createIcon({
  displayName: "MinimalizeIcon",
  path: (
    <g fill="none">
      <path d="M10.3333 4.99932H7V1.66602" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 1L7 4.99997" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.00127 10.3333V7H1.66797" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 11L4.99997 7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: "0 0 12 12",
});
