import React from "react";
import { useTranslation } from "react-i18next";

import { BaseContact } from "../../../interfaces/data";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";

import { EditViewProps } from "../../../components/EditBox/EditBox";
import { Select } from "../../../components/Select/Select";
import { CONTACT_STATUSES } from "../../../helpers/options";

export function EditStatus({ data, handleChange }: EditViewProps<BaseContact>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Select
        id="status"
        label={t("contact:Status")}
        name="status"
        onChange={handleChange}
        options={CONTACT_STATUSES}
        value={data.status}
      />
    </TwoColumnsLayout>
  );
}
