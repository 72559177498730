import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationPL from "./locales/pl/index";

const resources = {
  pl: translationPL,
};

i18n.use(initReactI18next).init({
  resources,
  ns: [
    "common",
    "sidebar",
    "login",
    "company",
    "contact",
    "validation",
    "application",
    "transaction",
    "hedge",
    "margin",
    "settlement",
    "marginCall",
    "commissionFee",
    "multiuser",
    "settings",
    "dashboard",
  ],
  defaultNS: "common",
  lng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
