import React, { useCallback, useEffect, useState } from "react";
import _cloneDeep from "lodash.clonedeep";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Margin } from "interfaces/data";
import { determineTransactionCurrencyPair, MARGIN_TYPES } from "helpers/transaction";
import { useTransaction } from "hooks/useTransaction";
import { useFirebase } from "hooks/useFirebase";
import { useToast } from "hooks/useToast";
import { MarginFormModal } from "./MarginFormModal/MarginFormModal";
import { useCompany } from "hooks/useCompany";
import { useIsMounted } from "hooks/useIsMounted";

export function MarginAdd() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { transaction, marginAdd, marginErrors } = useTransaction({ id });
  const { addPaymentEntry } = useCompany({ id: transaction?.company.id });
  const [currencies, setCurrencies] = useState({ from: "PLN", to: "PLN" });
  const { timestamp } = useFirebase();
  const [isSending, setIsSending] = useState(false);
  const { isMounted } = useIsMounted();

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newMargin: Margin) => {
      const clonedMargin = _cloneDeep(newMargin);
      setIsSending(true);
      marginAdd(clonedMargin)
        .then(async (savedMargin) => {
          if (savedMargin) {
            await addPaymentEntry(
              savedMargin.left.quantity,
              savedMargin.left.currency,
              savedMargin.id,
              savedMargin.date
            );
            setIsSending(false);
            toast({
              type: "success",
              message: t("margin:Margin has been added successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marginAdd, history, id, addPaymentEntry]
  );

  useEffect(() => {
    if (transaction) {
      const [to] = determineTransactionCurrencyPair(transaction);
      setCurrencies({ from: to, to });
    }
  }, [transaction]);

  if (!transaction || !isMounted) return null;

  return (
    <MarginFormModal
      errors={marginErrors}
      initialMargin={{
        id: "",
        date: timestamp(),
        type: MARGIN_TYPES.VARIATION.value,
        from: {
          currency: currencies.from,
          quantity: 0,
        },
        to: {
          currency: currencies.to,
          quantity: 0,
        },
        left: {
          currency: currencies.from,
          quantity: 0,
        },
      }}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("margin:New margin")}
      type="add"
    />
  );
}
