import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "react-datepicker/dist/react-datepicker.min.css";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { Text } from "@chakra-ui/react";
import enGb from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import { inputErrors } from "components/Input/Input.errors";
import { useTranslation } from "react-i18next";
import { CalendarIcon, CloseIcon } from "theme/icons";
import isPropValid from "@emotion/is-prop-valid";

registerLocale("en-GB", enGb);

interface DatePickerProps {
  name?: string;
  value?: any;
  onChange?: (newValue: any) => any;
  error?: string;
  label?: string;
  withError?: boolean;
  isDisabled?: boolean;
  enableDateClear?: boolean;
}

const StyledContainer = styled.div`
  position: relative;
`;

const StyledDatePickerContainer = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  hasError?: boolean;
  isDisabled?: boolean;
}>`
  display: flex;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 100;
    margin-top: 1px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    border-radius: 16px;
    border: 1px solid #ececec;
  }

  .react-datepicker__header {
    border-radius: 16px 16px 0 0;
    border: none;
    border-bottom: 1px solid #ececec;
    background: white;
  }

  .react-datepicker__input-container input {
    height: 42px;
    border: 1px solid ${(props) => props.theme.colors[props.hasError ? "sk-red" : "sk-light-gray"]};
    background-color: ${(props) => (props.isDisabled ? "rgba(236, 236, 236, 0.3)" : "#fff")};
    color: ${(props) => props.theme.colors[props.isDisabled ? "sk-gray" : "sk-dark"]};
    cursor: ${(props) => (props.isDisabled ? "not-allowed" : "unset")};
    font-size: 0.8125rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    min-width: 140px;
    width: 100%;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #877cd0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #5b4dbe !important;
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    overflow: visible;

    &:before {
      content: "";
      width: 40px;
      height: 30px;
      position: absolute;
      top: -15px;
      left: -25px;
      cursor: pointer;
    }
  }

  .react-datepicker__navigation--previous {
    &:before {
      left: initial;
      right: -25px;
    }
  }
`;
export const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
  transition: 300ms ease all;
  z-index: 2;
  padding: 3px 5px;
  top: -10px;
`;

const StyledCloseIcon = styled(CloseIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ enableDateClear: boolean }>`
  position: absolute;
  top: 12px;
  right: 22px;
  pointer-events: ${({ enableDateClear }) => (enableDateClear ? "all" : "none")};
  cursor: ${({ enableDateClear }) => enableDateClear && "pointer"};
`;

const StyledCalendarIcon = styled(CalendarIcon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ enableDateClear: boolean }>`
  position: absolute;
  top: 12px;
  right: 22px;
  pointer-events: ${({ enableDateClear }) => (enableDateClear ? "all" : "none")};
  cursor: ${({ enableDateClear }) => enableDateClear && "pointer"};
`;

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  name,
  value,
  onChange,
  error,
  label,
  withError,
  isDisabled = false,
  enableDateClear = false,
}) => {
  const { t } = useTranslation();
  const [inputError, setInputError] = useState<string | undefined>(undefined);

  const handleChange = (newDate: Date) => {
    if (typeof onChange !== "function" || !newDate) return;
    inputError && setInputError(undefined);

    onChange({
      target: {
        name,
        value: dayjs(newDate).format("YYYY-MM-DD"),
      },
      persist: () => {
        return;
      },
    });
  };

  const handleClear = () => {
    if (!enableDateClear || typeof onChange !== "function") {
      return;
    }

    return onChange({
      target: { name, value: "" },
      persist: () => {
        return;
      },
    });
  };

  useEffect(() => {
    error && setInputError(error);
  }, [error]);

  return (
    <StyledContainer>
      <StyledDatePickerContainer hasError={Boolean(inputError)} isDisabled={isDisabled}>
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          disabled={isDisabled}
          locale="en-GB"
          onChange={handleChange}
          popperPlacement="bottom-end"
          scrollableYearDropdown
          selected={value && new Date(value)}
          showYearDropdown
          yearDropdownItemNumber={6}
        />
        {label && <StyledLabel>{label}</StyledLabel>}
        {value && enableDateClear ? (
          <StyledCloseIcon enableDateClear onClick={handleClear} />
        ) : (
          <StyledCalendarIcon enableDateClear={false} />
        )}
      </StyledDatePickerContainer>
      {withError && inputError && (
        <Text
          alignItems="center"
          color="sk-red"
          display="flex"
          fontSize="xs-sm"
          fontWeight="bold"
          height="20px"
          mb="0px"
          ml="20px"
          mt="10px"
        >
          {t(`validation:${inputErrors[inputError] || "Unknown"}`)}
        </Text>
      )}
    </StyledContainer>
  );
};
