import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import {
  TransactionConfirmContent,
  TransactionConfirmDescription,
  TransactionConfirmLabel,
} from "./TransactionConfirm.styled";
import { BaseTransaction, TransactionType } from "interfaces/data";
import { DISPLAY_DATE_FORMAT, formatDate } from "helpers/date";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { determineTransactionCurrency, getMarginQuantity, TRANSACTION_TYPES } from "helpers/transaction";

interface TransactionConfirmProps {
  transaction?: BaseTransaction;
  isOpen: boolean;
  type: "add" | "edit" | "roll" | "convert";
  transactionType?: TransactionType;
  onClose: () => void;
  onConfirm: (transaction: BaseTransaction) => void;
  sendEmail?: boolean;
  isDisabled?: boolean;
}

export const TransactionConfirm: React.FC<TransactionConfirmProps> = ({
  isOpen,
  type,
  transactionType,
  onClose,
  onConfirm,
  transaction,
  sendEmail = false,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const modalBackgroundColor = useMemo(() => {
    switch (true) {
      case transactionType === TRANSACTION_TYPES.SPOT.value:
        return "#eaf6ff";
      case transactionType === TRANSACTION_TYPES.ORDER.value:
        return "#fff9e5";
      default:
        return "#fff";
    }
  }, [transactionType]);

  if (!transaction) return null;

  const comment = transaction.comment || "-";

  return (
    <Modal
      backgroundColor={modalBackgroundColor}
      confirmText={type === "roll" ? t("transaction:Roll") : type === "convert" ? t("Convert") : t("Save")}
      design="primary"
      isChild
      isDisabled={isDisabled}
      isOpen={isOpen}
      minWidth={420}
      onClose={onClose}
      onConfirm={() => onConfirm(transaction)}
    >
      <TransactionConfirmContent>
        {transaction.end && (
          <>
            <TransactionConfirmLabel>{t("transaction:Maturity date")}</TransactionConfirmLabel>
            <TransactionConfirmDescription>
              {formatDate(transaction.end, DISPLAY_DATE_FORMAT)}
            </TransactionConfirmDescription>
          </>
        )}

        {transaction.start && (
          <>
            <TransactionConfirmLabel>{t("transaction:Start date")}</TransactionConfirmLabel>
            <TransactionConfirmDescription>
              {formatDate(transaction.start, DISPLAY_DATE_FORMAT)}
            </TransactionConfirmDescription>
          </>
        )}

        <TransactionConfirmLabel>{t("transaction:Deal type")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{transaction.dealType}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:Quantity")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{formatNumberToString(transaction.from.quantity)}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:What")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{transaction.from.currency}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:For what")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{transaction.to.currency}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:Client's rate")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{formatNumberToString(transaction.clientRate, 4)}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:Our rate")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{formatRate(transaction.ourRate, 4)}</TransactionConfirmDescription>

        {Number(transaction.initialMargin) !== 0 && transactionType !== TRANSACTION_TYPES.SPOT.value && (
          <>
            <TransactionConfirmLabel>{t("transaction:Initial margin %")}</TransactionConfirmLabel>
            <TransactionConfirmDescription>
              {formatNumberToString(transaction.initialMargin || 0)}
            </TransactionConfirmDescription>

            <TransactionConfirmLabel>{t("transaction:Initial margin qty")}</TransactionConfirmLabel>
            <TransactionConfirmDescription>
              {getMarginQuantity(transaction)} {determineTransactionCurrency(transaction)}
            </TransactionConfirmDescription>
          </>
        )}

        <TransactionConfirmLabel>{t("transaction:Account")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>
          {transaction.account
            ? `${transaction.account.number} ${transaction.account.currency}`
            : t("transaction:Bank account will be provided later")}
        </TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:Comment")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{comment}</TransactionConfirmDescription>

        <TransactionConfirmLabel>{t("transaction:Email")}</TransactionConfirmLabel>
        <TransactionConfirmDescription>{sendEmail ? "Yes" : "No"}</TransactionConfirmDescription>
      </TransactionConfirmContent>
    </Modal>
  );
};
