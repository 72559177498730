import { Transaction } from "interfaces/data";
import { StyledLink } from "./styles";
import { Box } from "@chakra-ui/react";
import React from "react";

export function IdCell(arg: any) {
  const transaction = arg.row.original as Transaction;
  return (
    <StyledLink to={`/transactions/${transaction.id}`}>
      <Box as="span" display="block" whiteSpace="nowrap">
        {transaction.number}
      </Box>
    </StyledLink>
  );
}
