import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledEditableField = styled.div`
  cursor: pointer;
`;

export const StyledEditContainer = styled.div`
  position: absolute;
  display: flex;
  top: -25px;
  left: -18px;
  max-width: 160px;
  z-index: 10;
`;
