import styled from "@emotion/styled";

export const StyledFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  justify-content: space-between;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  display: flex;
  margin-top: 13px;
  justify-content: flex-start;
  font-weight: 600;
  line-height: 110%;
`;
