import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { MarginCallMarginAddModal } from "./MarginCallMarginAddModal";
import { Company, Transaction } from "interfaces/data";

export function MarginCall360MarginAdd() {
  const { id, transactionId, marginCallId } = useParams<{
    id: string;
    transactionId: string;
    marginCallId: string;
  }>();
  const { transaction, update: updateTransaction } = useTransaction({
    id: transactionId,
  });
  const { t } = useTranslation();
  const toast = useToast();
  const { company, update: updateCompany } = useCompany({ id });
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSave = useCallback(
    ({ updatedTransaction, updatedCompany }: { updatedTransaction: Transaction; updatedCompany: Company }) => {
      Promise.all([updateTransaction(updatedTransaction), updateCompany(updatedCompany)])
        .then(() => {
          toast({
            type: "success",
            message: t("transaction:Margin call has been edited successfully."),
          });
          handleClose();
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleClose, t, toast, updateCompany, updateTransaction]
  );

  if (!transaction || !marginCallId || !company) return null;

  return (
    <MarginCallMarginAddModal
      company={company}
      marginCallId={marginCallId}
      onClose={handleClose}
      onSave={handleSave}
      transaction={transaction}
    />
  );
}
