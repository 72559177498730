import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "hooks/useTransaction";
import { MarginCall } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { MarginCallFormModal } from "./MarginCallFormModal";
import { MARGIN_CALL_MAIL } from "helpers/mails";
import { useMail } from "hooks/useMail";
import { ZodOptionalIDMarginCall } from "schemas/marginCall";

export function MarginCallAdditionalEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId?: string;
  }>();
  const toast = useToast();
  const { transaction, marginCallUpdate, marginCallErrors } = useTransaction({
    id,
  });
  const sendCorrection = useMail();
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveAction = useCallback(
    (newMarginCall: ZodOptionalIDMarginCall) => {
      setIsSending(true);
      marginCallUpdate(newMarginCall as MarginCall)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            sendCorrection(MARGIN_CALL_MAIL, transaction!.id, {
              marginCall: newMarginCall.id,
              correction: "yes",
              additional: "yes",
            });
            toast({
              type: "success",
              message: t("marginCall:Margin Call has been updated successfully"),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`marginCall:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose, marginCallUpdate]
  );

  const editedMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [marginCallId, transaction]);

  const previousMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === editedMarginCall?.createdFromId);
    }
  }, [transaction, editedMarginCall]);

  // Edit can be made only if the nothing has been paid yet
  useEffect(() => {
    if (transaction && editedMarginCall && editedMarginCall.margins && editedMarginCall.margins.length > 0) {
      handleClose();
      toast({
        type: "error",
        message: t("marginCall:This Margin Call cannot be edited anymore."),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, editedMarginCall]);

  if (!transaction || !editedMarginCall || !previousMarginCall) return null;

  return (
    <MarginCallFormModal
      errors={marginCallErrors}
      initialMarginCall={editedMarginCall}
      initialTransaction={transaction}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      previousCallRate={previousMarginCall.callRate}
      title={t("marginCall:Edit Additional Margin Call {{marginCallId}}", {
        marginCallId: marginCallId,
      })}
      type="edit-additional"
    />
  );
}
