import React from "react";
import { ZodError, ZodIssue } from "zod";
import { Modal } from "components/Modal/Modal";
import { ModalBody, ModalHeader } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ZodInvalidUnionIssue } from "zod/lib/ZodError";

const ValidationList = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  column-gap: 2rem;
  align-items: center;
`;
const ValidationPath = styled.dt`
  font-family: monospace;
  text-align: right;
`;
const ValidationMessage = styled.dd``;
interface ValidationModalProps {
  title: string;
  validationErrors: ZodError;
  onClose: () => void;
  onConfirm: () => void;
}
export const ValidationModal: React.FC<ValidationModalProps> = ({ validationErrors, onClose, onConfirm, title }) => {
  return (
    <Modal confirmText="Fix" isOpen minWidth={700} onClose={onClose} onConfirm={onConfirm}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <ValidationList>
          {validationErrors.issues.map((issue) => (
            <Issue issue={issue} key={issue.path.join(".")} />
          ))}
        </ValidationList>
      </ModalBody>
    </Modal>
  );
};

const Issue: React.FC<{ issue: ZodIssue }> = ({ issue }) => {
  const message = !isZodInvalidUnionIssue(issue)
    ? issue.message
    : issue.unionErrors.map((e) => e.issues.map((i) => i.message).join(", ")).join(" or ");

  return (
    <>
      <ValidationPath>{issue.path.join(" > ")}</ValidationPath>
      <ValidationMessage>{message}</ValidationMessage>
    </>
  );
};

function isZodInvalidUnionIssue(issue: ZodIssue | ZodInvalidUnionIssue): issue is ZodInvalidUnionIssue {
  return (issue as ZodInvalidUnionIssue).unionErrors !== undefined;
}
