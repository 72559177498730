import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "../../hooks/useTransaction";
import { useToast } from "../../hooks/useToast";
import { Modal } from "../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { useMail } from "../../hooks/useMail";
import { GLOBAL_MARGIN_CALL_MAIL, MARGIN_CALL_MAIL } from "../../helpers/mails";

export function MarginCallCancel() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId: string;
  }>();
  const { transaction, marginCallCancel, globalMarginCallCancel } = useTransaction({ id });
  const sendCancellation = useMail();
  const [isSending, setIsSending] = useState(false);

  const marginCall = useMemo(
    () => transaction?.marginCalls?.find((margin) => (margin.id = marginCallId)),
    [marginCallId, transaction?.marginCalls]
  );

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelAction = useCallback(async () => {
    if (!marginCall) return null;

    setIsSending(true);

    if (marginCall.isGMC === "yes") {
      const transactionIds = marginCall?.globalTransactionsIds?.map((t) => t.id);
      if (transactionIds) {
        const data = await Promise.all(
          transactionIds.map((transactionId) => globalMarginCallCancel(marginCallId, transactionId))
        );
        if (!data.includes(false)) {
          sendCancellation(GLOBAL_MARGIN_CALL_MAIL, transactionIds[0], {
            marginCall: marginCallId,
            cancel: "yes",
          });
          toast({
            type: "success",
            message: t("marginCall:GMC has beed canceled successfully"),
          });
        } else {
          throw new Error("Unable to cancel GMC");
        }
      }
    } else {
      marginCallCancel(marginCallId)
        .then((success) => {
          if (success) {
            sendCancellation(MARGIN_CALL_MAIL, String(transaction?.id), {
              marginCall: marginCallId,
              cancel: "yes",
            });
            toast({
              type: "success",
              message: t("marginCall:Margin Call has been canceled successfully."),
            });
          }
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(`marginCall:${error.message}`),
          });
        });
    }
    setIsSending(false);
    handleClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marginCallCancel, marginCallId, history, id]);

  const editedMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [marginCallId, transaction]);

  useEffect(() => {
    if (!transaction || !transaction.marginCalls || !editedMarginCall) return;

    const maxMarginCallId = Math.max(...transaction.marginCalls.map((item) => Number(item.id?.split("-")[1])), 0);

    // Only most recent Margin Call can be canceled
    if (maxMarginCallId !== Number(editedMarginCall.id.split("-")[1])) {
      handleClose();
      toast({
        type: "error",
        message: t("marginCall:Only most recent Margin Call can be canceled."),
      });
      return;
    }

    // Margin Call can be canceled only if the nothing has been paid yet
    if (editedMarginCall.margins && editedMarginCall.margins.length > 0) {
      handleClose();
      toast({
        type: "error",
        message: t(`marginCall:This Margin Call cannot be canceled anymore.`),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, editedMarginCall]);

  if (!transaction || !editedMarginCall) return null;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={t(`marginCall:Cancel ${editedMarginCall.isGMC === "yes" ? "" : "margin call"} {{marginCallId}}`, {
        marginCallId,
      })}
    >
      <Box mb={"20px"}>
        {t(
          `marginCall:This action can't be undone. Are you sure you want to cancel ${
            editedMarginCall.isGMC === "yes" ? "" : "margin call"
          } {{marginCallId}}?`,
          { marginCallId }
        )}
      </Box>
    </Modal>
  );
}
