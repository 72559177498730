import * as yup from "yup";

export const marginSchema = yup.object().shape({
  type: yup.string().required().trim(),
  from: yup.object().shape({
    quantity: yup.number().positive().required(),
    currency: yup.string().required().trim(),
  }),
  to: yup.object().shape({
    quantity: yup.number().positive().required(),
    currency: yup.string().required().trim(),
  }),
});
