import styled from "@emotion/styled";
import { Button } from "components/Button/Button";
import isPropValid from "@emotion/is-prop-valid";

export const SelectedBankAccountButton = styled("button", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  withError?: boolean;
}>`
  height: 44px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors[props.withError ? "sk-red" : "sk-light-gray"]};
  background: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
`;

export const StyledDropdownItem = styled(Button)`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
  justify-content: flex-start;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 13px;
  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-purple"]};
  }
`;

export const CalcGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
  grid-column-gap: 5px;
`;

export const AccountGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  margin-bottom: 10px;
`;

export const StyledRadioContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  padding-bottom: 20px;
  margin-bottom: 30px;
`;

export const StyledCheckbox = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export const StyledCompanyInfo = styled.div`
  display: grid;
  grid-template-columns: 110px 1fr;
  grid-column-gap: 10px;
`;
