import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CreditCardIcon = createIcon({
  displayName: "CreditCardIcon",
  path: (
    <g fill="none">
      <path d="M4 9H7" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path d="M12 9H13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path d="M16 5H1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path
        d="M15 12H2C1.448 12 1 11.552 1 11V2C1 1.448 1.448 1 2 1H15C15.552 1 16 1.448 16 2V11C16 11.552 15.552 12 15 12Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 17 13",
});
