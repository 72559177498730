import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router-dom";
import { Box, Flex, Grid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _cloneDeep from "lodash.clonedeep";
import { Heading } from "components/Heading/Heading";
import { CompanyInfo } from "./CompanyInfo/CompanyInfo";
import { EditCompanyInfo } from "./CompanyInfo/EditCompanyInfo";
import { Details } from "./Details/Details";
import { EditDetails } from "./Details/EditDetails";
import { CompanyAddress } from "./CompanyAddress/CompanyAddress";
import { EditCompanyAddress } from "./CompanyAddress/EditCompanyAddress";
import { EditContactAddress } from "./ContactAddress/EditContactAddress";
import { ContactAddress } from "./ContactAddress/ContactAddress";
import { BankAccount } from "./BankAccount/BankAccount";
import { EditBankAccount } from "./BankAccount/EditBankAccount";
import { EditBox, StyledBoxContent, StyledBoxName, StyledContainer } from "components/EditBox/EditBox";
import { useApplication } from "hooks/useApplication";
import { Spinner } from "components/Spinner/Spinner";
import { CollapsableEditBox } from "components/CollapsableEditBox/CollapsableEditBox";
import { SingleContact } from "./SingleContact/SingleContact";
import { EditSingleContact } from "./SingleContact/EditSingleContact";
import { ConfirmationTag } from "../Contact/ConfirmationTag/ConfirmationTag";
import { AUTHORIZED_PERSON, BENEFICIARY, LEGAL_REPRESENTANT } from "helpers/contact";
import { Button } from "components/Button/Button";
import { Application as IApplication, BankAccount as IBankAccount, Contact } from "interfaces/data";
import { StyledBackButton, StyledThreeColumnsContainer } from "components/ItemsListElements/ItemsListElements";
import { useToast } from "hooks/useToast";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Footer } from "components/Footer/Footer";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { useMail } from "hooks/useMail";
import { AGREEMENT_MAIL } from "helpers/mails";
import { Modal } from "components/Modal/Modal";
import { useLogs } from "hooks/useLogs";
import { AddIcon } from "theme/icons/AddIcon";
import { ArrowLeftIcon } from "theme/icons";

export const StyledCollapseHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const StyledCollapseContent = styled.div`
  padding-top: 17px;
`;

export const StyledName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  min-height: 30px;
`;

enum ApplicationSections {
  companyInfo = "company-info",
  details = "details",
  companyAddress = "company-address",
  contactAddress = "contact-address",
  bankAccount = "bank-account",
  legalRepresenstants = "legal-representants",
  authorizedPersons = "authorized-persons",
  beneficiaries = "beneficiaries",
}

const emptyBankAccount: IBankAccount = {
  number: "",
  name: "",
  swift: "",
  currency: "PLN",
  country: "Poland",
};

const ApplicationThreeColumnsContainer = styled(StyledThreeColumnsContainer)`
  grid-template-columns: minmax(500px, 1fr) 1fr 1fr;
`;

const handleRemove =
  (name: keyof IApplication) =>
  (index: number, { handler, data }: { handler: Function; data: IApplication }) => {
    const value = _cloneDeep(data[name]);

    if (Array.isArray(value)) {
      value.splice(index, 1);
    }

    handler({
      target: {
        name,
        value,
      },
      persist: () => {
        return;
      },
    });
  };

const handleAdd =
  (name: keyof IApplication) =>
  (item: any, { handler, data }: { handler: Function; data: IApplication }) => {
    const currentValue = _cloneDeep(data[name]);
    const clonedItem = _cloneDeep(item);

    const value = Array.isArray(currentValue) ? [...currentValue, clonedItem] : [clonedItem];

    handler({
      target: {
        name,
        value,
      },
      persist: () => {
        return;
      },
    });
  };

export const Application: React.FC = () => {
  const { id, section, contactId } = useParams<{
    id: string;
    section: ApplicationSections;
    contactId: string;
  }>();
  const { application, loading, update, approve, remove, errors } = useApplication({
    id,
  });

  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { log } = useLogs();
  const sendAgreement = useMail();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editedSection, setEditedSection] = useState<ApplicationSections | undefined>(section);
  const [editedContactId, setEditedContactId] = useState<string | undefined>(contactId);
  const [collapsedContactsIds, setCollapsedContactsIds] = useState<Array<string>>([]);

  const cancelSectionEdit = useCallback(() => setEditedSection(undefined), []);

  const goToApplicationsList = useCallback(() => {
    history.push("/applications");
  }, [history]);

  useEffect(() => {
    if (editedContactId && editedSection) {
      history.replace(`/applications/${id}/edit/${editedSection}/${editedContactId}`);
    } else if (editedSection) {
      history.replace(`/applications/${id}/edit/${editedSection}`);
    } else {
      history.replace(`/applications/${id}`);
    }
  }, [editedSection, editedContactId, history, id]);

  const handleApplicationApprove = useCallback(() => {
    if (application) {
      approve(application).then(async (savedCompanyId) => {
        if (savedCompanyId) {
          await sendAgreement(AGREEMENT_MAIL, savedCompanyId);
          goToApplicationsList();
        }
        const toastSuccessMsg = t("application:Application successfully accepted. Company added to Companies list.");
        const toastErrorMsg = t("application:Application could not be approved.");
        toast({
          type: savedCompanyId ? "success" : "error",
          message: savedCompanyId ? toastSuccessMsg : toastErrorMsg,
        });
      });
      setIsConfirmed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  const handleApplicationRemove = useCallback(async () => {
    if (application) {
      await remove(application?.id);
      await log({
        action: "delete",
        item: {
          collection: "applications",
          id: application.id,
          name: application.name,
        },
        oldData: JSON.stringify(application),
      });
      setIsDeleteModalOpen(false);
      goToApplicationsList();
      toast({
        type: "success",
        message: t("application:Application successfully deleted."),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application]);

  const handleSectionEdit = useCallback(
    (section: ApplicationSections | undefined, contactId?: string) => () => {
      setEditedSection(section);
      setEditedContactId(contactId);
    },
    []
  );
  const submitSectionEdit = useCallback(
    (application: IApplication) => {
      cancelSectionEdit();
      update(application, {});
    },
    [update, cancelSectionEdit]
  );

  const saveContact = useCallback(
    (contact: Contact, contactIndex: number) => {
      if (!application || !application.contacts) {
        return;
      }
      const appContacts = application.contacts.map((existingContact, index) => {
        return index === contactIndex
          ? {
              ...contact,
            }
          : existingContact;
      });
      update(
        { ...application, contacts: appContacts },
        {
          onUpdate: handleSectionEdit(undefined),
        }
      );
    },
    [update, handleSectionEdit, application]
  );

  const contacts = application?.contacts?.map((contact, index) => ({
    ...contact,
    id: `${contact.type?.replace(/\s/g, "").toLowerCase()}-${index}`,
    contactIndex: index,
  }));

  const legalRepresentants = useMemo(
    () => contacts?.filter((contact) => contact.type?.toLowerCase() === LEGAL_REPRESENTANT),
    [contacts]
  );

  const authorizedPersons = useMemo(
    () => contacts?.filter((contact) => contact.type?.toLowerCase() === AUTHORIZED_PERSON),
    [contacts]
  );

  const beneficiaries = useMemo(
    () => contacts?.filter((contact) => contact.type?.toLowerCase() === BENEFICIARY),
    [contacts]
  );

  const handleContactCollapseToggle = useCallback(
    (contactId: string) => () => {
      const contactIds = collapsedContactsIds.includes(contactId)
        ? collapsedContactsIds.filter((id) => id !== contactId)
        : [...collapsedContactsIds, contactId];

      setCollapsedContactsIds(contactIds);
    },
    [collapsedContactsIds]
  );

  if (loading) {
    return <Spinner />;
  }

  if (!application) {
    return null;
  }

  return (
    <>
      <Box pb="80px">
        <PageHeader>
          <Heading type="h1">{t("application:Application preview")}</Heading>
          <Flex w="100%">
            <StyledBackButton onClick={() => history.push("/applications")}>
              <ArrowLeftIcon mr="10px" />
              {t("application:Applications")}
            </StyledBackButton>
          </Flex>
        </PageHeader>
        <ApplicationThreeColumnsContainer>
          {/* FIRST COLUMN */}
          <div>
            <Grid rowGap="20px">
              <ActiveUserBorder collection="applications" id={id} sections={`edit/${ApplicationSections.companyInfo}`}>
                <EditBox
                  data={application}
                  disabled={editedSection && editedSection !== ApplicationSections.companyInfo}
                  editView={EditCompanyInfo}
                  errors={errors}
                  isEditing={editedSection === ApplicationSections.companyInfo}
                  name={t("application:Company info")}
                  onCancel={cancelSectionEdit}
                  onEdit={handleSectionEdit(ApplicationSections.companyInfo)}
                  onSave={submitSectionEdit}
                >
                  <CompanyInfo application={application} />
                </EditBox>
              </ActiveUserBorder>

              <ActiveUserBorder collection="applications" id={id} sections={`edit/${ApplicationSections.details}`}>
                <EditBox
                  data={application}
                  disabled={editedSection && editedSection !== ApplicationSections.details}
                  editView={EditDetails}
                  errors={errors}
                  isEditing={editedSection === ApplicationSections.details}
                  name={t("application:Details")}
                  onCancel={cancelSectionEdit}
                  onEdit={handleSectionEdit(ApplicationSections.details)}
                  onSave={submitSectionEdit}
                >
                  <Details application={application} />
                </EditBox>
              </ActiveUserBorder>

              <ActiveUserBorder collection="applications" id={id} sections={`edit/${ApplicationSections.bankAccount}`}>
                <EditBox
                  data={application}
                  disabled={editedSection && editedSection !== ApplicationSections.bankAccount}
                  editView={EditBankAccount}
                  editViewProps={{
                    onAccountRemove: handleRemove("bankAccounts"),
                  }}
                  errors={errors}
                  isEditing={editedSection === ApplicationSections.bankAccount}
                  name={({ handleChange, data }) => (
                    <>
                      {t("application:Bank account")}
                      {editedSection === ApplicationSections.bankAccount && (
                        <Button
                          color="sk-purple"
                          design="link"
                          fontWeight="bold"
                          height="auto"
                          letterSpacing={0}
                          onClick={() =>
                            handleAdd("bankAccounts")(emptyBankAccount, {
                              handler: handleChange,
                              data,
                            })
                          }
                          px={0}
                        >
                          <AddIcon mr="10px" />
                          {t("company:Add new account")}
                        </Button>
                      )}
                    </>
                  )}
                  onCancel={cancelSectionEdit}
                  onEdit={handleSectionEdit(ApplicationSections.bankAccount)}
                  onSave={submitSectionEdit}
                >
                  <BankAccount application={application} />
                </EditBox>
              </ActiveUserBorder>
            </Grid>
          </div>

          {/* SECOND COLUMN */}
          <div>
            <Grid rowGap="20px">
              <ActiveUserBorder
                collection="applications"
                id={id}
                sections={`edit/${ApplicationSections.companyAddress}`}
              >
                <EditBox
                  data={application}
                  disabled={editedSection && editedSection !== ApplicationSections.companyAddress}
                  editView={EditCompanyAddress}
                  errors={errors}
                  isEditing={editedSection === ApplicationSections.companyAddress}
                  name={t("application:Company address")}
                  onCancel={cancelSectionEdit}
                  onEdit={handleSectionEdit(ApplicationSections.companyAddress)}
                  onSave={submitSectionEdit}
                >
                  <CompanyAddress application={application} />
                </EditBox>
              </ActiveUserBorder>

              <ActiveUserBorder
                collection="applications"
                id={id}
                sections={`edit/${ApplicationSections.contactAddress}`}
              >
                <EditBox
                  data={application}
                  disabled={editedSection && editedSection !== ApplicationSections.contactAddress}
                  editView={EditContactAddress}
                  errors={errors}
                  isEditing={editedSection === ApplicationSections.contactAddress}
                  name={t("application:Contact address")}
                  onCancel={cancelSectionEdit}
                  onEdit={handleSectionEdit(ApplicationSections.contactAddress)}
                  onSave={submitSectionEdit}
                >
                  <ContactAddress application={application} />
                </EditBox>
              </ActiveUserBorder>
            </Grid>
          </div>

          {/* SECOND COLUMN */}
          <div>
            <Grid rowGap="20px">
              <ActiveUserBorder
                collection="applications"
                id={id}
                sections={`edit/${ApplicationSections.legalRepresenstants}`}
              >
                <StyledContainer disabled={editedSection && editedSection !== ApplicationSections.legalRepresenstants}>
                  <StyledBoxName>{t("application:Legal representants")}</StyledBoxName>
                  <StyledBoxContent layout="single-column">
                    {legalRepresentants && legalRepresentants.length > 0
                      ? legalRepresentants.map((contact) => (
                          <CollapsableEditBox
                            collapseHeader={
                              <StyledCollapseHeader>
                                <StyledName>
                                  {contact.firstName} {contact.lastName}
                                </StyledName>
                                <ConfirmationTag contact={contact} />
                              </StyledCollapseHeader>
                            }
                            data={application.contacts && application.contacts[contact.contactIndex]}
                            disabled={
                              editedSection === ApplicationSections.legalRepresenstants &&
                              editedContactId !== contact.id
                            }
                            editView={EditSingleContact}
                            errors={errors}
                            isEditing={
                              editedSection === ApplicationSections.legalRepresenstants &&
                              editedContactId === contact.id
                            }
                            isExpanded={!collapsedContactsIds.includes(contact.id)}
                            key={contact.id}
                            onCancel={cancelSectionEdit}
                            onCollapseToggle={handleContactCollapseToggle(contact.id)}
                            onEdit={handleSectionEdit(ApplicationSections.legalRepresenstants, contact.id)}
                            onSave={(data) => saveContact(data, contact.contactIndex)}
                          >
                            <SingleContact contact={contact} />
                          </CollapsableEditBox>
                        ))
                      : "-"}
                  </StyledBoxContent>
                </StyledContainer>
              </ActiveUserBorder>
              <ActiveUserBorder
                collection="applications"
                id={id}
                sections={`edit/${ApplicationSections.authorizedPersons}`}
              >
                <StyledContainer disabled={editedSection && editedSection !== ApplicationSections.authorizedPersons}>
                  <StyledBoxName>{t("application:Authorized persons")}</StyledBoxName>
                  <StyledBoxContent layout="single-column">
                    {authorizedPersons && authorizedPersons.length > 0
                      ? authorizedPersons.map((contact) => (
                          <CollapsableEditBox
                            collapseHeader={
                              <StyledCollapseHeader>
                                <StyledName>
                                  {contact.firstName} {contact.lastName}
                                </StyledName>
                              </StyledCollapseHeader>
                            }
                            data={application.contacts && application.contacts[contact.contactIndex]}
                            disabled={
                              editedSection === ApplicationSections.authorizedPersons && editedContactId !== contact.id
                            }
                            editView={EditSingleContact}
                            errors={errors}
                            isEditing={
                              editedSection === ApplicationSections.authorizedPersons && editedContactId === contact.id
                            }
                            isExpanded={!collapsedContactsIds.includes(contact.id)}
                            key={contact.id}
                            onCancel={cancelSectionEdit}
                            onCollapseToggle={handleContactCollapseToggle(contact.id)}
                            onEdit={handleSectionEdit(ApplicationSections.authorizedPersons, contact.id)}
                            onSave={(data) => saveContact(data, contact.contactIndex)}
                          >
                            <SingleContact contact={contact} />
                          </CollapsableEditBox>
                        ))
                      : "-"}
                  </StyledBoxContent>
                </StyledContainer>
              </ActiveUserBorder>
              <ActiveUserBorder
                collection="applications"
                id={id}
                sections={`edit/${ApplicationSections.beneficiaries}`}
              >
                <StyledContainer disabled={editedSection && editedSection !== ApplicationSections.beneficiaries}>
                  <StyledBoxName>{t("application:Beneficiaries")}</StyledBoxName>
                  <StyledBoxContent layout="single-column">
                    {beneficiaries && beneficiaries.length > 0
                      ? beneficiaries.map((contact) => (
                          <CollapsableEditBox
                            collapseHeader={
                              <StyledCollapseHeader>
                                <StyledName>
                                  {contact.firstName} {contact.lastName}
                                </StyledName>
                              </StyledCollapseHeader>
                            }
                            data={application.contacts && application.contacts[contact.contactIndex]}
                            disabled={
                              editedSection === ApplicationSections.beneficiaries && editedContactId !== contact.id
                            }
                            editView={EditSingleContact}
                            editViewOptions={{
                              showAddress: true,
                              showOther: true,
                              showCitizenship: true,
                              hidePersonalDetails: true,
                            }}
                            errors={errors}
                            isEditing={
                              editedSection === ApplicationSections.beneficiaries && editedContactId === contact.id
                            }
                            isExpanded={!collapsedContactsIds.includes(contact.id)}
                            key={contact.id}
                            onCancel={cancelSectionEdit}
                            onCollapseToggle={handleContactCollapseToggle(contact.id)}
                            onEdit={handleSectionEdit(ApplicationSections.beneficiaries, contact.id)}
                            onSave={(data) => saveContact(data, contact.contactIndex)}
                          >
                            <SingleContact
                              contact={contact}
                              hidePersonalDetails
                              showAddress
                              showCitizenship
                              showOther
                            />
                          </CollapsableEditBox>
                        ))
                      : "-"}
                  </StyledBoxContent>
                </StyledContainer>
              </ActiveUserBorder>
            </Grid>
          </div>
        </ApplicationThreeColumnsContainer>
        <Footer>
          {t("application:Accept application")}
          <Button design="danger" ml="auto" mr="20px" onClick={() => setIsDeleteModalOpen(true)}>
            {t("Delete")}
          </Button>
          <Button design="secondary" ml="auto" mr="20px" onClick={goToApplicationsList}>
            {t("Back")}
          </Button>
          <Button design="primary" onClick={() => setIsConfirmed(true)}>
            {t("Accept")}
          </Button>
        </Footer>
      </Box>
      <Modal
        confirmText={t("Accept")}
        design="danger"
        isOpen={isConfirmed}
        onClose={() => setIsConfirmed(false)}
        onConfirm={handleApplicationApprove}
        title={t("application:Accept application")}
      >
        <Box mb={"20px"}>{t("application:Are you sure you want to accept this application?")}</Box>
      </Modal>
      <Modal
        confirmText={t("application:Yes, delete it")}
        design="danger"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleApplicationRemove}
        title={t(`application:Delete "{{name}}" application`, {
          name: application?.name,
        })}
      >
        <Box mb={"20px"}>
          {t("application:This action can't be undone. Are you sure you want to delete this application?")}
        </Box>
      </Modal>
    </>
  );
};
