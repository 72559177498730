import React, { useCallback, useState } from "react";
import { Modal } from "components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useMarginCall } from "hooks/useMarginCall";

export function MarginCallClose() {
  const { close } = useMarginCall();
  const history = useHistory();
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId: string;
  }>();

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
  }, [history, id]);

  const closeMarginCall = useCallback(async () => {
    setIsSending(true);

    const success = await close(id, marginCallId);

    if (success) {
      handleClose();
    }
  }, [close, handleClose, id, marginCallId]);

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={closeMarginCall}
      title={t(`marginCall:Close Margin Call {{marginCallId}}`, {
        marginCallId,
      })}
    >
      <Box mb={"20px"}>
        {t(`marginCall:This action can't be undone. Are you sure you want to close Margin Call {{marginCallId}}?`, {
          marginCallId,
        })}
      </Box>
    </Modal>
  );
}
