import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MarginMoveModal } from "./MarginMoveModal";

export function MarginMove() {
  const { id, marginId } = useParams<{
    id: string;
    marginId: string;
  }>();

  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <MarginMoveModal handleClose={handleClose} marginId={marginId} transactionId={id} />;
}
