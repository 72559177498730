import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "hooks/useTransaction";
import { useToast } from "hooks/useToast";
import { HedgeFormModal } from "./HedgeFormModal/HedgeFormModal";
import { getQuantityLeft } from "helpers/transaction";
import { sumLQtyOfHedges } from "helpers/hedge";
import { Hedge } from "interfaces/data";

export function HedgeEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, hedgeId } = useParams<{ id: string; hedgeId: string }>();
  const { transaction, hedgeUpdate, hedgeErrors } = useTransaction({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newHedge: Partial<Hedge>) => {
      setIsSending(true);
      hedgeUpdate(newHedge as Hedge)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("hedge:Hedge has been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`hedge:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hedgeUpdate, history, id]
  );

  const editedHedge = useMemo(() => {
    if (transaction) {
      return transaction.hedges?.find((hedge) => hedge.id === hedgeId);
    }
  }, [hedgeId, transaction]);

  const maxQuantity = useMemo(() => {
    if (!transaction || !editedHedge) return 0;
    return getQuantityLeft(transaction) - sumLQtyOfHedges(transaction.hedges) + Number(editedHedge.quantity);
  }, [transaction, editedHedge]);

  if (!transaction || !editedHedge) {
    return null;
  }

  return (
    <HedgeFormModal
      errors={hedgeErrors}
      initialHedge={editedHedge}
      isDisabled={isSending}
      maxQuantity={maxQuantity}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t(`hedge:Edit hedge {{hedgeId}}`, { hedgeId: hedgeId })}
      transaction={transaction}
      type="edit"
    />
  );
}
