import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../components/Tabs/Tabs";
import { CompaniesTab } from "./CompaniesTab/CompaniesTab";
import { SourcesTab } from "./SourcesTab/SourcesTab";
import { CommissionFeesTab } from "./CommissionFeesTab/CommissionFeesTab";
import { Heading } from "../../components/Heading/Heading";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { useAuth } from "../../hooks/useAuth";
import { ADD_COMMISSION_FEE, hasAccess } from "../../helpers/userRoles";

export const StyledSearchContainer = styled.div`
  width: 100%;
  max-width: 684px;
  padding-top: 30px;
`;

export const StyledPanel = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background-color: white;
  padding-bottom: 14px;
  min-height: 300px;
`;

export const CommissionFees = () => {
  const { t } = useTranslation();
  const { tab: paramTab } = useParams<{ tab: string }>();
  const history = useHistory();
  const { profile } = useAuth();

  const tabs = useMemo(
    () =>
      [
        {
          name: t("commissionFee:Settlements"),
          url: "companies",
          component: <CompaniesTab key="companies" />,
        },
        {
          name: t("commissionFee:Sources"),
          url: "sources",
          component: <SourcesTab key="sources" />,
        },
        {
          name: t("commissionFee:Commission fees"),
          url: "fees",
          component: <CommissionFeesTab key="fees" />,
        },
      ].filter(({ url }) => {
        if (["sources", "companies"].includes(url)) {
          return true;
        }

        return hasAccess(profile?.role, ADD_COMMISSION_FEE);
      }),
    [t, profile]
  );

  const [tabIndex, setTabIndex] = useState(() => {
    const tabIndex = tabs.findIndex((tab) => tab.url === paramTab);
    return tabIndex !== -1 ? tabIndex : 0;
  });

  const handleTabsChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  useEffect(() => {
    history.replace(`/commission-fees/${tabs[tabIndex].url}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("commissionFee:Commission fees")}</Heading>
      </PageHeader>
      <Tabs handleTabsChange={handleTabsChange} tabIndex={tabIndex} tabs={tabs.map((tab) => tab.name)}>
        {tabs.map((tab, index) =>
          index === tabIndex ? <StyledPanel key={index}>{tab.component}</StyledPanel> : <div key={index}></div>
        )}
      </Tabs>
    </>
  );
};
