import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ReverseIcon = createIcon({
  displayName: "ReverseIcon",
  path: (
    <g fill="none">
      <path
        d="M11.1255 9C10.7584 9.94196 10.1388 10.7645 9.33473 11.3773C8.53065 11.9901 7.57323 12.3694 6.56764 12.4736C5.56205 12.5777 4.54721 12.4027 3.6346 11.9678C2.72199 11.5328 1.94692 10.8547 1.39453 10.008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 1V6L7.5 5.5L12 1Z" fill="currentColor" />
      <path
        d="M0.875 4.99992C1.2421 4.05796 1.86173 3.2354 2.66581 2.62261C3.46988 2.00982 4.42731 1.63051 5.43289 1.52635C6.43848 1.42218 7.45332 1.59719 8.36593 2.03216C9.27855 2.46712 10.0536 3.1452 10.606 3.99192"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M0 13V8L4.5 8.5L0 13Z" fill="currentColor" />
    </g>
  ),
  viewBox: "0 0 12 14",
});
