import useAlgolia from "hooks/useAlgolia";
import { COMPANIES_INDEX_NAME, CONTACTS_INDEX_NAME, TRANSACTIONS_INDEX_NAME } from "helpers/algolia";
import {
  StyledSearchResults,
  StyledSearchResultsContainer,
  StyledSearchResultsSection,
  StyledSectionHeader,
} from "./GlobalSearch.styled";
import React from "react";
import { SearchResult } from "./SearchResult";
import { DISPLAY_DATE_FORMAT } from "helpers/date";
import dayjs from "dayjs";

interface TransactionSearchResult {
  company: {
    name: string;
    id: string;
  };
  dealType: string;
  lastmodified: number;
  number: string;
  status: string;
  objectID: string;
  path: string;
  type: string;
  end: number;
}

interface CompanySearchResult {
  companyAddress: any;
  lastmodified: number;
  legalEntity: string;
  name: string;
  objectID: string;
  path: string;
  regon: string;
  contacts: {
    name: string;
    email: string;
    phone: string;
  }[];
}

interface ContactSearchResult {
  objectID: string;
  firstName: string;
  lastName: string;
  email: string;
  companyAssociated: {
    id: string;
    name: string;
  };
  lastmodified: number;
  status: string;
  phone: string;
  path: string;
}

export const AlgoliaSearchResults: React.FC<{ query: string; closeGlobalSearch: () => void }> = ({
  query,
  closeGlobalSearch,
}) => {
  const { results: transactions } = useAlgolia<TransactionSearchResult>({
    indexName: TRANSACTIONS_INDEX_NAME,
    query,
    enabled: !!query,
  });

  const { results: companies } = useAlgolia<CompanySearchResult>({
    indexName: COMPANIES_INDEX_NAME,
    query,
    enabled: !!query,
  });

  const { results: contacts } = useAlgolia<ContactSearchResult>({
    indexName: CONTACTS_INDEX_NAME,
    query,
    enabled: !!query,
  });

  return (
    <StyledSearchResultsContainer>
      <StyledSearchResultsSection>
        {Boolean(!transactions?.length && !companies?.length && !contacts?.length) && (
          <StyledSectionHeader>No results found</StyledSectionHeader>
        )}
        {Boolean(companies?.length) && (
          <>
            <StyledSectionHeader>Companies</StyledSectionHeader>
            <StyledSearchResults>
              {companies?.map((company) => (
                <SearchResult
                  key={company.objectID}
                  leftSecondayContent={[
                    company.contacts?.length > 0
                      ? `${company.contacts[0].name} ${
                          company.contacts.length > 1 ? `+${company.contacts.length - 1} more` : ""
                        }`
                      : "",
                  ]}
                  onClick={closeGlobalSearch}
                  title={`${company.name}`}
                  url={`/${company.path}`}
                />
              ))}
            </StyledSearchResults>
          </>
        )}
        {Boolean(transactions?.length) && (
          <>
            <StyledSectionHeader>Transactions</StyledSectionHeader>
            <StyledSearchResults>
              {transactions?.map((transaction) => (
                <SearchResult
                  key={transaction.objectID}
                  leftSecondayContent={[transaction.company.name]}
                  onClick={closeGlobalSearch}
                  rightSecondayContent={[transaction.type, dayjs(transaction.end).format(DISPLAY_DATE_FORMAT) ?? "-"]}
                  title={`${transaction.number}`}
                  url={`/${transaction.path}`}
                />
              ))}
            </StyledSearchResults>
          </>
        )}
        {Boolean(contacts?.length) && (
          <>
            <StyledSectionHeader>Contacts</StyledSectionHeader>
            <StyledSearchResults>
              {contacts?.map((contact) => (
                <SearchResult
                  key={contact.objectID}
                  leftSecondayContent={[`${contact.email}`]}
                  onClick={closeGlobalSearch}
                  rightSecondayContent={[contact.companyAssociated?.name]}
                  title={`${contact.firstName} ${contact.lastName}`}
                  url={`/${contact.path}`}
                />
              ))}
            </StyledSearchResults>
          </>
        )}
      </StyledSearchResultsSection>
    </StyledSearchResultsContainer>
  );
};
