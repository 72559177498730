import styled from "@emotion/styled";

export const StyledNavigationItemContainer = styled.div<{
  isActive?: boolean;
}>`
  display: flex;
  padding: 12px 20px;
  align-items: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors["sk-gray"]};
  transition: background-color 0.15s ease-in;
  &:hover {
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
  ${(props) =>
    props.isActive &&
    `
    background: ${props.theme.colors["sk-purple"]} !important;
    color: white;
    svg path {
      fill: white;
    }
  `};
`;

export const StyledNavigationText = styled.span`
  flex: 1 1 auto;
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

export const StyledNavigationGroupContainer = styled.div`
  margin-bottom: ${(props) => props.theme.space[8]};
`;
