import React, { PropsWithChildren, useCallback } from "react";
import { useImmer } from "use-immer";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button/Button";
import _set from "lodash.set";
import { useHotkeys } from "hooks/useHotkeys";
import { RoleAreas } from "helpers/userRoles";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { EditIcon } from "theme/icons/EditIcon";

type NameFunction = ({ handleChange, data }: { handleChange: any; data: any }) => React.ReactNode;

interface EditBoxProps extends PropsWithChildren {
  data: any;
  name: React.ReactNode | NameFunction;
  layout?: "single-column" | "two-columns";
  isEditing?: boolean;
  disabled?: boolean;
  onEdit?: () => void;
  editView: any;
  onSave: (newData: any) => void;
  onCancel: (initialData: any) => void;
  errors: {
    [k: string]: string;
  };
  editViewProps?: object;
  isEditDisabled?: boolean;
  restrict?: RoleAreas;
}

export interface EditViewProps<T> {
  data: T;
  handleChange: any;
  errors: {
    [k: string]: string;
  };
}

export const StyledContainer = styled.div<{ disabled?: boolean }>`
  background: white;
  padding: 30px;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export const StyledBoxHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
`;

export const StyledBoxName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledEditButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  margin-top: 3px;
  margin-left: auto;
  white-space: nowrap;
  svg {
    margin-right: 10px;
    margin-top: -5px;
  }
`;

export const StyledBoxContent = styled.div<{ layout?: EditBoxProps["layout"] }>`
  display: ${(props) => (props.layout === "single-column" ? "block" : "flex")};
  flex-wrap: wrap;
  > div {
    flex-shrink: 0;
    flex-grow: 1;
    width: ${(props) => (props.layout === "single-column" ? "100%" : "50%")};
  }
  > div:last-of-type {
    margin-bottom: 0;
  }
`;

export const EditBox: React.FunctionComponent<EditBoxProps> = ({
  name,
  errors,
  layout = "single-column",
  isEditing,
  disabled,
  onEdit,
  editView: EditView,
  children,
  onSave,
  onCancel,
  data,
  editViewProps = {},
  isEditDisabled = false,
  restrict,
}) => {
  const { t } = useTranslation();
  const [editedData, setEditedData] = useImmer<any>(data);
  const [initialData] = useImmer<any>(data);

  const handleUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checkbox?: boolean) => {
      if (typeof e.persist === "function") {
        e.persist();
      }

      if (!e.target.name) {
        setEditedData(() => e.target.value);
        return;
      }

      const value = checkbox ? e.target.checked : e.target.value !== undefined ? e.target.value : e.target.checked;
      setEditedData((oldEditedData: any) => _set(oldEditedData, e.target.name, value));
    },
    [setEditedData]
  );

  const handleCancel = useCallback(() => {
    onCancel(initialData);
    setEditedData(() => initialData);
  }, [initialData, setEditedData, onCancel]);

  const handleEscapeKey = useCallback(() => {
    if (isEditing) {
      handleCancel();
    }
  }, [isEditing, handleCancel]);

  useHotkeys("Escape", handleEscapeKey);

  return (
    <StyledContainer disabled={disabled || isEditDisabled}>
      <StyledBoxHeader>
        <StyledBoxName>
          {typeof name === "function" ? name({ handleChange: handleUpdate, data: editedData }) : name}
        </StyledBoxName>
        <RestrictAccess area={restrict}>
          {!isEditing && !isEditDisabled && (
            <StyledEditButton onClick={onEdit}>
              <EditIcon />
              {t("Edit")}
            </StyledEditButton>
          )}
        </RestrictAccess>
      </StyledBoxHeader>
      <StyledBoxContent layout={layout}>
        {isEditing ? (
          <>
            <EditView data={editedData} errors={errors} handleChange={handleUpdate} {...editViewProps} />
            <Flex mt="30px">
              <Button design="secondary" ml="auto" mr="20px" onClick={handleCancel}>
                {t("Cancel")}
              </Button>
              <Button design="primary" onClick={() => onSave(editedData)}>
                {t("Save changes")}
              </Button>
            </Flex>
          </>
        ) : (
          children
        )}
      </StyledBoxContent>
    </StyledContainer>
  );
};
