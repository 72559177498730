import { useCallback, useState } from "react";
import { useFirebase } from "./useFirebase";
import { Activity, Log } from "../interfaces/data";
import firebaseApp from "firebase/app";

const COLLECTION = "logs";

export const useLogs = () => {
  const [logs, setLogs] = useState<Array<Log>>();
  const [firstLogDoc, setFirstLogDoc] = useState<any>();
  const [lastLogDoc, setLastLogDoc] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const { db, timestamp } = useFirebase();

  const fetch = useCallback(
    async ({
      startDate,
      endDate,
      userId,
      companyId,
      limit,
      startAfter,
      endBefore,
      action,
    }: {
      startDate?: Date;
      endDate?: Date;
      userId?: string;
      companyId?: string;
      limit: number;
      startAfter?: Date;
      endBefore?: Date;
      action?: string;
    }) => {
      const logs = [] as Array<Log>;
      const collection = db.collection(COLLECTION).orderBy("timestamp", "desc");

      const startDateCollection = startDate ? collection.where("timestamp", ">", startDate) : collection;

      const endDateCollection = endDate ? startDateCollection.where("timestamp", "<", endDate) : startDateCollection;

      const userIdCollection = userId ? endDateCollection.where("user.userId", "==", userId) : endDateCollection;

      const companyIdCollection = companyId ? userIdCollection.where("company.id", "==", companyId) : userIdCollection;

      const actionCollection = action ? companyIdCollection.where("action", "==", action) : companyIdCollection;

      const limitedCollection = endBefore ? actionCollection.limitToLast(limit) : actionCollection.limit(limit);

      const startAfterCollection = startAfter ? limitedCollection.startAfter(startAfter) : limitedCollection;

      const endBeforeCollection = endBefore ? startAfterCollection.endBefore(endBefore) : startAfterCollection;

      const snap = await endBeforeCollection.get();

      if (snap) {
        const first = snap.docs[0];
        const last = snap.docs[snap.docs.length - 1];

        snap.forEach((doc: any) => {
          const newLog = doc.data();
          logs.push({ ...newLog, id: doc.id });
        });

        setFirstLogDoc(first);
        setLastLogDoc(last);
        setLogs(logs);

        // Check if has prev and next pages
        if (first) {
          const prevSnap = await actionCollection.endBefore(first).get();
          setHasPrev(prevSnap.docs.length > 0);
        } else {
          setHasPrev(false);
        }
        if (last) {
          const nextSnap = await actionCollection.startAfter(last).get();
          setHasNext(nextSnap.docs.length > 0);
        } else {
          setHasNext(false);
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const log = useCallback(
    async (newLog: Activity) => {
      const currentUser = firebaseApp.auth().currentUser;
      if (!currentUser) return false;

      setLoading(true);
      const collection = db.collection(COLLECTION);

      const userSnap = await db.collection("profiles").where("userId", "==", String(currentUser.uid)).limit(1).get();

      const user = userSnap.docs[0].data();

      return collection
        .add({
          ...newLog,
          user,
          timestamp: timestamp(),
        })
        .then(() => {
          setLoading(false);
        })
        .catch((e) => {
          console.error("useLogs", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    logs,
    firstLogDoc,
    lastLogDoc,
    hasPrev,
    hasNext,
    loading,
    fetch,
    log,
  };
};
