import firebase from "firebase";

const Timestamp = firebase.firestore.Timestamp;

interface MapWithDashes {
  _seconds: number;
  _nanoseconds: number;
}

interface MapWithoutDashes {
  seconds: number;
  nanoseconds: number;
}

export const timestampFromMap = (date: MapWithDashes | MapWithoutDashes | firebase.firestore.Timestamp) => {
  if (date instanceof Timestamp) return date;
  if ("_seconds" in date && "_nanoseconds" in date) {
    return new Timestamp(date._seconds, date._nanoseconds);
  } else if ("seconds" in date && "nanoseconds" in date) {
    return new Timestamp(date.seconds, date.nanoseconds);
  }
  throw new Error("Invalid date");
};

/**
 * Converts date to Firebase Timestamp.
 * If it's undefined, returns undefined.
 * @param date
 */
export const convertToTimestamp = (date?: MapWithDashes | MapWithoutDashes | firebase.firestore.Timestamp) => {
  if (!date) return undefined;
  return timestampFromMap(date);
};

export const timestampFromDate = (date: Date) => {
  return Timestamp.fromDate(date);
};
