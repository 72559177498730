import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DEAL_TYPE_SELL, TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import { useFirebase } from "hooks/useFirebase";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { BaseTransaction, Transaction, TransactionStatus, TransactionType } from "interfaces/data";
import { TransactionFormModal } from "../TransactionFormModal/TransactionFormModal";
import _omit from "lodash.omit";
import { TransactionConfirm } from "../TransactionConfirm/TransactionConfirm";
import { mapTransactionTypeToEmailType, useMail } from "hooks/useMail";

export function TransactionAdd() {
  const { timestamp } = useFirebase();
  const { t } = useTranslation();
  const history = useHistory();
  const toast = useToast();
  const [sendEmail, setSendEmail] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState<BaseTransaction>();
  const [transactionType, setTransactionType] = useState(TRANSACTION_TYPES.FIXED.value);
  const { save, errors, validate } = useTransaction({
    skipFetching: true,
  });
  const sendConfirmation = useMail();

  const handleClose = useCallback(() => {
    history.push("/transactions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmSave = useCallback(
    (transaction: DeepPartial<Transaction>) => {
      validate(transaction).then((isValid) => {
        if (isValid) {
          setNewTransaction(transaction as Transaction);
          setIsPopupOpen(true);
        }
      });
    },
    [validate]
  );

  const handleSave = useCallback(
    (transaction: BaseTransaction) => {
      setIsSending(true);

      if (transaction.account === undefined) {
        delete transaction.account;
      }

      save(transaction)
        .then((transactionId) => {
          if (transactionId) {
            toast({
              type: "success",
              message: t("transaction:Transaction has been added successfully."),
            });

            if (sendEmail) {
              sendConfirmation(mapTransactionTypeToEmailType(transaction.type), String(transactionId));
            }

            return history.push(`/transactions/${transactionId}`);
          }
        })
        .catch((error) =>
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          })
        )
        .finally(() => setIsSending(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save, handleClose, toast, t]
  );

  const prepareTransaction = (transaction?: BaseTransaction) => {
    if (!transaction) return;
    let clonedTransaction = { ...transaction };

    const omittedTransactionFields = {
      [TRANSACTION_TYPES.FIXED.value]: ["currencyDate", "expiration"],
      [TRANSACTION_TYPES.ORDER.value]: ["currencyDate"],
      [TRANSACTION_TYPES.SPOT.value]: ["expiration", "start", "end", "initialMargin", "additionalMargin"],
    }[transaction.type];

    clonedTransaction = _omit(clonedTransaction, omittedTransactionFields) as BaseTransaction;

    return clonedTransaction;
  };

  return (
    <>
      <TransactionFormModal
        errors={errors}
        initialTransaction={{
          initialMargin: 0,
          agreement: timestamp(),
          systemDate: timestamp(),
          start: timestamp(),
          from: {
            currency: "EUR",
          },
          to: {
            currency: "PLN",
          },
          currencyDate: timestamp(),
          dealType: DEAL_TYPE_SELL,
          type: TRANSACTION_TYPES.FIXED.value as TransactionType,
          status: TRANSACTION_STATUSES.CREATED.value as TransactionStatus,
        }}
        onClose={handleClose}
        onSave={confirmSave}
        sendEmail={sendEmail}
        sendEmailChange={setSendEmail}
        setTransactionType={setTransactionType}
        title={t("transaction:Transaction add")}
        transactionType={transactionType}
        type="add"
      />
      <TransactionConfirm
        isDisabled={isSending}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleSave}
        sendEmail={sendEmail}
        transaction={prepareTransaction(newTransaction)}
        transactionType={transactionType}
        type="add"
      />
    </>
  );
}
