import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const MarginMigrationIcon = createIcon({
  displayName: "MarginMigrationIcon",
  path: (
    <g fill="none">
      <path
        d="M15.8,4.4c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S15.8,2.3,15.8,4.4z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M22,14.2l-7,7l-3.4-0.1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M22,20.6v-6.5h-6.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M0.6,19.6c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8s-1.7-3.8-3.8-3.8S0.6,17.5,0.6,19.6z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M1,10.8l7-7l3.8,0.1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M1,4.3v6.5h6.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
  viewBox: "0 0 24 24",
});
