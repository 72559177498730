import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { Margin } from "interfaces/data";
import { useCompany } from "hooks/useCompany";
import { MarginFormModal } from "views/Margin/MarginFormModal/MarginFormModal";

export function MarginEditGlobal() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginId } = useParams<{
    id: string;
    marginId: string;
  }>();
  const toast = useToast();
  const { company, globalMarginUpdate, marginErrors } = useCompany({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editedMargin = useMemo(
    () => company?.globalMargins?.find((margin) => margin.id === marginId),
    [company, marginId]
  );

  const handleOnSaveAction = useCallback(
    (newMargin: Margin) => {
      setIsSending(true);
      globalMarginUpdate(newMargin)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("margin:Margin have been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        });
    },
    [globalMarginUpdate, handleClose, t, toast]
  );

  if (!editedMargin) return null;

  return (
    <MarginFormModal
      errors={marginErrors}
      initialMargin={editedMargin}
      isDisabled={isSending}
      isTypeLocked
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("margin:Edit margin {{marginId}}", { marginId: marginId })}
      type="edit"
    />
  );
}
