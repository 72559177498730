import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { NumberCell } from "helpers/tableCells";
import {
  getSumOfHedgesLeft,
  getSumOfInitialMargins,
  getSumOfVariableMargins,
  getWeightedAverageFromObject,
} from "helpers/transaction";
import { Transaction, WeightedAverages } from "interfaces/data";
import { Box, Link, Text } from "@chakra-ui/react";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
} from "./shared.styled";
import { formatNumberToString } from "helpers/formatNumber";

interface TopCompaniesProps {
  transactions: Array<Transaction>;
  nbpRates: any;
  transactionFilter?: {
    currency: string;
    dealType: string;
  };
}

export const TopCompanies: React.FC<TopCompaniesProps> = ({ transactions, nbpRates, transactionFilter }) => {
  const currencyFrom = transactionFilter?.currency.substring(0, 3);
  const { t } = useTranslation();
  const collapseSize = 8;
  const [isExpanded, setExpanded] = useState(false);

  function CompanyCell(arg: any) {
    return (
      <Link href={`/companies/${arg.value.id}`}>
        <Text as="span" fontSize="15px" fontWeight="900">
          {arg.value.name}
        </Text>
      </Link>
    );
  }

  const ValueCell = useCallback(
    (arg: any) => (
      <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
        {transactionFilter?.dealType === "Sell" && Number(arg.value) !== 0 && "- "} {formatNumberToString(arg.value)}
      </Text>
    ),
    [transactionFilter]
  );

  const transactionsInSelectedCurrency = useMemo(() => {
    if (!transactions || transactions.length === 0 || !transactionFilter) return [];
    return transactions.filter((transaction) => {
      const transactionCurrency = `${transaction.from.currency}/${transaction.to.currency}`;

      return transactionFilter.currency === transactionCurrency && transactionFilter.dealType === transaction.dealType;
    });
  }, [transactions, transactionFilter]);

  const topCompaniesData = useMemo(() => {
    if (!transactionsInSelectedCurrency || transactionsInSelectedCurrency.length === 0 || !transactionFilter) return [];

    // TODO creare array with IDs of all the companies that have transactions in selected currency
    const companiesIds = transactionsInSelectedCurrency
      .map((transaction) => {
        return { id: transaction.company.id, name: transaction.company.name };
      })
      .filter((v, i, a) => a.map((x) => x.id).indexOf(v.id) === i);

    // prepare object with companies as keys and counter objects as values
    const companiesData = companiesIds.reduce(
      (acc, curr) => (
        (acc[curr.id] = {
          company: { name: curr.name, id: curr.id },
          volume: 0,
          hedgedQty: 0,
          initialMargin: 0,
          variableMargin: 0,
          avg: [],
          // eslint-disable-next-line no-sequences
        }),
        acc
      ),
      {} as any
    );

    transactionsInSelectedCurrency.forEach((transaction) => {
      const hedgesLeft = Number(getSumOfHedgesLeft(transaction));
      // increase proper counters for given company
      companiesData[transaction.company.id].avg = [
        {
          weight: Number(transaction.clientRate),
          val: hedgesLeft,
        },
        ...companiesData[transaction.company.id].avg,
      ];

      companiesData[transaction.company.id].volume =
        Number(companiesData[transaction.company.id].volume) + Number(transaction.from.quantity);

      companiesData[transaction.company.id].hedgedQty =
        Number(companiesData[transaction.company.id].hedgedQty) + hedgesLeft;

      companiesData[transaction.company.id].initialMargin =
        Number(companiesData[transaction.company.id].initialMargin) +
        getSumOfInitialMargins(transaction) * Number(nbpRates.rates[currencyFrom || transactionFilter.currency]);

      companiesData[transaction.company.id].variableMargin =
        Number(companiesData[transaction.company.id].variableMargin) +
        Number(getSumOfVariableMargins(transaction, nbpRates)) *
          Number(nbpRates.rates[currencyFrom || transactionFilter.currency]);
    });

    return Object.values(companiesData).sort((companyDataA: any, companyDataB: any) =>
      companyDataA.hedgedQty > companyDataB.hedgedQty ? -1 : 1
    );
  }, [currencyFrom, nbpRates, transactionFilter, transactionsInSelectedCurrency]);

  const visibleTopCompaniesData = useMemo(() => {
    if (isExpanded) {
      return topCompaniesData;
    }
    return topCompaniesData.slice(0, collapseSize);
  }, [topCompaniesData, isExpanded]);

  const columns = useMemo(
    () => [
      {
        Header: t("risk:Company"),
        accessor: "company",
        Cell: CompanyCell,
      },
      {
        Header: t("risk:Avarage"),
        accessor: "avg",
        Cell: (arg) => (
          <Text as="span" whiteSpace="nowrap">
            {getWeightedAverageFromObject({
              values: arg.value.map((v: WeightedAverages) => v.val),
              weights: arg.value.map((w: WeightedAverages) => w.weight),
            })}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Hedged Qty.")}
          </Text>
        ),
        accessor: "hedgedQty",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Transactions volume")}
          </Text>
        ),
        accessor: "volume",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Initial margin")}
          </Text>
        ),
        accessor: "initialMargin",
        Cell: NumberCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Variation margin")}
          </Text>
        ),
        accessor: "variableMargin",
        Cell: NumberCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Total margin")}
          </Text>
        ),
        accessor: "totalMargin",
        Cell: (arg) => {
          const data = arg.row.original as any;
          return (
            <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
              {formatNumberToString(Number(data.initialMargin) + Number(data.variableMargin))}
            </Text>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, transactionFilter]
  );

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>
          {t("risk:Top companies")}{" "}
          {transactionFilter && `- ${transactionFilter.currency} (${transactionFilter.dealType})`}
        </StyledSectionName>
      </StyledSectionHeader>
      {transactionFilter ? (
        <>
          <Table columns={columns} data={visibleTopCompaniesData} paginated={false} />
          {topCompaniesData.length > collapseSize && (
            <StyledButtonContainer>
              <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
                {isExpanded ? t("Collapse") : t("Expand")}
              </StyledButton>
            </StyledButtonContainer>
          )}
        </>
      ) : (
        <Box fontSize="13px" m="30px">
          {t("risk:Please select currency to see top companies")}
        </Box>
      )}
    </StyledContainer>
  );
};
