import { StyledButtonContainer } from "./styles";
import { Button } from "components/Button/Button";
import React from "react";

export const ActiveClosedSwitch = (props: { showClosed: boolean; setShowClosed: (showClosed: boolean) => void }) => (
  <StyledButtonContainer>
    <Button
      borderRadius="12px"
      design={!props.showClosed ? "primary" : "ghost"}
      height="32px"
      mr="4px"
      onClick={() => props.setShowClosed(false)}
      opacity={!props.showClosed ? 1 : 0.5}
    >
      Active
    </Button>
    <Button
      borderRadius="12px"
      design={props.showClosed ? "primary" : "ghost"}
      height="32px"
      onClick={() => props.setShowClosed(true)}
      opacity={props.showClosed ? 1 : 0.5}
    >
      Closed
    </Button>
  </StyledButtonContainer>
);
