import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const PaymentsIcon = createIcon({
  displayName: "PaymentsIcon",
  path: (
    <g fill="none">
      <path
        d="M13.8 1.5H2.06667C1.78377 1.5 1.51246 1.61238 1.31242 1.81242C1.11238 2.01246 1 2.28377 1 2.56667V17.5L3.66667 15.3667L5.8 17.5L7.93333 15.3667L10.0667 17.5L12.2 15.3667L14.8667 17.5V2.56667C14.8667 2.28377 14.7543 2.01246 14.5542 1.81242C14.3542 1.61238 14.0829 1.5 13.8 1.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.19995 11.1001H7.39995"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.93325 7.9002C8.81691 7.9002 9.53325 7.18385 9.53325 6.3002C9.53325 5.41654 8.81691 4.7002 7.93325 4.7002C7.0496 4.7002 6.33325 5.41654 6.33325 6.3002C6.33325 7.18385 7.0496 7.9002 7.93325 7.9002Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.6668 11.1001H10.6001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 16 19",
});
