import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { Institution } from "../interfaces/data";
import { useValidation } from "./useValidation";
import { useLogs } from "./useLogs";
import { institutionSchema } from "../validations/institution";

const COLLECTION = "institutions";

export const useInstitutions = () => {
  const [institutions, setInstitutions] = useState<Array<Institution>>([]);
  const [loading, setLoading] = useState(true);
  const { validate, errors, clearErrors } = useValidation(institutionSchema);
  const { db } = useFirebase();
  const { log } = useLogs();

  const fetch = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      collection.get().then((snap: firestore.DocumentData) => {
        const institutions = [] as Array<Institution>;
        snap.forEach((doc: any) => {
          institutions.push({ id: doc.id, ...doc.data() });
        });
        setInstitutions(institutions);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const save = useCallback(
    async (newInstitution: Institution) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      const isValid = await validate(newInstitution);

      if (!isValid) {
        setLoading(false);
        return false;
      }
      return collection
        .add(newInstitution)
        .then(async () => {
          await log({
            action: "create",
            item: {
              collection: "institutions",
              id: newInstitution.name,
            },
            newData: JSON.stringify(newInstitution),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useInstitutions", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (newInstitution: Institution) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);
      const { id, ...data } = newInstitution;

      const isValid = await validate(data);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const oldInstitution = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.data() as Institution;
          }
        });

      return collection
        .doc(id)
        .update(data)
        .then(async () => {
          await log({
            action: "edit",
            item: {
              collection: "institutions",
              id: newInstitution.name,
            },
            oldData: JSON.stringify({ ...oldInstitution, id }),
            newData: JSON.stringify(newInstitution),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useInstitutions", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const remove = useCallback(
    async (institution: Institution) => {
      const collection = db.collection(COLLECTION);
      setLoading(true);

      return collection
        .doc(institution.id)
        .delete()
        .then(async () => {
          await log({
            action: "delete",
            item: {
              collection: "institutions",
              id: institution.name,
            },
            oldData: JSON.stringify(institution),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useInstitutions", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    institutions,
    refetch: fetch,
    loading,
    save,
    update,
    remove,
    errors,
    clearErrors,
  };
};
