import React, { ChangeEvent } from "react";
import { Box } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { CURRENCIES } from "helpers/options";
import { Button } from "components/Button/Button";
import { StyledWarning } from "../GlobalMarginCallMarginWithdrawModal";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "@chakra-ui/icons";

interface WithdrawQuantityProps {
  currency: string;
  amount: number;
  onWithdrawClick: () => void;
  onValueChange: (value: number) => void;
  isQuantityValid: boolean;
  isWithdrawValid: boolean;
}

export const WithdrawQuantity: React.FC<WithdrawQuantityProps> = ({
  amount,
  currency,
  isQuantityValid,
  isWithdrawValid,
  onValueChange,
  onWithdrawClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      {amount === 0 ? (
        <StyledWarning>
          <WarningIcon mr="8px" mt="-2px" />
          {t("This LVM cannot be withdrawn because it's connected with margin.")}
        </StyledWarning>
      ) : (
        <Box display="flex" mb="10px">
          <Input
            error={!isQuantityValid ? "error" : undefined}
            formatNumberOnBlur
            hideErrorMessage
            id="quantity"
            label="Quantity"
            name="quantity"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onValueChange(Number(e.target.value))}
            topLabel
            type="number"
            value={amount || ""}
            withError={!isQuantityValid}
          />
          <Box ml="10px">
            <AutoCompleteSelect
              id="from.currency"
              isDisabled
              name="from.currency"
              options={CURRENCIES}
              value={currency}
              width="85px"
            />
          </Box>
          <Button design="primary" height="42px" isDisabled={!isWithdrawValid} ml="30px" onClick={onWithdrawClick}>
            {t("Withdraw")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
