import React, { PropsWithChildren, ReactElement } from "react";
import { CloseButton, Drawer as ChakraDrawer, DrawerContent, DrawerFooter } from "@chakra-ui/react";
import styled from "@emotion/styled";

interface DrawerProps extends PropsWithChildren {
  title: string;
  isOpen: boolean;
  onClose(): void;
  footer?: ReactElement;
}

export const Drawer: React.FC<DrawerProps> = ({ title, isOpen, onClose, children, footer }) => (
  <ChakraDrawer isOpen={isOpen} onClose={onClose} placement="right">
    <DrawerContent maxW={392} p="50px 30px" w={392}>
      <HeaderSection>
        <HeaderTitle>{title}</HeaderTitle>
        <CloseButton onClick={onClose} />
      </HeaderSection>

      {children}

      {footer && (
        <DrawerFooter alignItems="center" display="flex" justifyContent="space-evenly" p="5px 0 0 0" w="100%">
          {footer}
        </DrawerFooter>
      )}
    </DrawerContent>
  </ChakraDrawer>
);

const HeaderSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const HeaderTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #252527;
`;
