import * as React from "react";
import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from "@chakra-ui/react";

import { StyledLabel, StyledSelectContainer } from "./Select.styled";
import { useTranslation } from "react-i18next";
import { chakraStyles } from "theme/chakraStyles";

export type SelectOption = {
  value?: string;
  label: string;
};

export interface SelectProps extends ChakraSelectProps {
  error?: string;
  label?: string;
  options: SelectOption[];
  id?: string;
  value?: any;
  name: string;
  onChange: any;
}

export const Select = ({ label, options, error, ...rest }: SelectProps): React.ReactElement => {
  const { t } = useTranslation();
  const hasError = !!error;

  return (
    <StyledSelectContainer>
      <StyledLabel>{label}</StyledLabel>
      <ChakraSelect
        _disabled={chakraStyles.disabled}
        borderColor="sk-light-gray"
        borderRadius="base"
        errorBorderColor="sk-red"
        fontSize="xs-sm"
        fontWeight="semibold"
        height="42px"
        isInvalid={hasError}
        pr="0.25rem"
        {...rest}
      >
        {rest.value === null && <option>{t("Nothing selected")}</option>}
        {options.map(({ value, label }) => (
          <option key={`select-${value}`} value={value}>
            {label}
          </option>
        ))}
      </ChakraSelect>
    </StyledSelectContainer>
  );
};
