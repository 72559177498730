import React, { useCallback, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/Modal/Modal";
import { useProfiles } from "../../../hooks/useProfiles";
import { useToast } from "../../../hooks/useToast";
import { Profile } from "../../../interfaces/data";

interface DeleteUserProps {
  user: Profile;
  onClose: () => any;
  onSuccess: () => any;
}

export const DeleteUser: React.FC<DeleteUserProps> = ({ user, onClose, onSuccess }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();
  const { remove } = useProfiles();

  const toast = useToast();

  const handleConfirm = useCallback(async () => {
    if (!user) return;
    setIsDisabled(true);
    const fullName = `${user.name} ${user.surname}`;
    const result = await remove(user);
    if (result) {
      toast({
        type: "success",
        message: t("settings:User {{name}} has been deleted successfully.", {
          name: fullName,
        }),
      });
      onSuccess();
    } else {
      toast({
        type: "error",
        message: t("settings:User {{name}} could not be deleted.", {
          name: fullName,
        }),
      });
      onClose();
    }
    setIsDisabled(false);
  }, [user, remove, toast, t, onSuccess, onClose]);

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isDisabled}
      isOpen
      onClose={onClose}
      onConfirm={handleConfirm}
      title={t(`settings:Delete user`)}
    >
      <Box mb={"20px"}>
        {t(`settings:Are you sure you want to delete user {{name}}?`, {
          name: `${user?.name} ${user?.surname}`,
        })}
      </Box>
    </Modal>
  );
};
