import React, { useCallback, useMemo, useState } from "react";
import { InputLeftElement } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { StyledInput, StyledInputGroup, StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { Table } from "components/Table/Table";
import useDebounce from "hooks/useDebounce";
import { hasPreviousRowRepeatedFields } from "helpers/tableCells";
import { useCommissionFees } from "hooks/useCommissionFees";
import { StyledSearchContainer } from "../CommissionFees";
import { useCommissionFee } from "hooks/useCommissionFee";
import { CommissionFeeCell } from "./CommissionFeeCell";
import { ExpirationDateCell } from "./ExpirationDateCell";
import { useToast } from "hooks/useToast";
import { SearchIcon } from "@chakra-ui/icons";

const NameCell = (arg: any) => {
  const { firstName, lastName } = arg.row.original;
  if (hasPreviousRowRepeatedFields(arg, ["id"])) {
    return "";
  }
  return (
    <b>
      {firstName} {lastName}
    </b>
  );
};

export function CommissionFeesTab() {
  const { t } = useTranslation();
  const { update, save, clear } = useCommissionFee({
    skipFetching: true,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const toast = useToast();

  const { fees, loading, pageCount, refetch } = useCommissionFees({
    phrase: debouncedSearchQuery,
    fields: "firstName,lastName,company.name,number,id",
    orderBy: "firstName",
    orderDirection: "asc",
    offset,
    limit,
  });

  const handleSave = useCallback(
    ({ newFee, newDate, arg }: { newFee?: string; newDate?: Date; arg: any }) => {
      const row = arg.row.original;
      const id = row.commissionFee?.id;

      const newData = {} as any;
      if (newFee) newData.fee = newFee;
      if (newDate) newData.expiration = newDate;

      if (id) {
        update({
          id,
          ...newData,
        }).then((success) => {
          if (success) {
            refetch();
            toast({
              type: "success",
              message: t("commissionFee:Commission fee has been updated successfully."),
            });
          }
        });
      } else {
        save({
          companyId: row.company.id,
          contactId: row.id,
          ...newData,
        }).then((success) => {
          if (success) {
            refetch();
            toast({
              type: "success",
              message: t("commissionFee:Commission fee has been created successfully."),
            });
          }
        });
      }
    },
    [refetch, save, t, toast, update]
  );

  const handleClear = useCallback(
    async ({ field, arg }: { field: "fee" | "expiration"; arg: any }) => {
      const row = arg.row.original;
      const id = row.commissionFee?.id;

      if (id) {
        await clear({ id, field });
        refetch();
        toast({
          type: "success",
          message: t("commissionFee:Commission fee has been updated successfully."),
        });
      }
    },
    [clear, refetch, t, toast]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("commissionFee:User"),
        accessor: "user",
        Cell: NameCell,
      },
      {
        Header: t("commissionFee:Company"),
        accessor: "company.name",
      },
      {
        Header: t("commissionFee:Commission fee"),
        accessor: "commissionFee.fee",
        Cell: (arg) => (
          <CommissionFeeCell
            onSave={useCallback(
              (newFee) => {
                handleSave({ newFee, arg });
              },
              [arg]
            )}
            row={arg.row}
          />
        ),
      },
      {
        Header: t("commissionFee:Expiration date"),
        accessor: "commissionFee.expiration",
        Cell: (arg) => (
          <ExpirationDateCell
            onClear={() => handleClear({ field: "expiration", arg })}
            onSave={useCallback(
              (newDate?) => {
                handleSave({ newDate, arg });
              },
              [arg]
            )}
            row={arg.row}
          />
        ),
      },
    ],
    [handleClear, handleSave, t]
  );

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSeachInputUdpate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  if (!fees) return null;

  return (
    <>
      <StyledTableControls>
        <StyledSearchContainer>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSeachInputUdpate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
        </StyledSearchContainer>
      </StyledTableControls>
      <Table
        columns={columns}
        data={fees}
        initialPageSize={25}
        loading={loading}
        onStateUpdate={handleTableUdpate}
        pageCount={pageCount}
        pageSizeOptions={[25, 50, 100]}
      />
    </>
  );
}
