import styled from "@emotion/styled";

export const StyledNotes = styled.div<{ column?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: ${({ column }) => column && "column"};
  align-items: ${({ column }) => (column ? "flex-start" : "center")};
  font-weight: 600;
  color: ${(props) => props.theme.colors["sk-dark"]};
  width: 100%;
  justify-content: center;
`;

export const StyledEditContainer = styled.div<{ alignToRight?: boolean }>`
  position: absolute;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(129, 129, 129, 0.3);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  top: 0;
  width: 600px;
  z-index: 10;
  ${(props) => props.alignToRight && "right: 0;"}
`;

export const StyledLabel = styled.p`
  font-size: 14px;
  line-height: 110%;
  color: #818181;
`;

export const StyledNoteSrc = styled.p`
  font-size: 13px;
  line-height: 140%;
  color: #252527;
`;
