import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/Table/Table";
import { roles } from "../../../helpers/userRoles";
import { StyledPanel } from "../Settings";

export function Roles() {
  const { t } = useTranslation();

  const BooleanCell = useCallback(
    (arg: any) => {
      return t(`settings:${arg.value ? "Yes" : "No"}`);
    },
    [t]
  );

  const CommissionFeeCell = useCallback(
    (arg: any) => {
      const role = arg.row.original;
      if (role.type === "Office Manager") return t("settings:No");
      return t(`settings:${arg.value ? "Yes" : "No (only sees their own)"}`);
    },
    [t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("settings:User type"),
        accessor: "type",
      },
      {
        Header: t("settings:Access"),
        accessor: "access",
      },
      {
        Header: t("settings:Add new users"),
        accessor: "addUsers",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:Margin call"),
        accessor: "addMarginCalls",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:Commission fee"),
        accessor: "addCommissionFee",
        Cell: CommissionFeeCell,
      },
      {
        Header: t("settings:Risk panel"),
        accessor: "rPanel",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:New transaction"),
        accessor: "addTransaction",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:Add/edit company clients"),
        accessor: "addCompany",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:Company approval"),
        accessor: "approveCompany",
        Cell: BooleanCell,
      },
      {
        Header: t("settings:Company activation/deactivation"),
        accessor: "activateCompany",
        Cell: BooleanCell,
      },
    ],
    [BooleanCell, CommissionFeeCell, t]
  );

  return (
    <StyledPanel>
      <Table columns={columns} data={roles} paginated={false} />
    </StyledPanel>
  );
}
