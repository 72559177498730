import { Box, Text } from "@chakra-ui/react";
import React, { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { getMilisecondsFromTimestamp, transformTimestamp } from "helpers/date";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { sumLQtyOfHedges } from "helpers/hedge";
import { CurrencyCell } from "helpers/tableCells";
import { DashboardType, Transaction } from "interfaces/data";
import { ButtonContainer } from "../Company360/Main/Transactions/Transactions";
import { StyledButton } from "../Company360/shared.styled";
import { EditableComment } from "./components/EditableComment";
import { IdCell } from "./components/IdCell";
import { StyledTableWrapper } from "./components/styles";
import { ToHedgeCell } from "./components/ToHedgeCell";
import { QuantityLeftCell } from "./components/QuantityLeftCell";
import { TRANSACTION_TYPES } from "../../helpers/transaction";

interface DashboardTableProps {
  transactions: Array<Transaction>;
  lastColumn?: Array<"" | firebase.firestore.Timestamp | undefined>;
  isLoading?: boolean;
  title: string;
  color: string;
  showLqt?: boolean;
  customAction?: ReactNode;
  type: DashboardType;
}

export const DashboardTable: React.FC<DashboardTableProps> = ({
  transactions,
  lastColumn,
  isLoading,
  title,
  color,
  showLqt = false,
  customAction,
  type,
}) => {
  const numberOfCollapse = 7;

  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  const columns = useMemo(
    () => {
      const headers = [
        {
          Header: t("transaction:ID"),
          accessor: "number",
          Cell: IdCell,
        },
        type === "marginCall"
          ? {
              Header: t("transaction: Value"),
              accessor: "marginCallValue",
              Cell: (props: any) => (
                <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
                  {props.value}
                </Text>
              ),
            }
          : null,
        type === "initialMargin"
          ? {
              Header: t("transaction: Initial Margin"),
              accessor: "initialMarginValue",
              Cell: (props: any) => (
                <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
                  {props.value}
                </Text>
              ),
            }
          : null,
        {
          Header: t("transaction:Type"),
          accessor: "dealType",
        },
        {
          Header: t("transaction:Client Rate"),
          accessor: "clientRate",
          Cell: (arg: any) => (
            <Text as="span" fontSize="15px" fontWeight="900">
              {formatRate(arg.value)}
            </Text>
          ),
        },
        showLqt
          ? {
              Header: () => (
                <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
                  {t("transaction:LQT")}
                </Text>
              ),
              accessor: "hedge.leftQuantity",
              Cell: (props: any) => {
                const transaction = props.row.original;
                const hedges = transaction.hedges;
                const currency = transaction.from.currency;
                const hedgesLqt = sumLQtyOfHedges(hedges);
                return (
                  <div style={{ whiteSpace: "nowrap" }}>
                    {formatNumberToString(hedgesLqt)} {currency}
                  </div>
                );
              },
            }
          : {
              Header: () => (
                <Text as="span" display="block" textAlign="right" whiteSpace="nowrap" width="100%">
                  {t("transaction:Base Qty")}
                </Text>
              ),
              accessor: "from",
              Cell: (props: any) => {
                return (
                  <CurrencyCell
                    {...props}
                    cellProps={{
                      textAlign: "right",
                      whiteSpace: "nowrap",
                      display: "block",
                    }}
                  />
                );
              },
            },
        {
          Header: () => (
            <Text as="span" display="block" textAlign="right" whiteSpace="nowrap" width="100%">
              {t("transaction:Currency")}
            </Text>
          ),
          accessor: "to.currency",
          Cell: (arg: any) => (
            <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
              {arg.value}
            </Text>
          ),
        },
        type === "unhedged"
          ? {
              Header: t("transaction: Hedge value"),
              accessor: "hedges",
              Cell: ToHedgeCell,
            }
          : null,
        type === "soonToEnd"
          ? {
              Header: t("transaction: Quantity left"),
              accessor: "quantity",
              Cell: QuantityLeftCell,
            }
          : null,
        {
          Header: t("transaction:Company"),
          accessor: "company.name",
        },
        {
          Header: t("transaction:Note"),
          accessor: "comment",
          Cell: (arg: any) => {
            const transaction = arg.row.original as Transaction;
            return (
              <EditableComment
                comment={
                  transaction.dashboardComments
                    ? transaction.dashboardComments[type]
                    : transaction.dashboardComment || ""
                }
                transactionId={transaction.id}
                type={type}
              />
            );
          },
        },
      ];
      if (lastColumn) {
        headers.push({
          Header: t("transaction:Date"),
          accessor: "lastColumn",
        } as any);
      }
      return headers.filter((h) => h !== null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const visibleTransactions = useMemo(() => {
    const transactionsWithDateColumn = transactions
      .map((transaction, index) => ({
        ...transaction,
        lastColumn: lastColumn ? lastColumn[index] : undefined,
      }))
      .sort((a, b) => getMilisecondsFromTimestamp(a.lastColumn) - getMilisecondsFromTimestamp(b.lastColumn))
      .map((transaction) => ({
        ...transaction,
        lastColumn: transformTimestamp(transaction.lastColumn),
      }));
    if (isExpanded) {
      return transactionsWithDateColumn;
    }
    return transactionsWithDateColumn.slice(0, numberOfCollapse);
  }, [isExpanded, transactions, lastColumn]);

  return (
    <StyledTableWrapper>
      <Box backgroundColor={color} h="12px" />
      <Box backgroundColor="white" display="flex" fontSize="18px" fontWeight="bold" padding="18px 30px 5px 30px">
        {title}
        {customAction && <Box>{customAction}</Box>}
      </Box>

      <Table
        columns={columns}
        data={visibleTransactions}
        getCellProps={(cellInfo) => {
          return cellInfo.column.id === "hedge.leftQuantity" ? { className: "nowrap" } : {};
        }}
        getRowProps={(cellInfo) => {
          return cellInfo.original.type === TRANSACTION_TYPES.SPOT.value ? { className: "spot-row" } : {};
        }}
        loading={isLoading}
        paginated={false}
      />
      {transactions.length > numberOfCollapse && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledTableWrapper>
  );
};
