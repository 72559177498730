import React, { PropsWithChildren } from "react";
import { Button, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const ActionButtons: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Menu>
      <MenuButton as={Button} onClick={(e) => e.stopPropagation()} rightIcon={<ChevronDownIcon />} variant="outline">
        Actions
        <MenuList>{children}</MenuList>
      </MenuButton>
    </Menu>
  );
};
