import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Input } from "components/Input/Input";
import { Checkbox } from "components/Checkbox/Checkbox";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { Button } from "components/Button/Button";
import { BankAccount, BaseCompany, Contact } from "interfaces/data";
import { Box } from "components/Box/Box";
import {
  CompanyAddContainer,
  Section,
  SectionTitle,
  StyledBackButton,
  StyledCheckboxWrapper,
} from "./CompanyAdd.styled";
import { useCompany } from "hooks/useCompany";
import { Heading } from "components/Heading/Heading";
import { Select } from "components/Select/Select";
import { COUNTRIES } from "helpers/options";
import { EditBankAccounts } from "../Company/BankAccounts/EditBankAccounts";
import { EditCompanyInfo } from "../Company/CompanyInfo/EditCompanyInfo";
import { EditDetails } from "../Company/Details/EditDetails";
import { EditCompanyAddress } from "../Company/CompanyAddress/EditCompanyAddress";
import { EditOtherInfo } from "../Company/OtherInfo/EditOtherInfo";
import { EditContacts } from "../Company/Contacts/EditContacts";
import { StyledThreeColumnsContainer } from "components/ItemsListElements/ItemsListElements";
import { useToast } from "hooks/useToast";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Footer } from "components/Footer/Footer";
import { AddIcon, ArrowLeftIcon } from "theme/icons";

const emptyBankAccount: BankAccount = {
  number: "",
  name: "",
  swift: "",
  currency: "PLN",
  country: "PL",
};

const emptyCompany: BaseCompany = {
  name: "",
  legalEntity: "",
  nip: "",
  regon: "",
  krs: "",
  phone: "",
  details: {
    industry: "",
    goal: "",
    source: "",
    currency: "PLN",
    limit: "",
    notes: "",
  },
  companyAddress: {
    street: "",
    number: "",
    code: "",
    city: "",
    country: "PL",
  },
  contactAddress: {
    street: "",
    number: "",
    code: "",
    city: "",
    country: "PL",
  },
  other: {
    notes: "",
    communicationLanguage: "PL",
    ip: "",
  },
  contacts: [],
  bankAccounts: [{ ...emptyBankAccount }],
};

export const CompanyAdd: React.FunctionComponent = () => {
  const [newCompany, setNewCompany] = useState<BaseCompany>(() => _cloneDeep(emptyCompany));
  const [sameAsCompanyAddress, setSameAsCompanyAddress] = useState(true);
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation();
  const { save, company, loading, errors } = useCompany({ skipFetching: true });

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>, checkbox?: boolean) => {
    e.persist();

    setNewCompany((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };
      const value = checkbox ? e.target.checked : e.target.value;
      _set(newEditedData, e.target.name, value);

      return newEditedData;
    });
  }, []);

  const handleAccountRemove = useCallback(
    (index: number) => {
      const cloneNewCompany = _cloneDeep(newCompany);
      cloneNewCompany.bankAccounts.splice(index, 1);

      setNewCompany(cloneNewCompany);
    },
    [newCompany]
  );

  const handleAccountAdd = useCallback(() => {
    const cloneNewCompany = _cloneDeep(newCompany);
    cloneNewCompany.bankAccounts = [
      ...cloneNewCompany.bankAccounts,
      {
        number: "",
        name: "",
        swift: "",
        currency: "PLN",
        country: "Poland",
      },
    ];

    setNewCompany(cloneNewCompany);
  }, [newCompany]);

  const handleContactAdd = useCallback(
    (contact: Contact) => {
      const cloneNewCompany = _cloneDeep(newCompany);

      if (!cloneNewCompany.contacts) {
        cloneNewCompany.contacts = [];
      }

      cloneNewCompany.contacts = [...cloneNewCompany.contacts, contact];
      setNewCompany(cloneNewCompany);
    },
    [newCompany]
  );

  const handleContactRemove = useCallback(
    (index: number) => {
      const cloneNewCompany = _cloneDeep(newCompany);

      if (!cloneNewCompany.contacts) {
        cloneNewCompany.contacts = [];
      }

      cloneNewCompany.contacts.splice(index, 1);

      setNewCompany(cloneNewCompany);
    },
    [newCompany]
  );

  const firstAccount = newCompany.bankAccounts[0];
  const isFirstAccountFilled = useMemo(() => {
    return !!firstAccount?.number && !!firstAccount?.swift;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstAccount.number, firstAccount.swift]);

  const onCancelAction = useCallback(() => {
    history.push("/companies");
  }, [history]);

  const onSaveAction = useCallback(() => {
    if (sameAsCompanyAddress) {
      newCompany.contactAddress = {
        ...newCompany.companyAddress,
      };
    }

    save(newCompany).then((isSaved) => {
      if (isSaved) {
        history.push("/companies");
        toast({
          type: "success",
          message: t("company:Company has been added successfully."),
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompany, save, sameAsCompanyAddress]);

  useEffect(() => {
    if (!loading && company && !errors) {
      onCancelAction();
    }
  }, [errors, company, loading, onCancelAction]);

  return (
    <CompanyAddContainer>
      <PageHeader>
        <Heading type="h1">{t("company:New company")}</Heading>
        <StyledBackButton onClick={() => history.push("/companies")}>
          <ArrowLeftIcon mr="10px" />
          {t("company:All companies")}
        </StyledBackButton>
      </PageHeader>
      <StyledThreeColumnsContainer>
        <div>
          <Box bg="white" p="7">
            <Section>
              <SectionTitle>{t("company:Company info")}</SectionTitle>

              <EditCompanyInfo data={newCompany} errors={errors} handleChange={handleUpdate} />
            </Section>

            <Section>
              <SectionTitle>{t("company:Details")}</SectionTitle>

              <EditDetails data={newCompany} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>
        </div>

        <div>
          <Box bg="white" mb="7" p="7">
            <Section>
              <SectionTitle>{t("company:Company address")}</SectionTitle>

              <EditCompanyAddress data={newCompany} errors={errors} handleChange={handleUpdate} />
            </Section>
            <Section>
              <SectionTitle>{t("company:Contact address")}</SectionTitle>

              <StyledCheckboxWrapper>
                <Checkbox
                  fullWidth
                  isChecked={sameAsCompanyAddress}
                  onChange={(e) => setSameAsCompanyAddress(e.target.checked)}
                >
                  {t("company:Same as company address")}
                </Checkbox>
              </StyledCheckboxWrapper>

              {!sameAsCompanyAddress && (
                <TwoColumnsLayout>
                  <Input
                    id="street-contact"
                    label={t("company:Street")}
                    name="contactAddress.street"
                    onChange={handleUpdate}
                    type="text"
                    value={newCompany.contactAddress?.street}
                  />

                  <Input
                    id="number-contact"
                    label={t("company:Apt number")}
                    name="contactAddress.number"
                    onChange={handleUpdate}
                    type="text"
                    value={newCompany.contactAddress?.number}
                  />

                  <Input
                    id="code-contact"
                    label={t("company:Code")}
                    name="contactAddress.code"
                    onChange={handleUpdate}
                    type="text"
                    value={newCompany.contactAddress?.code}
                  />

                  <Input
                    id="city-contact"
                    label={t("company:City")}
                    name="contactAddress.city"
                    onChange={handleUpdate}
                    type="text"
                    value={newCompany.contactAddress?.city}
                  />

                  <Select
                    id="country-contact"
                    label={t("company:Country")}
                    name="contactAddress.country"
                    onChange={handleUpdate}
                    options={COUNTRIES}
                    value={newCompany.contactAddress?.country}
                  />
                </TwoColumnsLayout>
              )}
            </Section>

            <Section>
              <SectionTitle>{t("company:Other")}</SectionTitle>

              <EditOtherInfo data={newCompany} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>
        </div>
        <div>
          <Box bg="white" mb="7" p="7">
            <Section>
              <SectionTitle>{t("company:Add existing contact")}</SectionTitle>

              <EditContacts
                data={newCompany}
                errors={errors}
                handleChange={handleUpdate}
                onContactAdd={handleContactAdd}
                onContactRemove={handleContactRemove}
              />
            </Section>
          </Box>

          <Box bg="white" p="7">
            <Section>
              <SectionTitle>
                {t("company:Bank account")}
                {isFirstAccountFilled && (
                  <Button
                    color="sk-purple"
                    design="link"
                    fontWeight="bold"
                    height="auto"
                    letterSpacing={0}
                    onClick={handleAccountAdd}
                    px={0}
                  >
                    <AddIcon mr="10px" />
                    {t("company:Add new account")}
                  </Button>
                )}
              </SectionTitle>

              <EditBankAccounts
                data={newCompany}
                errors={errors}
                handleChange={handleUpdate}
                onAccountRemove={handleAccountRemove}
              />
            </Section>
          </Box>
        </div>
      </StyledThreeColumnsContainer>

      <Footer>
        <Button design="secondary" ml="auto" mr="20px" onClick={onCancelAction}>
          {t("Cancel")}
        </Button>
        <Button design="primary" onClick={onSaveAction}>
          {t("Add")}
        </Button>
      </Footer>
    </CompanyAddContainer>
  );
};
