import { Company } from "interfaces/data";
import { Company as CompanySchema } from "schemas/company";
import { OperationExtended } from "views/Company360/Main/Operations";
import { convertToTimestamp, timestampFromMap } from "./timestamp";

export const getCompanyOperations = (company?: Company): Array<OperationExtended> => {
  if (!company) return [];

  const payments = company.paymentEntries
    ? company.paymentEntries.map((paymentEntry) => ({
        id: paymentEntry.marginId,
        date: paymentEntry.date,
        settlementDate: paymentEntry.date,
        from: {
          quantity: paymentEntry.amount,
          currency: paymentEntry.currency,
        },
        to: { quantity: paymentEntry.amount, currency: paymentEntry.currency },
        status: `${paymentEntry.marginId} ${paymentEntry.amount > 0 ? "add" : "withdraw"}`,
      }))
    : [];

  const widthdraws = [] as Array<OperationExtended>;

  company.globalMargins?.forEach((margin) =>
    margin.operations?.forEach((operation) => {
      widthdraws.push({
        id: margin.id,
        date: operation.date,
        settlementDate: operation.date,
        from: {
          currency: operation.value.currency,
          quantity: -operation.value.quantity,
        },
        to: {
          currency: operation.value.currency,
          quantity: operation.newMarginValue?.quantity,
        },
        status: `${margin.type} ${margin.id} widthdraw`,
      });
    })
  );

  return [...payments, ...widthdraws].sort((a, b) => (a.date.seconds <= b.date.seconds ? 1 : -1));
};

export const fixCompanyTimestamps = (company: CompanySchema): CompanySchema => {
  if (company.createdAt) {
    company.createdAt = timestampFromMap(company.createdAt);
  }
  if (company.modifiedAt) {
    company.modifiedAt = timestampFromMap(company.modifiedAt);
  }
  if (company.reversePayment?.date) {
    company.reversePayment.date = timestampFromMap(company.reversePayment.date);
  }
  if (company.paymentEntries?.length) {
    company.paymentEntries = company.paymentEntries.map((entry) => ({
      ...entry,
      date: timestampFromMap(entry.date),
    }));
  }
  if (company.globalMargins?.length) {
    company.globalMargins = company.globalMargins.map((margin) => ({
      ...margin,
      createdAt: convertToTimestamp(margin.createdAt),
      modifiedAt: convertToTimestamp(margin.modifiedAt),
      date: timestampFromMap(margin.date),
      operations: margin.operations?.map((operation) => ({
        ...operation,
        date: timestampFromMap(operation.date),
      })),
    }));
  }

  if (company.globalMarginCalls?.length) {
    company.globalMarginCalls = company.globalMarginCalls.map((marginCall) => ({
      ...marginCall,
      createdAt: convertToTimestamp(marginCall.createdAt),
      modifiedAt: convertToTimestamp(marginCall.modifiedAt),
      callDate: convertToTimestamp(marginCall.callDate),
      finalNoticeSentAt: convertToTimestamp(marginCall.finalNoticeSentAt),
    }));
  }

  return company;
};
