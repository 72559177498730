import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "../../../hooks/useTransaction";
import { useToast } from "../../../hooks/useToast";
import { Modal } from "../../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";

export function TransactionCancel() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { transaction, remove } = useTransaction({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelAction = useCallback(() => {
    setIsSending(true);

    remove(id)
      .then((result) => {
        if (result) {
          setIsSending(false);
          toast({
            type: "success",
            message: t("transaction:Transaction has been canceled successfully."),
          });
          if (typeof result === "string") {
            history.replace(`/transactions/${result}`);
          } else {
            history.replace(`/transactions`);
          }
        }
      })
      .catch((err) => {
        setIsSending(false);
        toast({
          type: "error",
          message: t(`transaction:${err.message}`),
        });
        history.push(`/transactions/${id}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove, history, id]);

  if (!transaction) return null;

  let title, message;

  if (transaction?.createdFrom?.id) {
    // cancel roll
    title = t(`transaction:Cancel roll for transaction #{{id}}`, {
      id: transaction.number,
    });
    message = t(
      `transaction:This action can't be undone. Are you sure you want to cancel roll for transaction #{{id}}?`,
      { id: transaction.number }
    );
  } else if (transaction?.convertedFrom?.id) {
    // cancel conversion
    title = t(`transaction:Cancel conversion for transaction #{{id}}`, {
      id: transaction.number,
    });
    message = t(
      `transaction:This action can't be undone. Are you sure you want to cancel conversion for transaction #{{id}}?`,
      { id: transaction.number }
    );
  } else {
    // cancel
    title = t(`transaction:Cancel transaction #{{id}}`, {
      id: transaction.number,
    });
    message = t(`transaction:This action can't be undone. Are you sure you want to cancel transaction #{{id}}?`, {
      id: transaction.number,
    });
  }

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={title}
    >
      <Box mb={"20px"}>{message}</Box>
    </Modal>
  );
}
