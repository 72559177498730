import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { BaseContact } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface ContactInfoProps {
  contact: BaseContact;
}

export function ContactInfo({ contact }: ContactInfoProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("contact:Name")} value={contact.firstName} />
      <LabeledText label={t("contact:Surname")} value={contact.lastName} />
      <LabeledText label={t("contact:Email")} value={contact.email} />
      <LabeledText label={t("contact:Phone")} value={contact.phone} />
    </TwoColumnsLayout>
  );
}
