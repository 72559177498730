import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { StyledFieldsLayout, StyledLabel } from "./SelectedHedges.styled";
import { Input } from "components/Input/Input";
import dayjs from "dayjs";
import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { useToast } from "hooks/useToast";
import { transformTimestampToDate } from "helpers/date";
import { Institution } from "interfaces/data";
import { useInstitutions } from "hooks/useInstitutions";
import { Select } from "components/Select/Select";
import { useFirebase } from "hooks/useFirebase";
import { ExtendedHedge } from "views/Hedge/MassHedgeRoll/MassHedgeRoll";
import { useTransaction } from "hooks/useTransaction";

interface SelectedHedgesProps {
  startDate: any;
  hedges: Array<ExtendedHedge>;
  refetchTransactions: () => Promise<void>;
  setSelectedHedges: Dispatch<ExtendedHedge[]>;
}

const useRollHedge = (
  setIsSending: Dispatch<SetStateAction<boolean>>,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>,
  transactionId: string
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { massHedgeRoll } = useTransaction({
    id: transactionId,
  });

  const rollHedge = async (hedge: ExtendedHedge, hedgeToRoll: any) => {
    try {
      setIsSending(true);
      if (!hedge) return null;

      const rolledHedgesData = [
        {
          hedgeId: hedge.id,
          quantity: hedge.quantity,
        },
      ];
      await massHedgeRoll(
        {
          ...hedgeToRoll,
          id: hedge.id,
          quantity: hedge.leftQuantity,
          leftQuantity: hedge.leftQuantity,
        },
        hedge.id,
        rolledHedgesData,
        hedge.transaction
      );
    } catch (err: any) {
      toast({
        type: "error",
        message: t(`mass roll:${err.message || err}`),
      });
    } finally {
      setIsSending(false);
      setIsModalOpen(false);
    }
  };

  return { rollHedge };
};

export const SelectedHedges = ({ startDate, hedges, refetchTransactions, setSelectedHedges }: SelectedHedgesProps) => {
  const [endDate, setEndDate] = useState<number>(dayjs(transformTimestampToDate(startDate)).add(7, "day").valueOf());
  const toast = useToast();
  const { t } = useTranslation();
  const { timestampFrom } = useFirebase();
  const { institutions } = useInstitutions();
  const [isSending, setIsSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [points, setPoints] = useState<number>();
  const [selectedInstitution, setSelectedInstitution] = useState<Institution>();
  const { rollHedge } = useRollHedge(setIsSending, setIsModalOpen, hedges[0].transaction.id);
  const defaultInstitution = useMemo(
    () => institutions.find((instititution) => instititution.name === "Velocity"),
    [institutions]
  );

  const handleOpen = () => setIsModalOpen(true);

  const handleClose = () => setIsModalOpen(false);

  const selectedTransactionQuantity = useMemo(() => {
    if (!hedges.length) return null;
    const currency = hedges[0].fromCurrency;
    const quantity = hedges.reduce((sum, hedge) => Number(sum) + Number(hedge.leftQuantity), 0);
    return { currency, quantity };
  }, [hedges]);

  const hedgeToRoll = useMemo(
    () => ({
      comment: "",
      id: "",
      leftQuantity: 0,
      institution: selectedInstitution ? selectedInstitution?.name : defaultInstitution?.name,
      security: Number(selectedInstitution ? selectedInstitution.securityDeposit : defaultInstitution?.securityDeposit),
      points,
      end: timestampFrom(new Date(endDate)),
      start: timestampFrom(new Date(startDate._seconds * 1000)),
      quantity: 0,
    }),
    [
      defaultInstitution?.name,
      defaultInstitution?.securityDeposit,
      endDate,
      points,
      selectedInstitution,
      startDate,
      timestampFrom,
    ]
  );

  const handleInstitutionUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const institutionName = e.target.value;
      setSelectedInstitution(institutions.find((institution) => institution.name === institutionName));
    },
    [institutions]
  );

  const isButtonDisabled = useMemo(() => Boolean(points === 0 || points === undefined), [points]);

  const massRoll = async () => {
    try {
      for (const hedge of hedges) {
        await rollHedge(hedge, hedgeToRoll);
      }
      setSelectedHedges([]);
      setPoints(undefined);

      await refetchTransactions();

      toast({
        type: "success",
        message: t("hedge: Mass roll has been processed successfully"),
      });
    } catch (e) {
      toast({
        type: "error",
        message: t("Unable to mass roll selected hedges."),
      });
      console.warn(e);
    }
  };

  return (
    <>
      <Modal
        confirmText={t("Yes")}
        design="danger"
        isDisabled={isSending}
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={massRoll}
        title={t(`hedge:Create mass roll`)}
      >
        <Box mb={"20px"}>{t("hedge:Are you sure that you want to roll those hedges?")}</Box>
      </Modal>
      <Box alignItems="center" display="flex" mb="30px" width="100%">
        <Box width="50%">
          <Input
            id="startDate"
            isDisabled
            label={t("transaction:From")}
            name="startDate"
            topLabel
            type="date"
            value={dayjs(transformTimestampToDate(startDate)).add(1, "day").valueOf()}
          />
        </Box>
        <Box ml="30px" width="50%">
          <Input
            id="endDate"
            label={t("transaction:To")}
            name="endDate"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEndDate(dayjs(e.target.value, "MM-DD-YYYY").valueOf());
            }}
            topLabel
            type="date"
            value={endDate}
          />
        </Box>
      </Box>
      <StyledFieldsLayout>
        <StyledLabel>{t("hedge:Points")}</StyledLabel>
        <Input
          formatNumberOnBlur
          formatNumberPrecision={4}
          id="points"
          name="points"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoints(Number(e.target.value))}
          type="number"
          value={points}
        />
        <StyledLabel>{t("hedge:Institution")}</StyledLabel>
        <Select
          id="institution"
          name="institution"
          onChange={handleInstitutionUpdate}
          options={[
            { label: "", value: "" },
            ...institutions.map((institution) => {
              return { label: institution.name, value: institution.name };
            }),
          ]}
          placeholder=""
          value={selectedInstitution?.name || defaultInstitution?.name || ""}
        />
        <StyledLabel>{t("marginCall:Quantity")}</StyledLabel>
        <Input
          enableDataCopy
          formatNumberOnBlur
          id="transQty.quantity"
          isDisabled
          name="transQty.quantity"
          rightAddon={selectedTransactionQuantity?.currency}
          type="number"
          value={selectedTransactionQuantity?.quantity}
        />
      </StyledFieldsLayout>
      <Button
        design="primary"
        isDisabled={isSending || isButtonDisabled}
        onClick={handleOpen}
        style={{ marginTop: "30px", width: "100%" }}
      >
        {t("Roll")}
      </Button>
    </>
  );
};
