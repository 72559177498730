import styled from "@emotion/styled/macro";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { Input } from "../Input/Input";
import isPropValid from "@emotion/is-prop-valid";

export const StyledInputGroup = styled(InputGroup, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ rightAddon?: string }>`
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: ${({ rightAddon }) => (rightAddon ? "auto 50px" : "auto")};
`;

export const StyledGroup = styled.div`
  display: grid;
  grid-template-columns: 110px 281px;
  grid-column-gap: 10px;
`;

export const StyledInputRightElement = styled(InputRightElement)`
  z-index: -1;

  svg {
    margin-top: 5px;
    margin-right: 20px;
  }
`;

export const StyledInput = styled(Input)`
  background-color: #fff;

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
`;

export const StyledCopyButton = styled(InputRightElement)`
  position: absolute;
  right: 0;
  cursor: pointer;

  svg {
    margin-top: 5px;
    margin-right: 20px;
  }
`;
