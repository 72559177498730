import React from "react";
import styled from "@emotion/styled";
import { Avatar, Box } from "@chakra-ui/react";
import { BaseCompany, Contact } from "interfaces/data";
import { useTranslation } from "react-i18next";
import { Select } from "components/Select/Select";
import { AUTHORIZED_PERSON, EXTERNAL, LEGAL_REPRESENTANT, SALES } from "helpers/contact";
import { Checkbox } from "components/Checkbox/Checkbox";
import { SearchBox } from "components/SearchBox/SearchBox";
import { EditViewProps } from "components/EditBox/EditBox";
import { Button } from "components/Button/Button";
import { CONTACT_TYPES } from "helpers/options";
import { DeleteIcon } from "@chakra-ui/icons";

const StyledContainer = styled.div`
  display: flex;
  background: white;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  justify-content: space-between;
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const StyledContactsSection = styled.div`
  margin-top: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 5px;
`;

const StyledInfo = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const EditSection = styled.div`
  margin-left: 10px;
  min-width: 160px;
`;

const ContactInfo = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export interface EditContactsProps extends EditViewProps<BaseCompany> {
  onContactRemove: (index: number, { handler, data }: { handler: Function; data: BaseCompany }) => void;
  onContactAdd: (item: Contact, { handler, data }: { handler: Function; data: BaseCompany }) => void;
}

export function EditContacts({ data, handleChange, onContactAdd, onContactRemove }: EditContactsProps) {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <StyledContactsSection>
      <SearchBox
        collection="contacts"
        fields="firstName,lastName,email"
        item={(item: Contact) => `${item.firstName} ${item.lastName} (${item.email})`}
        label={t("company:Search contacts")}
        onSelect={(contact?: Contact) => {
          if (!contact) return;
          if (contact.type === SALES || contact.type === EXTERNAL) {
            contact.source = "yes";
          }
          onContactAdd({ ...contact, type: "sales" }, { handler: handleChange, data });
        }}
        orderBy="firstName"
      />
      {data.contacts &&
        data.contacts.map((contact, i) => (
          <StyledContainer key={i}>
            <div>
              <Avatar mr="10px" name={`${contact.firstName} ${contact.lastName}`} size="md" />
              <ContactInfo>
                <StyledName>{`${contact.firstName} ${contact.lastName}`}</StyledName>
                <StyledInfo>{contact.email}</StyledInfo>
                <StyledInfo>{contact.phone}</StyledInfo>
                <StyledInfo>{contact.ip}</StyledInfo>
              </ContactInfo>
            </div>
            <EditSection>
              <Select
                name={`contacts[${i}].type`}
                onChange={(e: any) => {
                  const isSource = e.target.value === SALES || e.target.value === EXTERNAL;
                  // update contact type
                  handleChange(e);
                  // reset CC flag on contact type change
                  handleChange({
                    target: {
                      name: `contacts[${i}].cc`,
                      value: "no",
                    },
                    persist: () => {
                      return;
                    },
                  });
                  // set source to true for SALES and EXTERNAL
                  handleChange({
                    target: {
                      name: `contacts[${i}].source`,
                      value: isSource ? "yes" : "no",
                    },
                    persist: () => {
                      return;
                    },
                  });
                }}
                options={CONTACT_TYPES}
                value={contact.type}
              />
              <Box display="flex" justifyContent="space-between" marginTop="10px">
                {contact.type === AUTHORIZED_PERSON && (
                  <Checkbox
                    isChecked={contact?.cc === "yes"}
                    mr={10}
                    name={`contacts[${i}].cc`}
                    onChange={(e) => {
                      e.target.value = e.target.checked ? "yes" : "no";
                      handleChange(e);
                    }}
                  >
                    {t("company:Send CC email")}
                  </Checkbox>
                )}
                {contact.type === LEGAL_REPRESENTANT && (
                  <Checkbox
                    isChecked={contact?.disableEmails}
                    mr={10}
                    name={`contacts[${i}].disableEmails`}
                    onChange={(e) => {
                      handleChange(e, true);
                    }}
                  >
                    {t("company:Don't send emails")}
                  </Checkbox>
                )}
                <Button
                  design="link"
                  height="auto"
                  marginLeft="auto"
                  onClick={() => onContactRemove(i, { handler: handleChange, data })}
                  p={0}
                  width="12px"
                >
                  <DeleteIcon color="sk-red" height="12px" width="12px" />
                </Button>
              </Box>
            </EditSection>
          </StyledContainer>
        ))}
    </StyledContactsSection>
  );
}
