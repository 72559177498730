import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import { Company } from "interfaces/data";
import { GlobalMarginCallMarginWithdrawModal } from "./GlobalMarginCallMarginWithdrawModal";

export function GlobalMarginCall360MarginWithdraw() {
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId: string;
  }>();
  const { t } = useTranslation();
  const toast = useToast();
  const { company, update: updateCompany } = useCompany({ id });
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSave = async (updatedCompany: Company) => {
    try {
      await updateCompany(updatedCompany);
      toast({
        type: "success",
        message: t("transaction:Global Margin call has been edited successfully."),
      });
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        toast({
          type: "error",
          message: t(`transaction:${error.message}`),
        });
      }
    }
  };

  const marginCall = company?.globalMarginCalls?.find((marginCall) => marginCall.id === marginCallId);

  if (!marginCall || !company) return null;

  return (
    <GlobalMarginCallMarginWithdrawModal
      company={company}
      marginCall={marginCall}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
}
