import dayjs from "dayjs";
import Decimal from "decimal.js";
import {
  BaseTransaction,
  Currency,
  DeliveryType,
  GlobalMarginCall,
  Hedge,
  MarginCall,
  MarginType,
  NbpRateRecord,
  Transaction,
  TransactionStatus,
  TransactionType,
} from "interfaces/data";
import { OperationExtended } from "views/Company360/Main/Operations";
import { getMilisecondsFromTimestamp } from "./date";
import { formatNumber, formatNumberToString } from "./formatNumber";
import { activeHedges, fixHedgesTimestamps } from "./hedge";
import { parseAnyFloat } from "./parseAnyFloat";
import { convertToTimestamp, timestampFromMap } from "./timestamp";
import { fixSettlementsTimestamps } from "./settlement";
import { fixMarginCallsTimestamps } from "./marginCall";
import { fixMarginsTimestamps } from "./margin";
import firebase from "firebase";

// DEAL TYPES
export const DEAL_TYPE_SELL = "Sell";
export const DEAL_TYPE_BUY = "Buy";

export const TRANSACTION_TYPES = {
  FIXED: {
    label: "Fixed rate",
    value: "FR" as TransactionType,
  },
  SPOT: {
    label: "Spot",
    value: "Spot" as TransactionType,
  },
  ORDER: {
    label: "Order",
    value: "Order" as TransactionType,
  },
};

export const TRANSACTION_STATUSES = {
  CLOSED: {
    label: "closed",
    value: "closed" as TransactionStatus,
  },
  ROLLED: {
    label: "rolled",
    value: "rolled" as TransactionStatus,
  },
  CREATED: {
    label: "created",
    value: "created" as TransactionStatus,
  },
  PARTIALLY_SETTLED: {
    label: "partially settled",
    value: "partiallySettled" as TransactionStatus,
  },
  SETTLED: {
    label: "settled",
    value: "settled" as TransactionStatus,
  },
};

export const DELIVERY_TYPES = {
  PHYSICAL: {
    label: "Physical",
    value: "Physical" as DeliveryType,
  },
  NET: {
    label: "Net",
    value: "Net" as DeliveryType,
  },
};

export const MARGIN_TYPES = {
  INITIAL: {
    label: "Initial Margin",
    value: "IM" as MarginType,
  },
  VARIATION: {
    label: "Variation Margin",
    value: "VM" as MarginType,
  },
};

export const getTransactionTypeLabel = (value: string): string => {
  for (const [, type] of Object.entries(TRANSACTION_TYPES)) {
    if (type.value === value) return type.label;
  }
  return "";
};

export const getTransactionStatusLabel = (value: string): string => {
  for (const [, type] of Object.entries(TRANSACTION_STATUSES)) {
    if (type.value === value) return type.label;
  }
  return "";
};

export const getSumOfInitialMargins = (transaction?: BaseTransaction) => {
  if (!transaction || !transaction.hedges || transaction.hedges.length === 0) return 0;
  return activeHedges(transaction.hedges).reduce((sum, hedge) => {
    return sum + ((Number(hedge?.leftQuantity) || 0) * Number(hedge?.security)) / 100;
  }, 0);
};

export const getSumOfVariableMargins = (transaction: BaseTransaction, nbpRates: NbpRateRecord): number => {
  if (!transaction || !nbpRates) return 0;

  const currency = transaction.from.currency;

  if (
    (transaction.dealType === DEAL_TYPE_SELL && nbpRates.rates[currency] > transaction.clientRate) ||
    (transaction.dealType === DEAL_TYPE_BUY && nbpRates.rates[currency] < transaction.clientRate)
  ) {
    return Number((getRateShift(transaction, nbpRates) * Number(getSumOfHedgesLeft(transaction))) / 100);
  } else {
    return 0;
  }
};

export const getSumOfHedgesLeft = (transaction: BaseTransaction): number => {
  if (!transaction.hedges) return 0;
  const hedgesLeftQuantity = activeHedges(transaction.hedges || []).reduce(
    (sum, hedge) => sum.plus(new Decimal(hedge.leftQuantity)),
    new Decimal(0)
  );
  return Number(hedgesLeftQuantity);
};

export const getRateShift = (transaction?: BaseTransaction, nbpRates?: any) => {
  if (!transaction || !nbpRates || transaction.type !== TRANSACTION_TYPES.FIXED.value) {
    return 0;
  }

  const nbpRate =
    transaction.to.currency === "PLN"
      ? new Decimal(nbpRates.rates[transaction.from.currency])
      : new Decimal(nbpRates.rates[transaction.from.currency]).dividedBy(nbpRates.rates[transaction.to.currency]);
  const clientRate = new Decimal(transaction.clientRate);

  const currenciesRatio =
    transaction.dealType !== DEAL_TYPE_SELL ? nbpRate.dividedBy(clientRate) : clientRate.dividedBy(nbpRate);

  return Number(currenciesRatio.minus(1).times(100).toDecimalPlaces(2));
  // return Number(currenciesRatio.times(100).toDecimalPlaces(2));
};

export const calculateMarginsInPlnForRiskPanel = (
  transactions?: Array<BaseTransaction>,
  nbpRates?: any
): { initialMargin: number; variableMargin: number } => {
  if (!transactions || transactions.length === 0) return { initialMargin: 0, variableMargin: 0 };

  let initialMargin = 0;
  let variableMargin = 0;

  transactions.forEach((transaction) => {
    const currency = transaction.from.currency;

    // transactions in PLN are not considered for risk panel
    if (currency === "PLN") return;

    initialMargin = initialMargin + getSumOfInitialMargins(transaction) * nbpRates.rates[currency];
    variableMargin = variableMargin + getSumOfVariableMargins(transaction, nbpRates) * nbpRates.rates[currency];
  });

  return { initialMargin, variableMargin: -variableMargin };
};

export const getMarginQuantity = (transaction: BaseTransaction): string => {
  const margin = new Decimal(parseAnyFloat(transaction.initialMargin));

  const baseMargin = margin.times(parseAnyFloat(transaction.from.quantity));

  const formatMargin = (decimalMargin: Decimal): string =>
    formatNumberToString(decimalMargin.dividedBy(100).toString()) || "-";

  if (transaction.dealType === DEAL_TYPE_BUY) {
    return formatMargin(baseMargin.times(parseAnyFloat(transaction.clientRate)));
  }

  return formatMargin(baseMargin);
};

export const getMarginQuantityNumber = (transaction: BaseTransaction): number => {
  const margin = new Decimal(parseAnyFloat(transaction.initialMargin));

  const baseMargin = margin.times(parseAnyFloat(transaction.from.quantity));

  if (transaction.dealType === DEAL_TYPE_BUY) {
    return baseMargin.times(parseAnyFloat(transaction.clientRate)).dividedBy(100).toNumber();
  }
  return baseMargin.dividedBy(100).toNumber();
};

export const isInitialMarginPaid = (transaction: BaseTransaction): boolean => {
  if (transaction.type !== "FR") return true;

  const margin = new Decimal(parseAnyFloat(transaction.initialMargin));

  const expectedInitialMargin = margin
    .times(parseAnyFloat(transaction.from.quantity))
    .dividedBy(100)
    .toDecimalPlaces(4);

  const finalExpectedInitialMargin =
    transaction.dealType === DEAL_TYPE_BUY
      ? Number(expectedInitialMargin.times(parseAnyFloat(transaction.clientRate)))
      : Number(expectedInitialMargin);

  const paidInitialMargin =
    transaction.margins
      ?.filter((margin) => margin.type === "IM")
      .reduce((sum, margin) => sum + (Number(margin.to.quantity) || 0), 0) || 0;

  return paidInitialMargin >= finalExpectedInitialMargin;
};

export const getQuantityLeft = (transaction: BaseTransaction): number => {
  const settled =
    transaction.settlements && transaction.settlements?.length > 0
      ? transaction.settlements.reduce(
          (sum, settlement) => sum + (Number(Number(settlement.quantity).toFixed(2)) || 0),
          0
        )
      : 0;
  return Number(transaction.from.quantity) - settled;
};

export const determineTransactionStatus = (transaction: Transaction): TransactionStatus => {
  if (getQuantityLeft(transaction) === 0) {
    return TRANSACTION_STATUSES.SETTLED.value;
  }
  if (getQuantityLeft(transaction) === Number(transaction.from.quantity)) {
    return TRANSACTION_STATUSES.CREATED.value;
  }
  if (getQuantityLeft(transaction) !== Number(transaction.from.quantity)) {
    return TRANSACTION_STATUSES.PARTIALLY_SETTLED.value;
  }
  return transaction.status;
};

export const getOperations = (transaction?: Transaction): Array<OperationExtended> => {
  if (!transaction) return [];
  const settlements = transaction.settlements
    ? transaction.settlements.map((settlement) => ({
        id: settlement.id,
        date: settlement.createdAt || settlement.date,
        settlementDate: settlement.date,
        from: {
          currency: transaction.from.currency,
          quantity: settlement.quantity,
        },
        to: {
          currency: transaction.to.currency,
          quantity: Number(settlement.quantity * transaction.clientRate),
        },
        status: "Settlement",
        transaction: {
          id: transaction.id,
          number: transaction.number,
        },
        comment: settlement.comment,
      }))
    : [];

  const widthdraws = [] as Array<OperationExtended>;

  transaction.margins?.forEach((margin) =>
    margin.operations?.forEach((operation) => {
      widthdraws.push({
        id: margin.id,
        date: operation.date,
        settlementDate: operation.date,
        from: {
          currency: operation.value.currency,
          quantity: -operation.value.quantity,
        },
        to: {
          currency: operation.value.currency,
          quantity: operation.newMarginValue?.quantity,
        },
        status: `${margin.type} ${margin.id} widthdraw`,
        transaction: {
          id: transaction.id,
          number: transaction.number,
        },
      });
    })
  );

  const operations = [...settlements, ...widthdraws];
  return operations.sort((a, b) => (a.date.seconds <= b.date.seconds ? 1 : -1));
};

export const getAllOperations = (transaction?: Transaction): Array<OperationExtended> => {
  if (!transaction) return [];
  const operations = [];
  const company = transaction?.company.name;

  operations.push({
    id: transaction.number.toString(),
    date: transaction.createdAt,
    from: transaction.from,
    to: transaction.to,
    status: transaction.status,
    settlementDate: transaction.createdAt,
    type: transaction.type,
    company,
    transaction: {
      id: transaction.id,
      number: transaction.number,
    },
  });

  transaction.marginCalls?.forEach((marginCall: MarginCall) => ({
    id: marginCall?.id,
    date: marginCall?.createdAt,
    from: {
      currency: transaction.from.currency,
      quantity: marginCall.quantity,
    },
    to: {
      currency: transaction.to.currency,
      quantity: Number(marginCall.quantity * transaction.clientRate),
    },
    status: marginCall.isClosed ? "Closed" : "Open",
    type: "Margin Call",
    company,
    transaction: {
      id: transaction?.id,
      number: transaction?.number,
    },
  }));

  transaction.settlements?.forEach((settlement) => {
    operations.push({
      id: settlement.id,
      date: settlement.createdAt || settlement.date,
      settlementDate: settlement.date,
      company,
      from: {
        currency: transaction.from.currency,
        quantity: settlement.quantity,
      },
      to: {
        currency: transaction.to.currency,
        quantity: Number(settlement.quantity * transaction.clientRate),
      },
      status: "Settlement",
      transaction: {
        id: transaction.id,
        number: transaction.number,
      },
      comment: settlement.comment,
    });
  });

  transaction.margins?.forEach((margin) =>
    margin.operations?.forEach((operation) => {
      operations.push({
        id: margin.id,
        date: operation.date,
        settlementDate: operation.date,
        from: {
          currency: operation.value.currency,
          quantity: -operation.value.quantity,
        },
        to: {
          currency: operation.value.currency,
          quantity: operation.newMarginValue?.quantity,
        },
        status: `${margin.type} ${margin.id} widthdraw`,
        transaction: {
          id: transaction.id,
          number: transaction.number,
        },
      });
    })
  );

  return operations.sort((a, b) => (a.date.seconds <= b.date.seconds ? 1 : -1));
};

export const isInactive = (transaction: BaseTransaction): boolean => {
  return (
    transaction.status === TRANSACTION_STATUSES.CLOSED.value ||
    transaction.status === TRANSACTION_STATUSES.ROLLED.value ||
    transaction.status === TRANSACTION_STATUSES.SETTLED.value
  );
};

export const isClosedOrRolled = (transaction: BaseTransaction): boolean => {
  return (
    transaction.status === TRANSACTION_STATUSES.CLOSED.value || transaction.status === TRANSACTION_STATUSES.ROLLED.value
  );
};

export const isOrderExpired = (transaction: BaseTransaction): boolean => {
  if (transaction.type === "Order" && transaction.expiration) {
    const expirationDate = dayjs(getMilisecondsFromTimestamp(transaction.expiration));
    return !expirationDate.isSameOrAfter(dayjs(), "day");
  }
  return false;
};

export const isNotFixedRate = (transaction: BaseTransaction): boolean =>
  transaction.type !== TRANSACTION_TYPES.FIXED.value;

export const isNotEvenlyHedged = (transaction: BaseTransaction): boolean => {
  if (isClosedOrRolled(transaction) || isNotFixedRate(transaction)) return false;

  const hedgesLeftQuantity = getHedgesLeftQuantity(transaction);

  return formatNumber(getQuantityLeft(transaction)) !== hedgesLeftQuantity;
};

export const hasDeadline = (transaction: any): boolean => {
  if (isInactive(transaction) || isNotFixedRate(transaction)) return false;

  const limitDate = dayjs().add(7, "day");
  const endDate = dayjs(getMilisecondsFromTimestamp(transaction.end));
  return limitDate.isSameOrAfter(endDate, "day") && endDate.isSameOrAfter(dayjs(), "day");
};

export const hasRisk = (
  transaction: BaseTransaction,
  nbpRates: NbpRateRecord,
  globalMarginCalls?: GlobalMarginCall[]
): boolean => {
  if (isInactive(transaction) || isNotFixedRate(transaction) || transaction.ignoreMarginCallRisk) return false;

  const rate = nbpRates.rates[transaction.from.currency];
  let parsedClientRate: number;
  if (Boolean(transaction.marginCalls?.length) || Boolean(globalMarginCalls?.length)) {
    const getLatestCallRate = (marginCalls: MarginCall[]) => {
      // take rate from the newest call
      return Number(marginCalls.sort((mc: any) => mc.callDate)[marginCalls.length - 1].callRate);
    };

    parsedClientRate = globalMarginCalls?.length
      ? getLatestCallRate(globalMarginCalls)
      : getLatestCallRate(transaction.marginCalls!);
  } else {
    parsedClientRate = transaction.clientRate;
  }
  if (!rate) return false;

  return transaction.dealType === DEAL_TYPE_SELL ? rate / parsedClientRate >= 1.025 : parsedClientRate / rate >= 1.025;
};

export const hasHedgeDeadline = (transaction: BaseTransaction): boolean => {
  if (isInactive(transaction) || isNotFixedRate(transaction)) return false;

  const hedges = activeHedges(transaction.hedges || []);
  if (hedges.length === 0) {
    return false;
  }
  const limitDate = dayjs().add(7, "day");

  return hedges.some((hedge: Hedge) => {
    const endDate = dayjs(getMilisecondsFromTimestamp(hedge.end));
    return limitDate.isSameOrAfter(endDate, "day") && endDate.isSameOrAfter(dayjs(), "day");
  });
};

export const isCompanyDeactivated = (transaction: BaseTransaction): boolean => {
  return transaction.company.isDeactivated === "yes";
};

// Determine what currency we should use
export const determineTransactionCurrency = (transaction: BaseTransaction): Currency => {
  if (transaction.dealType === DEAL_TYPE_SELL) {
    return transaction.from.currency;
  }

  return transaction.to.currency;
};

// Determine what pair of currencies we should use
export const determineTransactionCurrencyPair = (transaction: BaseTransaction): [Currency, Currency] => {
  if (transaction.dealType === DEAL_TYPE_SELL) {
    return [transaction.from.currency, transaction.to.currency];
  }

  return [transaction.to.currency, transaction.from.currency];
};

export function getHedgesLeftQuantity(transaction: BaseTransaction) {
  const hedges = activeHedges(transaction.hedges || []);
  const hedgesLeftQuantity = hedges.reduce((sum, hedge) => sum.plus(new Decimal(hedge.leftQuantity)), new Decimal(0));
  return formatNumber(hedgesLeftQuantity.toNumber());
}

export const getWeightedAverageFromObject = ({ values, weights }: { values: number[]; weights: number[] }) => {
  const result = values
    .map((value, i) => {
      const weight = weights[i];
      const product = value * weight;
      return [product, value];
    })
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);
  const avg = result[0] / result[1];

  return formatNumberToString(Number.isNaN(avg) ? 0 : avg);
};

export const fixTransactionTimestamps = (transaction: Transaction) => {
  if ("createdAt" in transaction) {
    transaction.createdAt = timestampFromMap(transaction.createdAt);
  }
  if ("modifiedAt" in transaction) {
    transaction.modifiedAt = timestampFromMap(transaction.modifiedAt);
  }
  if ("end" in transaction) {
    if (!transaction.end) {
      // @ts-ignore
      transaction.end = firebase.firestore.FieldValue.delete();
    } else {
      transaction.end = convertToTimestamp(transaction.end);
    }
  }
  if ("systemDate" in transaction) {
    transaction.systemDate = convertToTimestamp(transaction.systemDate);
  }
  if ("currencyDate" in transaction) {
    transaction.currencyDate = convertToTimestamp(transaction.currencyDate);
  }
  if ("start" in transaction) {
    transaction.start = convertToTimestamp(transaction.start);
  }
  if ("expiration" in transaction) {
    if (!transaction.expiration) {
      // @ts-ignore
      transaction.expiration = firebase.firestore.FieldValue.delete();
    } else {
      transaction.expiration = convertToTimestamp(transaction.expiration);
    }
  }
  if ("additionalMargin" in transaction) {
    // @ts-ignore
    transaction.additionalMargin = firebase.firestore.FieldValue.delete();
  }
  if ("agreement" in transaction) {
    transaction.agreement = convertToTimestamp(transaction.agreement);
  }
  if (transaction.settlements?.length) {
    transaction.settlements = fixSettlementsTimestamps(transaction.settlements);
  }
  if (transaction.marginCalls?.length) {
    transaction.marginCalls = fixMarginCallsTimestamps(transaction.marginCalls);
  }
  if (transaction.hedges?.length) {
    transaction.hedges = fixHedgesTimestamps(transaction.hedges);
  }
  if (transaction.margins?.length) {
    transaction.margins = fixMarginsTimestamps(transaction.margins);
  }

  // @ts-ignore
  transaction.id = firebase.firestore.FieldValue.delete();

  return transaction;
};
