import { TransactionSchema } from "schemas/transaction";
import { DEAL_TYPE_SELL, fixTransactionTimestamps } from "helpers/transaction";
import { fixSettlement } from "./fixSettlement";
import { fixMarginCall } from "./fixMarginCall";
import { fixHedge } from "./fixHedge";
import { fixMargin } from "./fixMargin";
import _get from "lodash.get";
import firebase from "firebase";
import { Transaction } from "interfaces/data";
import { getDefaultBankAccount } from "helpers/bankAccounts";

const COLLECTION = "transactions";
export const fixTransaction = async (transaction: any) => {
  const result = TransactionSchema.safeParse(transaction);

  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return transaction;
  } else {
    // eslint-disable-next-line no-console
    console.log(result.error);
  }

  const fixedTransaction = {
    ...transaction,
    company: transaction.company,
    number: Number(transaction.number),
    clientRate: Number(transaction.clientRate),
    ourRate: Number(transaction.ourRate),
    from: {
      currency: `${transaction.from.currency}`,
      quantity: Number(transaction.from.quantity),
    },
    to: {
      currency: `${transaction.to.currency}`,
      quantity: Number(transaction.to.quantity),
    },
  };
  if ("account" in fixedTransaction && "country" in fixedTransaction.account) {
    const countryCode = _get(fixedTransaction, "account.country");
    if (countryCode.length === 3) {
      if (countryCode === "EUR") {
        fixedTransaction.account.country = "PL";
      } else {
        fixedTransaction.account.country = countryCode.slice(0, 2);
      }
    }
  }
  if ("account" in fixedTransaction && fixedTransaction.account.number === "") {
    const dealType = _get(fixedTransaction, "dealType");
    const currency = dealType === DEAL_TYPE_SELL ? fixedTransaction.from.currency : fixedTransaction.to.currency;
    const account = await getDefaultBankAccount(currency, firebase.firestore());
    if (account) {
      fixedTransaction.account = account;
    } else {
      delete fixedTransaction.account;
    }
  }
  if ("leftQuantity" in fixedTransaction) {
    fixedTransaction.leftQuantity = Number(fixedTransaction.leftQuantity);
  }
  if ("initialMargin" in fixedTransaction) {
    fixedTransaction.initialMargin = Number(fixedTransaction.initialMargin);
  }
  if ("additionalMargin" in fixedTransaction) {
    fixedTransaction.additionalMargin = Number(fixedTransaction.additionalMargin);
  }
  if ("comment" in fixedTransaction) {
    fixedTransaction.comment = `${fixedTransaction.comment}`;
  }
  if (fixedTransaction.settlements?.length) {
    fixedTransaction.settlements = fixedTransaction.settlements.map((settlement: any) => fixSettlement(settlement));
  }
  if (fixedTransaction.marginCalls?.length) {
    fixedTransaction.marginCalls = fixedTransaction.marginCalls.map((marginCall: any) => fixMarginCall(marginCall));
  }
  if (fixedTransaction.hedges?.length) {
    fixedTransaction.hedges = fixedTransaction.hedges.map((hedge: any) => fixHedge(hedge));
  }
  if (fixedTransaction.margins?.length) {
    fixedTransaction.margins = fixedTransaction.margins.map((margin: any) => fixMargin(margin));
  }

  return fixedTransaction;
};

export const saveTransaction = async (transaction: any, db: firebase.firestore.Firestore, noValidate = false) => {
  try {
    if (!noValidate) {
      TransactionSchema.parse(transaction);
    }
    // save
    await db.collection(COLLECTION).doc(transaction.id).update(fixTransactionTimestamps(transaction));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored transaction", transaction);
    console.error(error);
  }
};

export const deleteTransaction = async (transaction: any, db: firebase.firestore.Firestore) => {
  try {
    // eslint-disable-next-line no-console
    console.log(`deleting transaction ${transaction.id}...`);
    await db.collection(COLLECTION).doc(transaction.id).delete();
    // eslint-disable-next-line no-console
    console.log("deleted transaction", transaction);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored transaction", transaction);
    console.error(error);
  }
};

export const batchDelete = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  ids.forEach((id) => {
    const ref = db.collection(COLLECTION).doc(id);
    batch.delete(ref);
  });
  await batch.commit();
};

export const batchFix = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  for (const id of ids) {
    const ref = db.collection(COLLECTION).doc(id);
    const transaction = await getTransaction(id, db);
    batch.update(ref, fixTransactionTimestamps(transaction));
  }
  await batch.commit();
};

export const getTransaction = async (id: string, db: firebase.firestore.Firestore) => {
  const doc = await db.collection(COLLECTION).doc(id).get();
  return { ...doc.data(), id } as Transaction;
};
