import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  position: relative;
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  font-size: 13px;
  line-height: 110%;
  background: white;
  width: 100%;
  th {
    border-bottom: 1px solid #b7b7b7;
    vertical-align: top;
  }
  tr {
    border-bottom: 1px solid #b7b7b7;
  }
  th,
  td {
    padding: 31px 10px;

    &.nowrap {
      white-space: nowrap;
    }
  }
  th:first-of-type,
  td:first-of-type {
    padding-left: 31px;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 31px;
  }
  tr > th {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 110%;
    text-align: left;
  }
  tbody > tr:hover {
    background-color: rgba(129, 129, 129, 0.05);
    transition: background-color 0.15s ease-in;
  }

  tbody > tr.clickable-row:hover {
    cursor: pointer;
  }
  tfoot td {
    background: #fbfbfb;
    font-weight: 900;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StyledPagination = styled.div`
  margin-top: 24px;
  margin-bottom: 10px;
`;

export const StyledEmptyState = styled.tr`
  padding: 20px;
  text-align: center;
`;
