import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SettlementsIcon = createIcon({
  displayName: "SettlementsIcon",
  path: (
    <g fill="none">
      <path
        d="M15.9333 5.7666H2.06667C1.47756 5.7666 1 6.24416 1 6.83327V16.4333C1 17.0224 1.47756 17.4999 2.06667 17.4999H15.9333C16.5224 17.4999 17 17.0224 17 16.4333V6.83327C17 6.24416 16.5224 5.7666 15.9333 5.7666Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.99992 14.3001C10.4727 14.3001 11.6666 13.1062 11.6666 11.6335C11.6666 10.1607 10.4727 8.9668 8.99992 8.9668C7.52716 8.9668 6.33325 10.1607 6.33325 11.6335C6.33325 13.1062 7.52716 14.3001 8.99992 14.3001Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.1333 3.6333H14.8666"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M5.2666 1.5H12.7333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 18 18",
});
