import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { Application, BaseCompany, BaseContact } from "interfaces/data";
import { applicationSchema } from "validations/application";
import { useValidation } from "./useValidation";
import { useCompany } from "./useCompany";
import { useContact } from "./useContact";
import _omit from "lodash.omit";
import { CONTACT_ACTIVE } from "helpers/contact";
import { contactSchema } from "validations/contact";
import { useLogs } from "./useLogs";
import { timestampFromMap } from "helpers/timestamp";

const COLLECTION = "applications";

export interface UseApplicationOptions {
  skipFetching?: boolean;
  id?: string;
}

export const useApplication = ({ skipFetching, id }: UseApplicationOptions = { skipFetching: false }) => {
  const [application, setApplication] = useState<Application>();

  const { save: saveCompany } = useCompany({
    skipFetching: true,
    skipLog: true,
  });
  const { save: saveContact } = useContact({
    skipFetching: true,
    skipLog: true,
  });
  const { validate: validateContact } = useValidation(contactSchema);
  const [loading, setLoading] = useState<boolean>(true);
  const { validate, errors, clearErrors } = useValidation(applicationSchema);
  const { db, timestamp } = useFirebase();
  const { log } = useLogs();

  const fetch = useCallback(
    (id: string) => {
      const collection = db.collection(COLLECTION);

      return collection.doc(id).onSnapshot((snap: firestore.DocumentData) => {
        const newApplication = snap.data() as Application;
        setLoading(false);
        if (newApplication) {
          setApplication({ ...newApplication, id });
        } else {
          setApplication(undefined);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (newApplication: Application, { onUpdate }: { onUpdate?: () => void }) => {
      setLoading(true);

      const { id, ...applicationData } = newApplication;
      const collection = db.collection(COLLECTION);
      const isValid = await validate(newApplication);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const newApplicationWithTimestamp = {
        ...applicationData,
        modifiedAt: timestamp(),
        createdAt: timestampFromMap(applicationData.createdAt),
      };

      const oldApplication = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.data() as Application;
          }
        });

      await collection
        .doc(id)
        .update(newApplicationWithTimestamp)
        .then(() => {
          setApplication({ ...newApplicationWithTimestamp, id });
          log({
            action: "edit",
            item: {
              collection: "applications",
              id: id,
              name: newApplication.name,
            },
            url: `/applications/${id}`,
            oldData: JSON.stringify(oldApplication),
            newData: JSON.stringify(newApplicationWithTimestamp),
          });
          if (typeof onUpdate === "function") {
            onUpdate();
          }

          clearErrors();
          setLoading(false);
        })
        .catch((e) => {
          console.error("useApplication", e);
        });

      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const approve = useCallback(
    async (application: Application) => {
      setLoading(true);

      const isValid = await validate(application);

      if (!isValid) {
        setLoading(false);
        return false;
      }
      let savedCompanyId: string | null = null;
      //create new company from application data
      try {
        const contactsPromises = application.contacts?.map(async (contact) => {
          const isValid = validateContact(contact);

          if (!isValid) {
            throw Error(
              `Contact ${contact.firstName} ${contact.lastName} cannot be attached to company ${
                application.name
              }. Payload: ${JSON.stringify(contact)}`
            );
          }

          const id = await saveContact({
            ...(_omit(contact, ["id", "createdAt", "modifiedAt"]) as BaseContact),
            status: CONTACT_ACTIVE,
          });

          return {
            _ref: db.collection("contacts").doc(String(id)),
            type: contact.type,
          };
        });

        const contacts = contactsPromises
          ? await Promise.all(contactsPromises).catch((error) => {
              throw error;
            })
          : [];

        savedCompanyId = (await saveCompany({
          ...(_omit(application, ["id", "createdAt", "modifiedAt"]) as BaseCompany),
          details: {
            ...application.details,
            currency: application.details?.currency || "EUR",
          },
          other: {
            ...application.other,
            communicationLanguage: application.other?.communicationLanguage || "PL",
          },
          contacts,
        })) as string;

        if (!savedCompanyId) {
          throw Error(`Company ${application.name} cannot be saved. Payload: ${JSON.stringify(application)}`);
        }
      } catch (error) {
        console.error(error);
        return;
      }

      // delete application
      return remove(application.id).then(async () => {
        await log({
          action: "approve",
          item: {
            collection: "applications",
            id: application.id,
            name: application.name,
          },
          oldData: JSON.stringify(application),
        });
        setLoading(false);
        return savedCompanyId;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const remove = useCallback(
    async (id: string) => {
      const dbCollection = db.collection(COLLECTION);

      return dbCollection
        .doc(id)
        .delete()
        .then(() => {
          return true;
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!skipFetching && id) {
      fetch(id);
    }
  }, [fetch, id, skipFetching]);

  return {
    application,
    setApplication,
    loading,
    update,
    remove,
    approve,
    errors,
  };
};
