import React, { ReactNode } from "react";
import { BoxProps, InputProps, Tooltip } from "@chakra-ui/react";

import {
  StyledCopyButton,
  StyledGroup,
  StyledInput,
  StyledInputGroup,
  StyledInputRightElement,
} from "./InputWithLabel.styled";

import { Label } from "../Label/Label";
import { InputRightAddon } from "./InputRightAddon";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useTranslation } from "react-i18next";
import { formatNumber } from "helpers/formatNumber";
import { CopyIcon } from "@chakra-ui/icons";

type InputWithLabelProps = InputProps & {
  error?: string;
  label?: string;
  withError?: boolean;
  fullWidth?: boolean;
  containerProps?: BoxProps;
  labelProps?: BoxProps;
  icon?: ReactNode;
  rightAddon?: string;
  formatNumberOnBlur?: boolean;
  formatNumberPrecision?: number;
  enableDateClear?: boolean;
  enableDataCopy?: boolean;
};

export const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  labelProps = {},
  icon,
  rightAddon,
  error,
  containerProps,
  enableDataCopy,
  ...props
}) => {
  const hasError = !!error;
  const copyToClipboard = useCopyToClipboard();
  const { t } = useTranslation();
  const { value } = props;
  const valueToCopy = isNaN(Number(value)) ? value : formatNumber(Number(value));

  return (
    <StyledGroup>
      {label && <Label {...labelProps}>{label}</Label>}
      <StyledInputGroup rightAddon={rightAddon}>
        <StyledInput containerProps={containerProps} error={error} withRightAddon={!!rightAddon} {...props} />
        {rightAddon && <InputRightAddon hasError={hasError} rightAddon={rightAddon} />}
        {icon && <StyledInputRightElement children={icon} />}
        {enableDataCopy && (
          <StyledCopyButton onClick={() => copyToClipboard((valueToCopy as any)?.toString())}>
            <Tooltip aria-label="A copy tooltip" hasArrow label={t("helpers:Copy")} placement="top">
              <CopyIcon />
            </Tooltip>
          </StyledCopyButton>
        )}
      </StyledInputGroup>
    </StyledGroup>
  );
};
