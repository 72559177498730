import { GlobalMarginCall, MarginCall, Transaction } from "interfaces/data";
import firebase from "firebase";
import { DEAL_TYPE_SELL } from "./transaction";
import { getRates } from "./rates";
import { getMarginCallBalance } from "./marginCall";

const Timestamp = firebase.firestore.Timestamp;

export const createFromMarginCall = async (marginCall: MarginCall, transaction: Transaction) => {
  const currency = transaction.dealType === DEAL_TYPE_SELL ? transaction.from.currency : transaction.to.currency;
  const rates = await getRates(marginCall.callDate.toDate().toISOString().slice(0, 10));
  const marginBalance = getMarginCallBalance(marginCall, rates, currency);

  return {
    transactions: [
      {
        number: transaction.number,
        date: transaction.createdAt,
        _id: transaction.id,
      },
    ],
    createdAt: Timestamp.now(),
    callDate: marginCall.callDate,
    callRate: Number(marginCall.callRate),
    transactionsQuantity: {
      quantity: Number(marginCall.transQuantity),
      currency,
    },
    paidMargin: {
      quantity: marginBalance,
      currency,
    },
    marginCallQuantity: {
      quantity: Number(marginCall.quantity),
      currency,
    },
    finalNoticeQuantity: {
      quantity: marginCall.quantity - marginBalance,
      currency,
    },
    bankAccount: transaction.account,
    _marginCallId: marginCall.id,
  };
};

export const createFromGlobalMarginCall = async (gmc: GlobalMarginCall, db: Firestore) => {
  if (!gmc.globalTransactionsIds) {
    throw new Error("Global transactions ids are not defined");
  }

  const transactionIds = gmc.globalTransactionsIds.map((t) => t.id);
  const transactions = await getDbTransactions(transactionIds, db);
  const rates = await getRates(gmc.callDate.toDate().toISOString().slice(0, 10));
  const marginBalance = getMarginCallBalance(gmc, rates, gmc.currency);

  return {
    transactions: transactions.map((t) => ({
      number: t.number,
      date: t.createdAt,
      _id: t.id,
    })),
    createdAt: Timestamp.now(),
    callDate: gmc.callDate,
    callRate: Number(gmc.callRate),
    transactionsQuantity: {
      quantity: Number(gmc.transQuantity),
      currency: gmc.currency,
    },
    paidMargin: {
      quantity: marginBalance,
      currency: gmc.currency,
    },
    marginCallQuantity: {
      quantity: Number(gmc.quantity),
      currency: gmc.currency,
    },
    finalNoticeQuantity: {
      quantity: gmc.quantity - marginBalance,
      currency: gmc.currency,
    },
    bankAccount: transactions[0].account,
    _marginCallId: gmc.id,
  };
};

const getDbTransactions = async (ids: string[], db: Firestore) => {
  const transactions = [];
  while (ids.length) {
    const batch = ids.splice(0, 10);
    transactions.push(
      ...(await db.collection("transactions").where(firebase.firestore.FieldPath.documentId(), "in", batch).get()).docs
    );
  }

  return transactions.map((t) => ({ ...t.data(), id: t.id } as Transaction)) || [];
};
