import { HedgeSchema } from "../../schemas/hedge";

export const fixHedge = (hedge: any) => {
  const result = HedgeSchema.safeParse(hedge);
  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return hedge;
  }

  const fixedHedge = {
    ...hedge,
    id: `${hedge.id}`,
    institution: `${hedge.institution}`,
    points: Number(hedge.points),
    quantity: Number(hedge.quantity),
    leftQuantity: Number(hedge.leftQuantity),
    security: Number(hedge.security),
  };

  if ("rolledQuantity" in fixedHedge) {
    fixedHedge.rolledQuantity = Number(fixedHedge.rolledQuantity);
  }
  if ("comment" in fixedHedge) {
    fixedHedge.comment = `${fixedHedge.comment}`;
  }
  if ("createdFromId" in fixedHedge) {
    fixedHedge.createdFromId = `${fixedHedge.createdFromId}`;
  }
  if ("createdFromData" in fixedHedge) {
    fixedHedge.createdFromData = fixedHedge.createdFromData.map((data: any) => ({
      hedgeId: `${data.hedgeId}`,
      quantity: Number(data.quantity),
    }));
  }

  return fixedHedge;
};
