import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const TransactionsIcon = createIcon({
  displayName: "TransactionsIcon",
  path: (
    <g fill="none">
      <path
        d="M4.2 1L1 4.2L4.2 7.4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M13.8 4.2002H1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path
        d="M13.8 10.6001L17 13.8001L13.8 17.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.19995 13.7998H17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 18 18",
});
