import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const AddIcon = createIcon({
  displayName: "AddIcon",
  path: (
    <g fill="none">
      <path
        d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM9 6C9 6.41421 8.66421 6.75 8.25 6.75H7.875C7.25368 6.75 6.75 7.25368 6.75 7.875V8.25C6.75 8.66421 6.41421 9 6 9C5.58579 9 5.25 8.66421 5.25 8.25V7.875C5.25 7.25368 4.74632 6.75 4.125 6.75H3.75C3.33579 6.75 3 6.41421 3 6C3 5.58579 3.33579 5.25 3.75 5.25H4.125C4.74632 5.25 5.25 4.74632 5.25 4.125V3.75C5.25 3.33579 5.58579 3 6 3C6.41421 3 6.75 3.33579 6.75 3.75V4.125C6.75 4.74632 7.25368 5.25 7.875 5.25H8.25C8.66421 5.25 9 5.58579 9 6Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 12 12",
});
