import styled from "@emotion/styled/macro";
import { InputGroup } from "@chakra-ui/react";

export const StyledGroup = styled.div`
  display: grid;
  grid-template-columns: 110px 281px;
  grid-column-gap: 10px;
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  display: block;

  input,
  select {
    background-color: white;
  }

  svg {
    margin-right: 10px;
  }
`;
