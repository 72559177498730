import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { TransactionType } from "interfaces/data";
import { TRANSACTION_TYPES } from "helpers/transaction";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Dropdown } from "components/Dropdown/Dropdown";
import {
  StyledClearButton,
  StyledDropdownButton,
  StyledDropdownContent,
} from "views/Transactions/Filters/shared.styled";
import { SelectIcon } from "theme/icons";

export const initialTypeFilterValue = [
  TRANSACTION_TYPES.FIXED.value,
  TRANSACTION_TYPES.SPOT.value,
  TRANSACTION_TYPES.ORDER.value,
];
interface TypeFilterProps {
  onChange?: (newStatusFilter: Array<TransactionType>) => void;
}

export const TypeFilter: React.FC<TypeFilterProps> = ({ onChange }) => {
  const { t } = useTranslation();

  const [typeFilters, setTypeFilters] = useState(initialTypeFilterValue);

  const handleTypeFiltersUpdate = useCallback(
    (filterKey: TransactionType) => () => {
      const newTypeFilters = typeFilters.includes(filterKey)
        ? typeFilters.filter((key) => key !== filterKey)
        : [...typeFilters, filterKey];

      setTypeFilters(newTypeFilters);
    },
    [typeFilters]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(typeFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilters]);

  const renderOptions = useCallback(() => {
    const options = [];

    for (const [, type] of Object.entries(TRANSACTION_TYPES)) {
      options.push(
        <Checkbox
          checkboxSize="small"
          isChecked={typeFilters.includes(type.value)}
          key={type.value}
          onChange={handleTypeFiltersUpdate(type.value)}
        >
          {t(`transaction:${type.label}`)}
        </Checkbox>
      );
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTypeFiltersUpdate, typeFilters]);

  return (
    <Box ml="10px">
      <Dropdown
        trigger={
          <StyledDropdownButton isActive={typeFilters.length !== initialTypeFilterValue.length}>
            <div>{t("transaction:Type")}</div>
            <SelectIcon ml="auto" />
          </StyledDropdownButton>
        }
      >
        <StyledDropdownContent>
          {renderOptions()}
          <StyledClearButton onClick={() => setTypeFilters([])}>{t("transaction:clear")}</StyledClearButton>
        </StyledDropdownContent>
      </Dropdown>
    </Box>
  );
};
