import React, { useState } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Flex, Text } from "@chakra-ui/react";
import { useTransactions } from "hooks/useTransactions";
import { PageHeader } from "components/PageHeader/PageHeader";
import { UseSearch } from "interfaces/hooks";
import { SpecialFilterTypes } from "../../Transactions/Transactions";
import { useHistory } from "react-router-dom";
import { ContentWrapper, StyledHeader } from "./MassHedgeRoll.styled";
import { Heading } from "components/Heading/Heading";
import { Spinner } from "components/Spinner/Spinner";
import { StyledBackButton } from "../../CompanyAdd/CompanyAdd.styled";
import { Box } from "components/Box/Box";
import theme from "theme/theme";
import { SelectedHedges } from "./components/SelectedHedges/SelectedHedges";
import { useTranslation } from "react-i18next";
import { TransactionTable } from "./components/TransactionTable/TransactionTable";
import { recentHedges } from "helpers/hedge";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { Hedge, Transaction, TransactionCompany } from "interfaces/data";
import { determineTransactionCurrencyPair } from "helpers/transaction";
import { ArrowLeftIcon } from "theme/icons";

dayjs.extend(isSameOrAfter);

const hedgeDeadline = ["hedge-deadline" as SpecialFilterTypes];

export interface ExtendedHedge extends Hedge {
  toCurrency: string;
  fromCurrency: string;
  currencyPair: string;
  dealType: string;
  company: TransactionCompany;
  clientRate: number;
  transactionId: string;
  transaction: Transaction;
}

export const MassHedgeRoll = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedHedges, setSelectedHedges] = useState<Array<ExtendedHedge>>([]);
  const {
    transactions: hedgeDeadlineTransactions,
    loading: hedgeDeadlineLoading,
    refetch,
  } = useTransactions({
    phrase: "",
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc" as UseSearch["orderDirection"],
    offset: 0,
    limit: 999999,
    filtersAndCustom: hedgeDeadline,
  });
  const [resetSelected, setResetSelected] = useState(false);

  const selectHedges = (hedges: ExtendedHedge[]) => {
    setResetSelected(hedges.length === 0);
    setSelectedHedges(hedges);
  };

  if (hedgeDeadlineLoading) {
    return <Spinner />;
  }

  const hedgesToExpire = hedgeDeadlineTransactions
    .map((transaction) => {
      return recentHedges(transaction?.hedges as Hedge[])
        ?.filter((hedge) => {
          const limitDate = dayjs().add(7, "day");
          const endDate = dayjs(getMilisecondsFromTimestamp(hedge.end));
          return limitDate.isSameOrAfter(endDate, "day") && endDate.isSameOrAfter(dayjs().subtract(7, "day"), "day");
        })
        ?.sort((a, b) => getMilisecondsFromTimestamp(a.end) - getMilisecondsFromTimestamp(b.end))
        .map((hedge) => {
          const extendedHedge: ExtendedHedge = {
            ...hedge,
            currencyPair: determineTransactionCurrencyPair(transaction).join(),
            fromCurrency: transaction.from.currency,
            toCurrency: transaction.to.currency,
            dealType: transaction.dealType,
            company: transaction.company,
            clientRate: transaction.clientRate,
            transactionId: transaction.id,
            transaction,
          };

          return extendedHedge;
        });
    })
    .flat();

  return (
    <>
      <PageHeader>
        <StyledHeader>
          <Flex direction="column">
            <Heading type="h1">Mass Roll Hedges</Heading>
            <StyledBackButton onClick={() => history.push(`/`)}>
              <ArrowLeftIcon mr="10px" />
              Go back
            </StyledBackButton>
          </Flex>
        </StyledHeader>
      </PageHeader>
      <ContentWrapper>
        <TransactionTable
          hedges={hedgesToExpire}
          resetSelected={resetSelected}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setSelectedHedges={selectHedges}
        />
        <div>
          <Box backgroundColor={theme.colors["sk-yellow"]} h="12px" />
          <Box backgroundColor="white" fontSize="16px" padding="18px">
            {selectedHedges && selectedHedges.length ? (
              <SelectedHedges
                hedges={selectedHedges}
                refetchTransactions={refetch}
                setSelectedHedges={selectHedges}
                startDate={selectedDate}
              />
            ) : (
              <Text as="span">{t("hedge:You must select atleast one hedge")}</Text>
            )}
          </Box>
        </div>
      </ContentWrapper>
    </>
  );
};
