import React, { ChangeEvent, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Heading/Heading";
import { PageHeader } from "components/PageHeader/PageHeader";
import { useCSVExport } from "hooks/useCSVExport";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { Checkbox } from "components/Checkbox/Checkbox";
import dayjs from "dayjs";
import { Company } from "interfaces/data";
import { useBalanceExport } from "hooks/useBalanceExport";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { SearchBox } from "components/SearchBox/SearchBox";
import { useMarginExport } from "hooks/useMarginExport";
import { useCommitmentsExport } from "hooks/useCommitmentsExport";

const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background: white;
  padding: 30px;
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  padding: 0 30px 30px;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

export function Reports() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(dayjs().startOf("month").valueOf());
  const [endDate, setEndDate] = useState(dayjs().endOf("month").valueOf());
  const [exportDate, setExportDate] = useState(dayjs().valueOf());
  const [company, setCompany] = useState<Company>();
  const [dataType, setDataType] = useState<string>("");
  const [format, setFormat] = useState<string>("csv");
  const exportCSV = useCSVExport({
    type: dataType,
    startTimestamp: startDate,
    endTimestamp: endDate,
    format,
  });
  const exportBalanceCSV = useBalanceExport({
    date: exportDate,
    company,
    format: "csv",
  });
  const exportAllBalancesCSV = useBalanceExport({
    date: exportDate,
    format: "csv",
  });
  const exportMarginCSV = useMarginExport({
    date: exportDate,
    company,
    format: "csv",
  });
  const exportAllMarginsCSV = useMarginExport({
    date: exportDate,
    format: "csv",
  });
  const exportCommitmentsCSV = useCommitmentsExport({
    date: exportDate,
    format: "csv",
  });

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("report:Reports")}</Heading>
      </PageHeader>
      <TwoColumnsLayout>
        <StyledBox>
          <Box alignItems="center" display="flex" mb="30px">
            <Input
              id="startDate"
              label={t("transaction:From")}
              name="startDate"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setStartDate(dayjs(e.target.value, "MM-DD-YYYY").valueOf());
              }}
              topLabel
              type="date"
              value={startDate}
              w={200}
            />
            <Box ml="30px">
              <Input
                id="endDate"
                label={t("transaction:To")}
                name="endDate"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEndDate(dayjs(e.target.value, "MM-DD-YYYY").valueOf());
                }}
                topLabel
                type="date"
                value={endDate}
                w={200}
              />
            </Box>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "settlements"}
              onChange={(e) => {
                setDataType(e.target.checked ? "settlements" : "");
                setFormat("csv");
              }}
            >
              {t(`transaction:Settlements`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "companies"}
              onChange={(e) => {
                setDataType(e.target.checked ? "companies" : "");
                setFormat("csv");
              }}
            >
              {t(`transaction:Companies`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "commission-fees"}
              onChange={(e) => {
                setDataType(e.target.checked ? "commission-fees" : "");
                setFormat("xlsx");
              }}
            >
              {t(`transaction:Commission Fees`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "contacts"}
              onChange={(e) => {
                setDataType(e.target.checked ? "contacts" : "");
                setFormat("csv");
              }}
            >
              {t(`transaction:Contacts`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "spots"}
              onChange={(e) => {
                setDataType(e.target.checked ? "spots" : "");
                setFormat("xlsx");
              }}
            >
              {t(`transaction:Spots`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "fixed-rates"}
              onChange={(e) => {
                setDataType(e.target.checked ? "fixed-rates" : "");
                setFormat("xlsx");
              }}
            >
              {t(`transaction:Fixed rates`)}
            </Checkbox>
          </Box>
          <Box mb="10px">
            <Checkbox
              isChecked={dataType === "margins"}
              onChange={(e) => {
                setDataType(e.target.checked ? "margins" : "");
                setFormat("xlsx");
              }}
            >
              {t(`transaction:Margins`)}
            </Checkbox>
          </Box>
          <Button design="primary" isDisabled={!dataType} mt="20px" onClick={exportCSV}>
            Export data
          </Button>
        </StyledBox>
        <StyledBox>
          <Box alignItems="center" display="flex" mb="30px">
            <Input
              id="date"
              label={t("transaction:Date")}
              name="date"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setExportDate(dayjs(e.target.value, "MM-DD-YYYY").valueOf());
              }}
              topLabel
              type="date"
              value={exportDate}
              w={200}
            />
          </Box>
          <SearchBox
            blockItem={(item: Company) => Boolean(item.isDeactivated)}
            collection="companies"
            fields="name"
            item={(item: Company) => `${item.name}`}
            label={t("transaction:Search company")}
            onBlockSelect={setCompany}
            onSelect={setCompany}
            orderBy="name"
            persist={(item: Company) => item.name}
          />
          <div>
            <Button design="primary" isDisabled={!company} mt="20px" onClick={exportBalanceCSV}>
              Export Balance
            </Button>
          </div>
          <div>
            <Button design="primary" mt="20px" onClick={exportAllBalancesCSV}>
              Export All Balances
            </Button>
          </div>
          <div>
            <Button design="primary" isDisabled={!company} mt="20px" onClick={exportMarginCSV}>
              Export Margins
            </Button>
          </div>
          <div>
            <Button design="primary" mt="20px" onClick={exportAllMarginsCSV}>
              Export All Margins
            </Button>
          </div>
          <div>
            <Button design="primary" mt="20px" onClick={exportCommitmentsCSV}>
              Export Commitments
            </Button>
          </div>
        </StyledBox>
      </TwoColumnsLayout>
    </>
  );
}
