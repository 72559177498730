import React from "react";
import { Application } from "interfaces/data";
import { Input } from "components/Input/Input";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "components/EditBox/EditBox";
import { CURRENCIES } from "helpers/options";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";

export function EditDetails({ data, handleChange, errors }: EditViewProps<Application>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["details.industry"]}
        id="industry"
        label={t("application:Industry")}
        name="details.industry"
        onChange={handleChange}
        type="text"
        value={data.details?.industry}
        withError={!!errors?.["details.industry"]}
      />
      <Input
        error={errors?.["details.password"]}
        id="password"
        label={t("application:Password")}
        name="details.password"
        onChange={handleChange}
        type="text"
        value={data.details?.password}
        withError={!!errors?.["details.password"]}
      />
      <Input
        error={errors?.["details.goal"]}
        id="goal"
        label={t("application:Purpose")}
        name="details.goal"
        onChange={handleChange}
        type="text"
        value={data.details?.goal}
        withError={!!errors?.["details.goal"]}
      />
      <Input
        error={errors?.["details.source"]}
        id="source"
        label={t("application:Origin of funds")}
        name="details.source"
        onChange={handleChange}
        type="text"
        value={data.details?.source}
        withError={!!errors?.["details.source"]}
      />
      <Input
        error={errors?.["details.limit"]}
        formatNumberOnBlur
        id="limit"
        label={t("application:Limit")}
        name="details.limit"
        onChange={handleChange}
        type="number"
        value={data.details?.limit}
        withError={!!errors?.["details.limit"]}
      />
      <AutoCompleteSelect
        error={errors?.["details.currency"]}
        id="currency"
        label={t("application:Currency")}
        name="details.currency"
        onChange={handleChange}
        options={CURRENCIES}
        value={data.details?.currency}
        withError={!!errors?.["details.currency"]}
      />
    </TwoColumnsLayout>
  );
}
