import { z } from "zod";
import { COUNTRIES, CURRENCIES } from "../helpers/options";
import firebase from "firebase";

export const YesNoSchema = z.enum(["yes", "no"], {
  errorMap: () => ({ message: "Only 'yes' and 'no' values are acceptable" }),
});

const currencies = [...CURRENCIES.map((c) => c.value)] as const;
export const CurrencySchema = z.enum(["PLN", ...currencies], {
  errorMap: () => ({ message: "Wrong currency symbol" }),
});
export const CurrencyValueSchema = z.object({
  currency: CurrencySchema,
  quantity: z.number(),
});

const countries = [...COUNTRIES.filter((c) => c.value !== "code").map((c) => c.value)] as const;
export const CountrySchema = z.enum(["AF", ...countries], {
  errorMap: () => ({ message: "Wrong country symbol" }),
});

export const BankAccountSchema = z.object({
  id: z.string().optional(),
  number: z.string().min(1, "Account number is required"),
  name: z.string().optional(),
  swift: z.string().optional(),
  currency: CurrencySchema,
  country: CountrySchema.optional(),
  isDefault: z.boolean().optional(),
});

export const TimestampSchema = z.union([
  z.object({
    _seconds: z.number(),
    _nanoseconds: z.number(),
  }),
  z.object({
    seconds: z.number(),
    nanoseconds: z.number(),
  }),
  z.instanceof(firebase.firestore.Timestamp, {
    message: "not instance of Timestamp",
  }),
]);

export const AddressSchema = z.object({
  street: z.string().nullable(),
  number: z.string().nullable(),
  code: z.string().nullable(),
  city: z.string().nullable(),
  country: CountrySchema,
});
