import styled from "@emotion/styled";
import { Button } from "../../../components/Button/Button";

export const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  display: block;
  background: ${(props) => props.theme.colors["sk-light-gray"]};
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const SelectedHedgeButton = styled.button`
  height: 44px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
`;

export const StyledDropdownItem = styled(Button)<{ muted?: boolean }>`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
  justify-content: flex-start;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 13px;
  color: ${(props) => (props.muted ? props.theme.colors["sk-light-gray"] : props.theme.colors["sk-dark"])};
  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-purple"]};
  }
`;

export const SelectedHedgeLabel = styled.div`
  display: flex;
  span {
    padding-right: 10px;
  }
`;

export const StyledClearButton = styled.button`
  text-align: left;
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-size: 13px;
  text-decoration: underline;
`;

export const StyledThreeFieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px 0 10px 45px;
`;

export const StyledLabel = styled.div`
  font-size: 14px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-right: 10px;
  width: 64px;
  text-align: right;
`;

export const StyledLabeledInput = styled.div`
  display: flex;
  align-items: center;
  > div:last-of-type {
    flex-grow: 1;
  }
`;

export const StyledSingleInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 110%;
  > div:first-of-type {
    width: 120px;
    text-align: right;
    padding-right: 10px;
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
  > div:last-of-type {
    flex-grow: 1;
    font-weight: 700;
  }
`;

export const StyledCommentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 200px;
`;

export const StyledLinksContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-decoration: underline;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

export const StyledRadioContainer = styled.div`
  margin-top: 30px;
  display: flex;
  font-weight: 800;
  font-size: 15px;
`;

export const StyledHedgeSelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 110%;
  > div:first-of-type {
    width: 120px;
    text-align: right;
    padding-right: 10px;
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
  > div:last-of-type {
    flex-grow: 1;
    font-weight: 700;
  }
  > button {
    color: ${(props) => props.theme.colors["sk-purple"]};
    margin-left: 30px;
    :disabled {
      opacity: 0.5;
      color: ${(props) => props.theme.colors["sk-gray"]};
    }
  }
`;

export const StyledSelectedHedgesList = styled.div`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  height: 100px;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-left: 120px;
`;

export const StyledSelectedHedgesListItem = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  padding: 5px 20px;
  min-height: 40px;
  font-size: 14px;
  button {
    display: none;
    margin-left: auto;
  }
  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-dark"]};
  }
  &:hover button {
    display: block;
  }
`;

export const SelectedHedgeListLabel = styled.div`
  display: flex;
  span {
    padding-right: 10px;
  }
`;

export const StyledAddButtonContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const StyledLinkPlaceholder = styled.span`
  cursor: pointer;
`;
