import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { Spinner } from "../../../components/Spinner/Spinner";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useEmailPassword } from "../../../hooks/useEmailPassword";
import { useToast } from "../../../hooks/useToast";

const StyledHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  padding-bottom: 30px;
`;

const StyledPanel = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background-color: white;
  padding: 30px;
`;

export function Email() {
  const { t } = useTranslation();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { updateEmailPassword, isSending } = useEmailPassword();

  const handleSubmit = useCallback(
    (email: string, password: string) => {
      updateEmailPassword(email, password)
        .then((success) => {
          if (success) {
            toast({
              type: "success",
              message: t("company:Email password has been updated successfully."),
            });
            setEmail("");
            setPassword("");
          }
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(`company:${error.message}`),
          });
        });
    },
    [t, toast, updateEmailPassword]
  );

  return (
    <StyledPanel>
      {isSending && <Spinner />}
      <StyledHeader>{t("settings:Email")}</StyledHeader>
      <Box maxWidth="700px">
        <TwoColumnsLayout>
          <Input
            id="app-email"
            label={t("settings:Email")}
            name="app-email"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            topLabel
            type="email"
            value={email}
          />
          <Input
            id="app-password"
            label={t("settings:Password")}
            name="app-password"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            topLabel
            type="text"
            value={password}
          />
        </TwoColumnsLayout>
      </Box>
      <Button
        design="primary"
        isDisabled={email.length === 0 || password.length === 0 || isSending}
        mt="30px"
        onClick={() => handleSubmit(email, password)}
      >
        {t("Save")}
      </Button>
    </StyledPanel>
  );
}
