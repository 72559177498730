import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Grid } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Modal } from "components/Modal/Modal";
import { BankAccount } from "interfaces/data";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { Checkbox } from "components/Checkbox/Checkbox";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { CURRENCIES } from "helpers/options";
import { Label } from "components/Label/Label";

export const StyledCheckbox = styled.div`
  margin-top: 30px;
  text-align: right;
`;

export const StyledCurrencySelectContainer = styled.div`
  display: grid;
  grid-template-columns: 110px 281px;
  grid-column-gap: 10px;
`;

interface BankAccountFormModalProps {
  type: "add" | "edit";
  title: string;
  initialBankAccount: BankAccount;
  errors: any;
  onSave: (newBankAccount: BankAccount) => void;
  onClose: () => void;
  isDisabled?: boolean;
}

export const BankAccountFormModal: React.FC<BankAccountFormModalProps> = ({
  type,
  title,
  initialBankAccount,
  errors,
  onSave,
  onClose,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const [editedBankAccount, setEditedBankAccount] = useState<BankAccount>(_cloneDeep(initialBankAccount));
  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    e.persist();
    setEditedBankAccount((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, e.target.value);
      return newEditedData;
    });
  }, []);

  const handleCheckboxUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditedBankAccount((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, e.target.checked);
      return newEditedData;
    });
  }, []);

  if (!editedBankAccount) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isDisabled={isDisabled}
      isOpen
      justifyButtons="flex-end"
      onClose={onClose}
      onConfirm={() => onSave(editedBankAccount)}
      title={title}
    >
      <Grid paddingLeft="30px" rowGap="10px">
        <InputWithLabel
          error={errors?.["number"]}
          id="number"
          label={t("settings:Number")}
          name="number"
          onChange={handleUpdate}
          type="text"
          value={editedBankAccount?.number}
          withError={!!errors?.["number"]}
        />
        {type === "add" ? (
          <StyledCurrencySelectContainer>
            <Label>{t("settings:Currency")}</Label>
            <AutoCompleteSelect
              error={errors?.["currency"]}
              id="currency"
              name="currency"
              onChange={handleUpdate}
              options={CURRENCIES}
              value={editedBankAccount?.currency}
              withError={!!errors?.["currency"]}
            />
          </StyledCurrencySelectContainer>
        ) : (
          <InputWithLabel
            id="currency"
            isDisabled
            label={t("settings:Currency")}
            name="currency"
            type="text"
            value={editedBankAccount?.currency}
          />
        )}
        <InputWithLabel
          error={errors?.["swift"]}
          id="swift"
          label={t("settings:SWIFT/BIC")}
          name="swift"
          onChange={handleUpdate}
          type="text"
          value={editedBankAccount?.swift}
          withError={!!errors?.["swift"]}
        />
        <InputWithLabel
          error={errors?.["name"]}
          id="name"
          label={t("settings:Bank name")}
          name="name"
          onChange={handleUpdate}
          type="text"
          value={editedBankAccount?.name}
          withError={!!errors?.["name"]}
        />
      </Grid>
      <StyledCheckbox>
        <Checkbox
          fontSize="15px"
          fontWeight="bold"
          id="isDefault"
          isChecked={editedBankAccount?.isDefault}
          name="isDefault"
          onChange={handleCheckboxUpdate}
        >
          {t("settings:Default bank account for the currency")}
        </Checkbox>
      </StyledCheckbox>
    </Modal>
  );
};
