import Decimal from "decimal.js";

export const formatNumberToString = (number: number | string, precision?: number) => {
  if (number == null || number === "") return;
  const parts = String(new Decimal(number).toDecimalPlaces(precision || 2)).split(".");
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  return parts.join(".");
};

export const formatNumber = (number?: number, precision?: number): number => {
  if (number == null) return 0;
  return Number(new Decimal(number).toDecimalPlaces(precision || 2));
};

export const formatRate = (rate: number | string, toFixed = 2) => {
  if (!rate && rate !== 0) return 0;
  const dec = rate.toString().split(".")[1];
  return dec?.length < toFixed ? rate : Number(rate).toFixed(toFixed);
};

export const formatFixedRate = (rate: number | string, toFixed = 2) => {
  if (!rate && rate !== 0) return 0;
  return Number(rate).toFixed(toFixed);
};
