import React from "react";
import { BaseCompany } from "interfaces/data";
import { Input } from "components/Input/Input";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "components/EditBox/EditBox";
import { Select } from "components/Select/Select";

export function EditOtherInfo({ data, errors, handleChange }: EditViewProps<BaseCompany>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const communicationLanguageOptions = [
    { label: "" },
    { label: t("Polish"), value: "PL" },
    { label: t("English"), value: "EN" },
  ];

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["other.notes"]}
        fullWidth
        id="other.notes"
        label={t("company:Description")}
        name="other.notes"
        onChange={handleChange}
        type="text"
        value={data.other?.notes}
        withError={!!errors?.["other.notes"]}
      />
      <Select
        error={errors?.["other.communicationLanguage"]}
        id="communicationLanguage"
        label={t("company:Communication language")}
        name="other.communicationLanguage"
        onChange={handleChange}
        options={communicationLanguageOptions}
        value={data.other?.communicationLanguage}
      />
      <Input
        error={errors?.["other.ip"]}
        id="other.ip"
        isDisabled
        name="other.ip"
        type="text"
        value={data.other?.ip ? data.other.ip : (t("company:No IP Address") as string)}
        withError={!!errors?.["other.ip"]}
      />
    </TwoColumnsLayout>
  );
}
