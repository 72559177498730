import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { StyledTableContainer } from "views/Transactions/Transactions";

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
`;

export const StyledTableWrapper = styled(StyledTableContainer)`
  max-height: 760px;
  overflow-y: auto;
`;

export const StyledNotes = styled.div<{ column?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: ${({ column }) => column && "column"};
  align-items: ${({ column }) => (column ? "flex-start" : "center")};
  font-weight: 600;
  color: ${(props) => props.theme.colors["sk-dark"]};
  width: 100%;
  min-height: 15px;
  max-width: 150px;
`;
