import React from "react";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { StyledButtonsContainer, StyledIconContainer } from "./margin.styled";
import { MarginCallExtended } from "../MarginCalls";
import { Link } from "react-router-dom";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";

export function ActionsCell(arg: any) {
  const marginCall = arg.row.original as MarginCallExtended;
  if (!marginCall || marginCall.isClosed === "yes") return "";
  const id = marginCall.transaction.company.id;

  return (
    <RestrictAccess area={ADD_MARGIN_CALLS}>
      <StyledButtonsContainer>
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/companies/${id}/transactions/${marginCall.transaction.id}/margin-calls/${marginCall.id}/add`}
        >
          <StyledIconContainer>
            <SmallAddIcon boxSize="22px" />
          </StyledIconContainer>
        </Link>
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/companies/${id}/transactions/${marginCall.transaction.id}/margin-calls/${marginCall.id}/withdraw`}
        >
          <StyledIconContainer>
            <MinusIcon />
          </StyledIconContainer>
        </Link>
      </StyledButtonsContainer>
    </RestrictAccess>
  );
}
