import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SelectIcon = createIcon({
  displayName: "SelectIcon",
  path: (
    <g fill="none">
      <path
        d="M6 3.5L3.5 1 1 3.5M6 7.5L3.5 10 1 7.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: "0 0 7 11",
});
