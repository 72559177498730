import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../../components/Table/Table";
import { Text } from "@chakra-ui/react";
import { StyledContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { DateCell } from "../../../helpers/tableCells";
import { formatNumberToString } from "../../../helpers/formatNumber";
import { Transaction } from "../../../interfaces/data";
import styled from "@emotion/styled";
import { convertLocalMarginsToTableRows } from "../../../helpers/360view";
import { StyledButtonContainer } from "./Transactions/Transactions";
import { Button } from "../../../components/Button/Button";

interface InitialMarginInfo {
  id: string;
  transactionId?: string;
  date?: firebase.firestore.Timestamp;
  quantity: number;
  remainingQty: number;
  currency: string;
}

interface InitialMarginsProps {
  transactions?: Array<Transaction>;
  nbpRates: any;
}

function QuantityRemainingRatioCell(arg: any) {
  const row = arg.row.original as InitialMarginInfo;
  return (
    <Text as="span" display="block" textAlign="center" whiteSpace="nowrap">
      {formatNumberToString(row.quantity)}
    </Text>
  );
}
const StyledTableContainer = styled.div`
  tr.total-row,
  tr.total-row:hover {
    background: #f9f9f9;
    font-weight: 900;
  }
  tr.total-row td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tr.total-row td:nth-of-type(2) {
    opacity: 0;
  }
`;

export const InitialMargins: React.FC<InitialMarginsProps> = ({ transactions, nbpRates }) => {
  const { t } = useTranslation();
  const [showWithdrawn, setShowWithdrawn] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
      },
      {
        Header: t("company:Date "),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
            {t("company:Quantity")}
          </Text>
        ),
        accessor: "quantityRemainingRatio",
        Cell: QuantityRemainingRatioCell,
      },
      {
        Header: t("company:Currency"),
        accessor: "currency",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initialMarginsRows = useMemo(() => {
    if (!transactions || !nbpRates) return [];
    return convertLocalMarginsToTableRows(transactions, nbpRates, "IM", false);
  }, [nbpRates, transactions]);

  const initialMarginsRowsWithdrawn = useMemo(() => {
    if (!transactions || !nbpRates) return [];
    return convertLocalMarginsToTableRows(transactions, nbpRates, "IM", true);
  }, [nbpRates, transactions]);

  if (!transactions || (initialMarginsRows.length === 1 && initialMarginsRowsWithdrawn.length === 1)) return null;

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("company:Initial margins")}</StyledSectionName>
        <StyledButtonContainer>
          <Button
            borderRadius="12px"
            design={!showWithdrawn ? "primary" : "ghost"}
            height="32px"
            mr="4px"
            onClick={() => setShowWithdrawn(false)}
            opacity={!showWithdrawn ? 1 : 0.5}
          >
            Open
          </Button>
          <Button
            borderRadius="12px"
            design={showWithdrawn ? "primary" : "ghost"}
            height="32px"
            onClick={() => setShowWithdrawn(true)}
            opacity={showWithdrawn ? 1 : 0.5}
          >
            Withdrawn
          </Button>
        </StyledButtonContainer>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={showWithdrawn ? initialMarginsRowsWithdrawn : initialMarginsRows}
          getRowProps={(rowInfo) => {
            const row = rowInfo.original;
            return ["Currency subtotal", "Total in PLN"].includes(row.id)
              ? { className: "total-row" }
              : {
                  url: `/transactions/${row.transactionId}`,
                  fromPath: window.location.pathname,
                };
          }}
          paginated={false}
        />
      </StyledTableContainer>
    </StyledContainer>
  );
};
