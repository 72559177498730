import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCompany } from "hooks/useCompany";
import { Spinner } from "components/Spinner/Spinner";
import { StyledBackButton } from "components/ItemsListElements/ItemsListElements";
import styled from "@emotion/styled";
import { OperationExtended, Operations } from "./Main/Operations";
import { Transactions } from "./Main/Transactions/Transactions";
import { MarginCallExtended, MarginCalls } from "./Main/MarginCalls";
import { Statistics } from "./Statistics";
import { RightSidebar } from "./RightSidebar/RightSidebar";
import { useTransactions } from "hooks/useTransactions";
import { getOperations, isInactive, TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import { useNbpRates } from "hooks/useNbpRates";
import { PageHeader } from "components/PageHeader/PageHeader";
import { InitialMargins } from "./Main/InitialMargins";
import { VariationMargins } from "./Main/VariationMargins";
import { useLastActivity } from "hooks/useLastActivity";
import { getCompanyOperations } from "helpers/company";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { Heading } from "components/Heading/Heading";
import { GlobalMarginCalls } from "./Main/GlobalMarginCalls";
import { ArrowLeftIcon } from "theme/icons";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-column-gap: 40px;
`;

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  div:last-of-type {
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    flex-wrap: nowrap;

    div:first-of-type {
      position: absolute;
      padding-top: 7px;
    }

    div:last-of-type {
      margin-top: 0;
      padding: 0 15%;
      justify-content: center;
    }
  }
`;
export const Company360: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { company, loading: companyLoading, update: updateCompany, globalMarginCommentUpdate } = useCompany({ id });
  const history = useHistory();
  const { t } = useTranslation();
  const { rates: nbpRates, loading: nbpRatesLoading } = useNbpRates();
  const { addNewVisitedCompaniesInfo } = useLastActivity();

  useEffect(() => {
    if (company) {
      addNewVisitedCompaniesInfo(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const filtersAnd = useMemo(
    () => [
      `company.id:${id}`,
      `status:${TRANSACTION_STATUSES.CREATED.value},${TRANSACTION_STATUSES.PARTIALLY_SETTLED.value},${TRANSACTION_STATUSES.SETTLED.value},${TRANSACTION_STATUSES.ROLLED.value},${TRANSACTION_STATUSES.CLOSED.value}`,
    ],
    [id]
  );

  const { transactions: allTransactions, loading: transactionsLoading } = useTransactions({
    phrase: "",
    filtersAnd,
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 0,
  });

  const transactions = useMemo(() => {
    return allTransactions.filter((transaction) =>
      [TRANSACTION_STATUSES.CREATED.value, TRANSACTION_STATUSES.PARTIALLY_SETTLED.value].includes(transaction.status)
    );
  }, [allTransactions]);

  const settledTransactionsWithoutOrder = useMemo(() => {
    return allTransactions.filter(
      (transaction) => isInactive(transaction) && transaction.type !== TRANSACTION_TYPES.ORDER.value
    );
  }, [allTransactions]);

  const transactionsWithoutOrders = useMemo(
    () => transactions.filter((transaction) => transaction.type !== TRANSACTION_TYPES.ORDER.value),
    [transactions]
  );

  const globalMarginCalls = useMemo(() => {
    return company?.globalMarginCalls?.map((marginCall) => ({
      ...marginCall,
      company,
    }));
  }, [company]);

  const marginCalls = useMemo(() => {
    if (!allTransactions) return [];
    return allTransactions.reduce((marginCallsArray, currentTransaction) => {
      if (!currentTransaction.marginCalls) return marginCallsArray;

      const currentMarginCalls = currentTransaction.marginCalls.map((marginCall) => ({
        ...marginCall,
        currency: currentTransaction.from.currency,
        transaction: currentTransaction,
        baseQty: currentTransaction.from.quantity,
      })) as Array<MarginCallExtended>;

      return [...marginCallsArray, ...currentMarginCalls];
    }, [] as Array<MarginCallExtended>);
  }, [allTransactions]);

  const settledTransactionsOrders = useMemo(() => {
    return allTransactions.filter(
      (transaction) => isInactive(transaction) && transaction.type === TRANSACTION_TYPES.ORDER.value
    );
  }, [allTransactions]);

  const transactionsOrders = useMemo(
    () => transactions.filter((transaction) => transaction.type === TRANSACTION_TYPES.ORDER.value),
    [transactions]
  );

  const operations = useMemo(() => {
    if (!allTransactions || !company) return [];
    const transactionOperations = allTransactions.reduce((operationsAcc, currTransaction) => {
      return [...operationsAcc, ...getOperations(currTransaction)];
    }, [] as Array<OperationExtended>);
    const companyOperations = getCompanyOperations(company);

    return [...transactionOperations, ...companyOperations].sort((a, b) =>
      getMilisecondsFromTimestamp(a.date) < getMilisecondsFromTimestamp(b.date) ? 1 : -1
    );
  }, [allTransactions, company]);

  const notesUpdate = useCallback(
    async (notes: string) => {
      if (!company) return;

      const newCompany = {
        ...company,
        details: {
          ...company.details,
          notes,
        },
      };

      await updateCompany(newCompany);
    },
    [company, updateCompany]
  );

  if (companyLoading || transactionsLoading || nbpRatesLoading) {
    return <Spinner />;
  }

  if (!company) {
    return <>{t("company:Company not found")}</>;
  }

  return (
    <>
      <PageHeader>
        <StyledHeader>
          <Flex>
            <StyledBackButton onClick={() => history.push("/companies")}>
              <ArrowLeftIcon mr="10px" />
              {t("company:All companies")}
            </StyledBackButton>
          </Flex>
          <Flex width="100%">
            <Heading type="h1">{company.name}</Heading>
          </Flex>
        </StyledHeader>
      </PageHeader>
      <StyledGrid>
        <div>
          <Statistics company={company} nbpRates={nbpRates} transactions={transactions} />
          {operations && operations?.length > 0 ? <Operations operations={operations} /> : null}
          <Transactions
            company={company}
            settledTransactions={settledTransactionsWithoutOrder}
            transactions={transactionsWithoutOrders}
          />
          {transactionsOrders.length ? (
            <Transactions
              company={company}
              isOrder
              settledTransactions={settledTransactionsOrders}
              transactions={transactionsOrders}
            />
          ) : null}
          <InitialMargins nbpRates={nbpRates} transactions={allTransactions} />
          <VariationMargins
            company={company}
            globalMarginCommentUpdate={globalMarginCommentUpdate}
            marginCalls={marginCalls}
            nbpRates={nbpRates}
            transactions={allTransactions}
          />

          <GlobalMarginCalls marginCalls={globalMarginCalls} />
          <MarginCalls marginCalls={marginCalls} />
        </div>
        <RightSidebar company={company} notesUpdate={notesUpdate} />
      </StyledGrid>
    </>
  );
};
