import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import {
  StyledAddbutton,
  StyledButtonContainer,
  StyledContainer,
  StyledDeleteButton,
  StyledSectionHeader,
  StyledSectionName,
  StyledTableContainer,
  StyledTableControls,
} from "./shared";
import { useToast } from "hooks/useToast";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input/Input";
import { useValidation } from "hooks/useValidation";
import { questionSchema } from "validations/scoring";
import { useTranslation } from "react-i18next";
import { useScoring } from "hooks/useScoring";
import { Table } from "components/Table/Table";
import { Box, useDisclosure } from "@chakra-ui/react";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { SHOW_SCORING } from "helpers/userRoles";
import { Question } from "interfaces/data";
import { DeleteIcon } from "@chakra-ui/icons";
import { AddIcon, EditIcon } from "theme/icons";

const createDeleteCell = (openModal: () => void, setDeleteRow: Dispatch<SetStateAction<Question | null>>) => {
  return (arg: any) => {
    return (
      <RestrictAccess area={SHOW_SCORING}>
        <StyledButtonContainer>
          <StyledDeleteButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDeleteRow(arg.row.original);
              openModal();
            }}
          >
            <DeleteIcon />
          </StyledDeleteButton>
        </StyledButtonContainer>
      </RestrictAccess>
    );
  };
};

const EditCell = (arg: any) => {
  const question = arg.row.original;
  const { t } = useTranslation();
  const toast = useToast();
  const { editQuestion } = useScoring();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionWeight, setQuestionWeight] = useState<number>(question.weight);
  const [questionTitle, setQuestionTitle] = useState<string | undefined>(question.title);

  const { validate: validateQuestion, errors: addQuestionErrors, clearErrors } = useValidation(questionSchema);

  const handleSubmit = async () => {
    await validateQuestion({
      title: questionTitle,
      weight: questionWeight,
    });
    if (questionWeight && questionTitle) {
      try {
        await editQuestion({
          ...question,
          weight: questionWeight,
          title: questionTitle,
          status: "active",
        });
        handleClose();
        toast({
          type: "success",
          message: t("scoring:Question successfully edited."),
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RestrictAccess area={SHOW_SCORING}>
      <Modal
        closeOnOverlayClick={false}
        confirmText={t("scoring:Edit")}
        design="primary"
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleSubmit}
        title={t("scoring:Edit question")}
      >
        <Box mb={"20px"}>
          <Input
            error={addQuestionErrors?.["title"]}
            id="title"
            label={t("scoring:Question")}
            name="title"
            onChange={(e: any) => setQuestionTitle(e.target.value)}
            type="text"
            value={questionTitle}
            withError={!!addQuestionErrors?.["title"]}
          />
        </Box>
        <Box mb={"20px"}>
          <Input
            error={addQuestionErrors?.["weight"]}
            id="weight"
            label={t("scoring:Weight")}
            name="weight"
            onChange={(e: any) => setQuestionWeight(e.target.value)}
            type="number"
            value={questionWeight}
            withError={!!addQuestionErrors?.["weight"]}
          />
        </Box>
      </Modal>
      <StyledButtonContainer>
        <StyledDeleteButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        >
          <EditIcon />
        </StyledDeleteButton>
      </StyledButtonContainer>
    </RestrictAccess>
  );
};

export const Questions = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { addQuestion, deleteQuestion, activeQuestions } = useScoring();
  const [questionWeight, setQuestionWeight] = useState<number>(0);
  const [questionTitle, setQuestionTitle] = useState<string | undefined>(undefined);
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<Question | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { validate: validateQuestion, errors: addQuestionErrors, clearErrors } = useValidation(questionSchema);

  const handleSubmit = async () => {
    await validateQuestion({
      title: questionTitle,
      weight: questionWeight,
    });

    if (questionWeight && questionTitle) {
      try {
        await addQuestion({
          id: 0,
          weight: questionWeight,
          title: questionTitle,
          status: "active",
        });
        handleClose();
        toast({
          type: "success",
          message: t("scoring:Question successfully added."),
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setDeleteRow(null);
    setAddQuestionModalOpen(false);
    setQuestionTitle(undefined);
    setQuestionWeight(0);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = useCallback(() => {
    if (deleteRow) {
      deleteQuestion(deleteRow?.id).then(async () => {
        handleClose();
        toast({
          type: "success",
          message: t("contact:Question successfully deleted."),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRow]);

  const columns = useMemo(
    () => [
      {
        Header: t("scoring:Question"),
        accessor: "title",
      },
      {
        Header: t("scoring:Weight"),
        accessor: "weight",
      },
      {
        Header: "",
        accessor: "editAction",
        Cell: EditCell,
      },
      {
        Header: "",
        accessor: "deleteAction",
        Cell: createDeleteCell(onOpen, setDeleteRow),
      },
    ],
    [onOpen, t]
  );

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        confirmText={t("scoring:Add")}
        design="primary"
        isOpen={addQuestionModalOpen}
        onClose={handleClose}
        onConfirm={handleSubmit}
        title={t("scoring:Add question")}
      >
        <Box mb={"20px"}>
          <Input
            error={addQuestionErrors?.["title"]}
            id="title"
            label={t("scoring:Question")}
            name="title"
            onChange={(e: any) => setQuestionTitle(e.target.value)}
            type="text"
            value={questionTitle}
            withError={!!addQuestionErrors?.["title"]}
          />
        </Box>
        <Box mb={"20px"}>
          <Input
            error={addQuestionErrors?.["weight"]}
            id="weight"
            label={t("scoring:Weight")}
            name="weight"
            onChange={(e: any) => setQuestionWeight(e.target.value)}
            type="number"
            value={questionWeight}
            withError={!!addQuestionErrors?.["weight"]}
          />
        </Box>
      </Modal>
      <Modal
        closeOnOverlayClick={false}
        confirmText={t("contact:Yes, delete it")}
        design="danger"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleRemove}
        title={t("scoring:Delete question")}
      >
        <Box mb={"20px"}>
          {t("contact:This action can't be undone. Are you sure you want to delete this question?")}
        </Box>
      </Modal>
      <StyledContainer>
        <StyledSectionHeader>
          <StyledSectionName>{t("scoring:Scoring questionare")}</StyledSectionName>
          <StyledTableControls>
            <RestrictAccess area={SHOW_SCORING}>
              <StyledAddbutton onClick={() => setAddQuestionModalOpen(true)}>
                <AddIcon mr="10px" />
                New question
              </StyledAddbutton>
            </RestrictAccess>
          </StyledTableControls>
        </StyledSectionHeader>
        <StyledTableContainer>
          <Table columns={columns} data={activeQuestions} paginated={false} />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
};
