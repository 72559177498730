import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputLeftElement } from "@chakra-ui/react";
import { Company } from "interfaces/data";
import useDebounce from "hooks/useDebounce";
import { useSearch } from "hooks/useSearch";
import { Spinner } from "components/Spinner/Spinner";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import {
  StyledContainer,
  StyledContent,
  StyledHeaderItem,
  StyledInput,
  StyledInputGroup,
  StyledItem,
  StyledNoData,
  StyledPane,
} from "./SearchBox.styled";
import { SearchIcon } from "theme/icons";

type SearchBoxProps = {
  collection: string;
  fields: string;
  item: (data: any) => React.ReactNode;
  persist?: (data: any) => string;
  blockItem?: (data: any) => boolean;
  onBlockSelect?: (data: any) => void;
  onSelect: (data: undefined | Company | any) => void;
  label?: string;
  placeholder?: string;
  resultsHeader?: React.ReactNode;
  orderBy: string;
  orderDirection?: "desc" | "asc" | string;
  limit?: number;
  filtersOr?: Array<string>;
  filtersAnd?: Array<string>;
  error?: string;
  withError?: boolean;
};

export const SearchBox: React.FC<SearchBoxProps> = ({
  collection,
  fields,
  item,
  persist,
  onSelect,
  label,
  placeholder,
  resultsHeader,
  orderBy,
  orderDirection = "asc",
  limit = 50,
  filtersOr,
  filtersAnd,
  error,
  withError,
  onBlockSelect,
  blockItem = () => false,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const debouncedSearchQuery = useDebounce(query, 400);
  const { data, loading } = useSearch<any>({
    collection,
    fields,
    orderBy,
    orderDirection,
    limit,
    filtersOr,
    filtersAnd,
    phrase: debouncedSearchQuery,
    offset: 0,
    skipAutoUpdate: true,
  });
  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => setIsOpen(false));

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setQuery(newValue);
      if (newValue === "") {
        onSelect(undefined);
        setIsOpen(true);
      }
    },
    [onSelect]
  );

  const handleOnSelect = (dataItem: unknown) => {
    const persisted = typeof persist === "function" ? persist(dataItem) : "";

    setQuery(persisted);
    setIsOpen(false);
    onSelect(dataItem);
  };

  const handleClick = (data: unknown) => {
    if (!blockItem(data)) {
      return handleOnSelect(data);
    }

    return typeof onBlockSelect === "function" ? onBlockSelect(data) : () => null;
  };

  return (
    <StyledContainer ref={containerRef}>
      <StyledInputGroup>
        <InputLeftElement children={<SearchIcon />} />
        <StyledInput
          containerProps={{
            width: "100%",
          }}
          error={error}
          fullWidth
          label={label}
          labelProps={{
            marginLeft: "33px",
          }}
          onChange={handleSearchInputUpdate}
          onClick={() => setIsOpen(true)}
          placeholder={placeholder}
          value={query}
          withError={withError}
        />
      </StyledInputGroup>
      {isOpen && (
        <StyledContent>
          {resultsHeader ? resultsHeader : <StyledHeaderItem>{t("Results")}</StyledHeaderItem>}
          <StyledPane className="search-box-pane">
            {data.length === 0 && <StyledNoData>No data</StyledNoData>}
            {!loading ? (
              data.map((dataItem, i) =>
                item(dataItem) ? (
                  <StyledItem
                    design="link"
                    isBlocked={blockItem(dataItem)}
                    key={i}
                    onClick={() => handleClick(dataItem)}
                  >
                    {item(dataItem)}
                  </StyledItem>
                ) : null
              )
            ) : (
              <Spinner />
            )}
          </StyledPane>
        </StyledContent>
      )}
    </StyledContainer>
  );
};
