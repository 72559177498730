import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import _cloneDeep from "lodash.clonedeep";
import { StyledInput, StyledInputGroup } from "components/ItemsListElements/ItemsListElements";
import { Box, InputLeftElement, useDisclosure } from "@chakra-ui/react";
import { StyledPanel } from "../Settings";
import styled from "@emotion/styled";
import { Institution } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { useInstitutions } from "hooks/useInstitutions";
import { InstitutionFormModal } from "./InstitutionFormModal";
import { EditIcon, RemoveIcon, SearchIcon } from "theme/icons";

const StyledTableControls = styled.div`
  padding: 30px 30px 0 30px;
  display: flex;
`;

export const StyledTableContainer = styled.div`
  tr.default-account-row {
    font-weight: 900;
  }
  tr td:last-child,
  tr td:nth-last-child(2) {
    width: 120px;
  }
`;
function createEditCell(
  openModal: () => void,
  label: string,
  setEditRow: React.Dispatch<React.SetStateAction<Institution | null>>
) {
  return (arg: any) => {
    return (
      <Box textAlign="right">
        <button
          onClick={(e) => {
            setEditRow(_cloneDeep(arg.row.original));
            openModal();
            e.stopPropagation();
          }}
        >
          <EditIcon ml="auto" mr="5px" mt="-2px" /> {label}
        </button>
      </Box>
    );
  };
}

function createDeleteCell(
  openModal: () => void,
  label: string,
  setEditRow: React.Dispatch<React.SetStateAction<Institution | null>>
) {
  return (arg: any) => {
    return (
      <Box textAlign="right">
        <button
          onClick={(e) => {
            setEditRow(_cloneDeep(arg.row.original));
            openModal();
            e.stopPropagation();
          }}
        >
          <RemoveIcon ml="auto" mr="5px" mt="-2px" />
          {label}
        </button>
      </Box>
    );
  };
}

export function Institutions() {
  const { t } = useTranslation();
  const { institutions, update, save, remove, refetch, errors, clearErrors } = useInstitutions();
  const [searchQuery, setSearchQuery] = useState<string>();
  const toast = useToast();
  const [editedInstitution, setEditedInstitution] = useState<Institution | null>(null);
  const [deletedInstitution, setDeletedInstitution] = useState<Institution | null>(null);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [isSending, setIsSending] = useState(false);

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleAddClose = useCallback(() => {
    onAddClose();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClose = useCallback(() => {
    onEditClose();
    setEditedInstitution(null);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClose = useCallback(() => {
    onEditClose();
    setDeletedInstitution(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = useCallback(
    (newInstitution: Institution) => {
      setIsSending(true);
      save(newInstitution).then((isSaved) => {
        setIsSending(false);
        if (isSaved) {
          onAddClose();
          refetch();
          toast({
            type: "success",
            message: t("settings:Institution has been created successfully."),
          });
        }
      });
    },
    [onAddClose, refetch, save, t, toast]
  );

  const handleEdit = useCallback(
    (newInstitution: Institution) => {
      setIsSending(true);
      update(newInstitution).then((isSaved) => {
        setIsSending(false);
        if (isSaved) {
          onEditClose();
          setEditedInstitution(null);
          refetch();
          toast({
            type: "success",
            message: t("settings:Institution has been updated successfully."),
          });
        }
      });
    },
    [onEditClose, refetch, t, toast, update]
  );

  const handleDelete = useCallback(() => {
    if (!deletedInstitution || !deletedInstitution.id) return;
    setIsSending(true);
    remove(deletedInstitution)
      .then((result) => {
        setIsSending(false);
        if (result) {
          onDeleteClose();
          setDeletedInstitution(null);
          refetch();
          toast({
            type: "success",
            message: t("settings:Institution has been deleted successfully."),
          });
        }
      })
      .catch((error) => {
        setIsSending(false);
        onDeleteClose();
        setDeletedInstitution(null);
        toast({
          type: "error",
          message: t(`settings:${error.message}`),
        });
      });
  }, [deletedInstitution, onDeleteClose, refetch, remove, t, toast]);

  const filteredInstitutions = useMemo(() => {
    if (!institutions) return [];
    const institutionsCopy = _cloneDeep(institutions);

    return searchQuery
      ? institutionsCopy.filter((institution) => {
          return (
            institution.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            String(institution.securityDeposit).toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      : institutionsCopy;
  }, [institutions, searchQuery]);

  const sortedInstitutions = useMemo(
    () => filteredInstitutions.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [filteredInstitutions]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("settings:Institution name"),
        accessor: "name",
      },
      {
        Header: t("settings:Security deposit"),
        accessor: "securityDeposit",
      },
      {
        accessor: "editAction",
        Cell: createEditCell(onEditOpen, t("Edit"), setEditedInstitution),
        width: 90,
      },
      {
        accessor: "deleteAction",
        Cell: createDeleteCell(onDeleteOpen, t("Delete"), setDeletedInstitution),
      },
    ],
    [onDeleteOpen, onEditOpen, t]
  );

  if (!institutions) return null;

  return (
    <>
      <StyledPanel>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <Box ml="30px">
            <Button design="primary" onClick={onAddOpen}>
              {t("settings:Add institution")}
            </Button>
          </Box>
        </StyledTableControls>
        <StyledTableContainer>
          <Table columns={columns} data={sortedInstitutions} paginated={false} />
        </StyledTableContainer>
      </StyledPanel>
      {isEditOpen && editedInstitution && (
        <InstitutionFormModal
          errors={errors}
          initialInstitution={editedInstitution}
          isDisabled={isSending}
          onClose={handleEditClose}
          onSave={handleEdit}
          title={t("settings:Edit institution")}
        />
      )}
      {isAddOpen && (
        <InstitutionFormModal
          errors={errors}
          initialInstitution={{} as Institution}
          isDisabled={isSending}
          onClose={handleAddClose}
          onSave={handleAdd}
          title={t("settings:Add institution")}
        />
      )}
      <Modal
        confirmText={t("Yes")}
        design="danger"
        isDisabled={isSending}
        isOpen={isDeleteOpen && Boolean(deletedInstitution)}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        title={t(`settings:Delete institution`)}
      >
        <Box mb={"20px"}>
          {t(`settings:This action can't be undone. Are you sure you want to delete institution {{institutionName}}?`, {
            institutionName: deletedInstitution?.name,
          })}
        </Box>
      </Modal>
    </>
  );
}
