import { z } from "zod";
import { AddressSchema, TimestampSchema, YesNoSchema } from "./common";

export const ContactSchema = z
  .object({
    id: z.string(),
    createdAt: TimestampSchema.optional(),
    modifiedAt: TimestampSchema.optional(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().nullish(),
    phone: z.string(),
    address: AddressSchema.optional(),
    idDetails: z.object({
      pesel: z.string(),
      idType: z.string().nullable(),
      number: z.string(),
      expirationDate: z.string().nullable(),
    }),
    status: z.enum(["Active", "Inactive"]),
    confirmed: z.boolean().optional(),
    ip: z.string().optional().nullable(),
    type: z.enum(["sales", "external", "authorized person", "legal representant", "beneficiary"]).optional(),
    companyAssociated: z
      .object({
        id: z.string(),
        name: z.string(),
      })
      .optional(),
    cc: YesNoSchema.optional(),
    source: z.string().optional(),
    other: z.string().optional(),
    citizenship: z.string().optional(),
  })
  .superRefine((val, context) => {
    if (val.type === "sales" || val.type === "external") {
      if (val.source !== "yes") {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Source must be set to 'yes' for sales and external contacts",
        });
      }
    }
  });

export type Contact = z.infer<typeof ContactSchema>;
