import { MarginCallSchema } from "schemas/marginCall";

export const fixMargin = (margin: any) => {
  const result = MarginCallSchema.safeParse(margin);
  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return margin;
  }

  const fixedMargin = {
    ...margin,
    id: `${margin.id}`,
    from: {
      currency: `${margin.from.currency}`,
      quantity: Number(margin.from.quantity),
    },
    to: {
      currency: `${margin.to.currency}`,
      quantity: Number(margin.to.quantity),
    },
    left: {
      currency: `${margin.left.currency}`,
      quantity: Number(margin.left.quantity),
    },
  };

  if ("marginCallId" in fixedMargin) {
    fixedMargin.marginCallId = `${fixedMargin.marginCallId}`;
  }
  if ("comment" in fixedMargin) {
    fixedMargin.comment = `${fixedMargin.comment}`;
  }
  if ("createdFromMovement" in fixedMargin) {
    fixedMargin.createdFromMovement = Boolean(fixedMargin.createdFromMovement);
  }
  if (fixedMargin.operations?.length) {
    fixedMargin.operations = fixedMargin.operations.map((operation: any) => ({
      ...operation,
      id: Number(operation.id),
      value: {
        currency: `${operation.value.currency}`,
        quantity: Number(operation.value.quantity),
      },
      oldMarginValue: {
        currency: `${operation.oldMarginValue.currency}`,
        quantity: Number(operation.oldMarginValue.quantity),
      },
      newMarginValue: {
        currency: `${operation.newMarginValue.currency}`,
        quantity: Number(operation.newMarginValue.quantity),
      },
    }));
  }

  return fixedMargin;
};
