import React from "react";
import { Company } from "../../../interfaces/data";
import { Section, SectionTitle } from "../shared.styled";
import { BankAccountField } from "../../../components/BankAccountField/BankAccountField";
import { useTranslation } from "react-i18next";

interface BankAccountsSectionProps {
  company: Company;
}

export const BankAccountsSection: React.FC<BankAccountsSectionProps> = ({ company }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle>{t("company:Bank accounts")}</SectionTitle>
      {company.bankAccounts.map((bankAccount, index) => (
        <BankAccountField accountNumber={bankAccount.number} currency={bankAccount.currency} key={index} />
      ))}
    </Section>
  );
};
