import styled from "@emotion/styled/macro";
import { Box } from "@chakra-ui/react";

export const StyledSkipLink = styled.a`
  left: 50%;
  position: absolute;
  transform: translateY(-100%);

  &:focus {
    transform: translateY(0%);
  }
`;

export const StyledLogo = styled.img`
  max-width: 100px;
  height: 44px;
  margin-bottom: 50px;
  cursor: pointer;
`;

export const StyledMain = styled.main`
  grid-area: content;
  padding: 20px 50px 50px 40px;
  position: relative;
`;

export const StyledHeaderContainer = styled.header`
  grid-area: header;
  padding: 50px 50px 0 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  z-index: 3;
  width: 100%;
`;

export const StyledPageHeader = styled.div``;

export const StyledRightSideContainer = styled.div`
  margin-left: auto;
  padding-left: 20px;
  display: flex;
`;

export const StyledLayoutContainer = styled.div`
  min-height: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: 264px calc(100% - 264px);
  grid-template-rows: auto 1fr;
  grid-template-areas: "sidebar header" "sidebar content";
  position: relative;

  ${StyledLogo} {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  ${StyledMain}, ${StyledHeaderContainer} {
    background-color: ${(props) => props.theme.colors["sk-lighter-gray"]};
  }
`;

export const StyledSidebarContainer = styled.nav`
  border-right: 1px solid ${(props) => props.theme.colors["sk-dark-gray"]};
  grid-area: sidebar;
`;

export const StyledUnauthorizedLayoutContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StyledMain} {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    padding-right: ${(props) => props.theme.space[16]};
  }
`;

export const StyledIllustration = styled.div`
  width: 55%;
  display: flex;
  justify-content: center;
`;

export const StyledImage = styled.img`
  max-height: 100%;
`;

export const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLayoutContent = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: ${(props) => props.theme.space[28]} ${(props) => props.theme.space[32]};
`;

export const StyledFooterPortal = styled(Box)`
  box-shadow: 10px -10px 34px rgba(112, 112, 112, 0.04);
  bottom: 0;
  left: 0;
  padding: 25px 50px;
  position: absolute;
  width: 100%;
  z-index: 0;

  :empty {
    display: none;
  }
`;
