// List of urls that can be accessed by many users at the same time without the risk of any conflicts
export const saveUrls = [
  "/dashboard",
  "/companies",
  "/contacts",
  "/applications",
  "/transactions",
  "/margin-calls",
  "/commission-fees/companies",
  "/commission-fees/sources",
  "/settings/account",
  "/settings/logs",
  "/settings/roles",
];

// Get only two first elements of url
// i.e. /transactions/fu1WJuwLDOFvMXcnSmUG/hedges/add => /transactions/fu1WJuwLDOFvMXcnSmUG
export function simplifyUrl(url: string) {
  if (!url) return "";
  return url.split("/").slice(0, 3).join("/").trim();
}

export function getAvatarColor(str: string) {
  let hash = 0;
  if (str.length === 0) return hash.toString();
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = "#";
  for (let j = 0; j < 3; j += 1) {
    const value = (hash >> (j * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
}
