import React, { useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { Modal } from "components/Modal/Modal";
import { formatNumberToString } from "helpers/formatNumber";
import { CURRENCIES } from "helpers/options";
import { useFirebase } from "hooks/useFirebase";
import { Company, Currency, Margin } from "interfaces/data";
import { addMarginToMarginCall, getMarginCallBalance, getSumsOfCurrenciesInMarginCall } from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import { StyledLabel, StyledValue } from "./MarginCallSettlementModal.styled";
import { getCrossRate } from "helpers/rates";
import { useFormEvents } from "hooks/useFormEvents";

interface GlobalMarginCallMarginAddModalProps {
  company: Company;
  marginCallId: string;
  onClose: () => void;
  onSave: (updatedCompany: Company) => void;
}

interface Addition {
  currency: string;
  amount: number;
}

export const GlobalMarginCallMarginAddModal: React.FC<GlobalMarginCallMarginAddModalProps> = ({
  company,
  marginCallId,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const { timestamp } = useFirebase();

  const marginCall = company.globalMarginCalls?.find((gmc) => gmc.id === marginCallId);

  const initialVM: Margin = useMemo(() => {
    return {
      id: "",
      date: timestamp(),
      type: "VM",
      from: {
        currency: marginCall?.currency ?? "EUR",
        quantity: 0,
      },
      to: {
        currency: marginCall?.currency ?? "EUR",
        quantity: 0,
      },
      left: {
        currency: marginCall?.currency ?? "EUR",
        quantity: 0,
      },
      marginCallId,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [margin, setMargin] = useState<Margin>(initialVM);
  const { rates: nbpRates } = useNbpRates();
  const [clearSpacesOnPaste] = useFormEvents();
  const [additions, setAdditions] = useState<Addition[]>([]);

  if (!marginCall || !nbpRates) return null;

  function generateInnerId(): number {
    const maxExistingId = marginCall!.margins?.length || 0;
    return maxExistingId + 1;
  }

  function handleQtyUpdate(e: React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    if (!marginCall) return;
    const updatedNewMargin = _cloneDeep(margin);
    updatedNewMargin.from.quantity = Number(e.target.value);
    updatedNewMargin.to.quantity =
      Number(e.target.value) * getCrossRate(updatedNewMargin.from.currency, marginCall.currency, nbpRates);
    updatedNewMargin.left.quantity = Number(e.target.value);
    setMargin(updatedNewMargin);
  }

  function handleCurrencyUpdate(e: React.ChangeEvent<HTMLSelectElement>) {
    e.persist();
    if (!marginCall) return;
    const updatedNewMargin = _cloneDeep(margin);
    updatedNewMargin.from.currency = e.target.value as Currency;
    updatedNewMargin.to.currency = marginCall.currency;
    updatedNewMargin.to.quantity =
      Number(updatedNewMargin.to.quantity) *
      getCrossRate(updatedNewMargin.from.currency, marginCall.currency, nbpRates);
    updatedNewMargin.left.currency = e.target.value as Currency;
    setMargin(updatedNewMargin);
  }

  function handleNewMarginAdd() {
    if (margin.from.quantity <= 0) return;
    if (!marginCall) return;

    if (!company.globalMargins) {
      company.globalMargins = [];
    }

    margin.id = `${marginCall.id}-${generateInnerId()}`;
    company.globalMargins.push(margin);
    setMargin(initialVM);
    addMarginToMarginCall(marginCall, margin);

    if (!company.paymentEntries) {
      company.paymentEntries = [];
    }

    // Add Payment Entry
    company.paymentEntries.push({
      amount: margin.from.quantity,
      currency: margin.from.currency,
      marginId: margin.id,
      date: margin.date,
    });

    setAdditions([...additions, { currency: margin.from.currency, amount: Number(margin.from.quantity) }]);
  }

  function handleSaveAction() {
    onSave(company);
  }

  const coveredValueInCurrencies = marginCall ? getSumsOfCurrenciesInMarginCall(marginCall) : [];

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isOpen
      justifyButtons="flex-end"
      minWidth={840}
      onClose={() => onClose()}
      onConfirm={handleSaveAction}
      title={t(`marginCall:{{marginCallId}} - add`, {
        marginCallId,
      })}
    >
      <Box display="flex" mb="10px">
        <Input
          error={margin?.from.quantity < 0 ? "error" : undefined}
          formatNumberOnBlur
          hideErrorMessage
          id="from.quantity"
          label="Quantity"
          name="from.quantity"
          onChange={handleQtyUpdate}
          onPaste={clearSpacesOnPaste}
          topLabel
          type="number"
          value={margin?.from.quantity || ""}
          withError={margin?.from.quantity < 0}
        />
        <Box ml="10px">
          <AutoCompleteSelect
            id="from.currency"
            name="from.currency"
            onChange={handleCurrencyUpdate}
            options={CURRENCIES}
            value={margin?.from.currency}
            width="85px"
          />
        </Box>
        <Button
          design="primary"
          height="42px"
          isDisabled={margin?.from.quantity < 0}
          ml="30px"
          onClick={handleNewMarginAdd}
        >
          {t("Add new")}
        </Button>
      </Box>

      <Box alignItems="flex-end" display="flex" flexDirection="column">
        {Boolean(additions?.length) &&
          additions.map((addition) => (
            <Box display="inline-block" key={`${addition.amount}-${addition.currency}`}>
              <StyledLabel>Added</StyledLabel>
              <StyledValue addition>
                +{formatNumberToString(Number(addition.amount), 0 || 0)}
                &nbsp;{addition.currency}
              </StyledValue>
            </Box>
          ))}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        {coveredValueInCurrencies.map((value, i) => (
          <Box display="inline-block" key={i} ml="20px">
            <StyledLabel>{value.currency}</StyledLabel>
            <StyledValue>{formatNumberToString(value.sum)}</StyledValue>
          </Box>
        ))}
        <Box display="inline-block" ml="20px">
          <StyledLabel>Balance</StyledLabel>
          <StyledValue>{formatNumberToString(getMarginCallBalance(marginCall, nbpRates))} PLN</StyledValue>
        </Box>
      </Box>
    </Modal>
  );
};
