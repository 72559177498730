import { Margin } from "../interfaces/data";
import { MARGIN_TYPES } from "./transaction";
import { convertToTimestamp, timestampFromMap } from "./timestamp";

export const getMarginTypeLabel = (value: string): string => {
  for (const [, type] of Object.entries(MARGIN_TYPES)) {
    if (type.value === value) return type.label;
  }
  return "";
};

export const getWithdrawValue = (margin: Margin): number => {
  if (!margin.operations) return 0;
  return margin.operations.reduce((sum: number, operation) => Number(operation.value.quantity) + sum, 0);
};

export const getRemainingValue = (margin: Margin): number => {
  const { operations = [], from } = margin;

  return (
    from.quantity +
    operations.reduce((acc, operation) => {
      return operation.type === "WITHDRAW" ? acc - operation.value.quantity : acc + operation.value.quantity;
    }, 0)
  );
};

export const fixMarginsTimestamps = (margins: Margin[] = []) => {
  return margins.map((margin) => {
    if (margin.createdAt) {
      margin.createdAt = convertToTimestamp(margin.createdAt);
    }
    if (margin.modifiedAt) {
      margin.modifiedAt = convertToTimestamp(margin.modifiedAt);
    }
    if (margin.operations?.length) {
      margin.operations = margin.operations.map((operation) => ({
        ...operation,
        date: timestampFromMap(operation.date),
      }));
    }
    return {
      ...margin,
      date: timestampFromMap(margin.date),
    };
  });
};
