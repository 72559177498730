import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { BaseContact } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { findCountry } from "../../../helpers/findCountry";

interface ContactAddressProps {
  contact: BaseContact;
}

export function ContactAddress({ contact }: ContactAddressProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("contact:Street")} value={contact.address?.street} />
      <LabeledText label={t("contact:Apt number")} value={contact.address?.number} />
      <LabeledText label={t("contact:Code")} value={contact.address?.code} />
      <LabeledText label={t("contact:City")} value={contact.address?.city} />
      <LabeledText
        label={t("contact:Country")}
        value={contact.address?.country || findCountry(contact.address?.country)}
      />
    </TwoColumnsLayout>
  );
}
