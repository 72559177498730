import React, { PropsWithChildren, useState } from "react";
import { Button } from "components/Button/Button";

export const BatchActions: React.FC<{
  selectAll: () => void;
  selectNone: () => void;
  deleteSelected: () => void;
}> = ({ selectAll, selectNone, deleteSelected }) => {
  return (
    <div
      style={{
        display: "grid",
        gap: "1rem",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        width: "50rem",
      }}
    >
      <BatchButton design="primary" onClick={selectAll}>
        Select All
      </BatchButton>
      <BatchButton design="primary" onClick={selectNone}>
        Select None
      </BatchButton>
      <BatchButton design="danger" onClick={deleteSelected}>
        Delete Selected
      </BatchButton>
    </div>
  );
};

const BatchButton: React.FC<
  {
    onClick: () => void;
    design: "primary" | "secondary" | "danger" | "ghost" | "link";
  } & PropsWithChildren
> = ({ onClick, children, design }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Button
      design={design}
      isDisabled={isDisabled}
      onClick={async () => {
        setIsDisabled(true);
        await onClick();
        setIsDisabled(false);
      }}
    >
      {children}
    </Button>
  );
};
