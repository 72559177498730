import * as yup from "yup";
import { isPointNumeric, isQuantityMoreThanZero, isEndDateValid } from "./shared";

export const hedgeSchema = yup.object().shape({
  start: yup.object().required(),
  end: isEndDateValid(yup.ref("start")),
  quantity: isQuantityMoreThanZero(),
  institution: yup.string().required().trim(),
  points: isPointNumeric(),
  security: yup.number(),
  comment: yup.string().trim(),
});
