import React, { useState } from "react";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Heading } from "components/Heading/Heading";
import { useFirebase } from "hooks/useFirebase";
import { Table } from "components/Table/Table";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { TransactionSchema } from "schemas/transaction";
import { batchDelete, deleteTransaction, fixTransaction, saveTransaction } from "./fixTransaction";
import { useAuth } from "hooks/useAuth";
import { BatchActions, DbIdCell, MoneyCell, Pagination, PrimitiveCell } from "./components";
import { MenuItem, useTheme } from "@chakra-ui/react";
import { ActionButtons } from "./components/ActionButtons";
import { DataFixerNav } from "./components/DataFixerNav";
import { useValidationModal } from "./hooks/useValidationModal";
import { ValidationModal } from "./components/ValidationModal";

export function TransactionsDataFixer() {
  const { db } = useFirebase();
  const { colors } = useTheme();
  const { page: paramPage } = useParams<{ page?: string }>();
  const [page, setPage] = useState(Number(paramPage) || 1);
  const [ids, setIds] = useState<string[]>([]);
  const [validationData, setValidationData] = useValidationModal();
  const { token } = useAuth();
  const { data, isLoading } = useQuery(["transactions", page], async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/data-fixer/transactions?page=${page}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.ok ? response.json() : [];
  });
  const queryClient = useQueryClient();
  const history = useHistory();

  const changePage = (page: number) => {
    setPage(page);
    history.push(`/data-fixer/transactions/${page}`);
  };

  const handleShowValidationModal = (e: MenuItemEvent, validationErrors: any, transaction: any) => {
    e.stopPropagation();
    setValidationData({
      title: `Validation errors for Transaction #${transaction.number}`,
      validationErrors,
      fixFunction: async () => {
        const fixedTransaction = await fixTransaction(transaction);
        await saveTransaction(fixedTransaction, db, true);
        await queryClient.invalidateQueries(["transactions", page]);
      },
    });
  };

  const handleDelete = async (e: MenuItemEvent, transaction: any) => {
    e.stopPropagation();
    await deleteTransaction(transaction, db);
    await queryClient.invalidateQueries(["transactions", page]);
  };

  const columns = [
    {
      Header: "DB ID",
      accessor: "id",
      Cell: (arg: any) => DbIdCell(arg, "/transactions"),
    },
    {
      Header: "Company",
      accessor: "company.name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Number",
      accessor: "number",
      Cell: PrimitiveCell,
    },
    {
      Header: "Client Rate",
      accessor: "clientRate",
      Cell: PrimitiveCell,
    },
    {
      Header: "Our Rate",
      accessor: "ourRate",
      Cell: PrimitiveCell,
    },
    {
      Header: "Deal Type",
      accessor: "dealType",
    },
    {
      Header: "From",
      accessor: "from",
      Cell: MoneyCell,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: MoneyCell,
    },
    {
      Header: "leftQuantity",
      accessor: "leftQuantity",
      Cell: PrimitiveCell,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (arg: any) => {
        const transaction = arg.row.original;
        const result = TransactionSchema.safeParse(transaction);

        return (
          <ActionButtons>
            {!result.success && (
              <MenuItem onClick={(e) => handleShowValidationModal(e, result.error, transaction)}>Validate</MenuItem>
            )}
            <MenuItem onClick={(e) => handleDelete(e, transaction)}>Delete</MenuItem>
          </ActionButtons>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader>
        <Heading type="h1">Transactions Data Fixer</Heading>
      </PageHeader>

      <DataFixerNav />

      {isLoading && <div>Loading...</div>}

      {validationData && (
        <ValidationModal
          onClose={() => setValidationData(undefined)}
          onConfirm={() => {
            validationData.fixFunction();
            setValidationData(undefined);
          }}
          title={validationData.title}
          validationErrors={validationData.validationErrors}
        />
      )}

      {data && (
        <>
          <Pagination changePage={changePage} data={data} page={page} />
          <Table
            columns={columns}
            data={data}
            getRowProps={(arg: any) => {
              const transaction = arg.original;
              if (!transaction) return {};
              const validation = TransactionSchema.safeParse(transaction);
              const isSelected = ids.includes(transaction.id);
              if (!validation.success) {
                return {
                  style: {
                    background: isSelected ? colors.red["300"] : colors.red["200"],
                  },
                };
              }
              return {
                style: {
                  background: isSelected ? colors.gray["100"] : "transparent",
                },
              };
            }}
          />
          <Pagination changePage={changePage} data={data} page={page} />
          <BatchActions
            deleteSelected={() => batchDelete(ids, db)}
            selectAll={() => setIds(data.map((d: any) => d.id))}
            selectNone={() => setIds([])}
          />
        </>
      )}
    </>
  );
}
