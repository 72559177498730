import { Box } from "@chakra-ui/react";

import styled from "@emotion/styled";

export const MarginGrid = styled(Box)`
  width: 50%;

  > div:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
