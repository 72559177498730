import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getAvatarColor, saveUrls, simplifyUrl } from "../helpers/activeUsers";
import { useAuth } from "hooks/useAuth";
import { useFirebase } from "hooks/useFirebase";

interface UserActivity {
  id: string;
  name: string;
  color: string;
  url: string;
  timestamp: firebase.firestore.Timestamp;
}

export interface ActiveUsersContextType {
  activeUsers: Array<UserActivity>;
  warning?: string;
}

export const ActiveUsersContext = createContext({
  activeUsers: [],
} as ActiveUsersContextType);

export const ActiveUsersContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { realtime, realtimeTimestamp } = useFirebase();
  const { t } = useTranslation();
  const location = useLocation();
  const { profile } = useAuth();
  const [allUsers, setAllUsers] = useState<Array<UserActivity>>([]);
  const [warning, setWarning] = useState<string>();

  // Conntect to real time database
  useEffect(() => {
    if (profile?.userId && location.pathname) {
      const fullName = `${profile.name} ${profile.surname}`;

      const userStatusDatabaseRef = realtime.ref("/activeUsers/" + profile?.userId);

      const isOffline = {
        state: "offline",
        timestamp: realtimeTimestamp,
      };

      const isOnline = {
        state: "online",
        id: profile.userId,
        name: fullName,
        url: location.pathname,
        timestamp: realtimeTimestamp,
        color: getAvatarColor(fullName),
      };

      realtime.ref(".info/connected").on("value", function (snapshot) {
        if (snapshot.val() === false) {
          return;
        }

        userStatusDatabaseRef
          .onDisconnect()
          .set(isOffline)
          .then(function () {
            userStatusDatabaseRef.set(isOnline);
          });
      });
    }
  }, [profile, realtime, location, realtimeTimestamp]);

  // Get array of all online users
  useEffect(() => {
    const userListRef = realtime.ref("/activeUsers");
    userListRef.on("value", (snapshot) => {
      const allUsersList = snapshot.val();
      const allActiveUsers = Object.values(allUsersList).filter(
        (user: any) => user.state === "online"
      ) as Array<UserActivity>;
      setAllUsers(allActiveUsers);
    });
  }, [realtime]);

  // Remove current user from all online users array
  const activeUsers = useMemo(() => allUsers.filter((user: any) => user.id !== profile?.userId), [allUsers, profile]);

  // Check if warning needs to be shown
  useEffect(() => {
    if (location.pathname && activeUsers && activeUsers.length > 0 && allUsers && allUsers.length > 0 && profile) {
      const getUsersOnTheSamePage = (userActivity: UserActivity) =>
        simplifyUrl(userActivity.url) === simplifyUrl(location.pathname);

      const otherUsersOnTheSamePage = activeUsers.filter(getUsersOnTheSamePage);

      if (otherUsersOnTheSamePage.length === 0 || saveUrls.includes(location.pathname)) {
        setWarning(undefined);
      } else {
        const userNames = otherUsersOnTheSamePage.map((user) => user.name).join(", ");
        const hasMutipleUsers = otherUsersOnTheSamePage.length === 1;
        const warningMessage = `${userNames} ${t(
          `multiuser:${hasMutipleUsers ? "is" : "are"} browsing the same page.`
        )}`;

        setWarning(warningMessage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers, location, profile, t]);

  return <ActiveUsersContext.Provider value={{ activeUsers, warning }}>{children}</ActiveUsersContext.Provider>;
};
