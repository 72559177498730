import { Operation } from "../interfaces/data";
import { UseOperations } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useOperations = ({
  phrase,
  fields,
  filtersOr,
  filtersAnd,
  filtersAndBetween,
  filtersAndCustom,
  orderBy,
  orderDirection,
  offset,
  limit,
  skipAutoUpdate,
  isLazy,
}: UseOperations) => {
  const {
    data: operations,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Operation>({
    collection: "transactions/operations",
    phrase,
    fields,
    filtersOr,
    filtersAnd,
    filtersAndBetween,
    filtersAndCustom,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate,
    isLazy,
  });

  return { operations, totalCount, pageCount, loading, error, refetch };
};
