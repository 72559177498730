import firebase from "firebase";
import { Company, CompanySchema } from "schemas/company";
import { fixCompanyTimestamps } from "helpers/company";
import { omit, pickBy } from "lodash";
import { fixMargin } from "./fixMargin";
import { firebase as fb } from "Firebase";

const COLLECTION = "companies";

export const fixCompany = async (company: any) => {
  const result = CompanySchema.safeParse(company);

  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return CompanySchema.parse(company);
  } else {
    // eslint-disable-next-line no-console
    console.log("errors", result.error);
  }

  const fixedCompany = {
    ...company,
    name: `${company.name}`,
    nip: `${company.nip}`,
    krs: `${company.krs}`,
    legalEntity: `${company.legalEntity}`,
    regon: `${company.regon}`,
    details: {
      ...company.details,
      industry: `${company.details.industry}`,
      goal: `${company.details.goal}`,
      source: `${company.details.source}`,
      currency: company.details.currency ? `${company.details.currency}` : null,
      limit: company.details.limit ? Number(company.details.limit) : null,
    },
    companyAddress: {
      ...company.companyAddress,
      country: `${company.companyAddress.country}`,
    },
  };

  fixedCompany.details = fixCompanyDetails(fixedCompany.details);
  if (fixedCompany.contactAddress) {
    if (isAddressEmpty(fixedCompany.contactAddress)) {
      // @ts-ignore
      fixedCompany.contactAddress = firebase.firestore.FieldValue.delete();
    }
  }
  if (!fixedCompany.createdAt) {
    fixedCompany.createdAt = await getFirstTransactionDate(fixedCompany);
  }
  if (fixedCompany.globalMargins?.length) {
    fixedCompany.globalMargins = fixedCompany.globalMargins.map((margin: any) => pickBy(fixMargin(margin)));
  }
  if (fixedCompany.paymentEntries?.length) {
    fixedCompany.paymentEntries = fixedCompany.paymentEntries.map((entry: any) => ({
      ...entry,
      amount: Number(entry.amount),
    }));
  }
  if ("reversePayment" in fixedCompany) {
    fixedCompany.reversePayment = {
      ...fixedCompany.reversePayment,
      limit: {
        ...fixedCompany.reversePayment.limit,
        quantity: Number(fixedCompany.reversePayment.limit.quantity),
      },
    };
  }

  return fixedCompany;
};

const fixCompanyDetails = (companyDetails: any) => {
  const fixedCompanyDetails = { ...companyDetails };
  if (companyDetails.limit) {
    fixedCompanyDetails.limit = Number(companyDetails.limit);
  }
  if (companyDetails.notes) {
    fixedCompanyDetails.notes = `${companyDetails.notes}`;
  }
  if (companyDetails.password) {
    fixedCompanyDetails.password = `${companyDetails.password}`;
  }
  if (companyDetails.score) {
    fixedCompanyDetails.score = `${companyDetails.score}`;
  }
  if (companyDetails.nolimits) {
    fixedCompanyDetails.nolimits = Boolean(companyDetails.nolimits);
  }

  return fixedCompanyDetails;
};

export const saveCompany = async (company: any, db: firebase.firestore.Firestore, noValidate = false) => {
  try {
    if (!noValidate) {
      CompanySchema.parse(company);
    }
    await db
      .collection(COLLECTION)
      .doc(company.id)
      .update(omit(fixCompanyTimestamps(company), ["id", "contacts"]));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored company", company);
    console.error(error);
  }
};

export const deleteCompany = async (company: any, db: firebase.firestore.Firestore) => {
  try {
    // eslint-disable-next-line no-console
    console.log(`deleting company ${company.id}...`);
    await db.collection(COLLECTION).doc(company.id).delete();
    // eslint-disable-next-line no-console
    console.log("deleted company", company);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored company", company);
    console.error(error);
  }
};

export const batchDelete = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  ids.forEach((id) => {
    const ref = db.collection(COLLECTION).doc(id);
    batch.delete(ref);
  });
  await batch.commit();
};

export const batchFix = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  for (const id of ids) {
    const ref = db.collection(COLLECTION).doc(id);
    const company = await getCompany(id, db);
    batch.update(ref, fixCompanyTimestamps(company));
  }
  await batch.commit();
};

export const getCompany = async (id: string, db: firebase.firestore.Firestore) => {
  const doc = await db.collection(COLLECTION).doc(id).get();
  return { ...doc.data(), id } as Company;
};

export const isAddressEmpty = (address: {
  city: string;
  code: string;
  country: string;
  number: string;
  street: string;
}) => {
  return !address.city && !address.code && !address.number && !address.street;
};

const getFirstTransactionDate = async (company: Company) => {
  const { db } = fb;
  const doc = await db
    .collection("transactions")
    .where("company.id", "==", company.id)
    .orderBy("agreement", "asc")
    .limit(1)
    .get();
  if (doc.empty) {
    return firebase.firestore.Timestamp.now();
  }
  return doc.docs[0].data().createdAt;
};
