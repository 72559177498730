import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { getQuantityLeft, TRANSACTION_TYPES } from "helpers/transaction";
import { useFirebase } from "hooks/useFirebase";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { BaseTransaction, Transaction } from "interfaces/data";
import { TransactionFormModal } from "../TransactionFormModal/TransactionFormModal";
import { getTimestamp } from "helpers/date";
import dayjs from "dayjs";
import { TransactionConfirm } from "../TransactionConfirm/TransactionConfirm";
import _cloneDeep from "lodash.clonedeep";
import { useMail } from "hooks/useMail";
import { PROLONG_MAIL } from "helpers/mails";
import { useCompany } from "hooks/useCompany";
import { useBankAccounts } from "hooks/useBankAccounts";
import { useGMC } from "hooks/useGMC";

export function TransactionRoll() {
  const { timestampFrom } = useFirebase();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [sendEmail, setSendEmail] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState<BaseTransaction>();
  const { roll, transaction, errors, validate } = useTransaction({
    id,
  });
  const { company } = useCompany({ id: transaction?.company.id });
  const { bankAccounts } = useBankAccounts();
  const { updateRolledTransactionId } = useGMC();

  const sendConfirmation = useMail();

  const confirmRoll = useCallback(
    (transaction: DeepPartial<Transaction>) => {
      validate(transaction).then((isValid) => {
        if (isValid) {
          setNewTransaction(transaction as Transaction);
          setIsPopupOpen(true);
        }
      });
    },
    [validate]
  );

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSave = useCallback(
    (transaction: BaseTransaction) => {
      setIsSending(true);
      roll(transaction as Transaction)
        .then(async (prolongedTransactionId) => {
          if (prolongedTransactionId && prolongedTransaction) {
            if (sendEmail) {
              sendConfirmation(PROLONG_MAIL, prolongedTransactionId.id);
            }

            // update GMCs with new ID
            await updateRolledTransactionId(transaction.company.id, prolongedTransaction.id, prolongedTransactionId);

            toast({
              type: "success",
              message: t("transaction:Transaction has been rolled successfully."),
            });
            setIsSending(false);
            history.push(`/transactions/${prolongedTransactionId.id}`);
          }
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          });
          setIsSending(false);
          history.push(`/transactions/${id}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roll, handleClose, toast, t, setIsSending]
  );

  const prolongedTransaction = useMemo(() => {
    if (transaction && company && bankAccounts) {
      // SET VALUES OF PROLONGED TRANSACTION
      const prolongedTransaction = _cloneDeep(transaction);
      prolongedTransaction.company.name = company.name;
      prolongedTransaction.agreement = timestampFrom(new Date());
      prolongedTransaction.systemDate = timestampFrom(new Date());
      prolongedTransaction.start = transaction.end || timestampFrom(new Date());
      prolongedTransaction.end = timestampFrom(dayjs(getTimestamp(prolongedTransaction.start)).add(2, "day").toDate());
      prolongedTransaction.from.quantity = getQuantityLeft(transaction);
      prolongedTransaction.to.quantity = getQuantityLeft(transaction) * transaction.clientRate;

      if (!bankAccounts.some((acc) => acc.id === prolongedTransaction.account?.id)) {
        prolongedTransaction.account = undefined;
      }
      prolongedTransaction.settlements = [];

      return prolongedTransaction;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, company, bankAccounts]);

  useEffect(() => {
    if (prolongedTransaction && prolongedTransaction.type !== TRANSACTION_TYPES.FIXED.value) {
      handleClose();
      toast({
        type: "error",
        message: t("transaction:Only Fixed Rate transactions can be rolled."),
      });
    }
  }, [handleClose, t, toast, prolongedTransaction]);

  if (!prolongedTransaction) {
    return null;
  }

  return (
    <>
      <TransactionFormModal
        errors={errors}
        initialTransaction={prolongedTransaction}
        onClose={handleClose}
        onSave={confirmRoll}
        sendEmail={sendEmail}
        sendEmailChange={setSendEmail}
        title={t(`transaction:Roll transaction #{{number}}`, {
          number: prolongedTransaction.number,
        })}
        transactionType={prolongedTransaction.type}
        type="roll"
      />
      <TransactionConfirm
        isDisabled={isSending}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleSave}
        sendEmail={sendEmail}
        transaction={newTransaction}
        transactionType={prolongedTransaction.type}
        type="roll"
      />
    </>
  );
}
