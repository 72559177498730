import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import _cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { Margin, MarginOperation } from "interfaces/data";
import { Modal } from "components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { Button } from "components/Button/Button";
import { StyledLabel, StyledMarginsListItem, StyledValue } from "../Company360/Main/MarginCallSettlementModal.styled";
import { formatDate } from "helpers/date";
import { useFirebase } from "hooks/useFirebase";
import { CURRENCIES } from "helpers/options";
import { formatNumberToString } from "helpers/formatNumber";
import styled from "@emotion/styled";
import { RemoveIcon } from "theme/icons";

const StyledMarginsList = styled.div`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  height: 100px;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
`;

interface MarginWithdrawModalProps {
  margin: Margin;
  onClose: () => void;
  onSave: (newMargin: Margin) => void;
  isDisabled?: boolean;
}

export const StyledClearButton = styled.button`
  text-align: left;
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-size: 13px;
  text-decoration: underline;
`;

export const StyledWarning = styled.div`
  margin: 10px 0;
  color: ${(props) => props.theme.colors["sk-red"]};
  font-size: 13px;
  font-weight: 900;
`;

export const MarginWithdrawModal: React.FC<MarginWithdrawModalProps> = ({ margin, onClose, onSave, isDisabled }) => {
  const { t } = useTranslation();
  const { timestamp, timestampFrom } = useFirebase();
  const [editedMargin, setEditedMargin] = useState<Margin>(margin);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [withdrawn, setWithdrawn] = useState(0);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const initialOperation = useMemo<MarginOperation>(() => {
    return {
      id: 0,
      type: "WITHDRAW",
      value: {
        quantity: 0,
        currency: editedMargin.from.currency,
      },
      oldMarginValue: {
        quantity: editedMargin.left.quantity,
        currency: editedMargin.left.currency,
      },
      newMarginValue: {
        quantity: editedMargin.left.quantity,
        currency: editedMargin.left.currency,
      },
      date: timestamp(),
    };
  }, [editedMargin, timestamp]);

  const [editedOperation, setEditedOperation] = useState(initialOperation);

  const isValueValid = useMemo(() => {
    if (!editedMargin || !editedMargin.left) return false;

    return editedOperation.value.quantity <= editedMargin.left.quantity && editedOperation.value.quantity >= 0;
  }, [editedMargin, editedOperation.value.quantity]);

  const isCommentValid = useMemo(() => Boolean(editedOperation.comment), [editedOperation.comment]);

  const handleOperationAdd = useCallback(() => {
    if (!editedMargin) return;
    const newEditedMargin = _cloneDeep(editedMargin);
    const newOperation = _cloneDeep(editedOperation);
    const newOperations = _cloneDeep(editedMargin?.operations) || [];

    newOperation.id =
      (newOperations.length === 0 ? 0 : Math.max(...newOperations.map((operation) => operation.id))) + 1;
    newOperations.unshift(newOperation);
    newEditedMargin.operations = newOperations;
    newEditedMargin.left.quantity = newEditedMargin.left.quantity - newOperation.value.quantity;
    setEditedMargin(newEditedMargin);
    setIsEditing(false);
    const newInitialOperation = _cloneDeep(initialOperation);
    newInitialOperation.oldMarginValue.quantity = newEditedMargin.left.quantity;
    setEditedOperation({ ...newInitialOperation, comment: "" });
    setIsConfirmed(false);
    setWithdrawn((prevState) => prevState - newOperation.value.quantity);
    setIsFormChanged(true);
  }, [editedMargin, editedOperation, initialOperation]);

  const handleOperationRemove = useCallback(
    (operation: MarginOperation) => {
      const newEditedMargin = _cloneDeep(editedMargin);
      const newOperations = _cloneDeep(editedMargin?.operations) || [];
      // add quantity back to VM
      newEditedMargin.left.quantity = Number(newEditedMargin.left.quantity) + operation.value.quantity;
      // remove opeation from operations array
      const editedOperationIndex = newOperations.findIndex((x) => x.id === operation.id);
      if (editedOperationIndex !== -1) {
        newOperations.splice(editedOperationIndex, 1);
      }

      newEditedMargin.operations = newOperations;
      setEditedMargin(newEditedMargin);
      const newInitialOperation = _cloneDeep(initialOperation);
      newInitialOperation.oldMarginValue.quantity = newEditedMargin.left.quantity;
      setEditedOperation(newInitialOperation);
      setIsFormChanged(true);
      if (withdrawn + operation.value.quantity === 0) {
        setIsFormChanged(false);
      }
      setWithdrawn((prevState) => prevState + operation.value.quantity);
    },
    [editedMargin, initialOperation, withdrawn]
  );

  useEffect(() => {
    const newOperation = _cloneDeep(editedOperation);
    newOperation.newMarginValue.quantity = newOperation.oldMarginValue.quantity - newOperation.value.quantity;
    setEditedOperation(newOperation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedOperation.value.quantity]);

  if (!editedMargin) return null;

  return (
    <>
      <Modal
        confirmText={t("Confirm")}
        design="danger"
        isOpen={isConfirmed}
        onClose={() => setIsConfirmed(false)}
        onConfirm={() => onSave(editedMargin)}
        title={t("Withdraw margin")}
      >
        <Box mb={"20px"}>{t("company:Are you sure that you want to withdraw margin?")}</Box>
      </Modal>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isDisabled={isDisabled || !isFormChanged}
        isOpen
        justifyButtons="flex-end"
        minWidth={840}
        onClose={onClose}
        onConfirm={() => setIsConfirmed(true)}
        title={t("margin:Withdraw margin")}
      >
        <Box alignItems="center" display="flex">
          <Input
            error={!isValueValid ? "error" : undefined}
            formatNumberOnBlur
            hideErrorMessage
            id="from.quantity"
            label={t("margin:Quantity")}
            name="from.quantity"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEditedOperation({
                ...editedOperation,
                value: {
                  ...editedOperation.value,
                  quantity: Number(e.target.value),
                },
              });
            }}
            type="number"
            value={editedOperation.value.quantity || ""}
            withError={!isValueValid}
          />
          <Box ml="10px">
            <AutoCompleteSelect
              id="from.currency"
              isDisabled
              name="from.currency"
              options={CURRENCIES}
              value={editedOperation.value.currency}
              width="85px"
            />
          </Box>
          <Button
            design="primary"
            isDisabled={!isValueValid || editedOperation.value.quantity === 0 || !isCommentValid}
            ml="10px"
            onClick={handleOperationAdd}
            px="50px"
          >
            {isEditing ? t("Save") : t("Withdraw")}
          </Button>
          <Box ml="10px">
            <StyledClearButton
              onClick={() => {
                setEditedOperation({
                  ...editedOperation,
                  value: {
                    ...editedOperation.value,
                    quantity: Number(editedMargin.left?.quantity),
                  },
                });
              }}
            >
              {t(`settlement:max`)}
            </StyledClearButton>
          </Box>
        </Box>
        <Box alignItems="center" display="flex" mb="15px" mt="15px">
          <Box flex={0.33} mr="20px">
            <Input
              id="date"
              name="date"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const date = new Date(e.target.value);
                setEditedOperation({
                  ...editedOperation,
                  date: timestampFrom(date),
                });
              }}
              type="date"
              value={formatDate(editedOperation.date)}
            />
          </Box>
          <Box flex="1">
            <Input
              error={editedOperation.comment !== undefined && !isCommentValid && !isFormChanged ? "error" : undefined}
              label={t("margin:Comment")}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const comment = e.target.value;
                setEditedOperation((prevState) => ({ ...prevState, comment }));
              }}
              value={editedOperation.comment}
            />
          </Box>
        </Box>
        <Box mb="10px" mt="20px" pl="10px">
          <Box display="flex">
            <Box flex="1">
              <StyledLabel>Quantity left: </StyledLabel>
              <StyledValue ml="40px">
                {formatNumberToString(editedMargin.left?.quantity || 0)} {editedMargin.left?.currency}
              </StyledValue>
            </Box>
            <Box flex="1" textAlign="right">
              <StyledLabel>Withdrawn: </StyledLabel>
              <StyledValue withdrawn>
                {formatNumberToString(withdrawn || 0)} {editedMargin.left?.currency}
              </StyledValue>
            </Box>
          </Box>
        </Box>
        <StyledMarginsList>
          {editedMargin.operations?.map((operation, i) => (
            <StyledMarginsListItem key={i}>
              <Box display="flex">
                <Box pr="15px" textAlign="right" w="100px">
                  {formatDate(operation.date)}
                </Box>
                <Box fontWeight="bold" pr="15px">
                  Qty: {formatNumberToString(operation.value.quantity)} {operation.value.currency}
                </Box>
                {operation.comment && <Box fontStyle="italic">{operation.comment}</Box>}
              </Box>
              {!isEditing && (
                <Box display="flex" ml="auto" w="60px">
                  <button onClick={() => handleOperationRemove(operation)}>
                    <RemoveIcon mt="-2px" />
                  </button>
                </Box>
              )}
            </StyledMarginsListItem>
          ))}
        </StyledMarginsList>
      </Modal>
    </>
  );
};
