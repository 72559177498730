import React from "react";
import { determineTransactionCurrency } from "helpers/transaction";
import { getMarginCallBalance } from "helpers/marginCall";
import { Text } from "@chakra-ui/react";
import { formatNumberToString } from "helpers/formatNumber";
import { MarginCallExtended } from "../MarginCalls";
import { useNbpRates } from "hooks/useNbpRates";

export function RemainingCell(arg: any) {
  const { rates: nbpRates } = useNbpRates();
  const marginCall = arg.row.original as MarginCallExtended;

  if (!marginCall || !nbpRates) return "";

  const currency = determineTransactionCurrency(marginCall.transaction);
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);
  const remainingValue = Number(marginCall.quantity) - Number(paid);

  return (
    <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
      {remainingValue > 0 ? formatNumberToString(remainingValue) : 0} {currency}
    </Text>
  );
}
