import React from "react";
import styled from "@emotion/styled";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

interface SimplePaginationProps {
  onPrevPage: () => void;
  hasPrev?: boolean;
  onNextPage: () => void;
  hasNext?: boolean;
}

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  button {
    outline: none;
  }
`;

export const StyledNavigationButton = styled.button`
  display: flex;
  align-items: center;
  height: 33px;
  margin: 0 5px;
  background: #ffffff;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 110%;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 0 2px 0 rgba(236, 236, 236, 0.3);
  border-radius: 8px;
  padding: 0 15px;
  &.prev svg {
    margin-right: 24px;
  }
  &.next svg {
    margin-left: 24px;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export function SimplePagination({ onPrevPage, hasPrev = true, onNextPage, hasNext = true }: SimplePaginationProps) {
  return (
    <StyledContainer className="pagination">
      <>
        <StyledNavigationButton className="prev" disabled={!hasPrev} onClick={onPrevPage}>
          <ChevronLeftIcon boxSize="16px" />
          Previous
        </StyledNavigationButton>
        <StyledNavigationButton className="next" disabled={!hasNext} onClick={onNextPage}>
          Next
          <ChevronRightIcon boxSize="16px" />
        </StyledNavigationButton>
      </>
    </StyledContainer>
  );
}
