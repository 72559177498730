import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { Box } from "@chakra-ui/react";
import { StyledClearButton, StyledDropdownButton, StyledDropdownContent } from "./shared.styled";
import { TRANSACTION_STATUSES } from "../../../helpers/transaction";
import { TransactionStatus } from "../../../interfaces/data";
import { SelectIcon } from "../../../theme/icons";

interface StatusFilterProps {
  filterValue: Array<TransactionStatus>;
  onChange?: (newStatusFilter: Array<TransactionStatus>) => void;
}

export const StatusFilter: React.FC<StatusFilterProps> = ({ filterValue, onChange }) => {
  const { t } = useTranslation();

  const [statusFilters, setStatusFilters] = useState(filterValue);

  const handleStatusFiltersUpdate = useCallback(
    (filterKey: TransactionStatus) => () => {
      const newstatusFilters = statusFilters.includes(filterKey)
        ? statusFilters.filter((key) => key !== filterKey)
        : [...statusFilters, filterKey];

      setStatusFilters(newstatusFilters);
    },
    [statusFilters]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(statusFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilters]);

  const activeFilters = useMemo(() => {
    return statusFilters
      .map((status) =>
        status
          .replace("created", "CR")
          .replace("partiallySettled", "PSE")
          .replace("closed", "CL")
          .replace("rolled", "RO")
          .replace("settled", "SE")
      )
      .toString();
  }, [statusFilters]);

  const renderOptions = useCallback(() => {
    const options = [];

    for (const [, status] of Object.entries(TRANSACTION_STATUSES)) {
      options.push(
        <Checkbox
          checkboxSize="small"
          isChecked={statusFilters.includes(status.value)}
          key={status.value}
          onChange={handleStatusFiltersUpdate(status.value)}
        >
          {t(`transaction:${status.label}`)}
        </Checkbox>
      );
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleStatusFiltersUpdate, statusFilters]);

  return (
    <Box ml="10px">
      <Dropdown
        trigger={
          <StyledDropdownButton isActive={statusFilters.length !== 0}>
            <div>{activeFilters ? <Box fontWeight={800}>{activeFilters}</Box> : t("transaction:Status")}</div>
            <SelectIcon ml="auto" />
          </StyledDropdownButton>
        }
      >
        <StyledDropdownContent>
          {renderOptions()}
          <StyledClearButton onClick={() => setStatusFilters([])}>{t("transaction:clear")}</StyledClearButton>
        </StyledDropdownContent>
      </Dropdown>
    </Box>
  );
};
