import Decimal from "decimal.js";
import { Hedge, Settlement } from "../interfaces/data";
import { HedgeWithRollback } from "../views/Settlement/SettlementFormModal/SettlementFormModal";
import { timestampFromMap } from "./timestamp";

export const calculatePointsForSettlement = (hedges: Array<HedgeWithRollback>) => {
  const quantity = hedges.reduce((sum, hedge) => sum.plus(new Decimal(hedge?.quantity)), new Decimal(0));

  const pointsSum = hedges.reduce(
    (sum, hedge) =>
      sum.plus(new Decimal(hedge?.quantity).times(new Decimal(hedge?.points).minus(new Decimal(hedge?.rollback || 0)))),
    new Decimal(0)
  );

  const rollbackSum = hedges.reduce(
    (sum, hedge) => sum.plus(new Decimal(hedge?.quantity).times(new Decimal(hedge?.rollback || 0))),
    new Decimal(0)
  );

  const fwdPointsSum = hedges.reduce(
    (sum, hedge) => sum.plus(new Decimal(hedge?.quantity).times(new Decimal(hedge?.points || 0))),
    new Decimal(0)
  );

  return {
    points: pointsSum.dividedBy(quantity).toDecimalPlaces(4),
    rollback: rollbackSum.dividedBy(quantity).toDecimalPlaces(4),
    fwdPoints: fwdPointsSum.dividedBy(quantity).toDecimalPlaces(4),
  };
};

export const calculateFwdForHedgeWithParents = (hedges: Hedge[], hedgeId: string): Decimal => {
  const hedge = hedges.find((h) => h.id === hedgeId);
  if (!hedge) return new Decimal(0);
  if (hedge.createdFromId === undefined) {
    return new Decimal(hedge.points);
  } else {
    return new Decimal(hedge.points).add(calculateFwdForHedgeWithParents(hedges, hedge.createdFromId));
  }
};

export const fixSettlementsTimestamps = (settlements: Settlement[] = []) => {
  return settlements.map((settlement) => {
    if (settlement.createdAt) {
      settlement.createdAt = timestampFromMap(settlement.createdAt);
    }
    if (settlement.modifiedAt) {
      settlement.modifiedAt = timestampFromMap(settlement.modifiedAt);
    }
    return {
      ...settlement,
      date: timestampFromMap(settlement.date),
    };
  });
};
