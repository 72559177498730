import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAuth } from "./useAuth";
import { useToast } from "./useToast";
import { Company } from "../interfaces/data";

export const useBalanceExport = ({
  date,
  company,
  format = "csv",
}: {
  date: number;
  company?: Company;
  format?: string;
}) => {
  const { token, logout } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  return useCallback(() => {
    const endpoint = company ? "statements" : "company-statements";

    return fetch(
      `${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/exports/${endpoint}?date=${date}&${
        company && `companyId=${company.id}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response: Response) => {
        // Logout user if unauthorized
        if (response.status === 401) {
          logout();
          return undefined;
        }

        if (response.status === 200) {
          return response.blob();
        }
      })
      .then((blob) => {
        if (!blob) return;
        const anchor = document.createElement("a");
        const sluggedCompanyName = company ? company.name.replaceAll(" ", "_") : "";

        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `stan_${sluggedCompanyName}_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}.${format}`;
        document.body.appendChild(anchor);

        anchor.click();
        anchor.remove();
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t("validation:CSV could not be exported"),
        });
        console.error("useCSVExport", error);
      });
  }, [date, company, token, logout, format, toast, t]);
};
