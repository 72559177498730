import React from "react";
import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";
import { BaseCompany, Contact } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { Select } from "../../../components/Select/Select";
import { AUTHORIZED_PERSON, EXTERNAL, LEGAL_REPRESENTANT, SALES } from "../../../helpers/contact";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { SearchBox } from "../../../components/SearchBox/SearchBox";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { Button } from "../../../components/Button/Button";
import { CONTACT_TYPES } from "../../../helpers/options";
import { DeleteIcon } from "@chakra-ui/icons";

const StyledContainer = styled.div`
  display: flex;
  background: white;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 10px;
  margin-top: 30px;
  justify-content: space-between;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledInfo = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledContactsSection = styled.div`
  margin-top: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 5px;
`;

const EditSection = styled.div`
  margin-left: 10px;
  min-width: 160px;
`;

const ContactInfo = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export interface EditContactsProps extends EditViewProps<BaseCompany[]> {
  onCompanyRemove: (
    index: number,
    { handler, data }: { handler: Function; data: BaseCompany[]; currentContact: Contact }
  ) => void;
  onCompanyAdd: (
    item: BaseCompany,
    { handler, data }: { handler: Function; data: BaseCompany[]; currentContact: Contact }
  ) => void;
  currentContact: Contact;
}

export function EditCompanies({
  data,
  currentContact,
  handleChange,
  onCompanyAdd,
  onCompanyRemove,
}: EditContactsProps) {
  const { t } = useTranslation();
  const currentContactId = currentContact.id;
  if (!data) return null;
  return (
    <StyledContactsSection>
      <SearchBox
        collection="companies"
        fields="name"
        item={(item: any) => `${item.name}`}
        label={t("company:Search companies")}
        onSelect={(company: BaseCompany) => {
          if (!company) {
            return;
          }
          const newCompany = {
            ...company,
            contacts: company.contacts?.filter((v) => v.id !== currentContactId) || [],
          };
          if (currentContact.type === SALES || currentContact.type === EXTERNAL) {
            onCompanyAdd(newCompany, {
              handler: handleChange,
              data,
              currentContact: { ...currentContact, source: "yes" } as Contact,
            });
            return;
          }
          onCompanyAdd(newCompany, {
            handler: handleChange,
            data,
            currentContact,
          });
        }}
        orderBy="name"
      />
      {data &&
        data.map((company, i) => {
          const contactIdx = company?.contacts?.findIndex((c) => c.id === currentContactId)!;
          const contact = company?.contacts?.find((c) => c.id === currentContactId)!;
          if (!contact) {
            return null;
          }
          return (
            <StyledContainer key={i}>
              <div>
                <ContactInfo>
                  <StyledName>{company.name}</StyledName>
                  <StyledInfo>{company.legalEntity}</StyledInfo>
                </ContactInfo>
              </div>
              <EditSection>
                <Select
                  name={`[${i}]contacts[${contactIdx}].type`}
                  onChange={(e: any) => {
                    const isSource = e.target.value === SALES || e.target.value === EXTERNAL;
                    // update contact type
                    handleChange(e);
                    // reset CC flag on contact type change
                    handleChange({
                      target: {
                        name: `[${i}]contacts[${contactIdx}].cc`,
                        value: "no",
                      },
                      persist: () => {
                        return;
                      },
                    });
                    // set source to true for SALES and EXTERNAL
                    handleChange({
                      target: {
                        name: `[${i}]contacts[${contactIdx}].source`,
                        value: isSource ? "yes" : "no",
                      },
                      persist: () => {
                        return;
                      },
                    });
                  }}
                  options={CONTACT_TYPES}
                  value={contact.type}
                />
                <Box display="flex" justifyContent="space-between" marginTop="10px">
                  {contact.type === AUTHORIZED_PERSON && (
                    <Checkbox
                      isChecked={contact?.cc === "yes"}
                      mr={10}
                      name={`[${i}]contacts[${contactIdx}].cc`}
                      onChange={(e) => {
                        e.target.value = e.target.checked ? "yes" : "no";
                        handleChange(e);
                      }}
                    >
                      {t("company:Send CC email")}
                    </Checkbox>
                  )}
                  {contact.type === LEGAL_REPRESENTANT && (
                    <Checkbox
                      isChecked={contact?.disableEmails}
                      mr={10}
                      name={`[${i}]contacts[${contactIdx}].disableEmails`}
                      onChange={(e) => {
                        handleChange(e, true);
                      }}
                    >
                      {t("company:Don't send emails")}
                    </Checkbox>
                  )}
                  <Button
                    design="link"
                    height="auto"
                    marginLeft="auto"
                    onClick={() =>
                      onCompanyRemove(i, {
                        handler: handleChange as Function,
                        data,
                        currentContact,
                      })
                    }
                    p={0}
                    width="12px"
                  >
                    <DeleteIcon color="sk-red" height="12px" width="12px" />
                  </Button>
                </Box>
              </EditSection>
            </StyledContainer>
          );
        })}
    </StyledContactsSection>
  );
}
