import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { EditComment } from "views/Transaction/Comment/EditComment";
import { EditIcon } from "../../theme/icons";

interface CommentProps {
  comment?: string;
  onSave: (newComment: string) => any;
  minWidth?: string;
}

const StyledButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledContainer = styled.div<{ minWidth: string }>`
  position: relative;
  padding-right: 20px;
  width: 100%;
  min-width: ${(props) => props.minWidth};
  max-width: 400px;
  min-height: 15px;
  ${StyledButton} {
    opacity: 0;
  }
  &:hover ${StyledButton} {
    opacity: 1;
  }
`;

const StyledEditContainer = styled.div<{ minWidth: string }>`
  position: relative;
  top: -37px;
  right: 35px;
  min-width: ${(props) => props.minWidth};
  width: calc(100% + 35px);
`;

export const EditableComment: React.FunctionComponent<CommentProps> = ({ comment, onSave, minWidth = "200px" }) => {
  const [isEdited, setIsEdited] = useState(false);
  return (
    <>
      {isEdited && (
        <StyledEditContainer minWidth={minWidth}>
          <EditComment
            alignToRight
            asBox={false}
            comment={comment}
            onEdit={(comment) => {
              onSave(comment);
              setIsEdited(false);
            }}
            onEditClose={() => setIsEdited(false)}
          />
        </StyledEditContainer>
      )}
      <StyledContainer minWidth={minWidth} onClick={() => setIsEdited(!isEdited)}>
        {comment || "-"}
        <StyledButton>
          <EditIcon ml="10px" mt="-2px" />
        </StyledButton>
      </StyledContainer>
    </>
  );
};
