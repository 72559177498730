import React, { PropsWithChildren, ReactNode } from "react";

import { StyledToastContainer, StyledToastContent } from "./Toast.styled";

import { Button, useTheme } from "@chakra-ui/react";
import { CloseIcon, WarningIcon } from "@chakra-ui/icons";
import { TickIcon } from "theme/icons";

type ToastContainerProps = PropsWithChildren & {
  color: string;
  icon: ReactNode;
  onClose: any;
};

type ToastProps = PropsWithChildren & {
  onClose: any;
};

const ToastContainer: React.FC<ToastContainerProps> = ({ color, icon, children, onClose }) => {
  return (
    <StyledToastContainer color={color}>
      {icon}
      <StyledToastContent>{children}</StyledToastContent>
      <Button onClick={onClose} padding="3px" type="button" variant="link">
        <CloseIcon color="sk-dark" height="10px" width="10px" />
      </Button>
    </StyledToastContainer>
  );
};

export const ToastSuccess: React.FC<ToastProps> = ({ children, onClose }) => {
  const theme = useTheme();
  const color = theme.colors["sk-green"];

  return (
    <ToastContainer color={color} icon={<TickIcon boxSize="12px" />} onClose={onClose}>
      {children}
    </ToastContainer>
  );
};

export const ToastError: React.FC<ToastProps> = ({ children, onClose }) => {
  const theme = useTheme();
  const color = theme.colors["sk-red"];

  return (
    <ToastContainer color={color} icon={<WarningIcon boxSize="12px" />} onClose={onClose}>
      {children}
    </ToastContainer>
  );
};

export const getToastDuration = (message: string): number => {
  const { min, max } = Math;
  const msgLength = message.length;

  return min(max(msgLength * 50, 2000), 7000);
};
