import React from "react";
import { GlobalMarginCall, MarginCallExtended } from "interfaces/data";
import { Box, Text } from "@chakra-ui/react";
import { StyledBoldSpan, StyledLink, StyledTag } from "./styles";
import { hasPreviousRowRepeatedFields } from "helpers/tableCells";
import { Link } from "react-router-dom";
import { formatNumberToString } from "helpers/formatNumber";
import { DEAL_TYPE_BUY, determineTransactionCurrency } from "helpers/transaction";
import { getMarginCallBalance } from "helpers/marginCall";
import { Rates } from "schemas/rates";
import { TransactionSchema } from "schemas/transaction";
import { EditableComment } from "views/Payments/EditableComment";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { StyledButtonsContainer, StyledIconContainer } from "views/Company360/Main/components/margin.styled";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";

export const IdCell = (arg: any) => {
  const row = arg.row.original;
  if (row.company) {
    return (
      <Box as="span" whiteSpace="nowrap">
        {arg.value}
      </Box>
    );
  } else {
    const transaction = TransactionSchema.parse(arg.row.original.transaction);
    return <StyledLink to={`/transactions/${transaction.id}`}>{arg.value}</StyledLink>;
  }
};

export const CompanyCell = (arg: any) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  const name = isGmc(marginCall) ? marginCall.company.name : marginCall.transaction.company.name;
  const id = isGmc(marginCall) ? marginCall.company.id : marginCall.transaction.company.id;

  if (isGmc(marginCall) && hasPreviousRowRepeatedFields(arg, ["company.name"])) return "";
  if (!isGmc(marginCall) && hasPreviousRowRepeatedFields(arg, ["transaction.company.name"])) return "";

  return (
    <StyledBoldSpan>
      <Link to={`/companies/${id}`}>{name}</Link>
    </StyledBoldSpan>
  );
};

export const CurrencyCell = (arg: any) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  if (isGmc(marginCall)) {
    return (
      <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
        <StyledBoldSpan>
          {formatNumberToString(arg.value)} {marginCall.currency}
        </StyledBoldSpan>
      </Text>
    );
  } else {
    const transaction = marginCall.transaction;
    return (
      <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
        <StyledBoldSpan>
          {formatNumberToString(arg.value)}&nbsp;
          {transaction.dealType === DEAL_TYPE_BUY ? transaction.to.currency : transaction.from.currency}
        </StyledBoldSpan>
      </Text>
    );
  }
};

export const RemainingCell = (arg: any, nbpRates?: Rates) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  if (!marginCall || !nbpRates) return "";
  const currency = isGmc(marginCall) ? marginCall.currency : determineTransactionCurrency(marginCall.transaction);
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);

  const remainingValue = Number(marginCall.quantity) - paid;
  return (
    <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
      {remainingValue < 0 ? 0 : formatNumberToString(remainingValue)} {currency}
    </Text>
  );
};

export const TransactionIdsCell = (arg: any) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  // leaving this as there are some legacy marginCalls in DB that still are in transactions
  // and need to be displayed correctly
  if (marginCall.isGMC !== "yes" || marginCall?.globalTransactionsIds?.length === 0) return "-";
  return (
    <Box>
      {marginCall.globalTransactionsIds?.map((gmc) => (
        <StyledLink key={gmc.id} to={`/transactions/${gmc.id}`}>
          <Box as="span" display="block" whiteSpace="nowrap">
            #{gmc.number}
          </Box>
        </StyledLink>
      ))}
    </Box>
  );
};

export const RemainingRatioCell = (arg: any, nbpRates?: Rates) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  if (!marginCall || !nbpRates) return "";
  const currency = isGmc(marginCall) ? marginCall.currency : determineTransactionCurrency(marginCall.transaction);
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);

  const remainingValue = Number(marginCall.quantity) - paid;
  return (
    <Text as="span" display="block" textAlign="center" whiteSpace="nowrap">
      <StyledBoldSpan>
        {formatNumberToString(paid)} {currency} / {remainingValue < 0 ? 0 : formatNumberToString(remainingValue)}&nbsp;
        {currency}
      </StyledBoldSpan>
    </Text>
  );
};

export const StatusCell = (arg: any, nbpRates?: Rates) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  if (!marginCall || !nbpRates) return "";

  if (marginCall.isClosed === "yes") {
    return <b>closed</b>;
  }

  const currency = isGmc(marginCall) ? marginCall.currency : determineTransactionCurrency(marginCall.transaction);
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);

  if (paid === 0) {
    return <StyledTag color="sk-red">unpaid</StyledTag>;
  }
  if (paid >= marginCall.quantity) {
    return <StyledTag color="sk-light-green">ok</StyledTag>;
  }
  return <StyledTag color="sk-yellow">partially</StyledTag>;
};

export const CommentCell = (
  arg: any,
  handleCommentUpdate: (update: { marginCall: MarginCallExtended | GlobalMarginCall; comment: string }) => void
) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;

  return (
    <EditableComment
      comment={arg.value}
      onSave={(newComment) =>
        handleCommentUpdate({
          marginCall,
          comment: newComment,
        })
      }
    />
  );
};

export const EditCell = (arg: any) => {
  const marginCall = arg.row.original as MarginCallExtended | GlobalMarginCall;
  if (!marginCall || marginCall.isClosed === "yes") return "";

  const addUrl = isGmc(marginCall)
    ? `/companies/${marginCall.company.id}/margin-calls/${marginCall.id}/add`
    : `/calls/margin-calls/${marginCall.transaction.id}/${marginCall.id}/add`;
  const withdrawUrl = isGmc(marginCall)
    ? `/companies/${marginCall.company.id}/margin-calls/${marginCall.id}/withdraw`
    : `/calls/margin-calls/${marginCall.transaction.id}/${marginCall.id}/withdraw`;

  return (
    <RestrictAccess area={ADD_MARGIN_CALLS}>
      <StyledButtonsContainer>
        <Link onClick={(e) => e.stopPropagation()} to={addUrl}>
          <StyledIconContainer>
            <SmallAddIcon boxSize="22px" />
          </StyledIconContainer>
        </Link>
        <Link onClick={(e) => e.stopPropagation()} to={withdrawUrl}>
          <StyledIconContainer>
            <MinusIcon />
          </StyledIconContainer>
        </Link>
      </StyledButtonsContainer>
    </RestrictAccess>
  );
};

export function isGmc(marginCall: MarginCallExtended | GlobalMarginCall): marginCall is GlobalMarginCall {
  return (marginCall as GlobalMarginCall).company !== undefined;
}
