import styled from "@emotion/styled";

export const StyledContainer = styled.div<{
  isRed?: boolean;
}>`
  border: 1px solid ${(props) => props.theme.colors[props.isRed ? "sk-red" : "sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  margin-bottom: 30px;
  background: ${(props) => (props.isRed ? "#fdede7" : "white")};

  table {
    tr.today-row {
      outline: 5px solid #77dd77;
      outline-offset: -5px;
      border: 0;
    }
    tr.yesterday-row {
      outline: 5px solid #fff153;
      outline-offset: -5px;
      border: 0;
    }
    tr.global-row {
      outline: 5px solid ${({ theme }) => theme.colors["sk-blue"]};
      outline-offset: -5px;
      background: ${(props) => `${props.theme.colors["sk-blue"]}25`};
      > * {
        font-weight: 700;
      }
    }
  }
`;

export const StyledCollapsableContainer = styled(StyledContainer)`
  max-height: 1002px;
  overflow-y: auto;
`;
export const StyledCollapsableSmallContainer = styled(StyledContainer)`
  max-height: 952px;
  overflow-y: auto;
`;
export const StyledSectionHeader = styled.div`
  display: flex;
  padding: 30px 30px 0 30px;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

export const StyledSectionOptions = styled.div`
  display: flex;
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
`;

export const StyledSectionOption = styled.button<{
  isActive?: boolean;
}>`
  font-weight: bold;
  color: ${(props) => (props.isActive ? props.theme.colors["sk-purple"] : props.theme.colors["sk-gray"])};
  cursor: ${(props) => (props.isActive ? "pointer" : "unset")};
  margin-left: 20px;
  svg {
    margin-top: -2px;
  }
`;

export const StyledButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
  text-decoration: underline;
`;
