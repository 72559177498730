import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ArrowLeftIcon = createIcon({
  displayName: "ArrowLeftIcon",
  path: (
    <g fill="none">
      <path
        d="M13 5.40041H1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M5 1.38435L1 5.4004L5 9.41644"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 14 10",
});
