import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../../components/Table/Table";
import { CURRENCIES } from "../../helpers/options";
import { hasPreviousRowRepeatedFields, NumberCell } from "../../helpers/tableCells";
import { Transaction } from "../../interfaces/data";
import { Text } from "@chakra-ui/react";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
} from "./shared.styled";
import styled from "@emotion/styled";
import { formatNumberToString } from "../../helpers/formatNumber";

export function CurrencyCell(arg: any) {
  if (hasPreviousRowRepeatedFields(arg, ["currency"])) {
    return "";
  }
  return (
    <Text as="span" fontSize="15px" fontWeight="900">
      {arg.value}
    </Text>
  );
}

export function ValueCell(arg: any) {
  return (
    <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
      {arg.value !== 0 && arg.row.original.type === "Sell" && "-"} {formatNumberToString(arg.value)}
    </Text>
  );
}

interface OrdersProps {
  transactions: Array<Transaction>;
  nbpRates: any;
}

export const Orders: React.FC<OrdersProps> = ({ transactions, nbpRates }) => {
  const { t } = useTranslation();
  const collapseSize = 8;
  const [isExpanded, setExpanded] = useState(false);

  const StyledTableContainer = styled.div`
    tr.selected-currency {
      font-weight: 900;
    }
  `;

  const ordersData = useMemo(() => {
    if (!transactions || transactions.length === 0 || !nbpRates) return [];

    // prepare object with currencies as keys and counter objects as values
    const transactionsData = CURRENCIES.map((currency) => currency.value).reduce(
      (acc, curr) => (
        (acc[curr] = {
          Buy: {
            currency: curr,
            type: "Buy",
            transQty: 0,
            initialMargin: 0,
          },
          Sell: {
            currency: curr,
            type: "Sell",
            transQty: 0,
            initialMargin: 0,
          },
          // eslint-disable-next-line no-sequences
        }),
        acc
      ),
      {} as any
    );

    transactions.forEach((transaction) => {
      // determine transaction currency
      const currency = transaction.from.currency;
      const type = transaction.dealType;

      // transactions in PLN are not considered for risk panel
      if (currency === "PLN") return;

      // increase proper counters for given currency
      transactionsData[currency][type].transQty =
        Number(transactionsData[currency][type].transQty) + Number(transaction.from.quantity);

      transactionsData[currency][type].initialMargin =
        Number(transactionsData[currency][type].initialMargin) + Number(transaction.from.quantity) * 0.05;
    });

    const separatedCurrencies = Object.values(transactionsData)
      .filter((transactionData: any) => transactionData["Sell"].transQty !== 0 || transactionData["Buy"].transQty !== 0)
      .sort((a: any, b: any) => {
        const aValue = (Number(a["Sell"].transQty) + Number(a["Buy"].transQty)) * nbpRates.rates[a["Sell"].currency];
        const bValue = (Number(b["Sell"].transQty) + Number(b["Buy"].transQty)) * nbpRates.rates[b["Sell"].currency];

        return aValue > bValue ? -1 : 1;
      });

    const separatedTypes: Array<any> = [];

    separatedCurrencies.forEach((currency: any) =>
      Object.values(currency).forEach((type) => separatedTypes.push(type))
    );

    return separatedTypes;
  }, [transactions, nbpRates]);

  const visibleOrdersData = useMemo(() => {
    if (isExpanded) {
      return ordersData;
    }
    return ordersData.slice(0, collapseSize);
  }, [ordersData, isExpanded]);

  const columns = useMemo(
    () => [
      {
        Header: t("risk:Currency"),
        accessor: "currency",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Trans. Qty.")}
          </Text>
        ),
        accessor: "transQty",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Initial margin")}
          </Text>
        ),
        accessor: "initialMargin",
        Cell: NumberCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("risk:Orders")}</StyledSectionName>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table columns={columns} data={visibleOrdersData} paginated={false} />
        {ordersData.length > collapseSize && (
          <StyledButtonContainer>
            <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
              {isExpanded ? t("Collapse") : t("Expand")}
            </StyledButton>
          </StyledButtonContainer>
        )}
      </StyledTableContainer>
    </StyledContainer>
  );
};
