import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScoring } from "hooks/useScoring";
import {
  StyledAddbutton,
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
  StyledTableControls,
} from "./shared";
import { Heading } from "components/Heading/Heading";
import { Box } from "components/Box/Box";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input/Input";
import { useValidation } from "hooks/useValidation";
import { limitSchema } from "validations/scoring";
import { formatNumberToString } from "helpers/formatNumber";
import { useToast } from "hooks/useToast";
import { EditIcon } from "theme/icons";

export const GlobalLimit = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { globalLimit, editLimit } = useScoring();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [globalLimitInput, setGlobalLimitInput] = useState(globalLimit);
  const { validate, errors: editLimitErrors, clearErrors } = useValidation(limitSchema);

  useEffect(() => {
    if (globalLimit) {
      setGlobalLimitInput(globalLimit);
    }
  }, [globalLimit]);

  const handleClose = () => {
    setIsModalOpen(false);
    clearErrors();
  };

  const handleSubmit = async () => {
    await validate({
      limit: globalLimitInput,
    });
    try {
      await editLimit(globalLimitInput);
      handleClose();
      toast({
        type: "success",
        message: t("scoring:Limit successfully edited."),
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <StyledContainer>
      <Modal
        confirmText={t("scoring:Edit")}
        design="primary"
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleSubmit}
        title={t("scoring:Edit global limit")}
      >
        <Box mb={"20px"}>
          <Input
            error={editLimitErrors?.["limit"]}
            id="limit"
            label={t("scoring:Limit")}
            name="limit"
            onChange={(e: any) => setGlobalLimitInput(e.target.value)}
            type="number"
            value={globalLimitInput}
            withError={!!editLimitErrors?.["limit"]}
          />
        </Box>
      </Modal>
      <StyledSectionHeader>
        <StyledSectionName>{t("scoring:Global limit")}</StyledSectionName>
        <StyledTableControls>
          <StyledAddbutton onClick={() => setIsModalOpen(true)}>
            <EditIcon mr="10px" />
            Edit
          </StyledAddbutton>
        </StyledTableControls>
      </StyledSectionHeader>
      <Box p="30px">
        <Heading type="h1">{formatNumberToString(globalLimit)} EUR</Heading>
        {/* <button onClick={migrate}>Migrate</button> */}
      </Box>
    </StyledContainer>
  );
};
