import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { Contact } from "interfaces/data";
import { contactSchema } from "validations/contact";
import { useValidation } from "./useValidation";
import { useLogs } from "./useLogs";
import { convertToTimestamp } from "helpers/timestamp";

export interface UseContactOptions {
  skipFetching?: boolean;
  skipLog?: boolean;
  id?: string;
}

const COLLECTION = "contacts";

export const useContact = (
  { skipFetching, id, skipLog }: UseContactOptions = {
    skipFetching: false,
    skipLog: false,
  }
) => {
  const [contact, setContact] = useState<Contact>();
  const [loading, setLoading] = useState(true);
  const { validate, errors, clearErrors } = useValidation(contactSchema);
  const { db, timestamp } = useFirebase();
  const { log } = useLogs();

  const fetch = useCallback(
    (id: string) => {
      const collection = db.collection(COLLECTION);

      return collection.doc(id).onSnapshot((snap: firestore.DocumentData) => {
        const contact = snap.data() as Contact;
        setLoading(false);
        if (contact) {
          setContact({ ...contact, id });
        } else {
          setContact(undefined);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const find = useCallback(
    async (path: string, value: string) => {
      const collection = db.collection(COLLECTION);

      return collection
        .where(path, "==", value)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.docs[0].data() as Contact;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (newContact: any) => {
      setLoading(true);

      const { id, ...data } = newContact;
      const collection = db.collection(COLLECTION);
      const isValid = await validate(newContact);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const newContactWithTimeStamp = {
        ...data,
        modifiedAt: timestamp(),
        createdAt: convertToTimestamp(data.createdAt),
      };

      const oldContact = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.data() as Contact;
          }
        });

      return collection
        .doc(id)
        .update(newContactWithTimeStamp)
        .then(() => {
          setContact({ ...newContactWithTimeStamp, id });
          if (!skipLog) {
            log({
              action: "edit",
              item: {
                collection: "contacts",
                id: id,
                name: `${newContactWithTimeStamp.firstName} ${newContactWithTimeStamp.lastName}`,
              },
              url: `/contacts/${id}`,
              oldData: JSON.stringify(oldContact),
              newData: JSON.stringify(newContactWithTimeStamp),
            });
          }

          clearErrors();
          setLoading(false);
        })
        .catch((e) => {
          console.error("useContact", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const save = useCallback(
    async (newContact: any) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);
      const isValid = await validate(newContact);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      return collection
        .add({
          ...newContact,
          createdAt: timestamp(),
          modifiedAt: timestamp(),
        })
        .then((doc) => {
          if (!skipLog) {
            doc.get().then((snap: firestore.DocumentData) => {
              if (!snap.empty) {
                const newContact = snap.data() as Contact;
                log({
                  action: "create",
                  item: {
                    collection: "contacts",
                    id: doc.id,
                    name: `${newContact.firstName} ${newContact.lastName}`,
                  },
                  url: `/contacts/${doc.id}`,
                  newData: JSON.stringify(newContact),
                });
              }
            });
          }
          setLoading(false);
          return doc.id;
        })
        .catch((e) => {
          console.error("useContact", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!skipFetching && id) {
      fetch(id);
    }
  }, [fetch, id, skipFetching]);

  return {
    contact,
    setContact,
    update,
    save,
    fetch,
    loading,
    errors,
    find,
    clearErrors,
  };
};
