import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

import { FirebaseInterface } from "./interfaces/models";

export const config = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
};

class Firebase implements FirebaseInterface {
  app: app.app.App;

  auth: app.auth.Auth;

  db: app.firestore.Firestore;

  realtime: app.database.Database;

  realtimeTimestamp: typeof app.database.ServerValue.TIMESTAMP;

  increment: (n: number) => any;

  timestamp: () => Timestamp;

  timestampFrom: (date: Date) => Timestamp;

  arrayUnion: (...args: any[]) => any;

  firebaseDelete: () => any;

  constructor() {
    this.app = app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.db.settings({ ignoreUndefinedProperties: true });
    this.realtime = app.database();
    this.realtimeTimestamp = app.database.ServerValue.TIMESTAMP;
    this.increment = (n = 1) => app.firestore.FieldValue.increment(n);
    this.timestamp = app.firestore.Timestamp.now;
    this.timestampFrom = app.firestore.Timestamp.fromDate;
    this.arrayUnion = app.firestore.FieldValue.arrayUnion;
    this.firebaseDelete = app.firestore.FieldValue.delete;
  }

  isReference(ref: any): boolean {
    if (!ref) {
      return false;
    }

    if (typeof ref !== "object") {
      return false;
    }

    return !(!ref.get || typeof ref.get !== "function");
  }
}

export default Firebase;

const firebase = new Firebase();
export { firebase };
