import styled from "@emotion/styled";
import { InputGroup, InputRightElement } from "@chakra-ui/react";

import { Input } from "../Input/Input";
import { Button } from "../Button/Button";
import isPropValid from "@emotion/is-prop-valid";

export const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ layout: string; width?: string }>`
  position: relative;
  ${({ width }) => width && `width: ${width}`}
  ${({ layout }) =>
    layout === "vertical" &&
    `
	 	display: grid;
  	grid-template-columns: 110px 281px;
  	grid-column-gap: 10px;
	`}
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  svg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-left: 20px;
    color: #aaa9af;
  }
`;

export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ error?: string }>`
  border: 1px solid ${(props) => (props.error ? props.theme.colors["sk-red"] : props.theme.colors["sk-light-gray"])} !important;
  border-radius: 16px;
  padding-left: 55px;
  height: 42px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 13px;
  width: 100%;
  ::placeholder {
    color: #aaa9af;
    opacity: 1;
  }
`;

export const StyledContent = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]} !important;
  border-radius: 16px;
  min-height: 40px;
  outline: none !important;
  font-size: 13px;
  width: 100%;
  position: absolute;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  z-index: 1000;
`;

export const StyledPane = styled.div`
  overflow-y: auto;
  max-height: 235px;

  &:only-child {
    border-radius: 16px;
  }
`;

export const StyledItem = styled(Button, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  selected?: boolean;
  value?: string;
  name?: string;
}>`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
  justify-content: flex-start;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: normal;
  font-size: 13px;

  background-color: ${({ selected }) => selected && "rgba(91, 77, 190, 0.2)"};
  color: ${({ theme, selected }) => selected && theme.colors["sk-purple"]};

  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-purple"]};
  }
`;

export const StyledNoData = styled.div`
  padding: 20px;
  font-size: 13px;
  line-height: 110%;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const StyledSelectContainer = styled.div`
  position: relative;
`;

export const StyledLabel = styled("span", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ layout: string }>`
  background-color: transparent;
  color: ${({ theme }) => theme.colors["sk-gray"]};
  font-size: 13px;
  font-weight: 600;
  padding-left: 0.3rem;
  padding-right: 0.5rem;
  ${({ layout }) =>
    layout === "horizontal"
      ? `
			left: 20px;
			position: absolute;
			top: -8px;
			z-index: 2;
		`
      : `
		margin-top: 13px;
		display: flex;
		justify-content: flex-end;
		`}
`;

export const StyledInputRightElement = styled(InputRightElement, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  isOpen?: boolean;
  isDisabled?: boolean;
}>`
  svg {
    transition: transform 0.1s ease-in-out;
    transform: ${({ isOpen }) => isOpen && "rotate(180deg)"};
    transform-origin: 50% 50%;
    margin-top: 4px;
    ${({ isDisabled, theme }) =>
      isDisabled &&
      `
				color: ${theme.colors["sk-gray"]};
				cursor: not-allowed;
    	`};
  }
`;
