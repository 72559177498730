import React, { useCallback, useMemo, useState } from "react";
import { Table } from "components/Table/Table";
import { Box, InputLeftElement, useDisclosure } from "@chakra-ui/react";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useApplications } from "hooks/useApplications";
import { Modal } from "components/Modal/Modal";
import { Application, Company } from "interfaces/data";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { getMilisecondsFromTimestamp, transformTimestamp } from "helpers/date";
import { LEGAL_REPRESENTANT } from "helpers/contact";
import { useToast } from "hooks/useToast";
import { PageHeader } from "components/PageHeader/PageHeader";
import { useLogs } from "hooks/useLogs";
import dayjs from "dayjs";
import chroma from "chroma-js";
import { EditableComment } from "views/Payments/EditableComment";
import { Link } from "react-router-dom";
import { useApplication } from "../../hooks/useApplication";
import { DeleteIcon, SearchIcon } from "@chakra-ui/icons";

const StyledButtonContainer = styled.div`
  text-align: right;
`;

const StyledDeleteButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 13px;
  width: 15px;
  line-height: 110%;
  margin-top: 3px;
  svg {
    margin-top: -5px;
  }
`;

const StyledNewTag = styled.div`
  margin: -10px 0 -10px 24px;
  background: ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 16px;
  padding: 10px;
  font-weight: 800;
  font-size: 11px;
  color: ${(props) => String(chroma(props.theme.colors["sk-dark"]).alpha(0.4))};
`;

function CreatedAtCell(arg: any) {
  return transformTimestamp(arg.value);
}

function RepresentantCell(arg: any) {
  const representant = arg.row.original.contacts.find((item: any) => item.type === LEGAL_REPRESENTANT);
  return representant ? `${representant.firstName} ${representant.lastName}` : "";
}

function EmailCell(arg: any) {
  const representant = arg.row.original.contacts.find((item: any) => item.type === LEGAL_REPRESENTANT);
  return <p>{representant?.email}</p>;
}

function createDeleteCell(
  openModal: () => void,
  setDeleteRow: React.Dispatch<React.SetStateAction<Application | null>>
) {
  return (arg: any) => {
    return (
      <StyledButtonContainer>
        <StyledDeleteButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteRow(arg.row.original);
            openModal();
          }}
        >
          <DeleteIcon />
        </StyledDeleteButton>
      </StyledButtonContainer>
    );
  };
}

export function Applications() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [deleteRow, setDeleteRow] = useState<Application | null>(null);
  const toast = useToast();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { log } = useLogs();
  const { update } = useApplication({ skipFetching: true });

  const CompanyCell = useCallback((arg: any) => {
    const company = arg.row.original as Company;

    const isNew = dayjs(getMilisecondsFromTimestamp(company.createdAt)).add(7, "day").isAfter(dayjs(), "day");

    return (
      <Box alignItems="center" display="flex" fontSize="15px" fontWeight="900">
        <Link to={`/applications/${company.id}`}>{arg.value}</Link>
        {isNew && <StyledNewTag>new</StyledNewTag>}
      </Box>
    );
  }, []);

  const handleRemove = useCallback(() => {
    if (deleteRow) {
      remove(deleteRow?.id).then(async () => {
        await log({
          action: "cancel",
          item: {
            collection: "applications",
            id: deleteRow.id,
            name: deleteRow.name,
          },
          oldData: JSON.stringify(deleteRow),
        });
        handleClose();
        toast({
          type: "success",
          message: t("application:Application successfully deleted."),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRow]);

  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "recentlyAdded",
        label: "Recently added",
        field: "createdAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("company:Company name (A-Z)"),
        field: "name",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("company:Company name (Z-A)"),
        field: "name",
        order: "desc",
      },
    ],
    [t]
  );

  const [sortingOption, setSortingOption] = useState(sortingOptions[0]);
  const { applications, loading, pageCount, remove, refetch } = useApplications({
    phrase: debouncedSearchQuery,
    fields: "name,createdAt|timestamp,contacts[].email,contacts[].firstName,contacts[].lastName",
    orderBy: sortingOption.field,
    orderDirection: sortingOption.order,
    offset,
    limit,
  });

  const handleClose = useCallback(() => {
    onClose();
    setDeleteRow(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCommentUpdate = useCallback(
    async ({ application, comment }: { application: Application; comment: string }) => {
      await update({ ...application, comment }, {});
      await refetch();
      toast({
        type: "success",
        message: t("Application comment has been updated."),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const CommentCell = useCallback(
    (arg: any) => {
      const application = arg.row.original as Application;

      return (
        <EditableComment
          comment={arg.value}
          onSave={(comment) => {
            handleCommentUpdate({ application, comment });
          }}
        />
      );
    },
    [handleCommentUpdate]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("Company"),
        accessor: "name",
        Cell: CompanyCell,
      },
      {
        Header: t("Comment"),
        accessor: "comment",
        Cell: CommentCell,
      },
      {
        Header: t("Created date"),
        accessor: "createdAt",
        Cell: CreatedAtCell,
      },
      {
        Header: t("Representant"),
        accessor: "representant",
        id: "representant",
        Cell: RepresentantCell,
      },
      {
        Header: t("Email"),
        accessor: "email",
        id: "email",
        Cell: EmailCell,
      },
      {
        Header: "",
        accessor: "delete",
        id: "deleteAction",
        Cell: createDeleteCell(onOpen, setDeleteRow),
        maxWidth: "5px",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const handleTableUpdate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleSortByUpdate = useCallback((newSelectedOption: TableSortOption) => {
    setSortingOption(newSelectedOption);
  }, []);

  if (!applications) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("Applications")}</Heading>
      </PageHeader>
      <StyledContainer>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <TableSortSelect
            onSortByChange={handleSortByUpdate}
            options={sortingOptions}
            selectedOption={sortingOption}
          />
        </StyledTableControls>
        <Table
          columns={columns}
          data={applications}
          initialPageSize={25}
          loading={loading}
          onStateUpdate={handleTableUpdate}
          pageCount={pageCount}
          pageSizeOptions={[25, 50, 100]}
        />
      </StyledContainer>
      <Modal
        confirmText={t("application:Yes, delete it")}
        design="danger"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleRemove}
        title={t(`application:Delete "{{name}}" application`, {
          name: deleteRow?.name,
        })}
      >
        <Box mb={"20px"}>
          {t("application:This action can't be undone. Are you sure you want to delete this application?")}
        </Box>
      </Modal>
    </>
  );
}
