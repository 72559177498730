import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { useBankAccounts } from "hooks/useBankAccounts";
import _cloneDeep from "lodash.clonedeep";
import { StyledInput, StyledInputGroup } from "components/ItemsListElements/ItemsListElements";
import { Box, InputLeftElement, useDisclosure } from "@chakra-ui/react";
import { StyledPanel } from "../Settings";
import styled from "@emotion/styled";
import { BankAccount } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { Button } from "components/Button/Button";
import { BankAccountFormModal } from "./BankAccountFormModal";
import { Modal } from "components/Modal/Modal";
import { CURRENCIES } from "helpers/options";
import { Select } from "components/Select/Select";
import { EditIcon, RemoveIcon, SearchIcon } from "theme/icons";

const StyledTableControls = styled.div`
  padding: 30px 30px 0 30px;
  display: flex;
`;

export const StyledTableContainer = styled.div`
  tr.default-account-row {
    font-weight: 900;
  }
  tr td:last-child,
  tr td:nth-last-child(2) {
    width: 120px;
  }
`;
function createEditCell(
  openModal: () => void,
  label: string,
  setEditRow: React.Dispatch<React.SetStateAction<BankAccount | null>>
) {
  return (arg: any) => {
    return (
      <Box textAlign="right">
        <button
          onClick={(e) => {
            setEditRow(_cloneDeep(arg.row.original));
            openModal();
            e.stopPropagation();
          }}
        >
          <EditIcon ml="auto" mr="5px" mt="-2px" /> {label}
        </button>
      </Box>
    );
  };
}

function createDeleteCell(
  openModal: () => void,
  label: string,
  setEditRow: React.Dispatch<React.SetStateAction<BankAccount | null>>
) {
  return (arg: any) => {
    return (
      <Box textAlign="right">
        <button
          onClick={(e) => {
            setEditRow(_cloneDeep(arg.row.original));
            openModal();
            e.stopPropagation();
          }}
        >
          <RemoveIcon ml="auto" mr="5px" mt="-2px" />
          {label}
        </button>
      </Box>
    );
  };
}

export function BankAccounts() {
  const { t } = useTranslation();
  const { bankAccounts, update, save, remove, refetch, errors, clearErrors } = useBankAccounts();
  const [searchQuery, setSearchQuery] = useState<string>();
  const [filteredCurrency, setFilteredCurrency] = useState<string>();
  const [filteredBankName, setFilteredBankName] = useState<string>();
  const toast = useToast();
  const [editedBankAccount, setEditedBankAccount] = useState<BankAccount | null>(null);
  const [deletedBankAccount, setDeletedBankAccount] = useState<BankAccount | null>(null);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [isSending, setIsSending] = useState(false);

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleAddClose = useCallback(() => {
    onAddClose();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditClose = useCallback(() => {
    onEditClose();
    setEditedBankAccount(null);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteClose = useCallback(() => {
    onEditClose();
    setDeletedBankAccount(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = useCallback(
    (newBankAccount: BankAccount) => {
      setIsSending(true);
      save(newBankAccount).then((isSaved) => {
        setIsSending(false);
        if (isSaved) {
          onAddClose();
          refetch();
          toast({
            type: "success",
            message: t("settings:Bank Account has been created successfully."),
          });
        }
      });
    },
    [onAddClose, refetch, save, t, toast]
  );

  const handleEdit = useCallback(
    (newBankAccount: BankAccount) => {
      setIsSending(true);
      update(newBankAccount).then((isSaved) => {
        setIsSending(false);
        if (isSaved) {
          onEditClose();
          setEditedBankAccount(null);
          refetch();
          toast({
            type: "success",
            message: t("settings:Bank Account has been updated successfully."),
          });
        }
      });
    },
    [onEditClose, refetch, t, toast, update]
  );

  const handleDelete = useCallback(() => {
    if (!deletedBankAccount || !deletedBankAccount.id) return;
    setIsSending(true);
    remove(deletedBankAccount)
      .then((result) => {
        setIsSending(false);
        if (result) {
          onDeleteClose();
          setDeletedBankAccount(null);
          refetch();
          toast({
            type: "success",
            message: t("settings:Bank Account has been deleted successfully."),
          });
        }
      })
      .catch((error) => {
        setIsSending(false);
        onDeleteClose();
        setDeletedBankAccount(null);
        toast({
          type: "error",
          message: t(`settings:${error.message}`),
        });
      });
  }, [deletedBankAccount, onDeleteClose, refetch, remove, t, toast]);

  const filteredBankAccounts = useMemo(() => {
    if (!bankAccounts) return [];
    const bankAccountsCopy = _cloneDeep(bankAccounts);

    const searchFilter = searchQuery
      ? bankAccountsCopy.filter((bankAccount) => {
          return (
            bankAccount.currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
            bankAccount.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            bankAccount.number.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
      : bankAccountsCopy;

    const currencyFilter = filteredCurrency
      ? searchFilter.filter((bankAccount) => {
          return bankAccount.currency.toLowerCase() === filteredCurrency.toLowerCase();
        })
      : searchFilter;

    return filteredBankName
      ? currencyFilter.filter((bankAccount) => {
          return bankAccount.name.toLowerCase() === filteredBankName.toLowerCase();
        })
      : currencyFilter;
  }, [bankAccounts, searchQuery, filteredBankName, filteredCurrency]);

  const sortedBankAccounts = useMemo(
    () => filteredBankAccounts.sort((a, b) => (a.currency > b.currency ? 1 : -1)),
    [filteredBankAccounts]
  );

  const availableBankAccountsNames = useMemo(() => {
    if (!bankAccounts || bankAccounts.length === 0) return [];

    const bankAccountsNames = bankAccounts.map((account) => account.name);
    const uniqueBankAccountsNames = bankAccountsNames.filter((v, i, a) => a.indexOf(v) === i);

    return uniqueBankAccountsNames.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
  }, [bankAccounts]);

  const columns = useMemo(
    () => [
      {
        Header: t("settings:Number"),
        accessor: "number",
      },
      {
        Header: t("settings:Currency"),
        accessor: "currency",
      },
      {
        Header: t("settings:SWIFT/BIC"),
        accessor: "swift",
      },
      {
        Header: t("settings:Bank name"),
        accessor: "name",
      },
      {
        accessor: "editAction",
        Cell: createEditCell(onEditOpen, t("Edit"), setEditedBankAccount),
      },
      {
        accessor: "deleteAction",
        Cell: createDeleteCell(onDeleteOpen, t("Delete"), setDeletedBankAccount),
      },
    ],
    [onDeleteOpen, onEditOpen, t]
  );

  if (!bankAccounts) return null;

  return (
    <>
      <StyledPanel>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <Box ml="20px" w="400px">
            <Select
              name="currency"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFilteredBankName(e.target.value);
              }}
              options={availableBankAccountsNames.map((bank) => {
                return { label: bank, value: bank };
              })}
              placeholder={t("settings:All banks")}
            />
          </Box>
          <Box ml="20px" w="320px">
            <Select
              name="currency"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFilteredCurrency(e.target.value);
              }}
              options={CURRENCIES}
              placeholder={t("settings:All currencies")}
            />
          </Box>
          <Box ml="30px">
            <Button design="primary" onClick={onAddOpen}>
              {t("settings:Add bank account")}
            </Button>
          </Box>
        </StyledTableControls>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={sortedBankAccounts}
            getRowProps={(rowInfo) => {
              const bankAccount = rowInfo.original as BankAccount;
              return {
                className: bankAccount.isDefault ? "default-account-row" : "",
              };
            }}
            paginated={false}
          />
        </StyledTableContainer>
      </StyledPanel>
      {isEditOpen && editedBankAccount && (
        <BankAccountFormModal
          errors={errors}
          initialBankAccount={editedBankAccount}
          isDisabled={isSending}
          onClose={handleEditClose}
          onSave={handleEdit}
          title={t("settings:Edit bank account")}
          type="edit"
        />
      )}
      {isAddOpen && (
        <BankAccountFormModal
          errors={errors}
          initialBankAccount={
            {
              number: "",
              name: "",
              swift: "",
              currency: "",
            } as BankAccount
          }
          isDisabled={isSending}
          onClose={handleAddClose}
          onSave={handleAdd}
          title={t("settings:Add bank account")}
          type="add"
        />
      )}
      <Modal
        confirmText={t("Yes")}
        design="danger"
        isDisabled={isSending}
        isOpen={isDeleteOpen && Boolean(deletedBankAccount)}
        onClose={handleDeleteClose}
        onConfirm={handleDelete}
        title={t(`settings:Delete bank account`)}
      >
        <Box mb={"20px"}>
          {t(
            `settings:This action can't be undone. Are you sure you want to delete {{currency}} bank account {{banknumber}}?`,
            {
              currency: deletedBankAccount?.currency,
              banknumber: deletedBankAccount?.number,
            }
          )}
        </Box>
      </Modal>
    </>
  );
}
