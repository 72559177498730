import React, { useCallback, useMemo, useState } from "react";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { Table } from "components/Table/Table";
import { Settlement } from "interfaces/data";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCollapsableContainer,
  StyledSectionHeader,
  StyledSectionName,
  StyledSectionOption,
  StyledSectionOptions,
} from "./shared.styled";
import { DateCell } from "helpers/tableCells";
import { useHistory, useParams } from "react-router-dom";
import { formatFixedRate, formatNumberToString, formatRate } from "helpers/formatNumber";
import { DELIVERY_TYPES, TRANSACTION_TYPES } from "helpers/transaction";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { AddIcon, EditIcon, NoteIcon, PaperPlaneIcon, RemoveIcon } from "theme/icons";

interface SettlementsProps {
  settlements?: Array<Settlement>;
  transactionId: string;
  transactionType: string;
  isDisabled?: boolean;
  fromCurrency: string;
}

dayjs.extend(isToday);
dayjs.extend(isYesterday);

function IdCell(arg: any) {
  const comment = arg.row.original.comment;
  return (
    <Box display="flex" whiteSpace="nowrap">
      {arg.value}
      {comment && (
        <Box alignItems="center" display="flex" ml="5px" title={comment}>
          <NoteIcon height="11px" />
        </Box>
      )}
    </Box>
  );
}

const TypeCell = (arg: any) => {
  const settlement = arg.row.original as Settlement;
  const type = settlement.type;
  return (
    <Box>
      {type}
      {type === DELIVERY_TYPES.NET.value && settlement.closedRate ? ` - ${formatRate(settlement.closedRate)}` : ""}
    </Box>
  );
};

export const Settlements: React.FC<SettlementsProps> = ({
  settlements = [],
  transactionId,
  transactionType,
  isDisabled = false,
  fromCurrency,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const isSpotType = transactionType !== TRANSACTION_TYPES.SPOT.value;
  const [selectedRow, setSelectedRow] = useState<Settlement>();
  const collapseSize = 10;
  const [isExpanded, setExpanded] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("Date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: t("Type"),
        accessor: "type",
        Cell: TypeCell,
      },
      {
        Header: () => t("Qty"),
        accessor: "quantity",
        Cell: (arg: any) => formatNumberToString(arg.value),
      },
      {
        Header: () => t("Currency"),
        accessor: "currency",
        Cell: () => fromCurrency,
      },
      ...(isSpotType
        ? [
            {
              Header: t("Fwd points"),
              accessor: "fwdPoints",
              Cell: (arg: any) => formatFixedRate(arg.value, 4),
            },
            {
              Header: t("Rollback"),
              accessor: "rollback",
              Cell: (arg: any) => formatFixedRate(arg.value, 4),
            },
            {
              Header: t("Points"),
              accessor: "points",
              Cell: (arg: any) => formatFixedRate(arg.value, 4),
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleResend = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/settlements/${selectedRow.id}/resend`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleCancel = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/settlements/${selectedRow.id}/cancel`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleEditHedges = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/settlements/${selectedRow.id}/edit-hedges`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleEdit = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/settlements/${selectedRow.id}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleAdd = useCallback(() => {
    if (isDisabled) return;
    history.push(`/transactions/${id}/settlements/add`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, id]);

  const visibleSettlements = useMemo(() => {
    if (isExpanded) {
      return settlements;
    }
    return settlements.slice(0, collapseSize);
  }, [settlements, isExpanded]);

  return (
    <StyledCollapsableContainer>
      <ActiveUserBorder collection="transactions" id={transactionId} sections="settlements">
        <StyledSectionHeader>
          <StyledSectionName>{t("transaction:Settlements")}</StyledSectionName>
          <StyledSectionOptions>
            {isSpotType && (
              <StyledSectionOption isActive={!!selectedRow} onClick={handleEditHedges}>
                <EditIcon mr="10px" /> {t("hedge:Hedges")}
              </StyledSectionOption>
            )}
            <StyledSectionOption isActive={!!selectedRow} onClick={handleResend}>
              <PaperPlaneIcon mr="10px" /> {t("Resend")}
            </StyledSectionOption>
            <StyledSectionOption isActive={!!selectedRow} onClick={handleCancel}>
              <RemoveIcon mr="10px" /> {t("Cancel")}
            </StyledSectionOption>
            <StyledSectionOption isActive={!!selectedRow} onClick={handleEdit}>
              <EditIcon mr="10px" /> {t("Edit")}
            </StyledSectionOption>
            <StyledSectionOption isActive={!isDisabled} onClick={handleAdd}>
              <AddIcon mr="10px" /> {t("Add")}
            </StyledSectionOption>
          </StyledSectionOptions>
        </StyledSectionHeader>
        <Table
          columns={columns}
          data={visibleSettlements}
          getRowProps={(rowInfo) => {
            const settlement = rowInfo.original as Settlement;
            const modifiedAtDate = settlement.createdAt?.seconds;
            if (!modifiedAtDate) return "";
            const date = dayjs(modifiedAtDate * 1000);
            return {
              className: `
              ${date.isToday() ? "today-row" : ""}
              ${date.isYesterday() ? "yesterday-row" : ""}
              `,
            };
          }}
          onRowSelected={setSelectedRow}
          paginated={false}
          selectableRows={!isDisabled}
        />
        {settlements.length > collapseSize && (
          <StyledButtonContainer>
            <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
              {isExpanded ? t("Collapse") : t("Expand")}
            </StyledButton>
          </StyledButtonContainer>
        )}
      </ActiveUserBorder>
    </StyledCollapsableContainer>
  );
};
