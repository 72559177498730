import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import theme from "./theme/theme";
import FirebaseContext from "./contexts/firebase";
import TransactionContextProvider from "./contexts/transaction/transactionContextProvider";
import { KeyboardFocusDetector } from "./components/KeyboardFocusDetector/KeyboardFocusDetector";
import "./i18n";
import { firebase } from "./Firebase";
import { GlobalStyles } from "./theme/GlobalStyles";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  // <StrictMode>  disabled due to remounting of components in React 18, which causes dashboards to break
  <FirebaseContext.Provider value={firebase}>
    <ChakraProvider theme={theme}>
      <GlobalStyles />
      <KeyboardFocusDetector />
      <TransactionContextProvider>
        <App />
      </TransactionContextProvider>
    </ChakraProvider>
  </FirebaseContext.Provider>
  // </StrictMode>
);
