import React from "react";
import { BrowserRouter as Router, Prompt, Redirect, Route, Switch } from "react-router-dom";

import { Layout } from "./components/Layout/Layout";
import { useAuth } from "./hooks/useAuth";
import guest from "./router/guest";
import user from "./router/user";
import { ScrollToTop } from "./helpers/ScrollToTop";
import { ActiveUsersContextProvider } from "./contexts/activeUsers";
import { hasAccess } from "./helpers/userRoles";
import { LastActivityContextProvider } from "./contexts/lastActivity";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

const userRoutes = (role?: string) => {
  return user.map((route, i) => (
    <Route exact={route.exact} key={i} path={route.path}>
      {hasAccess(role, route.guard) ? (
        <>
          {route.parent && <route.parent />}
          <route.component />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/companies",
            state: { restricted: true },
          }}
        />
      )}
    </Route>
  ));
};

const guestRoutes = guest.map((route, i) => (
  <Route exact={route.exact} key={i} path={route.path}>
    <route.component />
  </Route>
));

function App() {
  const { isLogged, profile } = useAuth({ skipAuthCheck: false });
  const role = profile?.role;

  return React.useMemo(() => {
    const LayoutComponent = isLogged ? Layout.LoggedIn : Layout.Unauthorized;

    return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <ActiveUsersContextProvider>
            <LastActivityContextProvider>
              <ScrollToTop />
              {/* TODO: add context for storing info about unsaved changes */}
              <Prompt
                message="You have unsaved changes. If you leave the page, you will loose any unsaved changes."
                when={false}
              />
              <LayoutComponent>
                <Switch>{isLogged ? userRoutes(role) : guestRoutes}</Switch>
              </LayoutComponent>
            </LastActivityContextProvider>
          </ActiveUsersContextProvider>
        </Router>
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    );
  }, [isLogged, role]);
}

export default App;
