import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { Checkbox } from "@chakra-ui/react";
import { CheckboxColors } from "./Checkbox";

export const StyledCheckbox = styled(Checkbox, {
  shouldForwardProp: (prop) => isPropValid(prop) || prop === "isChecked",
})<{
  checkboxSize: "small" | "normal";
  checkboxColor: CheckboxColors;
}>`
  /** Get checked checkbox */
  & > input[type="checkbox"]:checked + span:first-of-type {
    border-color: ${(props) => props.theme.colors[props.checkboxColor]};
    background-color: ${(props) => props.theme.colors[props.checkboxColor]};
  }

  /** Get disabled checkbox */
  & > input[type="checkbox"]:disabled + span:first-of-type {
    color: white;
  }
  & > input[type="checkbox"]:disabled:not(:checked) + span:first-of-type {
    background-color: white;
  }

  /** Get checkbox */
  & > span:first-of-type {
    border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
    border-radius: ${(props) => (props.checkboxSize === "small" ? "4px" : "6px")};
    width: ${(props) => (props.checkboxSize === "small" ? "12px" : "20px")};
    height: ${(props) => (props.checkboxSize === "small" ? "12px" : "20px")};
    background: #fff;
    svg {
      ${(props) => props.checkboxSize === "small" && "height: 7px"}
    }
  }

  /** Get checkbox label */
  & > span:nth-of-type(2) {
    font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  }
`;
