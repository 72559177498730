import React, { useState } from "react";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Heading } from "components/Heading/Heading";
import { useFirebase } from "hooks/useFirebase";
import { Table } from "components/Table/Table";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { BatchActions, DbIdCell, Pagination } from "./components";
import { MenuItem, useTheme } from "@chakra-ui/react";
import { ContactSchema } from "schemas/contact";
import { batchDelete, deleteContact, fixContact, saveContact } from "./fixContact";
import { DataFixerNav } from "./components/DataFixerNav";
import { ValidationModal } from "./components/ValidationModal";
import { useValidationModal } from "./hooks/useValidationModal";
import { ActionButtons } from "./components/ActionButtons";
import { ZodError } from "zod";

export function ContactsDataFixer() {
  const { db } = useFirebase();
  const { colors } = useTheme();
  const { page: paramPage } = useParams<{ page?: string }>();
  const [page, setPage] = useState(Number(paramPage) || 1);
  const [ids, setIds] = useState<string[]>([]);
  const [validationData, setValidationData] = useValidationModal();
  const { token } = useAuth();
  const { data, isLoading } = useQuery(["contacts", page], async () => {
    const response = await fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/data-fixer/contacts?page=${page}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.ok ? response.json() : [];
  });
  const queryClient = useQueryClient();
  const history = useHistory();

  const changePage = (page: number) => {
    setPage(page);
    history.push(`/data-fixer/contacts/${page}`);
  };

  const handleShowValidationModal = (e: MenuItemEvent, validationErrors: ZodError, contact: any) => {
    e.stopPropagation();
    setValidationData({
      title: `Validation errors for contact ${contact.name}`,
      validationErrors,
      fixFunction: async () => {
        const fixedContact = fixContact(contact);
        await saveContact(fixedContact, db, true);
        await queryClient.invalidateQueries(["contacts", page]);
      },
    });
  };

  const handleDelete = async (e: MenuItemEvent, contact: any) => {
    e.stopPropagation();
    await deleteContact(contact, db);
    await queryClient.invalidateQueries(["contacts", page]);
  };

  const columns = [
    {
      Header: "DB ID",
      accessor: "id",
      Cell: (arg: any) => DbIdCell(arg, "/contacts"),
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (arg: any) => {
        const contact = arg.row.original;
        const result = ContactSchema.safeParse(contact);

        return (
          <ActionButtons>
            {!result.success && (
              <MenuItem onClick={(e) => handleShowValidationModal(e, result.error, contact)}>Validate</MenuItem>
            )}
            <MenuItem onClick={(e) => handleDelete(e, contact)}>Delete</MenuItem>
          </ActionButtons>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader>
        <Heading type="h1">Contacts Data Fixer</Heading>
      </PageHeader>

      <DataFixerNav />

      {isLoading && <div>Loading...</div>}

      {validationData && (
        <ValidationModal
          onClose={() => setValidationData(undefined)}
          onConfirm={() => {
            validationData.fixFunction();
            setValidationData(undefined);
          }}
          title={validationData.title}
          validationErrors={validationData.validationErrors}
        />
      )}

      {data && (
        <>
          <Pagination changePage={changePage} data={data} page={page} />
          <Table
            columns={columns}
            data={data}
            getRowProps={(arg: any) => {
              const contact = arg.original;
              if (!contact) return {};
              const validation = ContactSchema.safeParse(contact);
              const isSelected = ids.includes(contact.id);
              const onClick = () =>
                setIds((ids) => (isSelected ? ids.filter((id) => id !== contact.id) : [...ids, contact.id]));
              if (!validation.success) {
                return {
                  style: {
                    background: isSelected ? colors.red["300"] : colors.red["200"],
                  },
                  onClick,
                };
              }
              return {
                onClick,
                style: {
                  background: isSelected ? colors.gray["100"] : "transparent",
                },
              };
            }}
          />
          <Pagination changePage={changePage} data={data} page={page} />
          <BatchActions
            deleteSelected={() => batchDelete(ids, db)}
            selectAll={() => setIds(data.map((d: any) => d.id))}
            selectNone={() => setIds([])}
          />
        </>
      )}
    </>
  );
}
