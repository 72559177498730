import React from "react";
import { Link } from "react-router-dom";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import styled from "@emotion/styled";
import { CopyIcon } from "@chakra-ui/icons";

export const PrimitiveCell = (arg: any) => (
  <p>
    {arg.value}
    <br />({typeof arg.value})
  </p>
);

export const MoneyCell = (arg: any) => (
  <p>
    {arg.value?.quantity} {arg.value?.currency}
    <br />({typeof arg.value?.quantity} {typeof arg.value?.currency})
  </p>
);

const IdCellStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
`;
export const DbIdCell = (arg: any, baseUrl: string) => {
  const copyToClipboard = useCopyToClipboard();
  return (
    <IdCellStyled>
      <Link onClick={(e) => e.stopPropagation()} style={{ color: "blue" }} to={`${baseUrl}/${arg.value}`}>
        {arg.value}
      </Link>
      <a
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          await copyToClipboard(`${arg.value}`);
        }}
        role="link"
        tabIndex={0}
      >
        <CopyIcon />
      </a>
    </IdCellStyled>
  );
};

export const LengthCell = (arg: any) => {
  if (arg.value?.length) {
    return arg.value.length;
  }
  return null;
};
