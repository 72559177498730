import { useFirebase } from "./useFirebase";
import { Transaction } from "interfaces/data";

export const useMarginCall = () => {
  const COLLECTION = "transactions";
  const { db } = useFirebase();
  const close = async (transactionId: string, marginCallId: string) => {
    // 1. get transaction from DB
    const transaction = (await db
      .collection(COLLECTION)
      .doc(transactionId)
      .get()
      .then((doc) => ({ ...doc.data(), id: doc.id }))) as Transaction;

    // 2. get margin call
    const marginCall = transaction.marginCalls?.find((mc) => mc.id === marginCallId);
    const marginCallIndex = transaction.marginCalls?.findIndex((mc) => mc.id === marginCallId);

    // 3. throw error if margin call does not exist
    if (!marginCall || marginCallIndex === -1 || marginCallIndex === undefined)
      throw Error(`Margin call ${marginCallId} does not exist in transaction ${transactionId}`);

    // 4. close margin call
    marginCall.isClosed = "yes";

    // 5. update transaction in DB
    await db
      .collection(COLLECTION)
      .doc(transactionId)
      .update({
        marginCalls: [
          ...(transaction.marginCalls?.slice(0, marginCallIndex) || []),
          marginCall,
          ...(transaction.marginCalls?.slice(marginCallIndex + 1) || []),
        ],
      });

    return true;
  };

  return { close };
};
