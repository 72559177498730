import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CompaniesIcon = createIcon({
  displayName: "CompaniesIcon",
  path: (
    <g fill="none">
      <path
        d="M12.6364 5.36364V16.2727C12.6364 16.6727 12.3091 17 11.9091 17H1.72727C1.32727 17 1 16.6727 1 16.2727V1.72727C1 1.32727 1.32727 1 1.72727 1H8.27273"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        clipRule="evenodd"
        d="M12.6363 5.36364H8.27271V1L12.6363 5.36364Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.18188 9H7.54552"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.72729 9H10.4546"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.18188 11.1816H7.54552"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.72729 11.1816H10.4546"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.18188 13.3638H7.54552"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.72729 13.3638H10.4546"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 14 18",
});
