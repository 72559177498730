import {
  BaseTransaction,
  DashboardComments,
  DashboardType,
  Hedge,
  Margin,
  MarginCall,
  Settlement,
  Transaction,
  TransactionStatus,
} from "interfaces/data";
import { useCallback, useContext, useEffect, useState } from "react";
import { firestore } from "firebase";
import { transactionSchema } from "validations/transaction";
import { hedgeSchema } from "validations/hedge";
import { useValidation } from "./useValidation";
import { useFirebase } from "./useFirebase";
import TransactionContext from "contexts/transaction/transactionContext";
import { determineTransactionStatus, TRANSACTION_STATUSES } from "helpers/transaction";
import _cloneDeep from "lodash.clonedeep";
import { marginSchema } from "validations/margin";
import { marginCallSchema } from "validations/marginCall";
import { settlementSchema } from "validations/settlement";
import { useLogs } from "./useLogs";
import { useMail } from "./useMail";
import { FIXED_RATE_MAIL, PROLONG_MAIL, SPOT_MAIL } from "helpers/mails";
import { convertToTimestamp } from "helpers/timestamp";
import { fixMarginCallsTimestamps } from "helpers/marginCall";
import { fixHedgesTimestamps } from "helpers/hedge";
import { fixMarginsTimestamps } from "helpers/margin";
import _omit from "lodash.omit";
import { MarginCallSchema } from "schemas/marginCall";
import { fixSettlementsTimestamps } from "../helpers/settlement";

const COLLECTION = "transactions";

export interface UseTransactionOptions {
  skipFetching?: boolean;
  id?: string;
}

export const useTransaction = ({ skipFetching, id }: UseTransactionOptions = { skipFetching: false }) => {
  const { transaction, setTransaction } = useContext(TransactionContext);
  const [loading, setLoading] = useState(true);
  const { validate: handleValidation, errors } = useValidation(transactionSchema);
  const { validate: validateHedge, errors: hedgeErrors } = useValidation(hedgeSchema);
  const { validate: validateMargin, errors: marginErrors } = useValidation(marginSchema);
  const { validate: validateSettlement, errors: settlementErrors } = useValidation(settlementSchema);
  const { validate: validateMarginCall, errors: marginCallErrors } = useValidation(marginCallSchema);

  const { db, timestamp, firebaseDelete } = useFirebase();
  const { log } = useLogs();
  const sendMail = useMail();

  // netsted items (hedges, margins, margin calls and settlements) have id of format
  // tranactionNumber-uniqueItemNumberWithinTranaction
  // we want to find the highest item id suffix (number after -) and add 1 to get new unique id suffix
  const _generateInnerId = useCallback(
    (nestedItems: Array<Hedge | Margin | MarginCall | Settlement>, passedTransaction?: Transaction): string => {
      const maxExistingId = Math.max(...nestedItems.map((item) => Number(item.id?.split("-")[1])), 0);
      if (passedTransaction) {
        return `${passedTransaction?.number}-${maxExistingId || 1}`;
      }
      if (!transaction) return "";
      return `${transaction.number}-${maxExistingId + 1}`;
    },
    [transaction]
  );

  const fetch = useCallback(
    (id?: string) => {
      const collection = db.collection(COLLECTION);

      if (!id) return;

      setTransaction(undefined);
      setLoading(true);

      return collection.doc(id).onSnapshot((snap: firestore.DocumentData) => {
        const newTransaction = snap.data() as Transaction;
        setLoading(false);
        if (newTransaction) {
          setTransaction({ ...newTransaction, id });
        } else {
          setTransaction(undefined);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const validate = useCallback(
    async (newTransaction: DeepPartial<Transaction>) => {
      setLoading(true);
      const isValid = await handleValidation(newTransaction);

      setLoading(false);
      return isValid;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const statusUpdate = useCallback(
    async (newTransactionStatus: TransactionStatus) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      const oldTransaction = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          return snap.data() as Transaction;
        });

      return collection
        .doc(id)
        .update({
          status: newTransactionStatus,
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: newTransactionStatus === "closed" ? "close" : "open",
            item: {
              collection: "transactions",
              id: String(oldTransaction.number),
            },
            company: oldTransaction.company,
            oldData: JSON.stringify(oldTransaction),
            newData: JSON.stringify({
              ...oldTransaction,
              status: newTransactionStatus,
            }),
            url: `/transactions/${id}`,
            transactionId: String(id),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useTransaction", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (updatedTransaction: Partial<Transaction>): Promise<boolean> => {
      setLoading(true);

      const collection = db.collection(COLLECTION);
      const isValid = await validate(updatedTransaction);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const updatedTransactionWithTimestamp = {
        ...updatedTransaction,
        modifiedAt: timestamp(),
        createdAt: convertToTimestamp(updatedTransaction.createdAt),
        agreement: convertToTimestamp(updatedTransaction.agreement),
        start: convertToTimestamp(updatedTransaction.start),
        end: convertToTimestamp(updatedTransaction.end),
        systemDate: convertToTimestamp(updatedTransaction.systemDate),
      };

      return collection
        .doc(updatedTransaction.id)
        .update(_omit(updatedTransactionWithTimestamp, ["id"]))
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "transactions",
              id: `${updatedTransaction.number}`,
            },
            transactionId: updatedTransaction.id,
            company: updatedTransaction.company,
            url: `/transactions/${updatedTransaction.id}`,
            oldData: JSON.stringify(transaction || {}),
            newData: JSON.stringify(updatedTransaction),
          });
          return true;
        })
        .catch((e) => {
          console.error("useTransactions", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const remove = useCallback(
    async (id: string) => {
      setLoading(true);
      const dbCollection = db.collection(COLLECTION);

      const canceledTransaction = await dbCollection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          const canceledTransaction = snap.data() as Transaction;
          // Transaction should not canceled if there are settlements already added
          if (canceledTransaction.settlements && canceledTransaction.settlements?.length > 0) {
            throw Error("Transaction cannot be canceled - it has settlements added.");
          }
          // Transaction should not canceled if there are hedges already added. Look only for hedges created from current transaction number (not rolled ones).
          const canceledTransactionHedges = canceledTransaction?.hedges?.filter(
            (hedge) => Number(hedge.id.split("-")[0]) === canceledTransaction.number
          );
          if (canceledTransactionHedges && canceledTransactionHedges.length > 0) {
            throw Error("Transaction cannot be canceled - it has hedges added.");
          }
          // Transaction should not canceled if it is prolonged by other transaction
          if (canceledTransaction.prolongedBy) {
            throw Error(
              `Transaction cannot be canceled - it is prolonged by transaction ${canceledTransaction.prolongedBy.number}. Cancel that transaction first.`
            );
          }
          // Transaction should not canceled if it is prolonged
          if (canceledTransaction.convertedTo) {
            throw Error(
              `Transaction cannot be canceled - it was converted to transaction ${canceledTransaction.convertedTo.number}. Cancel that transaction first.`
            );
          }
          return canceledTransaction;
        });

      if (!canceledTransaction) {
        setLoading(false);
        return false;
      }

      const batch = db.batch();
      let oldTransactionId: string;

      if (canceledTransaction.createdFrom?.id) {
        // if prolonged transaction unroll on old transaction
        oldTransactionId = canceledTransaction.createdFrom.id;
      } else if (canceledTransaction.convertedFrom?.id) {
        // if converted transaction undo conversion on old transaction
        oldTransactionId = canceledTransaction.convertedFrom?.id;
      }

      let sendCancelMail: Promise<any> = Promise.resolve();

      if (transaction?.type === "FR" && transaction?.createdFrom?.id) {
        sendCancelMail = sendMail(PROLONG_MAIL, id, { cancel: "yes" });
      } else if (transaction?.type === "FR") {
        sendCancelMail = sendMail(FIXED_RATE_MAIL, id, { cancel: "yes" });
      } else if (transaction?.type === "Spot") {
        sendCancelMail = sendMail(SPOT_MAIL, id, { cancel: "yes" });
      }
      return sendCancelMail.then(() => {
        if (oldTransactionId) {
          batch.update(dbCollection.doc(oldTransactionId), {
            status: TRANSACTION_STATUSES.CREATED.value,
            prolongedBy: firebaseDelete(),
            convertedTo: firebaseDelete(),
            modifiedAt: timestamp(),
          });
        }

        batch.delete(dbCollection.doc(id));

        return batch
          .commit()
          .then(() => {
            log({
              action: "cancel",
              item: {
                collection: "transactions",
                id: String(canceledTransaction.number),
              },
              company: canceledTransaction.company,
              transactionId: id,
              oldData: JSON.stringify(canceledTransaction || {}),
            });
            setLoading(false);
            return oldTransactionId || true;
          })
          .catch((e) => {
            console.error("cancelTransaction", e);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const commentUpdate = useCallback(
    async (comment: string) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      return collection
        .doc(id)
        .update({
          comment,
          modifiedAt: timestamp(),
        })
        .catch((e) => {
          console.error("useTransaction", e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const dashboardCommentsUpdate = useCallback(
    async (comment: string, commentType: DashboardType) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);
      const comments = transaction?.dashboardComments ?? ({} as DashboardComments);
      comments[commentType] = comment;

      return collection
        .doc(id)
        .set(
          {
            dashboardComments: comments,
            modifiedAt: timestamp(),
          },
          { merge: true }
        )
        .catch((e) => {
          console.error("useTransaction", e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const riskIgnoreUpdate = useCallback(
    async (newIgnoreMarginCallRisk: boolean) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      return collection
        .doc(id)
        .update({
          ignoreMarginCallRisk: newIgnoreMarginCallRisk,
          modifiedAt: timestamp(),
        })
        .then(() => {
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useTransaction", e);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const save = async (newTransaction: BaseTransaction) => {
    setLoading(true);

    const collection = db.collection(COLLECTION);
    const isValid = await validate(newTransaction);

    if (!isValid) {
      setLoading(false);
      return false;
    }

    const lastTransaction = await collection.orderBy("number", "desc").limit(1).get();
    let number = 1;

    if (!lastTransaction.empty) {
      const [transaction] = lastTransaction.docs;
      const { number: lastNumber } = transaction.data();

      if (!lastNumber) {
        // fallback
      }

      number += lastNumber;
    }

    const newTransactionData = {
      ...newTransaction,
      leftQuantity: newTransaction.from.quantity,
      number,
      createdAt: timestamp(),
      modifiedAt: timestamp(),
    };

    return collection
      .add(_omit(newTransactionData, ["id"]))
      .then((doc) => {
        log({
          action: "create",
          item: {
            collection: "transactions",
            id: String(number),
          },
          company: newTransaction.company,
          transactionId: doc.id,
          url: `/transactions/${doc.id}`,
          newData: JSON.stringify(newTransactionData),
        });
        return doc.id;
      })
      .catch((e) => {
        console.error("useTransactions", e);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convert = async (newTransaction: any): Promise<string | boolean> => {
    setLoading(true);

    const collection = db.collection(COLLECTION);
    const isValid = await validate(newTransaction);

    if (isValid && transaction) {
      const lastTransaction = await collection.orderBy("number", "desc").limit(1).get();
      let number = 1;

      if (!lastTransaction.empty) {
        const [transaction] = lastTransaction.docs;
        const { number: lastNumber } = transaction.data();

        if (!lastNumber) {
          // fallback
        }

        number += lastNumber;
      }

      return collection
        .add({
          ..._omit(newTransaction, ["id"]),
          number,
          convertedFrom: {
            id: transaction.id,
            number: transaction.number,
          },
          createdAt: timestamp(),
          modifiedAt: timestamp(),
        })
        .then((doc) => {
          collection.doc(id).update({
            status: TRANSACTION_STATUSES.CLOSED.value,
            convertedTo: {
              id: doc.id,
              number,
            },
            modifiedAt: timestamp(),
          });
          log({
            action: "convert",
            item: {
              collection: "transactions",
              id: String(transaction.number),
            },
            transactionId: transaction.id,
            company: transaction.company,
            url: `/transactions/${doc.id}`,
            oldData: JSON.stringify(transaction),
            newData: JSON.stringify(newTransaction),
          });
          setLoading(false);
          return doc.id;
        })
        .catch((e) => {
          console.error("convert", e);
          setLoading(false);
          return false;
        });
    }
    return false;
  };

  const roll = async (newTransaction: BaseTransaction) => {
    setLoading(true);

    const collection = db.collection(COLLECTION);
    const isValid = await validate(newTransaction);

    if (isValid && transaction) {
      const lastTransaction = await collection.orderBy("number", "desc").limit(1).get();
      let number = 1;

      if (!lastTransaction.empty) {
        const [transaction] = lastTransaction.docs;
        const { number: lastNumber } = transaction.data();

        if (!lastNumber) {
          // fallback
        }

        number += lastNumber;
      }

      const newTransactionData = {
        ..._omit(newTransaction, ["id"]),
        number,
        status: TRANSACTION_STATUSES.CREATED.value,
        createdFrom: {
          id: transaction.id,
          number: transaction.number,
        },
        createdAt: timestamp(),
        modifiedAt: timestamp(),
      };

      return collection
        .add(newTransactionData)
        .then((doc) => {
          collection.doc(id).update({
            status: TRANSACTION_STATUSES.ROLLED.value,
            prolongedBy: {
              number,
              id: doc.id,
            },
            modifiedAt: timestamp(),
          });
          log({
            action: "roll",
            item: {
              collection: "transactions",
              id: String(transaction.number),
            },
            transactionId: transaction.id,
            company: transaction.company,
            url: `/transactions/${doc.id}`,
            oldData: JSON.stringify(transaction),
            newData: JSON.stringify(newTransactionData),
          });

          return { id: doc.id, number };
        })
        .catch((e) => {
          console.error("useTransactions", e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const hedgeAdd = useCallback(
    async (newHedge: Hedge) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      const isValid = await validateHedge(newHedge, {
        endDate: transaction.end,
      });
      if (!isValid) return false;

      const hedges = transaction.hedges || [];
      newHedge.id = _generateInnerId(hedges);
      newHedge.createdAt = timestamp();
      newHedge.modifiedAt = timestamp();
      newHedge.leftQuantity = newHedge.quantity;

      hedges.push(newHedge);

      return collection
        .doc(id)
        .update({
          hedges: fixHedgesTimestamps(hedges),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "create",
            item: {
              collection: "hedges",
              id: newHedge.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            newData: JSON.stringify(newHedge),
          });
          return true;
        })
        .catch((e) => {
          console.error("hedgeAdd", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const hedgeRoll = useCallback(
    async (
      newHedge: Hedge,
      rolledHedgesIds: string,
      rolledHedgesData?: Array<{
        hedgeId: string;
        quantity: number;
      }>,
      passedTransaction?: Transaction
    ) => {
      const collection = db.collection(COLLECTION);
      const rollTransaction = passedTransaction || transaction;
      if (!rollTransaction) return false;

      const isValid = await validateHedge(newHedge, {
        endDate: rollTransaction.end,
      });

      if (!isValid) return false;

      const oldHedges = rollTransaction.hedges;
      let hedges = rollTransaction.hedges || [];
      const rolledHedgesIdsArray = rolledHedgesIds.split(",");

      const maxHedgeId = Math.max(...hedges.map((hedge) => Number(hedge.id?.split("-")[1])), 0);

      newHedge.id = `${rollTransaction.number}-${maxHedgeId + 1}`;
      newHedge.leftQuantity = newHedge.quantity;
      newHedge.createdFromId = rolledHedgesIds;

      if (rolledHedgesData) {
        newHedge.createdFromData = rolledHedgesData;
      }

      // update heges values, set rolledQuantity and leftQuantity
      hedges = hedges.map((hedge) => {
        if (rolledHedgesIdsArray.includes(hedge.id)) {
          if (rolledHedgesIdsArray.length === 1) {
            hedge.rolledQuantity = Number(hedge.rolledQuantity || 0) + Number(newHedge.quantity);
          } else {
            hedge.rolledQuantity = hedge.leftQuantity;
          }
        }
        return hedge;
      });

      hedges.push(newHedge);

      return collection
        .doc(rollTransaction.id)
        .update({
          hedges,
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "roll",
            item: {
              collection: "hedges",
              id: rolledHedgesIds,
            },
            transactionId: rollTransaction.id,
            company: rollTransaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldHedges),
            newData: JSON.stringify(hedges),
          });
          return true;
        })
        .catch((e) => {
          console.error("hedgeRoll", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const massHedgeRoll = useCallback(
    async (
      newHedge: Hedge,
      rolledHedgesIds: string,
      rolledHedgesData?: Array<{
        hedgeId: string;
        quantity: number;
      }>,
      passedTransaction?: Transaction
    ) => {
      const collection = db.collection(COLLECTION);
      const rollTransaction = passedTransaction || transaction;
      if (!rollTransaction) return false;

      const isValid = await validateHedge(newHedge, {
        endDate: rollTransaction.end,
      });

      if (!isValid) return false;

      const oldHedges = rollTransaction.hedges;
      let hedges = rollTransaction.hedges || [];
      const rolledHedgesIdsArray = rolledHedgesIds.split(",");

      newHedge.id = `${rollTransaction.number}-${hedges.length + 1}`;
      newHedge.leftQuantity = newHedge.quantity;
      newHedge.createdFromId = rolledHedgesIds;

      if (rolledHedgesData) {
        newHedge.createdFromData = rolledHedgesData;
      }

      // update heges values, set rolledQuantity and leftQuantity
      hedges = hedges.map((hedge) => {
        if (rolledHedgesIdsArray.includes(hedge.id)) {
          if (rolledHedgesIdsArray.length === 1) {
            hedge.rolledQuantity = Number(hedge.rolledQuantity || 0) + Number(newHedge.quantity);
          } else {
            hedge.rolledQuantity = hedge.leftQuantity;
          }
        }
        return hedge;
      });

      // this fixes setting hedge ID issue
      rollTransaction.hedges?.push(newHedge);

      hedges.push(newHedge);

      return collection
        .doc(rollTransaction.id)
        .update({
          hedges: fixHedgesTimestamps(hedges),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "roll",
            item: {
              collection: "hedges",
              id: rolledHedgesIds,
            },
            transactionId: rollTransaction.id,
            company: rollTransaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldHedges),
            newData: JSON.stringify(hedges),
          });
          return true;
        })
        .catch((e) => {
          console.error("hedgeRoll", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const hedgeUpdate = useCallback(
    async (newHedge: Hedge) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      const isValid = await validateHedge(newHedge, {
        endDate: transaction.end,
      });

      if (!isValid) return false;

      let oldHedge: Hedge;

      newHedge.modifiedAt = timestamp();
      const hedges = transaction.hedges || [];
      const editedHedgeIndex = hedges.findIndex((hedge) => hedge.id === newHedge.id);
      if (editedHedgeIndex !== -1) {
        oldHedge = hedges[editedHedgeIndex];
        const newLeftQuantity = Number(oldHedge.leftQuantity) + (Number(newHedge.quantity) - Number(oldHedge.quantity));
        if (newLeftQuantity < 0) {
          throw Error("Hedge could not be saved - wrong quantity entered.");
        }
        newHedge.leftQuantity = newLeftQuantity;
        hedges[editedHedgeIndex] = newHedge;
      } else {
        hedges.push(newHedge);
      }

      return collection
        .doc(id)
        .update({
          hedges: fixHedgesTimestamps(hedges),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "hedges",
              id: newHedge.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldHedge),
            newData: JSON.stringify(newHedge),
          });
          return true;
        })
        .catch((e) => {
          console.error("hedgeUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const hedgeCancel = useCallback(
    async (hedgeId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      const hedges = transaction.hedges || [];
      const oldHedge = hedges.find((hedge) => hedge.id === hedgeId);

      return collection
        .doc(id)
        .update({
          hedges: fixHedgesTimestamps(hedges.filter((hedge) => hedge.id !== hedgeId)),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "cancel",
            item: {
              collection: "hedges",
              id: hedgeId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldHedge),
          });
          return true;
        })
        .catch((e) => {
          console.error("hedgeCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const settlementAdd = useCallback(
    async (newSettlement: Settlement): Promise<false | { transaction: string; settlement: string }> => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateSettlement(newSettlement);
      if (!transaction || !isValid) return false;
      const newTransaction = _cloneDeep(transaction);

      // update leftQuantity of the used hedges
      const transactionHedges = newTransaction.hedges || [];
      newSettlement.hedges.forEach((settlementHedge) => {
        const matchingHedgeIndex = transactionHedges.findIndex(
          (transactionHedge) => transactionHedge.id === settlementHedge.hedgeId
        );
        if (matchingHedgeIndex >= 0) {
          transactionHedges[matchingHedgeIndex].leftQuantity =
            Number(transactionHedges[matchingHedgeIndex].leftQuantity.toFixed(2)) -
            Number(Number(settlementHedge.quantity).toFixed(2));
        }
      });

      const settlements = newTransaction.settlements || [];
      newSettlement.id = _generateInnerId(settlements);
      newSettlement.createdAt = timestamp();
      newSettlement.modifiedAt = timestamp();
      settlements.push(newSettlement);

      // get updated status of transaction
      newTransaction.settlements = settlements;
      newTransaction.hedges = transactionHedges;
      const newStatus = determineTransactionStatus(newTransaction);

      return collection
        .doc(id)
        .update({
          settlements: fixSettlementsTimestamps(settlements),
          status: newStatus,
          hedges: fixHedgesTimestamps(transactionHedges),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "create",
            item: {
              collection: "settlements",
              id: newSettlement.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            newData: JSON.stringify(newSettlement),
          });
          return {
            settlement: String(newSettlement.id),
            transaction: String(id),
          };
        })
        .catch((e) => {
          console.error("settlementAdd", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const settlementCancel = useCallback(
    async (settlementId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      let oldSettlement: Settlement;
      const newTransaction = _cloneDeep(transaction);

      const settlements = newTransaction.settlements || [];
      const editedSettlementIndex = settlements.findIndex((settlement) => settlement.id === settlementId);
      if (editedSettlementIndex !== -1) {
        oldSettlement = settlements[editedSettlementIndex];
        settlements.splice(editedSettlementIndex, 1);
      }

      // get updated status of transaction
      newTransaction.settlements = settlements;
      const newStatus = determineTransactionStatus(newTransaction);

      return collection
        .doc(id)
        .update({
          settlements,
          status: newStatus,
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "cancel",
            item: {
              collection: "settlements",
              id: settlementId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldSettlement),
          });
          return true;
        })
        .catch((e) => {
          console.error("settlementCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const settlementUpdate = useCallback(
    async (
      newSettlement: Settlement,
      oldSettlement: Settlement
    ): Promise<false | { transaction: string; settlement: string }> => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateSettlement(newSettlement);
      if (!transaction || !isValid) return false;

      const updatedHedges = transaction.hedges || [];

      // reset leftQuantity of the old used hedges
      oldSettlement.hedges.forEach((oldSettlementHedge) => {
        const matchingHedgeIndex = updatedHedges.findIndex(
          (transactionHedge) => transactionHedge.id === oldSettlementHedge.hedgeId
        );
        if (matchingHedgeIndex >= 0) {
          updatedHedges[matchingHedgeIndex].leftQuantity =
            Number(updatedHedges[matchingHedgeIndex].leftQuantity) + Number(oldSettlementHedge.quantity);
        }
      });

      // update leftQuantity of the used hedges
      newSettlement.hedges.forEach((newSettlementHedge) => {
        const matchingHedgeIndex = updatedHedges.findIndex(
          (transactionHedge) => transactionHedge.id === newSettlementHedge.hedgeId
        );
        if (matchingHedgeIndex >= 0) {
          updatedHedges[matchingHedgeIndex].leftQuantity =
            Number(updatedHedges[matchingHedgeIndex].leftQuantity.toFixed(2)) -
            Number(Number(newSettlementHedge.quantity).toFixed(2));
        }
      });
      newSettlement.modifiedAt = timestamp();
      const settlements = transaction.settlements || [];
      const editedSettlementIndex = settlements.findIndex((settlement) => settlement.id === newSettlement.id);
      if (editedSettlementIndex !== -1) {
        settlements[editedSettlementIndex] = newSettlement;
      } else {
        settlements.push(newSettlement);
      }

      // get updated status of transaction
      const newTransaction = _cloneDeep(transaction);
      const newStatus = determineTransactionStatus(newTransaction);

      return collection
        .doc(id)
        .update({
          settlements,
          status: newStatus,
          hedges: fixHedgesTimestamps(updatedHedges),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "settlements",
              id: oldSettlement.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldSettlement),
            newData: JSON.stringify(newSettlement),
          });
          return {
            settlement: String(newSettlement.id),
            transaction: String(id),
          };
        })
        .catch((e) => {
          console.error("settlementUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const settlementInTransactionUpdate = useCallback(
    async (transactionId: string, settlementId: string, update: object) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      let settlements;

      let oldSettlement: Settlement;
      let newSettlement: Settlement;
      let transaction: Transaction;

      await collection
        .doc(transactionId)
        .get()
        .then((snap: firestore.DocumentData) => {
          transaction = snap.data() as Transaction;
          if (transaction) {
            settlements = transaction.settlements || [];
            const editedSettlementIndex = settlements.findIndex((settlement) => settlement.id === settlementId);
            if (editedSettlementIndex !== -1) {
              oldSettlement = settlements[editedSettlementIndex];
              newSettlement = {
                ...settlements[editedSettlementIndex],
                ...update,
              };
              settlements[editedSettlementIndex] = newSettlement;
            }
          }
        });

      return collection
        .doc(transactionId)
        .update({
          settlements,
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "settlements",
              id: settlementId,
            },
            transactionId: transactionId,
            company: transaction.company,
            url: `/transactions/${transactionId}`,
            oldData: JSON.stringify(oldSettlement),
            newData: JSON.stringify(newSettlement),
          });
        })
        .catch((e) => {
          console.error("settlementStatusUpdate", e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const marginAdd = useCallback(
    async (newMargin: Margin) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(newMargin);
      if (!transaction || !isValid) return false;

      const margins = transaction.margins || [];
      newMargin.id = _generateInnerId(margins);
      newMargin.createdAt = timestamp();
      newMargin.modifiedAt = timestamp();
      newMargin.left = newMargin.from;

      margins.push(newMargin);

      await collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "create",
            item: {
              collection: "margins",
              id: newMargin.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            newData: JSON.stringify(newMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginAdd", e);
          return false;
        });
      return newMargin;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const marginCancel = useCallback(
    async (marginId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      let oldMargin: Margin;

      const margins = transaction.margins || [];
      const editedMarginIndex = margins.findIndex((margin) => margin.id === marginId);
      if (editedMarginIndex !== -1) {
        oldMargin = margins[editedMarginIndex];

        if (
          (oldMargin.operations && oldMargin.operations?.length > 0) ||
          Number(oldMargin.left?.quantity) !== Number(oldMargin.from.quantity)
        ) {
          throw Error("This margin cannot be canceled, because it has been used.");
        }

        const maxExistingId = Math.max(...margins.map((item) => Number(item.id?.split("-")[1])), 0);
        if (marginId !== `${transaction.number}-${maxExistingId}`) {
          throw Error("Only most recent margin can be canceled.");
        }

        margins.splice(editedMarginIndex, 1);
      }

      return collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "cancel",
            item: {
              collection: "margins",
              id: marginId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const marginCancelUnsafe = useCallback(
    async (marginId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      let oldMargin: Margin;

      const margins = transaction.margins || [];
      const editedMarginIndex = margins.findIndex((margin) => margin.id === marginId);
      if (editedMarginIndex !== -1) {
        oldMargin = margins[editedMarginIndex];
        margins.splice(editedMarginIndex, 1);
      }

      return collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "cancel",
            item: {
              collection: "margins",
              id: marginId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const marginUpdate = useCallback(
    async (margin: Margin, isWithdraw?: boolean) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(margin);
      if (!transaction || !isValid) return false;

      let oldMargin: Margin;
      const newMargin = _cloneDeep(margin);

      newMargin.modifiedAt = timestamp();

      const margins = transaction.margins || [];
      const editedMarginIndex = margins.findIndex((margin) => margin.id === newMargin.id);
      if (editedMarginIndex !== -1) {
        oldMargin = margins[editedMarginIndex];

        if (!isWithdraw) {
          // if not withdraw action, check if margin can be edited
          if (
            (oldMargin.operations && oldMargin.operations?.length > 0) ||
            Number(oldMargin.left?.quantity) !== Number(oldMargin.from.quantity)
          ) {
            throw Error("This margin cannot be edited, because it has been used.");
          }

          const maxExistingId = Math.max(...margins.map((item) => Number(item.id?.split("-")[1])), 0);
          if (newMargin.id !== `${transaction.number}-${maxExistingId}`) {
            throw Error("Only most recent margin can be edited.");
          }
        }

        margins[editedMarginIndex] = newMargin;
      } else {
        margins.push(newMargin);
      }

      return collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "margins",
              id: newMargin.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldMargin),
            newData: JSON.stringify(newMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const marginUpdateUnsafe = useCallback(
    async (margin: Margin) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(margin);
      if (!transaction || !isValid) return false;

      let oldMargin: Margin;
      const newMargin = _cloneDeep(margin);

      newMargin.modifiedAt = timestamp();

      const margins = transaction.margins || [];
      const editedMarginIndex = margins.findIndex((margin) => margin.id === newMargin.id);
      if (editedMarginIndex !== -1) {
        oldMargin = margins[editedMarginIndex];
        margins[editedMarginIndex] = newMargin;
      } else {
        margins.push(newMargin);
      }

      return collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "margins",
              id: newMargin.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldMargin),
            newData: JSON.stringify(newMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const moveLocalMarginToGlobal = useCallback(
    async (margin: Margin) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(margin);
      if (!transaction || !isValid) return false;

      let oldMargin: Margin;
      const newMargin = _cloneDeep(margin);

      newMargin.modifiedAt = timestamp();

      const margins = transaction.margins || [];
      const editedMarginIndex = margins.findIndex((margin) => margin.id === newMargin.id);
      if (editedMarginIndex !== -1) {
        oldMargin = margins[editedMarginIndex];

        // if not withdraw action, check if margin can be edited
        if (
          (oldMargin.operations && oldMargin.operations?.length > 0) ||
          Number(oldMargin.left?.quantity) !== Number(oldMargin.from.quantity)
        ) {
          throw Error("This margin cannot be edited, because it has been used.");
        }
        const maxExistingId = Math.max(...margins.map((item) => Number(item.id?.split("-")[1])), 0);
        if (newMargin.id !== `${transaction.number}-${maxExistingId}`) {
          throw Error("Only most recent margin can be edited.");
        }

        margins[editedMarginIndex] = newMargin;
      } else {
        margins.push(newMargin);
      }

      return collection
        .doc(id)
        .update({
          margins: fixMarginsTimestamps(margins),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "margins",
              id: newMargin.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldMargin),
            newData: JSON.stringify(newMargin),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const marginCallAdd = useCallback(
    async (newMarginCall: Partial<MarginCall>): Promise<{ marginCall: string; transaction: string }> => {
      const collection = db.collection(COLLECTION);

      if (!transaction) throw Error("Transaction not found");

      const marginCalls = transaction.marginCalls || [];
      newMarginCall.id = _generateInnerId(marginCalls);
      newMarginCall.createdAt = timestamp();
      newMarginCall.modifiedAt = timestamp();

      const marginCall = MarginCallSchema.parse(newMarginCall);

      marginCalls.push(marginCall as MarginCall);

      await collection.doc(transaction.id).update({
        marginCalls: fixMarginCallsTimestamps(marginCalls),
        modifiedAt: timestamp(),
      });
      await log({
        action: "create",
        item: {
          collection: "marginCalls",
          id: `${newMarginCall.id}`,
        },
        transactionId: transaction.id,
        company: transaction.company,
        url: `/transactions/${transaction.id}`,
        newData: JSON.stringify(newMarginCall),
      });

      return {
        marginCall: newMarginCall.id,
        transaction: transaction.id,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const globalMarginCallAdd = async (
    newMarginCall: MarginCall,
    innerTransaction: Transaction
  ): Promise<false | { transaction: string; marginCall: string }> => {
    const collection = db.collection(COLLECTION);
    const selectedTransaction = innerTransaction || transaction;
    const isValid = await validateMarginCall(newMarginCall);
    if (!selectedTransaction || !isValid) return false;

    const marginCalls = selectedTransaction.marginCalls || [];
    newMarginCall.createdAt = timestamp();
    newMarginCall.modifiedAt = timestamp();

    marginCalls.push(newMarginCall);

    return collection
      .doc(selectedTransaction.id)
      .update({
        marginCalls: fixMarginCallsTimestamps(marginCalls),
        modifiedAt: timestamp(),
      })
      .then(() => {
        log({
          action: "create",
          item: {
            collection: "marginCalls",
            id: newMarginCall.id,
          },
          transactionId: selectedTransaction.id,
          company: selectedTransaction.company,
          url: `/transactions/${selectedTransaction.id}`,
          newData: JSON.stringify(newMarginCall),
        });
        return {
          marginCall: newMarginCall.id,
          transaction: selectedTransaction.id,
        };
      })
      .catch((e) => {
        console.error("marginCallAdd", e);
        return false;
      });
  };

  const marginCallCancel = useCallback(
    async (marginCallId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      let oldMarginCall: MarginCall;

      const marginCalls = transaction.marginCalls || [];
      const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
      if (editedMarginCallIndex !== -1) {
        oldMarginCall = marginCalls[editedMarginCallIndex];
        marginCalls.splice(editedMarginCallIndex, 1);
      }

      return collection
        .doc(id)
        .update({
          marginCalls: fixMarginCallsTimestamps(marginCalls),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "cancel",
            item: {
              collection: "marginCalls",
              id: marginCallId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldMarginCall),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginCallCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const globalMarginCallCancel = useCallback(
    async (marginCallId: string, transactionId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transactionId) return false;

      const innerTransaction = (await collection
        .doc(transactionId)
        .get()
        .then((snap: firestore.DocumentData) => snap.data())) as Transaction;

      if (innerTransaction) {
        let oldMarginCall: MarginCall;

        const marginCalls = innerTransaction.marginCalls || [];

        const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === marginCallId);

        if (editedMarginCallIndex !== -1) {
          oldMarginCall = marginCalls[editedMarginCallIndex];
          marginCalls.splice(editedMarginCallIndex, 1);
        }

        return collection
          .doc(transactionId)
          .update({
            marginCalls: fixMarginCallsTimestamps(marginCalls),
            modifiedAt: timestamp(),
          })
          .then(() => {
            try {
              log({
                action: "cancel",
                item: {
                  collection: "marginCalls",
                  id: marginCallId,
                },
                transactionId: transactionId,
                company: innerTransaction.company,
                oldData: JSON.stringify(oldMarginCall),
              }).then(() => true);
              // eslint-disable-next-line no-empty
            } catch (err) {}
          })
          .catch((e) => {
            console.error("marginCallCancel", e);
            return false;
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const marginCallClose = useCallback(
    async (marginCallId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transaction) return false;

      let oldMarginCall: MarginCall;

      const marginCalls = transaction.marginCalls || [];
      const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
      if (editedMarginCallIndex !== -1) {
        oldMarginCall = marginCalls[editedMarginCallIndex];
        marginCalls[editedMarginCallIndex].isClosed = "yes";
      }

      return collection
        .doc(id)
        .update({
          marginCalls: fixMarginCallsTimestamps(marginCalls),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "close",
            item: {
              collection: "marginCalls",
              id: marginCallId,
            },
            transactionId: id,
            company: transaction.company,
            oldData: JSON.stringify(oldMarginCall),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginCallClose", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const globalMarginCallClose = useCallback(
    async (marginCallId: string, transactionId: string) => {
      const collection = db.collection(COLLECTION);

      if (!transactionId) return false;
      const innerTransaction = (await collection
        .doc(transactionId)
        .get()
        .then((snap: firestore.DocumentData) => snap.data())) as Transaction;

      if (innerTransaction) {
        let oldMarginCall: MarginCall;

        const marginCalls = innerTransaction.marginCalls || [];
        const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
        if (editedMarginCallIndex !== -1) {
          oldMarginCall = marginCalls[editedMarginCallIndex];
          marginCalls[editedMarginCallIndex].isClosed = "yes";
        }

        return collection
          .doc(transactionId)
          .update({
            marginCalls: fixMarginCallsTimestamps(marginCalls),
            modifiedAt: timestamp(),
          })
          .then(() => {
            try {
              log({
                action: "close",
                item: {
                  collection: "marginCalls",
                  id: marginCallId,
                },
                transactionId: transactionId,
                company: innerTransaction.company,
                oldData: JSON.stringify(oldMarginCall),
              });
              return true;
              // eslint-disable-next-line no-empty
            } catch {}
          })
          .catch((e) => {
            console.error("marginCallClose", e);
            return false;
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const marginCallUpdate = useCallback(
    async (newMarginCall: MarginCall) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateMarginCall(newMarginCall);
      if (!transaction || !isValid) return false;

      let oldMarginCall: MarginCall;

      newMarginCall.modifiedAt = timestamp();
      const marginCalls = transaction.marginCalls || [];
      const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === newMarginCall.id);
      if (editedMarginCallIndex !== -1) {
        oldMarginCall = marginCalls[editedMarginCallIndex];
        marginCalls[editedMarginCallIndex] = newMarginCall;
      } else {
        marginCalls.push(newMarginCall);
      }

      return collection
        .doc(id)
        .update({
          marginCalls: fixMarginCallsTimestamps(marginCalls),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "marginCalls",
              id: newMarginCall.id,
            },
            transactionId: id,
            company: transaction.company,
            url: `/transactions/${id}`,
            oldData: JSON.stringify(oldMarginCall),
            newData: JSON.stringify(newMarginCall),
          });
          return true;
        })
        .catch((e) => {
          console.error("marginCallUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transaction]
  );

  const globalMarginCallUpdate = useCallback(
    async (newMarginCall: MarginCall, transactionId: string) => {
      const collection = db.collection(COLLECTION);
      const isValid = await validateMarginCall(newMarginCall);
      let oldMarginCall: MarginCall;

      if (!isValid) return false;

      const innerTransaction = (await collection
        .doc(transactionId)
        .get()
        .then((snap: firestore.DocumentData) => snap.data())) as Transaction;

      if (innerTransaction) {
        newMarginCall.modifiedAt = timestamp();
        const marginCalls = innerTransaction.marginCalls || [];
        const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === newMarginCall.id);

        if (editedMarginCallIndex !== -1) {
          oldMarginCall = marginCalls[editedMarginCallIndex];
          marginCalls[editedMarginCallIndex] = newMarginCall;
        } else {
          marginCalls.push(newMarginCall);
        }

        return collection
          .doc(transactionId)
          .update({
            marginCalls: fixMarginCallsTimestamps(marginCalls),
            modifiedAt: timestamp(),
          })
          .then(() => {
            log({
              action: "edit",
              item: {
                collection: "marginCalls",
                id: newMarginCall.id,
              },
              transactionId: transactionId,
              company: innerTransaction.company,
              url: `/transactions/${transactionId}`,
              oldData: JSON.stringify(oldMarginCall),
              newData: JSON.stringify(newMarginCall),
            });
            return true;
          })
          .catch((e) => {
            console.error("marginCallUpdate", e);
            return false;
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const marginCallInTransactionUpdate = useCallback(
    async (transactionId: string, marginCallId: string, update: object) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      let marginCalls;

      let oldMarginCall: MarginCall;
      let newMarginCall: MarginCall;
      let transaction: Transaction;

      await collection
        .doc(transactionId)
        .get()
        .then((snap: firestore.DocumentData) => {
          transaction = snap.data() as Transaction;
          if (transaction) {
            marginCalls = transaction.marginCalls || [];
            const editedMarginCallIndex = marginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
            if (editedMarginCallIndex !== -1) {
              oldMarginCall = marginCalls[editedMarginCallIndex];
              newMarginCall = {
                ...marginCalls[editedMarginCallIndex],
                ...update,
              };
              marginCalls[editedMarginCallIndex] = newMarginCall;
            }
          }
        });

      return collection
        .doc(transactionId)
        .update({
          marginCalls: fixMarginCallsTimestamps(marginCalls),
          modifiedAt: timestamp(),
        })
        .then(() => {
          log({
            action: "edit",
            item: {
              collection: "marginCalls",
              id: marginCallId,
            },
            transactionId: transactionId,
            company: transaction.company,
            url: `/transactions/${transactionId}`,
            oldData: JSON.stringify(oldMarginCall),
            newData: JSON.stringify(newMarginCall),
          });
        })
        .catch((e) => {
          console.error("marginCallUpdate", e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!skipFetching) {
      fetch(id);
    }
  }, [fetch, id, skipFetching]);

  return {
    transaction,
    setTransaction,
    fetch,
    save,
    update,
    remove,
    roll,
    convert,
    loading,
    errors,
    hedgeErrors,
    marginErrors,
    marginCallErrors,
    settlementErrors,
    validate,
    statusUpdate,
    riskIgnoreUpdate,
    hedgeAdd,
    hedgeUpdate,
    hedgeCancel,
    hedgeRoll,
    massHedgeRoll,
    commentUpdate,
    dashboardCommentsUpdate,
    settlementAdd,
    settlementCancel,
    settlementUpdate,
    settlementInTransactionUpdate,
    marginAdd,
    marginUpdate,
    marginUpdateUnsafe,
    moveLocalMarginToGlobal,
    marginCancel,
    marginCancelUnsafe,
    marginCallAdd,
    marginCallCancel,
    globalMarginCallCancel,
    marginCallClose,
    globalMarginCallClose,
    marginCallUpdate,
    globalMarginCallUpdate,
    marginCallInTransactionUpdate,
    globalMarginCallAdd,
  };
};
