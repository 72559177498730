import * as yup from "yup";
import { validatePolish } from "validate-polish";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { getTimestamp } from "../helpers/date";
import { UserRole } from "../interfaces/data";

dayjs.extend(isSameOrAfter);

export const userRoleValidation = yup.string().test("isUserRoleValid", "role", (value) => {
  return Object.values(UserRole).includes(value as UserRole);
});

export const bankAccountNumberValidation = yup.string().test("isBankAccountNumberValid", "number", (value) => {
  const strippedValue = (value as string).replaceAll(" ", "");
  if (strippedValue.startsWith("PL") && strippedValue.length === 28) {
    return true;
  }
  return strippedValue.length === 26;
});

export const nipValidation = yup.string().test("isNIPValid", "nip", (value) => {
  if (value) {
    const nip = parseInt(value, 10);
    return !Number.isNaN(nip) && String(nip).length === 10;
  }
  return true;
});

export const regonValidation = yup.string().test("isREGONValid", "regon", (value) => {
  if (value) {
    const regon = parseInt(value, 10);

    return !Number.isNaN(regon) && (value.trim().length === 9 || value.trim().length === 14);
  }
  return true;
});

export const krsValidation = yup.string().nullable();

export const peselValidation = yup.string().test("isPESELValid", "pesel", (value) => {
  if (value) {
    return validatePolish.pesel(value);
  }
  return true;
});

export const bankNumberValidation = yup.string();

export const isDateSameOrAfter = (ref: any) =>
  yup.mixed().test({
    name: "isDateSameOrAfter",
    message: "Invalid date.",
    test: function (value) {
      const refValue = this.resolve(ref);

      if (!refValue) {
        return true;
      }

      return dayjs(getTimestamp(value)).isSameOrAfter(dayjs(getTimestamp(refValue)), "day");
    },
  });

export const isDateBeforeEndDate = () =>
  yup.mixed().test({
    name: "isDateBeforeEndDate",
    message: "Invalid date.",
    test: function test(date) {
      const yupContext = this.options.context as any;
      if (yupContext !== undefined) {
        const endDate = yupContext.endDate;
        return !dayjs(getTimestamp(date)).isAfter(dayjs(getTimestamp(endDate)), "day");
      }
      return false;
    },
  });

export const isEndDateValid = (ref: any) => {
  return yup.mixed().test({
    name: "isEndDateValid",
    message: "Invalid date.",
    test: function (date) {
      const refValue = this.resolve(ref);
      if (!refValue) return true;
      return dayjs(getTimestamp(date)).isSameOrAfter(dayjs(getTimestamp(refValue)), "day");
    },
  });
};

export const isPointNumeric = () =>
  yup.mixed().test("isPointNumeric", "points", (value) => {
    return value != null && value !== "";
  });

export const isQuantityMoreThanZero = () =>
  yup.mixed().test("isQuantityMoreThanZero", "quantity", (value) => {
    if (value != null) {
      return value > 0;
    }
    return false;
  });
