export const actions = [
  "create",
  "edit",
  "delete",
  "convert",
  "roll",
  "cancel",
  "close",
  "open",
  "approve",
  "deactivate",
  "activate",
];
