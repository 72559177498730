import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { Text } from "@chakra-ui/react";
import _uniqBy from "lodash.uniqby";
import { Company, Currency, MarginCall, Transaction } from "interfaces/data";
import { StyledButton, StyledContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { IdCell } from "views/Transaction/Transaction/MarginCalls";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  StyledAddbutton,
  StyledButtonContainerWithLink,
  StyledButtonContainerWrapper,
} from "./Transactions/Transactions";
import { determineTransactionCurrency } from "helpers/transaction";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { Button } from "components/Button/Button";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { StyledTableContainer } from "./components/margin.styled";
import { CloseCell } from "./components/CloseCell";
import { TransactionIdsCell } from "./components/TransactionIdsCell";
import { RemainingCell } from "./components/RemainingCell";
import { StatusCell } from "./components/StatusCell";
import { ActionsCell } from "./components/ActionsCell";
import { DateCell } from "helpers/tableCells";
import { AddIcon } from "theme/icons/AddIcon";

export interface MarginCallExtended extends MarginCall {
  currency: Currency;
  transaction: Transaction;
  baseQty: number;
}

interface MarginCallsProps {
  marginCalls?: Array<MarginCallExtended>;
  isDisabled?: boolean;
  company?: Company;
}

export const MarginCalls: React.FC<MarginCallsProps> = ({ marginCalls = [], isDisabled = false, company }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isExpanded, setExpanded] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const numberOfCollapse = 4;

  const sortedMarginCalls = useMemo(() => {
    const originalMarginCalls = marginCalls.filter((marginCall) => !marginCall.createdFromId);
    const additionalMarginCalls = marginCalls.filter((marginCall) => marginCall.createdFromId);
    additionalMarginCalls.forEach((additionalMarginCall) => {
      const originalIndex = originalMarginCalls.findIndex(
        (originalMarginCall) => additionalMarginCall.createdFromId === originalMarginCall.id
      );
      if (originalIndex !== -1) {
        originalMarginCalls.splice(originalIndex + 1, 0, additionalMarginCall);
      }
    });

    return _uniqBy(
      originalMarginCalls.filter((marginCall) => marginCall.isClosed === (showClosed ? "yes" : "no")),
      "id"
    ).sort(
      (prevElement, nextElement) =>
        getMilisecondsFromTimestamp(nextElement.callDate) - getMilisecondsFromTimestamp(prevElement.callDate)
    );
  }, [marginCalls, showClosed]);

  const visibleMarginCalls = useMemo(() => {
    if (isExpanded) {
      return sortedMarginCalls;
    }
    return sortedMarginCalls.slice(0, numberOfCollapse);
  }, [sortedMarginCalls, isExpanded]);

  const handleMarginCallAdd = useCallback(() => {
    history.push(`/companies/${id}/add-margin-call`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("marginCall:Call date"),
        accessor: "callDate",
        Cell: DateCell,
      },
      {
        Header: t("marginCall:Call rate"),
        accessor: "callRate",
      },
      {
        Header: t("Close"),
        accessor: "close",
        Cell: CloseCell,
      },
      {
        Header: t("Transactions IDs"),
        accessor: "globalTransactionsIds",
        Cell: TransactionIdsCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("marginCall:Quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" fontWeight="bold" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.value)}
          </Text>
        ),
      },
      {
        Header: t("marginCall:Currency"),
        accessor: "transaction",
        Cell: (arg: any) => <Text fontWeight="bold">{determineTransactionCurrency(arg.value)}</Text>,
      },
      {
        Header: t("marginCall:Type"),
        accessor: "transaction.dealType",
      },
      {
        Header: t("transaction:Client rate"),
        accessor: "transaction.clientRate",
        Cell: (arg: any) => <Text as="span">{formatRate(arg.value)}</Text>,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("marginCall:Base Qty")}
          </Text>
        ),
        accessor: "baseQty",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.row.original.isGMC === "yes" ? arg.row.original.transQuantity : arg.value)}{" "}
            {arg.row.original.transaction.from.currency}
          </Text>
        ),
      },

      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Remaining Qty")}
          </Text>
        ),
        accessor: "remaining",
        Cell: RemainingCell,
      },
      {
        Header: t("marginCall:Status"),
        accessor: "status",
        Cell: StatusCell,
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ActionsCell,
      },
    ],
    [t]
  );

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("transaction:Margin Calls")}</StyledSectionName>

        <StyledButtonContainerWithLink>
          <StyledButtonContainerWrapper>
            <Button
              borderRadius="12px"
              design={!showClosed ? "primary" : "ghost"}
              height="32px"
              mr="4px"
              onClick={() => setShowClosed(false)}
              opacity={!showClosed ? 1 : 0.5}
            >
              Open
            </Button>
            <Button
              borderRadius="12px"
              design={showClosed ? "primary" : "ghost"}
              height="32px"
              onClick={() => setShowClosed(true)}
              opacity={showClosed ? 1 : 0.5}
            >
              Closed
            </Button>
          </StyledButtonContainerWrapper>
        </StyledButtonContainerWithLink>
        <StyledTableControls>
          <RestrictAccess area={ADD_MARGIN_CALLS}>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated)} onClick={handleMarginCallAdd}>
              <AddIcon mr="10px" />
              New margin call
            </StyledAddbutton>
          </RestrictAccess>
        </StyledTableControls>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={visibleMarginCalls}
          getRowProps={(rowInfo) => {
            const row = rowInfo.original;
            return {
              url: row.isGMC === "yes" ? null : `/transactions/${row.transaction.id}`,
              fromPath: window.location.pathname,
              className: [row.isClosed === "yes" ? `closed-row` : "", row.isGMC === "yes" ? "global-row" : ""].join(""),
            };
          }}
          paginated={false}
          selectableRows={isDisabled}
        />
      </StyledTableContainer>
      {sortedMarginCalls.length > numberOfCollapse && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledContainer>
  );
};
