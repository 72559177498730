import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "hooks/useTransaction";
import { useToast } from "hooks/useToast";
import { MarginCallFormModal } from "./MarginCallFormModal";
import { useMail } from "hooks/useMail";
import { MARGIN_CALL_MAIL } from "helpers/mails";
import { ZodOptionalIDMarginCall } from "schemas/marginCall";
import { MarginCall } from "interfaces/data";

export function MarginCallEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId?: string;
  }>();
  const toast = useToast();
  const { transaction, marginCallUpdate, marginCallErrors } = useTransaction({
    id,
  });
  const [isSending, setIsSending] = useState(false);
  const sendCorrection = useMail();

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveAction = async (newMarginCall: ZodOptionalIDMarginCall) => {
    setIsSending(true);
    marginCallUpdate(newMarginCall as MarginCall)
      .then((isSaved) => {
        if (isSaved) {
          sendCorrection(MARGIN_CALL_MAIL, transaction!.id, {
            marginCall: newMarginCall.id,
            correction: "yes",
          });
          toast({
            type: "success",
            message: t("marginCall:Margin Call has been updated successfully"),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`marginCall:${error.message}`),
        });
      });
    handleClose();
    setIsSending(false);
  };

  const editedMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [marginCallId, transaction]);

  // Edit can be made only if the nothing has been paid yet
  useEffect(() => {
    if (transaction && editedMarginCall && editedMarginCall.margins && editedMarginCall.margins.length > 0) {
      handleClose();
      toast({
        type: "error",
        message: t("marginCall:This Margin Call cannot be edited anymore."),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, editedMarginCall]);

  if (!transaction || !editedMarginCall) return null;

  return (
    <MarginCallFormModal
      errors={marginCallErrors}
      initialMarginCall={editedMarginCall}
      initialTransaction={transaction}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t(`marginCall:Edit ${editedMarginCall.isGMC === "yes" ? "" : "Margin Call"} {{marginCallId}}`, {
        marginCallId: marginCallId,
      })}
      type="edit"
    />
  );
}
