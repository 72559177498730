import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { Table } from "../../../components/Table/Table";
import { Operation } from "../../../interfaces/data";
import {
  StyledButton,
  StyledButtonContainer,
  StyledCollapsableSmallContainer,
  StyledSectionHeader,
  StyledSectionName,
} from "./shared.styled";
import { CurrencyCell, DateCell } from "../../../helpers/tableCells";

interface OperationsProps {
  operations?: Array<Operation>;
}

export const Operations: React.FC<OperationsProps> = ({ operations = [] }) => {
  const { t } = useTranslation();
  const collapseSize = 10;
  const [isExpanded, setExpanded] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: t("transaction:Date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:From Quantity")}
          </Text>
        ),
        accessor: "from",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:To Quantity")}
          </Text>
        ),
        accessor: "to",
        Cell: CurrencyCell,
      },
      {
        Header: t("transaction:Operation type"),
        accessor: "status",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const visibleOperations = useMemo(() => {
    if (isExpanded) {
      return operations;
    }
    return operations.slice(0, collapseSize);
  }, [operations, isExpanded]);

  return (
    <StyledCollapsableSmallContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("transaction:Operations")}</StyledSectionName>
      </StyledSectionHeader>
      <Table columns={columns} data={visibleOperations} paginated={false} />
      {operations.length > collapseSize && (
        <StyledButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </StyledButtonContainer>
      )}
    </StyledCollapsableSmallContainer>
  );
};
