import { Input, InputGroup } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
import isPropValid from "@emotion/is-prop-valid";

export const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 10px;
  transition: 300ms ease all;
  z-index: 2;
  padding: 3px 5px;
  background-color: #fff;
`;

export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ topLabel?: boolean }>`
  background-color: white;
  &[aria-invalid="true"] {
    box-shadow: none !important;
  }

  & + ${StyledLabel} {
    ${(props) => props.topLabel && "top: -10px;"}
  }

  &:focus + ${StyledLabel}, &:valid + ${StyledLabel} {
    top: -10px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    box-shadow: 0 0 0 1000px #fff inset;
  }
`;

export const StyledGroup = styled(InputGroup)`
  position: relative;
  width: 100%;
  display: flex;
`;

export const StyledCopyButton = styled.button<{ rightAddon?: boolean }>`
  position: absolute;
  right: ${({ rightAddon }) => (rightAddon ? "60px" : "10px")};
  top: 7px;
`;
