import React from "react";
import styled from "@emotion/styled";

import { Avatar } from "@chakra-ui/react";
import chroma from "chroma-js";
import { BaseContact } from "interfaces/data";
import { Link } from "react-router-dom";
import { formatMobilePhone } from "helpers/formatText";

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledContainer = styled.div<{ roleOnBottom?: boolean }>`
  display: ${(props) => (props.roleOnBottom ? "block" : "flex")};
  background: white;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  padding: 20px;
`;

const StyledName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  margin-bottom: 5px;
`;

const StyledInfo = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const PhoneNumber = styled(StyledInfo)`
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

const StyledBadge = styled.div<{ roleOnBottom?: boolean }>`
  background: ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 16px;
  padding: 10px;
  margin-left: auto;
  align-self: flex-start;
  font-weight: 800;
  font-size: 11px;
  line-height: 110%;
  color: ${(props) => String(chroma(props.theme.colors["sk-dark"]).alpha(0.4))};
  ${(props) =>
    props.roleOnBottom &&
    `
    display: inline-block; 
    margin-top: 10px;
  `}
`;

interface ContactProps extends BaseContact {
  id: string;
  trackPreviousUrl?: boolean;
  hideAvatar?: boolean;
  roleOnBottom?: boolean;
}

export function Contact({
  id,
  firstName,
  lastName,
  email,
  phone,
  ip,
  type,
  hideAvatar = false,
  roleOnBottom = false,
  trackPreviousUrl = false,
}: ContactProps) {
  const name = `${firstName} ${lastName}`;
  return (
    <StyledLink
      to={{
        pathname: `/contacts/${id}`,
        state: {
          fromPath: trackPreviousUrl ? window.location.pathname : undefined,
        },
      }}
    >
      <StyledContainer roleOnBottom={roleOnBottom}>
        {!hideAvatar && <Avatar mr="10px" name={name} size="md" />}
        <div>
          <StyledName>{name}</StyledName>
          {email && <StyledInfo>{email}</StyledInfo>}
          {phone && <PhoneNumber>{formatMobilePhone(phone)}</PhoneNumber>}
          {ip && <StyledInfo>{ip}</StyledInfo>}
        </div>
        <StyledBadge roleOnBottom={roleOnBottom}>{type}</StyledBadge>
      </StyledContainer>
    </StyledLink>
  );
}
