import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ScoringIcon = createIcon({
  displayName: "ScoringIcon",
  path: (
    <g>
      <path
        d="M44.065,64.165c-3.44,2.096-4.511,6.573-2.394,10.011c2.132,3.463,6.627,4.548,10.068,2.451l2.26-2.135l26.459-25.289   l-34.377,14.13L44.065,64.165z"
        stroke="currentColor"
      />
      <g>
        <path
          d="M87.93,39.766c-2.08,0.12-3.81,1.48-4.48,3.36c-8.14-9.33-20.12-15.23-33.45-15.23c-24.49,0-44.41,19.92-44.41,44.41    c0,1.54-1.25,2.79-2.8,2.79c-1.54,0-2.79-1.25-2.79-2.79c0-27.57,22.43-50,50-50C65.16,22.306,78.76,29.086,87.93,39.766z"
          stroke="currentColor"
        />
        <path
          d="M100,72.306c0,1.54-1.25,2.79-2.8,2.79c-1.54,0-2.79-1.25-2.79-2.79c0-8.16-2.21-15.8-6.06-22.37    c2.1-0.03,3.88-1.33,4.62-3.17C97.43,54.246,100,62.976,100,72.306z"
          stroke="currentColor"
        />
      </g>
      <circle cx="88.248" cy="44.845" r="3.351" stroke="currentColor" />
    </g>
  ),
  viewBox: "0 0 100 125",
});
