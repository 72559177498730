import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const DangerIcon = createIcon({
  displayName: "DangerIcon",
  path: (
    <g fill="none">
      <path
        d="M40.6956 0H7.30438C3.27649 0 0 3.27649 0 7.30438V30.2609C0 34.2887 3.27649 37.5652 7.30438 37.5652H29.8289L39.9569 47.6953C40.1573 47.8936 40.4245 48 40.6957 48C40.8293 48 40.9649 47.9729 41.0944 47.9207C41.4846 47.7599 41.7392 47.378 41.7392 46.9565V37.4921C45.2746 36.9829 48.0001 33.9339 48.0001 30.2608V7.30438C48 3.27649 44.7234 0 40.6956 0ZM45.913 30.2608C45.913 33.1388 43.5714 35.4782 40.6956 35.4782C40.1196 35.4782 39.6521 35.9457 39.6521 36.5217V44.4375L30.9996 35.7829C30.8035 35.5888 30.5384 35.4782 30.2608 35.4782H7.30438C4.42858 35.4782 2.08702 33.1387 2.08702 30.2608V7.30438C2.08702 4.42643 4.42858 2.08702 7.30438 2.08702H40.6956C43.5714 2.08702 45.913 4.42653 45.913 7.30438V30.2608Z"
        fill="currentColor"
      />
      <path
        d="M23.9996 25.043C22.2736 25.043 20.8691 26.4475 20.8691 28.1734C20.8691 29.8993 22.2736 31.3038 23.9996 31.3038C25.7255 31.3038 27.13 29.8993 27.13 28.1734C27.13 26.4475 25.7255 25.043 23.9996 25.043ZM23.9996 29.2169C23.4236 29.2169 22.9561 28.7473 22.9561 28.1734C22.9561 27.5995 23.4236 27.1299 23.9996 27.1299C24.5756 27.1299 25.0431 27.5995 25.0431 28.1734C25.0431 28.7473 24.5756 29.2169 23.9996 29.2169Z"
        fill="currentColor"
      />
      <path
        d="M27.3353 7.25925C26.7447 6.62485 25.9099 6.26172 25.0438 6.26172H22.9569C22.0908 6.26172 21.2581 6.62485 20.6675 7.25925C20.0769 7.8916 19.7742 8.74934 19.8348 9.63006L20.7175 19.9125C20.8386 21.6196 22.2744 22.9573 24.0003 22.9573C25.7116 22.9573 27.1475 21.6195 27.2664 19.9291L28.1659 9.61539C28.2285 8.75149 27.9259 7.89375 27.3353 7.25925ZM26.0873 9.45055L25.1857 19.7664C25.1419 20.3862 24.6202 20.8704 23.9837 20.8704C23.3618 20.8704 22.8421 20.3862 22.7962 19.7497L21.9154 9.46728C21.8946 9.17302 21.9926 8.89334 22.193 8.68047C22.3933 8.4676 22.6646 8.34864 22.9568 8.34864H25.0438C25.336 8.34864 25.6073 8.4676 25.8076 8.68047C26.008 8.89334 26.1061 9.17302 26.0873 9.45055Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 48 48",
});
