/* eslint-disable import/no-anonymous-default-export */
import RequestResetPassword from "../views/ResetPassword/RequestResetPassword";
import ConfirmResetPassword from "../views/ResetPassword/ConfirmResetPassword";
import Login from "../views/Login/Login";
import Unauthorized from "../views/Unauthorized/Unauthorized";

const createRoute = (path: string, component: React.FC, exact = true) => ({
  path,
  component,
  exact,
});

export default [
  createRoute("/login", Login),
  createRoute("/reset-password", RequestResetPassword),
  createRoute("/reset-password/confirm", ConfirmResetPassword),
  createRoute("*", Unauthorized),
];
