import React from "react";
import { Button } from "components/Button/Button";
import { Box } from "components/Box/Box";
import styled from "@emotion/styled";

const StyledBox = styled(Box)`
  gap: 2rem;
  margin: 1rem auto;
  border: none;
  width: 34rem;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;

  button {
    min-width: 10rem;
  }
`;

export const Pagination = (props: { page: number; changePage: (page: number) => void; data: any }) => (
  <StyledBox>
    <Button
      design="secondary"
      isDisabled={props.page === 1}
      onClick={() => {
        if (props.page > 1) {
          props.changePage(props.page - 1);
        }
      }}
    >
      Prev
    </Button>
    <Button
      design="secondary"
      isDisabled={props.page === 1}
      onClick={() => {
        props.changePage(1);
      }}
    >
      Start
    </Button>
    <Button
      design="secondary"
      isDisabled={props.data.length === 0}
      onClick={() => {
        props.changePage(props.page + 1);
      }}
    >
      Next
    </Button>
  </StyledBox>
);
