import React from "react";
import { useToast as useChakraToast } from "@chakra-ui/react";
import { getToastDuration, ToastError, ToastSuccess } from "../components/Toast/Toast";

interface UseToast {
  type: "success" | "error";
  message: string;
}

export const useToast = () => {
  const chakraToast = useChakraToast();
  return function toast({ type, message }: UseToast) {
    chakraToast({
      position: "top",
      duration: getToastDuration(message),
      render: ({ onClose }) =>
        type === "error" ? (
          <ToastError onClose={onClose}>{message}</ToastError>
        ) : (
          <ToastSuccess onClose={onClose}>{message}</ToastSuccess>
        ),
    });
  };
};
