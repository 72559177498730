import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import { GlobalMarginCallMarginAddModal } from "./GlobalMarginCallMarginAddModal";
import { Company } from "interfaces/data";

export function GlobalMarginCall360MarginAdd() {
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId: string;
  }>();
  const { t } = useTranslation();
  const toast = useToast();
  const { company, update: updateCompany } = useCompany({ id });
  const history = useHistory();

  function handleClose() {
    history.push(`/companies/${id}`);
  }

  async function handleSave(updatedCompany: Company) {
    await updateCompany(updatedCompany);
    toast({
      type: "success",
      message: t("transaction:Global Margin call has been edited successfully."),
    });
    handleClose();
  }

  if (!marginCallId || !company) return null;

  return (
    <GlobalMarginCallMarginAddModal
      company={company}
      marginCallId={marginCallId}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
}
