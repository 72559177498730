import { Company, GlobalMarginCall, MarginCall, Transaction } from "interfaces/data";
import { useFirebase } from "./useFirebase";
import _cloneDeep from "lodash.clonedeep";

const COLLECTION = "companies";

export const useGMC = () => {
  const { db, timestamp } = useFirebase();

  async function getCompany(companyId: string) {
    const company = await db
      .collection(COLLECTION)
      .doc(companyId)
      .get()
      .then((doc) => doc.data());

    return { ...company, id: companyId } as Company;
  }

  async function closeMarginCall(company: Company, marginCallId: string) {
    const marginCallIndex = company.globalMarginCalls?.findIndex((marginCall) => marginCall.id === marginCallId);

    if (marginCallIndex === -1) return;

    const marginCall = company.globalMarginCalls?.[marginCallIndex!];

    if (!marginCall) throw Error(`Margin Call ${marginCallId} does not exist`);

    marginCall.isClosed = "yes";

    return db
      .collection(COLLECTION)
      .doc(company.id)
      .update({
        globalMarginCalls: [
          ...(company.globalMarginCalls?.slice(0, marginCallIndex) || []),
          marginCall,
          ...(company.globalMarginCalls?.slice(marginCallIndex! + 1) || []),
        ],
        modifiedAt: timestamp(),
      });
  }

  async function close(companyId: string, marginCallId: string) {
    // 1. get company from firebase
    const company = await getCompany(companyId);

    // 2. get margin call
    const marginCall = company.globalMarginCalls?.find((marginCall) => marginCallId === marginCall.id);

    // 3. throw error if margin call does not exist
    if (!marginCall) throw Error(`Margin Call ${marginCallId} does not exist`);

    // 4. close margin call
    await closeMarginCall(company, marginCallId);

    return true;
  }

  async function add(gmc: MarginCall, company: Company) {
    const { globalMarginCalls } = company;

    gmc.createdAt = timestamp();
    gmc.modifiedAt = timestamp();

    if (globalMarginCalls?.find((marginCall) => marginCall.id === gmc.id)) {
      throw Error(`Margin Call ${gmc.id} already exists on company ${company.id}`);
    }

    return db
      .collection(COLLECTION)
      .doc(company.id)
      .update({
        globalMarginCalls: [...(company.globalMarginCalls || []), gmc],
        modifiedAt: timestamp(),
        gmcId: gmc.id,
      });
  }

  async function findAllForTransaction(transaction: Transaction) {
    // 1. get company from firebase
    const company = await getCompany(transaction.company.id);

    // 2. get margin calls for transaction
    return (
      company.globalMarginCalls?.filter((gmc) =>
        gmc.globalTransactionsIds?.some((globalTransaction) => globalTransaction.id === transaction.id)
      ) ?? []
    );
  }

  async function updateRolledTransactionId(
    companyId: string,
    previousTransactionId: string,
    newTransaction: { id: string; number: number }
  ) {
    // 1. get company from firebase
    const company = await getCompany(companyId);

    // 2. return if no globalMarginCalls
    if (!company.globalMarginCalls) return;

    // 3. get global margin calls where previous id is present
    const gmcs: GlobalMarginCall[] = company.globalMarginCalls.map((gmc) => {
      // unchanged if no global transactions
      if (!gmc.globalTransactionsIds) return gmc;

      // unchanged if gmc is closed or paid
      if (gmc.isClosed === "yes" || gmc.isPaid) return gmc;

      // replace old id with new
      gmc.globalTransactionsIds = gmc.globalTransactionsIds.map((transaction) => {
        return transaction.id === previousTransactionId ? newTransaction : transaction;
      });

      return gmc;
    });

    // 4. update company collection
    return db
      .collection(COLLECTION)
      .doc(company.id)
      .update({
        globalMarginCalls: [...gmcs],
        modifiedAt: timestamp(),
      });
  }

  async function withdrawMargin({
    companyId,
    gmcId,
    marginId,
    amount,
  }: {
    companyId: string;
    gmcId: string;
    marginId: string;
    amount: number;
  }) {
    // 1. get company from firebase
    const company = await getCompany(companyId);

    // 2. return if no globalMarginCalls
    if (!company.globalMarginCalls) return;

    // 3. get GMC
    const gmc = company.globalMarginCalls.find((gmc) => gmc.id === gmcId);
    if (!gmc || !gmc.margins?.length) return;

    // 4. get Margin
    const margin = gmc.margins.find((margin) => margin.marginId === marginId);
    if (!margin) return;

    // 5. subtract withdrawal amount
    margin.quantity -= amount;

    // 6. update database
    return db
      .collection(COLLECTION)
      .doc(companyId)
      .update({
        globalMarginCalls: [..._cloneDeep(company.globalMarginCalls)],
        modifiedAt: timestamp(),
      });
  }

  async function updateComment(marginCall: GlobalMarginCall, comment: string) {
    // 1. get company from firebase
    const company = await getCompany(marginCall.company.id);

    // 2. return if no globalMarginCalls
    if (!company.globalMarginCalls) return;

    // 3. update comment
    marginCall.tableComment = comment;

    // 4. replace margin call
    company.globalMarginCalls = company.globalMarginCalls.map((gmc) => {
      return gmc.id === marginCall.id ? marginCall : gmc;
    });

    // 5. update database
    return db.collection(COLLECTION).doc(company.id).update({
      globalMarginCalls: company.globalMarginCalls,
      modifiedAt: timestamp(),
    });
  }

  return {
    add,
    close,
    findAllForTransaction,
    updateRolledTransactionId,
    withdrawMargin,
    updateComment,
  };
};
