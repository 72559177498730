import React from "react";
import { Section, SectionTitle } from "../shared.styled";
import { LabeledText } from "components/LabeledText/LabeledText";
import { Company } from "interfaces/data";
import { useTranslation } from "react-i18next";

interface AboutCompanySectionProps {
  company: Company;
}

export const AboutCompanySection: React.FC<AboutCompanySectionProps> = ({ company }) => {
  const { t } = useTranslation();

  const companyAddress = company.companyAddress;

  return (
    <Section>
      <SectionTitle>{t("company:About this company")}</SectionTitle>
      <LabeledText label={t("company:Name")} mb="20px" value={company.name} />
      <LabeledText
        label={t("company:Address")}
        mb="20px"
        value={
          <div>
            {`${companyAddress.street} ${companyAddress.number}`}
            <br />
            {`${companyAddress.code} ${companyAddress.city}, ${companyAddress.country}`}
          </div>
        }
      />
      <LabeledText label={t("company:NIP")} mb="20px" value={company.nip} />
      <LabeledText label={t("company:Industry")} mb="20px" value={company.details.industry} />
    </Section>
  );
};
