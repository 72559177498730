import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatNumberToString } from "helpers/formatNumber";
import { calculateMarginsInPlnForRiskPanel } from "helpers/transaction";
import { Transaction } from "interfaces/data";

interface TotalChargeProps {
  transactions: Array<Transaction>;
  nbpRates: any;
}

export const StyledContainer = styled.section`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  margin-bottom: 20px;
  background: white;
  padding: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const StyledHeaderSection = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
  display: flex;
  align-items: center;
`;

export const StyledSection = styled.div`
  text-align: center;
  border-left: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;

export const StyledCurrencyBox = styled.div`
  width: 50px;
  height: 42px;
  background: rgba(236, 236, 236, 0.3);
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

export const StyledLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-bottom: 6px;
`;

export const StyledValue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

export const TotalCharge: React.FC<TotalChargeProps> = ({ transactions, nbpRates }) => {
  const { t } = useTranslation();

  const totalChargeData = useMemo(
    () => calculateMarginsInPlnForRiskPanel(transactions, nbpRates),
    [nbpRates, transactions]
  );

  return (
    <StyledContainer>
      <StyledHeaderSection>
        <Flex alignItems="center">
          {t("risk:Total charge")}
          <StyledCurrencyBox>PLN</StyledCurrencyBox>
        </Flex>
      </StyledHeaderSection>
      <StyledSection>
        <StyledLabel>{t("risk:Initial margin")}</StyledLabel>
        <StyledValue>{formatNumberToString(totalChargeData.initialMargin)}</StyledValue>
      </StyledSection>
      <StyledSection>
        <StyledLabel>{t("risk:Variation margin")}</StyledLabel>
        <StyledValue>{formatNumberToString(totalChargeData.variableMargin)}</StyledValue>
      </StyledSection>
      <StyledSection>
        <StyledLabel>{t("risk:Total margin")}</StyledLabel>
        <StyledValue>
          {formatNumberToString(Number(totalChargeData.initialMargin) + Number(totalChargeData.variableMargin))}
        </StyledValue>
      </StyledSection>
    </StyledContainer>
  );
};
