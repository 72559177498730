import React from "react";
import styled from "@emotion/styled";
import { InputRightAddon as ChakraInputRightAddon } from "@chakra-ui/react";
import isPropValid from "@emotion/is-prop-valid";

type InputRightAddonProps = {
  hasError?: boolean;
  rightAddon?: string;
};

const StyledInputRightAddon = styled(ChakraInputRightAddon, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ hasError?: boolean }>`
  border: 1px solid ${(props) => (props.hasError ? props.theme.colors["sk-red"] : props.theme.colors["sk-light-gray"])};
  background-color: ${(props) => (props.hasError ? "rgba(236, 71, 19, 0.3)" : "rgba(236, 236, 236, 0.3)")};
  border-top-right-radius: ${(props) => props.theme.radii.base};
  border-bottom-right-radius: ${(props) => props.theme.radii.base};
`;

export const InputRightAddon: React.FC<InputRightAddonProps> = ({ rightAddon, hasError }) => {
  return (
    <StyledInputRightAddon
      children={rightAddon}
      color="sk-gray"
      fontSize="xs-sm"
      fontWeight="semibold"
      hasError={hasError}
      height="42px"
      justifyContent="center"
      p={0}
      width={50}
    />
  );
};
