import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { BaseTransaction, Transaction, TransactionStatus, TransactionType } from "interfaces/data";
import { TransactionFormModal } from "../TransactionFormModal/TransactionFormModal";
import { TransactionConfirm } from "../TransactionConfirm/TransactionConfirm";
import _cloneDeep from "lodash.clonedeep";
import { useMail } from "hooks/useMail";
import { FIXED_RATE_MAIL } from "helpers/mails";
import firebase from "firebase";

export function TransactionConvert() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [sendEmail, setSendEmail] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState<BaseTransaction>();
  const { convert, transaction, errors, validate } = useTransaction({
    id,
  });
  const sendConfirmation = useMail();

  const confirmConvert = useCallback(
    (transaction: DeepPartial<Transaction>) => {
      validate(transaction).then((isValid) => {
        if (isValid) {
          setNewTransaction(transaction as Transaction);
          setIsPopupOpen(true);
        }
      });
    },
    [validate]
  );

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSave = useCallback(
    (transaction: BaseTransaction) => {
      setIsSending(true);
      convert(transaction)
        .then((newTransactionId) => {
          if (newTransactionId) {
            if (sendEmail) {
              sendConfirmation(FIXED_RATE_MAIL, String(newTransactionId));
            }
            toast({
              type: "success",
              message: t("transaction:Transaction has been converted successfully."),
            });
            setIsSending(false);
            history.push(`/transactions/${newTransactionId}`);
          }
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          });
          setIsSending(false);
          history.push(`/transactions/${id}`);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convert, handleClose, toast, t, setIsSending]
  );

  const convertedTransaction = useMemo(() => {
    if (transaction) {
      // SET VALUES OF CONVERTED TRANSACTION
      const convertedTransaction = _cloneDeep(transaction);
      convertedTransaction.type = TRANSACTION_TYPES.FIXED.value as TransactionType;
      convertedTransaction.status = TRANSACTION_STATUSES.CREATED.value as TransactionStatus;

      if (transaction.comment) {
        convertedTransaction.comment = transaction.comment;
      }
      convertedTransaction.agreement = firebase.firestore.Timestamp.now();
      convertedTransaction.start = firebase.firestore.Timestamp.now();
      if ("end" in convertedTransaction && !convertedTransaction.end) {
        delete convertedTransaction.end;
      }

      return convertedTransaction;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  useEffect(() => {
    if (transaction && transaction.type !== TRANSACTION_TYPES.ORDER.value) {
      handleClose();
      toast({
        type: "error",
        message: t("transaction:Only Order transactions can be converted."),
      });
    }
  }, [handleClose, t, toast, transaction]);

  if (!convertedTransaction) return null;

  return (
    <>
      <TransactionFormModal
        errors={errors}
        initialTransaction={convertedTransaction}
        onClose={handleClose}
        onSave={confirmConvert}
        sendEmail={sendEmail}
        sendEmailChange={setSendEmail}
        title={`${t("transaction:Convert Transaction")} #${convertedTransaction.number}`}
        transactionType={TRANSACTION_TYPES.FIXED.value}
        type="convert"
      />
      <TransactionConfirm
        isDisabled={isSending}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleSave}
        sendEmail={sendEmail}
        transaction={newTransaction}
        transactionType={TRANSACTION_TYPES.FIXED.value}
        type="convert"
      />
    </>
  );
}
