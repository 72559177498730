/* eslint-disable import/no-anonymous-default-export */
import commonPL from "./common.json";
import sidebarPL from "./sidebar.json";
import loginPL from "./login.json";
import companyPL from "./company.json";
import contactPL from "./contact.json";
import applicationPL from "./application.json";
import validationPL from "./validation.json";
import transactionPL from "./transaction.json";
import hedgePL from "./hedge.json";
import marginPL from "./margin.json";
import settlementPL from "./settlement.json";
import marginCallPL from "./marginCall.json";
import commissionFeePL from "./commissionFee.json";
import multiuserPL from "./multiuser.json";
import settingsPL from "./settings.json";
import riskPL from "./risk.json";
import reportPL from "./report.json";
import helpersPL from "./helpers.json";

export default {
  common: commonPL,
  sidebar: sidebarPL,
  login: loginPL,
  company: companyPL,
  contact: contactPL,
  application: applicationPL,
  validation: validationPL,
  transaction: transactionPL,
  hedge: hedgePL,
  margin: marginPL,
  settlement: settlementPL,
  report: reportPL,
  marginCall: marginCallPL,
  commissionFee: commissionFeePL,
  multiuser: multiuserPL,
  settings: settingsPL,
  risk: riskPL,
  helpers: helpersPL,
};
