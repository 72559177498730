import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "hooks/useTransaction";
import { useToast } from "hooks/useToast";
import { Modal } from "components/Modal/Modal";
import { Box } from "@chakra-ui/react";

export function MarginCallsClose() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { transactionId, marginCallId } = useParams<{
    transactionId: string;
    marginCallId: string;
  }>();
  const { transaction, marginCallClose } = useTransaction({
    id: transactionId,
  });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/calls/margin-calls/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseAction = useCallback(() => {
    setIsSending(true);
    marginCallClose(marginCallId)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("marginCall:Margin Call has been closed successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`marginCall:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marginCallClose, marginCallId, history]);

  const editedMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [marginCallId, transaction]);

  if (!transaction || !editedMarginCall) return null;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCloseAction}
      title={t(`marginCall:Close ${editedMarginCall.isGMC === "yes" ? "" : "margin call"} {{marginCallId}}`, {
        marginCallId,
      })}
    >
      <Box mb={"20px"}>
        {t(
          `marginCall:This action can't be undone. Are you sure you want to close ${
            editedMarginCall.isGMC === "yes" ? "" : "margin call"
          } {{marginCallId}}?`,
          { marginCallId }
        )}
      </Box>
    </Modal>
  );
}
