import React from "react";
import { sortObjectProperties } from "../../../helpers/logs";

interface LogDetailsDataProps {
  data: string;
}

export function LogDetailsData({ data }: LogDetailsDataProps) {
  return (
    <pre>
      <code>{JSON.stringify(sortObjectProperties(JSON.parse(String(data))), null, 2)}</code>
    </pre>
  );
}
