import { PageHeader } from "components/PageHeader/PageHeader";
import { Heading } from "components/Heading/Heading";
import React, { useMemo } from "react";
import { StyledContainer } from "components/ItemsListElements/ItemsListElements";
import { StyledTableContainer } from "../Company360/Main/Operations";
import { Margin, Transaction } from "interfaces/data";
import { Table } from "components/Table/Table";
import { useTransactions } from "hooks/useTransactions";
import { TRANSACTION_STATUSES } from "helpers/transaction";
import { CurrencyValueCell } from "./components/CurrencyValueCell";
import { OperationsCell } from "./components/OperationsCell";
import { RemainingCell } from "./components/RemainingCell";
import { TransactionLinkCell } from "./components/TransactionLinkCell";
import { CompanyLinkCell } from "./components/CompanyLinkCell";
import { MigrateCell } from "./components/MigrateCell";
import { getRemainingValue } from "helpers/margin";

export interface MarginInfo extends Margin {
  transaction: Transaction;
}

export function GlobalMarginsMigration() {
  const filtersAnd = useMemo(
    () => [
      "status:" +
        [
          TRANSACTION_STATUSES.CREATED.value,
          TRANSACTION_STATUSES.PARTIALLY_SETTLED.value,
          TRANSACTION_STATUSES.SETTLED.value,
        ].join(","),
    ],
    []
  );

  const { transactions, loading, refetch } = useTransactions({
    phrase: "",
    filtersAnd,
    fields: "number,id",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 0,
  });

  const margins: MarginInfo[] = useMemo(() => {
    if (!transactions) return [];
    return (
      transactions
        // only margins that are assigned to GMC
        .reduce((margins: MarginInfo[], transaction) => {
          if (!transaction.margins) return margins;
          margins.push(
            ...transaction.margins
              .filter((margin) => margin.marginCallId?.includes("GMC") && getRemainingValue(margin) > 0)
              .map((margin) => ({ ...margin, transaction }))
          );

          return margins;
        }, [])
        // oldest on top
        .sort((a, b) => parseInt(a.id) - parseInt(b.id))
    );
  }, [transactions]);

  const refreshData = async () => {
    await refetch();
  };

  const columns = [
    {
      Header: "Margin ID",
      accessor: "id",
      Cell: TransactionLinkCell,
    },
    {
      Header: "Margin Call",
      accessor: "marginCallId",
      Cell: CompanyLinkCell,
    },
    {
      Header: "Company",
      accessor: "transaction.company.name",
      Cell: CompanyLinkCell,
    },
    {
      Header: "Operations",
      accessor: "operations",
      Cell: OperationsCell,
    },
    {
      Header: "From",
      accessor: "from",
      Cell: CurrencyValueCell,
    },
    {
      Header: "To",
      accessor: "to",
      Cell: CurrencyValueCell,
    },
    {
      Header: "Remaining",
      Cell: RemainingCell,
    },
    {
      Header: "Action",
      Cell: (arg: any) => MigrateCell(arg, refreshData),
    },
  ];

  return (
    <>
      <PageHeader>
        <Heading type="h1">Global Margins Migration ({margins.length})</Heading>
      </PageHeader>
      <StyledContainer>
        <StyledTableContainer>
          <Table columns={columns} data={margins} loading={loading} />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
}
