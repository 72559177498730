import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "components/Dropdown/Dropdown";
import { Box } from "@chakra-ui/react";
import { CURRENCIES } from "helpers/options";
import { StyledClearButton, StyledDropdownButton, StyledDropdownContent } from "./shared.styled";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { SpecialFilterTypes } from "../Transactions";
import { SelectIcon } from "theme/icons";

interface CurrencyFilterProps {
  specialFilters: Array<SpecialFilterTypes>;
  onChange?: (newSpecialFilter: Array<string>) => void;
}

export const CurrencyFilter: React.FC<CurrencyFilterProps> = ({ specialFilters, onChange }) => {
  const { t } = useTranslation();

  const [fromCurrency, setFromCurrency] = useState("");
  const [toCurrency, setToCurrency] = useState("");
  const [filters, setFilters] = useState<Array<SpecialFilterTypes>>([]);

  const handleReset = () => {
    setFromCurrency("");
    setToCurrency("");
    const newSpecialFilters = specialFilters.filter((key) => !key.startsWith("currency"));
    setFilters(newSpecialFilters);
    window.scrollTo(0, 0);
  };

  const handleSpecialFiltersUpdate = useCallback(
    (filterKey: SpecialFilterTypes, currency: string) => {
      const newSpecialFilters = specialFilters.find((f) => f.startsWith(filterKey))
        ? [...specialFilters.filter((key) => !key.startsWith(filterKey)), `${filterKey}${currency}`]
        : [...specialFilters, `${filterKey}${currency}`];
      setFilters(newSpecialFilters);
    },
    [specialFilters]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(filters);
    }
  }, [filters, onChange]);

  return (
    <Box ml="10px">
      <Dropdown
        trigger={
          <StyledDropdownButton isActive={Boolean(fromCurrency) || Boolean(toCurrency)}>
            <div>
              {fromCurrency || toCurrency ? (
                <Box fontWeight={800}>
                  {fromCurrency && `F: ${fromCurrency}`}
                  {toCurrency && `${fromCurrency && ", "}T: ${toCurrency}`}
                </Box>
              ) : (
                t("transaction:Currency")
              )}
            </div>
            <SelectIcon ml="auto" />
          </StyledDropdownButton>
        }
      >
        <StyledDropdownContent>
          <AutoCompleteSelect
            id="fromCurrency"
            label="From"
            mb="20px"
            name="fromCurrency"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const currency = e.target.value;
              handleSpecialFiltersUpdate("currency.from.", currency);
              setFromCurrency(currency);
            }}
            options={CURRENCIES}
            placeholder="All currencies"
            value={fromCurrency}
          />
          <AutoCompleteSelect
            id="toCurrency"
            label="To"
            mb="20px"
            name="toCurrency"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const currency = e.target.value;
              handleSpecialFiltersUpdate("currency.to.", currency);
              setToCurrency(currency);
            }}
            options={CURRENCIES}
            placeholder="All currencies"
            value={toCurrency}
          />
          <StyledClearButton onClick={handleReset}>{t("transaction:clear")}</StyledClearButton>
        </StyledDropdownContent>
      </Dropdown>
    </Box>
  );
};
