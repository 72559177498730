import { Log } from "../interfaces/data";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";
import { transformTimestamp } from "./date";

export function convertActionToVerb(action: string) {
  const wordBase = action.slice(-1) === "e" ? action.slice(0, -1) : action;
  return capitalizeFirstLetter(wordBase + "ed");
}

export function convertCollectionToNoun(collection?: string) {
  if (!collection) return "";
  if (collection === "companies") return "company";
  return collection
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .slice(0, -1);
}

export function getLogItemIdentifier(log: Log) {
  if (!log.item) return "";
  if (["companies", "contacts", "applications"].includes(log.item?.collection)) {
    return log.item.name;
  } else {
    return log.item.id;
  }
}

export function sortObjectProperties(object: any): any {
  if (!object || typeof object !== "object") {
    return object;
  }
  if (object instanceof Array) {
    return object.map(sortObjectProperties);
  }
  if (object.hasOwn("nanoseconds") && object.hasOwn("seconds")) {
    return transformTimestamp(object, "DD/MM/YYYY HH:mm:ss");
  }
  const keys = Object.keys(object);
  keys.sort();
  const newObject = {} as typeof object;
  for (let i = 0; i < keys.length; i++) {
    newObject[keys[i]] = sortObjectProperties(object[keys[i]]);
  }
  return newObject;
}
