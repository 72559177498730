import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Application } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { findCountry } from "../../../helpers/findCountry";

interface ContactAddressProps {
  application: Application;
}

export function ContactAddress({ application }: ContactAddressProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("application:Street")} value={application.contactAddress?.street} />
      <LabeledText label={t("application:Apt number")} value={application.contactAddress?.number} />
      <LabeledText label={t("application:Code")} value={application.contactAddress?.code} />
      <LabeledText label={t("application:City")} value={application.contactAddress?.city} />
      <LabeledText label={t("application:Country")} value={findCountry(application.contactAddress?.country)} />
    </TwoColumnsLayout>
  );
}
