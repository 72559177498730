import React, { useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTransaction } from "hooks/useTransaction";
import { MarginCallFinalModal } from "./MarginCallFinalModal";
import { useMutation } from "react-query";
import { useAuth } from "hooks/useAuth";
import { MarginFinalNotice } from "interfaces/data";

export function MarginCallFinalAdd() {
  const history = useHistory();
  const { token } = useAuth();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId?: string;
  }>();
  const { transaction } = useTransaction({ id });
  const { mutate, isLoading } = useMutation(
    (finalNotice: MarginFinalNotice) => {
      return fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/margin-calls/final-notice`, {
        method: "POST",
        body: JSON.stringify(finalNotice),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        history.push(`/transactions/${id}`);
      },
    }
  );

  const marginCall = useMemo(() => {
    if (!transaction) {
      return;
    }
    return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
  }, [marginCallId, transaction]);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveAction = useCallback(
    (finalNotice: MarginFinalNotice) => {
      mutate(finalNotice);
    },
    [mutate]
  );

  if (!marginCall || !transaction) {
    return null;
  }

  return (
    <MarginCallFinalModal
      isDisabled={isLoading}
      marginCall={marginCall}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      transaction={transaction}
    />
  );
}
