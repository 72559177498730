import styled from "@emotion/styled";

export const StyledFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: 250px auto;
  justify-content: space-between;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  display: flex;
  margin-top: 13px;
  justify-content: flex-start;
  font-weight: 600;
  line-height: 110%;
`;

export const StyledNoRiskInfo = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledSearchBoxContainer = styled.div`
  margin-bottom: 20px;
`;

export const StyledCompaniesSearchBoxContainer = styled.div`
  margin-bottom: 20px;
  .search-box-pane {
    border-radius: 16px !important;
  }
`;

export const StyledTransactionHeaderContainer = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledTransactionRowContainer = styled.div`
  display: flex;
`;

export const StyledTransactionHeaderCell = styled.div<{
  width: string;
}>`
  padding: 15px 10px;
  width: ${(props) => props.width};
  font-weight: bold;
  border-bottom: 2px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;

export const StyledTransactionRowCell = styled.div<{
  width: string;
}>`
  padding: 0 10px;
  width: ${(props) => props.width};
  text-align: left;
  font-weight: normal;
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;
