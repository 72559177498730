import React from "react";
import { LabeledText } from "components/LabeledText/LabeledText";
import { Application } from "interfaces/data";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { findCountry } from "helpers/findCountry";
import styled from "@emotion/styled";

interface BankAccountsProps {
  application: Application;
}

export const TwoColumnsBankAccountLayout = styled(TwoColumnsLayout)<{
  withPaddingBottom?: boolean;
  leftEmpty?: boolean;
  skipColumnGap?: boolean;
}>`
  grid-template-columns: 3fr 1fr;
`;

export function BankAccount({ application }: BankAccountsProps) {
  const { t } = useTranslation();
  return (
    <>
      {application.bankAccounts ? (
        application.bankAccounts.map((account, i) => (
          <Box key={i} mb="30px">
            <TwoColumnsBankAccountLayout>
              <LabeledText label={t("company:Name")} value={account.name} />
              <LabeledText label={t("company:Country")} value={account.country ? findCountry(account.country) : "-"} />
              <LabeledText label={t("company:Number")} value={`${account.number} ${account.currency}`} />
              <LabeledText label={t("company:SWIFT/BIC")} value={account.swift} />
            </TwoColumnsBankAccountLayout>
          </Box>
        ))
      ) : (
        <>No bank accounts added yet</>
      )}
    </>
  );
}
