import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ExportIcon = createIcon({
  displayName: "ExportIcon",
  path: (
    <g fill="none">
      <path
        d="M13.0001 0.5L6.6001 6.9"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M7.3999 0.5H12.9999V6.1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M4.2 0.5H1.8C1.3584 0.5 1 0.8584 1 1.3V11.7C1 12.1416 1.3584 12.5 1.8 12.5H12.2C12.6416 12.5 13 12.1416 13 11.7V9.3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 14 13",
});
