import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAuth } from "./useAuth";
import { useToast } from "./useToast";

export const useCSVExport = ({
  type,
  startTimestamp,
  endTimestamp,
  format = "csv",
}: {
  type: string;
  startTimestamp?: number;
  endTimestamp?: number;
  format?: string;
}) => {
  const { token, logout } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  return useCallback(() => {
    return fetch(
      `${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/exports/${type}?startTimestamp=${startTimestamp}&endTimestamp=${endTimestamp}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response: Response) => {
        // Logout user if unauthorized
        if (response.status === 401) {
          logout();
          return undefined;
        }

        if (response.status === 200) {
          return response.blob();
        }
      })
      .then((blob) => {
        if (!blob) return;
        const anchor = document.createElement("a");

        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = `${type}_${dayjs().format("YYYY_MM_DD_HH_mm_ss")}.${format}`;
        document.body.appendChild(anchor);

        anchor.click();
        anchor.remove();
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t("validation:CSV could not be exported"),
        });
        console.error("useCSVExport", error);
      });
  }, [type, startTimestamp, endTimestamp, token, logout, format, toast, t]);
};
