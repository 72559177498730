import { useState } from "react";
import { ZodError } from "zod";

interface ModalValidationData {
  title: string;
  validationErrors: ZodError;
  fixFunction: () => Promise<void>;
}

export const useValidationModal = () => {
  const [validationData, setValidationData] = useState<ModalValidationData>();
  return [validationData, setValidationData] as const;
};
