import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, useDisclosure } from "@chakra-ui/react";

import { BankAccount, BaseCompany } from "interfaces/data";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { Input } from "components/Input/Input";
import { Select } from "components/Select/Select";
import { COUNTRIES, CURRENCIES } from "helpers/options";
import { EditViewProps } from "components/EditBox/EditBox";
import { Button } from "components/Button/Button";
import { Modal } from "components/Modal/Modal";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { DeleteIcon } from "@chakra-ui/icons";

interface EditBankAccountsProps extends EditViewProps<BaseCompany> {
  onAccountRemove: (index: number, { handler, data }: { handler: Function; data: BaseCompany }) => void;
}

export function EditBankAccounts({ data, handleChange, errors, onAccountRemove }: EditBankAccountsProps) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteIndex, setDeleteIndex] = useState(-1);

  const handleClose = useCallback(() => {
    onClose();
    setDeleteIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = useCallback(
    (index?: number) => {
      const itemIndex = index ? index : deleteIndex;
      if (itemIndex !== -1) {
        onAccountRemove(itemIndex, { handler: handleChange, data });
        handleClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteIndex, handleClose, handleChange, data]
  );

  const handleOpen = useCallback(
    (index: number, account: BankAccount) => {
      setDeleteIndex(index);

      if (!account.name && !account.number && !account.swift) {
        handleRemove(index);
        return;
      }

      onOpen();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [setDeleteIndex, onOpen, handleRemove]
  );

  if (!data) {
    return null;
  }

  return (
    <>
      {data.bankAccounts?.map((account, index) => (
        <Box key={`bankAccount-${index}`} mb="30px">
          {index > 0 && (
            <Box display="flex" justifyContent="flex-end" mb="20px">
              <Button design="link" height="auto" minWidth="auto" onClick={() => handleOpen(index, account)} px={0}>
                <DeleteIcon color="sk-red" />
              </Button>
            </Box>
          )}
          <TwoColumnsLayout withPaddingBottom>
            <Input
              error={errors?.[`bankAccounts[${index}].name`]}
              id={`bankAccounts[${index}].name`}
              label={t("company:Name")}
              name={`bankAccounts[${index}].name`}
              onChange={handleChange}
              type="text"
              value={account.name}
              withError={!!errors?.[`bankAccounts[${index}].name`]}
            />
            <Select
              error={errors?.[`bankAccounts[${index}].country`]}
              id={`bank-account-countries-${index}`}
              label={t("company:Country")}
              name={`bankAccounts[${index}].country`}
              onChange={handleChange}
              options={COUNTRIES}
              value={account.country}
            />
          </TwoColumnsLayout>
          <Box display="grid" gridColumnGap="10px" gridRowGap="10px" gridTemplateColumns="1fr" mb="20px">
            <Input
              error={errors?.[`bankAccounts[${index}].number`]}
              id={`bankAccounts[${index}].number`}
              label={t("company:Number")}
              name={`bankAccounts[${index}].number`}
              onChange={handleChange}
              type="text"
              value={account.number}
              withError={!!errors?.[`bankAccounts[${index}].number`]}
            />
            <Box display="grid" gridColumnGap="30px" gridTemplateColumns="1fr 1fr" width="100%">
              <AutoCompleteSelect
                error={errors?.[`bankAccounts[${index}].currency`]}
                id={`bankAccounts[${index}].currency`}
                label={t("company:Currency")}
                name={`bankAccounts[${index}].currency`}
                onChange={handleChange}
                options={CURRENCIES}
                value={account.currency}
                withError={!!errors?.[`bankAccounts[${index}].currency`]}
              />
              <Input
                error={errors?.[`bankAccounts[${index}].swift`]}
                id={`bankAccounts[${index}].swift`}
                label={t("company:SWIFT/BIC")}
                name={`bankAccounts[${index}].swift`}
                onChange={handleChange}
                type="text"
                value={account.swift}
                withError={!!errors?.[`bankAccounts[${index}].swift`]}
              />
            </Box>
          </Box>
        </Box>
      ))}
      <Modal
        confirmText={t("company:Yes, delete it")}
        design="danger"
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleRemove}
        title={t(`company:Delete bank account`)}
      >
        <Box mb={"20px"}>
          {t("company:This action can't be undone. Are you sure you want to delete this bank account?")}
        </Box>
      </Modal>
    </>
  );
}
