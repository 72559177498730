import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const PaperPlaneIcon = createIcon({
  displayName: "PaperPlaneIcon",
  path: (
    <g fill="none">
      <path
        d="M4.2032 7.7024L10.6 12.5L13 0.5L1 5.3L2.6016 6.5008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
      <path
        d="M6.7042 9.57827L4.2002 12.4999L4.2034 7.70227L7.8002 5.29987"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 14 13",
});
