import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface OtherInfoProps {
  company: Company;
}

export function OtherInfo({ company }: OtherInfoProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText fullWidth label={t("company:Description")} value={company.other?.notes} />
      <LabeledText label={t("company:Communication language")} value={company.other?.communicationLanguage} />
      <LabeledText label={t("company:IP")} value={company.other?.ip} />
    </TwoColumnsLayout>
  );
}
