import React from "react";
import { createPortal } from "react-dom";
import { usePortal } from "../../hooks/usePortal";
import styled from "@emotion/styled";

export const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
`;

export const Footer = ({ children }: any) => {
  const target = usePortal("footer-portal");
  if (!target) return null;
  return createPortal(<StyledFooter>{children}</StyledFooter>, target);
};
