import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ApplicationsIcon = createIcon({
  displayName: "ApplicationsIcon",
  path: (
    <g fill="none">
      <path
        d="M5.2666 8.46647L7.93327 11.1331L13.2666 5.7998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.9333 17H2.06667C1.47787 17 1 16.5221 1 15.9333V2.06667C1 1.47787 1.47787 1 2.06667 1H15.9333C16.5221 1 17 1.47787 17 2.06667V15.9333C17 16.5221 16.5221 17 15.9333 17Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 18 18",
});
