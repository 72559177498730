import { getWeightedAverageFromObject } from "helpers/transaction";
import { Company } from "interfaces/data";
import { useCompanies } from "./useCompanies";
import { useScoring } from "./useScoring";
import { useNbpRates } from "./useNbpRates";
import { useCallback, useMemo } from "react";

export const useCompanyScoring = (company: Company) => {
  const { questions, globalLimit } = useScoring();
  const { rates: nbpRates } = useNbpRates();
  const { companies } = useCompanies({
    phrase: "",
    fields: "name",
    orderBy: "name",
    filtersOr: ["isDeactivated:false"],
    offset: 0,
    limit: 0,
    skipAutoUpdate: true,
  });

  const AUTO_LIMIT_CEILING = 1000000;
  const ROUNDING_VALUE = 50000;

  // 1. get all active companies which are eligible for limit
  const eligibleCompanies = useMemo(
    () => companies.filter((company: Company) => company.isDeactivated !== true && !company.details.nolimits),
    [companies]
  );

  // 2. get all companies with limit set manually
  const companiesWithSetLimit = useMemo(
    () => eligibleCompanies.filter((company: Company) => company.details.limit > 0),
    [eligibleCompanies]
  );

  // 3. get companies for auto limit
  const companiesForAutoLimit = useMemo(
    () =>
      eligibleCompanies.filter(
        (company: Company) => !companiesWithSetLimit.find((eligible) => company.id === eligible.id)
      ),
    [companiesWithSetLimit, eligibleCompanies]
  );

  // 4. calculate global limit for companies with limit set to auto
  const getCompanyLimitInEuro = useCallback(
    (company: Company) => {
      if (!company.details.limit || !nbpRates) {
        return 0;
      }

      const { currency, limit } = company.details;

      if (!currency || currency === "EUR") {
        return company.details.limit;
      }

      const eurRate = nbpRates.rates["EUR"];

      return Math.round(limit * (eurRate / nbpRates.rates[currency]));
    },
    [nbpRates]
  );

  const availableGlobalLimit = useMemo(
    () =>
      companiesWithSetLimit.reduce(
        (globalLimit: number, company: Company) => globalLimit - getCompanyLimitInEuro(company),
        globalLimit
      ),
    [companiesWithSetLimit, getCompanyLimitInEuro, globalLimit]
  );

  // 5. calculate base auto limit
  const getCompanyScore = useCallback(
    (company: Company) => {
      const companyScoringQuestions = company?.scoring?.questions;
      const companyScores = companyScoringQuestions?.map((csq: any) => Number(csq.score));
      const scoresWeight = companyScoringQuestions?.map((csq: any) =>
        Number(
          questions.filter((question) => question.status !== "inactive").find((q) => q.id === csq.questionId)?.weight ||
            0
        )
      );

      if (companyScores?.length && scoresWeight?.length) {
        const avg = getWeightedAverageFromObject({
          values: scoresWeight,
          weights: companyScores,
        });
        return avg ? Math.round(Number(avg)) : 0;
      }
      return 0;
    },
    [questions]
  );

  // return values: 1 for scoring 0, 1.4 for scoring 2, 0.6 for scoring -2
  const limitModifier = useCallback((company: Company) => 1 + getCompanyScore(company) / 20, [getCompanyScore]);

  const sumOfCompaniesWithLimitModifier = useMemo(
    () =>
      companiesForAutoLimit.reduce((base: number, company: Company) => {
        return base + limitModifier(company);
      }, 0),
    [companiesForAutoLimit, limitModifier]
  );

  const baseAutoLimitValuePerCompany = useMemo(
    () => Math.round(availableGlobalLimit / sumOfCompaniesWithLimitModifier),
    [availableGlobalLimit, sumOfCompaniesWithLimitModifier]
  );

  // 6. calculate company limit
  const calculateCompanyLimit = useCallback(
    (company: Company) => {
      // manually set limit
      if (company.details.limit > 0) {
        return Number(company.details.limit);
      }

      const companyAutoLimit = Math.min(baseAutoLimitValuePerCompany, AUTO_LIMIT_CEILING);

      return Math.round(companyAutoLimit * limitModifier(company));
    },
    [baseAutoLimitValuePerCompany, limitModifier]
  );

  const companyScore = useMemo(() => getCompanyScore(company), [company, getCompanyScore]);

  const companyLimit = useMemo(() => {
    const limit = calculateCompanyLimit(company);

    return Math.round(limit / ROUNDING_VALUE) * ROUNDING_VALUE;
  }, [company, calculateCompanyLimit]);

  const isAutoLimit = useMemo(
    () => !Number(company.details.limit) && !company.details.nolimits,
    [company.details.limit, company.details.nolimits]
  );

  return {
    companyScore,
    companyLimit,
    isAutoLimit,
  };
};
