import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { Collapse as ChakraCollapse } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface CollapseProps extends PropsWithChildren {
  header: React.ReactNode;
  isExpanded: boolean;
  onCollapseToggle: () => void;
}

const StyledHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Collapse: React.FC<CollapseProps> = ({ header, children, isExpanded, onCollapseToggle }) => {
  return (
    <>
      <StyledHeader onClick={onCollapseToggle}>
        {header} {isExpanded ? <ChevronUpIcon ml="auto" /> : <ChevronDownIcon ml="auto" />}
      </StyledHeader>
      <ChakraCollapse in={isExpanded}>{children}</ChakraCollapse>
    </>
  );
};
