import React, { useCallback } from "react";
import { StyledSelect, StyledSelectContainer, StyledSelectLabel } from "../ItemsListElements/ItemsListElements";
import { useTranslation } from "react-i18next";

export interface TableSortOption {
  value: string;
  label: string;
  field: string;
  order: "asc" | "desc";
}

interface TableSortSelectProps {
  options: Array<TableSortOption>;
  selectedOption: TableSortOption;
  onSortByChange: (newSelectedOption: TableSortOption) => void;
}

export function TableSortSelect({ options, selectedOption, onSortByChange }: TableSortSelectProps) {
  const handleSortByUpdate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => onSortByChange(options[e.target.selectedIndex]),
    [options, onSortByChange]
  );

  const { t } = useTranslation();
  return (
    <StyledSelectContainer>
      <StyledSelectLabel>{t("Sort By")}</StyledSelectLabel>
      <StyledSelect onChange={handleSortByUpdate} value={selectedOption.value}>
        {options.map((sortOption) => (
          <option key={sortOption.value} value={sortOption.value}>
            {sortOption.label}
          </option>
        ))}
      </StyledSelect>
    </StyledSelectContainer>
  );
}
