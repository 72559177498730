import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const RiskIcon = createIcon({
  displayName: "RiskIcon",
  path: (
    <g fill="none">
      <path
        d="M15.9333 1H2.06667C1.47756 1 1 1.47756 1 2.06667V15.9333C1 16.5224 1.47756 17 2.06667 17H15.9333C16.5224 17 17 16.5224 17 15.9333V2.06667C17 1.47756 16.5224 1 15.9333 1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.1333 10.5999L6.3333 7.3999L10.6 11.6666L14.8666 7.3999"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 18 18",
});
