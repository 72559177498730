import React, { PropsWithChildren } from "react";
import {
  StyledContent,
  StyledFooterPortal,
  StyledHeaderContainer,
  StyledIllustration,
  StyledImage,
  StyledLayoutContainer,
  StyledLayoutContent,
  StyledLogo,
  StyledMain,
  StyledPageHeader,
  StyledRightSideContainer,
  StyledSidebarContainer,
  StyledSkipLink,
  StyledUnauthorizedLayoutContainer,
} from "./Layout.styled";
import { Sidebar } from "components/Sidebar/Sidebar";
import illustration from "./illustration.svg";
import logo from "assets/logo.png";
import logo2x from "assets/logo@2x.png";
import { Link } from "react-router-dom";
import { UserAvatarWithMenu } from "components/UserAvatarWithMenu/UserAvatarWithMenu";
import { UserActivityBox } from "components/UserActivityBox/UserActivityBox";

const LoggedIn: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <UserActivityBox />
      <StyledLayoutContainer>
        <StyledSkipLink href="#main-content">Skip to main content</StyledSkipLink>
        <StyledSidebarContainer>
          <Sidebar />
        </StyledSidebarContainer>
        <StyledHeaderContainer>
          <StyledPageHeader id="page-header"></StyledPageHeader>
          <StyledRightSideContainer>
            <div id="page-buttons"></div>
            <UserAvatarWithMenu />
          </StyledRightSideContainer>
        </StyledHeaderContainer>
        <StyledMain id="main-content">
          {children}
          <StyledFooterPortal bg="white" id="footer-portal" />
        </StyledMain>
      </StyledLayoutContainer>
      <div id="global-search" />
    </>
  );
};

const Unauthorized: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledUnauthorizedLayoutContainer>
      <StyledLayoutContent>
        <Link to="/">
          <StyledLogo srcSet={`${logo} 1x, ${logo2x} 2x`} />
        </Link>
        <StyledContent>
          <StyledMain id="main-content">{children}</StyledMain>
          <StyledIllustration>
            <StyledImage alt="illustration" src={illustration} />
          </StyledIllustration>
        </StyledContent>
      </StyledLayoutContent>
    </StyledUnauthorizedLayoutContainer>
  );
};

export const Layout = {
  LoggedIn,
  Unauthorized,
};
