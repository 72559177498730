import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DEAL_TYPE_SELL, TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import { useFirebase } from "hooks/useFirebase";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { BaseTransaction, TransactionStatus, TransactionType } from "interfaces/data";
import _omit from "lodash.omit";
import { mapTransactionTypeToEmailType, useMail } from "hooks/useMail";
import { TransactionConfirm } from "views/Transaction/TransactionConfirm/TransactionConfirm";
import { TransactionFormModal } from "views/Transaction/TransactionFormModal/TransactionFormModal";
import { useCompany } from "hooks/useCompany";

export function CompanyOrderAdd() {
  const { timestamp } = useFirebase();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const [sendEmail, setSendEmail] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState<BaseTransaction>();
  const { save, errors, validate } = useTransaction({
    skipFetching: true,
  });
  const { company } = useCompany({ id });
  const sendConfirmation = useMail();

  const handleClose = useCallback(() => {
    if (location.pathname === `/companies/${id}/add-order`) {
      history.push(`/companies/${id}`);
    } else {
      history.push(`/companies/${id}/transactions`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location]);

  const confirmSave = useCallback(
    (transaction: DeepPartial<BaseTransaction>) => {
      validate(transaction).then((isValid) => {
        if (isValid) {
          setNewTransaction(transaction as BaseTransaction);
          setIsPopupOpen(true);
        }
      });
    },
    [validate]
  );

  const handleSave = useCallback(
    (transaction: BaseTransaction) => {
      setIsSending(true);

      save(transaction)
        .then((transactionId) => {
          setIsSending(false);
          if (transactionId) {
            toast({
              type: "success",
              message: t("transaction:Transaction has been added successfully."),
            });

            if (sendEmail) {
              sendConfirmation(mapTransactionTypeToEmailType(transaction.type), String(transactionId));
            }
            history.push(`/transactions/${transactionId}/`);
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [save, handleClose, toast, t]
  );

  const prepareTransaction = (transaction?: BaseTransaction) => {
    if (!transaction) return;
    const clonedTransaction = { ...transaction };

    const omittedTransactionFields = {
      [TRANSACTION_TYPES.FIXED.value]: ["currencyDate", "expiration"],
      [TRANSACTION_TYPES.ORDER.value]: ["currencyDate"],
      [TRANSACTION_TYPES.SPOT.value]: ["expiration", "start", "end", "initialMargin", "additionalMargin"],
    }[transaction.type];

    return _omit(clonedTransaction, omittedTransactionFields) as BaseTransaction;
  };

  if (!company) return null;

  return (
    <>
      <TransactionFormModal
        errors={errors}
        initialTransaction={{
          company: { id, name: company.name },
          initialMargin: 0,
          agreement: timestamp(),
          systemDate: timestamp(),
          start: timestamp(),
          currencyDate: timestamp(),
          from: {
            currency: "EUR",
          },
          to: {
            currency: "PLN",
          },
          dealType: DEAL_TYPE_SELL,
          type: TRANSACTION_TYPES.ORDER.value as TransactionType,
          status: TRANSACTION_STATUSES.CREATED.value as TransactionStatus,
        }}
        onClose={handleClose}
        onSave={confirmSave}
        orderOnly
        sendEmail={sendEmail}
        sendEmailChange={setSendEmail}
        skipCompany
        title={t("transaction:Order add")}
        transactionType={TRANSACTION_TYPES.ORDER.value}
        type="add"
      />
      <TransactionConfirm
        isDisabled={isSending}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleSave}
        sendEmail={sendEmail}
        transaction={prepareTransaction(newTransaction)}
        transactionType={TRANSACTION_TYPES.ORDER.value}
        type="add"
      />
    </>
  );
}
