import React from "react";
import styled from "@emotion/styled";
import { Spinner as ChakraSpinner } from "@chakra-ui/react";

const StyledSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export function Spinner() {
  return (
    <StyledSpinner>
      <ChakraSpinner size="xl" thickness="4px" />
    </StyledSpinner>
  );
}
