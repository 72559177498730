import React from "react";
import styled from "@emotion/styled";
import { formatNumberToString } from "helpers/formatNumber";
import isPropValid from "@emotion/is-prop-valid";

type Value = React.ReactNode | number | string | null;

interface LabeledTextProps {
  label: string;
  value?: Value;
  mb?: string;
  w?: string;
  fullWidth?: boolean;
  layout?: "horizontal" | "vertical";
  bold?: boolean;
  numberFormat?: boolean;
  breakWords?: boolean;
  isSmallValue?: boolean;
}

const StyledContainer = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  mb: string;
  w?: string;
  fullWidth?: boolean;
  layout: LabeledTextProps["layout"];
}>`
  margin-bottom: ${(props) => props.mb};
  ${(props) => props.w && `width: ${props.w} !important;`}
  ${(props) => props.fullWidth && `grid-column: 1 / 3;`}
  ${(props) =>
    props.layout === "horizontal" &&
    `
			display: flex;
			> div{
				width: 50%;
			}
			> div:first-of-type{
				text-align: right;
			}
			> div:last-of-type{
				padding-left: 20px;
			}	
  	`}
`;

export const StyledLabel = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  bold?: boolean;
  breakWords: boolean;
}>`
  font-weight: ${(props) => (props.bold ? "900" : "600")};
  font-size: ${(props) => (props.bold ? "15px" : "13px")};
  line-height: 110%;
  color: ${(props) => (props.bold ? props.theme.colors["sk-dark"] : props.theme.colors["sk-gray"])};
  margin-bottom: 5px;
  white-space: ${(props) => !props.breakWords && `nowrap`};
`;

export const StyledValue = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{
  bold?: boolean;
  isSmall?: boolean;
}>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "900" : "bold")};
  font-size: ${(props) => (props.bold ? "15px" : props.isSmall ? "9px" : "13px")};
  line-height: 110%;
  word-wrap: break-word;
  white-space: normal;
  color: ${(props) => props.theme.colors["sk-dark"]};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const isValidForNumberFormat = (value: Value): value is string | number => {
  return typeof value === "string" || typeof value === "number";
};

const formatValue = (rawValue: Value, isNumberFormat?: boolean): Value => {
  if (rawValue == null || rawValue === "") return "-";

  if (isNumberFormat && isValidForNumberFormat(rawValue)) {
    return formatNumberToString(rawValue);
  }

  return rawValue;
};

export const LabeledText: React.FunctionComponent<LabeledTextProps> = ({
  label,
  value,
  mb = "0",
  w,
  fullWidth,
  layout = "vertical",
  bold = false,
  numberFormat = false,
  breakWords = true,
  isSmallValue = false,
}) => {
  return (
    <StyledContainer fullWidth={fullWidth} layout={layout} mb={mb} w={w}>
      <StyledLabel bold={bold} breakWords={breakWords}>
        {" "}
        {label}{" "}
      </StyledLabel>
      <StyledValue bold={bold} isSmall={isSmallValue}>
        {formatValue(value, numberFormat)}
      </StyledValue>
    </StyledContainer>
  );
};
