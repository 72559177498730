import { useQuery } from "react-query";
import { search } from "helpers/algolia";

export type UseAlgoliaOptions = {
  indexName: string;
  query: string;
  enabled?: boolean;
};

export default function useAlgolia<TData>({ indexName, query, enabled }: UseAlgoliaOptions) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["algolia", indexName, query],
    queryFn: () => search<TData>({ indexName, query }),
    enabled,
  });

  return { results: data?.hits, isLoading, isError, error };
}
