import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { Margin } from "interfaces/data";
import { MarginWithdrawModal } from "views/Margin/MarginWithdrawModal";

export function MarginWithdrawLocal() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, transactionId, marginId } = useParams<{
    id: string;
    transactionId: string;
    marginId: string;
  }>();
  const toast = useToast();
  const { transaction, marginUpdate } = useTransaction({ id: transactionId });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editedMargin = useMemo(
    () => transaction?.margins?.find((margin) => margin.id === marginId),
    [marginId, transaction]
  );

  const handleOnSaveAction = useCallback(
    (newMargin: Margin) => {
      setIsSending(true);
      marginUpdate(newMargin, true)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("margin:Margin have been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marginUpdate, history, id, editedMargin]
  );

  if (!editedMargin) return null;

  return (
    <MarginWithdrawModal
      isDisabled={isSending}
      margin={editedMargin}
      onClose={handleClose}
      onSave={handleOnSaveAction}
    />
  );
}
