import { useCallback, useEffect, useState } from "react";
import { useAuth } from "./useAuth";
import { UseSearch } from "interfaces/hooks";
import { usePrevious } from "@chakra-ui/react";
import { useFirebase } from "./useFirebase";
import { UserUnauthorized } from "interfaces/data";

type TResults<T> = {
  results: Array<T>;
  count: number;
  success?: boolean;
  message?: UserUnauthorized.UNATHORIZED;
};

type TSearchStateData<T> = {
  loading: boolean;
  data: T[];
  totalCount: number;
  pageCount: number;
  error: null | string;
};

export const useSearch = <T>({
  collection,
  phrase,
  fields,
  extraOptions,
  filtersOr,
  filtersAnd,
  filtersAndBetween,
  filtersAndCustom,
  orderBy,
  orderDirection = "asc",
  offset,
  limit,
  skipAutoUpdate = false,
  isLazy = false,
  type = "include",
}: UseSearch) => {
  const [searchData, setSearchData] = useState<TSearchStateData<T>>({
    loading: true,
    data: [],
    totalCount: 0,
    pageCount: 0,
    error: null,
  });

  const { token, logout } = useAuth();
  const { db } = useFirebase();

  const stalePhrase = usePrevious(phrase);
  // Create the abort controller which will cancel the request
  // and prevent setState if the useEffect cleanup before request ends
  // to prevent race condition
  const abortController = new AbortController();

  const fetchData = useCallback(async () => {
    searchData.loading = true;
    const _offset = stalePhrase === phrase ? offset : 0;
    const _extraOptions = extraOptions ? `&${extraOptions}` : "";

    const _filtersOr = filtersOr?.map((f) => `&filtersOr=${f}`).join("") || "";
    const _filtersAnd = filtersAnd?.map((f) => `&filtersAnd=${f}`).join("") || "";
    const _filterAndBetween = filtersAndBetween?.map((f) => `&filtersAndBetween=${f}`).join("") || "";
    const _filtersAndCustom = filtersAndCustom?.map((f) => `&filtersAndCustom=${f}`).join("") || "";

    try {
      const response: Response = await fetch(
        `${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/search/${collection}?phrase=${phrase}&type=${type}&fields=${fields}&orderBy=${orderBy}&orderDirection=${orderDirection}&offset=${_offset}&limit=${limit}${_filtersOr}${_filtersAnd}${_filterAndBetween}${_filtersAndCustom}${_extraOptions}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: abortController.signal,
        }
      );
      // Logout user if unauthorized
      if (response.status === 401) {
        return await logout();
      }

      const responseJson: TResults<T> = await response.json();

      if (abortController.signal.aborted) {
        return;
      }

      setSearchData({
        loading: false,
        data: responseJson.results,
        totalCount: responseJson.count,
        pageCount: Math.ceil(responseJson.count / limit),
        error: null,
      });
    } catch (error) {
      if (error instanceof Error) {
        // Cancelled fetch request issues an error, so it it's it, we don't set the state
        if (error.name === "AbortError") {
          return;
        }
        setSearchData({
          error: error.message,
          loading: false,
          data: [],
          totalCount: 0,
          pageCount: 0,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collection,
    type,
    fields,
    limit,
    offset,
    orderBy,
    phrase,
    extraOptions,
    orderDirection,
    filtersOr,
    filtersAnd,
    filtersAndBetween,
    filtersAndCustom,
  ]);

  const remove = useCallback(
    async (id: string) => {
      const dbCollection = db.collection(collection);

      return dbCollection
        .doc(id)
        .delete()
        .then(() => {
          fetchData();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData]
  );

  // unless skipAutoUpdate is set to true subscribe to firestore, when collection is changed
  // refetch all data from the endpoint with 500ms delay (to make sure that they are cached by BE function)
  useEffect(() => {
    if (isLazy) return undefined;
    if (skipAutoUpdate) {
      fetchData();
      return () => {
        abortController.abort();
      };
    }

    fetchData();
    const POLLING_INTERVAL = 10000;
    const intervalId = setTimeout(fetchData, POLLING_INTERVAL);

    return () => {
      abortController.abort();
      clearTimeout(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  return {
    data: searchData.data,
    totalCount: searchData.totalCount,
    pageCount: searchData.pageCount,
    loading: searchData.loading,
    error: searchData.error,
    remove,
    refetch: fetchData,
  };
};
