import React, { useCallback, useEffect, useMemo, useState } from "react";
import _set from "lodash.set";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { Company, MarginCall, Transaction } from "interfaces/data";
import styled from "@emotion/styled";
import { Input } from "components/Input/Input";
import { DEAL_TYPE_BUY, DEAL_TYPE_SELL, determineTransactionCurrency, getQuantityLeft } from "helpers/transaction";
import { useNbpRate } from "hooks/useNbpRate";
import Decimal from "decimal.js";
import { formatNumber, formatNumberToString } from "helpers/formatNumber";
import { SearchBox } from "components/SearchBox/SearchBox";
import { transformTimestamp } from "helpers/date";
import { useTransaction } from "hooks/useTransaction";
import { TextareaWithLabel } from "components/TextareaWithLabel/TextareaWithLabel";
import { Box, useDisclosure } from "@chakra-ui/react";
import { getMarginCallBalance } from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import { OptionalIDMarginCallSchema, ZodOptionalIDMarginCall } from "schemas/marginCall";

interface MarginCallFormModalProps {
  type: "add" | "add-additional" | "edit" | "edit-additional";
  title: string;
  initialMarginCall: Partial<MarginCall>;
  previousCallRate?: number;
  initialTransaction?: Transaction;
  initialCompany?: Company;
  errors: any;
  onSave: (marginCall: ZodOptionalIDMarginCall) => void;
  onClose: () => void;
  isDisabled?: boolean;
  previousMarginCall?: MarginCall;
}

const StyledFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  display: flex;
  margin-top: 13px;
  justify-content: flex-end;
  font-weight: 600;
  line-height: 110%;
`;

const StyledNoRiskInfo = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`;

const StyledSearchBoxContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledCompaniesSearchBoxContainer = styled.div`
  margin-bottom: 20px;
  .search-box-pane {
    border-radius: 16px !important;
  }
`;

const StyledTransactionHeaderContainer = styled.div`
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledTransactionRowContainer = styled.div`
  display: flex;
`;

const StyledTransactionHeaderCell = styled.div<{ width: string }>`
  padding: 15px 10px;
  width: ${(props) => props.width};
  font-weight: bold;
  border-bottom: 2px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;

const StyledTransactionRowCell = styled.div<{ width: string }>`
  padding: 0 10px;
  width: ${(props) => props.width};
  text-align: left;
  font-weight: normal;
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;

const TransactionRowHeader = (
  <StyledTransactionHeaderContainer>
    <StyledTransactionHeaderCell width="78px">ID</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="100px">Trans date</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="70px">Type</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="120px">Base Qty</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="120px">Currency Pair</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="120px">Settlement Qty</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="120px">Remaining Qty</StyledTransactionHeaderCell>
    <StyledTransactionHeaderCell width="100px">Trans. end date</StyledTransactionHeaderCell>
  </StyledTransactionHeaderContainer>
);

const TransactionRow = (transaction: Transaction) => {
  const isWithGMC = Boolean(transaction?.marginCalls?.filter((margin) => margin.isGMC !== "yes"));
  return !isWithGMC ? (
    <StyledTransactionRowContainer>
      <StyledTransactionRowCell width="78px">{transaction.number}</StyledTransactionRowCell>
      <StyledTransactionRowCell width="100px">{transformTimestamp(transaction.agreement)}</StyledTransactionRowCell>
      <StyledTransactionRowCell width="70px">{transaction.dealType}</StyledTransactionRowCell>
      <StyledTransactionRowCell width="120px">
        {formatNumberToString(transaction.from.quantity)}
      </StyledTransactionRowCell>
      <StyledTransactionRowCell width="120px">
        {transaction.from.currency} / {transaction.to.currency}
      </StyledTransactionRowCell>
      <StyledTransactionRowCell width="120px">
        {formatNumberToString(transaction.from.quantity - getQuantityLeft(transaction))}
      </StyledTransactionRowCell>
      <StyledTransactionRowCell width="120px">
        {formatNumberToString(getQuantityLeft(transaction))}
      </StyledTransactionRowCell>
      <StyledTransactionRowCell width="100px">{transformTimestamp(transaction.end)}</StyledTransactionRowCell>
    </StyledTransactionRowContainer>
  ) : null;
};

export const MarginCallFormModal: React.FC<MarginCallFormModalProps> = ({
  type,
  title,
  initialMarginCall,
  previousCallRate,
  initialTransaction,
  initialCompany,
  errors,
  onSave,
  onClose,
  isDisabled = false,
  previousMarginCall,
}) => {
  const { t } = useTranslation();
  const { rates: nbpRates } = useNbpRates();
  const [marginCall, setMarginCall] = useState(initialMarginCall);
  const [clientRate, setClientRate] = useState(0);
  const [variation, setVariation] = useState(0);
  const [variationC, setVariationC] = useState(0);
  const [company, setCompany] = useState<Company | undefined>(initialCompany);
  const [transaction, setTransaction] = useState(initialTransaction);
  const { fetch: fetchTransaction } = useTransaction({
    skipFetching: true,
    id: transaction?.id,
  });
  const [marginCallQuantity, setMarginCallQuantity] = useState(0);
  const [noRisk, setNoRisk] = useState(false);
  const { rateInfo } = useNbpRate({
    from: transaction?.from.currency,
    to: transaction?.to.currency,
  });

  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();

  const marginCallCurrency = useMemo(
    () => (transaction?.dealType === DEAL_TYPE_SELL ? transaction?.from.currency : transaction?.to.currency),
    [transaction]
  );

  const marginBalance =
    nbpRates && previousMarginCall
      ? getMarginCallBalance(previousMarginCall, nbpRates, marginCallCurrency || "EUR")
      : 0;

  useEffect(() => {
    if (type === "edit" || type === "edit-additional") return;

    if (rateInfo && rateInfo.rate !== undefined) {
      setMarginCall((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        _set(newEditedData, "callRate", Number(rateInfo.rate));
        return newEditedData;
      });
    }
  }, [rateInfo, type]);

  useEffect(() => {
    if (type === "edit" || type === "edit-additional") return;

    if (transaction?.id && !initialTransaction) {
      fetchTransaction(transaction.id);
      setMarginCall((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        _set(newEditedData, "transQuantity", getQuantityLeft(transaction));
        return newEditedData;
      });
    }
  }, [fetchTransaction, initialTransaction, transaction, type]);

  const setNoRiskValues = useCallback(() => {
    setMarginCallQuantity(0);
    setMarginCall((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, "quantity", 0);
      return newEditedData;
    });
    setVariationC(0);
    setVariation(0);
    setNoRisk(true);
  }, []);

  useEffect(() => {
    if (!transaction || !rateInfo?.rate) return;

    if (transaction.dealType === DEAL_TYPE_BUY) {
      if (Number(clientRate) <= Number(marginCall.callRate)) {
        // Once Client Rate is equal or lower than the Margin Call Rate, the Margin Call Qty should equal 0.
        setNoRiskValues();
      } else {
        const newMarginCallQuantity = formatNumber(
          Math.abs(Number(marginCall.callRate) - Number(clientRate)) * Number(marginCall.transQuantity)
        );
        setMarginCallQuantity(newMarginCallQuantity);
        setMarginCall((oldEditedData) => {
          const newEditedData = { ...oldEditedData };
          _set(newEditedData, "quantity", newMarginCallQuantity);
          return newEditedData;
        });
        if (type === "add-additional" || type === "edit-additional") {
          setVariationC(formatNumber(Number(marginCall.callRate) / Number(clientRate) - 1));
          setVariation(formatNumber(Number(clientRate) / Number(rateInfo.rate) - 1));
        } else {
          setVariationC(formatNumber(Number(marginCall.callRate) / Number(clientRate) - 1));
          setVariation(formatNumber(Number(marginCall.callRate) / Number(rateInfo.rate) - 1));
        }
        setNoRisk(false);
      }
    }

    if (transaction.dealType === DEAL_TYPE_SELL) {
      if (Number(clientRate) >= Number(marginCall.callRate)) {
        // Once Client Rate is equal or higher than the Margin Call Rate, the Margin Call Qty should equal 0.
        setNoRiskValues();
      } else {
        const newMarginCallQuantity = formatNumber(
          (Math.abs(Number(marginCall.callRate) - Number(clientRate)) * Number(marginCall.transQuantity)) /
            Number(marginCall.callRate)
        );
        setMarginCallQuantity(newMarginCallQuantity);
        setMarginCall((oldEditedData) => {
          const newEditedData = { ...oldEditedData };
          _set(newEditedData, "quantity", newMarginCallQuantity);
          return newEditedData;
        });
        if (type === "add-additional" || type === "edit-additional") {
          setVariationC(formatNumber(Number(clientRate) / Number(marginCall.callRate) - 1));
          setVariation(formatNumber(Number(clientRate) / Number(rateInfo.rate) - 1));
        } else {
          setVariationC(formatNumber(Number(marginCall.callRate) / Number(clientRate) - 1));
          setVariation(formatNumber(Number(rateInfo.rate) / Number(clientRate) - 1));
        }
        setNoRisk(false);
      }
    }
  }, [setNoRiskValues, type, marginCall.callRate, marginCall.transQuantity, clientRate, rateInfo, transaction]);

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setMarginCall((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      if (e.target.type === "number") {
        _set(newEditedData, e.target.name, Number(e.target.value));
      } else {
        _set(newEditedData, e.target.name, e.target.value);
      }
      return newEditedData;
    });
  }, []);

  useEffect(() => {
    if (transaction) {
      setClientRate(
        Number(type === "add-additional" || type === "edit-additional" ? previousCallRate : transaction.clientRate)
      );
    }
  }, [transaction, previousCallRate, type]);

  const handleConfirm = () => {
    const validMarginCall = OptionalIDMarginCallSchema.parse(marginCall);
    onSave(validMarginCall);
  };

  return (
    <>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isDisabled={isDisabled || noRisk || !transaction}
        isOpen
        justifyButtons="flex-end"
        minWidth={initialTransaction ? 600 : 930}
        onClose={onClose}
        onConfirm={onConfirmationOpen}
        title={title}
      >
        {!initialTransaction && !initialCompany && (
          <StyledCompaniesSearchBoxContainer>
            <SearchBox
              collection="companies"
              error={errors?.["company.name"]}
              fields="name"
              item={(item: Company) => `${item.name}`}
              label={t("transaction:Select existing company")}
              limit={1000000}
              onSelect={(company: Company) => setCompany(company)}
              orderBy="name"
              persist={(item: Company) => item.name}
              resultsHeader={<></>}
              withError={!!errors?.["company.name"]}
            />
          </StyledCompaniesSearchBoxContainer>
        )}
        {!initialTransaction && company && (
          <StyledSearchBoxContainer>
            <SearchBox
              collection="transactions"
              error={errors?.["company.name"]}
              fields="number,company.name,to.currency,from.currency"
              filtersAnd={[`status:created,partiallySettled`, `company.id:${company?.id}`]}
              filtersOr={["type:FR"]}
              item={TransactionRow}
              label={t("transaction:Select transaction")}
              limit={1000000}
              onSelect={(transaction: Transaction) => setTransaction(transaction)}
              orderBy="company.name"
              persist={(item: Transaction) => String(item.number)}
              resultsHeader={TransactionRowHeader}
              withError={!!errors?.["company.name"]}
            />
          </StyledSearchBoxContainer>
        )}
        {transaction && (
          <>
            <StyledFieldsLayout>
              <StyledLabel>{t("marginCall:Remaining Quantity")}</StyledLabel>
              <Input
                error={errors?.["transQty.quantity"]}
                formatNumberOnBlur
                id="transQty.quantity"
                isDisabled
                name="transQty.quantity"
                rightAddon={transaction.from.currency}
                type="number"
                value={marginCall.transQuantity}
                withError={!!errors?.["transQty.quantity"]}
              />
              <StyledLabel>
                {t(
                  `marginCall:${
                    type === "add-additional" || type === "edit-additional"
                      ? "Previous Margin Call Rate"
                      : "Client Rate"
                  }`
                )}
              </StyledLabel>
              <Input
                formatNumberOnBlur
                formatNumberPrecision={4}
                id="clientRate"
                isDisabled
                name="clientRate"
                type="number"
                value={clientRate}
              />
              <StyledLabel>
                {t(`marginCall:NBP Exchange Rate(V {{v}}%)`, {
                  v: new Decimal(variation * 100).toFixed(4),
                })}
              </StyledLabel>
              <Input id="NbpRate" isDisabled name="NbpRate" type="number" value={formatNumber(rateInfo?.rate, 4)} />
              <StyledLabel>
                {t(`marginCall:Margin Call Rate (Vc {{vc}}%)`, {
                  vc: new Decimal(variationC * 100).toFixed(4),
                })}
              </StyledLabel>
              <Input
                error={errors?.["callRate"]}
                formatNumberOnBlur
                formatNumberPrecision={4}
                id="callRate"
                name="callRate"
                onChange={handleUpdate}
                type="number"
                value={marginCall.callRate}
                withError={!!errors?.["callRate"]}
              />
              <StyledLabel>{t("marginCall:Margin Call Quantity")}</StyledLabel>
              <Input
                error={errors?.["marginCallQuantity"]}
                formatNumberOnBlur
                id="marginCallQuantity"
                isDisabled
                name="marginCallQuantity"
                rightAddon={marginCallCurrency}
                type="number"
                value={marginCallQuantity}
                withError={!!errors?.["marginCallQuantity"]}
              />
              <StyledLabel>{t("marginCall:Margin Balance (V)")}</StyledLabel>
              <Input
                formatNumberOnBlur
                id="marginBalance"
                isDisabled
                name="marginBalance"
                rightAddon={determineTransactionCurrency(transaction)}
                type="number"
                value={Number(marginBalance)}
              />
              <StyledLabel>{t("marginCall:Margin Call Quantity (mail)")}</StyledLabel>
              <Input
                error={errors?.["quantity"]}
                formatNumberOnBlur
                id="quantity"
                name="quantity"
                onChange={handleUpdate}
                rightAddon={marginCallCurrency}
                type="number"
                value={marginCall.quantity}
                withError={!!errors?.["quantity"]}
              />
            </StyledFieldsLayout>
            <Box mt="10px">
              <TextareaWithLabel
                id="comment"
                label={t("marginCall:Comment")}
                labelProps={{
                  paddingRight: "10px",
                }}
                name="comment"
                onChange={handleUpdate}
                value={marginCall.comment}
              />
            </Box>
          </>
        )}
        {noRisk && (
          <StyledNoRiskInfo>
            {t("marginCall:There is no risk on the transaction. See Last Call Rate.")}
          </StyledNoRiskInfo>
        )}
      </Modal>
      {/* CONFIRMATION MODAL */}
      <Modal
        confirmText={t("Yes")}
        design="primary"
        isChild
        isDisabled={isDisabled}
        isOpen={isConfirmationOpen}
        minWidth={420}
        onClose={onConfirmationClose}
        onConfirm={handleConfirm}
        title={t("marginCall:Send Margin Call")}
      >
        {t("marginCall:Are you sure you want to send Margin Call?")}
      </Modal>
    </>
  );
};
