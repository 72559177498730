import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CalendarIcon = createIcon({
  displayName: "CalendarIcon",
  path: (
    <g fill="none">
      <path
        d="M3 .5v2M10 .5v2M1 5.5h11M11 2.5H2a1 1 0 00-1 1v7a1 1 0 001 1h9a1 1 0 001-1v-7a1 1 0 00-1-1z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: "0 0 13 12",
});
