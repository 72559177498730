import React from "react";
import { BaseContact } from "../../../interfaces/data";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";

export function EditContactInfo({ data, handleChange, errors }: EditViewProps<BaseContact>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.firstName}
        id="firstName"
        label={t("contact:Name")}
        name="firstName"
        onChange={handleChange}
        type="text"
        value={data.firstName}
        withError={!!errors?.firstName}
      />
      <Input
        error={errors?.lastName}
        id="lastName"
        label={t("contact:Surname")}
        name="lastName"
        onChange={handleChange}
        type="text"
        value={data.lastName}
        withError={!!errors?.lastName}
      />
      <Input
        error={errors?.email}
        id="email"
        label={t("contact:Email")}
        name="email"
        onChange={handleChange}
        type="text"
        value={data.email}
        withError={!!errors?.email}
      />
      <Input
        error={errors?.phone}
        id="phone"
        label={t("contact:Phone")}
        name="phone"
        onChange={handleChange}
        type="text"
        value={data.phone}
        withError={!!errors?.phone}
      />
    </TwoColumnsLayout>
  );
}
