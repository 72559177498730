import React, { useCallback, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { Modal } from "components/Modal/Modal";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";

export const CompanyDeactivate: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { company, loading, deactivate } = useCompany({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}/preview`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleConfirm = useCallback(() => {
    setIsSending(true);
    deactivate(id, company)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("company:Company has been deactivated successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`company:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
  }, [deactivate, handleClose, id, t, toast, company]);

  if (loading) {
    return <Spinner />;
  }

  if (!company) {
    return <>{t("company:Company not found")}</>;
  }

  return (
    <Modal
      confirmText={t("company:Deactivate")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={`${t("company:Deactivate {{name}} company", {
        name: company.name,
      })} `}
    >
      <Box mb={"20px"}>
        <Box fontSize="18px" fontWeight="bold" mb={"20px"}>
          {t("company:Once the company is deactivated")},
        </Box>
        <Box>
          <Box as="p" mb={"20px"}>
            {t("company:you will not be able to run any transactions")}
          </Box>
          <Box as="p" mb={"20px"}>
            {t("company:you will not be able to edit company’s data")}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
