import React, { useState } from "react";
import { MarginInfo } from "../GlobalMarginsMigration";
import { Button } from "components/Button/Button";
import { MigrateModal } from "./MigrateModal";
import { Margin } from "interfaces/data";
import { useMarginMigration } from "hooks/useMarginMigration";
import _omit from "lodash.omit";
import { useHistory } from "react-router-dom";

export function MigrateCell(arg: any, refreshData: () => Promise<void>) {
  const margin = arg.row.original as MarginInfo;
  const [isOpen, setIsOpen] = useState(false);
  const { migrateMargin } = useMarginMigration();
  const history = useHistory();

  const handleConfirm = async (toMargin: Margin) => {
    await migrateMargin({
      fromMargin: _omit(margin, "transaction"),
      toMargin,
      transactionId: margin.transaction.id,
      companyId: margin.transaction.company.id,
    });
    setIsOpen(false);
    await refreshData();
    history.push(`/companies/${margin.transaction.company.id}#global-margins`);
  };

  return (
    <>
      <Button design="danger" onClick={() => setIsOpen(true)}>
        migrate
      </Button>

      {isOpen && (
        <MigrateModal isOpen={isOpen} margin={margin} onClose={() => setIsOpen(false)} onConfirm={handleConfirm} />
      )}
    </>
  );
}
