import styled from "@emotion/styled";
import chroma from "chroma-js";

export const StyledToastContainer = styled.div<{ color: string }>`
  color: white;
  background-color: ${(props) => String(chroma(props.color).alpha(0.8))};
  font-size: ${(props) => props.theme.fontSizes.sm};
  border: 1px solid ${(props) => String(chroma(props.color).alpha(1))};
  padding: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 100%;
  flex-basis: 100%;

  svg {
    color: white;
  }

  &:first-of-type {
    margin-top: 50px;
  }
`;

export const StyledToastContent = styled.div`
  flex: 1 1 auto;
  padding: 0 10px;
  text-align: left;
`;
