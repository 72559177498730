import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { DashboardTable } from "./DashboardTable";
import theme from "theme/theme";
import { useTransactions } from "hooks/useTransactions";
import { SpecialFilterTypes } from "../Transactions/Transactions";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Heading } from "components/Heading/Heading";
import { UseSearch } from "interfaces/hooks";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { activeHedges, sumLQtyOfHedges } from "helpers/hedge";
import {
  determineTransactionCurrency,
  getMarginQuantity,
  getQuantityLeft,
  isInitialMarginPaid,
} from "helpers/transaction";
import { Link } from "react-router-dom";
import { RefreshIcon } from "theme/icons";

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  @media (min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
  font-size: 12px;
  margin-left: 20px;
`;

const partiallyHedged = ["partially-hedged" as SpecialFilterTypes];
const transactionDeadline = ["transaction-deadline" as SpecialFilterTypes];
const risk = ["risk" as SpecialFilterTypes];
const hedgeDeadline = ["hedge-deadline" as SpecialFilterTypes];
const fixedRate = ["type:FR", "status:created,partiallySettled"];

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  const transactionOptions = {
    phrase: "",
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc" as UseSearch["orderDirection"],
    offset: 0,
    limit: 999999,
  };

  const { transactions: partiallyHedgedTransactions, loading: partiallyHedgedLoading } = useTransactions({
    ...transactionOptions,
    filtersAndCustom: partiallyHedged,
  });

  const { transactions: deadlineTransactions, loading: deadlineLoading } = useTransactions({
    ...transactionOptions,
    filtersAndCustom: transactionDeadline,
  });

  const { transactions: riskTransactions, loading: riskLoading } = useTransactions({
    ...transactionOptions,
    filtersAndCustom: risk,
  });

  const { transactions: fixedRateTransactions, loading: fixedRateLoading } = useTransactions({
    ...transactionOptions,
    filtersAnd: fixedRate,
  });

  const riskTransactionsWithMarginCallValues = riskTransactions.map((riskTransaction) => ({
    ...riskTransaction,
    marginCallValue: `${(Number(riskTransaction.leftQuantity) || 0) * 0.025} ${determineTransactionCurrency(
      riskTransaction
    )}`,
  }));

  const initialMarginUnpaidTransactions = fixedRateLoading
    ? []
    : fixedRateTransactions
        .filter((transaction) => !isInitialMarginPaid(transaction))
        .map((transaction) => ({
          ...transaction,
          initialMarginValue: `${getMarginQuantity(transaction)} ${determineTransactionCurrency(transaction)}`,
        }));

  const { transactions: hedgeDeadlineTransactions, loading: hedgeDeadlineLoading } = useTransactions({
    ...transactionOptions,
    filtersAndCustom: hedgeDeadline,
  });

  const deadlineTransactionsDates = useMemo(
    () =>
      deadlineTransactions.map((transaction) =>
        transaction.type === "FR" ? transaction.end : transaction.currencyDate
      ),
    [deadlineTransactions]
  );

  const hedgeDeadlineDates = useMemo(
    () =>
      hedgeDeadlineTransactions.map((transaction) => {
        const soonestHedge = activeHedges(transaction.hedges || []).sort(
          (a, b) => getMilisecondsFromTimestamp(a.end) - getMilisecondsFromTimestamp(b.end)
        )[0];
        return soonestHedge ? soonestHedge.end : "";
      }),
    [hedgeDeadlineTransactions]
  );

  const filterPartiallyHedgedTransactions = useMemo(
    () =>
      partiallyHedgedTransactions.filter((t) => {
        const q = getQuantityLeft(t);
        const h = sumLQtyOfHedges(t.hedges?.filter((hedge) => Number(hedge.id.split("-")[0]) === t.number));
        return Number(q.toFixed(2)) !== Number(h.toFixed(2));
      }),
    [partiallyHedgedTransactions]
  );

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("transaction:Dashboard")}</Heading>
      </PageHeader>

      <StyledContainer>
        <DashboardTable
          color={theme.colors["sk-purple"]}
          isLoading={partiallyHedgedLoading}
          title={t("Unhedged transactions")}
          transactions={filterPartiallyHedgedTransactions}
          type="unhedged"
        />
        <DashboardTable
          color={theme.colors["sk-yellow"]}
          isLoading={deadlineLoading}
          lastColumn={deadlineTransactionsDates}
          title={t("Transactions soon to end")}
          transactions={deadlineTransactions}
          type="soonToEnd"
        />
        <DashboardTable
          color={theme.colors["sk-red"]}
          isLoading={riskLoading}
          title={t("Margin Call alert")}
          transactions={riskTransactionsWithMarginCallValues}
          type="marginCall"
        />
        <DashboardTable
          color={theme.colors["sk-blue"]}
          customAction={
            <StyledLink to="mass-hedge-roll">
              <RefreshIcon mr="10px" /> {t("Mass Roll")}
            </StyledLink>
          }
          isLoading={hedgeDeadlineLoading}
          lastColumn={hedgeDeadlineDates}
          showLqt
          title={t("Roll hedge")}
          transactions={hedgeDeadlineTransactions}
          type="rollHedge"
        />
        <DashboardTable
          color={theme.colors["sk-green"]}
          isLoading={fixedRateLoading}
          title={t("Initial Margins")}
          transactions={initialMarginUnpaidTransactions}
          type="initialMargin"
        />
      </StyledContainer>
    </>
  );
};
