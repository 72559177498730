import React from "react";
import { Link as BaseLink } from "react-router-dom";
import styled from "@emotion/styled";

const List = styled.ul`
  list-style: none;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, auto);
  max-width: 20rem;
  margin-bottom: 2rem;
  & > li {
    width: auto;
  }
`;

const Link = styled(BaseLink)<{ isActive?: boolean }>`
  color: ${(props) => props.theme.colors["sk-blue"]};
  padding-bottom: 0.3rem;
  border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors["sk-purple"] : "transparent")};
  &:hover {
    color: ${(props) => props.theme.colors["sk-purple"]};
  }
`;

export const DataFixerNav = () => {
  const BASE_URL = "/data-fixer";
  const isActive = (matchUrl: string, location: Location) => {
    if (!matchUrl) {
      return false;
    }
    return matchUrl === location.pathname;
  };

  const links = [
    { name: "Transactions", url: `${BASE_URL}/transactions` },
    { name: "Companies", url: `${BASE_URL}/companies` },
    { name: "Contacts", url: `${BASE_URL}/contacts` },
  ];

  return (
    <nav>
      <List>
        {links.map((link) => (
          <li key={link.url}>
            <Link isActive={isActive(link.url, window.location)} to={link.url}>
              {link.name}
            </Link>
          </li>
        ))}
      </List>
    </nav>
  );
};
