import { Company, Currency, Margin, MarginCall, Transaction } from "interfaces/data";
import { CURRENCIES } from "./options";
import _cloneDeep from "lodash.clonedeep";
import { firestore } from "firebase";
import { convertToTimestamp, timestampFromMap } from "./timestamp";
import { Rates } from "schemas/rates";

function generateCurrencyKeyObject() {
  return CURRENCIES.map((currency) => currency.value).reduce(
    // eslint-disable-next-line no-sequences
    (acc, curr) => ((acc[curr] = { currency: curr, sum: 0 }), acc),
    {} as any
  );
}

export function getSumsOfCurrenciesInMarginCall(marginCall: MarginCall): Array<{
  currency: Currency;
  sum: number;
}> {
  const marginCallsValuesByCurrency = generateCurrencyKeyObject();

  marginCall.margins?.forEach((margin) => {
    marginCallsValuesByCurrency[margin.currency].sum =
      marginCallsValuesByCurrency[margin.currency].sum + Number(margin.quantity);
  });

  return Object.values(marginCallsValuesByCurrency).filter((mv: any) => mv.sum !== 0) as Array<{
    currency: Currency;
    sum: number;
  }>;
}

export function getMarginCallBalance(marginCall: MarginCall, nbpRates: Rates, currency?: string) {
  const coveredValueInCurrencies = getSumsOfCurrenciesInMarginCall(marginCall);

  const balanceInPln = coveredValueInCurrencies.reduce(
    (sum, value) => sum + Number(value.sum) * nbpRates.rates[value.currency],
    0
  );

  if (!currency || currency === "PLN") {
    return balanceInPln;
  } else {
    return balanceInPln / Number(nbpRates.rates[currency]);
  }
}

// if quantity is > 0, this function will assign margin value to margin call
// if quantity is < 0, this function will move value from margin call back to margin and generate withdraw operation
export function moveQuantityFromMarginToMarginCall({
  transaction,
  company,
  marginCallId,
  marginId,
  quantity,
  currency,
  nbpRates,
  timestamp,
}: {
  transaction: Transaction;
  company: Company;
  marginCallId: string;
  marginId: string;
  quantity: number;
  currency: Currency;
  nbpRates: any;
  timestamp?: () => firestore.Timestamp;
}) {
  const newTransaction = _cloneDeep(transaction);
  const newCompany = _cloneDeep(company);

  // Update transaction margins
  const transactionMargins = newTransaction?.margins || [];
  const updatedMarginIndex = transactionMargins.findIndex((margin) => margin.id === marginId);
  if (updatedMarginIndex !== -1) {
    // update local margin
    const newMargin = _cloneDeep(transactionMargins[updatedMarginIndex]);
    if (newMargin && newMargin.left) {
      // if this is withdraw operation
      if (quantity < 0 && timestamp) {
        const newOperations = _cloneDeep(newMargin?.operations) || [];
        newOperations.unshift({
          id: (newOperations.length === 0 ? 0 : Math.max(...newOperations.map((operation) => operation.id))) + 1,
          type: "WITHDRAW",
          value: {
            quantity: -quantity,
            currency,
          },
          oldMarginValue: {
            quantity: newMargin.left.quantity,
            currency: newMargin.left.currency,
          },
          newMarginValue: {
            quantity: newMargin.left.quantity,
            currency: newMargin.left.currency,
          },
          date: timestamp(),
        });
        newMargin.operations = newOperations;
      }

      newMargin.marginCallId = marginCallId;
      transactionMargins[updatedMarginIndex] = newMargin;
    }
    newTransaction.margins = transactionMargins;
  }

  // Update margin call
  const transactionMarginCalls = newTransaction?.marginCalls || [];
  const updatedMarginCallIndex = transactionMarginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
  if (updatedMarginCallIndex !== -1) {
    const newMarginCall = _cloneDeep(transactionMarginCalls[updatedMarginCallIndex]);
    if (newMarginCall) {
      const marginCallMargins = newMarginCall.margins || [];
      const updatedMarginIndex = marginCallMargins.findIndex((margin) => margin.marginId === marginId);
      if (updatedMarginIndex !== -1) {
        // update existing VM info
        marginCallMargins[updatedMarginIndex].quantity = marginCallMargins[updatedMarginIndex].quantity + quantity;
      } else {
        // add new VM info
        const newVMinfo: any = {
          marginId: marginId,
          quantity: quantity,
          currency: currency,
        };
        if (marginId.startsWith("g-")) {
          newVMinfo.isGlobal = true;
        }
        marginCallMargins.push(newVMinfo);
      }
      newMarginCall.margins = marginCallMargins.filter((mc) => Number(mc.quantity) !== 0);
    }

    // check status of margin call, if fully paid - set isPaid flat to true
    const currentBalance = getMarginCallBalance(newMarginCall, nbpRates, currency);
    if (currentBalance >= newMarginCall.quantity) {
      newMarginCall.isPaid = true;
    }

    // if balance is now 0, and flag isPaid is true, set isClosed to "yes"
    if (currentBalance === 0 && newMarginCall.isPaid) {
      newMarginCall.isClosed = "yes";
    }

    transactionMarginCalls[updatedMarginCallIndex] = newMarginCall;
    newTransaction.marginCalls = transactionMarginCalls;
  }

  return { newTransaction, newCompany };
}

// Remove quantity from global margin, add it to the local margin, assign local margin to the margin call
export function moveQuantityFromGlobalMarginToLocalAndToMarginCall({
  transaction,
  company,
  marginCallId,
  globalMarginId,
  marginId,
  quantity,
  currency,
  nbpRates,
}: {
  transaction: Transaction;
  company: Company;
  marginCallId: string;
  globalMarginId: string;
  marginId: string;
  quantity: number;
  currency: Currency;
  nbpRates: any;
  timestamp?: () => firestore.Timestamp;
}) {
  const newTransaction = _cloneDeep(transaction);
  const newCompany = _cloneDeep(company);

  // Remove quantity from global margin
  const globalMargins = newCompany.globalMargins || [];
  const globalMarginIndex = globalMargins.findIndex((margin) => margin.id === globalMarginId);
  if (globalMarginIndex !== -1) {
    const newMargin = _cloneDeep(globalMargins[globalMarginIndex]);
    if (newMargin) {
      newMargin.from.quantity = newMargin.from.quantity - quantity;
      newMargin.left.quantity = newMargin.left.quantity - quantity;
      globalMargins[globalMarginIndex] = newMargin;
    }
    newCompany.globalMargins = globalMargins;
  }

  // Add it to the newly created local margin
  const _transactionMargins = newTransaction?.margins || [];
  const _updatedMarginIndex = _transactionMargins.findIndex((margin) => margin.id === marginId);
  if (_updatedMarginIndex !== -1) {
    const newMargin = _cloneDeep(_transactionMargins[_updatedMarginIndex]);
    if (newMargin) {
      newMargin.from.quantity = newMargin.from.quantity + quantity;
      newMargin.to.quantity = newMargin.to.quantity + quantity;
      newMargin.left.quantity = 0;
      newMargin.createdFromMovement = true;
      if (Number(newMargin.left.quantity) !== Number(newMargin.from.quantity)) {
        newMargin.marginCallId = marginCallId;
      } else {
        newMargin.marginCallId = "";
      }
      _transactionMargins[_updatedMarginIndex] = newMargin;
    }
    newTransaction.margins = _transactionMargins;
  }

  // Update margin call
  const transactionMarginCalls = newTransaction?.marginCalls || [];
  const updatedMarginCallIndex = transactionMarginCalls.findIndex((marginCall) => marginCall.id === marginCallId);
  if (updatedMarginCallIndex !== -1) {
    const newMarginCall = _cloneDeep(transactionMarginCalls[updatedMarginCallIndex]);
    if (newMarginCall) {
      const marginCallMargins = newMarginCall.margins || [];
      const updatedMarginIndex = marginCallMargins.findIndex((margin) => margin.marginId === marginId);
      if (updatedMarginIndex !== -1) {
        // update existing VM info
        marginCallMargins[updatedMarginIndex].quantity = marginCallMargins[updatedMarginIndex].quantity + quantity;
      } else {
        // add new VM info
        const newVMinfo: any = {
          marginId: marginId,
          quantity: quantity,
          currency: currency,
        };
        marginCallMargins.push(newVMinfo);
      }
      newMarginCall.margins = marginCallMargins.filter((mc) => Number(mc.quantity) !== 0);
    }

    // check status of margin call, if fully paid - set isPaid flat to true
    const currentBalance = getMarginCallBalance(newMarginCall, nbpRates, currency);
    if (currentBalance >= newMarginCall.quantity) {
      newMarginCall.isPaid = true;
    }

    // if balance is now 0, and flag isPaid is true, set isClosed to "yes"
    if (currentBalance === 0 && newMarginCall.isPaid) {
      newMarginCall.isClosed = "yes";
    }

    transactionMarginCalls[updatedMarginCallIndex] = newMarginCall;
    newTransaction.marginCalls = transactionMarginCalls;
  }

  return { newTransaction, newCompany };
}

export function addMarginToMarginCall(marginCall: MarginCall, margin: Margin): void {
  // 1. create reference object
  const marginReference = {
    marginId: margin.id,
    isGlobal: marginCall.isGMC === "yes",
    quantity: margin.from.quantity,
    currency: margin.from.currency,
  };
  const margins = marginCall.margins || [];
  // 2. search if margin already exists in margin call
  const marginIndex = margins.findIndex((m) => m.marginId === margin.id);
  if (marginIndex !== -1) {
    // 3. if yes, update quantity
    margins[marginIndex].quantity = margins[marginIndex].quantity + marginReference.quantity;
  } else {
    // 4. if no, add new margin
    margins.push(marginReference);
  }
  // 5. update margin call
  marginCall.margins = margins;
}

export function withdrawMarginFromMarginCall(
  marginCall: MarginCall,
  margin: Margin,
  quantity: number,
  date?: Date,
  comment?: string
): void {
  // 1. find margin reference in margin call
  const marginReference = marginCall.margins?.find((m) => m.marginId === margin.id);

  // 2. if not found, throw error
  if (!marginReference) {
    throw new Error(`Margin ${margin.id} not found in Margin Call ${marginCall.id}`);
  }
  // 3. check if quantity is not greater than margin reference quantity
  if (quantity > marginReference.quantity) {
    throw new Error(`Quantity ${quantity} is greater than margin reference quantity ${marginReference.quantity}`);
  }

  // 4. if found, update quantity
  marginReference.quantity = marginReference.quantity - quantity;

  // 5. Check if quantity is not greater than margin quantity
  if (quantity > margin.left.quantity) {
    throw new Error(`Quantity ${quantity} is greater than margin quantity ${margin.left.quantity}`);
  }

  // 4. update margin quantity
  margin.left.quantity = margin.left.quantity - quantity;

  // 5. add operation to margin
  if (!margin.operations) margin.operations = [];
  const operation = createWithdrawalOperation(margin, quantity, date, comment);
  margin.operations.unshift(operation);
}

function createWithdrawalOperation(margin: Margin, quantity: number, date?: Date, comment?: string) {
  return {
    id: margin.operations?.length ? margin.operations?.length + 1 : 1,
    type: "WITHDRAW",
    value: {
      quantity: quantity,
      currency: margin.from.currency,
    },
    oldMarginValue: {
      quantity: margin.left.quantity,
      currency: margin.left.currency,
    },
    newMarginValue: {
      quantity: margin.left.quantity - quantity,
      currency: margin.left.currency,
    },
    date: date ? firestore.Timestamp.fromDate(date) : firestore.Timestamp.now(),
    comment,
  };
}

export function fixMarginCallsTimestamps(marginCalls: MarginCall[] = []) {
  return marginCalls.map((marginCall) => {
    if (marginCall.createdAt) {
      marginCall.createdAt = convertToTimestamp(marginCall.createdAt);
    }
    if (marginCall.modifiedAt) {
      marginCall.modifiedAt = convertToTimestamp(marginCall.modifiedAt);
    }
    marginCall.callDate = timestampFromMap(marginCall.callDate);
    return marginCall;
  });
}
