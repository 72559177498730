import React from "react";
import { StyledCheckbox } from "./Checkbox.styled";
import { CheckboxProps as ChakraCheckboxProps } from "@chakra-ui/react";

export type CheckboxColors = "sk-purple" | "sk-light-green" | "sk-yellow";

type CheckboxProps = ChakraCheckboxProps & {
  fullWidth?: boolean;
  checkboxSize?: "small" | "normal";
  checkboxColor?: CheckboxColors;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  children,
  fullWidth,
  checkboxSize = "normal",
  checkboxColor = "sk-purple",
  ...props
}) => {
  return (
    <StyledCheckbox
      {...props}
      checkboxColor={checkboxColor}
      checkboxSize={checkboxSize}
      width={fullWidth ? "100%" : "auto"}
    >
      {children}
    </StyledCheckbox>
  );
};
