import {
  ADD_MARGIN_CALLS,
  ADD_TRANSACTION,
  APPROVE_APPLICATION_GUARD,
  DATA_FIXER,
  LIST_PAYMENTS,
  RISK_GUARD,
  RoleAreas,
  SHOW_DASHBOARD,
  SHOW_OWN_COMMISSION_FEE,
  SHOW_REPORTS,
  SHOW_SCORING,
} from "helpers/userRoles";
import React, { ReactNode } from "react";
import { DashboardIcon } from "theme/icons/DashboardIcon";
import { CompaniesIcon } from "theme/icons/CompaniesIcon";
import { ContactsIcon } from "theme/icons/ContactsIcon";
import { ApplicationsIcon } from "theme/icons/ApplicationsIcon";
import { TransactionsIcon } from "theme/icons/TransactionsIcon";
import { PaymentsIcon } from "theme/icons/PaymentsIcon";
import { SettlementsIcon } from "theme/icons/SettlementsIcon";
import { MarginCallsIcon } from "theme/icons/MarginCallsIcon";
import { CommissionFeeIcon } from "theme/icons/CommissionFeeIcon";
import { RiskIcon } from "theme/icons/RiskIcon";
import { SettingsIcon } from "@chakra-ui/icons";
import { ScoringIcon } from "theme/icons/ScoringIcon";
import { CreditCardIcon } from "theme/icons/CreditCardIcon";

type NavigationItem = {
  path: string;
  icon: ReactNode;
  name: string;
  guard?: RoleAreas;
};

const createNavigationItem = (name: string, path: string, icon: ReactNode, guard?: RoleAreas): NavigationItem => ({
  path,
  icon,
  name,
  guard,
});

const iconProps = {
  height: "20px",
  marginRight: "25px",
  width: "20px",
};

export const navigationItems: NavigationItem[] = [
  createNavigationItem("Dashboard", "/dashboard", <DashboardIcon {...iconProps} />, SHOW_DASHBOARD),
  createNavigationItem("Companies", "/companies", <CompaniesIcon {...iconProps} />),
  createNavigationItem("Contacts", "/contacts", <ContactsIcon {...iconProps} />),
  createNavigationItem("Applications", "/applications", <ApplicationsIcon {...iconProps} />, APPROVE_APPLICATION_GUARD),
  createNavigationItem("Transactions", "/transactions", <TransactionsIcon {...iconProps} />, ADD_TRANSACTION),
  createNavigationItem("Payments", "/payments", <PaymentsIcon {...iconProps} />, LIST_PAYMENTS),
  createNavigationItem("Reports", "/reports", <SettlementsIcon {...iconProps} />, SHOW_REPORTS),
  createNavigationItem("Margin calls", "/calls", <MarginCallsIcon {...iconProps} />, ADD_MARGIN_CALLS),
  createNavigationItem(
    "Commission Fees",
    "/commission-fees",
    <CommissionFeeIcon {...iconProps} />,
    SHOW_OWN_COMMISSION_FEE
  ),
  createNavigationItem("Risk", "/risk", <RiskIcon {...iconProps} />, RISK_GUARD),
  createNavigationItem("Scoring", "/scoring", <ScoringIcon {...iconProps} />, SHOW_SCORING),
  createNavigationItem("Operations", "/operations", <CreditCardIcon {...iconProps} />),
  createNavigationItem("Data Fixer", "/data-fixer", <SettingsIcon {...iconProps} />, DATA_FIXER),
];
