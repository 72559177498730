import styled from "@emotion/styled/macro";

export const ContactAddContainer = styled.h3`
  padding-bottom: 100px;
  position: relative;
`;

export const Section = styled.div`
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const StyledBackButton = styled.button`
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 110%;
  svg {
    margin-top: -2px;
  }
`;
