import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
  StyledSectionOption,
  StyledSectionOptions,
} from "./shared.styled";
import { Currency, Hedge } from "interfaces/data";
import styled from "@emotion/styled";
import { transformTimestamp } from "helpers/date";
import { SingleHedge } from "./SingleHedge";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { activeHedges as getActiveHedges } from "helpers/hedge";
import { AddIcon, EditIcon, RefreshIcon, RemoveIcon } from "theme/icons";

interface HedgesProps {
  transactionId: string;
  hedges?: Array<Hedge>;
  currency: Currency;
  partiallyHedgedInfo?: string;
  isDisabled?: boolean;
}

const StyledHedgesContainer = styled.div`
  margin: 30px;
`;

const StyledEmptyState = styled.div`
  text-align: center;
  font-size: 14px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
  text-decoration: underline;
`;

export const StyledWarning = styled.div`
  margin: 10px 30px;
  color: ${(props) => props.theme.colors["sk-red"]};
  font-size: 13px;
`;

export const Hedges: React.FC<HedgesProps> = ({
  hedges = [],
  transactionId,
  currency,
  partiallyHedgedInfo,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isExpanded, setExpanded] = useState(false);
  const numberOfCollapse = 3;

  const [selectedHedges, setSelectedHedges] = useState<Array<Hedge | undefined>>([]);

  const isHedgeSelected = useCallback(
    (hedge: Hedge) => {
      return selectedHedges.findIndex((selectedHedge) => selectedHedge?.id === hedge.id) !== -1;
    },
    [selectedHedges]
  );

  const handleHedgeSelect = useCallback(
    (hedge: Hedge) => {
      if (isHedgeSelected(hedge)) {
        // if hedge already array then remove
        setSelectedHedges((prevState) => prevState.filter((selectedHedge) => selectedHedge?.id !== hedge.id));
      } else {
        // else only leave hedges with the same end date as newly added hedge and add it to array
        setSelectedHedges((prevState) => [
          ...prevState.filter(
            (selectedHedge) => transformTimestamp(selectedHedge?.end) === transformTimestamp(hedge.end)
          ),
          hedge,
        ]);
      }
    },
    [isHedgeSelected]
  );

  const isOneSelected = useMemo(() => selectedHedges?.length === 1, [selectedHedges]);

  const areMulitpleSelected = useMemo(() => selectedHedges?.length >= 1, [selectedHedges]);

  const activeHedges = useMemo(() => {
    if (isDisabled) return [];
    return getActiveHedges(hedges);
  }, [hedges, isDisabled]);

  const inactiveHedges = useMemo(() => {
    return hedges.filter((hedge) => !activeHedges.find((activeHedge) => activeHedge.id === hedge.id)).reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHedges]);

  const handleRoll = useCallback(() => {
    const selectedHedgesIds = String(selectedHedges.map((hedge) => hedge?.id).join(","));
    history.push(`/transactions/${id}/hedges/${selectedHedgesIds}/roll`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedHedges]);

  const handleCancel = useCallback(() => {
    if (selectedHedges.length === 1) {
      const [selectedHedge] = selectedHedges;
      history.push(`/transactions/${id}/hedges/${selectedHedge?.id}/cancel`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedHedges]);

  const handleEdit = useCallback(() => {
    if (selectedHedges.length === 1) {
      const [selectedHedge] = selectedHedges;
      history.push(`/transactions/${id}/hedges/${selectedHedge?.id}/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedHedges]);

  const handleAdd = useCallback(() => {
    if (isDisabled) return;
    history.push(`/transactions/${id}/hedges/add`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDisabled]);

  return (
    <StyledContainer isRed={!!partiallyHedgedInfo}>
      <ActiveUserBorder collection="transactions" id={transactionId} sections="hedges">
        <StyledSectionHeader>
          <StyledSectionName>{t("transaction:Hedges")}</StyledSectionName>
          <StyledSectionOptions>
            <StyledSectionOption
              isActive={isOneSelected || areMulitpleSelected}
              onClick={isOneSelected || areMulitpleSelected ? handleRoll : undefined}
            >
              <RefreshIcon mr="10px" /> {t("Roll")}
            </StyledSectionOption>
            <StyledSectionOption isActive={isOneSelected} onClick={isOneSelected ? handleCancel : undefined}>
              <RemoveIcon mr="10px" /> {t("Cancel")}
            </StyledSectionOption>
            <StyledSectionOption isActive={isOneSelected} onClick={isOneSelected ? handleEdit : undefined}>
              <EditIcon mr="10px" /> {t("Edit")}
            </StyledSectionOption>
            <StyledSectionOption isActive={!isDisabled && !!partiallyHedgedInfo} onClick={handleAdd}>
              <AddIcon mr="10px" /> {t("Add")}
            </StyledSectionOption>
          </StyledSectionOptions>
        </StyledSectionHeader>
        {partiallyHedgedInfo && <StyledWarning>{partiallyHedgedInfo}</StyledWarning>}
        <StyledHedgesContainer>
          {hedges.length > 0 ? (
            [...activeHedges, ...inactiveHedges]
              .splice(0, isExpanded ? hedges.length : numberOfCollapse)
              .map((hedge) => (
                <SingleHedge
                  currency={currency}
                  hedge={hedge}
                  isDisabled={inactiveHedges.includes(hedge)}
                  isRed={!!partiallyHedgedInfo}
                  isSelected={isHedgeSelected(hedge)}
                  key={hedge.id}
                  onSelect={() => handleHedgeSelect(hedge)}
                />
              ))
          ) : (
            <StyledEmptyState>No data...</StyledEmptyState>
          )}
          {hedges.length > numberOfCollapse ? (
            <StyledButtonContainer>
              <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
                {isExpanded ? t("Collapse") : t("Expand")}
              </StyledButton>
            </StyledButtonContainer>
          ) : null}
        </StyledHedgesContainer>
      </ActiveUserBorder>
    </StyledContainer>
  );
};
