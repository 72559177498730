import React, { useCallback } from "react";

export const useFormEvents = () => {
  const clearSpacesOnPaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = e.clipboardData.getData("text");
    e.currentTarget.value = clipboardData.replaceAll(" ", "").replaceAll(",", ".");
    e.preventDefault();
  }, []);

  return [clearSpacesOnPaste];
};
