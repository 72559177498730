import React from "react";
import { Button } from "components/Button/Button";
import { MarginCallExtended } from "../MarginCalls";
import { useHistory } from "react-router-dom";

export function CloseCell(arg: any) {
  const marginCall = arg.row.original as MarginCallExtended;
  const history = useHistory();

  // show button only for MC's that are not already closed
  // and does not have any margins
  if ((marginCall.margins && marginCall.margins.length > 0) || marginCall.isClosed === "yes") return "";

  return (
    <Button
      design="link"
      fontSize="11px"
      height="25px"
      letterSpacing="0"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        history.push(`/transactions/${marginCall.transaction.id}/margin-calls/${marginCall.id}/close`);
      }}
      padding="7px 9px"
      style={{ border: "1px solid black" }}
    >
      close
    </Button>
  );
}
