import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Modal } from "components/Modal/Modal";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { Dropdown } from "components/Dropdown/Dropdown";
import { Hedge, Settlement, Transaction } from "interfaces/data";
import { getQuantityLeft } from "helpers/transaction";
import { formatNumberToString } from "helpers/formatNumber";
import { transformTimestamp } from "helpers/date";
import { Input } from "components/Input/Input";
import { TextareaWithLabel } from "components/TextareaWithLabel/TextareaWithLabel";
import {
  SelectedHedgeButton,
  SelectedHedgeLabel,
  SelectedHedgeListLabel,
  StyledAddButtonContainer,
  StyledCommentContainer,
  StyledDropdownItem,
  StyledHedgeSelectContainer,
  StyledLabel,
  StyledLabeledInput,
  StyledLinkPlaceholder,
  StyledLinksContainer,
  StyledSelectedHedgesList,
  StyledSelectedHedgesListItem,
  StyledSingleInfoContainer,
  StyledThreeFieldsGrid,
} from "./SettlementFormModal.styled";
import { calculateFwdForHedgeWithParents, calculatePointsForSettlement } from "helpers/settlement";
import { Button } from "components/Button/Button";
import styled from "@emotion/styled";
import { activeHedges } from "helpers/hedge";
import { AddIcon, RemoveIcon, SelectIcon } from "theme/icons";

export const StyledCheckbox = styled.div`
  margin-top: 30px;
  text-align: right;
`;

interface SettlementHedgesFormModalProps {
  type: "add" | "edit";
  title: string;
  initialSettlement: Settlement;
  transaction: Transaction;
  errors: any;
  onSave: (newSettlement: Settlement) => void;
  onClose: () => void;
  isDisabled?: boolean;
}

export interface HedgeWithRollback extends Hedge {
  rollback?: number;
}

export const SettlementHedgesFormModal: React.FC<SettlementHedgesFormModalProps> = ({
  type,
  title,
  initialSettlement,
  transaction,
  errors,
  onSave,
  onClose,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [settlement, setSettlement] = useState(initialSettlement);
  const [isHedgeDropdownOpen, setIsHedgeDropdownOpen] = useState(false);
  const [selectedHedge, setSelectedHedge] = useState<Hedge>();
  const [editedHedge, setEditedHedge] = useState<HedgeWithRollback>();
  const [selectedHedgesArray, setSelectedHedgesArray] = useState<Array<HedgeWithRollback>>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSettlement((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, e.target.value);
      return newEditedData;
    });
  }, []);

  const handleHedgeUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditedHedge((oldSelectedHedge: any) => {
      const newSelectedHedge = { ...oldSelectedHedge };
      const value = e.target.type === "number" ? Number(e.target.value) : e.target.value;
      _set(newSelectedHedge, e.target.name, value);
      return newSelectedHedge;
    });
  }, []);

  const sumOfAllHedges = selectedHedgesArray.reduce((sum, hedge) => sum + (Number(hedge?.quantity) || 0), 0);

  const quantityLeft = useMemo(
    (): number => settlement.quantity - sumOfAllHedges,
    [settlement.quantity, sumOfAllHedges]
  );

  const isQtyValid = useMemo((): boolean => {
    if (!editedHedge || !selectedHedge) return true;

    const maxAllowedQuantity = Number(settlement.quantity - sumOfAllHedges);

    return (
      Number(editedHedge.quantity) <= maxAllowedQuantity &&
      Number(editedHedge.quantity) <= Number(selectedHedge.quantity)
    );
  }, [settlement.quantity, editedHedge, selectedHedge, sumOfAllHedges]);

  const isQuantityValid = useMemo((): boolean => {
    if (settlement.quantity == null) return true;
    if (type === "edit") {
      return Number(settlement.quantity) <= getQuantityLeft(transaction) + Number(initialSettlement.quantity);
    }
    return Number(settlement.quantity) <= getQuantityLeft(transaction);
  }, [initialSettlement.quantity, settlement.quantity, transaction, type]);

  const handleHedgeSelect = useCallback(
    (hedgeId?: string) => () => {
      const selectedHedge = transaction?.hedges?.find((hedge) => hedge.id === hedgeId);
      if (selectedHedge && hedgeId) {
        setSelectedHedge(selectedHedge);
        const newEditedHedge = _cloneDeep(selectedHedge);
        newEditedHedge.quantity = Math.min(selectedHedge.leftQuantity, quantityLeft);
        newEditedHedge.points = calculateFwdForHedgeWithParents(transaction.hedges || [], hedgeId).toNumber();
        setEditedHedge(newEditedHedge);
        setIsHedgeDropdownOpen(false);
      }
    },
    [transaction, quantityLeft]
  );

  const handleAddHedgeToList = useCallback(() => {
    if (selectedHedge && editedHedge) {
      setSelectedHedgesArray((selectedHedgesArray) => {
        const newSelectedHedgesArray = _cloneDeep(selectedHedgesArray);
        newSelectedHedgesArray.push(editedHedge);
        return newSelectedHedgesArray;
      });
      setSelectedHedge(undefined);
      setEditedHedge(undefined);
    }
  }, [selectedHedge, editedHedge]);

  const handleRemoveHedgeFromList = useCallback(
    (hedgeId: string) => () => {
      setSelectedHedgesArray((selectedHedgesArray) => {
        return _cloneDeep(selectedHedgesArray).filter((selectedHedge) => selectedHedge.id !== hedgeId);
      });
    },
    []
  );

  const getHedgeLabel = useCallback(
    (hedge: Hedge) => (
      <SelectedHedgeLabel>
        <span>{hedge.id}</span>
        <span>{hedge.institution}</span>
        <span>
          {transformTimestamp(hedge.start)}
          {" > "}
          {transformTimestamp(hedge.end)}
        </span>
        <span>
          <b>{formatNumberToString(hedge.quantity)}</b>
        </span>
        <span>{formatNumberToString(hedge.leftQuantity)}</span>
      </SelectedHedgeLabel>
    ),
    []
  );

  const getHedgeWithRollbackLabel = useCallback(
    (hedge: HedgeWithRollback) => (
      <SelectedHedgeListLabel>
        <span>{hedge.id}</span>
        <span>{hedge.institution}</span>
        <span>
          Qty: <b>{hedge.quantity}</b>
        </span>
        <span>
          Fwd: <b>{hedge.points}</b>
        </span>
        <span>
          Roll: <b>{hedge.rollback}</b>
        </span>
      </SelectedHedgeListLabel>
    ),
    []
  );

  // update settlement when hedges are added to the list:
  useEffect(() => {
    const updatedSettlement = _cloneDeep(settlement);

    // // points
    const { points, rollback, fwdPoints } = calculatePointsForSettlement(selectedHedgesArray);

    // hedges
    const newHedges = selectedHedgesArray.map((selectedHedge) => ({
      hedgeId: selectedHedge.id,
      quantity: Number(selectedHedge.quantity),
      fwd: Number(selectedHedge.points),
      roll: Number(selectedHedge.rollback) || 0,
    }));

    // update NewSettlement
    updatedSettlement.points = Number(points) || 0;
    updatedSettlement.rollback = Number(rollback) || 0;
    updatedSettlement.fwdPoints = Number(fwdPoints) || 0;
    updatedSettlement.hedges = newHedges || [];
    setSettlement(updatedSettlement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHedgesArray]);

  const availableHedges = useMemo(
    () =>
      activeHedges(transaction.hedges || [], true)
        .filter((hedge) => {
          return selectedHedgesArray.findIndex((selectedHedge) => selectedHedge.id === hedge.id) === -1;
        })
        // Descending sort based on hedge end date
        .sort((hedgeA, hedgeB) => {
          return hedgeA.end.seconds - hedgeB.end.seconds;
        }),

    [selectedHedgesArray, transaction]
  );

  const availableHedgesList = useMemo(
    () =>
      availableHedges ? (
        availableHedges.map((hedge) => (
          <StyledDropdownItem design="link" key={hedge.id} onClick={handleHedgeSelect(hedge.id)}>
            {getHedgeLabel(hedge)}
          </StyledDropdownItem>
        ))
      ) : (
        <></>
      ),
    [availableHedges, getHedgeLabel, handleHedgeSelect]
  );

  useEffect(() => {
    const firstHedge = availableHedges && availableHedges?.length > 0 ? availableHedges[0] : undefined;

    if (firstHedge) {
      const quantity = Number(quantityLeft) >= Number(firstHedge.quantity) ? firstHedge.quantity : quantityLeft;

      const points = calculateFwdForHedgeWithParents(transaction.hedges || [], firstHedge.id).toNumber();
      setSelectedHedge(firstHedge);
      return setEditedHedge({ ...firstHedge, quantity, points });
    }
    setSelectedHedge(undefined);
    setEditedHedge(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityLeft]);

  // FOR EDIT - display list of used hedges
  useEffect(() => {
    if (transaction && initialSettlement && type === "edit") {
      const usedHedges =
        transaction.hedges?.filter((hedge) =>
          initialSettlement.hedges.find((settlementHedge) => settlementHedge.hedgeId === hedge.id)
        ) || [];

      setSelectedHedgesArray(
        usedHedges.map((usedHedge) => {
          const values = initialSettlement.hedges.find((settlementHedge) => settlementHedge.hedgeId === usedHedge.id);
          if (!values) return usedHedge;
          return {
            ...usedHedge,
            quantity: Number(values.quantity),
            points: Number(values.fwd),
            rollback: Number(values.roll),
          };
        })
      );
    }
  }, [initialSettlement, transaction, type]);

  if (!transaction) {
    return null;
  }

  return (
    <>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isDisabled={!isQuantityValid || isDisabled}
        isOpen
        justifyButtons="flex-end"
        minWidth={899}
        onClose={onClose}
        onConfirm={() => (selectedHedgesArray.length ? onSave(settlement) : setShowConfirmation(true))}
        title={title}
      >
        <TwoColumnsLayout>
          <StyledSingleInfoContainer>
            <div>{t(`settlement:Quantity left`)}:</div>
            <div>{`${formatNumberToString(quantityLeft)} ${transaction.from.currency}`}</div>
          </StyledSingleInfoContainer>
          <StyledSingleInfoContainer>
            <div>{t(`settlement:Points`)}:</div>
            <div>{settlement.points.toFixed(4)}</div>
          </StyledSingleInfoContainer>
        </TwoColumnsLayout>

        {/* HEDGE SELECT */}
        <StyledHedgeSelectContainer>
          <div>{t("hedge:Hedge")}</div>
          <Dropdown
            isDisabled={!isQuantityValid || quantityLeft === 0}
            isOpen={isHedgeDropdownOpen}
            marginTop="-5px !important"
            maxHeight="230px"
            maxWidth="720px"
            onClose={() => setIsHedgeDropdownOpen(false)}
            overflowX="auto"
            padding="0"
            placement="left"
            trigger={
              <SelectedHedgeButton onClick={() => setIsHedgeDropdownOpen(!isHedgeDropdownOpen)}>
                {selectedHedge ? getHedgeLabel(selectedHedge) : ""}
                <SelectIcon ml="auto" />
              </SelectedHedgeButton>
            }
            width="720px"
            zIndex={1404}
          >
            {availableHedgesList}
          </Dropdown>
        </StyledHedgeSelectContainer>

        <StyledThreeFieldsGrid>
          <StyledLabeledInput>
            <StyledLabel>{t(`settlement:Qty.`)}</StyledLabel>
            <Input
              enableDataCopy
              error={!isQtyValid ? " " : undefined}
              formatNumberOnBlur
              id="quantity"
              isDisabled={!selectedHedge}
              name="quantity"
              onChange={handleHedgeUpdate}
              type="number"
              value={editedHedge?.quantity}
            />
          </StyledLabeledInput>
          <StyledLabeledInput>
            <StyledLabel>{t(`settlement:Fwd.`)}</StyledLabel>
            <Input
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="points"
              isDisabled
              name="points"
              onChange={handleHedgeUpdate}
              step="0.1"
              type="number"
              value={editedHedge?.points}
            />
          </StyledLabeledInput>
          <StyledLabeledInput>
            <StyledLabel>{t(`settlement:Roll.`)}</StyledLabel>
            <Input
              autoFocus
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="rollback"
              isDisabled={!selectedHedge}
              name="rollback"
              onChange={handleHedgeUpdate}
              step="0.1"
              type="number"
              value={editedHedge?.rollback}
            />
          </StyledLabeledInput>
        </StyledThreeFieldsGrid>

        <StyledAddButtonContainer>
          <Button design="primary" isDisabled={!selectedHedge || !isQtyValid} ml="auto" onClick={handleAddHedgeToList}>
            <AddIcon mr="10px" />
            {t("Add")}
          </Button>
        </StyledAddButtonContainer>

        <StyledSelectedHedgesList>
          {selectedHedgesArray.map((selectedHedge) => (
            <StyledSelectedHedgesListItem key={selectedHedge.id}>
              {getHedgeWithRollbackLabel(selectedHedge)}
              <button onClick={handleRemoveHedgeFromList(selectedHedge.id)}>
                <RemoveIcon mt="-2px" />
              </button>
            </StyledSelectedHedgesListItem>
          ))}
        </StyledSelectedHedgesList>

        <StyledCommentContainer>
          <TextareaWithLabel
            error={errors?.["comment"]}
            id="comment"
            label={t("settlement:Comment")}
            labelProps={{
              paddingRight: "10px",
            }}
            name="comment"
            onChange={handleUpdate}
            value={settlement?.comment}
            withError={!!errors?.["comment"]}
          />
          <StyledLinksContainer>
            <StyledLinkPlaceholder>{t("settlement:Hedge history")}</StyledLinkPlaceholder>
            <StyledLinkPlaceholder>{t("settlement:Visualisation")}</StyledLinkPlaceholder>
          </StyledLinksContainer>
        </StyledCommentContainer>
      </Modal>
      <Modal
        design="danger"
        isChild
        isOpen={showConfirmation}
        minWidth={550}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => onSave(settlement)}
        title={t(`hedge:No hedge selected`)}
      >
        {t("transaction:Are you sure you want to save without any hedge added?")}
      </Modal>
    </>
  );
};
