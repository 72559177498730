import React, { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { InputLeftElement } from "@chakra-ui/react";
import { StyledInput, StyledInputGroup, StyledInputRightElement, StyledSearchContainer } from "./GlobalSearch.styled";
import { usePortal } from "hooks/usePortal";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { AlgoliaSearchResults } from "./AlgoliaSearchResults";
import useDebounce from "hooks/useDebounce";

export const GlobalSearch: React.FC = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);

  const handleSearchInputUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setQuery(newValue);
  }, []);

  const handleResultClick = useCallback(() => {
    setQuery("");
  }, []);

  const target = usePortal("global-search");

  if (!target) return null;

  return createPortal(
    <StyledSearchContainer>
      <StyledInputGroup>
        <InputLeftElement children={<SearchIcon />} />
        <StyledInput
          name="search"
          onChange={handleSearchInputUpdate}
          placeholder={t("Search")}
          type="text"
          value={query}
        />
        {query.length > 0 && <StyledInputRightElement children={<CloseIcon />} onClick={() => setQuery("")} />}
      </StyledInputGroup>
      {debouncedQuery && <AlgoliaSearchResults closeGlobalSearch={handleResultClick} query={debouncedQuery} />}
    </StyledSearchContainer>,
    target
  );
};
