import algoliasearch from "algoliasearch";
import { Hit } from "@algolia/client-search";
import { config } from "../Firebase";

const INDEX_PREFIX = config.projectId as string;
export const TRANSACTIONS_INDEX_NAME = `${INDEX_PREFIX}-transactions`;
export const COMPANIES_INDEX_NAME = `${INDEX_PREFIX}-companies`;
export const CONTACTS_INDEX_NAME = `${INDEX_PREFIX}-contacts`;

type SearchOptions = {
  indexName: string;
  query: string;
};

export async function search<TData>({ indexName, query }: SearchOptions): Promise<{
  hits: Hit<TData>[];
}> {
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID as string,
    process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string
  );
  const index = client.initIndex(indexName);
  const { hits } = await index.search<TData>(query);

  return { hits };
}
