import React, { FC } from "react";

export const Rate: FC<{ fixed: number; value: number }> = ({ fixed, value }) => {
  const toFixedValue = value.toFixed(fixed);
  const [val, decimal] = toFixedValue.toString().split(".");
  // add zeros to the end of the decimal when it's shorter than fixed
  const decimalWithZeros = decimal ? decimal.padEnd(fixed, "0") : "";
  const decimalFirstTwoDigits = decimalWithZeros.slice(0, 2);
  const decimalRest = decimalWithZeros.slice(2);

  return (
    <>
      {val}.{decimalFirstTwoDigits}
      <small>{decimalRest}</small>
    </>
  );
};
