import { extendTheme } from "@chakra-ui/react";

const siteTheme = extendTheme({
  breakpoints: ["90em"],
  fontSizes: {
    "xs-sm": "0.8125rem",
  },
  fonts: {
    body: "Muli, sans-serif",
    heading: "Muli, sans-serif",
  },
  sizes: {
    9: "2.25rem",
  },
  space: {
    7: "1.875rem",
    44: "11rem",
    28: "7.5rem",
  },
  radii: {
    "sm-md": "0.1875rem",
    base: "1rem",
  },
  colors: {
    "sk-dark": "#252527",
    "sk-lighter-gray": "#F9F9F9",
    "sk-light-gray": "#ECECEC",
    "sk-gray": "#818181",
    "sk-dark-gray": "#DFDFDF",
    "sk-red": "#EC4713",
    "sk-purple": "#5B4DBE",
    "sk-blue": "#007FD8",
    "sk-yellow": "#FFC700",
    "sk-green": "#34A200",
    "sk-dark-green": "#1c6147",
    "sk-light-green": "#3FC600",
    "sk-purple-hue": {
      50: "#eeebff",
      100: "#cbc6f0",
      200: "#a9a2df",
      300: "#877cd0",
      400: "#6457c2",
      500: "#4b3da8",
      600: "#3a3084",
      700: "#28225f",
      800: "#18133b",
      900: "#08061a",
    },
  },
});
export default siteTheme;
