import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _set from "lodash.set";
import { Margin } from "interfaces/data";
import { Modal } from "components/Modal/Modal";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { SelectWithLabel } from "components/SelectWithLabel/SelectWithLabel";
import { CURRENCIES, MARGIN_TYPES_OPTIONS } from "helpers/options";
import { TotalValue } from "./TotalValue/TotalValue";
import { MarginGrid } from "./MarginFormModal.styled";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { useNbpRate } from "hooks/useNbpRate";
import { useImmer } from "use-immer";
import { formatDate } from "helpers/date";
import { useFirebase } from "hooks/useFirebase";
import styled from "@emotion/styled";
import { useIsMounted } from "hooks/useIsMounted";

export const StyledMarginsList = styled.div`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  height: 235px;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
`;

export const StyledMarginsListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  padding: 5px 20px;
  min-height: 40px;
  font-size: 14px;
  button {
    display: none;
    margin-left: auto;
  }
  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-dark"]};
  }
  &:hover button {
    display: block;
  }
`;

export const StyledShowAllButton = styled.button`
  font-size: 13px;
  text-align: center;
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
  width: 100%;
`;

interface MarginFormModalProps {
  type: "add" | "edit";
  title: string;
  initialMargin: Margin;
  errors: any;
  onSave: (newMargin: Margin) => void;
  onClose: () => void;
  isDisabled?: boolean;
  isTypeLocked?: boolean;
}

export const MarginFormModal: React.FC<MarginFormModalProps> = ({
  title,
  initialMargin,
  errors,
  onSave,
  onClose,
  isDisabled = false,
  isTypeLocked = false,
}) => {
  const { t } = useTranslation();
  const [margin, setMargin] = useImmer(initialMargin);
  const { timestampFrom } = useFirebase();
  const { isMounted } = useIsMounted();

  const { rateInfo } = useNbpRate({
    from: margin?.from.currency,
    to: margin?.to.currency,
  });

  const handleUpdate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      e.persist();
      const value = e.target.type === "number" ? Number(e.target.value) : e.target.value;

      setMargin((draft: Margin) => {
        _set(draft, e.target.name, value);
      });
    },
    [setMargin]
  );

  const handleUpdateDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setMargin((oldEditedData: any) => {
        const newEditedData = { ...oldEditedData };
        const date = new Date(e.target.value);
        _set(newEditedData, e.target.name, timestampFrom(date));
        return newEditedData;
      });
    },
    [setMargin, timestampFrom]
  );

  useEffect(() => {
    if (!rateInfo || !rateInfo.rate || !isMounted) {
      return;
    }

    setMargin((draft: Margin) => {
      draft.to.quantity = draft.from.quantity * rateInfo.rate;
      draft.left = draft.from;
    });
  }, [margin.from.quantity, margin.from.currency, rateInfo, setMargin, isMounted]);

  useEffect(() => {
    setMargin((draft) => {
      draft.from = initialMargin.from;
      draft.to = initialMargin.to;
    });
  }, [setMargin, initialMargin]);

  return (
    <Modal
      bodyProps={{
        padding: 0,
      }}
      confirmText={t("Save")}
      design="primary"
      isDisabled={isDisabled || rateInfo?.rate == null || Number.isNaN(rateInfo?.rate)}
      isOpen
      justifyButtons="flex-end"
      minWidth={899}
      onClose={onClose}
      onConfirm={() => onSave(margin)}
      title={title}
    >
      <MarginGrid p={30}>
        <SelectWithLabel
          error={errors?.["type"]}
          id="type"
          isDisabled={isTypeLocked}
          label={t("margin:Type")}
          name="type"
          onChange={handleUpdate}
          options={MARGIN_TYPES_OPTIONS}
          value={margin.type}
        />
        <AutoCompleteSelect
          error={errors?.["from.currency"]}
          id="currency"
          label={t("margin:Currency")}
          layout="vertical"
          name="from.currency"
          onChange={handleUpdate}
          options={CURRENCIES}
          placeholder="Select"
          value={margin?.from.currency}
        />
        <InputWithLabel
          error={errors?.["from.quantity"]}
          formatNumberOnBlur
          id="quantity"
          label={t("margin:Quantity")}
          name="from.quantity"
          onChange={handleUpdate}
          type="number"
          value={margin.from.quantity}
          withError={!!errors?.["from.quantity"]}
        />
        <InputWithLabel
          error={errors?.["date"]}
          id="date"
          label={t("transaction:Date")}
          name="date"
          onChange={handleUpdateDate}
          type="date"
          value={formatDate(margin.date)}
          withError={!!errors?.["date"]}
        />
      </MarginGrid>
      <TotalValue currency={margin.to.currency} label={t("margin:Total")} mb={30} quantity={margin.to.quantity} />
    </Modal>
  );
};
