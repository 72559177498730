import React, { useCallback, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { useProfiles } from "hooks/useProfiles";
import { useToast } from "hooks/useToast";
import { Profile } from "interfaces/data";

interface EditUserProps {
  user: Profile;
  onClose: () => any;
  onSuccess: () => any;
}

export const EditUser: React.FC<EditUserProps> = ({ user, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { update } = useProfiles();

  const toast = useToast();

  const handleConfirm = useCallback(async () => {
    if (!user) return;
    setLoading(true);
    const fullName = `${user.name} ${user.surname}`;
    const result = await update(user);
    if (result) {
      toast({
        type: "success",
        message: t(`settings:User {{name}} has been updated.`, {
          name: fullName,
        }),
      });
      onSuccess();
    } else {
      toast({
        type: "error",
        message: t(`settings:User {{name}} could not be updated.`, {
          name: fullName,
        }),
      });
      onClose();
    }
    setLoading(false);
  }, [user, update, toast, t, onSuccess, onClose]);

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={loading}
      isOpen
      onClose={onClose}
      onConfirm={handleConfirm}
      title={t(`settings:Change user role`)}
    >
      <Box mb={"20px"}>
        {t(`settings:Are you sure you want to change {{name}} data?`, {
          name: `${user?.name} ${user?.surname}`,
        })}
      </Box>
    </Modal>
  );
};
