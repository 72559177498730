import { BankAccountSchema } from "../schemas/common";

export const getDefaultBankAccount = (currency: string, db: Firestore) => {
  const bankAccounts = db.collection("bankAccounts");
  return bankAccounts
    .where("currency", "==", currency)
    .where("isDefault", "==", true)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return null;
      }

      return BankAccountSchema.parse(snapshot.docs[0].data());
    });
};
