import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const MarginCallsIcon = createIcon({
  displayName: "MarginCallsIcon",
  path: (
    <g fill="none">
      <path
        d="M6.33325 6.3335L9.53325 9.5335L6.33325 12.7335"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.53333 9.53333H5.26667C2.9104 9.53333 1 7.62293 1 5.26667C1 2.9104 2.9104 1 5.26667 1H9.53333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.7334 9.5332H17.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.7334 5.2666H17.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.7334 1H17.0001"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 18 14",
});
