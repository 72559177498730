import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import matchSorter from "match-sorter";
import Highlighter from "react-highlight-words";
import { SelectProps as ChakraSelectProps } from "@chakra-ui/react";

import {
  StyledContainer,
  StyledContent,
  StyledInputRightElement,
  StyledItem,
  StyledLabel,
  StyledNoData,
  StyledPane,
} from "./AutoCompleteSelect.styled";
import { useTranslation } from "react-i18next";
import { chakraStyles } from "theme/chakraStyles";
import { StyledInputGroup } from "../InputWithLabel/InputWithLabel.styled";
import { Input } from "../Input/Input";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useHotkeys } from "hooks/useHotkeys";
import { ChevronDownIcon } from "@chakra-ui/icons";

export type SelectOption = {
  value: string;
  label: string;
};

export interface AutoCompleteSelectProps extends ChakraSelectProps {
  error?: string;
  label?: string;
  options: Array<SelectOption>;
  id?: string;
  value?: any;
  name: string;
  withError?: boolean;
  highlightSearch?: boolean;
  layout?: string;
  includePlaceholder?: boolean;
  width?: string;
}

function defaultOptionFilterFunc(items: Array<SelectOption>, inputValue: string): Array<SelectOption> {
  return matchSorter(items, inputValue, { keys: ["value", "label"] });
}

export const AutoCompleteSelect = ({
  label,
  name,
  value,
  id,
  onChange,
  mb,
  options,
  error,
  placeholder = "",
  includePlaceholder = false,
  highlightSearch = true,
  layout = "horizontal",
  isDisabled = false,
  width,
}: AutoCompleteSelectProps): React.ReactElement => {
  const { t } = useTranslation();
  const [inputError, setInputError] = useState(error);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const initOptions = includePlaceholder ? [{ value: "", label: placeholder }, ...options] : options;
  const [filteredOptions, setFilteredOptions] = useState<Array<SelectOption>>(initOptions);
  const containerRef = useRef(null);

  const selectOption = useCallback(
    (selectedOption: string) => {
      setInputValue(selectedOption);
      if (onChange) {
        // react no longer allows to programmatically trigger onChange for Input component like this:
        // inputRef.dispatchEvent(new Event('input', { bubbles: true}))
        // that's why we are passing pseudo-event with all the necessary data
        onChange({
          // @ts-ignore
          target: {
            name,
            value: selectedOption,
          },
          persist: () => {
            return;
          },
        });
      }
      setIsOpen(false);
      inputError && setInputError(undefined);
    },
    [onChange, name, inputError]
  );

  const selectFirstSuggestion = useCallback(() => {
    if (isOpen) {
      if (filteredOptions.length > 0 && inputValue !== "") {
        selectOption(filteredOptions[0].label);
      } else {
        selectOption("");
      }
    }
  }, [isOpen, filteredOptions, inputValue, selectOption]);

  useHotkeys("Tab", selectFirstSuggestion);
  useOnClickOutside(containerRef, selectFirstSuggestion);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    setInputError(error);
  }, [error]);

  useEffect(() => {
    setFilteredOptions(initOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    setFilteredOptions(defaultOptionFilterFunc(initOptions, inputValue || ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputChange = useMemo(
    () => (e: ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) return;
      if (!isOpen) {
        setIsOpen(true);
      }
      setInputValue(e.target.value);
    },
    [isDisabled, isOpen]
  );

  return (
    <StyledContainer layout={layout} ref={containerRef} width={width}>
      <StyledLabel layout={layout}>{label}</StyledLabel>
      <StyledInputGroup>
        <Input
          _disabled={chakraStyles.disabled}
          borderColor="sk-light-gray"
          borderRadius="base"
          errorBorderColor="sk-red"
          fontSize="xs-sm"
          fontWeight="semibold"
          height="42px"
          id={id}
          isDisabled={isDisabled}
          isInvalid={Boolean(inputError)}
          mb={mb}
          name={name}
          onChange={handleInputChange}
          placeholder={placeholder}
          px="1rem"
          value={inputValue}
        />
        <StyledInputRightElement
          children={<ChevronDownIcon boxSize="20px" />}
          isDisabled={isDisabled}
          isOpen={isOpen}
          onClick={() => {
            if (isDisabled) return;
            setIsOpen((prev) => !prev);
          }}
        />
        {isOpen && (
          <StyledContent>
            <StyledPane>
              {filteredOptions.length === 0 && <StyledNoData>{t("No results")}</StyledNoData>}
              {value === null && (
                <StyledItem design="link" onClick={() => selectOption("")}>
                  {t("Nothing selected")}
                </StyledItem>
              )}
              {filteredOptions.map((option) => (
                <StyledItem
                  design="link"
                  key={option.value}
                  name={name}
                  onClick={() => {
                    selectOption(option.label);
                  }}
                  selected={option.value === value}
                  value={option.value}
                >
                  {highlightSearch ? (
                    <Highlighter
                      autoEscape
                      highlightStyle={{ background: "#cbc6f0" }}
                      searchWords={[inputValue]}
                      textToHighlight={option.label}
                    />
                  ) : (
                    option.label
                  )}
                </StyledItem>
              ))}
            </StyledPane>
          </StyledContent>
        )}
      </StyledInputGroup>
    </StyledContainer>
  );
};
