import React, { useCallback, useState } from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { Modal } from "../../components/Modal/Modal";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCompany } from "../../hooks/useCompany";
import { useToast } from "../../hooks/useToast";

export const CompanyActivate: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { company, loading, activate } = useCompany({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}/preview`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleConfirm = useCallback(() => {
    setIsSending(true);
    activate(id, company)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("company:Company has been activated successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`company:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
  }, [activate, handleClose, id, t, toast, company]);

  if (loading) {
    return <Spinner />;
  }

  if (!company) {
    return <>{t("company:Company not found")}</>;
  }

  return (
    <Modal
      confirmText={t("company:Activate")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={`${t("company:Activate company")} ${company.name}`}
    >
      <Box mb={"20px"}>{t("company:Are you sure you want to activate this company?")}</Box>
    </Modal>
  );
};
