import styled from "@emotion/styled/macro";

import { FormLabel } from "@chakra-ui/react";

export const StyledLabel = styled(FormLabel)<{
  paddingRight: string;
}>`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  padding: 0 ${(props) => props.paddingRight || "0"} 0 0;
  display: flex;
  margin-top: 13px;
  justify-content: flex-end;
  font-weight: 600;
  line-height: 110%;
`;
