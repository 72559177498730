import * as yup from "yup";
import { peselValidation } from "./shared";

export const contactSchema = yup.object().shape({
  email: yup.string().nullable().trim(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup.string().trim(),
  status: yup.string().trim(),
  idDetails: yup
    .object()
    .shape({
      expirationDate: yup.string().nullable().trim(),
      idType: yup.string().nullable().trim(),
      number: yup.string().trim(),
      pesel: peselValidation.trim(),
    })
    .required(),
  address: yup.object().shape({
    street: yup.string().nullable().trim(),
    number: yup.string().nullable().trim(),
    code: yup.string().nullable().trim(),
    city: yup.string().nullable().trim(),
    country: yup.string().nullable().trim(),
  }),
});
