import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ExportFileIcon = createIcon({
  displayName: "ExportFileIcon",
  path: (
    <g fill="none">
      <path
        clipRule="evenodd"
        d="M46.7999 43.8659H51.5999C54.9095 43.8659 57.5999 41.1732 57.5999 37.866V11.466C57.5999 8.1588 54.9095 5.46601 51.5999 5.46601H24.4968L20.0496 1.01641C19.824 0.793215 19.5192 0.666016 19.2 0.666016H5.99999C2.6904 0.666016 0 3.35881 0 6.66601V37.866C0 41.1732 2.6904 43.8659 5.99999 43.8659H10.8C11.4624 43.8659 12 43.3284 12 42.666C12 42.0036 11.4624 41.466 10.8 41.466H5.99999C4.01519 41.466 2.4 39.8508 2.4 37.866V6.66601C2.4 4.68121 4.01519 3.06601 5.99999 3.06601H18.7032L23.1504 7.51561C23.376 7.7388 23.6808 7.866 24 7.866H51.5999C53.5847 7.866 55.1999 9.4812 55.1999 11.466V37.866C55.1999 39.8508 53.5847 41.466 51.5999 41.466H46.7999C46.1375 41.466 45.5999 42.0036 45.5999 42.666C45.5999 43.3284 46.1375 43.8659 46.7999 43.8659ZM27.5998 40.266C27.5998 40.9284 28.1374 41.466 28.7998 41.466C29.4622 41.466 29.9998 40.9284 29.9998 40.266V23.9633L33.9503 27.9157C34.1855 28.1485 34.4927 28.2661 34.7999 28.2661C35.1071 28.2661 35.4143 28.1485 35.6495 27.9133C36.1175 27.4453 36.1175 26.6845 35.6495 26.2165L29.6495 20.2165C29.1815 19.7485 28.4207 19.7485 27.9527 20.2165L21.9527 26.2165C21.4847 26.6845 21.4847 27.4453 21.9527 27.9133C22.4207 28.3813 23.1815 28.3813 23.6495 27.9133L27.5998 23.963V40.266ZM20.3998 48.666H37.1998C39.1846 48.666 40.7998 47.0508 40.7998 45.066V40.266C40.7998 39.6036 40.2622 39.066 39.5998 39.066C38.9374 39.066 38.3998 39.6036 38.3998 40.266V45.066C38.3998 45.7284 37.8598 46.266 37.1998 46.266H20.3998C19.7398 46.266 19.1998 45.7284 19.1998 45.066V40.266C19.1998 39.6036 18.6622 39.066 17.9998 39.066C17.3374 39.066 16.7998 39.6036 16.7998 40.266V45.066C16.7998 47.0508 18.415 48.666 20.3998 48.666Z"
        fill="#252527"
        fillRule="evenodd"
      />
    </g>
  ),
  viewBox: "0 0 58 49",
});
