import styled from "@emotion/styled";
import { InputGroup, Select } from "@chakra-ui/react";

export const StyledContainer = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const StyledTopSection = styled.div`
  display: flex;
  margin-bottom: 30px;
  button:first-of-type {
    margin-left: auto;
  }
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  svg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-left: 20px;
    color: #aaa9af;
  }
`;

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]} !important;
  border-radius: 16px;
  padding-left: 55px;
  height: 42px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 13px;
  width: 100%;
  ::placeholder {
    color: #aaa9af;
    opacity: 1;
  }
`;

export const StyledSelect = styled(Select)`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]} !important;
  border-radius: 16px;
  height: 42px;
  outline: none !important;
  padding-left: 75px;
  font-size: 13px;
  font-weight: 600;
  box-shadow: 0 2px 0 rgba(236, 236, 236, 0.3) !important;
`;

export const StyledSelectContainer = styled.div`
  position: relative;
  width: 400px;
  margin-left: 20px;
`;

export const StyledSelectLabel = styled.div`
  position: absolute;
  top: 13px;
  left: 20px;
  z-index: 2;
  font-size: 12px;
  color: #aaa9af;
`;

export const StyledButton = styled.button`
  display: flex;
  font-weight: 800;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: 2px;
  border-radius: 16px;
  padding: 18px 30px;
  background: #ffffff;
  color: #252527;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(236, 236, 236, 0.3);
  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: 1px;
  }
  &.primary {
    /* TODO: replace colors with theme value after new theme is merged to master */
    background: #5b4dbe;
    color: #ffffff;
  }
`;

export const StyledTableControls = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
`;

export const StyledThreeColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledBackButton = styled.button`
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 110%;
  svg {
    margin-top: -2px;
  }
`;

export const StyledSecondBackButton = styled(StyledBackButton)`
  margin-left: 20px;
`;
