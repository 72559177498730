import React, { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../hooks/useToast";
import { Modal } from "../../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { useCompany } from "../../../hooks/useCompany";

export function MarginCancelGlobal() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginId } = useParams<{
    id: string;
    marginId: string;
  }>();
  const toast = useToast();
  const { globalMarginCancel } = useCompany({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelAction = useCallback(() => {
    setIsSending(true);
    globalMarginCancel(marginId)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("margin:Margin has been canceled successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`margin:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
  }, [globalMarginCancel, handleClose, marginId, t, toast]);

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={t(`margin:Cancel margin {{marginId}}`, { marginId })}
    >
      <Box mb={"20px"}>
        {t(`margin:This action can't be undone. Are you sure you want to cancel margin {{marginId}}?`, { marginId })}
      </Box>
    </Modal>
  );
}
