import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { StyledMarginsList, StyledMarginsListItem, StyledShowAllButton } from "../MarginCallSettlementModal.styled";
import { Radio } from "components/Radio/Radio";
import { transformTimestampToDate } from "helpers/date";
import { formatNumberToString } from "helpers/formatNumber";
import { Margin, MarginCall } from "interfaces/data";
import { useTranslation } from "react-i18next";

interface MarginListProps {
  margins: Margin[];
  selectedMargin: Margin | null;
  marginReferences: MarginCall["margins"];
  onSelect: (margin: Margin) => void;
}

export const MarginList: React.FC<MarginListProps> = ({ margins, selectedMargin, marginReferences, onSelect }) => {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);

  const numberOfCollapse = 5;
  const visibleMargins = isExpanded ? margins : margins.slice(0, numberOfCollapse);

  function getMarginQuantity(marginId: string) {
    return marginReferences?.find((margin) => margin.marginId === marginId)?.quantity ?? 0;
  }

  if (visibleMargins.length < 2) {
    return <></>;
  }

  return (
    <Box mb="30px">
      <StyledMarginsList>
        {visibleMargins.map((margin) => (
          <StyledMarginsListItem
            key={margin.id}
            onClick={(e) => {
              onSelect(margin);
              e.stopPropagation();
            }}
          >
            <Box display="flex">
              <Radio
                isChecked={Boolean(selectedMargin?.id === margin.id)}
                mr="10px"
                onChange={(e) => {
                  e.preventDefault();
                }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              />
              <Box pr="15px" textAlign="right" w="100px">
                {transformTimestampToDate(margin.date)}
              </Box>
              <Box fontWeight="bold" pr="15px">
                Qty: {formatNumberToString(getMarginQuantity(margin.id))}
                {" / "}
                {formatNumberToString(margin.from.quantity)}
                {margin.from.currency}
              </Box>
              <Box>{margin.id}</Box>
            </Box>
          </StyledMarginsListItem>
        ))}
        {margins.length > numberOfCollapse && !isExpanded && (
          <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
            {t("Show all")}
          </StyledShowAllButton>
        )}
      </StyledMarginsList>
    </Box>
  );
};
