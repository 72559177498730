import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Company, GlobalMarginCall, MarginCall, Transaction } from "interfaces/data";
import { useTranslation } from "react-i18next";
import { StyledFieldsLayout, StyledLabel } from "./SelectedTransactions.styled";
import { Input } from "components/Input/Input";
import { DEAL_TYPE_BUY, DEAL_TYPE_SELL, determineTransactionCurrency, getQuantityLeft } from "helpers/transaction";
import { formatNumber } from "helpers/formatNumber";
import { useNbpRate } from "hooks/useNbpRate";
import { useToast } from "hooks/useToast";
import { useMail } from "hooks/useMail";
import { Modal } from "components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { Button } from "components/Button/Button";
import { useFirebase } from "hooks/useFirebase";
import { useCompany } from "hooks/useCompany";
import { useGMC } from "hooks/useGMC";
import { GLOBAL_MARGIN_CALL_MAIL } from "helpers/mails";

interface SelectedTransactionsProps {
  transactions?: Array<Transaction>;
  totalLocalGlobalMarginQuantityPLN: number;
  companyInitialMarginRequests: number;
  companyId: string;
}

const useAddMarginCall = (
  setIsSending: Dispatch<SetStateAction<boolean>>,
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { add } = useGMC();
  const sendConfirmationMail = useMail();

  const addGMC = async (marginCall: MarginCall, transactions: Array<Transaction>, company: Company) => {
    try {
      setIsSending(true);
      await add(marginCall, company);

      // TODO: send mail to company
      await sendConfirmationMail(GLOBAL_MARGIN_CALL_MAIL, transactions[0].id, {
        marginCall: marginCall.id,
      });

      toast({
        type: "success",
        message: t("marginCall:GMC has been added successfully"),
      });

      return true;
    } catch (err: any) {
      toast({
        type: "error",
        message: t(`gmc:${err.message || err}`),
      });
    } finally {
      setIsSending(false);
      setIsModalOpen(false);
    }
  };
  return { addGMC };
};

export const SelectedTransactions = ({
  transactions,
  totalLocalGlobalMarginQuantityPLN,
  companyInitialMarginRequests,
  companyId,
}: SelectedTransactionsProps) => {
  const { t } = useTranslation();
  const { timestamp } = useFirebase();
  const { company } = useCompany({ id: companyId });
  const transaction = (transactions && transactions.length > 0 && transactions[0]) as Transaction;
  const [variation, setVariation] = useState("");
  const [variationC, setVariationC] = useState("");
  const [callRate, setCallRate] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [marginCallQuantity, setMarginCallQuantity] = useState(0);
  const [totalMarginCallQuantity, setTotalMarginCallQuantity] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { rateInfo } = useNbpRate({
    from: transaction?.from?.currency || "EUR",
    to: transaction?.to?.currency || "PLN",
  });
  const { addGMC } = useAddMarginCall(setIsSending, setIsModalOpen);

  const remainingQuantity = useMemo(
    () => transactions?.reduce((sum: number, transaction: Transaction) => getQuantityLeft(transaction) + sum, 0) || 0,
    [transactions]
  );

  const remainingQuantityInCurrency = useMemo(
    () =>
      transactions?.reduce(
        (sum: number, transaction: Transaction) => getQuantityLeft(transaction) * transaction.clientRate + sum,
        0
      ) || 0,
    [transactions]
  );

  const clientRate = useMemo(
    () => remainingQuantityInCurrency / remainingQuantity,
    [remainingQuantityInCurrency, remainingQuantity]
  );

  const marginCallCurrency = useMemo(() => determineTransactionCurrency(transaction), [transaction]);

  let initialMarginsPaid = 0;
  transactions?.forEach((transaction) => {
    const sumOfInitialMargins =
      transaction.margins?.reduce((sum, margin) => {
        return margin.type === "IM" && margin.from.currency === transaction.from.currency
          ? sum + Number(margin.from.quantity)
          : sum;
      }, 0) ?? 0;
    initialMarginsPaid += sumOfInitialMargins;
  });

  const totalMarginCallsSum = useMemo(() => {
    const rate = marginCallCurrency === "PLN" ? 1 : rateInfo?.rate ?? 0;
    return rate ? totalLocalGlobalMarginQuantityPLN / rate - companyInitialMarginRequests + initialMarginsPaid : 0;
  }, [
    marginCallCurrency,
    rateInfo?.rate,
    totalLocalGlobalMarginQuantityPLN,
    companyInitialMarginRequests,
    initialMarginsPaid,
  ]);

  const gmcId = useMemo((): string => {
    if (!company) return "";
    if (!company.gmcId) return "GMC-1";
    const newGmcId = company.gmcId.split("-")[1];
    return `GMC-${Number(newGmcId) + 1}`;
  }, [company]);

  const marginCall = useMemo(
    () =>
      ({
        id: gmcId,
        callDate: timestamp(),
        callRate,
        clientRate,
        transQuantity: remainingQuantity,
        totalPaidMargin: {
          quantity: totalMarginCallsSum,
          currency: marginCallCurrency,
        },
        quantity,
        isClosed: "no",
        isGMC: "yes",
        globalTransactionsIds: transactions?.map((transaction) => ({
          id: transaction.id,
          number: transaction.number,
        })),
        currency: transactions?.[0]?.from?.currency,
        dealType: transactions?.[0]?.dealType,
      } as GlobalMarginCall),
    [
      callRate,
      clientRate,
      gmcId,
      marginCallCurrency,
      quantity,
      remainingQuantity,
      timestamp,
      totalMarginCallsSum,
      transactions,
    ]
  );

  useEffect(() => {
    if (rateInfo) {
      if (transaction.dealType === DEAL_TYPE_BUY) {
        const modifier = Number(clientRate) <= Number(callRate) ? -1 : 1;
        const newMarginCallQuantity = formatNumber(
          Math.abs(Number(callRate) - Number(clientRate)) * remainingQuantity * modifier - totalMarginCallsSum
        );
        setMarginCallQuantity(newMarginCallQuantity);
        setTotalMarginCallQuantity(newMarginCallQuantity + totalMarginCallsSum);
        setQuantity(newMarginCallQuantity);

        const variationC =
          ((Number(callRate) - Number(clientRate)) / ((Number(callRate) + Number(clientRate)) / 2)) * 100;
        const variation =
          ((Number(clientRate) - Number(rateInfo.rate)) / ((Number(clientRate) + Number(rateInfo.rate)) / 2)) * 100;
        setVariationC(formatNumber(variationC).toFixed(4));
        setVariation(formatNumber(-variation).toFixed(4));
      }

      if (transaction.dealType === DEAL_TYPE_SELL) {
        const modifier = Number(clientRate) >= Number(callRate) ? -1 : 1;
        const newMarginCallQuantity = formatNumber(
          ((Math.abs(Number(callRate) - Number(clientRate)) * remainingQuantity) / Number(callRate)) * modifier -
            totalMarginCallsSum
        );
        setMarginCallQuantity(newMarginCallQuantity);
        setTotalMarginCallQuantity(newMarginCallQuantity + totalMarginCallsSum);
        if (newMarginCallQuantity > 0) {
          setQuantity(newMarginCallQuantity);
        } else {
          setQuantity(0);
        }

        const variationC =
          ((Number(callRate) - Number(clientRate)) / ((Number(callRate) + Number(clientRate)) / 2)) * 100;
        const variation =
          ((Number(rateInfo.rate) - Number(clientRate)) / ((Number(rateInfo.rate) + Number(clientRate)) / 2)) * 100;
        setVariationC(formatNumber(variationC).toFixed(4));
        setVariation(formatNumber(variation).toFixed(4));
      }
    }
  }, [callRate, clientRate, rateInfo, remainingQuantity, transaction, totalMarginCallsSum]);

  useEffect(() => {
    if (rateInfo) {
      setCallRate(formatNumber(rateInfo?.rate, 4));
    }
  }, [rateInfo]);

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    if (marginCallQuantity <= 0) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [marginCallQuantity]);

  const handleAddGmc = async () => {
    if (transactions && transactions.length && company) {
      await addGMC(marginCall, transactions, company);
    }
  };

  return (
    <>
      <Modal
        confirmText={t("Yes")}
        design="danger"
        isDisabled={isSending}
        isOpen={isModalOpen}
        onClose={handleClose}
        onConfirm={handleAddGmc}
        title={t(`marginCall:Create global margin call`)}
      >
        <Box mb={"20px"}>{t("hedge:Are you sure that you want to create global margin call?")}</Box>
      </Modal>
      <StyledFieldsLayout>
        <StyledLabel>{t("marginCall:Remaining Quantity")}</StyledLabel>
        <Input
          formatNumberOnBlur
          id="transQty.quantity"
          isDisabled
          name="transQty.quantity"
          rightAddon={transaction.from.currency}
          type="number"
          value={remainingQuantity}
        />
        <StyledLabel>{t("Client Rate")}</StyledLabel>
        <Input formatNumberOnBlur id="clientRate" isDisabled name="clientRate" type="number" value={clientRate} />
        <StyledLabel>
          {t(`marginCall:NBP Exchange Rate(V {{v}}%)`, {
            v: variation,
          })}
        </StyledLabel>
        <Input id="nbpRate" isDisabled name="nbpRate" type="number" value={formatNumber(rateInfo?.rate, 4)} />
        <StyledLabel>
          {t(`marginCall:Margin Call Rate (Vc {{vc}}%)`, {
            vc: variationC,
          })}
        </StyledLabel>
        <Input
          formatNumberOnBlur
          formatNumberPrecision={4}
          id="callRate"
          name="callRate"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCallRate(Number(e.target.value))}
          type="number"
          value={callRate}
        />
        <StyledLabel>{t("marginCall:Total Margin Call Quantity")}</StyledLabel>
        <Input
          formatNumberOnBlur
          id="totalMarginCallQuantity"
          isDisabled
          name="totalMarginCallQuantity"
          rightAddon={marginCallCurrency}
          type="number"
          value={totalMarginCallQuantity}
        />
        <StyledLabel>{t("marginCall:Margin Balance (V)")}</StyledLabel>
        <Input
          formatNumberOnBlur
          id="marginBalance"
          isDisabled
          name="marginBalance"
          rightAddon={marginCallCurrency}
          type="number"
          value={Number(totalMarginCallsSum)}
        />
        <StyledLabel>{t("marginCall:Margin Call Quantity")}</StyledLabel>
        <Input
          formatNumberOnBlur
          id="marginCallQuantity"
          isDisabled
          name="marginCallQuantity"
          rightAddon={marginCallCurrency}
          type="number"
          value={marginCallQuantity}
        />
        <StyledLabel>{t("marginCall:Margin Call Quantity (mail)")}</StyledLabel>
        <Input
          formatNumberOnBlur
          id="quantity"
          name="quantity"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
          rightAddon={marginCallCurrency}
          type="number"
          value={quantity}
        />
      </StyledFieldsLayout>
      <Button
        design="primary"
        isDisabled={buttonDisable || isSending}
        onClick={handleOpen}
        style={{ marginTop: "30px", width: "100%" }}
      >
        {t("Add")}
      </Button>
    </>
  );
};
