import { COUNTRIES, CURRENCIES } from "helpers/options";
import {
  AUTHORIZED_PERSON,
  BENEFICIARY,
  CONTACT_ACTIVE,
  CONTACT_INACTIVE,
  EXTERNAL,
  LEGAL_REPRESENTANT,
  SALES,
} from "helpers/contact";

export enum UserRole {
  ADMIN = "Admin",
  DEALER = "Dealer",
  SENIOR_EMPLOYEE = "Senior Employee",
  EMPLOYEE = "Employee",
  OFFICE_MANAGER = "Office Manager",
  DISABLED = "Disabled",
}
export enum UserUnauthorized {
  UNATHORIZED = "Unauthorized",
}

export interface BaseProfile {
  name: string;
  surname: string;
  email: string;
  userId: string;
  roles?: Array<string>;
  role: UserRole;
  lang?: string;
  contactId?: string;
  connectedWith?: {
    firstName: string;
    lastName: string;
  };
  companies?: string[];
}

export type Profile = BaseProfile & DatabaseFields;

export interface BaseContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: Address;
  companies?: Company[];
  idDetails: {
    pesel: string;
    idType: string;
    number: string;
    expirationDate: string;
  };
  status: typeof CONTACT_ACTIVE | typeof CONTACT_INACTIVE;
  confirmed?: boolean;
  ip?: string;
  type?: typeof SALES | typeof EXTERNAL | typeof AUTHORIZED_PERSON | typeof LEGAL_REPRESENTANT | typeof BENEFICIARY;
  companyAssociated?:
    | {
        id: string;
        name: string;
      }
    | {};
  cc?: string;
  disableEmails?: boolean;
  source?: string;
  other?: string;
  citizenship?: string;
}

export type Contact = BaseContact & DatabaseFields;

interface DatabaseFields {
  id: string;
  createdAt: Timestamp;
  modifiedAt: Timestamp;
}

export interface CompanyDetails {
  industry: string;
  goal: string;
  source: string;
  currency: Currency;
  limit: number | "";
  notes?: string;
  password?: string;
  score?: string;
  nolimits?: boolean;
}

export interface CompanyOther {
  notes: string;
  communicationLanguage: "PL" | "EN";
  ip: string;
}

export interface BankAccount {
  id?: string;
  number: string;
  name: string;
  swift: string;
  currency: Currency;
  country?: Country;
  isDefault?: boolean;
}

export type Country = (typeof COUNTRIES)[number]["value"];

export type Currency = (typeof CURRENCIES)[number]["value"];

export interface Address {
  street: string;
  number: string;
  code: string;
  city: string;
  country: Country;
}

export interface PaymentEntry {
  amount: number;
  currency: string;
  marginId: string;
  date: Timestamp;
  comment?: string;
}

export interface BaseCompany {
  name: string;
  nip: string;
  krs: string;
  legalEntity: string;
  regon: string;
  phone: string;
  details: CompanyDetails;
  companyAddress: Address;
  contactAddress: Address;
  other: CompanyOther;
  contacts?: Array<Contact>;
  bankAccounts: Array<BankAccount>;
  cc?: boolean;
  disableEmails?: boolean;
  isDeactivated?: boolean;
  reversePayment?: {
    limit: CurrencyValue;
    date?: Timestamp;
  };
  globalMargins?: Array<Margin>;
  globalMarginCalls?: Array<GlobalMarginCall>;
  gmcId?: string;
  paymentEntries?: Array<PaymentEntry>;
  scoring?: {
    questions: Array<{
      questionId: number;
      score: number;
    }>;
  };
}

export type Company = BaseCompany & DatabaseFields;

export interface Application extends Company {
  phone: string;
  comment?: string;
}

export type TransactionType = "FR" | "Order" | "Spot";

export type MarginType = "IM" | "VM";

export type TransactionStatus = "closed" | "rolled" | "created" | "partiallySettled" | "settled";

export interface BaseInstitution {
  name: string;
  securityDeposit: number;
}

export type Institution = BaseInstitution & DatabaseFields;

export type DeliveryType = "Physical" | "Net";

export interface CurrencyValue {
  currency: Currency;
  quantity: number;
}

export interface TransactionCompany {
  name: string;
  id: string;
  isDeactivated?: "yes" | "no";
}

export interface BaseTransaction {
  company: TransactionCompany;
  type: TransactionType;
  number: number;
  clientRate: number;
  ourRate: number;
  dealType: string;
  from: CurrencyValue;
  to: CurrencyValue;
  leftQuantity?: number;
  end?: Timestamp;
  systemDate?: Timestamp;
  currencyDate?: Timestamp;
  start?: Timestamp;
  expiration?: Timestamp;
  initialMargin?: number;
  additionalMargin?: string;
  status: TransactionStatus;
  agreement?: Timestamp;
  account?: BankAccount;
  comment?: string;
  dashboardComment?: string; // @deprecated
  dashboardComments?: DashboardComments;
  settlements?: Array<Settlement>;
  marginCalls?: Array<MarginCall>;
  hedges?: Array<Hedge>;
  margins?: Array<Margin>;
  ignoreMarginCallRisk?: boolean;
  createdFrom?: {
    number: number;
    id: string;
  };
  convertedFrom?: {
    number: number;
    id: string;
  };
  convertedTo?: {
    number: number;
    id: string;
  };
  prolongedBy?: {
    number: number;
    id: string;
  };
}

export type DashboardComments = {
  [key in DashboardType]: string;
};

export type DashboardType = "unhedged" | "soonToEnd" | "marginCall" | "rollHedge" | "initialMargin";

export type Transaction = BaseTransaction & DatabaseFields;

export interface Settlement {
  id: string;
  createdAt?: Timestamp;
  modifiedAt?: Timestamp;
  date: Timestamp;
  type: string;
  quantity: number;
  fwdPoints: number;
  rollback: number;
  points: number;
  comment?: string;
  paymentComment?: string;
  isReversePayment?: boolean;
  isSent?: boolean;
  isDone?: boolean;
  isReversePaymentOk?: boolean;
  closedRate?: number;
  hedges: Array<{
    hedgeId: string;
    quantity: number;
    fwd: number;
    roll: number;
  }>;
}

export interface MarginCall {
  id: string;
  createdAt?: Timestamp;
  modifiedAt?: Timestamp;
  createdFromId?: string;
  callDate: Timestamp;
  callRate: number;
  clientRate?: number;
  transQuantity: number;
  quantity: number;
  isClosed?: "yes" | "no";
  isGMC?: "yes" | "no";
  totalPaidMargin?: {
    quantity: number;
    currency: string;
  };
  globalTransactionsIds?: Array<{ id: string; number: number }>;
  isPaid?: boolean;
  comment?: string;
  tableComment?: string;
  margins?: Array<{
    marginId: string;
    isGlobal?: boolean;
    quantity: number;
    currency: Currency;
  }>;
  finalNoticeSentAt?: Timestamp;
}

export interface MarginFinalNotice {
  transactions: Array<{
    number: number;
    date: Timestamp;
    _id: string;
  }>;
  createdAt: Timestamp;
  callDate: Timestamp;
  callRate: number;
  transactionsQuantity: CurrencyValue;
  marginCallQuantity: CurrencyValue;
  finalNoticeQuantity: CurrencyValue;
  paidMargin?: CurrencyValue;
  bankAccount?: BankAccount;
  _marginCallId: string;
}

export interface GlobalMarginCall extends MarginCall {
  company: Company;
  currency: Currency;
  dealType: string;
}

export interface MarginOperation {
  id: number;
  type: string;
  value: CurrencyValue;
  oldMarginValue: CurrencyValue;
  newMarginValue: CurrencyValue;
  date: Timestamp;
  comment?: string;
}

export interface Margin {
  id: string;
  createdAt?: Timestamp;
  modifiedAt?: Timestamp;
  date: Timestamp;
  type: MarginType;
  from: CurrencyValue;
  to: CurrencyValue;
  left: CurrencyValue;
  marginCallId?: string;
  operations?: Array<MarginOperation>;
  comment?: string;
  createdFromMovement?: boolean;
}

export interface Operation {
  id: string;
  date: Timestamp;
  settlementDate: Timestamp;
  from: CurrencyValue;
  to: CurrencyValue;
  status: string;
}

export interface Hedge {
  id: string;
  createdAt?: Timestamp;
  modifiedAt?: Timestamp;
  start: Timestamp;
  end: Timestamp;
  institution: string;
  points: number;
  quantity: number;
  leftQuantity: number;
  rolledQuantity?: number;
  security: number;
  comment?: string;
  createdFromId?: string;
  createdFromData?: Array<{
    hedgeId: string;
    quantity: number;
  }>;
}

export type CommissionFee = {
  fee?: number;
  expiration?: Timestamp;
};

export type CommissionFeeExtended = CommissionFee & Contact;

export type Payment = Settlement & {
  transaction: Omit<Transaction, "settlements">;
};

export type MarginCallExtended = MarginCall & {
  transaction: Omit<Transaction, "marginCalls">;
};

export interface Activity {
  action:
    | "view"
    | "create"
    | "edit"
    | "delete"
    | "convert"
    | "roll"
    | "cancel"
    | "close"
    | "open"
    | "approve"
    | "deactivate"
    | "activate";
  actionDetails?: string;
  item?: {
    collection: string;
    id: string;
    name?: string;
  };
  transactionId?: string;
  company?: {
    id: string;
    name: string;
  };
  url?: string;
  oldData?: string;
  newData?: string;
}

export interface Log extends Activity {
  user: Profile;
  timestamp: Timestamp;
}

export interface WeightedAverages {
  val: number;
  weight: number;
}

export interface AvaragesByCurrencyProps {
  settled: WeightedAverages;
  remaining: WeightedAverages;
}

export interface Question {
  id: number;
  title: string;
  weight: number;
  status: "active" | "inactive";
}
export interface Scoring extends DatabaseFields {
  config: {
    limit: number;
  };
  questions: Array<Question>;
}

export interface NbpRateRecord {
  createdAt: Timestamp;
  effectiveDate: string;
  modifiedAt: Timestamp;
  no: string;
  rates: {
    [key: string]: number;
  };
}
