import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { Button } from "components/Button/Button";
import { useResetPassword } from "hooks/useResetPassword";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";

const RequestResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const ERROR_MESSAGES: { [key: string]: string } = {
    "auth/user-not-found": "userNotFound",
    "auth/invalid-email": "invalidEmail",
  };
  const [error, setError] = React.useState<string>("");
  const { requestResetPassword } = useResetPassword();
  const toast = useToast();

  const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.persist();
    const target = e.target as HTMLFormElement;

    requestResetPassword(target.email.value).then((err) => {
      if (err) {
        const message = ERROR_MESSAGES[err.code];

        setError(message);
      } else {
        target.reset();
        toast({
          type: "success",
          message: t("login:Password reset link sent. Check your mailbox."),
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="xl">
      <Heading as="h1" fontSize="42px" fontWeight="bold" mb={12}>
        {t("login:Reset your password")}
      </Heading>
      <form onSubmit={handleSubmit}>
        <Input error={error} id="email" label="E-mail" name="email" type="text" withError />
        <Box alignContent="center" display="flex" mb={7}>
          <Button design="primary" type="submit">
            {t("login:Send new password")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default RequestResetPassword;
