import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  margin-left: 20px;
  height: 52px;
  display: flex;
  z-index: 5;
`;

export const StyledLoggedInPane = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const StyledLoggedInItem = styled.button`
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
