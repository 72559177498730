import React from "react";
import { Label, StyledValue, TotalValueStyled } from "./TotalValue.styled";
import { Box, BoxProps } from "@chakra-ui/react";
import { formatNumberToString } from "../../../../helpers/formatNumber";

type TotalProps = BoxProps & {
  label?: string;
  quantity?: number;
  currency?: string;
};

export const TotalValue: React.FC<TotalProps> = ({ label, quantity, currency, ...rest }) => {
  const total = quantity ? `${formatNumberToString(quantity)} ${currency}` : "-";

  return (
    <Box as={TotalValueStyled} {...rest}>
      <Label>{`${label}: `}</Label>
      <StyledValue>{total}</StyledValue>
    </Box>
  );
};
