import React from "react";
import { getMarginCallBalance } from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import { GlobalMarginCall } from "interfaces/data";
import { StyledTag } from "views/MarginCalls/components/styles";

export function StatusGmcCell(arg: any) {
  const { rates: nbpRates } = useNbpRates();
  const marginCall = arg.row.original as GlobalMarginCall;

  if (!marginCall || !nbpRates) return "";

  if (marginCall.isClosed === "yes") {
    return <b>closed</b>;
  }

  const currency = marginCall.currency;
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);

  if (paid === 0) {
    return <StyledTag color="sk-red">unpaid</StyledTag>;
  }
  if (paid >= marginCall.quantity) {
    return <StyledTag color="sk-light-green">ok</StyledTag>;
  }
  return <StyledTag color="sk-yellow">partially</StyledTag>;
}
