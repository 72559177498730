import dayjs from "dayjs";
import { getMilisecondsFromTimestamp } from "./date";

export const isCommissionExpired = (expiration: any, relatedDate: any) => {
  if (!expiration) {
    return false;
  }

  return dayjs(getMilisecondsFromTimestamp(relatedDate)).isAfter(dayjs(getMilisecondsFromTimestamp(expiration)), "day");
};
