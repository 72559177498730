import * as yup from "yup";
import { krsValidation, nipValidation, regonValidation } from "./shared";

export const applicationSchema = yup.object().shape({
  name: yup.string().required().trim(),
  nip: yup.string().when("companyAddress.country", {
    is: "PL",
    then: nipValidation.required().trim(),
  }),
  krs: krsValidation.nullable().trim(),
  legalEntity: yup.string().trim(),
  phone: yup.string().trim(),
  comment: yup.string().trim().optional(),
  regon: yup.string().when("companyAddress.country", {
    is: "PL",
    then: regonValidation.trim(),
  }),
  details: yup.object().shape({
    industry: yup.string().trim(),
    goal: yup.string().trim(),
    source: yup.string().trim(),
    currency: yup.string().nullable().trim(),
    limit: yup.string().nullable().trim(),
    notes: yup.string().nullable().trim(),
    password: yup.string().nullable().trim(),
  }),
  companyAddress: yup.object().shape({
    street: yup.string().trim(),
    number: yup.string().trim(),
    code: yup.string().trim(),
    city: yup.string().trim(),
    country: yup.string().trim(),
  }),
  contactAddress: yup.object().shape({
    street: yup.string().trim(),
    number: yup.string().trim(),
    code: yup.string().trim(),
    city: yup.string().trim(),
    country: yup.string().trim(),
  }),
  other: yup.object().shape({
    notes: yup.string().trim(),
    ip: yup.string().trim(),
    communicationLanguage: yup.mixed().oneOf(["PL", "EN"]),
  }),
  bankAccounts: yup.array().of(
    yup.object().shape({
      name: yup.string().trim(),
      number: yup.string().required().trim(),
      swift: yup.string().trim(),
      currency: yup.string().trim(),
      country: yup.string().trim(),
    })
  ),
});
