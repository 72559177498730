import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { formatNumberToString } from "helpers/formatNumber";
import { useHotkeys } from "hooks/useHotkeys";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { StyledContainer, StyledEditableField, StyledEditContainer } from "./shared.styled";
import { ApproveIcon } from "theme/icons";

interface CommissionFeeCellProps {
  row: any;
  onSave: (newFee: string) => any;
}

export const CommissionFeeCell: React.FC<CommissionFeeCellProps> = ({ row, onSave }) => {
  const fee = row.original.commissionFee.fee;
  const [isEdited, setIsEdited] = useState(false);
  const [commissionFee, setCommissionFee] = useState(fee);
  const editRef = useRef(null);

  useEffect(() => {
    setCommissionFee(fee);
  }, [fee]);

  const handleSave = useCallback(() => {
    onSave(commissionFee);
    setIsEdited(false);
  }, [commissionFee, onSave]);

  const handleClose = useCallback(() => {
    setIsEdited(false);
    setCommissionFee(fee);
  }, [fee]);

  useOnClickOutside(editRef, handleClose);
  useHotkeys("Escape", handleClose);
  useHotkeys("Enter", () => {
    isEdited && handleSave();
  });

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCommissionFee(e.target.value);
  }, []);

  return (
    <StyledContainer>
      {isEdited ? (
        <StyledEditContainer ref={editRef}>
          <Input
            containerProps={{
              width: "100%",
            }}
            formatNumberOnBlur
            fullWidth
            name="commissionFee"
            onChange={handleChange}
            type="number"
            value={commissionFee}
            width="100%"
          />
          <Button design="primary" height="100%" isDisabled={!commissionFee} ml="11px" onClick={handleSave} p="18px">
            <ApproveIcon />
          </Button>
        </StyledEditContainer>
      ) : (
        <StyledEditableField onClick={() => setIsEdited(true)}>
          {fee != null ? `${formatNumberToString(fee, 4)}%` : "-"}
        </StyledEditableField>
      )}
    </StyledContainer>
  );
};
