import React, { useMemo, useState } from "react";
import { Contact as ContactItem } from "../../Company/Contacts/Contact";
import { Contact } from "../../../interfaces/data";
import styled from "@emotion/styled";
import { LEGAL_REPRESENTANT } from "../../../helpers/contact";
import { StyledButton, Section, SectionTitle } from "../shared.styled";
import { useTranslation } from "react-i18next";

interface ContactPersonSectionProps {
  contacts?: Array<Contact>;
}

const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

export const ContactPersonSection = ({ contacts }: ContactPersonSectionProps) => {
  const [isExpanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const filteredContacts = useMemo(() => (contacts || []).filter((contact) => contact.source !== "yes"), [contacts]);

  const companyContacts = useMemo(() => {
    if (filteredContacts.length === 0) return t("company:No contacts");

    if (!isExpanded) {
      const representative = filteredContacts.find((contact) => contact.type === LEGAL_REPRESENTANT);
      const contact = representative ? representative : filteredContacts[0];
      return <ContactItem {...contact} hideAvatar roleOnBottom trackPreviousUrl />;
    }

    return filteredContacts.map((contact, index) => (
      <ContactItem {...contact} hideAvatar key={index} roleOnBottom trackPreviousUrl />
    ));
  }, [filteredContacts, isExpanded, t]);

  return (
    <Section>
      <SectionTitle>{t("company:Contact person")}</SectionTitle>
      {companyContacts}
      {filteredContacts.length > 1 && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("common:Hide") : t("common:Show more")}
          </StyledButton>
        </ButtonContainer>
      )}
    </Section>
  );
};
