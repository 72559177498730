import React, { PropsWithChildren } from "react";
import { Avatar, Popover, PopoverContent, PopoverTrigger as OrigPopoverTrigger } from "@chakra-ui/react";
import { useActiveUsers } from "hooks/useActiveUsers";
import {
  StyledAvatarContainer,
  StyledLink,
  StyledPopoverBody,
  StyledUserActivityBox,
  StyledWarningBox,
} from "./UserActivityBox.styled";
import { useTranslation } from "react-i18next";
import { WarningIcon } from "@chakra-ui/icons";

export const PopoverTrigger: React.FC<PropsWithChildren> = OrigPopoverTrigger;

export const ActivityBox: React.FC = () => {
  const { activeUsers, warning } = useActiveUsers();
  const { t } = useTranslation();

  if (!activeUsers || activeUsers.length === 0)
    return <StyledUserActivityBox>{t("multiuser:No other users online")}</StyledUserActivityBox>;

  const onlineUsersCounter =
    activeUsers.length > 1
      ? t(`multiuser:{{count}} users online`, { count: activeUsers.length })
      : t("multiuser:1 user online");

  return (
    <StyledUserActivityBox hasWarning={Boolean(warning)}>
      {Boolean(warning) && (
        <StyledWarningBox>
          <WarningIcon mr="8px" mt="-2px" />
          {warning}
        </StyledWarningBox>
      )}
      {onlineUsersCounter}:
      {activeUsers.map((activeUser) => (
        <Popover key={activeUser.id} trigger="hover">
          <PopoverTrigger>
            <StyledAvatarContainer>
              <Avatar ml="10px" name={activeUser.name} size="xs" />
            </StyledAvatarContainer>
          </PopoverTrigger>
          <PopoverContent>
            <StyledPopoverBody>
              <b>{activeUser.name}</b> {t("multiuser:is browsing")}:{" "}
              <StyledLink to={activeUser.url}>{activeUser.url}</StyledLink>
            </StyledPopoverBody>
          </PopoverContent>
        </Popover>
      ))}
    </StyledUserActivityBox>
  );
};

export const UserActivityBox = React.memo(ActivityBox);
