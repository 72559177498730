import React, { FC } from "react";
import { MarginInfo } from "../GlobalMarginsMigration";
import { Modal } from "components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import _deepClone from "lodash.clonedeep";
import _omit from "lodash.omit";
import { useCompany } from "hooks/useCompany";
import { formatNumberToString } from "helpers/formatNumber";
import styled from "@emotion/styled";
import { Margin } from "interfaces/data";
import { createFirestoreTimestamp, transformTimestampToDate } from "helpers/date";

interface MigrateModalProps {
  margin: MarginInfo;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (margin: Margin) => void;
}

const StyledLabel = styled.div`
  color: grey;
  margin-right: 1.5rem;
  text-align: right;
  font-style: italic;
`;

const StyledComparison = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0.5rem;
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.9rem;
`;

const StyledValue = styled.div``;

export const MigrateModal: FC<MigrateModalProps> = ({ margin, isOpen, onClose, onConfirm }) => {
  const { t } = useTranslation();
  const { company } = useCompany({ id: margin.transaction.company.id });

  if (!company) return null;
  const migrationMargin = _omit(_deepClone(margin), ["transaction"]) as Margin;
  migrationMargin.id = `g-${(company?.globalMargins?.length || 0) + 1}`;
  delete migrationMargin.marginCallId;
  migrationMargin.date = createFirestoreTimestamp(margin.date);

  // calculate remaining margin
  const used = migrationMargin.operations
    ? migrationMargin.operations.reduce((acc, op) => {
        return op.type === "WITHDRAW" ? acc + op.value.quantity : acc - op.value.quantity;
      }, 0)
    : 0;
  migrationMargin.left.quantity = migrationMargin.from.quantity - used;
  migrationMargin.left.currency = migrationMargin.from.currency;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => onConfirm(migrationMargin)}
      title={`#${margin.id} from ${margin.marginCallId} migration`}
    >
      <Box mb={"20px"}>
        <StyledComparison>
          <StyledLabel>Date:</StyledLabel>
          <StyledValue>{transformTimestampToDate(margin.date)}</StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>Type:</StyledLabel>
          <StyledValue>{margin.type}</StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>From:</StyledLabel>
          <StyledValue>
            {formatNumberToString(margin.from.quantity)} {margin.from.currency}
          </StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>To:</StyledLabel>
          <StyledValue>
            {formatNumberToString(margin.to.quantity)} {margin.to.currency}
          </StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>ID:</StyledLabel>
          <StyledValue>{margin.id}</StyledValue>
          <StyledValue>→</StyledValue>
          <StyledValue>{migrationMargin.id}</StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>Margin Call:</StyledLabel>
          <StyledValue>{margin.marginCallId}</StyledValue>
          <StyledValue>→</StyledValue>
          <StyledValue>{migrationMargin.marginCallId || "none"}</StyledValue>
        </StyledComparison>
        <StyledComparison>
          <StyledLabel>Left:</StyledLabel>
          <StyledValue>
            {formatNumberToString(margin.left.quantity)} {margin.left.currency}
          </StyledValue>
          <StyledValue>→</StyledValue>
          <StyledValue>
            {formatNumberToString(migrationMargin.left.quantity)} {migrationMargin.left.currency}
          </StyledValue>
        </StyledComparison>
      </Box>
      <Box mb={"20px"}>{t("hedge:Are you sure that you want to migrate global margin?")}</Box>
    </Modal>
  );
};
