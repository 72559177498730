import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import _set from "lodash.set";
import { Modal } from "components/Modal/Modal";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { SelectWithLabel } from "components/SelectWithLabel/SelectWithLabel";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { TextareaWithLabel } from "components/TextareaWithLabel/TextareaWithLabel";
import { useFirebase } from "hooks/useFirebase";
import { Hedge, Transaction } from "interfaces/data";
import { formatDate, getTimestamp } from "helpers/date";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useInstitutions } from "hooks/useInstitutions";
import { Spinner } from "components/Spinner/Spinner";
import { useIsMounted } from "hooks/useIsMounted";
import { ArrowLeftIcon } from "theme/icons";

dayjs.extend(isSameOrAfter);

interface HedgeFormModalProps {
  type: "add" | "roll" | "roll-multiple" | "edit";
  title: string;
  initialHedge: Partial<Hedge>;
  maxQuantity?: number;
  transaction: Transaction;
  errors: any;
  onSave: (newHedge: Partial<Hedge>) => void;
  onClose: () => void;
  isDisabled?: boolean;
}

export const HedgeGrid = styled(Box)`
  > div {
    margin-bottom: 10px;
  }
`;

export const EndDateLabel = styled(Box)`
  display: flex;
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 110%;
  padding: 17px 0 0 5px;

  > p {
    padding-left: 8px;
    font-weight: 700;
    color: ${(props) => props.theme.colors["sk-dark"]};
  }
`;

export const HedgeFormModal: React.FC<HedgeFormModalProps> = ({
  type,
  title,
  initialHedge,
  maxQuantity,
  transaction,
  errors,
  onSave,
  onClose,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [hedge, setHedge] = useState(initialHedge);
  const { timestampFrom } = useFirebase();
  const { isMounted } = useIsMounted();

  if (!hedge.institution) {
    hedge.institution = "Velocity";
  }

  const { institutions, loading } = useInstitutions();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setHedge((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      if (e.target.type === "number") {
        _set(newEditedData, e.target.name, Number(e.target.value));
      } else {
        _set(newEditedData, e.target.name, e.target.value);
      }
      return newEditedData;
    });
  }, []);

  const handleUpdateNumber = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setHedge((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, Number(e.target.value));
      return newEditedData;
    });
  }, []);

  const handleInstitutionUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setHedge((oldEditedData) => {
        const institutionName = e.target.value;
        const selectedInstitution = institutions.find((institution) => institution.name === institutionName);

        const newEditedData = { ...oldEditedData };
        _set(newEditedData, "institution", institutionName);
        _set(newEditedData, "security", Number(selectedInstitution?.securityDeposit) || 0);
        return newEditedData;
      });
    },
    [institutions]
  );

  const handleUpdateDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setHedge((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        const date = new Date(e.target.value);
        _set(newEditedData, e.target.name, timestampFrom(date));
        return newEditedData;
      });
    },
    [timestampFrom]
  );

  const isQuantityValid = useMemo(() => {
    if (maxQuantity == null || isDisabled) {
      return true;
    }
    return Number(hedge.quantity) <= Number(maxQuantity) && Number(hedge.quantity) > 0;
  }, [hedge, maxQuantity, isDisabled]);

  const isPartiallyUsed = useMemo(
    () => type === "edit" && Number(initialHedge.quantity) !== Number(initialHedge.leftQuantity),
    [type, initialHedge]
  );

  if (!hedge || !transaction || !isMounted) {
    return null;
  }

  if (loading) return <Spinner />;

  return (
    <>
      <Modal
        confirmText={t(type === "roll" || type === "roll-multiple" ? "transaction:Roll" : "Save")}
        design="primary"
        isOpen
        justifyButtons="flex-end"
        minWidth={899}
        onClose={onClose}
        onConfirm={() => {
          if (
            transaction.end &&
            hedge.end &&
            !dayjs(getTimestamp(transaction.end)).isSameOrAfter(dayjs(getTimestamp(hedge.end)), "day")
          ) {
            setShowConfirmation(true);
          } else {
            onSave(hedge);
          }
        }}
        title={title}
      >
        <Box mb="10px" pr="20px" width="50%">
          <InputWithLabel
            error={errors?.["start"]}
            id="start"
            isDisabled={type === "roll" || type === "roll-multiple"}
            label={t("hedge:Start date")}
            max={formatDate(transaction.end)}
            name="start"
            onChange={handleUpdateDate}
            type="date"
            value={formatDate(hedge?.start)}
            withError={!!errors?.["start"]}
          />
        </Box>
        <TwoColumnsLayout withPaddingBottom>
          <InputWithLabel
            error={errors?.["end"]}
            id="end"
            label={t("hedge:End date")}
            max={formatDate(transaction.end)}
            name="end"
            onChange={handleUpdateDate}
            type="date"
            value={formatDate(hedge?.end)}
            withError={!!errors?.["end"]}
          />
          <EndDateLabel>
            <ArrowLeftIcon
              mr="10px"
              name="arrow-left"
              onClick={() => {
                setHedge((oldEditedData) => {
                  const newEditedData = { ...oldEditedData };
                  transaction.end && _set(newEditedData, "end", transaction.end);
                  return newEditedData;
                });
              }}
              style={{
                cursor: "pointer",
              }}
            />
            <span>{t("End transaction date")}: </span>
            <p>{formatDate(transaction.end, "DD/MM/YYYY")}</p>
          </EndDateLabel>
        </TwoColumnsLayout>
        <HedgeGrid pr="20px" width="50%">
          <SelectWithLabel
            error={errors?.["institution"]}
            id="institution"
            label={t("hedge:Institution")}
            name="institution"
            onChange={handleInstitutionUpdate}
            options={[
              ...institutions.map((institution) => {
                return { label: institution.name, value: institution.name };
              }),
            ]}
            placeholder=""
            value={hedge.institution}
          />
          <InputWithLabel
            error={errors?.["points"]}
            formatNumberOnBlur
            formatNumberPrecision={4}
            id="points"
            isDisabled={type === "roll-multiple" || isPartiallyUsed}
            label={t("hedge:Forward points")}
            name="points"
            onChange={handleUpdateNumber}
            step="0.1"
            type="number"
            value={hedge.points || 0}
            withError={!!errors?.["points"]}
          />
          <InputWithLabel
            error={errors?.["quantity"] || !isQuantityValid}
            formatNumberOnBlur
            id="quantity"
            isDisabled={type === "roll-multiple" || isPartiallyUsed}
            label={t("hedge:Quantity")}
            name="quantity"
            onChange={handleUpdateNumber}
            rightAddon={transaction.from.currency || "-"}
            type="number"
            value={hedge.quantity}
            withError={!!errors?.["quantity"]}
          />
          <InputWithLabel
            error={errors?.["security"]}
            formatNumberOnBlur
            id="security"
            isDisabled
            label={t("hedge:Our depo security")}
            name="security"
            onChange={handleUpdateNumber}
            rightAddon="%"
            step="0.1"
            type="number"
            value={hedge.security || ""}
            withError={!!errors?.["security"]}
          />
        </HedgeGrid>
        <TextareaWithLabel
          id="comment"
          label={t("hedge:Comment")}
          labelProps={{
            paddingRight: "10px",
          }}
          name="comment"
          onChange={handleUpdate}
          value={hedge?.comment}
        />
      </Modal>
      <Modal
        design="primary"
        isChild
        isOpen={showConfirmation}
        minWidth={550}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => onSave(hedge)}
        title={t(`hedge:Invalid hedge's end date`)}
      >
        {t("hedge:Transaction's end date is before hedge's end date. Are you sure you want to create this hedge?")}
      </Modal>
    </>
  );
};
