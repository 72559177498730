import React from "react";
import { Global } from "@emotion/react";
import theme from "./theme";

export const GlobalStyles = () => (
  <Global
    styles={{
      body: {
        margin: 0,
        color: theme.colors["sk-dark"],
      },
      "body, html, #root": {
        height: "100%",
        width: "100%",
      },
      "#root *": {
        boxSizing: "border-box",
      },
      "body *:focus, body input[type='checkbox']:focus + div:first-of-type": {
        outline: "none !important",
        boxShadow: "none !important",
      },
      "body.has-keyboard-focus *:focus, body.has-keyboard-focus input[type='checkbox']:focus + div:first-of-type": {
        outline: "2px solid #7aacfe !important",
      },
      ".Toaster__manager-top": {
        maxWidth: "100% !important",
        paddingRight: "50px",
        paddingLeft: "50px",
        minWidth: "1440px !important",
      },
      ".loggedIn .Toaster__manager-top": {
        paddingLeft: "304px",
      },
      ".Toaster__message-wrapper": {
        flexBasis: "100%",
        width: "100%",
      },
      ".Toaster__message-wrapper > div": {
        backdropFilter: "blur(4px)",
      },
    }}
  />
);
