/* eslint-disable react/jsx-key */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useExpanded, usePagination, useSortBy, useTable } from "react-table";
import { Flex } from "@chakra-ui/react";
import { StyledContainer, StyledEmptyState, StyledPagination, StyledTable } from "./Table.styled";
import { Pagination } from "../Pagination/Pagination";
import { Spinner } from "../Spinner/Spinner";
import { Checkbox } from "../Checkbox/Checkbox";
import { useHistory } from "react-router-dom";
import { ArrowDownIcon, ArrowUpDownIcon, ArrowUpIcon } from "@chakra-ui/icons";

interface TableProps {
  columns: any;
  data: any;
  loading?: boolean;
  paginated?: boolean;
  initialPageSize?: number;
  pageSizeOptions?: Array<number>;
  onStateUpdate?: (arg?: any) => void;
  pageCount?: number;
  emptyDataText?: string;
  selectableRows?: boolean;
  manualPagination?: boolean;
  onRowSelected?: (selectedRow: any) => void;
  getCellProps?: (args?: any) => any;
  getRowProps?: (args?: any) => any;
  displayFooter?: boolean;
  disableSortBy?: boolean;
  renderRowSubComponent?: (args?: any) => any;
  multipleRowSelect?: boolean;
  shouldShowAllRowSelect?: boolean;
  setRowsSelected?: (args?: any) => any;
  resetSelected?: boolean;
}

export function Table({
  columns,
  data,
  loading,
  paginated = true,
  initialPageSize,
  pageSizeOptions = [25],
  onStateUpdate,
  pageCount: controlledPageCount,
  emptyDataText = "No data...",
  selectableRows = false,
  manualPagination = true,
  onRowSelected,
  getCellProps = () => ({}),
  getRowProps = () => ({}),
  displayFooter = false,
  disableSortBy = true,
  renderRowSubComponent,
  multipleRowSelect = false,
  shouldShowAllRowSelect = false,
  setRowsSelected = () => ({}),
  resetSelected = false,
}: TableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination,
      initialState: { pageIndex: 0, pageSize: initialPageSize },
      pageCount: controlledPageCount,
      autoResetExpanded: false,
      autoResetPage: false,
      autoResetSortBy: false,
      manualSortBy: true,
      disableSortBy,
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const [allToggled, setAllToggled] = useState(false);
  const [toggled, setToggled] = useState(Array.from(new Array(data.length), () => false));
  const history = useHistory();

  useEffect(() => {
    if (resetSelected) {
      setToggled(Array.from(new Array(data.length), () => false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelected]);

  useEffect(() => {
    if (setRowsSelected) {
      setRowsSelected(toggled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggled]);

  useEffect(() => {
    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledPageCount]);

  useEffect(() => {
    if (typeof onStateUpdate === "function") {
      onStateUpdate({ sortBy, pageIndex, pageSize });
    }
  }, [onStateUpdate, pageIndex, pageSize, sortBy]);

  const [selectedRowId, setSelectedRowId] = useState<number>();

  const handleRowSelect = useCallback(
    (row: any) => {
      if (!selectableRows) return;
      setSelectedRowId((prevState: any) => {
        if (prevState === row.id) {
          return undefined;
        }
        return row.id;
      });
      if (typeof onRowSelected === "function") {
        onRowSelected(row?.original);
      }
    },
    [selectableRows, onRowSelected]
  );

  const handleToggleAll = () => {
    setAllToggled((prev) => !prev);
    setToggled((prev) => prev.map((item) => !item));
  };

  const handleToggle = (index: number) => {
    const toggledCopy = [...toggled];
    toggledCopy[index] = !toggledCopy[index];
    setToggled(toggledCopy);
    if (!toggledCopy[index]) {
      setAllToggled(false);
    } else if (allToggled) {
      setAllToggled(false);
    }
  };

  const hideToggleAll = useMemo(() => {
    const trueCount = toggled.filter((t) => t).length;
    return Boolean(trueCount > 0 && trueCount !== toggled.length);
  }, [toggled]);

  return (
    <>
      <StyledContainer>
        {loading && <Spinner />}
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {selectableRows && page.length !== 0 && <th className="checkbox-th" />}
                {multipleRowSelect ? (
                  shouldShowAllRowSelect && page.length !== 0 ? (
                    <th className="checkbox-th">
                      {!hideToggleAll ? (
                        <Checkbox
                          isChecked={allToggled}
                          onChange={(e) => {
                            e.preventDefault();
                            handleToggleAll();
                          }}
                        />
                      ) : null}
                    </th>
                  ) : (
                    <th />
                  )
                ) : null}
                {headerGroup.headers.map((column: any) => {
                  const isSortable = column.getSortByToggleProps().title;
                  return (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <Flex>
                        {column.render("Header")}
                        {isSortable ? (
                          column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowDownIcon />
                            ) : (
                              <ArrowUpIcon />
                            )
                          ) : (
                            <ArrowUpDownIcon />
                          )
                        ) : null}
                      </Flex>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <StyledEmptyState>
                <td colSpan={columns.length + 1}>{emptyDataText}</td>
              </StyledEmptyState>
            ) : (
              page.map((row: any) => {
                prepareRow(row);
                const { url, fromPath = undefined, ...restProps } = row.getRowProps(getRowProps(row));
                const rowOnClick = restProps.onClick;
                const classNames = [restProps.className];

                if (selectableRows || typeof rowOnClick === "function" || url) {
                  classNames.push("clickable-row");
                }

                return (
                  <React.Fragment key={row.id}>
                    <tr
                      {...{
                        ...restProps,
                        onClick: () => {
                          if (selectableRows) handleRowSelect(row);
                          if (typeof rowOnClick === "function") rowOnClick();
                          if (url) history.push(url, { fromPath });
                        },
                        className: classNames.filter(Boolean).join(" "),
                      }}
                    >
                      {selectableRows && (
                        <td className="checkbox-td">
                          <>
                            <Checkbox isChecked={selectedRowId === row.id} onChange={() => handleRowSelect(row)} />
                          </>
                        </td>
                      )}
                      {multipleRowSelect && (
                        <td className="checkbox-td">
                          <Checkbox
                            isChecked={toggled[row.index]}
                            onChange={(e) => {
                              e.preventDefault();
                              handleToggle(row.index);
                            }}
                          />
                        </td>
                      )}
                      {row.cells.map((cell: any) => (
                        <td {...cell.getCellProps([getCellProps(cell)])}>
                          <>{cell.isPlaceholder ? null : cell.render("Cell")}</>
                        </td>
                      ))}
                    </tr>
                    {renderRowSubComponent && row.isExpanded && (
                      <tr>
                        <td colSpan={visibleColumns.length + 1}>{renderRowSubComponent({ row })}</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </tbody>
          {displayFooter && (
            <tfoot>
              {footerGroups.map((footerGroup: any) => (
                <tr {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column: any) => (
                    <td {...column.getFooterProps()}>{column.render("Footer")}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </StyledTable>
      </StyledContainer>
      {paginated && (
        <StyledPagination>
          <Pagination
            currentPage={pageIndex}
            onPageChange={gotoPage}
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            totalPagesNumber={pageOptions.length}
          />
        </StyledPagination>
      )}
    </>
  );
}
