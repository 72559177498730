import React from "react";
import { LabeledText } from "components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { Contact } from "interfaces/data";
import { useTranslation } from "react-i18next";
import siteTheme from "theme/theme";
import { CONTACT_ACTIVE } from "helpers/contact";
import { CrossIcon, TickIcon } from "theme/icons";

interface StatusProps {
  contact: Contact;
}

export function Status({ contact }: StatusProps) {
  const { t } = useTranslation();
  const iconProps = {
    color: contact.status === CONTACT_ACTIVE ? siteTheme.colors["sk-green"] : siteTheme.colors["sk-red"],
    ml: "10px",
    mt: "-3px",
  };

  return (
    <TwoColumnsLayout>
      <LabeledText
        fullWidth
        label={t("contact:Status")}
        value={
          <>
            {contact.status}
            {contact.status === CONTACT_ACTIVE ? <TickIcon {...iconProps} /> : <CrossIcon {...iconProps} />}
          </>
        }
      />
    </TwoColumnsLayout>
  );
}
