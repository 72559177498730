import React, { useCallback, useEffect, useMemo, useState } from "react";
import _uniqBy from "lodash.uniqby";
import { Table } from "components/Table/Table";
import { Box, InputLeftElement, Text } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import { Button } from "components/Button/Button";
import { useCompanies } from "hooks/useCompanies";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { BaseContact, Company } from "interfaces/data";
import { PageHeader } from "components/PageHeader/PageHeader";
import { PageButtons } from "components/PageButtons/PageButtons";
import styled from "@emotion/styled";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_COMPANY_GUARD } from "helpers/userRoles";
import { useToast } from "hooks/useToast";
import { SearchIcon } from "@chakra-ui/icons";
import { EditIcon } from "theme/icons/EditIcon";

export const DeactivatedBox = styled(Box)`
  display: inline-flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 800;
  font-size: 11px;
  line-height: 110%;
  border-radius: 16px;
  color: white;
  background: ${(props) => props.theme.colors["sk-red"]};
  margin-left: 20px;
  position: relative;
`;

const StyledButtonContainer = styled.div`
  text-align: right;
`;

const StyledEditButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 13px;
  width: 15px;
  line-height: 110%;
  margin-top: 3px;
  svg {
    margin-top: -5px;
  }
`;

function ContactPersonCell(arg: any) {
  const contactNames = arg.value
    ? _uniqBy<BaseContact>(arg.value, "id").map((contact: BaseContact) => `${contact.firstName} ${contact.lastName}`)
    : [];
  return contactNames.join(", ");
}

function createEditCell(arg: any) {
  const company = arg.row.original as Company;

  return (
    <Link to={`/companies/${company.id}/preview`}>
      <StyledButtonContainer>
        <StyledEditButton>
          <EditIcon />
        </StyledEditButton>
      </StyledButtonContainer>
    </Link>
  );
}

export function Companies() {
  const { t } = useTranslation();

  const NameCell = useCallback((arg: any) => {
    const company = arg.row.original as Company;
    return (
      <Box alignItems="center" display="flex">
        <Text as="span" fontSize="15px" fontWeight="900">
          {arg.value}
        </Text>
        {company.isDeactivated && <DeactivatedBox>{t("company:deactivated")}</DeactivatedBox>}
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "name",
        Cell: NameCell,
      },
      {
        Header: t("Phone"),
        accessor: "phone",
        Cell: (arg: any) => (
          <Text as="span" whiteSpace="nowrap">
            {arg.value}
          </Text>
        ),
      },
      {
        Header: t("Contact Person"),
        accessor: "contacts",
        Cell: ContactPersonCell,
      },
      {
        Header: t("Notes"),
        accessor: "other.notes",
      },
      {
        Header: "",
        accessor: "edit",
        id: "editAction",
        Cell: createEditCell,
        maxWidth: "5px",
      },
    ],
    [NameCell, t]
  );

  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "modifiedDesc",
        label: t("company:Recently updated"),
        field: "modifiedAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("company:Company name (A-Z)"),
        field: "name",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("company:Company name (Z-A)"),
        field: "name",
        order: "desc",
      },
      {
        value: "createdDesc",
        label: t("company:Creation date (newest first)"),
        field: "createdAt",
        order: "desc",
      },
      {
        value: "createdAsc",
        label: t("company:Creation date (oldest first)"),
        field: "createdAt",
        order: "asc",
      },
    ],
    [t]
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortingOption, setSortingOption] = useState(sortingOptions[0]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const history = useHistory();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const toast = useToast();

  useEffect(() => {
    const state = history.location.state as { restricted?: boolean };

    if (state?.restricted) {
      toast({
        message: t("You don't have access to see this page."),
        type: "error",
      });
      history.replace("/companies", {});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { companies, loading, pageCount } = useCompanies({
    phrase: debouncedSearchQuery,
    fields: "name,phone,contacts[].firstName,contacts[].lastName",
    orderBy: `isDeactivated,${sortingOption.field}`,
    orderDirection: `desc,${sortingOption.order}`,
    offset,
    limit,
  });

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSeachInputUdpate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleSortByUpdate = useCallback((newSelectedOption: TableSortOption) => {
    setSortingOption(newSelectedOption);
  }, []);

  const navigateToCompanyAdd = useCallback(() => {
    history.push("/companies/add");
  }, [history]);

  if (companies) {
    return (
      <>
        <PageHeader>
          <Heading type="h1">{t("company:Companies")}</Heading>
        </PageHeader>
        <PageButtons>
          <RestrictAccess area={ADD_COMPANY_GUARD}>
            <Button design="primary" onClick={navigateToCompanyAdd}>
              {t("company:New company")}
            </Button>
          </RestrictAccess>
        </PageButtons>
        <StyledContainer>
          <StyledTableControls>
            <StyledInputGroup>
              <InputLeftElement children={<SearchIcon />} />
              <StyledInput onChange={handleSeachInputUdpate} placeholder={t("Search")} value={searchQuery} />
            </StyledInputGroup>
            <TableSortSelect
              onSortByChange={handleSortByUpdate}
              options={sortingOptions}
              selectedOption={sortingOption}
            />
          </StyledTableControls>
          <Table
            columns={columns}
            data={companies}
            getRowProps={(rowInfo) => {
              const company = rowInfo.original as Company;
              return {
                url: `/companies/${company.id}`,
              };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUdpate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledContainer>
      </>
    );
  }
  return null;
}
