import React, { useEffect, useState } from "react";
import { Box, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { useProfiles } from "hooks/useProfiles";
import { useToast } from "hooks/useToast";
import { Company, Profile } from "interfaces/data";
import { useQuery } from "react-query";
import { SearchBox } from "components/SearchBox/SearchBox";

interface AssignDealerProps {
  user: Profile;
  onClose: () => any;
  onSuccess: () => any;
}

export const AssignDealer: React.FC<AssignDealerProps> = ({ user, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { update, fetchCompanies } = useProfiles();

  const toast = useToast();

  const { data: dealerCompaniesData } = useQuery("dealerCompanies", () => fetchCompanies(user));
  const [dealerCompanies, setDealerCompanies] = useState(dealerCompaniesData);

  useEffect(() => {
    setDealerCompanies(dealerCompaniesData);
  }, [dealerCompaniesData]);

  const removeCompany = (id: string) => {
    user.companies = user.companies?.filter((companyId) => companyId !== id);
    setDealerCompanies((prevState) => (prevState ? [...prevState.filter((company) => company.id !== id)] : []));
  };

  const addCompany = (company: { id: string; name: string }) => {
    if (!user.companies) {
      user.companies = [];
    }
    if (user.companies.includes(company.id)) {
      return;
    }
    user.companies.push(company.id);
    setDealerCompanies((prevState) => (prevState ? [...prevState, company] : [company]));
  };

  const handleConfirm = async () => {
    if (!user) return;
    setLoading(true);
    const fullName = `${user.name} ${user.surname}`;
    const result = await update(user);
    if (result) {
      toast({
        type: "success",
        message: t(`settings:User {{name}} has been updated.`, {
          name: fullName,
        }),
      });
      onSuccess();
    } else {
      toast({
        type: "error",
        message: t(`settings:User {{name}} could not be updated.`, {
          name: fullName,
        }),
      });
      onClose();
    }
    setLoading(false);
  };

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={loading}
      isOpen
      onClose={onClose}
      onConfirm={handleConfirm}
      title={t(`settings:Assign Dealer To Company`)}
    >
      {dealerCompanies && (
        <Box mb={"20px"}>
          <h3>{`Dealer's Companies:`}</h3>
          <Box mt={"10px"}>
            {dealerCompanies?.map((company) => (
              <Tag
                borderRadius="full"
                colorScheme="blue"
                key={company.id}
                marginRight="5px"
                rounded="full"
                size="md"
                variant="solid"
              >
                <TagLabel color="white">{company.name}</TagLabel>
                <TagCloseButton color="white" ml={"5px"} onClick={() => removeCompany(company.id)} />
              </Tag>
            ))}
          </Box>
        </Box>
      )}

      <Box mb={"20px"}>
        <SearchBox
          collection="companies"
          fields="id,name"
          item={(company: Company) => company.name}
          onSelect={(company) => {
            if (!company) {
              return;
            }
            addCompany({ id: company.id, name: company.name });
          }}
          orderBy="name"
          placeholder="Search to assign"
        />
      </Box>
    </Modal>
  );
};
