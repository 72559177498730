import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { transformTimestampToDate } from "helpers/date";
import { formatNumberToString } from "helpers/formatNumber";
import { Company, Margin, Transaction } from "interfaces/data";
import {
  getMarginCallBalance,
  getSumsOfCurrenciesInMarginCall,
  moveQuantityFromMarginToMarginCall,
} from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import {
  StyledLabel,
  StyledMarginsList,
  StyledMarginsListItem,
  StyledShowAllButton,
  StyledValue,
} from "./MarginCallSettlementModal.styled";
import { Input } from "components/Input/Input";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { CURRENCIES } from "helpers/options";
import { Button } from "components/Button/Button";
import { useFirebase } from "hooks/useFirebase";
import { Radio } from "components/Radio/Radio";
import { WarningIcon } from "@chakra-ui/icons";

interface MarginCallMarginWithdrawModalProps {
  transaction: Transaction;
  company: Company;
  marginCallId: string;
  onClose: () => void;
  onSave: ({
    updatedTransaction,
    updatedCompany,
  }: {
    updatedTransaction: Transaction;
    updatedCompany: Company;
  }) => void;
}

interface Withdrawal {
  currency: string;
  amount: number;
}

export const StyledWarning = styled.div`
  margin: 10px 0;
  color: ${(props) => props.theme.colors["sk-red"]};
  font-size: 13px;
  font-weight: 900;
`;

export const MarginCallMarginWithdrawModal: React.FC<MarginCallMarginWithdrawModalProps> = ({
  transaction,
  company,
  marginCallId,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [editedTransaction, setEditedTransaction] = useState<Transaction>(transaction);
  const [editedCompany, setEditedCompany] = useState<Company>(company);
  const [selectedMargin, setSelectedMargin] = useState<Margin | undefined>();
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [quantity, setQuantity] = useState<number>();
  const [isExpanded, setExpanded] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { rates: nbpRates } = useNbpRates();
  const { timestamp } = useFirebase();

  const numberOfCollapse = 5;

  useEffect(() => {
    setEditedTransaction(transaction);
  }, [transaction]);

  const editedMarginCall = useMemo(() => {
    return editedTransaction.marginCalls?.find((mc) => mc.id === marginCallId);
  }, [editedTransaction.marginCalls, marginCallId]);

  const availableMargins = useMemo(() => {
    if (!editedTransaction) return [];
    const transactionMargins =
      editedTransaction.margins?.filter(
        (margin) =>
          margin.type === "VM" &&
          editedMarginCall?.margins?.findIndex((McMargin) => McMargin.marginId === margin.id) !== -1
      ) || [];
    const globalMargins =
      editedCompany.globalMargins?.filter(
        (globalMargin) =>
          editedMarginCall?.margins?.findIndex((McMargin) => McMargin.marginId === globalMargin.id) !== -1
      ) || [];
    return [...transactionMargins.reverse(), ...globalMargins] as Array<Margin>;
  }, [editedCompany.globalMargins, editedMarginCall, editedTransaction]);

  const visibleAvailableMargins = useMemo(() => {
    if (isExpanded) {
      return availableMargins;
    }
    return availableMargins.slice(0, numberOfCollapse);
  }, [availableMargins, isExpanded]);

  const coveredValueInCurrencies = useMemo(() => {
    if (!editedMarginCall) return [];
    return getSumsOfCurrenciesInMarginCall(editedMarginCall);
  }, [editedMarginCall]);

  const maxValidQuantity = useMemo(() => {
    if (!selectedMargin || !editedMarginCall) {
      return 0;
    }
    return Number(selectedMargin.left.quantity);
  }, [editedMarginCall, selectedMargin]);

  const isValueValid = useMemo(() => {
    if (!quantity) return false;
    return quantity > 0 && quantity <= maxValidQuantity;
  }, [maxValidQuantity, quantity]);

  const handleSaveAction = useCallback(() => {
    if (!editedTransaction || !editedCompany) return;
    onSave({
      updatedTransaction: editedTransaction,
      updatedCompany: editedCompany,
    });
    setIsConfirmed(false);
  }, [editedTransaction, editedCompany, onSave]);

  const handleMarginSettlement = useCallback(() => {
    if (!editedTransaction || !selectedMargin || !quantity) return;

    const { newTransaction, newCompany } = moveQuantityFromMarginToMarginCall({
      transaction: editedTransaction,
      company: editedCompany,
      marginCallId: marginCallId,
      marginId: selectedMargin.id,
      quantity: -Number(quantity),
      currency: selectedMargin.from.currency,
      nbpRates,
      timestamp,
    });

    setSelectedMargin(undefined);
    setEditedTransaction(newTransaction);
    setEditedCompany(newCompany);
    setWithdrawals([...withdrawals, { currency: selectedMargin.from.currency, amount: Number(quantity) }]);
  }, [editedTransaction, selectedMargin, quantity, editedCompany, marginCallId, nbpRates, timestamp, withdrawals]);

  // if there is only one margin assigned - automatically select this margin
  useEffect(() => {
    if (availableMargins.length === 1) {
      setSelectedMargin(availableMargins[0]);
    }
  }, [availableMargins]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getMarginQuantity(marginId: string) {
    if (!editedMarginCall) return 0;
    const { margins } = editedMarginCall;
    return margins ? margins.find((margin) => margin.marginId === marginId)?.quantity ?? 0 : 0;
  }

  const selectedMarginQuantity = useMemo(
    () => getMarginQuantity(selectedMargin?.id || ""),
    [getMarginQuantity, selectedMargin?.id]
  );

  // automatically set withdraw quantity to max. valid value on margin select
  useEffect(() => {
    if (selectedMargin) {
      setQuantity(selectedMarginQuantity);
    } else if (coveredValueInCurrencies.length) {
      setQuantity(coveredValueInCurrencies[0].sum);
    } else {
      setQuantity(maxValidQuantity);
    }
  }, [selectedMargin, maxValidQuantity, coveredValueInCurrencies, selectedMarginQuantity]);

  if (!editedMarginCall || !nbpRates) return null;

  return (
    <>
      <Modal
        confirmText={t("Confirm")}
        design="danger"
        isOpen={isConfirmed}
        onClose={() => setIsConfirmed(false)}
        onConfirm={handleSaveAction}
        title={t("Withdraw margin")}
      >
        <Box mb={"20px"}>{t("company:Are you sure that you want to withdraw margin?")}</Box>
      </Modal>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isOpen
        justifyButtons="flex-end"
        minWidth={840}
        onClose={() => onClose()}
        onConfirm={() => setIsConfirmed(true)}
        title={t(`marginCall:Margin Call, ID #{{marginCallId}} - withdraw`, {
          marginCallId,
        })}
      >
        {visibleAvailableMargins.length > 1 && (
          <Box mb="30px">
            <StyledMarginsList>
              {visibleAvailableMargins.map((margin) => (
                <StyledMarginsListItem
                  key={margin.id}
                  onClick={(e) => {
                    if (selectedMargin && selectedMargin.id === margin.id) {
                      setSelectedMargin(undefined);
                      setQuantity(undefined);
                    } else {
                      setSelectedMargin(margin);
                      setQuantity(undefined);
                    }
                    e.stopPropagation();
                  }}
                >
                  <Box display="flex">
                    <Radio
                      isChecked={selectedMargin && selectedMargin.id === margin.id}
                      mr="10px"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <Box pr="15px" textAlign="right" w="100px">
                      {transformTimestampToDate(margin.date)}
                    </Box>
                    <Box fontWeight="bold" pr="15px">
                      Qty: {formatNumberToString(getMarginQuantity(margin.id))}
                      {" / "}
                      {formatNumberToString(margin.from.quantity)}
                      {margin.from.currency}
                    </Box>
                    <Box>{margin.id}</Box>
                  </Box>
                </StyledMarginsListItem>
              ))}
              {availableMargins.length > numberOfCollapse && !isExpanded && (
                <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
                  {t("Show all")}
                </StyledShowAllButton>
              )}
            </StyledMarginsList>
          </Box>
        )}

        {selectedMargin && (
          <Box>
            {quantity === 0 ? (
              <StyledWarning>
                <WarningIcon mr="8px" mt="-2px" />
                {t("This LVM cannot be withdrawn because it's connected with margin.")}
              </StyledWarning>
            ) : (
              <Box display="flex" mb="10px">
                <Input
                  error={!isValueValid ? "error" : undefined}
                  formatNumberOnBlur
                  hideErrorMessage
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
                  topLabel
                  type="number"
                  value={quantity || ""}
                  withError={!isValueValid}
                />
                <Box ml="10px">
                  <AutoCompleteSelect
                    id="from.currency"
                    isDisabled
                    name="from.currency"
                    options={CURRENCIES}
                    value={selectedMargin.from.currency}
                    width="85px"
                  />
                </Box>
                <Button
                  design="primary"
                  height="42px"
                  isDisabled={!isValueValid}
                  ml="30px"
                  onClick={handleMarginSettlement}
                >
                  {t("Withdraw")}
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box display="flex" justifyContent="flex-end">
          {Boolean(withdrawals.length) && (
            <Box display="inline-block">
              <StyledLabel>Withdrawn</StyledLabel>
              <StyledValue withdrawn>
                -{formatNumberToString(withdrawals.reduce((sum, withdrawal) => sum + withdrawal.amount, 0))}
                &nbsp;{withdrawals[0].currency}
              </StyledValue>
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {coveredValueInCurrencies.map((value, i) => (
            <Box display="inline-block" key={i} ml="20px">
              <StyledLabel>{value.currency}</StyledLabel>
              <StyledValue>{formatNumberToString(value.sum)}</StyledValue>
            </Box>
          ))}
          <Box display="inline-block" ml="20px">
            <StyledLabel>Balance</StyledLabel>
            <StyledValue>{formatNumberToString(getMarginCallBalance(editedMarginCall, nbpRates))} PLN</StyledValue>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
