import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const TwoArrowsIcon = createIcon({
  displayName: "TwoArrowsIcon",
  path: (
    <g fill="none">
      <path
        d="M0.5 3.94531L3.61077 0.834539L6.72155 3.94531"
        stroke="#252527"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M3.60938 12.5L3.60937 0.834598" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.27734 3.9428L11.3881 0.832031L14.4989 3.94281"
        stroke="#252527"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.3867 12.4974L11.3867 0.832031" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: "0 0 15 13",
});
