import React from "react";
import { BoxProps, Textarea } from "@chakra-ui/react";
import { StyledGroup } from "./TextareaWithLabel.styled";
import { Label } from "../Label/Label";

export interface TextareaProps {
  error?: string;
  label?: string;
  id?: string;
  value?: any;
  name: string;
  onChange: any;
  withError?: boolean;
  placeholder?: string;
  labelProps?: BoxProps;
}

export const TextareaWithLabel = ({ label, labelProps = {}, ...props }: TextareaProps): React.ReactElement => (
  <StyledGroup>
    {label && <Label {...labelProps}>{label}</Label>}
    <Textarea
      backgroundColor="white"
      borderColor="sk-light-gray"
      borderRadius="base"
      errorBorderColor="sk-red"
      fontSize="xs-sm"
      height="42px"
      minHeight="42px"
      paddingBottom="12px"
      paddingTop="12px"
      px="1rem"
      {...props}
    />
  </StyledGroup>
);
