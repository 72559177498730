import { createIcon } from "@chakra-ui/icons";
import React from "react";

export const DashboardIcon = createIcon({
  displayName: "DashboardIcon",
  path: (
    <g fill="none">
      <path
        clipRule="evenodd"
        d="M10.143 9.38105C10.143 9.80238 9.80238 10.143 9.38105 10.143C8.95971 10.143 8.61914 9.80238 8.61914 9.38105C8.61914 8.95971 8.95971 8.61914 9.38105 8.61914C9.80238 8.61914 10.143 8.95971 10.143 9.38105Z"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.93752 17C2.56571 15.4869 1 12.6282 1 9.56076C1 4.83314 4.75314 1 9.38095 1C14.0103 1 17.7619 4.83314 17.7619 9.56076C17.7619 12.6 16.1771 15.4808 13.8427 17"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.38086 9.38093L13.1904 7.09521"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M9.37939 2.52381V1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.238 9.38086H17.7618"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M1 9.38086H2.52381"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M5.93464 3.45231L5.17578 2.15479"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.3096 5.93463L16.5911 5.19482"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M3.48364 5.93463L2.20288 5.19482"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M1.98657 13.6843L3.45248 12.8271"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M16.9324 13.3331L15.4558 12.5605"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12.561 3.30521L13.2765 1.97949"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 19 18",
});
