/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { Question } from "../interfaces/data";
import { useValidation } from "./useValidation";
import { questionSchema } from "../validations/scoring";

const COLLECTION = "scoring";
const QUESTIONS_DOCUMENT = "questions";
const CONFIG_DOCUMENT = "config";

export const useScoring = () => {
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [activeQuestions, setActiveQuestions] = useState<Array<Question>>([]);
  const [globalLimit, setGlobalLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const { db, timestamp } = useFirebase();
  const { validate: validateQuestion, errors: addQuestionErrors } = useValidation(questionSchema);

  const _generateInnerQuestionId = useCallback((): number => {
    if (!questions) return 0;
    const lastItemId = questions.slice(-1)[0].id;
    return lastItemId ? lastItemId + 1 : 0;
  }, [questions]);

  const fetchQuestions = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      return collection.doc(QUESTIONS_DOCUMENT).onSnapshot((snap: firestore.DocumentData) => {
        const questions = snap.data() as any;
        if (questions) {
          setQuestions(questions.questions);
          setActiveQuestions(questions.questions.filter((question: Question) => question.status !== "inactive"));
        } else {
          setQuestions([]);
          setActiveQuestions([]);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchLimit = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      const unsubscribe = collection.doc(CONFIG_DOCUMENT).onSnapshot((snap: firestore.DocumentData) => {
        const config = snap.data() as any;
        if (questions && config) {
          setGlobalLimit(Number(config.limit));
        } else {
          setQuestions([]);
          setActiveQuestions([]);
        }
      });
      setLoading(false);
      return unsubscribe;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addQuestion = useCallback(
    async (newQuestion: Question) => {
      const collection = db.collection(COLLECTION);

      const isValid = await validateQuestion(newQuestion);

      if (!questions || !isValid) return false;

      const newQuestions = questions || [];
      newQuestion.id = _generateInnerQuestionId();
      newQuestion.status = "active";
      newQuestions.push(newQuestion);

      await collection
        .doc(QUESTIONS_DOCUMENT)
        .update({
          questions: newQuestions,
          modifiedAt: timestamp(),
        })
        .then(() => {
          setQuestions(newQuestions);
          setActiveQuestions(newQuestions.filter((question) => question.status !== "inactive"));
        })
        .catch((e) => {
          console.error("questionAdd", e);
          return false;
        });
    },
    [questions]
  );

  const deleteQuestion = useCallback(
    async (questionId?: number) => {
      const collection = db.collection(COLLECTION);

      if (typeof questionId !== "number") return false;

      const newQuestions = questions || [];

      const editedMarginIndex = newQuestions.findIndex((question) => question.id === questionId);

      newQuestions[editedMarginIndex] = {
        ...newQuestions[editedMarginIndex],
        status: "inactive",
      };

      return collection
        .doc(QUESTIONS_DOCUMENT)
        .update({
          questions: newQuestions,
          modifiedAt: timestamp(),
        })
        .then(() => {
          setQuestions(newQuestions);
          setActiveQuestions(newQuestions.filter((question) => question.status !== "inactive"));
        })
        .catch((e) => {
          console.error("questionAdd", e);
          return false;
        });
    },
    [questions]
  );

  const editQuestion = useCallback(
    async (question?: Question) => {
      const collection = db.collection(COLLECTION);

      if (!question) return false;

      const newQuestions = questions || [];

      const editedMarginIndex = newQuestions.findIndex((q) => q.id === question.id);

      newQuestions[editedMarginIndex] = {
        ...question,
      };

      return collection
        .doc(QUESTIONS_DOCUMENT)
        .update({
          questions: newQuestions,
          modifiedAt: timestamp(),
        })
        .then(() => {
          setQuestions(newQuestions);
          setActiveQuestions(newQuestions.filter((question) => question.status !== "inactive"));
        })
        .catch((e) => {
          console.error("questionAdd", e);
          return false;
        });
    },
    [questions]
  );

  const editLimit = useCallback(async (limit: number) => {
    const collection = db.collection(COLLECTION);
    await collection
      .doc(CONFIG_DOCUMENT)
      .update({
        limit,
        modifiedAt: timestamp(),
      })
      .then(() => {
        setGlobalLimit(Number(limit));
      })
      .catch((e) => {
        console.error("limitEdit", e);
        return false;
      });
  }, []);

  useEffect(() => {
    fetchQuestions();
    fetchLimit();
  }, []);

  return {
    addQuestion,
    deleteQuestion,
    addQuestionErrors,
    questions,
    activeQuestions,
    globalLimit,
    loading,
    editLimit,
    editQuestion,
  };
};
