import styled from "@emotion/styled";

export const TotalValueStyled = styled.div`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  background-color: rgba(236, 236, 236, 0.2);
  padding: 25px 30px;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: 600;
  line-height: 110%;
`;

export const StyledValue = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 110%;
`;
