import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { StyledTypeFilterContainer } from "../Transactions";
import { TRANSACTION_TYPES } from "../../../helpers/transaction";
import { TransactionType } from "../../../interfaces/data";

interface TypeFilterProps {
  filterValue: Array<TransactionType>;
  onChange?: (newTypeFilter: Array<TransactionType>) => void;
}

export const TypeFilter: React.FC<TypeFilterProps> = ({ filterValue, onChange }) => {
  const { t } = useTranslation();

  const [typeFilters, setTypeFilters] = useState(filterValue);

  const handleTypeFiltersUpdate = useCallback(
    (filterKey: TransactionType) => () => {
      const newTypeFilters = typeFilters.includes(filterKey)
        ? typeFilters.filter((key) => key !== filterKey)
        : [...typeFilters, filterKey];

      setTypeFilters(newTypeFilters);
    },
    [typeFilters]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(typeFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeFilters]);

  const renderOptions = useCallback(() => {
    const options = [];

    for (const [, type] of Object.entries(TRANSACTION_TYPES)) {
      options.push(
        <StyledTypeFilterContainer key={type.value}>
          <Checkbox
            checkboxSize="small"
            isChecked={typeFilters.includes(type.value)}
            onChange={handleTypeFiltersUpdate(type.value)}
          >
            {t(`transaction:${type.label}`)}
          </Checkbox>
        </StyledTypeFilterContainer>
      );
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleTypeFiltersUpdate, typeFilters]);

  return <>{renderOptions()}</>;
};
