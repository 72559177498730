import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { SearchBox } from "components/SearchBox/SearchBox";
import { DEAL_TYPE_BUY, DEAL_TYPE_SELL, TRANSACTION_TYPES } from "helpers/transaction";
import { BaseTransaction, Company, Transaction, TransactionType } from "interfaces/data";
import _set from "lodash.set";
import { useFirebase } from "hooks/useFirebase";
import { Radio } from "components/Radio/Radio";
import { CURRENCIES, TRANSACTION_DEAL_TYPES } from "helpers/options";
import { Checkbox } from "components/Checkbox/Checkbox";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { DISPLAY_DATE_TIME_FORMAT, formatDate, formatDateTime } from "helpers/date";
import { Box, RadioGroup, Text } from "@chakra-ui/react";
import { SelectWithLabel } from "components/SelectWithLabel/SelectWithLabel";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { TextareaWithLabel } from "components/TextareaWithLabel/TextareaWithLabel";
import Decimal from "decimal.js";
import { parseAnyFloat } from "helpers/parseAnyFloat";
import { StyledGroup } from "components/InputWithLabel/InputWithLabel.styled";
import { Label } from "components/Label/Label";
import { Input } from "components/Input/Input";
import { useBankAccounts } from "hooks/useBankAccounts";
import { useHistory } from "react-router-dom";
import { Spinner } from "components/Spinner/Spinner";
import { CalcGrid, StyledCheckbox, StyledCompanyInfo, StyledRadioContainer } from "./TransactionFormModal.styled";

interface TransactionFormModalProps {
  type: "add" | "roll" | "edit" | "convert";
  title: string;
  initialTransaction: DeepPartial<BaseTransaction>;
  errors: any;
  onSave: (transaction: DeepPartial<BaseTransaction>) => void;
  onClose: () => void;
  sendEmail?: boolean;
  sendEmailChange?: (newSendEmail: boolean) => void;
  skipCompany?: boolean;
  orderOnly?: boolean;
  setTransactionType?: Dispatch<SetStateAction<TransactionType>>;
  transactionType?: TransactionType;
}

export const TransactionFormModal: React.FC<TransactionFormModalProps> = ({
  type,
  title,
  initialTransaction,
  errors,
  onSave,
  onClose,
  sendEmail = false,
  sendEmailChange,
  skipCompany,
  orderOnly,
  setTransactionType,
  transactionType,
}) => {
  const { t } = useTranslation();
  const { timestampFrom } = useFirebase();
  const [transaction, setTransaction] = useState(initialTransaction);
  const { bankAccounts, loading } = useBankAccounts();
  const history = useHistory();

  const isRollOrProlongedEdit = type === "roll" || Boolean(initialTransaction.createdFrom);

  const modalBackgroundColor = useMemo(() => {
    switch (transactionType) {
      case TRANSACTION_TYPES.SPOT.value:
        return "#eaf6ff";
      case TRANSACTION_TYPES.ORDER.value:
        return "#fff9e5";
      default:
        return "#fff";
    }
  }, [transactionType]);

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>, callback?: Function) => {
    e.persist();
    setTransaction((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      if (e.target.type === "number") {
        _set(newEditedData, e.target.name, Number(e.target.value));
      } else {
        _set(newEditedData, e.target.name, e.target.value);
      }
      if (typeof callback === "function") {
        callback(newEditedData);
      }
      return newEditedData;
    });
  }, []);

  const handleChangeRadio = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const selectedValue = e.target.value as TransactionType;
      setTransactionType && setTransactionType(selectedValue);
      if (selectedValue === "Order") {
        typeof sendEmailChange === "function" && sendEmailChange(false);
      }
      setTransaction((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        _set(newEditedData, e.target.name, selectedValue);
        if (selectedValue === TRANSACTION_TYPES.FIXED.value) {
          newEditedData.dealType = DEAL_TYPE_SELL;
          _set(newEditedData, "from.currency", "EUR");
          _set(newEditedData, "to.currency", "PLN");
        }
        return newEditedData;
      });
    },
    [sendEmailChange, setTransactionType]
  );

  const handleUpdateDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setTransaction((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        if (!e.target.value) {
          _set(newEditedData, e.target.name, "");
          return newEditedData;
        }

        const date = new Date(e.target.value);
        _set(newEditedData, e.target.name, timestampFrom(date));
        return newEditedData;
      });
    },
    [timestampFrom]
  );

  const handleChangeCompany = (company: Company) => {
    if (!company) return false;

    setTransaction((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      const companyInfo = {
        name: company.name,
        id: company.id,
      };
      _set(newEditedData, "company", companyInfo);
      return newEditedData;
    });
  };

  const handleChangeBlockedCompany = (company: Company) => {
    history.push(`/companies/${company.id}`);
  };

  const handleCalculate = (data: Transaction): Transaction => {
    const rate = new Decimal(parseAnyFloat(data.clientRate));
    _set(data, "to.quantity", rate.times(parseAnyFloat(data.from.quantity)).toNumber());
    return data;
  };

  const getBankAccountsByCurrency = useCallback(
    (currency: string) => {
      return bankAccounts.filter((account) => account.currency === currency).sort((a) => (a.isDefault ? -1 : 1));
    },
    [bankAccounts]
  );
  const getDefaultBankAccountByCurrency = useCallback(
    (currency: string) => {
      return getBankAccountsByCurrency(currency)[0];
    },
    [getBankAccountsByCurrency]
  );

  const availableBankAccounts = useMemo(() => {
    if (!transaction.from?.currency || !transaction.to?.currency) return [];
    if (bankAccounts && transaction.dealType) {
      const currency = transaction.dealType === DEAL_TYPE_SELL ? transaction.from.currency : transaction.to.currency;
      return getBankAccountsByCurrency(currency);
    }
    return [];
  }, [
    bankAccounts,
    getBankAccountsByCurrency,
    transaction.dealType,
    transaction.from?.currency,
    transaction.to?.currency,
  ]);

  const handleAccountChange = (accountId?: string) => {
    const account = availableBankAccounts.find((account) => account.id === accountId);
    setTransaction((prevState) => {
      return { ...prevState, account };
    });
  };

  const handleCurrencyUpdate = useCallback(async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currency = e.target.value;
    const field = e.target.name;
    if (field === "from.currency") {
      setTransaction((prevState) => ({ ...prevState, from: { ...prevState.from, currency } }));
    }
    if (field === "to.currency") {
      setTransaction((prevState) => ({ ...prevState, to: { ...prevState.to, currency } }));
    }
  }, []);

  // Set bank account based on currency and deal type
  useEffect(() => {
    if (transaction.dealType === DEAL_TYPE_SELL && transaction.from?.currency !== transaction.account?.currency) {
      if (!transaction.from?.currency) return;
      const account = getDefaultBankAccountByCurrency(transaction.from?.currency);
      setTransaction((prevState) => ({ ...prevState, account }));
    }
    if (transaction.dealType === DEAL_TYPE_BUY && transaction.to?.currency !== transaction.account?.currency) {
      if (!transaction.to?.currency) return;
      const account = getDefaultBankAccountByCurrency(transaction.to?.currency);
      setTransaction((prevState) => ({ ...prevState, account }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction.dealType, transaction.from, transaction.to]);

  const accountOptions = useMemo(() => {
    return availableBankAccounts.map((account) => ({
      value: account.id || "",
      label: `${account.number} - ${account.currency} (${account.name})${
        account.isDefault ? " - " + t("settings:default account") : ""
      }`,
    }));
  }, [availableBankAccounts, t]);

  useEffect(() => {
    const account = availableBankAccounts[0];
    setTransaction((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      if (!oldEditedData.account) {
        _set(newEditedData, "account", account);
      }
      return newEditedData;
    });
  }, [availableBankAccounts]);

  const radioOptions = useMemo(() => {
    const keys = Object.keys(TRANSACTION_TYPES) as Array<keyof typeof TRANSACTION_TYPES>;
    return keys.map((item, key) => (
      <Radio
        colorScheme="purple"
        isDisabled={type !== "add"}
        key={key}
        onChange={handleChangeRadio}
        value={TRANSACTION_TYPES[item].value}
      >
        <Text fontSize="xs-sm" mr={5}>
          {t(`transaction:${TRANSACTION_TYPES[item].label}`)}
        </Text>
      </Radio>
    ));
  }, [handleChangeRadio, t, type]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Modal
      backgroundColor={modalBackgroundColor}
      confirmText={t(type === "roll" ? "transaction:Roll" : type === "convert" ? "Convert" : "Save")}
      design="primary"
      isOpen
      justifyButtons="flex-end"
      minWidth={899}
      onClose={onClose}
      onConfirm={() => onSave(transaction)}
      title={title}
    >
      {!skipCompany && (
        <Box pb="30px">
          {type === "add" ? (
            <SearchBox
              blockItem={(item: Company) => Boolean(item.isDeactivated)}
              collection="companies"
              error={errors?.["company.name"]}
              fields="name"
              item={(item: Company) => `${item.name}`}
              label={t("transaction:Search company")}
              onBlockSelect={handleChangeBlockedCompany}
              onSelect={handleChangeCompany}
              orderBy="name"
              persist={(item: Company) => item.name}
              withError={!!errors?.["company.name"]}
            />
          ) : (
            <StyledCompanyInfo>
              <Label>{t("transaction:Company")}</Label>
              <Input isDisabled value={transaction.company?.name} />
            </StyledCompanyInfo>
          )}
        </Box>
      )}
      {!orderOnly && (
        <StyledRadioContainer>
          <RadioGroup defaultValue={transaction.type} name="type">
            {radioOptions}
          </RadioGroup>
        </StyledRadioContainer>
      )}
      <TwoColumnsLayout skipColumnGap withPaddingBottom>
        <InputWithLabel
          error={errors?.["agreement"]}
          id="agreement"
          label={t("transaction:Agreement date")}
          name="agreement"
          onChange={handleUpdateDate}
          type="date"
          value={formatDate(transaction.agreement as Timestamp)}
          withError={!!errors?.["agreement"]}
        />
        <Box display="flex" justifyContent="flex-end">
          <InputWithLabel
            id="systemDate"
            isDisabled
            label={t("transaction:System date")}
            name="systemDate"
            type="text"
            value={formatDateTime(transaction.systemDate as Timestamp, DISPLAY_DATE_TIME_FORMAT)}
          />
        </Box>
      </TwoColumnsLayout>
      {transaction.type === TRANSACTION_TYPES.ORDER.value && (
        <TwoColumnsLayout leftEmpty skipColumnGap withPaddingBottom>
          <Box display="flex" justifyContent="flex-end">
            <InputWithLabel
              error={errors?.["expiration"]}
              id="expiration"
              label={t("transaction:Expiration date")}
              name="expiration"
              onChange={handleUpdateDate}
              type="date"
              value={transaction.expiration ? formatDate(transaction.expiration as Timestamp) : undefined}
              withError={!!errors?.["expiration"]}
            />
          </Box>
        </TwoColumnsLayout>
      )}
      {transaction.type !== TRANSACTION_TYPES.SPOT.value && (
        <TwoColumnsLayout skipColumnGap withPaddingBottom>
          <InputWithLabel
            error={errors?.["start"]}
            id="start"
            isDisabled={isRollOrProlongedEdit}
            label={t("transaction:Start date")}
            name="start"
            onChange={handleUpdateDate}
            type="date"
            value={formatDate(transaction.start as Timestamp)}
            withError={!!errors?.["start"]}
          />
          <Box display="flex" justifyContent="flex-end">
            <InputWithLabel
              enableDateClear={transaction.type === TRANSACTION_TYPES.ORDER.value}
              error={errors?.["end"]}
              id="end"
              label={t("transaction:End date")}
              name="end"
              onChange={handleUpdateDate}
              type="date"
              value={transaction.end ? formatDate(transaction.end as Timestamp) : undefined}
              withError={!!errors?.["end"]}
            />
          </Box>
        </TwoColumnsLayout>
      )}
      {transaction.type === TRANSACTION_TYPES.SPOT.value && (
        <TwoColumnsLayout skipColumnGap withPaddingBottom>
          <InputWithLabel
            error={errors?.["currencyDate"]}
            id="currencyDate"
            label={t("transaction:Currency date")}
            name="currencyDate"
            onChange={handleUpdateDate}
            type="date"
            value={formatDate(transaction.currencyDate as Timestamp)}
            withError={!!errors?.["currencyDate"]}
          />
        </TwoColumnsLayout>
      )}
      <TwoColumnsLayout skipColumnGap withPaddingBottom>
        <SelectWithLabel
          error={errors?.["dealType"]}
          id="dealType"
          isDisabled={isRollOrProlongedEdit}
          label={t("transaction:Deal Type")}
          name="dealType"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate(e)}
          options={TRANSACTION_DEAL_TYPES}
          value={transaction?.dealType}
        />
        <Box display="flex" justifyContent="flex-end">
          <StyledGroup>
            <Label>{t("transaction:Quantity")}</Label>
            <CalcGrid>
              <Input
                error={errors?.["from.quantity"]}
                formatNumberOnBlur
                hideErrorMessage
                id="quantity"
                isDisabled={isRollOrProlongedEdit}
                name="from.quantity"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate(e, handleCalculate)}
                type="number"
                value={transaction.from?.quantity}
                withError={!!errors?.["from.quantity"]}
              />
              <Label>{t("transaction:Calc")}</Label>
              <Input
                formatNumberOnBlur
                id="calc"
                isDisabled
                name="to.quantity"
                type="number"
                value={transaction.to?.quantity}
              />
            </CalcGrid>
          </StyledGroup>
        </Box>
        <AutoCompleteSelect
          error={errors?.["from.currency"]}
          id="what"
          isDisabled={isRollOrProlongedEdit}
          label={t("transaction:What")}
          layout="vertical"
          name="from.currency"
          onChange={handleCurrencyUpdate}
          options={CURRENCIES}
          placeholder="Select"
          value={transaction.from?.currency}
          withError={!!errors?.["from.currency"]}
        />
        <Box display="flex" justifyContent="flex-end">
          <AutoCompleteSelect
            error={errors?.["to.currency"]}
            id="forWhat"
            isDisabled={isRollOrProlongedEdit}
            label={t("transaction:For What")}
            layout="vertical"
            name="to.currency"
            onChange={handleCurrencyUpdate}
            options={CURRENCIES}
            placeholder="Select"
            value={transaction.to?.currency}
            withError={!!errors?.["to.currency"]}
          />
        </Box>
        <InputWithLabel
          error={errors?.["clientRate"]}
          formatNumberOnBlur
          formatNumberPrecision={4}
          id="clientRate"
          label={t("transaction:Client's Rate")}
          name="clientRate"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdate(e, handleCalculate)}
          step="any"
          type="number"
          value={transaction?.clientRate ?? ""}
          withError={!!errors?.["clientRate"]}
        />
        <Box display="flex" justifyContent="flex-end">
          <InputWithLabel
            error={errors?.["ourRate"]}
            formatNumberOnBlur
            formatNumberPrecision={4}
            id="ourRate"
            isDisabled={isRollOrProlongedEdit}
            label={t("transaction:Our Rate")}
            name="ourRate"
            onChange={handleUpdate}
            step="any"
            type="number"
            value={transaction?.ourRate ?? ""}
            withError={!!errors?.["ourRate"]}
          />
        </Box>
        {transaction.type !== TRANSACTION_TYPES.SPOT.value && (
          <InputWithLabel
            containerProps={{
              width: "100%",
            }}
            error={errors?.["initialMargin"]}
            formatNumberOnBlur
            id="initialMargin"
            label={t("transaction:Initial Margin")}
            name="initialMargin"
            onChange={handleUpdate}
            rightAddon="%"
            type="number"
            value={transaction?.initialMargin}
            withError={!!errors?.["initialMargin"]}
          />
        )}
      </TwoColumnsLayout>
      <Box mb="10px">
        <SelectWithLabel
          groupStyles={{ gridTemplateColumns: "110px 1fr" }}
          id="account"
          isDisabled={availableBankAccounts.length === 0}
          label={t("transaction:Account")}
          name="account"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleAccountChange(e.target.value)}
          options={accountOptions}
          value={transaction?.account?.id}
        />
      </Box>

      <TextareaWithLabel
        id="comment"
        label={t("transaction:Comment")}
        labelProps={{
          paddingRight: "10px",
        }}
        name="comment"
        onChange={handleUpdate}
        value={transaction?.comment}
      />
      <StyledCheckbox>
        <Checkbox
          fontSize="15px"
          fontWeight="bold"
          isChecked={sendEmail}
          onChange={(e) => typeof sendEmailChange === "function" && sendEmailChange(e.target.checked)}
        >
          {t(`transaction:${type === "edit" ? "Send e-mail correction" : "Send e-mail confirmation"}`)}
        </Checkbox>
      </StyledCheckbox>
    </Modal>
  );
};
