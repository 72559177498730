import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { Text } from "@chakra-ui/react";
import _uniqBy from "lodash.uniqby";
import { Company, GlobalMarginCall } from "interfaces/data";
import { StyledButton, StyledContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { IdCell } from "views/Transaction/Transaction/MarginCalls";
import { useHistory, useParams } from "react-router-dom";
import {
  ButtonContainer,
  StyledAddbutton,
  StyledButtonContainerWithLink,
  StyledButtonContainerWrapper,
} from "./Transactions/Transactions";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { DateCell } from "helpers/tableCells";
import { Button } from "components/Button/Button";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { StyledTableContainer } from "./components/margin.styled";
import { TransactionIdsCell } from "./components/TransactionIdsCell";
import { ActionsGmcCell } from "./components/ActionsGmcCell";
import { RemainingCell } from "./components/RemainingGmcCell";
import { StatusGmcCell } from "./components/StatusGmcCell";
import { CloseGmcCell } from "./components/CloseGmcCell";
import { AddIcon } from "theme/icons/AddIcon";

interface MarginCallsProps {
  marginCalls?: Array<GlobalMarginCall>;
  isDisabled?: boolean;
  company?: Company;
}

export const GlobalMarginCalls: React.FC<MarginCallsProps> = ({ marginCalls = [], isDisabled = false, company }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isExpanded, setExpanded] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const numberOfCollapse = 4;

  const sortedMarginCalls = useMemo(() => {
    const originalMarginCalls = marginCalls.filter((marginCall) => !marginCall.createdFromId);
    const additionalMarginCalls = marginCalls.filter((marginCall) => marginCall.createdFromId);
    additionalMarginCalls.forEach((additionalMarginCall) => {
      const originalIndex = originalMarginCalls.findIndex(
        (originalMarginCall) => additionalMarginCall.createdFromId === originalMarginCall.id
      );
      if (originalIndex !== -1) {
        originalMarginCalls.splice(originalIndex + 1, 0, additionalMarginCall);
      }
    });

    return _uniqBy(
      originalMarginCalls.filter((marginCall) => marginCall.isClosed === (showClosed ? "yes" : "no")),
      "id"
    ).sort(
      (prevElement, nextElement) =>
        getMilisecondsFromTimestamp(nextElement.callDate) - getMilisecondsFromTimestamp(prevElement.callDate)
    );
  }, [marginCalls, showClosed]);

  const visibleMarginCalls = useMemo(() => {
    if (isExpanded) {
      return sortedMarginCalls;
    }
    return sortedMarginCalls.slice(0, numberOfCollapse);
  }, [sortedMarginCalls, isExpanded]);

  const handleGMCAdd = useCallback(() => {
    history.push(`/companies/${id}/add-gmc`);
  }, [history, id]);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("Close"),
        accessor: "close",
        Cell: CloseGmcCell,
      },
      {
        Header: t("Transactions IDs"),
        accessor: "globalTransactionsIds",
        Cell: TransactionIdsCell,
      },
      {
        Header: t("marginCall:Call rate"),
        accessor: "callRate",
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("marginCall:Quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.value)}
          </Text>
        ),
      },
      {
        Header: t("marginCall:Currency"),
        accessor: "currency",
        Cell: (arg: any) => <Text>{arg.value}</Text>,
      },
      {
        Header: t("marginCall:Call date"),
        accessor: "callDate",
        Cell: DateCell,
      },
      {
        Header: t("marginCall:Type"),
        accessor: "dealType",
      },
      {
        Header: t("transaction:Client rate"),
        accessor: "clientRate",
        Cell: (arg: any) => <Text as="span">{formatRate(arg.value)}</Text>,
      },
      {
        Header: () => (
          <Text textAlign="right" whiteSpace="nowrap" width="100%">
            {t("marginCall:Base Qty")}
          </Text>
        ),
        accessor: "baseQty",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.row.original.transQuantity)} {arg.row.original.currency}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text textAlign="right" whiteSpace="nowrap" width="100%">
            {t("Remaining Qty")}
          </Text>
        ),
        accessor: "remaining",
        Cell: RemainingCell,
      },
      {
        Header: t("marginCall:Status"),
        accessor: "status",
        Cell: StatusGmcCell,
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ActionsGmcCell,
      },
    ],
    [t]
  );

  return (
    <StyledContainer id="gmc">
      <StyledSectionHeader>
        <StyledSectionName>{t("transaction:Global Margin Calls")}</StyledSectionName>

        <StyledButtonContainerWithLink>
          <StyledButtonContainerWrapper>
            <Button
              borderRadius="12px"
              design={!showClosed ? "primary" : "ghost"}
              height="32px"
              mr="4px"
              onClick={() => setShowClosed(false)}
              opacity={!showClosed ? 1 : 0.5}
            >
              Open
            </Button>
            <Button
              borderRadius="12px"
              design={showClosed ? "primary" : "ghost"}
              height="32px"
              onClick={() => setShowClosed(true)}
              opacity={showClosed ? 1 : 0.5}
            >
              Closed
            </Button>
          </StyledButtonContainerWrapper>
          <RestrictAccess area={ADD_MARGIN_CALLS}>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated)} onClick={handleGMCAdd}>
              <AddIcon mr="10px" />
              New GMC
            </StyledAddbutton>
          </RestrictAccess>
        </StyledButtonContainerWithLink>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={visibleMarginCalls}
          getRowProps={(rowInfo) => {
            const row = rowInfo.original;
            return {
              url: `/companies/${id}/gmc/${row.id}`,
              className: [row.isClosed === "yes" ? "closed-row" : "", "global-row"].join(" "),
            };
          }}
          paginated={false}
          selectableRows={isDisabled}
        />
      </StyledTableContainer>
      {sortedMarginCalls.length > numberOfCollapse && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledContainer>
  );
};
