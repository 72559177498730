/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { useFirebase } from "./useFirebase";

type UseRelationsObject<T> = {
  field: keyof T;
  collection: string;
  preserve: string[];
};

export const useRelations = <T>(relations: UseRelationsObject<T>[]) => {
  const { db, isReference } = useFirebase();

  const retrieveValues = useCallback(async (item: any, fields: string[]) => {
    const acc: { [key: string]: string } = {};

    for (const field of fields) {
      const value = item[field];

      if (!value) {
        continue;
      }

      acc[field] = value;
    }

    return acc;
  }, []);

  const getRelationData = useCallback(async (item: any, options: UseRelationsObject<T>) => {
    if (!isReference(item._ref)) {
      return;
    }

    const reference = item._ref;

    const [relationReference, internalData] = await Promise.all([
      reference.get(),
      retrieveValues(item, options.preserve),
    ]);

    if (!relationReference || !relationReference.exists) {
      return;
    }

    return Object.assign({}, { id: relationReference.id, ...relationReference.data() }, internalData);
  }, []);

  const getRelationReference = useCallback(async (element: any, options: UseRelationsObject<T>) => {
    const internalData = await retrieveValues(element, options.preserve);

    return Object.assign(
      {},
      {
        _ref: element._ref || db.collection(options.collection).doc(element.id),
      },
      internalData
    );
  }, []);

  const attachRelations = useCallback(
    async (item: T) => {
      for (const relation of relations) {
        const field = item[relation.field];
        const data = Array.isArray(field)
          ? await Promise.all(field.map((element: T) => getRelationData(element, relation)))
          : await getRelationData(field, relation);

        const results = Array.isArray(data) ? data.filter(Boolean) : data;

        if (results) {
          item[relation.field] = results;
        }
      }

      return item;
    },
    [getRelationData, relations]
  );

  const detachRelations = useCallback(
    async (item: any) => {
      for (const relation of relations) {
        const relationField = item[relation.field];

        const getRelationObject = (element: any) => getRelationReference(element, relation);

        const fetchRelations = Array.isArray(relationField)
          ? Promise.all(relationField.map(getRelationObject))
          : getRelationObject(relationField);

        item[relation.field] = await fetchRelations;
      }

      return item;
    },
    [relations]
  );

  return {
    attachRelations,
    detachRelations,
  };
};
