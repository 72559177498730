import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs } from "../../components/Tabs/Tabs";
import { Heading } from "../../components/Heading/Heading";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Account } from "./Account/Account";
import { Members } from "./Members/Members";
import { LogsHistory } from "./LogsHistory/LogsHistory";
import { Roles } from "./Roles/Roles";
import { BankAccounts } from "./BankAccounts/BankAccounts";
import styled from "@emotion/styled";
import { useAuth } from "../../hooks/useAuth";
import { ADD_USERS, hasAccess } from "../../helpers/userRoles";
import { Institutions } from "./Institutions/Institutions";
import { Email } from "./Email/Email";
import { GlobalMarginsMigration } from "../GlobalMargins/GlobalMarginsMigration";

export const StyledPanel = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background-color: white;
  min-height: 300px;
`;

export function Settings() {
  const { t } = useTranslation();
  const { tab: paramTab } = useParams<{ tab: string }>();
  const history = useHistory();
  const { profile } = useAuth();

  const tabs = useMemo(
    () =>
      [
        {
          name: t("settings:Account"),
          url: "account",
          component: <Account key="account" />,
        },
        {
          name: t("settings:Members"),
          url: "members",
          component: <Members key="members" />,
        },
        {
          name: t("settings:Logs history"),
          url: "logs",
          component: <LogsHistory key="logs" />,
        },
        {
          name: t("settings:Roles"),
          url: "roles",
          component: <Roles key="roles" />,
        },
        {
          name: t("settings:Bank accounts"),
          url: "bank-accounts",
          component: <BankAccounts key="bank-accounts" />,
        },
        {
          name: t("settings:Institutions"),
          url: "institutions",
          component: <Institutions key="institutions" />,
        },
        {
          name: t("settings:Email"),
          url: "email",
          component: <Email key="email" />,
        },
        {
          name: t("settings:Margin Migrations"),
          url: "margin-migrations",
          component: <GlobalMarginsMigration key="email" />,
        },
      ].filter(({ url }) => {
        if (url === "account") {
          return true;
        }

        return hasAccess(profile?.role, ADD_USERS);
      }),
    [t, profile]
  );

  const [tabIndex, setTabIndex] = useState(() => {
    const tabIndex = tabs.findIndex((tab) => tab.url === paramTab);
    return tabIndex !== -1 ? tabIndex : 0;
  });

  const handleTabsChange = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  useEffect(() => {
    history.replace(`/settings/${tabs[tabIndex].url}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("settings:Account and settings")}</Heading>
      </PageHeader>
      <Tabs handleTabsChange={handleTabsChange} tabIndex={tabIndex} tabs={tabs.map((tab) => tab.name)}>
        {tabs.map((tab, index) => (index === tabIndex ? tab.component : <div key={index}></div>))}
      </Tabs>
    </>
  );
}
