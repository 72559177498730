import React from "react";
import { BaseContact } from "interfaces/data";
import { Input } from "components/Input/Input";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "components/EditBox/EditBox";
import { COUNTRIES } from "helpers/options";
import { Select } from "../../../components/Select/Select";

export function EditContactAddress({ data, handleChange, errors }: EditViewProps<BaseContact>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["address.street"]}
        id="street"
        label={t("contact:Street")}
        name="address.street"
        onChange={handleChange}
        type="text"
        value={data.address?.street}
        withError={!!errors?.["address.street"]}
      />
      <Input
        error={errors?.["address.number"]}
        id="address.number"
        label={t("contact:Apt number")}
        name="address.number"
        onChange={handleChange}
        type="text"
        value={data.address?.number}
        withError={!!errors?.["address.number"]}
      />
      <Input
        error={errors?.["address.code"]}
        id="code"
        label={t("contact:Code")}
        name="address.code"
        onChange={handleChange}
        type="text"
        value={data.address?.code}
        withError={!!errors?.["address.code"]}
      />
      <Input
        error={errors?.["address.city"]}
        id="city"
        label={t("contact:City")}
        name="address.city"
        onChange={handleChange}
        type="text"
        value={data.address?.city}
        withError={!!errors?.["address.city"]}
      />
      <Select
        error={errors?.["address.country"]}
        id="country"
        label={t("contact:Country")}
        name="address.country"
        onChange={handleChange}
        options={COUNTRIES}
        value={data.address.country}
      />
    </TwoColumnsLayout>
  );
}
