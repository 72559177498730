import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Application } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { formatNumberToString } from "../../../helpers/formatNumber";

interface DetailsProps {
  application: Application;
}

export function Details({ application }: DetailsProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("application:Industry")} value={application.details?.industry} />
      <LabeledText label={t("application:Password")} value={application.details?.password} />
      <LabeledText label={t("application:Purpose")} value={application.details?.goal} />
      <LabeledText label={t("application:Origin of funds")} value={application.details?.source} />
      <LabeledText label={t("application:Limit")} value={formatNumberToString(application.details?.limit)} />
      <LabeledText label={t("application:Currency")} value={application.details?.currency} />
    </TwoColumnsLayout>
  );
}
