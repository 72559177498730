import { Box, PopoverBody } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";

interface StyledUserActivityBoxProps {
  hasWarning?: boolean;
}

export const StyledUserActivityBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hasWarning",
})<StyledUserActivityBoxProps>`
  position: ${(props) => (props.hasWarning ? `fixed;` : `absolute`)};
  width: 100%;
  z-index: 10;
  top: 0;
  right: 0;
  padding: 8px 50px 8px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.hasWarning && `background: ${props.theme.colors["sk-red"]};`}
  color: ${(props) => (props.hasWarning ? "#fff" : props.theme.colors["sk-dark"])};
  text-align: center;
  font-size: 13px;
`;

export const StyledPopoverBody = styled(PopoverBody)`
  color: black;
  text-align: left;
`;

export const StyledAvatarContainer = styled.div`
  cursor: pointer;
`;

export const StyledWarningBox = styled(Box)`
  margin-right: auto;
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
`;
