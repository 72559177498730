import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const RefreshIcon = createIcon({
  displayName: "RefreshIcon",
  path: (
    <g fill="none">
      <path
        d="M11.0921 9.99999C10.4458 10.9909 9.49683 11.7467 8.38638 12.1548C7.27592 12.5628 6.06338 12.6014 4.92922 12.2648C3.79507 11.9281 2.79994 11.2342 2.09196 10.2864C1.38397 9.33857 1.00099 8.18745 1 7.00438C0.999017 5.82132 1.38009 4.66957 2.08649 3.72055C2.7929 2.77153 3.78687 2.076 4.92046 1.73746C6.05405 1.39893 7.26666 1.4355 8.37779 1.84173C9.48893 2.24796 10.4392 3.00213 11.0871 3.99199"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4814 1V6L7.98145 5.5L12.4814 1Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: "0 0 13 13",
});
