import React from "react";
import styled from "@emotion/styled";
import { BoxProps, Text, Tooltip } from "@chakra-ui/react";
import { transformTimestamp } from "./date";
import { formatNumberToString } from "./formatNumber";
import { Payment } from "../interfaces/data";
import { DEAL_TYPE_BUY, TRANSACTION_TYPES } from "./transaction";
import { useCopyToClipboard } from "../hooks/useCopyToClipboard";
import { useTranslation } from "react-i18next";
import { CopyIcon } from "@chakra-ui/icons";

export type CurrencyCellProps = {
  value?: any;
  cellProps?: BoxProps;
};

const StyledCopyButton = styled.button`
  svg {
    margin-top: -2px;
    margin-left: 10px;
  }
`;

export const CurrencyCell: React.FC<CurrencyCellProps> = ({ value, cellProps }) => {
  const currency = value ? `${formatNumberToString(value.quantity)} ${value.currency}` : "";
  return (
    <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%" {...cellProps}>
      {currency}
    </Text>
  );
};

export function DateCellTransactionEndDate(arg: any) {
  const transactionIsSpotType = arg.row.original.type === TRANSACTION_TYPES.SPOT.value;

  return transactionIsSpotType ? transformTimestamp(arg.row.original.currencyDate) : transformTimestamp(arg.value);
}

export function DateCell(arg: any) {
  return transformTimestamp(arg.value);
}

export function BoldCell(arg: any) {
  return (
    <Text as="span" fontSize="15px" fontWeight="900">
      {arg.value}
    </Text>
  );
}

export function NumberCell(arg: any) {
  return (
    <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
      {formatNumberToString(arg.value)}
    </Text>
  );
}

export function hasPreviousRowRepeatedFields(arg: any, fields: Array<string>) {
  const rowId = Number(arg.row.id);
  if (rowId === 0) return false;

  let isRepeated = true;

  function resolve(path: string, obj: any) {
    return path.split(".").reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
      // eslint-disable-next-line no-restricted-globals
    }, obj || self);
  }

  fields.forEach((field) => {
    if (resolve(field, arg.rows[rowId].original) !== resolve(field, arg.rows[rowId - 1].original)) {
      isRepeated = false;
    }
  });
  return isRepeated;
}

export function QuantityCell(arg: any, enableCopy?: boolean, isSources?: boolean) {
  const { t } = useTranslation();
  const payment = arg.row.original as Payment;
  const copyToClipboard = useCopyToClipboard();
  if (!payment) return "";

  const isBuy = payment.transaction.dealType === DEAL_TYPE_BUY;
  const formattedValue =
    isBuy || isSources
      ? formatNumberToString(arg.value)
      : formatNumberToString(arg.value * payment.transaction.clientRate);

  return (
    <Text as="span" display="block" fontSize="15px" fontWeight="900" textAlign="right" whiteSpace="nowrap">
      {formattedValue}
      {enableCopy && (
        <StyledCopyButton onClick={() => copyToClipboard(formattedValue)}>
          <Tooltip aria-label="A copy tooltip" hasArrow label={t("helpers:Copy")} placement="top">
            <CopyIcon />
          </Tooltip>
        </StyledCopyButton>
      )}
    </Text>
  );
}
