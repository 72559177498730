import React, { Children, cloneElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, useDisclosure } from "@chakra-ui/react";
import { Drawer } from "components/Drawer/Drawer";
import { StyledDropdownButton } from "./shared.styled";
import { CollapsableSection } from "components/CollapsableSection/CollapsableSection";
import { MoreFiltersIcon } from "theme/icons";

interface MoreFiltersProps {
  children: any;
  isActive?: boolean;
  activeFilter?: string;
}

export const MoreFilters: React.FC<MoreFiltersProps> = ({ children, isActive = false, activeFilter }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clearAllFlag, setClearAllFlag] = useState(false);

  if (!children) return null;

  return (
    <>
      <Box ml="10px">
        <StyledDropdownButton isActive={isActive} onClick={onOpen}>
          {activeFilter ? (
            <Box fontWeight={800} whiteSpace="break-spaces">
              {activeFilter.replace(",", "\n")}
            </Box>
          ) : (
            t("transaction:More filters")
          )}
          <MoreFiltersIcon ml="auto" />
        </StyledDropdownButton>
      </Box>
      <Drawer isOpen={isOpen} onClose={onClose} title={t("transaction:More filters")}>
        {Children.map(children, (child) => (
          <CollapsableSection {...child.props}>
            {cloneElement(child, { clearAllFlag, setClearAllFlag })}
          </CollapsableSection>
        ))}
      </Drawer>
    </>
  );
};
