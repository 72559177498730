import React from "react";
import { useAuth } from "hooks/useAuth";
import { Box, Heading, Link } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { Checkbox } from "components/Checkbox/Checkbox";
import { Button } from "components/Button/Button";
import { useTranslation } from "react-i18next";
import { useToast } from "hooks/useToast";
import { Spinner } from "components/Spinner/Spinner";

type Inputs = {
  email: string;
  password: string;
  keepLogged: boolean;
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = React.useState<{ email: string; password: string }>({ email: "", password: "" });
  const [loading, setLoading] = React.useState(false);
  const { login } = useAuth();
  const toast = useToast();

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      const target = e.target as HTMLFormElement;

      const data: Inputs = {
        email: target.email.value,
        password: target.password.value,
        keepLogged: target.keepLogged.checked,
      };

      login(data)
        .then((err) => {
          if (err) {
            switch (err.code) {
              case "auth/invalid-email":
                setError({
                  password: "",
                  email: "invalidEmail",
                });
                break;
              case "auth/wrong-password":
                setError({
                  email: "",
                  password: "wrongPassword",
                });
                break;
              case "auth/user-not-found":
                setError({
                  password: "",
                  email: "userNotFound",
                });
                break;
              case "auth/too-many-requests":
                setError({
                  password: "",
                  email: "tooManyRequests",
                });
                break;
            }
          }
        })
        .finally(() => setLoading(false));
    },
    [login]
  );

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const resetPassword = params.get("reset-password");

    if (resetPassword === "success") {
      toast({
        type: "success",
        message: t("login:Password changed. You can now log in using new password."),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="xl">
      {loading && <Spinner />}

      <Heading as="h1" fontSize="42px" fontWeight="bold" mb={12}>
        {t("login:Welcome back!")}
      </Heading>
      <form onSubmit={handleSubmit}>
        <Input error={error.email} id="email" label="E-mail" name="email" topLabel type="text" withError />
        {error.email && <Box h="10px" />}
        <Input
          error={error.password}
          id="password"
          label={t("login:Password")}
          name="password"
          topLabel
          type="password"
          withError
        />
        {error.password && <Box h="10px" />}
        <Box alignContent="center" display="flex" mb={7}>
          <Button design="primary" mr="30px" px="62px" type="submit">
            {t("login:Log in")}
          </Button>
          <Checkbox defaultChecked name="keepLogged">
            {t("login:Keep me logged in")}
          </Checkbox>
        </Box>
        <Link color="sk-purple" fontSize="sm" fontWeight="bold" href="/reset-password" textDecoration="underline">
          {t("login:Forgot your password?")}
        </Link>
      </form>
    </Box>
  );
};

export default Login;
