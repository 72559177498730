import { z } from "zod";
import { AddressSchema, BankAccountSchema, CurrencySchema, CurrencyValueSchema, TimestampSchema } from "./common";
import { MarginSchema } from "./margin";
import { GlobalMarginCallSchema } from "./marginCall";
import { ContactSchema } from "./contact";

const CompanyDetailsSchema = z.object({
  industry: z.string(),
  goal: z.string(),
  source: z.string(),
  currency: CurrencySchema.nullable(),
  limit: z.number().optional().nullable(),
  notes: z.string().optional().nullable(),
  password: z.string().optional(),
  score: z.string().optional(),
  nolimits: z.boolean().optional(),
});

const CompanyOtherSchema = z.object({
  notes: z.string().optional(),
  communicationLanguage: z
    .enum(["PL", "EN", ""])
    .optional()
    .transform((val) => (!val ? "PL" : val)),
  ip: z.string().optional(),
});

const PaymentEntrySchema = z.object({
  amount: z.number(),
  currency: CurrencySchema,
  marginId: z.string(),
  date: TimestampSchema,
  comment: z.string().optional(),
});

export const CompanySchema = z.object({
  id: z.string(),
  createdAt: TimestampSchema,
  modifiedAt: TimestampSchema.optional(),
  name: z.string(),
  nip: z.string(),
  krs: z.string().nullable(),
  legalEntity: z.string(),
  regon: z.string(),
  details: CompanyDetailsSchema,
  companyAddress: AddressSchema,
  contactAddress: AddressSchema.optional(),
  other: CompanyOtherSchema,
  contacts: z.array(ContactSchema),
  bankAccounts: z.array(BankAccountSchema).optional(),
  cc: z.boolean().optional(),
  isDeactivated: z.boolean().optional(),
  reversePayment: z
    .object({
      limit: CurrencyValueSchema,
      date: TimestampSchema.optional(),
    })
    .optional(),
  globalMargins: z.array(MarginSchema).optional(),
  globalMarginCalls: z.array(GlobalMarginCallSchema).optional(),
  gmcId: z.string().optional(),
  paymentEntries: z.array(PaymentEntrySchema).optional(),
  scoring: z
    .object({
      questions: z.array(
        z.object({
          questionId: z.number(),
          score: z.number(),
        })
      ),
    })
    .optional(),
});

export type Company = z.infer<typeof CompanySchema>;
