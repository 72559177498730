import { Box, useDisclosure } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button/Button";
import { SearchBox } from "components/SearchBox/SearchBox";
import { Select } from "components/Select/Select";
import { Table } from "components/Table/Table";
import { USER_ROLES } from "helpers/options";
import { useProfiles } from "hooks/useProfiles";
import { Contact, Profile } from "interfaces/data";
import { StyledPanel } from "../Settings";
import { DeleteUser } from "./DeleteUser";
import { EditUser } from "./EditUser";
import { InviteUser } from "./InviteUser";
import { AssignDealer } from "./AssignDealer";
import { DeleteIcon, LinkIcon } from "@chakra-ui/icons";

const StyledHeader = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
`;

export function Members() {
  const { profiles, fetch, loading } = useProfiles();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedProfile, setEditedProfile] = useState<Profile>();
  const [deleteProfile, setDeleteProfile] = useState<Profile>();
  const [assignToCompany, setAssignToCompany] = useState<Profile>();
  const [contactAssignOpen, setContactAssignOpen] = useState(undefined);

  const handleProfileChange = (newProfile: Profile) => {
    setEditedProfile(newProfile);
  };

  const handleAddMemberSuccess = () => {
    fetch();
    onClose();
  };

  const handleEditModalClose = () => {
    setEditedProfile(undefined);
  };

  const handleEditModalSuccess = () => {
    fetch();
    handleEditModalClose();
    setContactAssignOpen(undefined);
  };

  const handleDeleteModalClose = () => {
    setDeleteProfile(undefined);
  };

  const handleDeleteModalSuccess = () => {
    fetch();
    handleDeleteModalClose();
  };

  const RoleCell = (arg: any) => {
    const profile = arg.row.original as Profile;
    return (
      <Select
        id="role"
        name="role"
        onChange={async (e: any) => {
          profile.role = e.target.value;
          handleProfileChange(profile);
        }}
        options={USER_ROLES}
        value={arg.value}
      />
    );
  };

  const ContactCell = (arg: any) => {
    const profile = arg.row.original as Profile;

    return arg.value === contactAssignOpen || !arg.value ? (
      <SearchBox
        collection="contacts"
        fields="firstName,lastName,email"
        item={(contact: Contact) => `${contact.firstName} ${contact.lastName} (${contact.email})`}
        onSelect={(contact) => {
          if (!contact) {
            return;
          }

          profile.contactId = contact.id;
          profile.connectedWith = {
            firstName: contact.firstName,
            lastName: contact.lastName,
          };
          handleProfileChange(profile);
        }}
        orderBy="firstName"
        persist={(contact: Contact) => `${contact.firstName} ${contact.lastName}`}
        placeholder="Search"
      />
    ) : (
      <Box cursor="pointer" onClick={() => setContactAssignOpen(arg.value)}>
        {profile.connectedWith?.firstName} {profile.connectedWith?.lastName}
      </Box>
    );
  };

  const ActionsCell = (arg: any) => {
    const profile = arg.row.original as Profile;
    const displayLink = profile.role === "Dealer";
    return (
      <>
        <button
          onClick={() => {
            setDeleteProfile(profile);
          }}
        >
          <DeleteIcon boxSize="6" color="#ff0000" mr="20px" />
        </button>
        {displayLink && (
          <button
            onClick={() => {
              setAssignToCompany(profile);
            }}
          >
            <LinkIcon boxSize="6" />
          </button>
        )}
      </>
    );
  };

  const columns = [
    {
      Header: t("settings:Name"),
      accessor: "fullName",
      Cell: (arg: any) => {
        const profile = arg.row.original as Profile;
        return `${profile.name} ${profile.surname}`;
      },
    },
    {
      Header: t("settings:Email"),
      accessor: "email",
    },
    {
      Header: t("settings:Connected contact"),
      accessor: "contactId",
      Cell: ContactCell,
    },
    {
      Header: t("settings:Role"),
      accessor: "role",
      Cell: RoleCell,
    },
    {
      Header: () => null,
      accessor: "actions",
      Cell: ActionsCell,
    },
  ];

  const sortedProfiles = profiles.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledPanel>
        <Box alignItems="center" display="flex" padding="30px">
          <StyledHeader>{t("settings:Organization members")}</StyledHeader>
          <Button design="primary" ml="auto" onClick={onOpen}>
            {t("settings:Invite member")}
          </Button>
        </Box>
        <Table
          columns={columns}
          data={sortedProfiles}
          initialPageSize={25}
          loading={loading}
          manualPagination={false}
          pageSizeOptions={[25, 50]}
        />
      </StyledPanel>
      {editedProfile && (
        <EditUser onClose={handleEditModalClose} onSuccess={handleEditModalSuccess} user={editedProfile} />
      )}
      {deleteProfile && (
        <DeleteUser onClose={handleDeleteModalClose} onSuccess={handleDeleteModalSuccess} user={deleteProfile} />
      )}
      {assignToCompany && (
        <AssignDealer
          onClose={() => setAssignToCompany(undefined)}
          onSuccess={() => {
            fetch();
            setAssignToCompany(undefined);
          }}
          user={assignToCompany}
        />
      )}
      <InviteUser isOpen={isOpen} onClose={onClose} onSuccess={handleAddMemberSuccess} />
    </>
  );
}
