import React from "react";
import { Redirect } from "react-router-dom";

import { hasAccess, RoleAreas } from "../../helpers/userRoles";
import { useAuth } from "../../hooks/useAuth";

type RestrictAccessProps = {
  area?: RoleAreas;
  children: React.ReactNode;
  redirectTo?: string;
};

export const RestrictAccess = ({ area, children, redirectTo }: RestrictAccessProps): JSX.Element | null => {
  const { profile } = useAuth();

  return hasAccess(profile?.role, area) ? <>{children}</> : redirectTo ? <Redirect to={redirectTo} /> : null;
};
