import { Box, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "../../../../../components/Table/Table";
import { formatNumberToString, formatRate } from "../../../../../helpers/formatNumber";
import { StyledTableContainer } from "../../../../Transactions/Transactions";
import theme from "../../../../../theme/theme";
import { transformTimestamp } from "../../../../../helpers/date";
import { ExtendedHedge } from "../../MassHedgeRoll";

interface TransactionTableProps {
  hedges: Array<ExtendedHedge>;
  setSelectedHedges: Dispatch<ExtendedHedge[]>;
  selectedDate: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
  resetSelected?: boolean;
}

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
`;

const StyledTableWrapper = styled(StyledTableContainer)`
  max-height: 760px;
  overflow-y: auto;
`;

export const BoldText = styled.span`
  font-weight: 900;
`;

const IdCell = (arg: any) => {
  const hedge = arg.row.original as ExtendedHedge;
  return (
    <StyledLink to={`/transactions/${hedge.transactionId}`}>
      <Box as="span" display="block" whiteSpace="nowrap">
        {hedge.id}
      </Box>
    </StyledLink>
  );
};

export const TransactionTable: React.FC<TransactionTableProps> = ({
  hedges,
  setSelectedHedges,
  selectedDate,
  setSelectedDate,
  resetSelected = false,
}) => {
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("");
  const [selectedDealType, setSelectedDealType] = useState("");
  const [enableAllFields, setEnableAllFields] = useState(true);
  const { t } = useTranslation();
  const shouldShowAllRowSelect = new Set(hedges.map((hedge) => hedge.currencyPair)).size === 1;

  const columns = useMemo(
    () => [
      {
        Header: t("transaction:ID"),
        accessor: "number",
        Cell: IdCell,
      },
      {
        Header: t("transaction:Type"),
        accessor: "dealType",
      },
      {
        Header: t("transaction:Client Rate"),
        accessor: "clientRate",
        Cell: (arg: any) => (
          <Text as="span" fontSize="15px" fontWeight="900">
            {formatRate(arg.value)}
          </Text>
        ),
      },

      {
        Header: () => (
          <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
            {t("transaction:LQT")}
          </Text>
        ),
        accessor: "hedge.leftQuantity",
        Cell: (props: any) => {
          const hedge = props.row.original as ExtendedHedge;
          return (
            <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
              {formatNumberToString(hedge.leftQuantity)} {hedge.fromCurrency}
            </Text>
          );
        },
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {t("transaction:Currency")}
          </Text>
        ),
        accessor: "toCurrency",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {arg.value}
          </Text>
        ),
      },
      {
        Header: t("transaction:Company"),
        accessor: "company.name",
      },
      {
        Header: t("transaction:Transaction ID"),
        accessor: "transaction.number",
      },
      {
        Header: t("transaction:Transaction End"),
        accessor: "transaction.end",
        Cell: (arg: any) => {
          return <Text as="span">{transformTimestamp(arg.value)}</Text>;
        },
      },
      {
        Header: t("transaction:Hedge End"),
        accessor: "end",
        Cell: (arg: any) => {
          return <Text as="span">{transformTimestamp(arg.value)}</Text>;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const setSelectedRow = (selectedRows: Array<boolean>) => {
    const selectedHedges = hedges.filter((t, index) => selectedRows[index]);
    setSelectedHedges(selectedHedges);
    if (selectedHedges.length) {
      setSelectedCurrencyPair(selectedHedges[0].currencyPair);
      setSelectedDealType(selectedHedges[0].dealType);
      setSelectedDate(selectedHedges[0].end as any);
      setEnableAllFields(false);
    } else {
      setEnableAllFields(true);
    }
  };

  const isRowActive = (hedge: ExtendedHedge) => {
    if (
      (hedge.currencyPair === selectedCurrencyPair &&
        hedge.dealType === selectedDealType &&
        transformTimestamp(hedge.end) === transformTimestamp(selectedDate)) ||
      enableAllFields
    ) {
      return null;
    }
    return "inactive-row";
  };

  return (
    <StyledTableWrapper>
      <Box backgroundColor={theme.colors["sk-purple"]} h="12px" />
      <Box backgroundColor="white" fontSize="18px" fontWeight="bold" padding="18px 30px 5px 30px">
        {t("Company:Hedges")}
      </Box>
      <Table
        columns={columns}
        data={hedges}
        getRowProps={(rowInfo) => {
          const transaction = rowInfo.original as ExtendedHedge;
          return {
            className: isRowActive(transaction),
          };
        }}
        multipleRowSelect
        paginated={false}
        resetSelected={resetSelected}
        setRowsSelected={setSelectedRow}
        shouldShowAllRowSelect={shouldShowAllRowSelect}
      />
    </StyledTableWrapper>
  );
};
