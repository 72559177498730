import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const NoteIcon = createIcon({
  displayName: "NoteIcon",
  path: (
    <g fill="none">
      <path d="M10 11.5H1V0.5H10V11.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 4.5H8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 7.5H8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: "0 0 11 12",
});
