import React from "react";
import { BaseCompany } from "../../../interfaces/data";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { CURRENCIES } from "../../../helpers/options";
import { AutoCompleteSelect } from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { Select } from "../../../components/Select/Select";
import { Checkbox } from "../../../components/Checkbox/Checkbox";

const MAX_SCORE = 10;

const scoreOptions = Array.from({ length: MAX_SCORE }, (_, i) => {
  const score = String(i + 1);
  return { label: score, value: score };
});

scoreOptions.reverse().unshift({ label: "", value: "" });

export function EditDetails({ data, handleChange, errors }: EditViewProps<BaseCompany>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["details.industry"]}
        id="industry"
        label={t("company:Industry")}
        name="details.industry"
        onChange={handleChange}
        type="text"
        value={data.details?.industry}
        withError={!!errors?.["details.industry"]}
      />
      <Input
        error={errors?.["details.password"]}
        id="password"
        label={t("company:Password")}
        name="details.password"
        onChange={handleChange}
        type="text"
        value={data.details?.password}
        withError={!!errors?.["details.password"]}
      />
      <Input
        error={errors?.["details.goal"]}
        id="goal"
        label={t("company:Purpose")}
        name="details.goal"
        onChange={handleChange}
        type="text"
        value={data.details?.goal}
        withError={!!errors?.["details.goal"]}
      />
      <Input
        error={errors?.["details.source"]}
        id="source"
        label={t("company:Origin of funds")}
        name="details.source"
        onChange={handleChange}
        type="text"
        value={data.details?.source}
        withError={!!errors?.["details.source"]}
      />
      <Input
        error={errors?.["details.limit"]}
        formatNumberOnBlur
        id="limit"
        label={t("company:Limit")}
        name="details.limit"
        onChange={handleChange}
        type="number"
        value={data.details?.limit}
        withError={!!errors?.["details.limit"]}
      />
      <Checkbox defaultIsChecked={data.details?.nolimits} name="details.nolimits" onChange={handleChange}>
        {t(`transaction:No limit`)}
      </Checkbox>
      <AutoCompleteSelect
        error={errors?.["details.currency"]}
        id="currency"
        label={t("company:Currency")}
        name="details.currency"
        onChange={handleChange}
        options={CURRENCIES}
        value={data.details?.currency}
        withError={!!errors?.["details.currency"]}
      />
      <div>
        <Select
          error={errors?.["details.score"]}
          id="score"
          label={t("company:Score")}
          name="details.score"
          onChange={handleChange}
          options={scoreOptions}
          value={data.details?.score}
        />
      </div>
      <Input
        error={errors?.["details.notes"]}
        fullWidth
        id="notes"
        label={t("company:Notes")}
        name="details.notes"
        onChange={handleChange}
        type="text"
        value={data.details?.notes}
        withError={!!errors?.["details.notes"]}
      />
    </TwoColumnsLayout>
  );
}
