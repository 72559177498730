import React, { useCallback, useEffect, useState } from "react";
import { Box, Radio, RadioGroup, Text, useDisclosure } from "@chakra-ui/react";
import { useScoring } from "hooks/useScoring";
import { Company } from "interfaces/data";
import { StyledEditButton, TwoColumnsLayout } from "views/Scoring/shared";
import { Section, SectionTitle } from "../shared.styled";
import { useTranslation } from "react-i18next";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import styled from "@emotion/styled";
import { Modal } from "components/Modal/Modal";
import theme from "theme/theme";
import { useCompanyScoring } from "hooks/useCompanyScoring";
import { EditIcon } from "theme/icons/EditIcon";

interface ScoringSectionProps {
  company: Company;
}

interface EditQuestionsProps {
  company: Company;
}

const StyledRadioContainer = styled.div`
  display: flex;
  label {
    display: flex;
  }
`;

const EditQuestions = ({ company }: EditQuestionsProps) => {
  const keys = [-2, -1, 0, 1, 2];
  const companyQuestions = company?.scoring?.questions;
  const { update } = useCompany({ id: company.id });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { activeQuestions } = useScoring();
  const toast = useToast();
  const [questionsScores, setQuestionsScores] = useState(
    activeQuestions.map((q) => ({
      questionId: q.id,
      score: 0,
    }))
  );

  useEffect(() => {
    setQuestionsScores(
      activeQuestions.map((q) => ({
        questionId: q.id,
        score: companyQuestions?.find((cq) => cq?.questionId === q.id)?.score || 0,
      }))
    );
  }, [activeQuestions, companyQuestions]);

  const handleClose = () => {
    onClose();
  };

  const handleEditQuestions = useCallback(
    () => {
      const newCompany = {
        ...company,
        scoring: {
          questions: questionsScores,
        },
      };
      update(newCompany);
      toast({
        type: "success",
        message: t("scoring:Scores successfully edited."),
      });
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [questionsScores, update]
  );

  const handleFieldChange = (value: any, id: number) => {
    const newQuestionScore = questionsScores.map((qs) =>
      qs.questionId === id ? { questionId: id, score: Number(value) } : qs
    );
    setQuestionsScores(newQuestionScore);
  };

  const getFieldValue = (id: number) => {
    try {
      return questionsScores?.find((qs) => qs?.questionId === id)?.score || 0;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        confirmText={t("scoring:Edit")}
        design="primary"
        isOpen={isOpen}
        onClose={handleClose}
        onConfirm={handleEditQuestions}
        title={t("scoring:Questionare")}
      >
        {activeQuestions.map((question) => (
          <TwoColumnsLayout key={question.id} withPaddingBottom>
            <Text as="span">{question.title}</Text>
            <StyledRadioContainer>
              <RadioGroup
                name={question.id.toString()}
                onChange={(e: any) => handleFieldChange(e.target.value, question.id)}
                value={getFieldValue(question.id)}
              >
                {keys.map((item) => (
                  <Radio colorScheme="purple" fontSize="13px" key={item} value={item}>
                    <Text as="span" marginLeft="3px">
                      {item}
                    </Text>
                  </Radio>
                ))}
              </RadioGroup>
            </StyledRadioContainer>
          </TwoColumnsLayout>
        ))}
      </Modal>
      <Text
        as="span"
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        <StyledEditButton>
          <EditIcon />
        </StyledEditButton>
      </Text>
    </>
  );
};

export const ScoringSection = ({ company }: ScoringSectionProps) => {
  const { companyScore } = useCompanyScoring(company);

  return (
    <Section>
      <Box backgroundColor={theme.colors["sk-purple"]} h="12px" />
      <Box
        alignItems="center"
        backgroundColor="white"
        display="flex"
        fontSize="18px"
        fontWeight="bold"
        justifyContent="space-between"
        padding="18px 30px 5px 30px"
      >
        <SectionTitle>
          Scoring: {companyScore}
          <EditQuestions company={company} />
        </SectionTitle>
      </Box>
    </Section>
  );
};
