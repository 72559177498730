import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { formatNumberToString } from "../../../helpers/formatNumber";
import { transformTimestamp } from "../../../helpers/date";

interface ReversePaymentProps {
  company: Company;
}

export function ReversePayment({ company }: ReversePaymentProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText
        label={t("company:Limit")}
        value={
          company.reversePayment?.limit.quantity
            ? formatNumberToString(company.reversePayment?.limit.quantity || "")
            : "-"
        }
      />
      <LabeledText label={t("company:Currency")} value={company.reversePayment?.limit.currency} />
      <LabeledText fullWidth label={t("company:Date")} value={transformTimestamp(company.reversePayment?.date)} />
    </TwoColumnsLayout>
  );
}
