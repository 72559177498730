import styled from "@emotion/styled";

export const TransactionConfirmContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  padding: 20px 0;
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  white-space: nowrap;
`;

export const TransactionConfirmLabel = styled.span`
  color: ${(props) => props.theme.colors["sk-gray"]};
  text-align: right;
`;

export const TransactionConfirmDescription = styled.span`
  white-space: pre-wrap;
  font-weight: bold;
`;
