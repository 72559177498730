import React from "react";
import { Section, SectionTitle } from "../shared.styled";
import { Contact } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { Contact as ContactItem } from "../../Company/Contacts/Contact";

interface SourceSectionProps {
  contacts?: Array<Contact>;
}

export const SourceSection: React.FC<SourceSectionProps> = ({ contacts }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle>{t("Source")}</SectionTitle>
      {contacts && contacts.length
        ? contacts
            .filter((contact) => contact.source === "yes")
            .map((contact, index) => <ContactItem key={index} {...contact} hideAvatar roleOnBottom />)
        : t("No source")}
    </Section>
  );
};
