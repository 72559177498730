import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ApproveIcon = createIcon({
  displayName: "ApproveIcon",
  path: (
    <g fill="none">
      <path
        d="M1.5 5L5.5 9L13.5 1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 15 10",
});
