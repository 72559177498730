import React from "react";
import { Currency, Hedge } from "interfaces/data";
import styled from "@emotion/styled";

import { Checkbox } from "components/Checkbox/Checkbox";
import { transformTimestamp } from "helpers/date";
import { formatNumberToString } from "helpers/formatNumber";
import { Box } from "@chakra-ui/react";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { NoteIcon, RefreshIcon } from "theme/icons";

interface SingleHedgeProps {
  hedge: Hedge;
  currency: Currency;
  isSelected?: boolean;
  onSelect?: (selectedHedge: Hedge) => void;
  isDisabled?: boolean;
  isRed?: boolean;
}

const StyledHedge = styled.div<{
  isDisabled?: boolean;
  isRed?: boolean;
}>`
  border: 1px solid ${(props) => props.theme.colors[props.isRed ? "sk-red" : "sk-light-gray"]};
  border-radius: 16px;
  padding: 20px 20px 0 20px;
  margin-bottom: 10px;
  font-size: 13px;
  ${(props) => props.isDisabled && "opacity: 0.5;"};
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(129, 129, 129, 0.1);
    transition: background-color 0.15s ease-in;
  }
`;

const StyledCreatedFrom = styled.p`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SingleHedge: React.FC<SingleHedgeProps> = ({
  hedge,
  currency,
  isSelected = false,
  onSelect = () => {
    return;
  },
  isDisabled = false,
  isRed,
}) => {
  const copyToClipboard = useCopyToClipboard();
  const hedgeEnd = transformTimestamp(hedge.end);

  const handleHedgeChange = (e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (e.target instanceof HTMLInputElement || e.target instanceof HTMLDivElement) {
      onSelect(hedge);
    }
  };

  return (
    <StyledHedge isDisabled={isDisabled} isRed={isRed} onClick={handleHedgeChange}>
      <StyledRow>
        <Checkbox isChecked={isSelected} mt="-4px" />
        <Box display="flex">
          {hedge.id}{" "}
          {hedge.comment && (
            <Box alignItems="center" display="flex" ml="5px" title={hedge.comment}>
              <NoteIcon height="11px" />
            </Box>
          )}
        </Box>
        <p>{hedge.institution}</p>
        <p>Fwd: {hedge.points}</p>
        <p>B: {transformTimestamp(hedge.start)}</p>
        <div
          onClick={() => copyToClipboard(hedgeEnd)}
          role="button"
          style={{
            fontWeight: isDisabled ? "inherit" : "bolder",
            fontSize: isDisabled ? "inherit" : "16px",
          }}
          tabIndex={0}
        >
          E: {hedgeEnd}
        </div>
      </StyledRow>
      <StyledRow>
        <StyledCreatedFrom>
          {hedge.createdFromId && (
            <>
              <RefreshIcon mr="7px" mt="-4px" />
              {hedge.createdFromId}
            </>
          )}
        </StyledCreatedFrom>
        <div>
          <Box display="inline">
            LQty: {formatNumberToString(hedge.leftQuantity)} {currency}
          </Box>
          <Box display="inline" ml="20px">
            Qty: {formatNumberToString(hedge.quantity)} {currency}
          </Box>
        </div>
      </StyledRow>
    </StyledHedge>
  );
};
