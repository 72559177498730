import React, { useCallback, useEffect, useState } from "react";
import _set from "lodash.set";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "components/Modal/Modal";
import { useNbpRate } from "hooks/useNbpRate";
import { useImmer } from "use-immer";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { MarginGrid } from "../../Margin/MarginFormModal/MarginFormModal.styled";
import { SelectWithLabel } from "components/SelectWithLabel/SelectWithLabel";
import { TotalValue } from "../../Margin/MarginFormModal/TotalValue/TotalValue";
import { useFirebase } from "hooks/useFirebase";
import { MARGIN_TYPES } from "helpers/transaction";
import { Margin } from "interfaces/data";
import { CURRENCIES, MARGIN_TYPES_OPTIONS } from "helpers/options";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import { formatDate } from "helpers/date";
import { useFormEvents } from "hooks/useFormEvents";

export function GlobalMarginAdd() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const toast = useToast();
  const { timestamp, timestampFrom } = useFirebase();
  const [isSending, setIsSending] = useState(false);
  const { globalMarginAdd, marginErrors, addPaymentEntry } = useCompany({
    id,
  });
  const initialMargin = {
    id: "",
    date: timestamp(),
    type: MARGIN_TYPES.VARIATION.value,
    from: {
      currency: "PLN",
      quantity: 0,
    },
    to: {
      currency: "PLN",
      quantity: 0,
    },
    left: {
      currency: "PLN",
      quantity: 0,
    },
  };
  const [margin, setMargin] = useImmer(initialMargin);
  const [clearSpacesOnPaste] = useFormEvents();

  const { rateInfo } = useNbpRate({
    from: margin.from.currency,
    to: margin.to.currency,
  });

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    async (newMargin: Margin) => {
      setIsSending(true);

      try {
        const savedMargin = await globalMarginAdd(newMargin);
        if (savedMargin) {
          await addPaymentEntry(savedMargin.left.quantity, savedMargin.left.currency, savedMargin.id, savedMargin.date);
          setIsSending(false);

          toast({
            type: "success",
            message: t("margin:Global margin has been added successfully"),
          });

          handleClose();
        }
      } catch (error) {
        setIsSending(false);
        if (error instanceof Error) {
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        }
      }
    },
    [globalMarginAdd, addPaymentEntry, toast, t, handleClose]
  );

  const handleUpdateDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setMargin((oldEditedData: any) => {
        const newEditedData = { ...oldEditedData };
        const date = new Date(e.target.value);
        _set(newEditedData, e.target.name, timestampFrom(date));
        return newEditedData;
      });
    },
    [setMargin, timestampFrom]
  );

  const handleUpdate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
      e.persist();
      const value = e.target.type === "number" ? Number(e.target.value) : e.target.value;

      setMargin((draft: Margin) => {
        _set(draft, e.target.name, value);
      });
    },
    [setMargin]
  );

  useEffect(() => {
    if (!rateInfo || !rateInfo.rate) {
      return;
    }
    setMargin((draft: Margin) => {
      draft.to.quantity = draft.from.quantity * rateInfo.rate;
    });
  }, [margin.from.quantity, margin.from.currency, rateInfo, setMargin]);

  return (
    <Modal
      bodyProps={{
        padding: 0,
      }}
      confirmText={t("Save")}
      design="primary"
      isDisabled={isSending}
      isOpen
      justifyButtons="flex-end"
      minWidth={899}
      onClose={handleClose}
      onConfirm={() => handleOnSaveAction(margin)}
      title={t("margin:Add global margin")}
    >
      <MarginGrid p={30}>
        <SelectWithLabel
          error={marginErrors?.["type"]}
          id="type"
          isDisabled
          label={t("margin:Type")}
          name="type"
          onChange={handleUpdate}
          options={MARGIN_TYPES_OPTIONS}
          value={margin.type}
        />
        <AutoCompleteSelect
          error={marginErrors?.["from.currency"]}
          id="currency"
          label={t("margin:Currency")}
          layout="vertical"
          name="from.currency"
          onChange={handleUpdate}
          options={CURRENCIES}
          placeholder="Select"
          value={margin?.from.currency}
          withError={!!marginErrors?.["from.currency"]}
        />
        <InputWithLabel
          error={marginErrors?.["from.quantity"]}
          formatNumberOnBlur
          id="quantity"
          label={t("margin:Quantity")}
          name="from.quantity"
          onChange={handleUpdate}
          onPaste={clearSpacesOnPaste}
          type="number"
          value={margin.from.quantity || ""}
          withError={!!marginErrors?.["from.quantity"]}
        />
        <InputWithLabel
          error={marginErrors?.["date"]}
          icon="calendar"
          id="date"
          label={t("transaction:Date")}
          name="date"
          onChange={handleUpdateDate}
          type="date"
          value={formatDate(margin.date)}
          withError={!!marginErrors?.["date"]}
        />
      </MarginGrid>
      <TotalValue currency={margin.from.currency} label={t("margin:Total")} mb={30} quantity={margin.from.quantity} />
    </Modal>
  );
}
