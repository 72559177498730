import React, { useCallback, useState } from "react";
import { Settlement } from "interfaces/data";
import { DELIVERY_TYPES } from "helpers/transaction";
import { useHistory, useParams } from "react-router-dom";
import { useTransaction } from "hooks/useTransaction";
import { useFirebase } from "hooks/useFirebase";
import { useTranslation } from "react-i18next";
import { SettlementFormModal } from "./SettlementFormModal/SettlementFormModal";
import { useToast } from "hooks/useToast";
import { useMail } from "hooks/useMail";
import { SETTLEMENT_MAIL } from "helpers/mails";
import { useIsMounted } from "hooks/useIsMounted";
import { SettlementWithOptionalIdSchema } from "schemas/settlement";

export function SettlementAdd() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { timestamp } = useFirebase();
  const { transaction, settlementAdd, settlementErrors } = useTransaction({
    id,
  });
  const sendSettlementMail = useMail();
  const [isSending, setIsSending] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const { isMounted } = useIsMounted();

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newSettlement: Partial<Settlement>) => {
      setIsSending(true);
      const settlement = SettlementWithOptionalIdSchema.parse(newSettlement);
      settlementAdd(settlement as Settlement)
        .then((data) => {
          setIsSending(false);
          if (data) {
            if (transaction?.type === "FR" && sendEmail) {
              sendSettlementMail(SETTLEMENT_MAIL, data.transaction, {
                settlement: data.settlement,
                correction: "no",
              });
            }
            toast({
              type: "success",
              message: t("settlement:Settlement has been added successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`settlement:${error.message}`),
          });
        });
    },
    [settlementAdd, transaction, sendEmail, toast, t, handleClose, sendSettlementMail]
  );

  if (!transaction || !isMounted) {
    return null;
  }

  return (
    <SettlementFormModal
      errors={settlementErrors}
      initialSettlement={{
        date: timestamp(),
        type: DELIVERY_TYPES.PHYSICAL.value,
        quantity: 0,
        fwdPoints: 0,
        rollback: 0,
        points: 0,
        hedges: [],
        closedRate: 0,
      }}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      sendEmail={sendEmail}
      sendEmailChange={setSendEmail}
      title={t(`settlement:Partially settle transaction #{{number}}`, {
        number: transaction.number,
      })}
      transaction={transaction}
      type="add"
    />
  );
}
