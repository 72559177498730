import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { formatNumberToString } from "../../../helpers/formatNumber";

interface DetailsProps {
  company: Company;
}

export function Details({ company }: DetailsProps) {
  const { t } = useTranslation();
  const limits = (company: Company) => {
    if (company.details.nolimits) {
      return "off";
    }
    if (company.details.limit > 0) {
      return formatNumberToString(company.details?.limit);
    }
    return "auto";
  };

  return (
    <TwoColumnsLayout>
      <LabeledText label={t("company:Industry")} value={company.details?.industry} />
      <LabeledText label={t("company:Password")} value={company.details?.password} />
      <LabeledText label={t("company:Purpose")} value={company.details?.goal} />
      <LabeledText label={t("company:Origin of funds")} value={company.details?.source} />
      <LabeledText label={t("company:Limit")} value={limits(company)} />
      <LabeledText label={t("company:Currency")} value={company.details?.currency} />
      <LabeledText fullWidth label={t("company:Score")} value={company.details?.score} />
      <LabeledText fullWidth label={t("company:Notes")} value={company.details?.notes} />
    </TwoColumnsLayout>
  );
}
