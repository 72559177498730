import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../components/Checkbox/Checkbox";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { Box } from "@chakra-ui/react";
import { StyledClearButton, StyledDropdownButton, StyledDropdownContent } from "./shared.styled";
import { DEAL_TYPE_BUY, DEAL_TYPE_SELL } from "../../../helpers/transaction";
import { SelectIcon } from "../../../theme/icons";

interface DealFilterProps {
  onChange?: (newDealFilter: string) => void;
}

export const DealFilter: React.FC<DealFilterProps> = ({ onChange }) => {
  const { t } = useTranslation();

  const [dealFilter, setDealFilter] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(dealFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealFilter]);

  return (
    <Box ml="10px">
      <Dropdown
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        trigger={
          <StyledDropdownButton isActive={dealFilter !== ""} onClick={() => setIsOpen(!isOpen)}>
            <div>{dealFilter ? <Box fontWeight={800}>{dealFilter}</Box> : t("transaction:Deals")}</div>
            <SelectIcon ml="auto" />
          </StyledDropdownButton>
        }
      >
        <StyledDropdownContent>
          <Checkbox
            checkboxSize="small"
            isChecked={dealFilter === DEAL_TYPE_SELL}
            onChange={() => {
              setDealFilter(DEAL_TYPE_SELL);
              setIsOpen(false);
            }}
          >
            {t("transaction:Show sells only")}
          </Checkbox>
          <Checkbox
            checkboxSize="small"
            isChecked={dealFilter === DEAL_TYPE_BUY}
            onChange={() => {
              setDealFilter(DEAL_TYPE_BUY);
              setIsOpen(false);
            }}
          >
            {t("transaction:Show buys only")}
          </Checkbox>
          <StyledClearButton
            onClick={() => {
              setDealFilter("");
              setIsOpen(false);
            }}
          >
            {t("transaction:clear")}
          </StyledClearButton>
        </StyledDropdownContent>
      </Dropdown>
    </Box>
  );
};
