import { Payment } from "../interfaces/data";
import { UseCommissionFeeSources } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useCommissionFeeSources = ({
  phrase,
  fields,
  extraOptions,
  filtersAndBetween,
  orderBy,
  orderDirection,
  offset,
  limit,
}: UseCommissionFeeSources) => {
  const {
    data: commissionFeeSources,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Payment>({
    collection: "commission-fees/sources",
    phrase,
    extraOptions,
    filtersAndBetween,
    fields,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate: true,
  });

  return {
    commissionFeeSources,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  };
};
