import React, { useCallback, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { Modal } from "../../components/Modal/Modal";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCompany } from "../../hooks/useCompany";
import { useToast } from "../../hooks/useToast";

export const CompanyDelete: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { company, loading, remove } = useCompany({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}/preview`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleConfirm = useCallback(() => {
    setIsSending(true);
    remove(id, company)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("company:Company has been deleted successfully."),
          });
          history.push("/companies/");
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`company:${error.message}`),
        });
        handleClose();
      })
      .finally(() => {
        setIsSending(false);
      });
  }, [remove, id, company, toast, t, history, handleClose]);

  if (loading) {
    return <Spinner />;
  }

  if (!company) {
    return null;
  }

  return (
    <Modal
      confirmText={t("company:Delete")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={`${t("company:Delete company {{name}}", {
        name: company.name,
      })} `}
    >
      {t(`marginCall:This action can't be undone. Are you sure you want to delete company {{name}}?`, {
        name: company.name,
      })}
    </Modal>
  );
};
