import styled from "@emotion/styled";
import { StyledInfoContainer } from "./ExchangeRateSimulation/ExchangeRateSimulation";

export const StyledContainer = styled.section`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  margin-bottom: 20px;
  background: white;
  max-height: 800px;
  overflow-y: auto;
`;

export const StyledSectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
  padding: 0 30px;
`;

export const StyledButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;

export const StyledButton = styled.button`
  color: ${(props) => props.theme.colors["sk-purple"]};
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
  text-decoration: underline;
`;

export const StyledCommitmentsHeader = styled(StyledSectionHeader)`
  display: block;
`;

export const StyledCommitmentsContainer = styled(StyledInfoContainer)`
  display: block;
`;
