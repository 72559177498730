import { useState, useEffect, useCallback } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { Currency } from "../interfaces/data";

export interface UseNbpRates {
  from?: Currency;
  to?: Currency;
}

export interface RateInfo {
  rate: number;
  date: string;
}

const COLLECTION = "rates";

export const useNbpRate = ({ from, to }: UseNbpRates) => {
  const [rateInfo, setRateInfo] = useState<RateInfo>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  const { db } = useFirebase();

  const fetch = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      collection
        .orderBy("effectiveDate", "desc")
        .limit(1)
        .get()
        .then((snap: firestore.DocumentData) => {
          const npbRates = snap.docs[0].data();

          if (npbRates && from && to) {
            npbRates.rates.PLN = 1;
            const nbpRate = {
              rate: Number(npbRates.rates[from]) / Number(npbRates.rates[to]),
              date: npbRates.rates.effectiveDate,
            };
            setRateInfo(nbpRate);
          } else {
            setRateInfo(undefined);
          }
        })
        .catch((e) => {
          console.error("useNbpRates", e);
          setErrors(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [from, to]
  );

  useEffect(
    () => {
      fetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [from, to]
  );

  return { rateInfo, loading, errors, refetch: fetch };
};
