import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const RemoveIcon = createIcon({
  displayName: "RemoveIcon",
  path: (
    <g fill="none">
      <path
        d="M1.76953 5V11.3994C1.76953 11.8237 1.93162 12.2307 2.22014 12.5307C2.50865 12.8307 2.89997 12.9993 3.30799 12.9993H8.69261C9.10063 12.9993 9.49195 12.8307 9.78046 12.5307C10.069 12.2307 10.2311 11.8237 10.2311 11.3994V5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 3.39844H11" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.07812 3.39979V1H7.92428V3.39979" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.07812 10.5993L7.92428 6.59961" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.92428 10.5993L4.07812 6.59961" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: "0 0 12 14",
});
