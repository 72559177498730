import React, { useMemo } from "react";
import { Company, Transaction } from "interfaces/data";
import { Decimal } from "decimal.js";
import { DEAL_TYPE_SELL, determineTransactionCurrency, getQuantityLeft } from "helpers/transaction";
import { useTranslation } from "react-i18next";
import {
  StyledStatisticDivider,
  StyledStatisticItem,
  StyledStatisticItemMain,
  StyledStatisticItemTitle,
  StyledStatisticsSection,
} from "./Statistics.styled";
import { formatNumberToString } from "helpers/formatNumber";
import { useCompanyScoring } from "hooks/useCompanyScoring";
import { LimitsGauge } from "./LimitsGauge";

interface StatisticsProps {
  company: Company;
  transactions: Array<Transaction>;
  nbpRates: any;
}

export const Statistics: React.FC<StatisticsProps> = ({ company, transactions, nbpRates }) => {
  const { t } = useTranslation();
  const { companyLimit, isAutoLimit } = useCompanyScoring(company);
  const companyCurrency = company.details.currency || "EUR";
  const used = useMemo(() => {
    if (!nbpRates || !transactions) return 0;
    return transactions.reduce((total, currentTransaction) => {
      if (currentTransaction.type === "Order") {
        return total;
      }

      const rate = currentTransaction.from.currency === companyCurrency ? 1 : currentTransaction.clientRate;

      const quantity =
        currentTransaction.dealType === DEAL_TYPE_SELL
          ? getQuantityLeft(currentTransaction)
          : getQuantityLeft(currentTransaction) * rate;

      const currency = determineTransactionCurrency(currentTransaction);

      const crossRate =
        currentTransaction.from.currency === companyCurrency
          ? 1
          : Number(nbpRates.rates[currency]) / Number(nbpRates.rates[companyCurrency]);

      const crossRateQuantity = quantity * crossRate;

      return total + Number(new Decimal(crossRateQuantity).toFixed(0));
    }, 0);
  }, [nbpRates, transactions, companyCurrency]);

  const availableFunds = companyLimit - used;

  const available = useMemo(() => {
    if (availableFunds <= 0) return new Decimal(0).toString();

    return new Decimal(availableFunds).dividedBy(companyLimit).times(100).toFixed(0).toString();
  }, [availableFunds, companyLimit]);

  const limitAssigned = useMemo(
    () =>
      company.details?.nolimits
        ? "No limits"
        : `${formatNumberToString(companyLimit)} ${isAutoLimit ? "EUR" : companyCurrency}`,
    [company.details?.nolimits, companyLimit, isAutoLimit, companyCurrency]
  );

  const availableFundsFormatted = useMemo(
    () =>
      company.details?.nolimits
        ? "No limits"
        : `${formatNumberToString(availableFunds)} ${isAutoLimit ? "EUR" : companyCurrency}`,
    [company.details?.nolimits, availableFunds, isAutoLimit, companyCurrency]
  );

  return (
    <StyledStatisticsSection>
      <StyledStatisticItem>
        <StyledStatisticItemTitle>{t("company:Limit assigned")}</StyledStatisticItemTitle>
        <StyledStatisticItemMain>{limitAssigned}</StyledStatisticItemMain>
      </StyledStatisticItem>
      <StyledStatisticDivider />
      <StyledStatisticItem>
        <StyledStatisticItemMain>
          <LimitsGauge available={parseInt(available)} company={company} />
        </StyledStatisticItemMain>
      </StyledStatisticItem>
      <StyledStatisticDivider />
      <StyledStatisticItem>
        <StyledStatisticItemTitle>{t("company:Available funds")}</StyledStatisticItemTitle>
        <StyledStatisticItemMain>{availableFundsFormatted}</StyledStatisticItemMain>
      </StyledStatisticItem>
    </StyledStatisticsSection>
  );
};
