import React from "react";
import { determineTransactionCurrency } from "helpers/transaction";
import { getMarginCallBalance } from "helpers/marginCall";
import { MarginCallExtended } from "../MarginCalls";
import { useNbpRates } from "hooks/useNbpRates";
import { StyledTag } from "views/MarginCalls/components/styles";

export function StatusCell(arg: any) {
  const { rates: nbpRates } = useNbpRates();
  const marginCall = arg.row.original as MarginCallExtended;

  if (!marginCall || !nbpRates) return "";

  if (marginCall.isClosed === "yes") {
    return <b>closed</b>;
  }

  const currency = determineTransactionCurrency(marginCall.transaction);
  const paid = getMarginCallBalance(marginCall, nbpRates, currency);

  if (paid === 0) {
    return <StyledTag color="sk-red">unpaid</StyledTag>;
  }
  if (paid >= marginCall.quantity) {
    return <StyledTag color="sk-light-green">ok</StyledTag>;
  }
  return <StyledTag color="sk-yellow">partially</StyledTag>;
}
