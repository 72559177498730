import React from "react";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { StyledButtonsContainer, StyledIconContainer } from "./margin.styled";
import { GlobalMarginCall } from "interfaces/data";
import { Link } from "react-router-dom";
import { MinusIcon, SmallAddIcon } from "@chakra-ui/icons";

export function ActionsGmcCell(arg: any) {
  const marginCall = arg.row.original as GlobalMarginCall;
  if (!marginCall || marginCall.isClosed === "yes") return "";
  const id = marginCall.company.id;

  return (
    <RestrictAccess area={ADD_MARGIN_CALLS}>
      <StyledButtonsContainer>
        <Link onClick={(e) => e.stopPropagation()} to={`/companies/${id}/margin-calls/${marginCall.id}/add`}>
          <StyledIconContainer>
            <SmallAddIcon boxSize="22px" />
          </StyledIconContainer>
        </Link>
        <Link onClick={(e) => e.stopPropagation()} to={`/companies/${id}/margin-calls/${marginCall.id}/withdraw`}>
          <StyledIconContainer>
            <MinusIcon />
          </StyledIconContainer>
        </Link>
      </StyledButtonsContainer>
    </RestrictAccess>
  );
}
