import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import _cloneDeep from "lodash.clonedeep";
import dayjs from "dayjs";
import { useFirebase } from "./useFirebase";
import { Company, Margin, Transaction } from "interfaces/data";
import { companySchema } from "validations/company";
import { useValidation } from "./useValidation";
import { useRelations } from "./useRelations";
import { useLogs } from "./useLogs";
import { determineTransactionCurrencyPair, getQuantityLeft } from "helpers/transaction";
import { marginSchema } from "validations/margin";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { timestampFromMap } from "helpers/timestamp";

const COLLECTION = "companies";

export interface UseCompanyOptions {
  skipFetching?: boolean;
  skipLog?: boolean;
  id?: string;
}

export const useCompany = (
  { skipFetching, id, skipLog }: UseCompanyOptions = {
    skipFetching: false,
    skipLog: false,
  }
) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { validate, errors, clearErrors } = useValidation(companySchema);
  const { validate: validateMargin, errors: marginErrors } = useValidation(marginSchema);
  const { db, timestamp, firebaseDelete } = useFirebase();
  const { log } = useLogs();

  const _generateGlobalMarginId = useCallback((): string => {
    if (!company) return "";
    if (!company.globalMargins) return "g-1";
    const maxExistingId = Math.max(...company.globalMargins.map((item) => Number(item.id?.split("-")[1])), 0);
    return `g-${maxExistingId + 1}`;
  }, [company]);

  const { attachRelations, detachRelations } = useRelations<Company>([
    {
      field: "contacts",
      collection: "contacts",
      preserve: ["type", "cc", "source", "disableEmails"],
    },
  ]);

  const fetch = useCallback(
    (id?: string) => {
      if (!id) return;

      const collection = db.collection(COLLECTION);

      return collection.doc(id).onSnapshot((snap: firestore.DocumentData) => {
        const company = snap.data() as Company;
        if (company) {
          attachRelations({ ...company, id }).then((data) => {
            setCompany(data);
            setLoading(false);
          });
        } else {
          setCompany(undefined);
          setLoading(false);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const find = useCallback(
    async (path: string, value: string) => {
      const collection = db.collection(COLLECTION);

      return collection
        .where(path, "==", value)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.docs[0].data() as Company;
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (company: Company) => {
      setLoading(true);

      const companyToSave =
        Boolean(company.other.communicationLanguage) === false
          ? { ...company, other: { ...company.other, communicationLanguage: "PL" as const } }
          : company;

      const { id, ...companyData } = companyToSave;
      const collection = db.collection(COLLECTION);
      const isValid = await validate(companyToSave);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const companyWithTimeStamp = {
        ...companyData,
        modifiedAt: timestamp(),
        createdAt: timestampFromMap(companyData.createdAt),
      };

      const companyWithRelations = await detachRelations(_cloneDeep(companyWithTimeStamp));

      if (!companyWithRelations.other.communicationLanguage) {
        companyWithRelations.other.communicationLanguage = "PL";
      }

      const oldCompany = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            const company = snap.data() as Company;
            return attachRelations({ ...company, id }).then((data) => {
              return data;
            });
          }
        });

      await collection
        .doc(id)
        .update(companyWithRelations)
        .then(async () => {
          setCompany({ ...companyWithTimeStamp, id });
          if (!skipLog) {
            await log({
              action: "edit",
              item: {
                collection: "companies",
                id: id,
                name: company.name,
              },
              company: {
                id: id,
                name: company.name,
              },
              url: `/companies/${id}`,
              oldData: JSON.stringify(oldCompany),
              newData: JSON.stringify(company),
            });
          }
          setLoading(false);
          return true;
        })
        .catch((e: Error) => {
          console.error("useCompany", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const save = useCallback(
    async (newCompany: any) => {
      setLoading(true);

      const collection = db.collection(COLLECTION);
      const isValid = await validate(newCompany);

      const companyWithRelations = await detachRelations(newCompany);

      if (isValid) {
        return collection
          .add({
            ...companyWithRelations,
            createdAt: timestamp(),
            modifiedAt: timestamp(),
          })
          .then((doc) => {
            if (!skipLog) {
              doc.get().then((snap: firestore.DocumentData) => {
                if (!snap.empty) {
                  const newCompany = snap.data() as Company;
                  log({
                    action: "create",
                    item: {
                      collection: "companies",
                      id: doc.id,
                      name: newCompany.name,
                    },
                    company: {
                      id: doc.id,
                      name: newCompany.name,
                    },
                    url: `/companies/${doc.id}`,
                    newData: JSON.stringify(newCompany),
                  });
                }
              });
            }
            setLoading(false);
            return doc.id;
          })
          .catch((e: Error) => {
            console.error("useCompany", e);
          });
      }

      setLoading(false);
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const deactivate = useCallback(
    async (id: string, companyData?: Company) => {
      const collection = db.collection(COLLECTION);

      const transactionsRef = db.collection("transactions").where("company.id", "==", id);

      const snap = await transactionsRef.get();

      // Only company without any open transaction can be deactivated. If there is any open transaction - throw an error
      if (snap) {
        const transactions = [] as Array<Transaction>;

        snap.forEach((doc: any) => {
          const transaction = doc.data();
          transactions.push({ ...transaction, id: doc.id });
        });

        transactions.forEach((transaction) => {
          if (
            Number(getQuantityLeft(transaction)) > 0 &&
            transaction.status !== "closed" &&
            transaction.status !== "rolled"
          ) {
            throw Error("Company cannot be deactivated - it has unsettled transactions.");
          }
        });
      }

      const batch = db.batch();

      // add isDeactivated flag to each existing transaction
      if (snap) {
        snap.forEach((doc) => {
          batch.update(doc.ref, {
            "company.isDeactivated": "yes",
          });
        });
      }

      batch.update(collection.doc(id), {
        isDeactivated: true,
        modifiedAt: timestamp(),
      });

      return batch
        .commit()
        .then(async () => {
          await log({
            action: "deactivate",
            item: {
              collection: "companies",
              id: id,
              name: companyData?.name,
            },
            company: {
              id: id,
              name: String(companyData?.name),
            },
            url: `/companies/${id}`,
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("useCompany", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const remove = useCallback(
    async (id: string, companyData?: Company) => {
      const collection = db.collection(COLLECTION);

      const transactionsRef = db.collection("transactions").where("company.id", "==", id);

      const snap = await transactionsRef.get();

      // Only company without any open transaction can be deleted. If there is any open transaction - throw an error
      if (snap) {
        const transactions = [] as Array<Transaction>;

        snap.forEach((doc: any) => {
          const transaction = doc.data();
          transactions.push({ ...transaction, id: doc.id });
        });

        transactions.forEach((transaction) => {
          if (
            Number(getQuantityLeft(transaction)) > 0 &&
            transaction.status !== "closed" &&
            transaction.status !== "rolled"
          ) {
            throw Error("Company cannot be deleted - it has unsettled transactions.");
          }
        });
      }

      const batch = db.batch();

      // remove all existing transactions from this company
      if (snap) {
        snap.forEach((doc) => {
          batch.delete(doc.ref);
        });
      }

      batch.delete(collection.doc(id));

      return batch
        .commit()
        .then(async () => {
          await log({
            action: "delete",
            item: {
              collection: "companies",
              id: id,
              name: companyData?.name,
            },
            oldData: JSON.stringify(companyData),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("useCompany", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCompaniesContacts = useCallback(
    async (companies: Company[]) => {
      const collection = db.collection(COLLECTION);
      const batch = db.batch();
      const detachedCompanies = await Promise.all(companies.map((c: Company) => detachRelations(_cloneDeep(c))));
      detachedCompanies.forEach((c: Company) => {
        batch.update(collection.doc(c.id), {
          modifiedAt: timestamp(),
          contacts: c.contacts,
        });
      });

      return batch
        .commit()
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Updated Contacts",
            url: `/companies/${id}`,
            newData: JSON.stringify(companies),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("useCompany", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const activate = useCallback(
    async (id: string, companyData?: Company) => {
      const collection = db.collection(COLLECTION);

      const transactionsRef = db.collection("transactions").where("company.id", "==", id);

      const snap = await transactionsRef.get();
      const batch = db.batch();

      // remove isDeactivated flag from each existing transaction
      if (snap) {
        snap.forEach((doc) => {
          batch.update(doc.ref, {
            "company.isDeactivated": firebaseDelete(),
          });
        });
      }

      batch.update(collection.doc(id), {
        isDeactivated: firebaseDelete(),
        modifiedAt: timestamp(),
      });

      return batch
        .commit()
        .then(async () => {
          await log({
            action: "activate",
            item: {
              collection: "companies",
              id: id,
              name: companyData?.name,
            },
            company: {
              id: id,
              name: String(companyData?.name),
            },
            url: `/companies/${id}`,
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("useCompany", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const globalMarginAdd = useCallback(
    async (margin: Margin) => {
      const newMargin = _cloneDeep(margin);
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(newMargin);
      if (!company || !isValid) return false;

      const globalMargins = company.globalMargins || [];
      newMargin.id = _generateGlobalMarginId();
      newMargin.createdAt = timestamp();
      newMargin.modifiedAt = timestamp();
      newMargin.left = newMargin.from;

      globalMargins.push(newMargin);

      await collection
        .doc(id)
        .update({
          globalMargins,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: `Added Global ${margin.type}`,
            item: {
              collection: "companies",
              id: newMargin.id,
              name: company?.name,
            },
            company: {
              id: newMargin.id,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(newMargin),
          });
        })
        .catch((e: Error) => {
          console.error("globalMarginAdd", e);
          return false;
        });
      return newMargin;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const addPaymentEntry = useCallback(
    async (amount: number, currency: string, marginId: string, date: Timestamp) => {
      const collection = db.collection(COLLECTION);

      if (!company) return false;

      const paymentEntries = company.paymentEntries || [];

      const newPayment = { amount, currency, marginId, date };
      paymentEntries.push(newPayment);

      return collection
        .doc(id)
        .update({
          paymentEntries,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: `Added Payment ${amount}${currency}`,
            item: {
              collection: "companies",
              id: marginId,
              name: company?.name,
            },
            company: {
              id: marginId,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(newPayment),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("addPaymentEntry", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const globalMarginUpdate = useCallback(
    async (margin: Margin, isWithdraw?: boolean) => {
      const newMargin = _cloneDeep(margin);
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(newMargin);
      if (!company || !isValid) return false;

      const isPartOfGMC = newMargin.id.includes("GMC");

      newMargin.modifiedAt = timestamp();

      const globalMargins = company.globalMargins || [];
      const globalMarginCalls = company.globalMarginCalls || [];

      const editedMarginIndex = globalMargins.findIndex((margin) => margin.id === newMargin.id);

      if (editedMarginIndex !== -1) {
        const oldMargin = globalMargins[editedMarginIndex];

        if (!isWithdraw) {
          // if not withdraw action, check if margin can be edited
          if (
            (oldMargin.operations && oldMargin.operations?.length > 0) ||
            Number(oldMargin.left?.quantity) !== Number(oldMargin.from.quantity)
          ) {
            throw Error("This margin cannot be edited, because it has been used.");
          }

          const maxExistingId = Math.max(...globalMargins.map((item) => Number(item.id?.split("-")[1])), 0);
          if (newMargin.id !== `g-${maxExistingId}`) {
            throw Error("Only most recent margin can be edited.");
          }
        }

        globalMargins[editedMarginIndex] = newMargin;

        if (isPartOfGMC) {
          // we need to update margins collection on GMC
          const gmc = globalMarginCalls.find((gmc) => newMargin.marginCallId === gmc.id);
          if (gmc) {
            const margin = gmc.margins?.find((margin) => margin.marginId === newMargin.id);
            if (margin) {
              margin.quantity = newMargin.left.quantity;
            }
          }
        }
      } else {
        globalMargins.push(newMargin);

        if (isPartOfGMC) {
          const gmc = globalMarginCalls.find((gmc) => newMargin.marginCallId === gmc.id);
          if (gmc) {
            gmc.margins?.push({
              marginId: newMargin.id,
              isGlobal: true,
              currency: newMargin.from.currency,
              quantity: newMargin.from.quantity,
            });
          }
        }
      }

      return collection
        .doc(id)
        .update({
          globalMargins,
          globalMarginCalls,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Updated Global Margin",
            item: {
              collection: "companies",
              id: newMargin.id,
              name: company?.name,
            },
            company: {
              id: newMargin.id,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(newMargin),
          });

          return true;
        })
        .catch((e: Error) => {
          console.error("globalMarginUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const globalMarginUpdateUnsafe = useCallback(
    async (margin: Margin) => {
      const newMargin = _cloneDeep(margin);
      const collection = db.collection(COLLECTION);

      const isValid = await validateMargin(newMargin);
      if (!company || !isValid) return false;

      newMargin.modifiedAt = timestamp();

      const globalMargins = company.globalMargins || [];

      const editedMarginIndex = globalMargins.findIndex((margin) => margin.id === newMargin.id);

      if (editedMarginIndex !== -1) {
        globalMargins[editedMarginIndex] = newMargin;
      } else {
        globalMargins.push(newMargin);
      }

      return collection
        .doc(id)
        .update({
          globalMargins,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Updated Global Margin",
            item: {
              collection: "companies",
              id: newMargin.id,
              name: company?.name,
            },
            company: {
              id: newMargin.id,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(newMargin),
          });

          return true;
        })
        .catch((e: Error) => {
          console.error("globalMarginUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const globalMarginCommentUpdate = useCallback(
    async (marginId: string, newComment: string) => {
      const collection = db.collection(COLLECTION);

      if (!company) return false;

      const globalMargins = company.globalMargins || [];

      const editedMarginIndex = globalMargins.findIndex((margin) => margin.id === marginId);

      if (editedMarginIndex !== -1) {
        globalMargins[editedMarginIndex].comment = newComment;
      }

      return collection
        .doc(id)
        .update({
          globalMargins,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Updated Global Margin Comment",
            item: {
              collection: "companies",
              id: marginId,
              name: company?.name,
            },
            company: {
              id: marginId,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(newComment),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("globalMarginCommentUpdate", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const globalMarginCancel = useCallback(
    async (marginId: string) => {
      const collection = db.collection(COLLECTION);

      if (!company) return false;

      const globalMargins = company.globalMargins || [];

      const editedMarginIndex = globalMargins.findIndex((margin) => margin.id === marginId);

      if (editedMarginIndex !== -1) {
        const oldMargin = globalMargins[editedMarginIndex];
        if (
          (oldMargin.operations && oldMargin.operations?.length > 0) ||
          Number(oldMargin.left?.quantity) !== Number(oldMargin.from.quantity)
        ) {
          throw Error("This margin cannot be canceled, because it has been used.");
        }

        const maxExistingId = Math.max(...globalMargins.map((item) => Number(item.id?.split("-")[1])), 0);
        if (marginId !== `g-${maxExistingId}`) {
          throw Error("Only most recent margin can be canceled.");
        }
        globalMargins.splice(editedMarginIndex, 1);
      }

      return collection
        .doc(id)
        .update({
          globalMargins,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Canceled Global Margin",
            item: {
              collection: "companies",
              id: marginId,
              name: company?.name,
            },
            company: {
              id: marginId,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(globalMargins),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("globalMarginCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const globalMarginCancelUnsafe = useCallback(
    async (marginId: string) => {
      const collection = db.collection(COLLECTION);

      if (!company) return false;

      const globalMargins = company.globalMargins || [];

      const editedMarginIndex = globalMargins.findIndex((margin) => margin.id === marginId);

      if (editedMarginIndex !== -1) {
        globalMargins.splice(editedMarginIndex, 1);
      }

      return collection
        .doc(id)
        .update({
          globalMargins,
        })
        .then(async () => {
          await log({
            action: "edit",
            actionDetails: "Canceled Global Margin",
            item: {
              collection: "companies",
              id: marginId,
              name: company?.name,
            },
            company: {
              id: marginId,
              name: String(company?.name),
            },
            url: `/companies/${id}`,
            newData: JSON.stringify(globalMargins),
          });
          return true;
        })
        .catch((e: Error) => {
          console.error("globalMarginCancel", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const isReversePaymentValid = useCallback(
    async (quantity: number, currency: string) => {
      if (!company || !company.reversePayment?.limit) return false;
      if (company.reversePayment.date) {
        const limitDate = dayjs(getMilisecondsFromTimestamp(company.reversePayment.date));
        if (!limitDate.isSameOrAfter(dayjs(), "day")) return false;
      }

      // get NBP rates
      const nbpRates = await db
        .collection("rates")
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .then((snap: firestore.DocumentData) => {
          const npbRates = snap.docs[0].data();
          npbRates.rates.PLN = 1;
          return npbRates;
        });

      // get all company's transactions
      const transactionsSnapshot = await db.collection("transactions").where("company.id", "==", company.id).get();

      if (transactionsSnapshot.empty) return true;

      const transactions = [] as Array<Transaction>;
      transactionsSnapshot.forEach((doc: any) => {
        transactions.push({ id: doc.id, ...doc.data() });
      });

      // get info about all the unsettled reverse payments for the company
      const usedLimitArray = [] as Array<{
        currency: string;
        value: number;
      }>;
      transactions.forEach((transaction) => {
        const [, currency] = determineTransactionCurrencyPair(transaction);
        transaction.settlements?.forEach((settlement) => {
          if (settlement.isReversePayment && !settlement.isReversePaymentOk) {
            usedLimitArray.push({
              currency,
              value: Number(settlement.quantity),
            });
          }
        });
      });

      // calculate currently used reverse payment limit
      const usedLimitInPln = usedLimitArray.reduce(
        (sum, val) => sum + val.value * Number(nbpRates.rates[val.currency]),
        0
      );

      const newPaymentValueInPln = Number(quantity) * Number(nbpRates.rates[currency]);

      const companyLimitInPln =
        Number(company.reversePayment.limit.quantity) * Number(nbpRates.rates[company.reversePayment.limit.currency]);

      return companyLimitInPln >= usedLimitInPln + newPaymentValueInPln;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  const getReversePaymentLimitLeft = useCallback(
    async (currency: string) => {
      if (!company || !company.reversePayment?.limit) return 0;
      if (company.reversePayment.date) {
        const limitDate = dayjs(getMilisecondsFromTimestamp(company.reversePayment.date));
        if (!limitDate.isSameOrAfter(dayjs(), "day")) return 0;
      }

      // get NBP rates
      const nbpRates = await db
        .collection("rates")
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .then((snap: firestore.DocumentData) => {
          const npbRates = snap.docs[0].data();
          npbRates.rates.PLN = 1;
          return npbRates;
        });

      // get all company's transactions
      const transactionsSnapshot = await db.collection("transactions").where("company.id", "==", company.id).get();

      const transactions = [] as Array<Transaction>;
      transactionsSnapshot.forEach((doc: any) => {
        transactions.push({ id: doc.id, ...doc.data() });
      });

      // get info about all the unsettled reverse payments for the company
      const usedLimitArray = [] as Array<{
        currency: string;
        value: number;
      }>;
      transactions.forEach((transaction) => {
        const [, currency] = determineTransactionCurrencyPair(transaction);
        transaction.settlements?.forEach((settlement) => {
          if (settlement.isReversePayment && !settlement.isReversePaymentOk) {
            usedLimitArray.push({
              currency,
              value: Number(settlement.quantity),
            });
          }
        });
      });

      // calculate currently used reverse payment limit
      const usedLimitInPln = usedLimitArray.reduce(
        (sum, val) => sum + val.value * Number(nbpRates.rates[val.currency]),
        0
      );

      const companyLimitInPln =
        Number(company.reversePayment.limit.quantity) * Number(nbpRates.rates[company.reversePayment.limit.currency]);

      const limitLeftInPln = companyLimitInPln - usedLimitInPln;

      return limitLeftInPln / Number(nbpRates.rates[currency]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company]
  );

  useEffect(() => {
    if (!skipFetching) {
      fetch(id);
    }
  }, [fetch, id, skipFetching]);

  return {
    company,
    setCompany,
    updateCompaniesContacts,
    loading,
    update,
    save,
    deactivate,
    activate,
    remove,
    errors,
    find,
    clearErrors,
    globalMarginAdd,
    globalMarginUpdate,
    globalMarginUpdateUnsafe,
    globalMarginCancel,
    globalMarginCancelUnsafe,
    globalMarginCommentUpdate,
    isReversePaymentValid,
    getReversePaymentLimitLeft,
    marginErrors,
    addPaymentEntry,
  };
};
