import { MarginCallSchema } from "schemas/marginCall";

export const fixMarginCall = (marginCall: any) => {
  const result = MarginCallSchema.safeParse(marginCall);
  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return marginCall;
  }

  const fixedMarginCall = {
    ...marginCall,
    id: `${marginCall.id}`,
    callRate: Number(marginCall.callRate),
    transQuantity: Number(marginCall.transQuantity),
    quantity: Number(marginCall.quantity),
  };

  if ("createdFromId" in fixedMarginCall) {
    fixedMarginCall.createdFromId = `${marginCall.createdFromId}`;
  }
  if ("clientRate" in fixedMarginCall) {
    fixedMarginCall.clientRate = Number(fixedMarginCall.clientRate);
  }
  if ("totalPaidMargin" in fixedMarginCall) {
    fixedMarginCall.totalPaidMargin = {
      quantity: Number(fixedMarginCall.totalPaidMargin.quantity),
      currency: `${fixedMarginCall.totalPaidMargin.currency}`,
    };
  }
  if ("globalTransactionsIds" in fixedMarginCall) {
    fixedMarginCall.globalTransactionsIds = fixedMarginCall.globalTransactionsIds.map((id: any) => ({
      id: `${id.id}`,
      number: Number(id.number),
    }));
  }
  if ("isPaid" in fixedMarginCall) {
    fixedMarginCall.isPaid = Boolean(fixedMarginCall.isPaid);
  }
  if ("comment" in fixedMarginCall) {
    fixedMarginCall.comment = `${fixedMarginCall.comment}`;
  }
  if ("tableComment" in fixedMarginCall) {
    fixedMarginCall.tableComment = `${fixedMarginCall.tableComment}`;
  }
  if ("margins" in fixedMarginCall) {
    fixedMarginCall.margins = fixedMarginCall.margins.map((margin: any) => ({
      marginId: `${margin.marginId}`,
      isGlobal: Boolean(margin.isGlobal),
      quantity: Number(margin.quantity),
      currency: `${margin.currency}`,
    }));
  }

  return fixedMarginCall;
};
