import { Box } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "components/Modal/Modal";
import { SETTLEMENT_MAIL } from "helpers/mails";
import { useMail } from "hooks/useMail";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";

export const SettlementResend: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const sendConfirmation = useMail();
  const { id, settlementId } = useParams<{
    id: string;
    settlementId: string;
  }>();
  const { transaction } = useTransaction({ id });
  const [isSending, setIsSending] = useState(false);

  const isSettlementNet = useMemo(() => {
    if (!transaction || !transaction.settlements || !settlementId) return false;
    const settlement = transaction.settlements.find((settlement) => settlementId === settlement.id);
    return settlement?.type === "Net";
  }, [transaction, settlementId]);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleResend = useCallback(async () => {
    setIsSending(true);

    if (transaction?.type === "FR" && !isSettlementNet) {
      await sendConfirmation(SETTLEMENT_MAIL, String(transaction?.id), {
        settlement: settlementId,
      });
    }

    toast({
      type: "success",
      message: t("settlement:Settlement has been resent successfully."),
    });
    setIsSending(false);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose]);

  const editedSettlement = useMemo(() => {
    if (transaction) {
      return transaction.settlements?.find((settlement) => settlement.id === settlementId);
    }
  }, [settlementId, transaction]);

  if (!transaction || !editedSettlement) return null;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleResend}
      title={t(`settlement:Resend settlement`)}
    >
      <Box mb={"20px"}>
        {t(`settlement:Are you sure you want to resend settlement {{settlementId}}?`, { settlementId })}
      </Box>
    </Modal>
  );
};
