import React, { useCallback, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useCompany } from "hooks/useCompany";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { formatDate } from "helpers/date";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import styled from "@emotion/styled";

const StyledList = styled.dl`
  display: grid;
  grid-template-columns: 100px 1fr 100px 1fr;
  gap: 0.5rem;
  grid-column-gap: 1rem;
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
`;

const StyledLabel = styled.dt`
  text-align: right;
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  padding-bottom: 0.5rem;
`;

const StyledValue = styled.dd`
  color: ${(props) => props.theme.colors["black"]};
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  padding-bottom: 0.5rem;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-blue"]};
  margin-right: 0.25rem;
  display: inline-block;
  &:after {
    content: ", ";
  }
`;

const StyledUnorderedList = styled.ul`
  list-style: none;
`;

export function GlobalMarginCallView() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId?: string;
  }>();
  const { company } = useCompany({ id });
  const marginCall = useMemo(() => {
    if (!company) {
      return;
    }
    return company.globalMarginCalls?.find((marginCall) => marginCall.id === marginCallId);
  }, [marginCallId, company]);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!marginCall) return null;

  return (
    <Modal
      cancelText={t("Common:Close")}
      confirmText={t("marginCall:Final Notice")}
      design="primary"
      isDisabled={Boolean(marginCall.finalNoticeSentAt)}
      isOpen
      justifyButtons="flex-end"
      minWidth={700}
      onClose={handleClose}
      onConfirm={() => {
        history.push(`/companies/${id}/gmc-final-call/${marginCall.id}`);
      }}
      title={`${t("marginCall:Global Margin Call")} - ${marginCall.id}`}
    >
      <StyledList>
        <StyledLabel>{t("marginCall:Transactions")}</StyledLabel>
        <StyledValue>
          {marginCall.globalTransactionsIds?.map((t) => (
            <>
              <StyledLink key={t.id} to={`/transactions/${t.id}`}>
                {t.number}
              </StyledLink>
            </>
          ))}
        </StyledValue>

        <StyledLabel>{t("marginCall:ID")}</StyledLabel>
        <StyledValue>{marginCall.id}</StyledValue>

        <StyledLabel>{t("marginCall:Quantity")}</StyledLabel>
        <StyledValue>
          {formatNumberToString(marginCall.quantity)} {marginCall.currency}
        </StyledValue>

        <StyledLabel>{t("marginCall:Call Rate")}</StyledLabel>
        <StyledValue>{formatRate(marginCall.callRate, 4)}</StyledValue>

        <StyledLabel>{t("marginCall:Client Rate")}</StyledLabel>
        <StyledValue>{formatRate(Number(marginCall.clientRate), 4)}</StyledValue>

        <StyledLabel>{t("marginCall:Margin Call Date")}</StyledLabel>
        <StyledValue>{formatDate(marginCall.callDate)}</StyledValue>

        <StyledLabel>{t("marginCall:Final Notice Sent?")}</StyledLabel>
        <StyledValue>{formatDate(marginCall.finalNoticeSentAt) || "No"}</StyledValue>

        <StyledLabel>{t("marginCall:Paid?")}</StyledLabel>
        <StyledValue>{marginCall.isPaid ? t("Common:Yes") : t("Common:No")}</StyledValue>

        <StyledLabel>{t("marginCall:Deal Type")}</StyledLabel>
        <StyledValue>{marginCall.dealType}</StyledValue>

        <StyledLabel>{t("marginCall:Transactions Quantity")}</StyledLabel>
        <StyledValue>
          {formatNumberToString(marginCall.transQuantity)} {marginCall.currency}
        </StyledValue>

        {marginCall.totalPaidMargin && (
          <>
            <StyledLabel>{t("marginCall:Total Paid")}</StyledLabel>
            <StyledValue>
              {formatNumberToString(marginCall.totalPaidMargin.quantity)} {marginCall.totalPaidMargin?.currency}
            </StyledValue>
          </>
        )}

        {marginCall.createdFromId && (
          <>
            <StyledLabel>{t("marginCall:Created From")}</StyledLabel>
            <StyledValue>{marginCall.createdFromId}</StyledValue>
          </>
        )}

        {marginCall.comment && (
          <>
            <StyledLabel>{t("marginCall:Comment")}</StyledLabel>
            <StyledValue>{marginCall.comment}</StyledValue>
          </>
        )}

        {marginCall.margins?.length && (
          <>
            <StyledLabel>{t("marginCall:Margins")}</StyledLabel>
            <StyledValue>
              <StyledUnorderedList>
                {marginCall.margins?.map((margin) => (
                  <li key={margin.marginId}>
                    {margin.marginId} - {formatNumberToString(margin.quantity)} {margin.currency}
                  </li>
                ))}
              </StyledUnorderedList>
            </StyledValue>
          </>
        )}
      </StyledList>
    </Modal>
  );
}
