import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "../../hooks/useTransaction";
import { useToast } from "../../hooks/useToast";
import { Modal } from "../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";

export function HedgeCancel() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, hedgeId } = useParams<{ id: string; hedgeId: string }>();
  const { transaction, hedgeCancel } = useTransaction({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelAction = useCallback(() => {
    setIsSending(true);
    hedgeCancel(hedgeId)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("hedge:Hedge has been canceled successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`hedge:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hedgeCancel, hedgeId, history, id]);

  const editedHedege = useMemo(() => {
    if (transaction) {
      return transaction.hedges?.find((hedge) => hedge.id === hedgeId);
    }
  }, [hedgeId, transaction]);

  useEffect(() => {
    if (!transaction || !transaction.hedges || !editedHedege) return;

    const maxHedegeId = Math.max(...transaction.hedges.map((item) => Number(item.id?.split("-")[1])), 0);

    // Only most recent Hedge can be canceled
    if (maxHedegeId !== Number(editedHedege.id.split("-")[1])) {
      handleClose();
      toast({
        type: "error",
        message: t("hedge:Only most recent Hedge can be canceled."),
      });
      return;
    }

    // Hedge can be canceled only no hedges are used
    if (Number(editedHedege.quantity) !== Number(editedHedege.leftQuantity)) {
      handleClose();
      toast({
        type: "error",
        message: t("hedge:This Hedge cannot be canceled, because it is used in settlement. Remove it first."),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, editedHedege]);

  if (!transaction || !editedHedege) {
    return null;
  }

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={t(`hedge:Cancel hedge {{hedgeId}}`, { hedgeId })}
    >
      <Box mb={"20px"}>
        {t(`hedge:This action can't be undone. Are you sure you want to cancel hedge {{hedgeId}}?`, { hedgeId })}
      </Box>
    </Modal>
  );
}
