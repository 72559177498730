import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Button } from "components/Button/Button";
import { BaseContact } from "interfaces/data";
import { Box } from "components/Box/Box";
import { ContactAddContainer, Section, SectionTitle, StyledBackButton } from "./ContactAdd.styled";
import { useContact } from "hooks/useContact";
import { Heading } from "components/Heading/Heading";
import { EditContactInfo } from "../Contact/ContactInfo/EditContactInfo";
import { EditContactAddress } from "../Contact/ContactAddress/EditContactAddress";
import { EditIdDetails } from "../Contact/IdDetails/EditIdDetails";
import { EditStatus } from "../Contact/Status/EditStatus";
import { StyledThreeColumnsContainer } from "components/ItemsListElements/ItemsListElements";
import { useToast } from "hooks/useToast";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Footer } from "components/Footer/Footer";
import { ArrowLeftIcon } from "theme/icons";

const emptyContact: BaseContact = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  status: "Active",
  ip: "",
  idDetails: {
    pesel: "",
    idType: "",
    number: "",
    expirationDate: "",
  },
  address: {
    street: "",
    number: "",
    code: "",
    city: "",
    country: "Poland",
  },
  type: "sales",
  companyAssociated: {
    id: "",
    name: "",
  },
};

export const ContactAdd: React.FunctionComponent = () => {
  const [newContact, setNewContact] = useState<BaseContact>(() => _cloneDeep(emptyContact));
  const history = useHistory();
  const { t } = useTranslation();
  const { save, contact, loading, errors } = useContact({ skipFetching: true });
  const toast = useToast();

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    setNewContact((oldEditedData: any) => {
      const newEditedData = { ...oldEditedData };

      _set(newEditedData, e.target.name, e.target.value);

      return newEditedData;
    });
  }, []);

  const onCancelAction = useCallback(() => {
    history.push("/contacts");
  }, [history]);

  const onSaveAction = useCallback(() => {
    save(newContact).then((isSaved) => {
      if (isSaved) {
        history.push("/contacts");
        toast({
          type: "success",
          message: t("contact:Contact has been added successfully."),
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact, save]);

  useEffect(() => {
    if (!loading && contact && !errors) {
      onCancelAction();
    }
  }, [errors, contact, loading, onCancelAction]);

  const headingText = useMemo(() => {
    if (newContact.firstName && newContact.firstName) {
      return `${newContact.firstName} ${newContact.lastName}`;
    }

    return t("contact:New contact");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newContact.firstName, newContact.lastName]);

  return (
    <ContactAddContainer>
      <PageHeader>
        <Heading type="h1">{headingText}</Heading>
        <StyledBackButton onClick={() => history.push("/contacts")}>
          <ArrowLeftIcon mr="10px" />
          {t("contact:Contacts")}
        </StyledBackButton>
      </PageHeader>
      <StyledThreeColumnsContainer>
        <div>
          <Box bg="white" mb="7" p="7">
            <Section>
              <SectionTitle>{t("contact:Contact info")}</SectionTitle>

              <EditContactInfo data={newContact} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>

          <Box bg="white" p="7">
            <Section>
              <SectionTitle>{t("contact:Status")}</SectionTitle>

              <EditStatus data={newContact} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>
        </div>

        <div>
          <Box bg="white" p="7">
            <Section>
              <SectionTitle>{t("contact:Address")}</SectionTitle>

              <EditContactAddress data={newContact} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>
        </div>

        <div>
          <Box bg="white" mb="7" p="7">
            <Section>
              <SectionTitle>{t("contact:ID details")}</SectionTitle>

              <EditIdDetails data={newContact} errors={errors} handleChange={handleUpdate} />
            </Section>
          </Box>
        </div>
      </StyledThreeColumnsContainer>

      <Footer>
        <Button design="secondary" ml="auto" mr="20px" onClick={onCancelAction}>
          {t("Cancel")}
        </Button>
        <Button design="primary" onClick={onSaveAction}>
          {t("Save")}
        </Button>
      </Footer>
    </ContactAddContainer>
  );
};
