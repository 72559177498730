import React from "react";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { Heading } from "../../components/Heading/Heading";
import { Companies } from "./Companies";
import { Questions } from "./Questions";
import { GlobalLimit } from "./GlobalLimit";
import { useTranslation } from "react-i18next";

const Scoring = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("scoring:Scoring")}</Heading>
      </PageHeader>
      <GlobalLimit />
      <Questions />
      <Companies />
    </>
  );
};

export default Scoring;
