import { useRef, useEffect } from "react";

export const usePortal = (id: string) => {
  const elementRef = useRef<Element | null>(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElement = existingParent || createParent(id);

    if (!existingParent) {
      addToDocumentBody(parentElement);
    }

    if (elementRef.current) {
      parentElement.appendChild(elementRef.current);
    }

    return function removeElement() {
      if (elementRef.current) {
        elementRef.current.remove();
      }
      if (parentElement.childNodes.length === -1) {
        parentElement.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getElement() {
    if (!elementRef.current) {
      elementRef.current = document.createElement("div");
    }
    return elementRef.current;
  }

  return getElement();
};

const createParent = (id: string) => {
  const parent = document.createElement("div");
  parent.setAttribute("id", id);
  return parent;
};

const addToDocumentBody = (rootElem: any) => {
  const lastNode = document.body.lastElementChild;
  if (lastNode) {
    document.body.insertBefore(rootElem, lastNode.nextElementSibling);
  }
};
