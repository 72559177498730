import React from "react";
import { Button } from "components/Button/Button";
import { useHistory } from "react-router-dom";
import { GlobalMarginCall } from "interfaces/data";

export function CloseGmcCell(arg: any) {
  const marginCall = arg.row.original as GlobalMarginCall;
  const history = useHistory();

  // show button only for GMC's that are not already closed
  // and does not have any margins
  if ((marginCall.margins && marginCall.margins.length > 0) || marginCall.isClosed === "yes") return "";

  const id = marginCall.company.id;

  return (
    <Button
      design="link"
      fontSize="11px"
      height="25px"
      letterSpacing="0"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        history.push(`/companies/${id}/margin-calls/${marginCall.id}/close`);
      }}
      padding="7px 9px"
      style={{ border: "1px solid black" }}
    >
      close
    </Button>
  );
}
