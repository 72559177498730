import {
  AvaragesByCurrencyProps,
  Margin,
  NbpRateRecord,
  Payment,
  Transaction,
  WeightedAverages,
} from "../interfaces/data";
import { VariationMarginInfo } from "../views/Company360/Main/VariationMargins";
import { formatNumberToString } from "./formatNumber";
import { getWithdrawValue } from "./margin";
import { CURRENCIES } from "./options";
import { getQuantityLeft, getWeightedAverageFromObject } from "./transaction";

const generateCurrencyKeyObject = () => {
  return CURRENCIES.map((currency) => currency.value).reduce(
    // eslint-disable-next-line no-sequences
    (acc, curr) => ((acc[curr] = [] as Array<VariationMarginInfo>), acc),
    {} as any
  );
};

// const generateCurrencyKeyObjectFromTransactions = (transactions: Array<Transaction>) => {
//   const transactionsCurrencies = Array.from(
//     new Set(transactions.map((transaction) => transaction.from.currency)),
//   );
//   return transactionsCurrencies.reduce(
//     // eslint-disable-next-line no-sequences
//     (acc, curr) => ((acc[curr] = [] as Array<VariationMarginInfo>), acc),
//     {} as any,
//   );
// }

export const generateCurrenciesPairsKeyObjectFromTransactions = (transactions: Array<Transaction>) => {
  const transactionsPairCurrencies = Array.from(
    new Set(transactions.map((transaction) => `${transaction.from.currency}/${transaction.to.currency}`))
  );
  return transactionsPairCurrencies.reduce(
    // eslint-disable-next-line no-sequences
    (acc, curr) => ((acc[curr] = [] as Array<VariationMarginInfo>), acc),
    {} as any
  );
};

function convertLocalVMDataToTableRows(marginsDataByCurrency: any, nbpRates: NbpRateRecord) {
  const rowsGroupedByCurrencies = Object.values(marginsDataByCurrency).filter((x: any) => x.length !== 0) as Array<
    Array<VariationMarginInfo>
  >;

  const result = [] as Array<VariationMarginInfo>;
  const subtotals = [] as Array<VariationMarginInfo>;

  rowsGroupedByCurrencies.forEach((rowsGroup) => {
    const { currency } = rowsGroup[0];

    const totalVM = rowsGroup.reduce((sum: number, margin: VariationMarginInfo) => margin.quantity + sum, 0);

    const remainingQty = rowsGroup.reduce(
      (sum: number, margin: VariationMarginInfo) => Number(margin.quantity - (margin.withdrawn || 0)) + sum,
      0
    );

    const total = {
      id: "Currency subtotal",
      remainingQty,
      quantity: totalVM || 0,
      transactionId: "-",
      currency,
    };

    result.push(...rowsGroup);
    result.push(total);
    subtotals.push(total);
  });

  result.push({
    id: "Total in PLN",
    transactionId: "-",
    quantity: subtotals.reduce(
      (sum: number, margin: VariationMarginInfo) => margin.quantity * nbpRates.rates[margin.currency] + sum,
      0
    ),
    remainingQty: subtotals.reduce(
      (sum: number, margin: VariationMarginInfo) => margin.remainingQty * nbpRates.rates[margin.currency] + sum,
      0
    ),
    currency: "PLN",
  });

  return result;
}

function convertDataToTableRows(marginsDataByCurrency: any, nbpRates: NbpRateRecord) {
  const rowsGroupedByCurrencies = Object.values(marginsDataByCurrency).filter((x: any) => x.length !== 0) as Array<
    Array<VariationMarginInfo>
  >;

  const result = [] as Array<VariationMarginInfo>;
  const subtotals = [] as Array<VariationMarginInfo>;

  rowsGroupedByCurrencies.forEach((rowsGroup) => {
    const { currency } = rowsGroup[0];

    const totalQuantity = rowsGroup.reduce((sum: number, margin: VariationMarginInfo) => margin.quantity + sum, 0);
    const totalRemainingQty = rowsGroup.reduce(
      (sum: number, margin: VariationMarginInfo) => (margin.remainingQty || 0) + sum,
      0
    );

    const total = {
      id: "Currency subtotal",
      quantity: totalQuantity,
      remainingQty: totalRemainingQty,
      transactionId: "-",
      currency,
    };

    result.push(...rowsGroup);
    result.push(total);
    subtotals.push(total);
  });

  result.push({
    id: "Total in PLN",
    transactionId: "-",
    quantity: subtotals.reduce(
      (sum: number, margin: VariationMarginInfo) => margin.quantity * nbpRates.rates[margin.currency] + sum,
      0
    ),
    remainingQty: subtotals.reduce(
      (sum: number, margin: VariationMarginInfo) => margin.remainingQty * nbpRates.rates[margin.currency] + sum,
      0
    ),
    currency: "PLN",
  });

  return result;
}

export function convertGlobalMarginsToTableRows(
  margins: Array<Margin>,
  nbpRates: NbpRateRecord
): Array<VariationMarginInfo> {
  const marginsDataByCurrency = generateCurrencyKeyObject();

  margins.forEach((margin) => {
    const newItem = {
      id: margin.id,
      transactionId: "-",
      date: margin.date,
      quantity: Number(margin.from.quantity),
      remainingQty: Number(margin.left?.quantity),
      withdrawn: getWithdrawValue(margin),
      assignedToMc: Number(margin.from.quantity) - Number(margin.left?.quantity) - getWithdrawValue(margin),
      currency: margin.from.currency,
      comment: margin.comment,
    } as VariationMarginInfo;

    if (margin.marginCallId) {
      newItem.marginCallId = margin.marginCallId;
    }
    marginsDataByCurrency[margin.from.currency].push(newItem);
  });

  return convertDataToTableRows(marginsDataByCurrency, nbpRates);
}

export function convertLocalMarginsToTableRows(
  transactions: Array<Transaction>,
  nbpRates: NbpRateRecord,
  marginType: string,
  showWithdrawn?: boolean
): Array<VariationMarginInfo> {
  const marginsDataByCurrency = generateCurrencyKeyObject();

  transactions.forEach((transaction) => {
    if (transaction.status === "rolled") return;
    transaction.margins?.forEach((margin) => {
      if (margin.type === marginType) {
        const { currency } = margin.from;

        const newItem = {
          id: margin.id,
          transactionId: transaction.id,
          date: margin.date,
          quantity: Number(margin.from.quantity),
          withdrawn: getWithdrawValue(margin),
          assignedToMc: Number(margin.from.quantity) - Number(margin.left?.quantity) - getWithdrawValue(margin),
          currency,
        } as VariationMarginInfo;

        if (margin.marginCallId) {
          newItem.marginCallId = margin.marginCallId;
        }

        if (
          (showWithdrawn && getWithdrawValue(margin) === Number(margin.from.quantity)) ||
          (!showWithdrawn && getWithdrawValue(margin) !== Number(margin.from.quantity))
        ) {
          marginsDataByCurrency[currency].push(newItem);
        }
      }
    });
  });

  return marginType === "VM"
    ? convertLocalVMDataToTableRows(marginsDataByCurrency, nbpRates)
    : convertDataToTableRows(marginsDataByCurrency, nbpRates);
}

export function calculateTotalSum(
  globalMarginsBalance: number,
  localMarginsBalance: number,
  initialMarginsBalance: number,
  paperLoss: number
) {
  const sum = localMarginsBalance + globalMarginsBalance + initialMarginsBalance + paperLoss;

  return {
    id: "VM & GM in PLN / Paper Loss:",
    quantity: `${formatNumberToString(localMarginsBalance + globalMarginsBalance)} PLN`,
    paperLoss: `${formatNumberToString(paperLoss)} PLN`,
    return: `${formatNumberToString(sum < 0 ? 0 : sum)} PLN`,
  };
}

export const calculateTotalSumByCurrency = (
  globalVariationMarginsRows: VariationMarginInfo[],
  variationMarginsRows: VariationMarginInfo[]
) => {
  return [...globalVariationMarginsRows, ...variationMarginsRows].reduce(
    (acc: { [key: string]: number }, row: VariationMarginInfo) => {
      // 1. iterate over variation margins rows and find id 'Currency subtotal'
      if (row.id === "Currency subtotal") {
        // 2. sum per currency
        acc[row.currency] = acc[row.currency]
          ? (acc[row.currency] += row.remainingQty)
          : (acc[row.currency] = row.remainingQty);
      }
      return acc;
    },
    {}
  );
};

export const calculateMarginCallsSum = (globalMarginsBalance: number, localMarginsBalance: number) =>
  localMarginsBalance + globalMarginsBalance;

export const calculateQuantitiesAverages = (transactions: Array<Transaction>) => {
  if (!transactions.length) return null;
  const averagesByCurrencyPair = generateCurrenciesPairsKeyObjectFromTransactions(transactions);

  transactions.forEach((currentTransaction) => {
    const currencyFrom = currentTransaction.from.currency;
    const currencyTo = currentTransaction.to.currency;

    if (currencyFrom === currencyTo) return;

    const settled = {
      weight: currentTransaction.clientRate,
      val: Number(currentTransaction.from.quantity),
    } as WeightedAverages;

    averagesByCurrencyPair[`${currencyFrom}/${currencyTo}`].push({
      settled,
    });
  });

  return Object.entries<Array<AvaragesByCurrencyProps>>(averagesByCurrencyPair).map(([currency, value]) => {
    const settled = getWeightedAverageFromObject({
      values: value.map((v) => v.settled.val),
      weights: value.map((v) => v.settled.weight),
    });

    return {
      settled,
      currency,
    };
  });
};

export const calculateQuantitiesSummary = (transactions: Array<Transaction>) => {
  if (!transactions.length) return null;

  const summaryByCurrency = generateCurrenciesPairsKeyObjectFromTransactions(transactions);

  transactions.forEach((currentTransaction) => {
    const { currency: currencyFrom } = currentTransaction.from;
    const { currency: currencyTo } = currentTransaction.to;
    const remainingQuantity = getQuantityLeft(currentTransaction);
    const settled = {
      val: Number(currentTransaction.leftQuantity) - remainingQuantity,
    } as WeightedAverages;
    const remaining = {
      val: remainingQuantity,
    } as WeightedAverages;
    summaryByCurrency[`${currencyFrom}/${currencyTo}`].push({
      settled,
      remaining,
    });
  });

  return Object.entries<Array<AvaragesByCurrencyProps>>(summaryByCurrency).map(([currency, value]) => {
    const settledSummary = value.reduce((sum, v) => sum + v.settled.val, 0);
    const remainingSummary = value.reduce((sum, v) => sum + v.remaining.val, 0);
    return {
      settled: formatNumberToString(settledSummary),
      remaining: formatNumberToString(remainingSummary),
      currency,
    };
  });
};

const totalPerCurrencies = (payments: Payment[]) =>
  payments.reduce((acc, source) => {
    const key = `${source.transaction.from.currency}/${source.transaction.to.currency}`;
    const quantity = Number(source.quantity) || 0;
    acc[key] = (acc[key] || 0) + quantity;
    return acc;
  }, {} as { [key: string]: number });

export const calculateQuantitiesTotal = (payments: Payment[]) => {
  if (!payments.length) return null;

  const totalsPerCurrencies = totalPerCurrencies(payments);
  const unsortedTotals = Object.entries(totalsPerCurrencies).map(([currency, total]) => ({ currency, total }));

  // We need to sort the totals so pairs with EUR come first then ones with USD and then all the remaining pairs
  return unsortedTotals.sort((a, b) => {
    const aUpper = a.currency.toUpperCase();
    const bUpper = b.currency.toUpperCase();
    if (aUpper.includes("EUR")) return -1;
    if (bUpper.includes("EUR")) return 1;
    if (aUpper.includes("USD")) return -1;
    if (bUpper.includes("USD")) return 1;
    return 0;
  });
};
