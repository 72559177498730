import * as yup from "yup";
import { TRANSACTION_TYPES } from "../helpers/transaction";
import { isDateSameOrAfter } from "./shared";

export const transactionSchema = yup.object().shape({
  type: yup.string().required().trim(),
  company: yup.object().shape({
    name: yup.string().required().trim(),
    id: yup.string().required().trim(),
  }),
  initialMargin: yup.string().trim(),
  additionalMargin: yup.string().trim(),
  ourRate: yup.number().positive().required(),
  clientRate: yup.number().positive().required(),
  start: yup.object(),
  systemDate: yup.string().required().trim(),
  agreement: yup.string().trim(),
  end: yup.mixed().when("type", {
    is: (value) => value === TRANSACTION_TYPES.FIXED.value,
    then: isDateSameOrAfter(yup.ref("start")),
  }),
  dealType: yup.string().required().trim(),
  account: yup
    .object()
    .nullable()
    .shape({
      number: yup.string().trim().min(26).required(),
      currency: yup.string().trim(),
    }),
  from: yup.object().shape({
    currency: yup.string().required().trim(),
    quantity: yup.number().positive().required(),
  }),
  to: yup.object().shape({
    currency: yup.string().required().trim(),
    quantity: yup.number().required(),
  }),
  comment: yup.string().trim(),
});
