import styled from "@emotion/styled/macro";

export const StyledSelectContainer = styled.div`
  position: relative;
`;

export const StyledLabel = styled.span`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors["sk-gray"]};
  font-size: 13px;
  font-weight: 600;
  left: 20px;
  padding-left: 0.3rem;
  padding-right: 0.5rem;
  position: absolute;
  top: -8px;
  z-index: 2;
`;
