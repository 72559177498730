import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Contact } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface IdDetailsProps {
  contact: Contact;
}

export function IdDetails({ contact }: IdDetailsProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("contact:PESEL")} value={contact.idDetails?.pesel} />
      <LabeledText label={t("contact:ID Type")} value={contact.idDetails?.idType} />
      <LabeledText label={t("contact:Number")} value={contact.idDetails?.number} />
      <LabeledText label={t("contact:Expiration Date")} value={contact.idDetails?.expirationDate} />
    </TwoColumnsLayout>
  );
}
