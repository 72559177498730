import { User } from "../interfaces/models";

export const ADMIN_ROLE = "admin";
export const USER_ROLE = "user";
export const DEALER_ROLE = "dealer";

export const ADD_COMPANY_GUARD = "addCompany";
export const ACTIVATE_COMPANY_GUARD = "activateCompany";
export const DELETE_COMPANY_GUARD = "deleteCompany";
export const APPROVE_APPLICATION_GUARD = "approveCompany";
export const RISK_GUARD = "rPanel";
export const ADD_MARGIN_CALLS = "addMarginCalls";
export const ADD_TRANSACTION = "addTransaction";
export const ADD_USERS = "addUsers";
export const ADD_COMMISSION_FEE = "addCommissionFee";
export const SHOW_OWN_COMMISSION_FEE = "showOwnCommissionFee";
export const LIST_PAYMENTS = "listPayments";
export const SHOW_REPORTS = "showReports";
export const SHOW_DASHBOARD = "showDashboard";
export const SHOW_SCORING = "showScoring";
export const SHOW_OPERATIONS = "showOperations";
export const DATA_FIXER = "dataFixer";

export type UserRoles = typeof ADMIN_ROLE | typeof USER_ROLE;
export type RoleAreas =
  | typeof ADD_COMPANY_GUARD
  | typeof ACTIVATE_COMPANY_GUARD
  | typeof DELETE_COMPANY_GUARD
  | typeof ADD_COMMISSION_FEE
  | typeof RISK_GUARD
  | typeof ADD_TRANSACTION
  | typeof APPROVE_APPLICATION_GUARD
  | typeof ADD_MARGIN_CALLS
  | typeof ADD_USERS
  | typeof LIST_PAYMENTS
  | typeof SHOW_OWN_COMMISSION_FEE
  | typeof SHOW_REPORTS
  | typeof SHOW_DASHBOARD
  | typeof SHOW_SCORING
  | typeof SHOW_OPERATIONS
  | typeof DATA_FIXER;

export const roles = [
  {
    type: "Admin",
    access: "Full",
    addUsers: true,
    addMarginCalls: true,
    addCommissionFee: true,
    showOwnCommissionFee: true,
    rPanel: true,
    addTransaction: true,
    addCompany: true,
    approveCompany: true,
    activateCompany: true,
    deleteCompany: true,
    listPayments: true,
    showReports: true,
    showDashboard: true,
    showScoring: true,
    showOperations: true,
    dataFixer: true,
  },
  {
    type: "Dealer",
    access: "Limited",
    addUsers: false,
    addMarginCalls: true,
    addCommissionFee: false,
    showOwnCommissionFee: true,
    rPanel: true,
    addTransaction: true,
    addCompany: true,
    approveCompany: true,
    activateCompany: true,
    deleteCompany: false,
    listPayments: true,
    showReports: false,
    showDashboard: true,
    showOperations: true,
    showScoring: false,
  },
  {
    type: "Senior Employee",
    addUsers: false,
    addMarginCalls: true,
    addCommissionFee: false,
    showOwnCommissionFee: true,
    rPanel: false,
    addTransaction: true,
    addCompany: false,
    approveCompany: false,
    activateCompany: false,
    deleteCompany: false,
    listPayments: true,
    showReports: false,
    showDashboard: true,
    showOperations: true,
    showScoring: false,
  },
  {
    type: "Employee",
    access: "Limited",
    addUsers: false,
    addMarginCalls: false,
    addCommissionFee: false,
    showOwnCommissionFee: true,
    rPanel: false,
    addTransaction: true,
    addCompany: false,
    approveCompany: false,
    activateCompany: false,
    deleteCompany: false,
    listPayments: true,
    showReports: false,
    showDashboard: false,
    showOperations: false,
    showScoring: false,
  },
  {
    type: "Office Manager",
    access: "Limited",
    addUsers: false,
    addMarginCalls: false,
    addCommissionFee: false,
    showOwnCommissionFee: false,
    rPanel: false,
    addTransaction: false,
    addCompany: true,
    approveCompany: true,
    activateCompany: false,
    deleteCompany: false,
    listPayments: false,
    showReports: false,
    showDashboard: false,
    showOperations: false,
    showScoring: false,
  },
  {
    type: "Disabled",
    access: "Limited",
    addUsers: false,
    addMarginCalls: false,
    addCommissionFee: false,
    showOwnCommissionFee: false,
    rPanel: false,
    addTransaction: false,
    addCompany: false,
    approveCompany: false,
    activateCompany: false,
    deleteCompany: false,
    listPayments: false,
    showReports: false,
    showDashboard: false,
    showOperations: false,
    showScoring: false,
  },
];

export const hasAccess = (role?: string, area?: RoleAreas) => {
  if (!area) {
    return true;
  }

  if (!role) {
    return false;
  }

  const permissions = roles.find((userRole) => userRole.type === role);

  if (!permissions) {
    return false;
  }

  return Boolean(permissions[area]);
};

export const hasUserRole = ({ user, role }: { user: User; role: UserRoles }) => {
  if (!user.profile || !user.profile.roles) return false;
  return user.profile.roles.includes(role);
};
