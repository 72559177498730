import * as yup from "yup";
import { userRoleValidation } from "./shared";

export const profileSchema = yup.object().shape({
  name: yup.string().required().trim(),
  surname: yup.string().required().trim(),
  email: yup.string().required().trim(),
  role: userRoleValidation,
  lang: yup.string().nullable().trim(),
});
