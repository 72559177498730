import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { MarginMoveModal } from "../../Margin/MarginMoveModal";

export function MarginMoveLocal() {
  const { id, transactionId, marginId } = useParams<{
    id: string;
    transactionId: string;
    marginId: string;
  }>();

  const history = useHistory();
  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <MarginMoveModal handleClose={handleClose} marginId={marginId} transactionId={transactionId} />;
}
