import Decimal from "decimal.js";
import { Hedge } from "../interfaces/data";
import { convertToTimestamp, timestampFromMap } from "./timestamp";
import dayjs from "dayjs";
import { transformTimestampToDate } from "./date";

export const sumLQtyOfHedges = (hedges?: Array<Hedge | undefined>) => {
  if (!hedges) return 0;
  const cleanedUpHedges = hedges.filter((hedge): hedge is Hedge => hedge !== undefined);
  return cleanedUpHedges
    .filter((hedge) => activeHedge(cleanedUpHedges, hedge, true))
    .reduce((sum, rolledHedge) => sum + (Number(rolledHedge?.leftQuantity) || 0), 0);
};

export const getMeanAverageOfHedgesPoints = (hedges?: Array<Hedge | undefined>) => {
  if (!hedges) return 0;
  const totalQuantity = sumLQtyOfHedges(hedges);
  return Number(
    new Decimal(
      hedges.reduce(
        (sum, rolledHedge) => sum + Number((rolledHedge?.leftQuantity || 0) * (rolledHedge?.points || 0)),
        0
      ) / totalQuantity
    ).toFixed(4)
  );
};

export function isHedgePast(hedge: Hedge) {
  const hedgeEnd = hedge.end ? dayjs(transformTimestampToDate(hedge.end)) : undefined;
  const today = dayjs().startOf("day");
  return hedgeEnd ? hedgeEnd.isBefore(today) : false;
}

export function activeHedge(hedges: Hedge[], hedge: Hedge, allowPastHedges = false) {
  const isHedgeActive = allowPastHedges || !isHedgePast(hedge);
  return !hedges.some((h) => h.createdFromId === hedge.id) && hedge.leftQuantity > 0 && isHedgeActive;
}

export function recentHedge(hedges: Hedge[], hedge: Hedge) {
  const hedgeEnd = hedge.end ? dayjs(transformTimestampToDate(hedge.end)) : undefined;
  const inSevenDays = dayjs().add(7, "day");
  const isHedgeRecent = hedgeEnd ? hedgeEnd.isBefore(inSevenDays) : true;
  return !hedges.some((h) => h.createdFromId === hedge.id) && hedge.leftQuantity > 0 && isHedgeRecent;
}

export const activeHedges = (hedges: Hedge[], allowPastHedges?: boolean) =>
  hedges.filter((hedge) => activeHedge(hedges, hedge, allowPastHedges));
export const recentHedges = (hedges: Hedge[]) => hedges.filter((hedge) => recentHedge(hedges, hedge));

export const fixHedgesTimestamps = (hedges: Hedge[] = []) => {
  return hedges.map((hedge) => {
    if (hedge.createdAt) {
      hedge.createdAt = convertToTimestamp(hedge.createdAt);
    }
    if (hedge.modifiedAt) {
      hedge.modifiedAt = convertToTimestamp(hedge.modifiedAt);
    }
    return {
      ...hedge,
      start: timestampFromMap(hedge.start),
      end: timestampFromMap(hedge.end),
    };
  });
};
