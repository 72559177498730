import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "components/Table/Table";
import { InputLeftElement, Text } from "@chakra-ui/react";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { usePayments } from "hooks/usePayments";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { Checkbox } from "components/Checkbox/Checkbox";
import styled from "@emotion/styled";
import { useTransaction } from "hooks/useTransaction";
import { Payment, Transaction } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { DateCell, QuantityCell } from "helpers/tableCells";
import { PAYMENT_STATUS_DONE, PAYMENT_STATUS_OK, PAYMENT_STATUS_SENT } from "helpers/payments";
import { PageHeader } from "components/PageHeader/PageHeader";
import { EditableComment } from "./EditableComment";
import { Link } from "react-router-dom";
import { DEAL_TYPE_BUY, determineTransactionCurrencyPair } from "helpers/transaction";
import { SearchIcon } from "theme/icons";

interface CheckboxStatuses {
  id: string;
  isSent?: boolean;
  isDone?: boolean;
  isReversePaymentOk?: boolean;
}

const StyledTableContainer = styled.div`
  td[role="cell"] {
    position: relative;
  }

  tr.sent-row {
    background-color: #fff9e5;
    &:hover {
      background-color: #fff7d9;
    }
  }
  tr.done-row {
    background-color: #ecf9e5;
    &:hover {
      background-color: #e2f7d9;
    }
  }
  tr.reverse-payment-row {
    background-color: #111;
    color: white;
    a,
    button {
      color: white !important;
    }
    textarea {
      color: ${(props) => props.theme.colors["sk-dark"]};
    }
    &:hover {
      background-color: #000;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const TransactionIdCell = (arg: any) => {
  const id = arg.row.original.id;
  const transaction = arg.row.original.transaction as Transaction;
  return (
    <StyledLink style={{ whiteSpace: "nowrap" }} to={`/transactions/${transaction.id}`}>
      {id}
    </StyledLink>
  );
};

const OriginalQtyCell = (arg: any) => {
  const transaction = arg.row.original.transaction as Transaction;
  const isBuy = transaction.dealType === DEAL_TYPE_BUY;
  const quantity = isBuy ? formatNumberToString(arg.value * transaction.clientRate) : formatNumberToString(arg.value);
  const currency = isBuy ? transaction.to.currency : transaction.from.currency;

  return (
    <Text as="span" display="block" fontSize="8px" textAlign="right" whiteSpace="nowrap">
      ({quantity} {currency})
    </Text>
  );
};

const PaymentCurrencyCell = (arg: any) => {
  const payment = arg.row.original as Payment;
  if (!payment) return "";
  const [, currency] = determineTransactionCurrencyPair(payment.transaction);

  return (
    <Text as="span" fontSize="15px" fontWeight="900">
      {currency}
    </Text>
  );
};

export function Payments() {
  const { t } = useTranslation();
  const { settlementInTransactionUpdate } = useTransaction({
    skipFetching: true,
  });

  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "createdDesc",
        label: t("company:Creation date (newest first)"),
        field: "createdAt",
        order: "desc",
      },
      {
        value: "createdAsc",
        label: t("company:Creation date (oldest first)"),
        field: "createdAt",
        order: "asc",
      },
      {
        value: "modifiedDesc",
        label: t("company:Recently updated"),
        field: "transaction.modifiedAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("company:Company name (A-Z)"),
        field: "transaction.company.name",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("company:Company name (Z-A)"),
        field: "transaction.company.name",
        order: "desc",
      },
    ],
    [t]
  );
  const [defaultSortingOption] = sortingOptions;
  const [searchQuery, setSearchQuery] = useState("");
  const [sortingOption, setSortingOption] = useState(defaultSortingOption);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const [checkboxStatuses, setCheckboxStatuses] = useState<Array<CheckboxStatuses>>([]);
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const toast = useToast();

  const [isSending, setIsSending] = useState(false);

  const { payments, loading, pageCount, refetch } = usePayments({
    phrase: debouncedSearchQuery,
    fields: "transaction.company.name,transaction.number",
    orderBy: sortingOption.field,
    orderDirection: sortingOption.order,
    offset,
    limit,
  });

  useEffect(() => {
    const paymentStates = payments.map(({ id, isSent, isDone, isReversePaymentOk }) => ({
      id,
      isSent,
      isDone,
      isReversePaymentOk,
    }));
    setCheckboxStatuses(paymentStates);
  }, [payments]);

  const handleSettlementStatusUpdate = useCallback(
    ({
        transactionId,
        settlementId,
        status,
        value,
        isDisabled,
      }: {
        transactionId: string;
        settlementId: string;
        status: string;
        value: boolean;
        isDisabled: boolean;
      }) =>
      async () => {
        const statuses = checkboxStatuses;
        try {
          if (isDisabled) return;
          const newStatuses = statuses.map((s) => {
            if (s.id === settlementId) {
              s.isDone = status === PAYMENT_STATUS_DONE && Boolean(value);
              s.isSent = status === PAYMENT_STATUS_SENT && Boolean(value);
              s.isReversePaymentOk = status === PAYMENT_STATUS_OK && Boolean(value);
            }
            return s;
          });
          setCheckboxStatuses(newStatuses);
          setIsSending(true);

          await settlementInTransactionUpdate(transactionId, settlementId, {
            [status]: value,
          });

          await refetch();
          toast({
            type: "success",
            message: t("Payment status updated"),
          });
        } catch (error) {
          setCheckboxStatuses(statuses);
          if (error instanceof Error) {
            toast({
              type: "error",
              message: t(error.message),
            });
          }
        } finally {
          setIsSending(false);
        }
      },
    [checkboxStatuses, refetch, settlementInTransactionUpdate, t, toast]
  );

  const handleCommentUpdate = useCallback(
    async ({
      transactionId,
      settlementId,
      comment,
    }: {
      transactionId: string;
      settlementId: string;
      comment: string;
    }) => {
      await settlementInTransactionUpdate(transactionId, settlementId, {
        paymentComment: comment,
      })
        .then(() => {
          refetch();
          toast({
            type: "success",
            message: t("Payment comment has been updated."),
          });
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(error.message),
          });
        })
        .finally(() => {
          setIsSending(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const CommentCell = useCallback(
    (arg: any) => {
      const payment = arg.row.original as Payment;
      const transactionId = payment.transaction.id;
      const settlementId = payment.id;
      return (
        <EditableComment
          comment={arg.value}
          onSave={(newComment) =>
            handleCommentUpdate({
              transactionId,
              settlementId,
              comment: newComment,
            })
          }
        />
      );
    },
    [handleCommentUpdate]
  );

  const CheckboxCell = useCallback(
    (arg: any) => {
      const payment = arg.row.original as Payment;
      const checkboxType = arg.cell.column.id;
      const checkboxStatus = checkboxStatuses.find((status) => status.id === payment.id);
      const isChecked =
        checkboxType === PAYMENT_STATUS_DONE
          ? checkboxStatus?.isDone
          : checkboxType === PAYMENT_STATUS_SENT
          ? checkboxStatus?.isSent
          : checkboxStatus?.isReversePaymentOk;
      const isDisabled =
        (checkboxType === PAYMENT_STATUS_DONE && !payment.isSent) ||
        (checkboxType === PAYMENT_STATUS_SENT && payment.isDone) ||
        isSending;
      const checkboxColor = checkboxType === PAYMENT_STATUS_SENT ? "sk-yellow" : "sk-light-green";
      if (!payment) return "";
      return (
        <Checkbox
          checkboxColor={checkboxColor}
          isChecked={isChecked}
          isDisabled={isDisabled}
          onChange={handleSettlementStatusUpdate({
            transactionId: payment.transaction.id,
            settlementId: payment.id,
            status: checkboxType,
            value: !isChecked,
            isDisabled,
          })}
        />
      );
    },
    [checkboxStatuses, handleSettlementStatusUpdate, isSending]
  );

  const CompanyCell = (arg: any) => {
    const { value } = arg;
    const companyId = arg.row.original.transaction.company.id;

    return (
      <Link to={`/companies/${companyId}`}>
        <Text as="span" fontSize="15px" fontWeight="900">
          {value}
        </Text>
      </Link>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t("Company"),
        accessor: "transaction.company.name",
        Cell: CompanyCell,
      },
      {
        Header: t("Trans ID"),
        accessor: "transaction.number",
        Cell: TransactionIdCell,
      },
      {
        Header: t("Transaction type"),
        accessor: "transaction.type",
      },
      {
        Header: t("Type"),
        accessor: "transaction.dealType",
      },
      {
        Header: t("Client rate"),
        accessor: "transaction.clientRate",
        Cell: (arg: any) => `${formatRate(arg.value)}`,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Quantity")}
          </Text>
        ),
        accessor: "transaction.from.quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.value)}
          </Text>
        ),
      },
      {
        Header: t("What"),
        id: "whichCurrency",
        accessor: "transaction.from.currency",
      },
      {
        Header: t("Payment date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Payment Qty")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg) => QuantityCell(arg, true, false),
      },
      {
        Header: t("Pmt Ccy"),
        id: "paymentCurrency",
        accessor: "transaction.to.currency",
        Cell: PaymentCurrencyCell,
      },
      {
        Header: " ",
        accessor: "quantity",
        id: "different-qty",
        Cell: OriginalQtyCell,
      },
      {
        Header: t("Payment type"),
        accessor: "paymentType",
        Cell: () => "Settlement",
      },
      {
        Header: t("Comments"),
        accessor: "paymentComment",
        Cell: CommentCell,
      },
      {
        Header: t("Transfer created"),
        accessor: PAYMENT_STATUS_SENT,
        Cell: CheckboxCell,
      },
      {
        Header: t("Transfer done"),
        accessor: PAYMENT_STATUS_DONE,
        Cell: CheckboxCell,
      },
      {
        Header: t("OK"),
        accessor: PAYMENT_STATUS_OK,
        Cell: (arg: any) => (arg.row.original.isReversePayment ? CheckboxCell(arg) : null),
      },
    ],
    [CheckboxCell, CommentCell, t]
  );

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSeachInputUdpate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleSortByUpdate = useCallback((newSelectedOption: TableSortOption) => {
    setSortingOption(newSelectedOption);
  }, []);

  if (!payments) return null;

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("Payments")}</Heading>
      </PageHeader>
      <StyledContainer>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSeachInputUdpate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <TableSortSelect
            onSortByChange={handleSortByUpdate}
            options={sortingOptions}
            selectedOption={sortingOption}
          />
        </StyledTableControls>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={payments}
            getRowProps={(rowInfo) => {
              const payment = rowInfo.original as Payment;

              let prefix;
              if (payment.isSent) prefix = "sent";
              if (payment.isDone) prefix = "done";

              if (payment.isReversePayment) {
                prefix = payment.isReversePaymentOk ? "done" : "reverse-payment";
              }

              return { className: prefix ? `${prefix}-row` : "" };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUdpate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
}
