import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledLinkContainer = styled.div`
  color: ${(props) => props.theme.colors["sk-dark"]};
  margin: -30px;
  padding: 30px;
`;

export const StyledAddbutton = styled.button`
  text-align: center;
  color: ${(props) => props.theme.colors["sk-purple"]};
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
`;

export const StyledTableLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  color: #818181;
  margin-left: 30px;
  margin-top: 30px;
`;

export const StyledTableContainer = styled.div`
  tr td:first-of-type {
    width: 60px;
  }
  tr.total-row td:first-of-type {
    opacity: 0;
  }
  tr.total-row,
  tr.total-row:hover {
    background: #f9f9f9;
    font-weight: 900;
  }
  tr.total-row td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tr.total-row td:nth-of-type(2) {
    position: relative;
  }
  tr.total-row td:nth-of-type(2) span {
    position: relative;
    left: -40px;
  }
  tr.total-row td:nth-of-type(3) {
    opacity: 0;
  }

  tr.total-balance-row {
    height: 100px;
  }
  tr.global-row {
    outline: 5px solid ${({ theme }) => theme.colors["sk-blue"]};
    outline-offset: -5px;
    background: ${(props) => `${props.theme.colors["sk-blue"]}25`};
    > * {
      font-weight: 700;
    }
  }
  td.transactions-row {
    max-width: 150px;
  }
`;

export const StyledVMTableContainer = styled(StyledTableContainer)`
  tr td:last-child {
    width: 60px;
  }
  .mc-table {
    width: calc(100% + 60px);
    margin: -30px;
    margin-bottom: -32px;
  }
  .mc-table tr td:last-child {
    width: auto;
  }
  .mc-table thead {
    font-weight: bold;
  }
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
`;

export const StyledTotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 20px;
  padding-right: 200px;
  background: #f9f9f9;
  font-weight: 900;
  border-top: 1px solid #b7b7b7;
  border-bottom: 1px solid #b7b7b7;
  font-size: 13px;
  line-height: 110%;
  color: #252527;
`;

export const StyledTotalCurrenciesRow = styled(StyledTotalRow)`
  margin-top: 0;
  border-top: none;
`;

export const StyledTextBold = styled.span`
  font-weight: 900;
`;
