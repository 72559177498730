import React from "react";
import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

const StyledValue = styled.div<{ bold?: boolean }>`
  font-style: normal;
  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  font-size: 13px;
  line-height: 110%;
  word-wrap: break-word;
  white-space: normal;
  color: ${(props) => props.theme.colors["sk-dark"]};
  margin-right: 5px;
`;

interface BankAccountFieldProps {
  accountNumber: string;
  currency: string;
}

export const BankAccountField: React.FC<BankAccountFieldProps> = ({ accountNumber, currency }) => {
  return (
    <Box display="flex">
      <StyledValue bold>{currency}</StyledValue>
      <StyledValue>{accountNumber}</StyledValue>
    </Box>
  );
};
