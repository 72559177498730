import { StyledCommitmentsContainer, StyledCommitmentsHeader, StyledSectionName } from "../shared.styled";
import { Table } from "components/Table/Table";
import React, { useMemo } from "react";
import { TransactionCommitment, transactionCommitment } from "helpers/transactionCommitment";
import { NbpRateRecord, Transaction } from "interfaces/data";
import { useTranslation } from "react-i18next";
import { Text } from "@chakra-ui/react";
import { formatNumberToString } from "helpers/formatNumber";

interface CommitmentTotalsProps {
  transactions: Array<Transaction>;
  nbpRates: NbpRateRecord;
}

const ValueCell = (arg: { value: Number }) => {
  return (
    <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
      {formatNumberToString(arg.value.valueOf(), 2)} PLN
    </Text>
  );
};

export const CommitmentTotals: React.FC<CommitmentTotalsProps> = ({ transactions, nbpRates }) => {
  const { t } = useTranslation();

  // Table columns
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Spread Profit")}
          </Text>
        ),
        accessor: "spreadProfit",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Hedges Profit")}
          </Text>
        ),
        accessor: "hedgesProfit",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Client Loss")}
          </Text>
        ),
        accessor: "clientLoss",
        Cell: ValueCell,
      },
    ],
    [t]
  );

  const transactionsCommitments = transactions.map((transaction) => {
    return transactionCommitment(transaction, nbpRates);
  });
  const data = transactionsCommitments.reduce(
    (acc: TransactionCommitment, transactionCommitment) => {
      return {
        hedgesProfit: acc.hedgesProfit + transactionCommitment.hedgesProfit,
        spreadProfit: acc.spreadProfit + transactionCommitment.spreadProfit,
        clientLoss: acc.clientLoss + transactionCommitment.clientLoss,
      };
    },
    { hedgesProfit: 0, spreadProfit: 0, clientLoss: 0 }
  );

  return (
    <>
      <StyledCommitmentsHeader>
        <StyledSectionName>{t("risk:Total Commitments")}</StyledSectionName>
      </StyledCommitmentsHeader>

      <StyledCommitmentsContainer>
        <Table columns={columns} data={[data]} />
      </StyledCommitmentsContainer>
    </>
  );
};
