import GaugeChart from "react-gauge-chart";
import React from "react";
import { Company } from "interfaces/data";

export const LimitsGauge: React.FC<{ company: Company; available: number }> = ({ company, available }) => {
  if (!company || !available) return null;

  return (
    <GaugeChart
      animate={false}
      arcPadding={0.03}
      arcsLength={[0.8, 0.1, 0.1]}
      colors={["#5BE12C", "#F5CD19", "#EA4228"]}
      formatTextValue={() => (company.details?.nolimits ? "No limits" : `${100 - available}%`)}
      id="gauge-chart1"
      percent={company.details?.nolimits ? 100 : (100 - available) / 100}
      textColor="#252527"
    />
  );
};
