import React from "react";
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";

interface ButtonProps extends ChakraButtonProps {
  type?: "button" | "reset" | "submit" | undefined;
  design?: "primary" | "secondary" | "danger" | "ghost" | "link";
}

export const Button: React.FC<ButtonProps> = ({ children, type, design, ...props }) => {
  let color: string;
  let bgColor: string;
  let bgHoverColor: string;
  let bgActiveColor: string;
  let borderColor: string;

  switch (design) {
    case "primary":
      color = "white";
      bgColor = "sk-purple";
      bgHoverColor = "linear-gradient(#5B4DBE, #3a3084)";
      bgActiveColor = "linear-gradient(#3a3084, #5B4DBE)";
      borderColor = "sk-purple";
      break;
    case "secondary":
      color = "sk-dark";
      bgColor = "white";
      bgHoverColor = "linear-gradient(white, #ECECEC)";
      bgActiveColor = "linear-gradient(#ECECEC, white)";
      borderColor = "sk-light-gray";
      break;
    case "danger":
      color = "white";
      bgColor = "sk-red";
      bgHoverColor = "linear-gradient(#EC4713, #bb3910)";
      bgActiveColor = "linear-gradient(#bb3910, #EC4713)";
      borderColor = "sk-red";
      break;
    case "ghost":
      color = "sk-dark";
      bgColor = "transparent";
      bgHoverColor = "transparent";
      bgActiveColor = "transparent";
      borderColor = "transparent";
      break;
    case "link":
      color = "sk-dark";
      bgColor = "transparent";
      bgHoverColor = "transparent";
      bgActiveColor = "transparent";
      borderColor = "transparent";
      break;
    default:
      color = "sk-dark";
      bgColor = "white";
      bgHoverColor = "sk-light-gray";
      bgActiveColor = "sk-light-gray";
      borderColor = "sk-light-gray";
  }

  return (
    <ChakraButton
      _active={{
        backgroundImage: bgActiveColor,
      }}
      _focus={{
        backgroundColor: bgColor,
      }}
      _hover={{
        backgroundImage: bgHoverColor && !props.isDisabled ? bgHoverColor : undefined,
      }}
      backgroundColor={bgColor}
      borderColor={borderColor}
      borderRadius="base"
      borderStyle="solid"
      borderWidth="1px"
      color={color}
      fontSize="sm"
      fontWeight="extrabold"
      height="52px"
      letterSpacing="2px"
      px="30px"
      type={type}
      {...props}
    >
      {children}
    </ChakraButton>
  );
};
