import { Transaction } from "../interfaces/data";
import { UseTransactions } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useTransactions = ({
  phrase,
  fields,
  filtersOr,
  filtersAnd,
  filtersAndBetween,
  filtersAndCustom,
  orderBy,
  orderDirection,
  offset,
  limit,
  skipAutoUpdate,
  isLazy,
}: UseTransactions) => {
  const {
    data: transactions,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Transaction>({
    collection: "transactions",
    phrase,
    fields,
    filtersOr,
    filtersAnd,
    filtersAndBetween,
    filtersAndCustom,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate,
    isLazy,
  });

  return { transactions, totalCount, pageCount, loading, error, refetch };
};
