import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFirebase } from "hooks/useFirebase";
import { useTransaction } from "hooks/useTransaction";
import { getQuantityLeft, TRANSACTION_TYPES } from "helpers/transaction";
import { useToast } from "hooks/useToast";
import { MarginCallFormModal } from "./MarginCallFormModal";
import { MARGIN_CALL_MAIL } from "helpers/mails";
import { useMail } from "hooks/useMail";
import { ZodOptionalIDMarginCall } from "schemas/marginCall";
import { MarginCall } from "interfaces/data";

export function MarginCallAdditionalAdd() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId?: string;
  }>();
  const toast = useToast();
  const { transaction, marginCallAdd, marginCallErrors } = useTransaction({
    id,
  });
  const { timestamp } = useFirebase();
  const sendConfirmation = useMail();
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveAction = useCallback(
    (newMarginCall: ZodOptionalIDMarginCall) => {
      setIsSending(true);
      marginCallAdd(newMarginCall as MarginCall)
        .then((data) => {
          setIsSending(false);
          if (data) {
            sendConfirmation(MARGIN_CALL_MAIL, data.transaction, {
              marginCall: data.marginCall,
            });
            toast({
              type: "success",
              message: t("marginCall:Additional Margin Call has been added successfully"),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`marginCall:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose, marginCallAdd]
  );

  useEffect(() => {
    if (transaction && transaction.type !== TRANSACTION_TYPES.FIXED.value) {
      handleClose();
      toast({
        type: "error",
        message: t("marginCall:Margin Calls can be added only to Fixed Rate transactions"),
      });
    }
  }, [handleClose, t, toast, transaction]);

  const previousMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [transaction, marginCallId]);

  if (!transaction || !marginCallId || !previousMarginCall) return null;

  return (
    <MarginCallFormModal
      errors={marginCallErrors}
      initialMarginCall={{
        callDate: timestamp(),
        transQuantity: getQuantityLeft(transaction),
        isClosed: "no",
        createdFromId: marginCallId,
      }}
      initialTransaction={transaction}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      previousCallRate={previousMarginCall.callRate}
      previousMarginCall={previousMarginCall}
      title={t("marginCall:Additional Margin Call")}
      type="add-additional"
    />
  );
}
