import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { transformTimestamp } from "helpers/date";
import { useHotkeys } from "hooks/useHotkeys";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { StyledContainer, StyledEditableField, StyledEditContainer } from "./shared.styled";
import { ApproveIcon } from "theme/icons/ApproveIcon";
import { CloseIcon } from "theme/icons/CloseIcon";

const formatTimestamp = (expiration: any) => {
  const formattedDate = transformTimestamp(expiration, "YYYY-MM-DD");
  return formattedDate !== "-" ? formattedDate : undefined;
};

interface ExpirationDateCellProps {
  row: any;
  onSave: (newDate?: Date) => any;
  onClear: () => any;
}

export const ExpirationDateCell: React.FC<ExpirationDateCellProps> = ({ row, onSave, onClear }) => {
  const expiration = row.original.commissionFee.expiration;
  const [isEdited, setIsEdited] = useState(false);
  const [expirationDate, setExpirationDate] = useState(formatTimestamp(expiration));
  const editRef = useRef(null);

  useEffect(() => {
    setExpirationDate(formatTimestamp(expiration));
  }, [expiration]);

  const handleSave = () => {
    if (expirationDate) {
      const newExpirationDate = new Date(expirationDate);
      onSave(newExpirationDate);
    }
    setIsEdited(false);
  };

  const handleClear = () => {
    setExpirationDate(undefined);
    onClear();
    setIsEdited(false);
  };

  const handleClose = useCallback(() => {
    setExpirationDate(formatTimestamp(expiration));
    setIsEdited(false);
  }, [expiration]);

  useOnClickOutside(editRef, handleClose);
  useHotkeys("Escape", handleClose);
  useHotkeys("Enter", () => {
    isEdited && handleSave();
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpirationDate(e.target.value);
  };

  return (
    <StyledContainer>
      {isEdited ? (
        <StyledEditContainer ref={editRef}>
          <Input
            containerProps={{
              width: "100%",
            }}
            fullWidth
            name="expiration"
            onChange={handleChange}
            type="date"
            value={expirationDate}
            width="100%"
          />
          <Button design="primary" height="100%" ml="11px" onClick={handleSave} p="18px">
            <ApproveIcon />
          </Button>
          <Button design="danger" height="100%" ml="11px" onClick={handleClear} p="18px">
            <CloseIcon />
          </Button>
        </StyledEditContainer>
      ) : (
        <StyledEditableField onClick={() => setIsEdited(true)}>
          {expiration ? transformTimestamp(expiration) : "-"}
        </StyledEditableField>
      )}
    </StyledContainer>
  );
};
