import React, { useCallback, useState } from "react";
import { StyledSectionComment } from "../../Transaction/Transaction/TransactionDetails";
import { EditComment } from "../../Transaction/Comment/EditComment";
import { Comment } from "../../Transaction/Comment/Comment";
import { useTranslation } from "react-i18next";
import { Company } from "../../../interfaces/data";
import { Section } from "../shared.styled";

interface NoteSectionProps {
  company: Company;
  notesUpdate: (comment: string) => Promise<void>;
}

export const NoteSection: React.FC<NoteSectionProps> = ({ company, notesUpdate }) => {
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const { t } = useTranslation();

  const handleNotesUpdate = useCallback(
    (comments: string) => {
      notesUpdate(comments).then(() => {
        setIsEditingNotes(false);
      });
    },
    [notesUpdate]
  );

  return (
    <Section>
      <StyledSectionComment>
        {isEditingNotes && (
          <EditComment
            alignToRight
            asBox={false}
            comment={company.details.notes}
            onEdit={handleNotesUpdate}
            onEditClose={() => setIsEditingNotes(false)}
          />
        )}
        <Comment comment={company.details.notes} label={t("Note")} onEdit={() => setIsEditingNotes(true)} />
      </StyledSectionComment>
    </Section>
  );
};
