import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import { Spinner } from "components/Spinner/Spinner";
import { Heading } from "components/Heading/Heading";
import { EditBox } from "components/EditBox/EditBox";
import { Box, Flex } from "@chakra-ui/react";
import { EditContactAddress } from "./ContactAddress/EditContactAddress";
import { ContactAddress } from "./ContactAddress/ContactAddress";
import { ContactInfo } from "./ContactInfo/ContactInfo";
import { EditContactInfo } from "./ContactInfo/EditContactInfo";
import { IdDetails } from "./IdDetails/IdDetails";
import { EditIdDetails } from "./IdDetails/EditIdDetails";
import { Status } from "./Status/Status";
import { EditStatus } from "./Status/EditStatus";
import { useContact } from "hooks/useContact";
import {
  StyledBackButton,
  StyledSecondBackButton,
  StyledThreeColumnsContainer,
} from "components/ItemsListElements/ItemsListElements";
import { PageHeader } from "components/PageHeader/PageHeader";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { ADD_COMPANY_GUARD } from "helpers/userRoles";
import { EditCompanies } from "./Company/EditCompanies";
import { useContacts } from "hooks/useContacts";
import { Company } from "./Company/Company";
import { BaseCompany, Company as CompanyInterface, Contact as ContactInterface } from "interfaces/data";
import { useCompany } from "hooks/useCompany";
import { useToast } from "hooks/useToast";
import { ArrowLeftIcon } from "theme/icons";

enum ContactSections {
  contactInfo = "contact-info",
  contactAddress = "contact-address",
  idDetails = "id-details",
  status = "status",
  assignedCompanies = "assigned-companies",
}
export function Contact() {
  const { id, section } = useParams<{ id: string; section: ContactSections }>();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState<Array<CompanyInterface> | null>(null);
  const { contact, update, loading, errors, setContact, clearErrors } = useContact({ id });
  const { updateCompaniesContacts } = useCompany({ skipFetching: true });
  const { contacts } = useContacts({
    phrase: id,
    fields: "id",
    orderBy: "id",
    orderDirection: "asc",
    offset: 0,
    limit: 1,
  });

  useEffect(() => {
    if (contacts?.length && !companies) {
      const contactsCompanies = contacts?.[0]?.companies || [];
      setCompanies(
        contactsCompanies.map((c, idx) => {
          const tempCompanies = contactsCompanies.filter((cc, idx2) => cc.id === c.id && idx2 < idx);
          const roleIndex = tempCompanies.length;
          const contactToAdd = c.contacts!.filter((v) => v.id === id).find((_, idx2) => idx2 === roleIndex);
          return {
            ...c,
            contacts: [...(c.contacts?.filter((v) => v.id !== id) || []), contactToAdd!],
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);
  const [editedSection, setEditedSection] = useState<ContactSections | null>(section);

  const cancelSectionEdit = useCallback((initialData: ContactInterface) => {
    setEditedSection(null);
    setContact(initialData);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editedSection) {
      history.replace(`/contacts/${id}/edit/${editedSection}`);
    } else if (editedSection === null) {
      history.replace(`/contacts/${id}`);
    }
  }, [editedSection, history, id]);

  const handleSectionEdit = useCallback(
    (section: ContactSections | null) => () => {
      setEditedSection(section);
    },
    []
  );

  const submitSectionEdit = useCallback(
    (newContact: ContactInterface) => {
      handleSectionEdit(null)();
      update(newContact);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, handleSectionEdit]
  );
  const handleCompaniesSave = async function (companies: CompanyInterface[]) {
    const companyPositions = companies.map(
      (c, idx) => `${c.id},${c.contacts!.find((cc) => cc.email === contact!.email)?.type || idx}`
    );
    const uniqueCompanyPositionsSize = new Set(companyPositions).size;
    if (companyPositions.length !== uniqueCompanyPositionsSize) {
      return toast({
        type: "error",
        message: t("validation: Contact is already assigned with this role to this company"),
      });
    }
    const toUpdate = companies.reduce<CompanyInterface[]>((acc, curr) => {
      const companyAlreadySet = acc.find((comp) => comp.id === curr.id);
      if (companyAlreadySet) {
        const contacts = [...companyAlreadySet.contacts!, ...curr.contacts!];
        const uniqueContacts: ContactInterface[] = [];
        contacts.forEach((c1) =>
          !uniqueContacts.find((c2) => `${c2!.id},${c2.type}` === `${c1.id},${c1.type}`)
            ? uniqueContacts.push(c1)
            : null
        );
        return [...acc.filter((c) => c.id !== curr.id), { ...curr, contacts: uniqueContacts }];
      }
      return [...acc, curr];
    }, []);
    await updateCompaniesContacts(toUpdate);
    handleSectionEdit(null)();
  };

  const handleCompanyAdd: (
    item: CompanyInterface,
    { handler, data }: { handler: Function; data: BaseCompany[] }
  ) => void = (item, { handler, data }) => {
    if (!contact || !item) {
      return;
    }
    const newItem = { ...item, contacts: [contact, ...(item.contacts || [])] };

    const value = _cloneDeep(data);
    value.splice(0, 0, newItem);
    handler({
      target: {
        name: null,
        value,
      },
      persist: () => {
        return;
      },
    });
  };
  const handleCompanyRemove: (index: number, { handler, data }: { handler: Function; data: BaseCompany[] }) => void = (
    index,
    { handler, data }
  ) => {
    const value = _cloneDeep(data);
    const item = value[index];
    const newItem = {
      ...item,
      contacts: item.contacts?.filter((c) => c.id !== id) || [],
    };

    value.splice(index, 1, newItem);
    handler({
      target: {
        name: null,
        value,
      },
      persist: () => {
        return;
      },
    });
  };
  if (loading || companies === null) {
    return <Spinner />;
  }

  if (!contact) {
    return <>{t("contact:Contact not found")}</>;
  }

  // Take previous location path if user is coming from company 360 view
  const fromPath: string | undefined = _get(history, "location.state.fromPath", undefined);

  return (
    <>
      <PageHeader>
        <Heading type="h1">{`${contact.firstName} ${contact.lastName}`}</Heading>
        <Flex w="100%">
          <StyledBackButton onClick={() => history.push("/contacts")}>
            <ArrowLeftIcon mr="10px" />
            {t("contact:Contacts")}
          </StyledBackButton>
          {fromPath && (
            <StyledSecondBackButton onClick={() => history.push(fromPath)}>
              <ArrowLeftIcon mr="10px" />
              {t("transaction:360 company view")}
            </StyledSecondBackButton>
          )}
        </Flex>
      </PageHeader>
      <StyledThreeColumnsContainer>
        {/* FIRST COLUMN */}
        <div>
          <ActiveUserBorder collection="contacts" id={id} sections={`edit/${ContactSections.contactInfo}`}>
            <EditBox
              data={contact}
              disabled={!!editedSection && editedSection !== ContactSections.contactInfo}
              editView={EditContactInfo}
              errors={errors}
              isEditing={editedSection === ContactSections.contactInfo}
              name={t("contact:Contact info")}
              onCancel={cancelSectionEdit}
              onEdit={handleSectionEdit(ContactSections.contactInfo)}
              onSave={submitSectionEdit}
              restrict={ADD_COMPANY_GUARD}
            >
              <ContactInfo contact={contact} />
            </EditBox>
          </ActiveUserBorder>
          <Box h="20px" />
          <ActiveUserBorder collection="contacts" id={id} sections={`edit/${ContactSections.status}`}>
            <EditBox
              data={contact}
              disabled={!!editedSection && editedSection !== ContactSections.status}
              editView={EditStatus}
              errors={errors}
              isEditing={editedSection === ContactSections.status}
              name={t("contact:Status")}
              onCancel={cancelSectionEdit}
              onEdit={handleSectionEdit(ContactSections.status)}
              onSave={submitSectionEdit}
              restrict={ADD_COMPANY_GUARD}
            >
              <Status contact={contact} />
            </EditBox>
          </ActiveUserBorder>
        </div>

        {/* SECOND COLUMN */}
        <div>
          <ActiveUserBorder collection="contacts" id={id} sections={`edit/${ContactSections.contactAddress}`}>
            <EditBox
              data={contact}
              disabled={!!editedSection && editedSection !== ContactSections.contactAddress}
              editView={EditContactAddress}
              errors={errors}
              isEditing={editedSection === ContactSections.contactAddress}
              name={t("contact:Address")}
              onCancel={cancelSectionEdit}
              onEdit={handleSectionEdit(ContactSections.contactAddress)}
              onSave={submitSectionEdit}
              restrict={ADD_COMPANY_GUARD}
            >
              <ContactAddress contact={contact} />
            </EditBox>
          </ActiveUserBorder>
          <Box h="20px" />
          <ActiveUserBorder collection="contacts" id={id} sections={`edit/${ContactSections.idDetails}`}>
            <EditBox
              data={contact}
              disabled={!!editedSection && editedSection !== ContactSections.idDetails}
              editView={EditIdDetails}
              errors={errors}
              isEditing={editedSection === ContactSections.idDetails}
              name={t("contact:ID details")}
              onCancel={cancelSectionEdit}
              onEdit={handleSectionEdit(ContactSections.idDetails)}
              onSave={submitSectionEdit}
              restrict={ADD_COMPANY_GUARD}
            >
              <IdDetails contact={contact} />
            </EditBox>
          </ActiveUserBorder>
        </div>

        {/* THIRD COLUMN */}
        <div>
          <ActiveUserBorder collection="companies" id={id} sections={`edit/${ContactSections.assignedCompanies}`}>
            <EditBox
              data={companies}
              disabled={!!editedSection && editedSection !== ContactSections.assignedCompanies}
              editView={EditCompanies}
              editViewProps={{
                currentContact: contact,
                onCompanyRemove: handleCompanyRemove,
                onCompanyAdd: handleCompanyAdd,
              }}
              errors={errors}
              isEditDisabled={false}
              isEditing={editedSection === ContactSections.assignedCompanies}
              name={t("company:Companies")}
              onCancel={cancelSectionEdit}
              onEdit={handleSectionEdit(ContactSections.assignedCompanies)}
              onSave={handleCompaniesSave}
            >
              {companies.map((cc, idx) => (
                <Company company={cc} currentContactId={contact.id} key={cc.id + idx} />
              ))}
            </EditBox>
          </ActiveUserBorder>
        </div>
      </StyledThreeColumnsContainer>
    </>
  );
}
