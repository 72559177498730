import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFirebase } from "hooks/useFirebase";
import { useTransaction } from "hooks/useTransaction";
import { Hedge } from "interfaces/data";
import { getQuantityLeft } from "helpers/transaction";
import { getTimestamp } from "helpers/date";
import { useToast } from "hooks/useToast";
import dayjs from "dayjs";
import { HedgeFormModal } from "./HedgeFormModal/HedgeFormModal";
import { sumLQtyOfHedges } from "helpers/hedge";
import { useIsMounted } from "hooks/useIsMounted";
import { HedgeWithOptionalIdSchema } from "schemas/hedge";

export function HedgeAdd() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id } = useParams<{ id: string }>();
  const { transaction, hedgeAdd, hedgeErrors } = useTransaction({ id });
  const { timestamp, timestampFrom } = useFirebase();
  const [isSending, setIsSending] = useState(false);
  const { isMounted } = useIsMounted();

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newHedge: Partial<Hedge>) => {
      setIsSending(true);
      const hedge = HedgeWithOptionalIdSchema.parse(newHedge);
      hedgeAdd(hedge as Hedge)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("hedge:Hedge has been added successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`hedge:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hedgeAdd, history, id]
  );

  const initialQuantity = useMemo(() => {
    if (!transaction) return 0;
    const initialQuantityValue = getQuantityLeft(transaction) - sumLQtyOfHedges(transaction.hedges);
    return initialQuantityValue > 0 ? initialQuantityValue : 0;
  }, [transaction]);

  const initialHedge = useMemo(() => {
    if (transaction) {
      const transactionEndDate = dayjs(getTimestamp(transaction.end || timestamp()));
      const isTransactionEndSoon = dayjs().add(1, "day").isAfter(transactionEndDate);
      const endDate = isTransactionEndSoon
        ? transaction.end || timestamp()
        : timestampFrom(dayjs().add(1, "day").toDate());
      return {
        start: timestamp(),
        end: endDate,
        quantity: initialQuantity,
        leftQuantity: 0,
        security: 0,
        points: 0,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, initialQuantity]);

  if (!transaction || !initialHedge || !isMounted) {
    return null;
  }

  return (
    <HedgeFormModal
      errors={hedgeErrors}
      initialHedge={initialHedge}
      isDisabled={isSending}
      maxQuantity={initialQuantity}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("hedge:Create new hedge")}
      transaction={transaction}
      type="add"
    />
  );
}
