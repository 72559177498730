import React, { CSSProperties } from "react";
import { Select, SelectProps } from "../Select/Select";
import { StyledGroup, StyledInputGroup } from "./SelectWithLabel.styled";
import { Label } from "components/Label/Label";
import { SelectIcon } from "theme/icons";

export interface SelectGroupProps extends SelectProps {
  placeholder?: string;
  isDisabled?: boolean;
  groupStyles?: CSSProperties;
}

export const SelectWithLabel = ({ label, groupStyles, ...props }: SelectGroupProps): React.ReactElement => (
  <StyledGroup style={groupStyles}>
    {label && <Label>{label}</Label>}
    <StyledInputGroup>
      <Select {...props} icon={<SelectIcon />} iconSize="1rem" pr=".5rem" />
    </StyledInputGroup>
  </StyledGroup>
);
