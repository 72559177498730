import { Company } from "../interfaces/data";
import { UseCompanies } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useCompanies = ({
  phrase,
  fields,
  orderBy,
  orderDirection,
  offset,
  limit,
  skipAutoUpdate,
  isLazy,
  type,
}: UseCompanies) => {
  const {
    data: companies,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Company>({
    collection: "companies",
    phrase,
    fields,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate,
    isLazy,
    type,
  });

  return { companies, totalCount, pageCount, loading, error, refetch };
};
