import { Box } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "../../components/Modal/Modal";
import { GLOBAL_MARGIN_CALL_MAIL, MARGIN_CALL_MAIL } from "../../helpers/mails";
import { useMail } from "../../hooks/useMail";
import { useToast } from "../../hooks/useToast";
import { useTransaction } from "../../hooks/useTransaction";

interface MarginCallResendProps {
  isGmc?: boolean;
}
export const MarginCallResend = ({ isGmc = false }: MarginCallResendProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, marginCallId } = useParams<{
    id: string;
    marginCallId: string;
  }>();
  const { transaction } = useTransaction({ id });
  const sendConfirmation = useMail();
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleResend = useCallback(() => {
    setIsSending(true);

    sendConfirmation(isGmc ? GLOBAL_MARGIN_CALL_MAIL : MARGIN_CALL_MAIL, String(transaction?.id), {
      marginCall: marginCallId,
    });
    toast({
      type: "success",
      message: isGmc
        ? t("marginCall:GMC has been resent successfully")
        : t("marginCall:Margin Call has been resent successfully."),
    });
    setIsSending(false);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleClose, marginCallId]);

  const editedMarginCall = useMemo(() => {
    if (transaction) {
      return transaction.marginCalls?.find((marginCall) => marginCall.id === marginCallId);
    }
  }, [marginCallId, transaction]);

  if (!transaction || !editedMarginCall) return null;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleResend}
      title={t(`marginCall:Resend margin call`)}
    >
      <Box mb={"20px"}>
        {t(`marginCall:Are you sure you want to resend ${isGmc ? "" : "margin call"} {{marginCallId}}?`, {
          marginCallId,
        })}
      </Box>
    </Modal>
  );
};
