import React from "react";
import { getMarginCallBalance } from "helpers/marginCall";
import { Text } from "@chakra-ui/react";
import { formatNumberToString } from "helpers/formatNumber";
import { useNbpRates } from "hooks/useNbpRates";
import { GlobalMarginCall } from "interfaces/data";

export function RemainingCell(arg: any) {
  const { rates: nbpRates } = useNbpRates();
  const marginCall = arg.row.original as GlobalMarginCall;

  if (!marginCall || !nbpRates) return "";

  const paid = getMarginCallBalance(marginCall, nbpRates, marginCall.currency);
  const remainingValue = Number(marginCall.quantity) - Number(paid);

  return (
    <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
      {remainingValue > 0 ? formatNumberToString(remainingValue) : 0} {marginCall.currency}
    </Text>
  );
}
