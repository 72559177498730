import { Payment, Transaction } from "../interfaces/data";
import { formatNumber } from "./formatNumber";
import { DEAL_TYPE_SELL } from "./transaction";

// PAYMENT STATUSES
export const PAYMENT_STATUS_SENT = "isSent";
export const PAYMENT_STATUS_DONE = "isDone";
export const PAYMENT_STATUS_OK = "isReversePaymentOk";

export const calculateProfit = (payment: Payment, nbpRates: any) => {
  const transaction = payment.transaction as Transaction;
  if (!payment || !transaction || !nbpRates) return 0;

  const ratesDifference =
    transaction.dealType === DEAL_TYPE_SELL
      ? Number(transaction.ourRate) - Number(transaction.clientRate)
      : Number(transaction.clientRate) - Number(transaction.ourRate);

  const points = transaction.dealType === DEAL_TYPE_SELL ? -Number(payment.points) : Number(payment.points);

  const paymentPoints = formatNumber(ratesDifference - points, 4);

  const rate = formatNumber(transaction.to.currency === "PLN" ? 1 : nbpRates.rates[transaction.from.currency], 4);

  const profit = paymentPoints * rate * Number(payment.quantity);

  return formatNumber(profit);
};
