import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CloseIcon = createIcon({
  displayName: "CloseIcon",
  path: (
    <g fill="none">
      <path d="M8 0.5L1 7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path d="M1 0.5L8 7.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
    </g>
  ),
  viewBox: "0 0 9 8",
});
