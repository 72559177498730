import React from "react";
import styled from "@emotion/styled";
import { Box, BoxProps } from "@chakra-ui/react";

interface TagProps extends BoxProps {
  color: "grey" | "green";
  content: React.ReactNode;
}

const StyledContainer = styled(Box)<{
  color: TagProps["color"];
}>`
  background: ${(props) =>
    props.color === "green" ? props.theme.colors["sk-light-green"] : props.theme.colors["sk-light-gray"]};
  color: ${(props) => (props.color === "green" ? "white" : "#9b9b9c")};
  display: inline-flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 800;
  font-size: 11px;
  line-height: 110%;
  border-radius: 16px;
`;

export const Tag: React.FunctionComponent<TagProps> = ({ color, content, ...props }) => {
  return (
    <StyledContainer color={color} {...props}>
      {content}
    </StyledContainer>
  );
};
