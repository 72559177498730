import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { Modal } from "components/Modal/Modal";
import { formatDate } from "helpers/date";
import { formatNumberToString } from "helpers/formatNumber";
import { CURRENCIES } from "helpers/options";
import { determineTransactionCurrency } from "helpers/transaction";
import { useFirebase } from "hooks/useFirebase";
import { Company, Currency, Margin, Transaction } from "interfaces/data";
import { Checkbox } from "components/Checkbox/Checkbox";
import {
  getMarginCallBalance,
  getSumsOfCurrenciesInMarginCall,
  moveQuantityFromGlobalMarginToLocalAndToMarginCall,
  moveQuantityFromMarginToMarginCall,
} from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import {
  StyledLabel,
  StyledMarginsList,
  StyledMarginsListItem,
  StyledShowAllButton,
  StyledValue,
} from "./MarginCallSettlementModal.styled";
import { getCrossRate } from "helpers/rates";
import { useFormEvents } from "hooks/useFormEvents";

interface MarginCallMarginAddModalProps {
  transaction: Transaction;
  company: Company;
  marginCallId: string;
  onClose: () => void;
  onSave: ({
    updatedTransaction,
    updatedCompany,
  }: {
    updatedTransaction: Transaction;
    updatedCompany: Company;
  }) => void;
}

export const MarginCallMarginAddModal: React.FC<MarginCallMarginAddModalProps> = ({
  transaction,
  company,
  marginCallId,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const { timestamp } = useFirebase();

  const initialVM: Margin = useMemo(() => {
    return {
      id: "",
      date: timestamp(),
      type: "VM",
      from: {
        currency: determineTransactionCurrency(transaction),
        quantity: 0,
      },
      to: {
        currency: determineTransactionCurrency(transaction),
        quantity: 0,
      },
      left: {
        currency: determineTransactionCurrency(transaction),
        quantity: 0,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const [editedTransaction, setEditedTransaction] = useState<Transaction>(transaction);
  const [editedCompany, setEditedCompany] = useState<Company>(company);
  const [newMargin, setNewMargin] = useState<Margin>(initialVM);
  const [newMarginsList, setNewMarginsList] = useState<Array<Margin>>([]);
  const [selectedMargin, setSelectedMargin] = useState<Margin>();
  const [isExpanded, setExpanded] = useState(false);
  const [useExisting, setUseExisting] = useState(false);
  const [quantity, setQuantity] = useState<string | undefined>(undefined);
  const { rates: nbpRates } = useNbpRates();
  const [clearSpacesOnPaste] = useFormEvents();

  const numberOfCollapse = 5;

  useEffect(() => {
    setEditedTransaction(transaction);
  }, [transaction]);

  const editedMarginCall = useMemo(() => {
    return editedTransaction.marginCalls?.find((mc) => mc.id === marginCallId);
  }, [editedTransaction.marginCalls, marginCallId]);

  const generateInnerId = useCallback((): number => {
    if (!editedTransaction || !editedTransaction.margins) return 1;
    const maxExistingId = Math.max(...editedTransaction.margins.map((item) => Number(item.id?.split("-")[1])), 0);
    return maxExistingId + 1;
  }, [editedTransaction]);

  const handleQtyUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const updatedNewMargin = _cloneDeep(newMargin);
      updatedNewMargin.from.quantity = Number(e.target.value);
      updatedNewMargin.to.quantity =
        Number(e.target.value) *
        getCrossRate(updatedNewMargin.from.currency, determineTransactionCurrency(transaction), nbpRates);
      updatedNewMargin.left.quantity = Number(e.target.value);
      setNewMargin(updatedNewMargin);
    },
    [nbpRates, newMargin, transaction]
  );

  const handleCurrencyUpdate = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      e.persist();
      const updatedNewMargin = _cloneDeep(newMargin);
      updatedNewMargin.from.currency = e.target.value as Currency;
      updatedNewMargin.to.currency = determineTransactionCurrency(transaction) as Currency;
      updatedNewMargin.to.quantity =
        Number(updatedNewMargin.to.quantity) *
        getCrossRate(updatedNewMargin.from.currency, determineTransactionCurrency(transaction), nbpRates);
      updatedNewMargin.left.currency = e.target.value as Currency;
      setNewMargin(updatedNewMargin);
    },
    [nbpRates, newMargin, transaction]
  );

  const handleNewMarginAdd = useCallback(() => {
    if (newMargin.from.quantity <= 0) return;

    const updatedNewMarginsList = _cloneDeep(newMarginsList);
    const updatedNewMargin = _cloneDeep(newMargin);
    const newEditedTransaction = _cloneDeep(editedTransaction);
    updatedNewMargin.id = `${newEditedTransaction.number}-${generateInnerId()}`;
    if (!newEditedTransaction.margins) {
      newEditedTransaction.margins = [];
    }
    updatedNewMarginsList.push(updatedNewMargin);
    newEditedTransaction.margins.push(updatedNewMargin);
    setNewMargin(initialVM);
    setNewMarginsList(updatedNewMarginsList);

    if (!editedCompany.paymentEntries) {
      editedCompany.paymentEntries = [];
    }
    // Add Payment Entry
    editedCompany.paymentEntries.push({
      amount: updatedNewMargin.from.quantity,
      currency: updatedNewMargin.from.currency,
      marginId: marginCallId,
      date: updatedNewMargin.date,
    });

    const { newTransaction } = moveQuantityFromMarginToMarginCall({
      transaction: newEditedTransaction,
      company: editedCompany,
      marginCallId: marginCallId,
      marginId: updatedNewMargin.id,
      quantity: Number(updatedNewMargin.from.quantity),
      currency: updatedNewMargin.from.currency,
      nbpRates: nbpRates,
    });

    setEditedTransaction(newTransaction);
  }, [editedCompany, editedTransaction, generateInnerId, initialVM, marginCallId, newMargin, newMarginsList, nbpRates]);

  const handleMarginSettlement = useCallback(() => {
    if (!editedTransaction || !selectedMargin || !quantity) return;

    // if it's global margin, create a local VM, transfer to it the money  and assign it to the margin call
    if (selectedMargin.id.startsWith("g")) {
      if (selectedMargin.from.quantity <= 0) return;

      const updatedNewMargin = _cloneDeep(initialVM);
      const newEditedTransaction = _cloneDeep(editedTransaction);
      updatedNewMargin.id = `${newEditedTransaction.number}-${generateInnerId()}`;
      if (!newEditedTransaction.margins) {
        newEditedTransaction.margins = [];
      }

      newEditedTransaction.margins.push(updatedNewMargin);

      const { newTransaction, newCompany } = moveQuantityFromGlobalMarginToLocalAndToMarginCall({
        transaction: newEditedTransaction,
        company: editedCompany,
        marginCallId: marginCallId,
        globalMarginId: selectedMargin.id,
        marginId: updatedNewMargin.id,
        quantity: Number(quantity),
        currency: updatedNewMargin.from.currency,
        nbpRates: nbpRates,
      });

      setEditedTransaction(newTransaction);
      setEditedCompany(newCompany);
      return;
    }

    const { newTransaction, newCompany } = moveQuantityFromMarginToMarginCall({
      transaction: editedTransaction,
      company: editedCompany,
      marginCallId: marginCallId,
      marginId: selectedMargin.id,
      quantity: Number(quantity),
      currency: selectedMargin.from.currency,
      nbpRates: nbpRates,
    });

    setEditedTransaction(newTransaction);
    setEditedCompany(newCompany);
    setSelectedMargin(undefined);
    setQuantity(undefined);
  }, [editedCompany, editedTransaction, marginCallId, quantity, selectedMargin, nbpRates, generateInnerId, initialVM]);

  const isValueValid = useMemo(() => {
    if (!quantity || !selectedMargin) return true;
    return Number(quantity) > 0 && Number(quantity) <= Number(selectedMargin?.left.quantity);
  }, [quantity, selectedMargin]);

  const handleSaveAction = useCallback(() => {
    if (!editedTransaction || !editedCompany) return;
    onSave({
      updatedTransaction: editedTransaction,
      updatedCompany: editedCompany,
    });
  }, [editedTransaction, editedCompany, onSave]);

  const availableMargins = useMemo(() => {
    if (!editedTransaction) return [];
    const transactionMargins =
      editedTransaction.margins?.filter((margin) => margin.type === "VM" && Number(margin.left.quantity) > 0) || [];
    const globalMargins = editedCompany.globalMargins?.filter((globalMargin) => globalMargin.left.quantity > 0) || [];
    return [...transactionMargins.reverse(), ...globalMargins] as Array<Margin>;
  }, [editedCompany.globalMargins, editedTransaction]);

  const visibleAvailableMargins = useMemo(() => {
    if (isExpanded) {
      return availableMargins;
    }
    return availableMargins.slice(0, numberOfCollapse);
  }, [availableMargins, isExpanded]);

  const coveredValueInCurrencies = useMemo(() => {
    if (!editedMarginCall) return [];
    return getSumsOfCurrenciesInMarginCall(editedMarginCall);
  }, [editedMarginCall]);

  if (!editedMarginCall || !nbpRates) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isOpen
      justifyButtons="flex-end"
      minWidth={840}
      onClose={() => onClose()}
      onConfirm={handleSaveAction}
      title={t(`marginCall:Margin Call, ID #{{marginCallId}} - add`, {
        marginCallId,
      })}
    >
      <Box display="flex" mb="10px">
        <Input
          error={newMargin?.from.quantity < 0 ? "error" : undefined}
          formatNumberOnBlur
          hideErrorMessage
          id="from.quantity"
          isDisabled={useExisting}
          label="Quantity"
          name="from.quantity"
          onChange={handleQtyUpdate}
          onPaste={clearSpacesOnPaste}
          topLabel
          type="number"
          value={newMargin?.from.quantity || ""}
          withError={newMargin?.from.quantity < 0}
        />
        <Box ml="10px">
          <AutoCompleteSelect
            id="from.currency"
            isDisabled={useExisting}
            name="from.currency"
            onChange={handleCurrencyUpdate}
            options={CURRENCIES}
            value={newMargin?.from.currency}
            width="85px"
          />
        </Box>
        <Button
          design="primary"
          height="42px"
          isDisabled={!isValueValid || useExisting || newMargin?.from.quantity < 0}
          ml="30px"
          onClick={handleNewMarginAdd}
        >
          {t("Add new")}
        </Button>
      </Box>
      <Box mb="10px" mt="20px">
        <Checkbox fullWidth isChecked={useExisting} onChange={(e) => setUseExisting(e.target.checked)}>
          Use existing Variation Margin
        </Checkbox>
      </Box>
      {useExisting && (
        <StyledMarginsList>
          {visibleAvailableMargins.map((margin) => (
            <StyledMarginsListItem
              key={margin.id}
              onClick={(e) => {
                setSelectedMargin(selectedMargin && selectedMargin.id === margin.id ? undefined : margin);
                e.stopPropagation();
              }}
            >
              <Box display="flex">
                <Checkbox
                  checkboxSize="small"
                  isChecked={selectedMargin && selectedMargin.id === margin.id}
                  isReadOnly
                  mr="10px"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
                <Box pr="15px" textAlign="right" w="100px">
                  {formatDate(margin.date)}
                </Box>
                <Box fontWeight="bold" pr="15px">
                  Qty: {formatNumberToString(margin.from.quantity)}
                  {margin.from.currency}
                </Box>
                <Box>{margin.id}</Box>
              </Box>
            </StyledMarginsListItem>
          ))}
          {availableMargins.length > numberOfCollapse && !isExpanded && (
            <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
              {t("Show all")}
            </StyledShowAllButton>
          )}
        </StyledMarginsList>
      )}

      {selectedMargin && (
        <Box display="flex" mb="10px" mt="30px">
          <Input
            error={!isValueValid ? "error" : undefined}
            formatNumberOnBlur
            hideErrorMessage
            id="quantity"
            label="Quantity"
            name="quantity"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setQuantity(e.target.value)}
            type="number"
            value={quantity || ""}
            withError={!isValueValid}
          />
          <Box ml="10px">
            <AutoCompleteSelect
              id="from.currency"
              isDisabled
              name="from.currency"
              options={CURRENCIES}
              value={selectedMargin.from.currency}
              width="85px"
            />
          </Box>
          <Button design="primary" height="42px" isDisabled={!isValueValid} ml="30px" onClick={handleMarginSettlement}>
            {t("Add from selected VM")}
          </Button>
        </Box>
      )}

      <Box alignItems="flex-end" display="flex" flexDirection="column">
        {Boolean(newMarginsList?.length) &&
          newMarginsList.map((newMargin) => (
            <Box display="inline-block" key={newMargin.id}>
              <StyledLabel>Added</StyledLabel>
              <StyledValue addition>
                +{formatNumberToString(Number(newMargin.from.quantity), 0 || 0)}
                &nbsp;{newMargin.from.currency}
              </StyledValue>
            </Box>
          ))}
      </Box>

      <Box display="flex" justifyContent="flex-end">
        {coveredValueInCurrencies.map((value, i) => (
          <Box display="inline-block" key={i} ml="20px">
            <StyledLabel>{value.currency}</StyledLabel>
            <StyledValue>{formatNumberToString(value.sum)}</StyledValue>
          </Box>
        ))}
        <Box display="inline-block" ml="20px">
          <StyledLabel>Balance</StyledLabel>
          <StyledValue>{formatNumberToString(getMarginCallBalance(editedMarginCall, nbpRates))} PLN</StyledValue>
        </Box>
      </Box>
    </Modal>
  );
};
