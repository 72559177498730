import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { Button } from "components/Button/Button";
import { useResetPassword } from "hooks/useResetPassword";
import { useTranslation } from "react-i18next";

type Inputs = {
  password: string;
  confirmPassword: string;
};

const ConfirmResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const ERROR_MESSAGES: { [key: string]: string } = {
    "auth/weak-password": "weakPassword",
    "auth/expired-action-code": "expiredActionCode",
    "auth/invalid-action-code": "invalidActionCode",
  };
  const [error, setError] = React.useState<string>("");
  const { confirmResetPassword } = useResetPassword();

  const handleSubmit = React.useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data: Inputs = {
      password: target.password.value,
      confirmPassword: target.confirmPassword.value,
    };

    if (data.password !== data.confirmPassword) {
      setError("samePasswords");
      return;
    }

    const params = new URLSearchParams(window.location.search);
    const code = params.get("oobCode");

    if (!code) {
      setError("missingCode");
      return;
    }

    confirmResetPassword(code, data.password).then((err) => {
      if (err) {
        const message = ERROR_MESSAGES[err.code];

        setError(message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="xl">
      <Heading as="h1" fontSize="42px" fontWeight="bold" mb={12}>
        {t("login:Set new password")}
      </Heading>
      <form onSubmit={handleSubmit}>
        <Input id="new-password" label="Enter new password" name="password" type="password" withError />
        <Input
          error={error}
          id="confirm-new-password"
          label="Repeat new password"
          name="confirmPassword"
          type="password"
          withError
        />

        <Button design="primary" type="submit">
          {t("login:Save new password")}
        </Button>
      </form>
    </Box>
  );
};

export default ConfirmResetPassword;
