import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { MARGIN_CALL_MAIL } from "helpers/mails";
import { useCompany } from "hooks/useCompany";
import { useFirebase } from "hooks/useFirebase";
import { useMail } from "hooks/useMail";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { MarginCall } from "interfaces/data";
import { MarginCallFormModal } from "views/MarginCall/MarginCallFormModal";
import { ZodOptionalIDMarginCall } from "schemas/marginCall";

export function CompanyMarginCallAdd() {
  const { timestamp } = useFirebase();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { marginCallAdd, errors } = useTransaction({ skipFetching: true });
  const history = useHistory();
  const toast = useToast();
  const [isSending, setIsSending] = useState(false);
  const { company } = useCompany({ id });
  const sendConfirmation = useMail();

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newMarginCall: ZodOptionalIDMarginCall) => {
      setIsSending(true);
      marginCallAdd(newMarginCall as MarginCall)
        .then((data) => {
          setIsSending(false);
          if (data) {
            sendConfirmation(MARGIN_CALL_MAIL, data.transaction, {
              marginCall: data.marginCall,
            });
            toast({
              type: "success",
              message: t("marginCall:Margin Call has been added successfully"),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`marginCall:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClose, marginCallAdd]
  );

  if (!company) return null;

  return (
    <MarginCallFormModal
      errors={errors}
      initialCompany={company}
      initialMarginCall={{
        callDate: timestamp(),
        isClosed: "no",
      }}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("marginCall:New Margin Call")}
      type="add"
    />
  );
}
