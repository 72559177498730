import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

import { StyledLabel } from "./Label.styled";

export const Label: React.FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Box as={StyledLabel} {...props}>
      {children}
    </Box>
  );
};
