import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import firebase from "firebase";

export const DISPLAY_DATE_FORMAT = "DD.MM.YYYY";
export const FORM_DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm:ss";
export const FORM_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

dayjs.extend(duration);

type DbTimestamp = { _seconds: number; _nanoseconds: number } | firebase.firestore.Timestamp;

export const formatDate = (date?: DbTimestamp | "", format = FORM_DATE_FORMAT): string => {
  if (!date) return "";
  const timestamp = createFirestoreTimestamp(date);
  return dayjs(timestamp.toDate()).format(format);
};

export const formatDateTime = (date?: DbTimestamp, format = FORM_DATE_TIME_FORMAT): string => formatDate(date, format);

export const getTimestamp = ({ seconds }: firebase.firestore.Timestamp): number => seconds * 1000;

export const getTomorrow = () => dayjs().add(1, "day").toDate();

export const transformTimestamp = (arg: any, format = DISPLAY_DATE_FORMAT): string => {
  if (!arg || !(arg._seconds || arg.seconds)) {
    return "-";
  }
  const seconds = arg._seconds ? arg._seconds : arg.seconds;
  const date = dayjs(seconds * 1000);
  return date.format(format);
};

export const transformTimestampToDate = (arg: any, format = FORM_DATE_FORMAT): string => {
  if (!arg || !(arg._seconds || arg.seconds)) {
    return "-";
  }
  const seconds = arg._seconds ? arg._seconds : arg.seconds;
  const date = dayjs(seconds * 1000);
  return date.format(format);
};

export const getMilisecondsFromTimestamp = (arg: any): number => {
  if (!arg || !(arg._seconds || arg.seconds)) {
    return NaN;
  }
  const seconds = arg._seconds ? arg._seconds : arg.seconds;
  return seconds * 1000;
};

export const getDurationFromTimestamps = (start: any, end: any): number => {
  const _start = getMilisecondsFromTimestamp(start);
  const _end = getMilisecondsFromTimestamp(end);

  return dayjs.duration(dayjs(_start).diff(dayjs(_end))).asMilliseconds();
};

export const createFirestoreTimestamp = (date: DbTimestamp): firebase.firestore.Timestamp => {
  if (date instanceof firebase.firestore.Timestamp) {
    return date;
  }
  return new firebase.firestore.Timestamp(date._seconds, date._nanoseconds);
};

export const convertTimestampToDayjs = (date: DbTimestamp): dayjs.Dayjs => {
  const timestamp = createFirestoreTimestamp(date);
  return dayjs(timestamp.toDate());
};
