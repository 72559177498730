import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const StyledContainer = styled.div`
  position: relative;
  margin-top: auto;
  padding: 0 20px 20px 20px;
  width: 100%;
  min-height: 55px;
  border-top: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
`;

export const StyledActivityContainer = styled.div`
  display: flex;
  font-size: 10px;
  padding: 6px 0;
`;

export const StyledSectionHeader = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  min-width: 30px;
  padding-right: 5px;
`;

export const StyledMoreInfo = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
`;

export const StyledButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
`;
