import { Transaction } from "interfaces/data";
import React from "react";
import { getQuantityLeft } from "helpers/transaction";
import { formatNumberToString } from "helpers/formatNumber";

export function QuantityLeftCell(arg: any) {
  const transaction = arg.row.original as Transaction;
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {formatNumberToString(getQuantityLeft(transaction))}
      &nbsp;
      {transaction.from.currency}
    </div>
  );
}
