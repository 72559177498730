import React, { useCallback, useState } from "react";
import { Grid } from "@chakra-ui/react";
import _set from "lodash.set";
import { useTranslation } from "react-i18next";
import { Input } from "components/Input/Input";
import { Modal } from "components/Modal/Modal";
import { Select } from "components/Select/Select";
import { USER_ROLES } from "helpers/options";
import { BaseProfile, UserRole } from "interfaces/data";
import { useProfiles } from "hooks/useProfiles";
import { useToast } from "hooks/useToast";

const initialProfile: BaseProfile = {
  name: "",
  surname: "",
  email: "",
  userId: "",
  role: UserRole.OFFICE_MANAGER,
};

interface InviteUserProps {
  isOpen: boolean;
  onClose: () => any;
  onSuccess: () => any;
}

export const InviteUser: React.FC<InviteUserProps> = ({ isOpen, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { create, errors } = useProfiles();
  const [profile, setProfile] = useState(initialProfile);
  const toast = useToast();

  const handleInvite = useCallback(async () => {
    try {
      const result = await create(profile);
      if (result) {
        toast({
          type: "success",
          message: t("settings:Invitation has been sent to {{email}}.", {
            email: profile.email,
          }),
        });
        setProfile(initialProfile);
        onSuccess();
      }
    } catch (error) {
      if (error instanceof Error) {
        toast({
          type: "error",
          message: t(`settings:${error.message}`),
        });
      }
    }
  }, [create, onSuccess, profile, t, toast]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setProfile((oldEditedData: BaseProfile) => {
      const newEditedData = { ...oldEditedData };
      _set(newEditedData, e.target.name, e.target.value);
      return newEditedData;
    });
  }, []);

  const handleClose = useCallback(() => {
    setProfile(initialProfile);
    onClose();
  }, [onClose]);

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isOpen={isOpen}
      justifyButtons="flex-end"
      minWidth={800}
      onClose={handleClose}
      onConfirm={handleInvite}
      title="Invite member"
    >
      <Grid columnGap="30px" gridTemplateColumns="1fr 1fr" rowGap="10px">
        <Input
          error={errors?.["name"]}
          id="name"
          label={t("settings:First name")}
          name="name"
          onChange={handleChange}
          type="text"
          value={profile.name}
          withError={errors && !!errors["name"]}
        />
        <Input
          error={errors?.["surname"]}
          id="surname"
          label={t("settings:Last name")}
          name="surname"
          onChange={handleChange}
          type="text"
          value={profile.surname}
          withError={errors && !!errors["surname"]}
        />
        <Input
          error={errors?.["email"]}
          id="email"
          label={t("settings:Email")}
          name="email"
          onChange={handleChange}
          type="email"
          value={profile.email}
          withError={errors && !!errors["email"]}
        />
        <Select
          error={errors?.["role"]}
          id="role"
          label={t("settings:Permissions")}
          name="role"
          onChange={handleChange}
          options={USER_ROLES}
          value={profile.role}
        />
      </Grid>
    </Modal>
  );
};
