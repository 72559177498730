import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Switch, Text } from "@chakra-ui/react";
import { Table } from "components/Table/Table";
import { Currency, MarginCall, MarginCallExtended } from "interfaces/data";
import {
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
  StyledSectionOption,
  StyledSectionOptions,
} from "./shared.styled";
import { DateCell } from "helpers/tableCells";
import { Link, useHistory, useParams } from "react-router-dom";
import { formatNumberToString } from "helpers/formatNumber";
import styled from "@emotion/styled";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import { useNbpRates } from "hooks/useNbpRates";
import { getMarginCallBalance } from "helpers/marginCall";
import { formatDate, getMilisecondsFromTimestamp } from "helpers/date";
import { StyledButtonsContainer, StyledIconContainer } from "views/Company360/Main/components/margin.styled";
import { StyledTag } from "views/MarginCalls/components/styles";
import { AddIcon, CancelIcon, EditIcon, NoteIcon, PaperPlaneIcon, RefreshIcon, RemoveIcon } from "theme/icons";
import { MinusIcon, SmallAddIcon, WarningIcon } from "@chakra-ui/icons";

interface MarginCallsProps {
  marginCalls?: Array<MarginCall>;
  currency: Currency;
  isDisabled?: boolean;
  transactionId: string;
  transactionCurrency: Currency;
  ignoreMarginCallRisk: boolean;
  isWithGMC: boolean;
  onIgnoreMarginCallRiskUpdate: (newValue: boolean) => void;
}

export const StyledAdditionalIdCell = styled.span`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const StyledSwitch = styled(Switch)`
  input[type="checkbox"] + div:first-of-type {
    background: ${(props) => props.theme.colors["sk-light-gray"]};
    padding: 0;
  }

  input[type="checkbox"] + div:first-of-type > div {
    border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  }

  input[type="checkbox"]:checked + div:first-of-type {
    background: ${(props) => props.theme.colors["sk-purple"]};
  }
`;

const StyledTableContainer = styled.div`
  tr.closed-row {
    opacity: 0.5;
  }
  tr.global-row {
    outline: 5px solid ${({ theme }) => theme.colors["sk-blue"]};
    outline-offset: -5px;
    background: ${(props) => `${props.theme.colors["sk-blue"]}25`};
    > * {
      font-weight: 700;
    }
  }
  tr.global-row .checkbox-td label {
    visibility: hidden;
  }
  tr td:last-child {
    width: 60px;
  }
`;

export function IdCell(arg: any) {
  const marginCall = arg.row.original as MarginCall;
  if (!marginCall) return "";
  const comment = marginCall.comment;
  const isAdditional = marginCall.createdFromId && marginCall.createdFromId.length > 0;
  const isFinalCall = Boolean(marginCall.finalNoticeSentAt);

  return (
    <Box display="flex" whiteSpace="nowrap">
      {marginCall.id}
      {isAdditional && (
        <StyledAdditionalIdCell>
          , <RefreshIcon mr="5px" mt="-2px" />
          {marginCall.createdFromId}
        </StyledAdditionalIdCell>
      )}
      {isFinalCall && (
        <Box
          alignItems="center"
          display="flex"
          ml="5px"
          title={`Final call sent: ${formatDate(marginCall.finalNoticeSentAt)}`}
        >
          <WarningIcon color="sk-red" mr="11px" />
        </Box>
      )}
      {comment && (
        <Box alignItems="center" display="flex" ml="5px" title={comment}>
          <NoteIcon height="11px" />
        </Box>
      )}
    </Box>
  );
}

export const MarginCalls: React.FC<MarginCallsProps> = ({
  marginCalls = [],
  transactionId,
  currency,
  isDisabled = false,
  transactionCurrency,
  ignoreMarginCallRisk,
  isWithGMC = false,
  onIgnoreMarginCallRiskUpdate,
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { rates: nbpRates } = useNbpRates();

  const [selectedRow, setSelectedRow] = useState<MarginCall>();

  const StatusCell = useCallback(
    (arg: any, currency: Currency) => {
      const marginCall = arg.row.original as MarginCall;
      if (!marginCall || !nbpRates) return "";

      if (marginCall.isClosed === "yes") {
        return <b>closed</b>;
      }

      const paid = getMarginCallBalance(marginCall, nbpRates, currency);

      if (paid === 0) {
        return <StyledTag color="sk-red">unpaid</StyledTag>;
      }
      if (paid >= marginCall.quantity) {
        return <StyledTag color="sk-light-green">ok</StyledTag>;
      }
      return <StyledTag color="sk-yellow">partially</StyledTag>;
    },
    [nbpRates]
  );

  const PaidCell = useCallback(
    (arg: any, currency: Currency) => {
      const marginCall = arg.row.original as MarginCall;
      if (!marginCall || !nbpRates) return "";

      const paid = getMarginCallBalance(marginCall, nbpRates, currency);

      return (
        <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
          {formatNumberToString(paid)} {currency}
        </Text>
      );
    },
    [nbpRates]
  );

  const RemainingCell = useCallback(
    (arg: any, currency: Currency) => {
      const marginCall = arg.row.original as MarginCall;
      if (!marginCall || !nbpRates) return "";

      const paid = getMarginCallBalance(marginCall, nbpRates, currency);

      const remainingValue = Number(marginCall.quantity) - Number(paid);
      return (
        <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
          {remainingValue > 0 ? formatNumberToString(remainingValue) : 0} {currency}
        </Text>
      );
    },
    [nbpRates]
  );

  const EditCell = useCallback(
    (arg: any) => {
      const marginCall = arg.row.original as MarginCall;
      if (!marginCall || marginCall.isClosed === "yes" || marginCall.isGMC === "yes") return "";

      return (
        <RestrictAccess area={ADD_MARGIN_CALLS}>
          <StyledButtonsContainer>
            <Link onClick={(e) => e.stopPropagation()} to={`/transactions/${id}/margin-calls/${marginCall.id}/add`}>
              <StyledIconContainer>
                <SmallAddIcon boxSize="22px" />
              </StyledIconContainer>
            </Link>
            <Link
              onClick={(e) => e.stopPropagation()}
              to={`/transactions/${id}/margin-calls/${marginCall.id}/withdraw`}
            >
              <StyledIconContainer>
                <MinusIcon />
              </StyledIconContainer>
            </Link>
          </StyledButtonsContainer>
        </RestrictAccess>
      );
    },
    [id]
  );

  const TransactionIdsCell = useCallback((arg: any) => {
    const margin = arg.row.original as MarginCallExtended;
    if (margin.isGMC !== "yes" || margin?.globalTransactionsIds?.length === 0) return "-";
    return (
      <Box>
        {margin.globalTransactionsIds?.map((gmc) => (
          <Link key={gmc.id} to={`/transactions/${gmc.id}`}>
            <Box as="span" display="block" whiteSpace="nowrap">
              #{gmc.number}
            </Box>
          </Link>
        ))}
      </Box>
    );
  }, []);

  const sortedMarginCalls = useMemo(() => {
    const originalMarginCalls = marginCalls.filter((marginCall) => !marginCall.createdFromId);
    const additionalMarginCalls = marginCalls.filter((marginCall) => marginCall.createdFromId);
    additionalMarginCalls.forEach((additionalMarginCall) => {
      const originalIndex = originalMarginCalls.findIndex(
        (originalMarginCall) => additionalMarginCall.createdFromId === originalMarginCall.id
      );
      if (originalIndex !== -1) {
        originalMarginCalls.splice(originalIndex + 1, 0, additionalMarginCall);
      }
    });
    return originalMarginCalls.sort(
      (prevElement, nextElement) =>
        getMilisecondsFromTimestamp(nextElement.callDate) - getMilisecondsFromTimestamp(prevElement.callDate)
    );
  }, [marginCalls]);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("Transactions IDs"),
        accessor: "globalTransactionsIds",
        Cell: TransactionIdsCell,
      },
      {
        Header: t("Call date"),
        accessor: "callDate",
        Cell: DateCell,
      },
      {
        Header: t("Call rate"),
        accessor: "callRate",
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
            {t("Quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
            {formatNumberToString(arg.value)} {currency}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
            {t("Trans Qty")}
          </Text>
        ),
        accessor: "transQuantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
            {formatNumberToString(arg.value)} {transactionCurrency}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap">
            {t("Paid")}
          </Text>
        ),
        accessor: "paid",
        Cell: (arg: any) => PaidCell(arg, currency),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Remaining")}
          </Text>
        ),
        accessor: "remaining",
        Cell: (arg: any) => RemainingCell(arg, currency),
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: (arg: any) => StatusCell(arg, currency),
      },
      {
        Header: "",
        accessor: "edit",
        Cell: EditCell,
      },
    ],
    [EditCell, PaidCell, RemainingCell, StatusCell, TransactionIdsCell, currency, t, transactionCurrency]
  );

  const handleFinalCall = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/final-call`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleAdditionalMarginCall = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/add-additional`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleResend = useCallback(() => {
    if (!selectedRow) return;
    history.push(
      `/transactions/${id}/${selectedRow.isGMC === "yes" ? "global-margin-call" : "margin-calls"}/${
        selectedRow.id
      }/resend`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleCancel = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/cancel`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleClose = useCallback(() => {
    if (!selectedRow) return;
    history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/close`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleEdit = useCallback(() => {
    if (!selectedRow) return;
    if (selectedRow.createdFromId) {
      history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/edit-additional`);
    } else {
      history.push(`/transactions/${id}/margin-calls/${selectedRow.id}/edit`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleAdd = useCallback(() => {
    if (isDisabled) return;
    history.push(`/transactions/${id}/margin-calls/add`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, id]);

  return (
    <StyledContainer>
      <ActiveUserBorder collection="transactions" id={transactionId} sections="margin-calls">
        <StyledSectionHeader>
          <StyledSectionName>{t("transaction:Margin Calls")}</StyledSectionName>
          <Box ml="20px">
            <StyledSwitch
              isChecked={ignoreMarginCallRisk}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.persist();
                onIgnoreMarginCallRiskUpdate(e.target.checked);
              }}
            />
            <Box display="inline-block" fontSize="14px" ml="10px">
              {t("transaction:No MC")}
            </Box>
          </Box>
          <RestrictAccess area={ADD_MARGIN_CALLS}>
            <StyledSectionOptions>
              <StyledSectionOption
                isActive={!!selectedRow && !selectedRow.isGMC && !selectedRow.finalNoticeSentAt}
                onClick={handleFinalCall}
              >
                <WarningIcon mr="10px" /> {t("marginCall:Final Call")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow && !selectedRow.isGMC} onClick={handleAdditionalMarginCall}>
                <RefreshIcon mr="10px" /> {t("marginCall:Additional MC")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow && !selectedRow.isGMC} onClick={handleResend}>
                <PaperPlaneIcon mr="10px" /> {t("Resend")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow && !selectedRow.isGMC} onClick={handleCancel}>
                <RemoveIcon mr="10px" /> {t("Cancel")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow && !selectedRow.isGMC} onClick={handleClose}>
                <CancelIcon mr="10px" /> {t("Close")}
              </StyledSectionOption>
              <StyledSectionOption isActive={!!selectedRow && !selectedRow.isGMC} onClick={handleEdit}>
                <EditIcon mr="10px" /> {t("Edit")}
              </StyledSectionOption>
              <StyledSectionOption
                isActive={!isDisabled && !isWithGMC}
                onClick={() => (isWithGMC ? null : handleAdd())}
              >
                <AddIcon mr="10px" /> {t("Add")}
              </StyledSectionOption>
            </StyledSectionOptions>
          </RestrictAccess>
        </StyledSectionHeader>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={sortedMarginCalls}
            getRowProps={(rowInfo) => {
              const marginCall = rowInfo.original as MarginCall;
              return {
                className: [
                  marginCall.isClosed === "yes" ? "closed-row" : null,
                  marginCall.isGMC === "yes" ? "global-row" : null,
                ]
                  .filter(Boolean)
                  .join(" "),
              };
            }}
            onRowSelected={setSelectedRow}
            paginated={false}
            selectableRows={!isDisabled}
          />
        </StyledTableContainer>
      </ActiveUserBorder>
    </StyledContainer>
  );
};
