export const getFontColor = (available: number, isUnlimited: boolean) => {
  switch (true) {
    case available <= 80 || isUnlimited:
      return "#5BE12C";
    case available > 80 && available <= 90:
      return "#F5CD19";
    case available > 90:
    default:
      return "#EA4228";
  }
};
