import React from "react";
import { NavigationItem } from "../Navigation/Navigation";
import { navigationItems } from "../Navigation/Navigation.utils";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RestrictAccess } from "../RestrictAccess/RestrictAccess";
import { GlobalSearch } from "./GlobalSearch";
import { LastActivityWidget } from "./LastActivityWidget";
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  position: fixed;
  width: 263px;
  height: calc(100% - 122px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  top: 122px;
`;

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <div>
        <GlobalSearch />
        {navigationItems.map((navigation, i) => (
          <RestrictAccess area={navigation.guard} key={i}>
            <NavigationItem
              href={navigation.path}
              icon={navigation.icon}
              isActive={location.pathname.startsWith(navigation.path)}
            >
              {t(`sidebar:${navigation.name}`)}
            </NavigationItem>
          </RestrictAccess>
        ))}
      </div>
      <LastActivityWidget />
    </StyledContainer>
  );
};
