import React from "react";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { Contact } from "../../../interfaces/data";
import { EditContactAddress } from "../../Contact/ContactAddress/EditContactAddress";
import { StyledAddressHeader } from "./SingleContact";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Input } from "../../../components/Input/Input";
import { CONTACT_ID_TYPES, CONTACT_ROLES, COUNTRIES } from "../../../helpers/options";
import { Select } from "../../../components/Select/Select";

interface EditSingleContactProps extends EditViewProps<Contact> {
  showAddress?: boolean;
  showOther?: boolean;
  showCitizenship?: boolean;
  hidePersonalDetails?: boolean;
}

export function EditSingleContact({
  showAddress,
  showOther,
  showCitizenship,
  hidePersonalDetails,
  ...props
}: EditSingleContactProps) {
  const { t } = useTranslation();

  return (
    <>
      <TwoColumnsLayout>
        <Input
          error={props.errors?.firstName}
          id="firstName"
          label={t("contact:Name")}
          name="firstName"
          onChange={props.handleChange}
          type="text"
          value={props.data.firstName}
          withError={!!props.errors?.firstName}
        />
        <Input
          error={props.errors?.lastName}
          id="lastName"
          label={t("contact:Surname")}
          name="lastName"
          onChange={props.handleChange}
          type="text"
          value={props.data.lastName}
          withError={!!props.errors?.lastName}
        />
        {!hidePersonalDetails && (
          <>
            <Input
              error={props.errors?.email}
              id="email"
              label={t("contact:Email")}
              name="email"
              onChange={props.handleChange}
              type="text"
              value={props.data.email}
              withError={!!props.errors?.email}
            />
            <Input
              error={props.errors?.phone}
              id="phone"
              label={t("contact:Phone")}
              name="phone"
              onChange={props.handleChange}
              type="text"
              value={props.data.phone}
              withError={!!props.errors?.phone}
            />
          </>
        )}
        <Select
          error={props.errors?.["address.country"]}
          id="country"
          label={t("contact:Country")}
          name="address.country"
          onChange={props.handleChange}
          options={COUNTRIES}
          value={props.data.address?.country}
        />
        {showCitizenship && (
          <Input
            error={props.errors?.["citizenship"]}
            id="citizenship"
            label={t("contact:Citizenship")}
            name="citizenship"
            onChange={props.handleChange}
            type="text"
            value={props.data.citizenship}
            withError={!!props.errors?.["citizenship"]}
          />
        )}
        {!hidePersonalDetails && (
          <>
            <Input
              error={props.errors?.["idDetails.pesel"]}
              id="pesel"
              label={t("contact:PESEL")}
              name="idDetails.pesel"
              onChange={props.handleChange}
              type="text"
              value={props.data.idDetails?.pesel}
              withError={!!props.errors?.["idDetails.pesel"]}
            />
            <Select
              error={props.errors?.["idDetails.idType"]}
              id="idType"
              label={t("contact:ID Type")}
              name="idDetails.idType"
              onChange={props.handleChange}
              options={CONTACT_ID_TYPES}
              value={props.data.idDetails?.idType}
            />
            <Input
              error={props.errors?.["idDetails.number"]}
              id="number"
              label={t("contact:ID number")}
              name="idDetails.number"
              onChange={props.handleChange}
              type="text"
              value={props.data.idDetails?.number}
              withError={!!props.errors?.["idDetails.number"]}
            />
            <InputMask
              mask="99.99.9999"
              // @ts-ignore Missing typing from @types/react-input-mask
              maskPlaceholder="dd.mm.yyyy"
              onChange={props.handleChange}
              value={props.data.idDetails?.expirationDate}
            >
              <Input
                error={props.errors?.["idDetails.expirationDate"]}
                id="expirationDate"
                label={t("contact:Expiration date")}
                name="idDetails.expirationDate"
                type="text"
                withError={!!props.errors?.["idDetails.expirationDate"]}
              />
            </InputMask>
          </>
        )}
        <Select
          error={props.errors?.["type"]}
          id="type"
          label={t("contact:Role")}
          name="type"
          onChange={props.handleChange}
          options={CONTACT_ROLES}
          value={props.data.type}
        />
        {showOther && (
          <Input
            error={props.errors?.["other"]}
            id="other"
            label={t("contact:Other")}
            name="other"
            onChange={props.handleChange}
            type="text"
            value={props.data.other}
            withError={!!props.errors?.["other"]}
          />
        )}
      </TwoColumnsLayout>
      {showAddress && (
        <>
          <StyledAddressHeader>{t("contact:Address")}</StyledAddressHeader>
          <EditContactAddress {...props} />
        </>
      )}
    </>
  );
}
