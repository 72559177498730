import { Flex, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { PageHeader } from "components/PageHeader/PageHeader";
import { useCompany } from "hooks/useCompany";
import { CompanyAddGmcWrapper, StyledBackToCompanyButton, StyledHeader } from "./CompanyAddGmc.styled";
import { Heading } from "components/Heading/Heading";
import { Spinner } from "components/Spinner/Spinner";
import { useTransactions } from "hooks/useTransactions";
import { getMarginQuantityNumber, TRANSACTION_STATUSES, TRANSACTION_TYPES } from "helpers/transaction";
import { TransactionTable } from "./components/TransactionTable/TransactionTable";
import { Transaction } from "interfaces/data";
import { SelectedTransactions } from "./components/SelectedTransactions/SelectedTransactions";
import { Box } from "components/Box/Box";
import theme from "theme/theme";
import { getWithdrawValue } from "helpers/margin";
import {
  calculateMarginCallsSum,
  convertGlobalMarginsToTableRows,
  convertLocalMarginsToTableRows,
} from "helpers/360view";
import { useNbpRates } from "hooks/useNbpRates";
import { ArrowLeftIcon } from "theme/icons";

export const CompanyAddGmc = () => {
  const { id } = useParams<{ id: string }>();
  const { company, loading: companyLoading } = useCompany({ id });
  const history = useHistory();
  const { t } = useTranslation();
  const { rates: nbpRates, loading: ratesLoading } = useNbpRates();
  const [selectedTransactions, setSelectedTransactions] = useState<Array<Transaction>>([]);
  const filtersAnd = useMemo(
    () => [
      `company.id:${id}`,
      `status:${TRANSACTION_STATUSES.CREATED.value},${TRANSACTION_STATUSES.PARTIALLY_SETTLED.value}`,
    ],
    [id]
  );

  const filtersOr = useMemo(() => [`type:${TRANSACTION_TYPES.FIXED.value}`], []);

  const { transactions, loading: transactionsLoading } = useTransactions({
    phrase: "",
    filtersOr,
    filtersAnd,
    fields: "company.name,number",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 0,
    skipAutoUpdate: true,
  });

  const globalVariationMarginsRows = useMemo(() => {
    if (!company || !company.globalMargins || !nbpRates) return [];
    return convertGlobalMarginsToTableRows(
      company.globalMargins.filter((margin) => getWithdrawValue(margin) !== Number(margin.from.quantity)),
      nbpRates
    );
  }, [company, nbpRates]);

  const variationMarginsRows = useMemo(() => {
    if (!transactions || !nbpRates) return [];
    return convertLocalMarginsToTableRows(transactions, nbpRates, "VM", false);
  }, [nbpRates, transactions]);

  const totalLocalGlobalMarginQuantityPLN = calculateMarginCallsSum(
    globalVariationMarginsRows.length > 0
      ? globalVariationMarginsRows[globalVariationMarginsRows.length - 1].remainingQty
      : 0,
    variationMarginsRows.length > 0 ? variationMarginsRows[variationMarginsRows.length - 1].remainingQty : 0
  );
  const companyInitialMarginRequests = useMemo(
    () =>
      transactions?.reduce((sum: number, transaction: Transaction) => getMarginQuantityNumber(transaction) + sum, 0) ||
      0,
    [transactions]
  );

  if (companyLoading || transactionsLoading || ratesLoading) {
    return <Spinner />;
  }

  if (!company) {
    return <>{t("company:Company not found")}</>;
  }

  return (
    <>
      <PageHeader>
        <StyledHeader>
          <Flex direction="column">
            <Heading type="h1">Add Global Margin Call</Heading>
            <StyledBackToCompanyButton onClick={() => history.push(`/companies/${id}`)}>
              <ArrowLeftIcon mr="10px" />
              {company.name}
            </StyledBackToCompanyButton>
          </Flex>
        </StyledHeader>
      </PageHeader>
      <CompanyAddGmcWrapper>
        <TransactionTable
          isLoading={transactionsLoading}
          setSelectedTransactions={setSelectedTransactions}
          transactions={transactions}
        />
        <div>
          <Box backgroundColor={theme.colors["sk-yellow"]} h="12px" />
          <Box backgroundColor="white" fontSize="16px" padding="18px">
            {selectedTransactions && selectedTransactions.length ? (
              <SelectedTransactions
                companyId={company.id}
                companyInitialMarginRequests={companyInitialMarginRequests}
                totalLocalGlobalMarginQuantityPLN={totalLocalGlobalMarginQuantityPLN}
                transactions={selectedTransactions}
              />
            ) : (
              <Text as="span">{t("company:You must select atleast one transaction")}</Text>
            )}
          </Box>
        </div>
      </CompanyAddGmcWrapper>
    </>
  );
};
