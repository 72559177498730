import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from "hooks/useToast";
import { useTransaction } from "hooks/useTransaction";
import { BaseTransaction, Transaction } from "interfaces/data";
import { TransactionFormModal } from "../TransactionFormModal/TransactionFormModal";
import { TransactionConfirm } from "../TransactionConfirm/TransactionConfirm";
import { useMail } from "hooks/useMail";
import { TRANSACTION_TYPES } from "helpers/transaction";
import { FIXED_RATE_MAIL, PROLONG_MAIL, SPOT_MAIL } from "helpers/mails";
import { useIsMounted } from "hooks/useIsMounted";

export function TransactionEdit() {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [sendEmail, setSendEmail] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newTransaction, setNewTransaction] = useState<BaseTransaction>();
  const { update, transaction, errors, validate } = useTransaction({
    id,
  });
  const sendCorrection = useMail();
  const { isMounted } = useIsMounted();

  const confirmEdit = useCallback(
    (transaction: DeepPartial<Transaction>) => {
      validate(transaction).then((isValid) => {
        if (isValid) {
          setNewTransaction(transaction as Transaction);
          setIsPopupOpen(true);
        }
      });
    },
    [validate]
  );

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSave = useCallback(
    (transaction: BaseTransaction) => {
      setIsSending(true);

      update(transaction)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            if (sendEmail) {
              const currentTransaction = transaction as Transaction;
              const mailType = transaction.createdFrom?.id
                ? PROLONG_MAIL
                : transaction.type === TRANSACTION_TYPES.FIXED.value
                ? FIXED_RATE_MAIL
                : SPOT_MAIL;

              sendCorrection(mailType, currentTransaction.id, {
                correction: "yes",
              });
            }
            toast({
              type: "success",
              message: t("transaction:Transaction has been edited successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`transaction:${error.message}`),
          });
        });
    },
    [update, handleClose, toast, t, setIsSending, sendCorrection, sendEmail]
  );

  // ONLY TRANSACTIONS WITHOUT SETTLEMENTS CAN BE EDITED
  useEffect(() => {
    if (transaction && transaction.settlements && transaction.settlements.length > 0) {
      handleClose();
      toast({
        type: "error",
        message: t("transaction:This transaction cannot be edited, because there are settlements added."),
      });
    }
  }, [handleClose, t, toast, transaction]);

  if (!transaction || !isMounted) {
    return null;
  }

  return (
    <>
      <TransactionFormModal
        errors={errors}
        initialTransaction={transaction}
        onClose={handleClose}
        onSave={confirmEdit}
        sendEmail={sendEmail}
        sendEmailChange={setSendEmail}
        title={t(`transaction:Edit transaction #{{number}}`, {
          number: transaction.number,
        })}
        transactionType={transaction.type}
        type="edit"
      />
      <TransactionConfirm
        isDisabled={isSending}
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onConfirm={handleSave}
        sendEmail={sendEmail}
        transaction={newTransaction}
        transactionType={transaction.type}
        type="edit"
      />
    </>
  );
}
