import styled from "@emotion/styled";
import { InputGroup } from "@chakra-ui/react";

import { Input } from "components/Input/Input";
import { Button } from "components/Button/Button";
import isPropValid from "@emotion/is-prop-valid";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 100%;
  svg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-left: 20px;
    color: #aaa9af;
  }
`;

export const StyledInput = styled(Input)<{ error?: string }>`
  background: #ffffff;
  border: 1px solid ${(props) => (props.error ? props.theme.colors["sk-red"] : props.theme.colors["sk-light-gray"])} !important;
  border-radius: 16px;
  padding-left: 55px;
  height: 42px;
  outline: none !important;
  box-shadow: none !important;
  font-size: 13px;
  width: 100%;
  ::placeholder {
    color: #aaa9af;
    opacity: 1;
  }
`;

export const StyledContent = styled.div`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]} !important;
  border-radius: 16px;
  min-height: 42px;
  outline: none !important;
  font-size: 13px;
  width: 100%;
  position: absolute;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  margin-top: 10px;
  z-index: 1000;
`;

export const StyledPane = styled.div`
  overflow-y: auto;
  max-height: 375px;
  border-radius: 0 0 16px 16px;
`;

export const StyledHeaderItem = styled.div`
  padding: 20px;
  font-size: 15px;
  line-height: 110%;
  font-weight: bold;
`;

export const StyledItem = styled(Button, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ isBlocked: boolean }>`
  padding: 0 20px;
  width: 100%;
  height: 40px;
  border-radius: 0;
  border: none;
  justify-content: flex-start;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: normal;
  font-size: 13px;

  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-purple"]};
  }

  ${(props) =>
    props.isBlocked &&
    `
    color: ${props.theme.colors["sk-gray"]};
  `}
`;

export const StyledNoData = styled.div`
  padding: 20px;
  font-size: 13px;
  line-height: 110%;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
`;
