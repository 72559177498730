import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Button } from "components/Button/Button";
import { Link, useHistory, useParams } from "react-router-dom";
import _get from "lodash.get";
import { useTranslation } from "react-i18next";
import { useTransaction } from "hooks/useTransaction";
import styled from "@emotion/styled";
import { Dropdown } from "components/Dropdown/Dropdown";
import {
  determineTransactionCurrency,
  determineTransactionStatus,
  getHedgesLeftQuantity,
  getOperations,
  getQuantityLeft,
  isNotEvenlyHedged,
  TRANSACTION_STATUSES,
  TRANSACTION_TYPES,
} from "helpers/transaction";
import { Hedges } from "./Hedges";
import { Margins } from "./Margins";
import { TransactionDetails } from "./TransactionDetails";
import { Operations } from "./Operations";
import { Settlements } from "./Settlements";
import { MarginCalls } from "./MarginCalls";
import { Modal } from "components/Modal/Modal";
import { useToast } from "hooks/useToast";
import { PageButtons } from "components/PageButtons/PageButtons";
import { PageHeader } from "components/PageHeader/PageHeader";
import { formatNumberToString } from "helpers/formatNumber";
import { useMail } from "hooks/useMail";
import { FIXED_RATE_MAIL, PROLONG_MAIL, SPOT_MAIL } from "helpers/mails";
import { useLastActivity } from "hooks/useLastActivity";
import { useCompany } from "hooks/useCompany";
import { getMilisecondsFromTimestamp } from "helpers/date";
import { Commitments } from "./Commitments";
import { useIsMounted } from "hooks/useIsMounted";
import { getCompanyOperations } from "helpers/company";
import { ArrowLeftIcon, ConvertIcon, DangerIcon, RefreshIcon, SelectIcon, TwoArrowsIcon } from "theme/icons";

const StyledHeadingLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const StyledSmallLink = styled(Link)`
  font-size: 18px;
  color: ${(props) => props.theme.colors["sk-purple"]};
  margin-right: 10px;
`;

const StyledActionLink = styled.button`
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  padding: 10px 20px;
  :hover {
    background: ${(props) => props.theme.colors["sk-light-gray"]};
  }
  &:disabled {
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
`;

const StyledActionsContainer = styled.div`
  display: inline-block;
`;

const StyledBackButton = styled.button`
  font-size: 14px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  line-height: 110%;
  svg {
    margin-top: -2px;
  }
`;

const StyledSecondBackButton = styled(StyledBackButton)`
  margin-left: 20px;
`;

const StyledModalContent = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  padding-top: 60px;
  padding-bottom: 20px;
`;

const StyledColumnsContainer = styled.div<{ isDisabled?: boolean }>`
  display: grid;
  grid-template-columns: 2fr minmax(600px, 1fr);
  grid-column-gap: 30px;
  ${(props) => props.isDisabled && "opacity: 0.5;"}
`;

const StyledHeaderGrid = styled.div`
  font-weight: 700;
  font-size: 42px;
  line-height: 110%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`;

const StyledIconsWrapper = styled.div`
  line-height: 1;
  font-size: 18px;
  padding-top: 5px;
  grid-column-start: 2;
`;

export function Transaction() {
  const { id } = useParams<{ id: string }>();
  const { transaction, statusUpdate, commentUpdate, riskIgnoreUpdate } = useTransaction({
    id,
  });
  const { company } = useCompany({
    id: transaction?.company.id,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmableAction, setConfirmableAction] = useState<() => any>();
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const sendConfirmation = useMail();
  const [isResendClicked, setIsResendClicked] = useState(false);
  const { isMounted } = useIsMounted();

  const { addNewVisitedTransactionsInfo } = useLastActivity();

  const isWithGMC = useMemo(
    () => Boolean(transaction?.marginCalls?.filter((margin) => margin.isGMC === "yes").length),
    [transaction?.marginCalls]
  );

  useEffect(() => {
    if (transaction) {
      addNewVisitedTransactionsInfo(transaction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const handleConvertAction = useCallback(() => {
    history.push(`/transactions/${id}/convert`);
  }, [history, id]);

  const handleEditAction = useCallback(() => {
    history.push(`/transactions/${id}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleRollAction = useCallback(() => {
    history.push(`/transactions/${id}/roll`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCloseAction = useCallback(() => {
    setConfirmableAction(() => () => statusUpdate(TRANSACTION_STATUSES.CLOSED.value));
    setShowConfirmationModal(true);
  }, [statusUpdate]);

  const handleCancelAction = useCallback(() => {
    history.push(`/transactions/${id}/cancel`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleActivateAction = useCallback(() => {
    if (transaction) {
      const status = determineTransactionStatus(transaction);
      setConfirmableAction(() => () => statusUpdate(status));
      setShowConfirmationModal(true);
    }
  }, [transaction, statusUpdate]);

  const handleModalClose = useCallback(() => {
    setShowConfirmationModal(false);
    setConfirmableAction(() => {
      return;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendAction = useCallback(() => {
    setConfirmableAction(() => () => {
      setIsResendClicked(true);
      if (transaction) {
        sendConfirmation(
          transaction.type === "FR" ? (transaction.createdFrom ? PROLONG_MAIL : FIXED_RATE_MAIL) : SPOT_MAIL,
          String(id)
        ).then(() => {
          toast({
            type: "success",
            message: t("transaction:Transaction confirmation has been sent."),
          });
        });
      }
    });
    setShowConfirmationModal(true);
  }, [transaction, sendConfirmation, id, toast, t]);

  const isTransactionClosed = useMemo(() => transaction?.status === TRANSACTION_STATUSES.CLOSED.value, [transaction]);

  const isTransactionRolledTo = useMemo(() => Boolean(transaction?.createdFrom?.id), [transaction]);

  const isTransactionRolledFrom = useMemo(
    () => transaction?.status === TRANSACTION_STATUSES.ROLLED.value,
    [transaction]
  );

  const isTransactionConvertedTo = useMemo(
    () => transaction?.status === TRANSACTION_STATUSES.CLOSED.value && transaction.convertedTo?.id,
    [transaction]
  );

  const isTransactionConvertedFrom = useMemo(() => Boolean(transaction?.convertedFrom?.id), [transaction]);

  const isCompanyDeactivated = useMemo(() => transaction?.company.isDeactivated === "yes", [transaction]);

  const isTransactionDisabled = useMemo(
    () => isTransactionRolledFrom || isTransactionClosed || isCompanyDeactivated,
    [isTransactionClosed, isTransactionRolledFrom, isCompanyDeactivated]
  );

  const canTransactionBeActivated = useMemo(
    () => isTransactionClosed && !isTransactionConvertedTo && !isTransactionRolledFrom && !isCompanyDeactivated,
    [isTransactionClosed, isTransactionConvertedTo, isTransactionRolledFrom, isCompanyDeactivated]
  );

  const partiallyHedgedInfo = useMemo(() => {
    if (!transaction) return;

    const notEvenlyHedged = isNotEvenlyHedged(transaction);

    if (!notEvenlyHedged) return;

    return `${t("transaction:Transaction partially hedged")}: ${formatNumberToString(
      getHedgesLeftQuantity(transaction)
    )} / ${formatNumberToString(getQuantityLeft(transaction))} ${transaction.from.currency}`;
  }, [transaction, t]);

  const operations = useMemo(
    () => [
      ...getOperations(transaction),
      ...getCompanyOperations(company).filter((operation) =>
        operation.id.startsWith(transaction?.number.toString() || "0")
      ),
    ],
    [transaction, company]
  );

  if (!transaction || !isMounted) {
    return null;
  }

  // Take previous location path if user is coming from company 360 view
  const fromPath: string | undefined = _get(history, "location.state.fromPath", undefined);
  const marginCalls = [
    ...(transaction.marginCalls || []),
    ...(company?.globalMarginCalls?.filter((gmc) =>
      Boolean(gmc.globalTransactionsIds?.find((ids) => ids.id === transaction.id))
    ) || []),
  ];

  return (
    <>
      <PageHeader>
        <StyledHeaderGrid>
          <div>{t("transaction:Transaction")}</div>
          <div>
            #{transaction.number}{" "}
            <StyledHeadingLink to={`/companies/${transaction.company.id}`}>
              {transaction.company.name}
            </StyledHeadingLink>
          </div>
          {(transaction.createdFrom?.number ||
            transaction.convertedFrom?.number ||
            transaction.convertedTo?.number ||
            transaction.prolongedBy?.number) && (
            <StyledIconsWrapper>
              {transaction.createdFrom?.number && (
                <span>
                  <RefreshIcon boxSize="14px" mt="-2px" />{" "}
                  <StyledSmallLink to={`/transactions/${transaction.createdFrom.id}`}>
                    {transaction.createdFrom.number}
                  </StyledSmallLink>
                </span>
              )}
              {transaction.convertedFrom?.number && (
                <span>
                  <ConvertIcon boxSize="14px" mt="-2px" />{" "}
                  <StyledSmallLink to={`/transactions/${transaction.convertedFrom.id}`}>
                    {transaction.convertedFrom.number}
                  </StyledSmallLink>
                </span>
              )}
              {transaction.convertedTo?.number && (
                <span>
                  <ConvertIcon boxSize="14px" mt="-2px" />{" "}
                  <StyledSmallLink to={`/transactions/${transaction.convertedTo.id}`}>
                    {transaction.convertedTo.number}
                  </StyledSmallLink>
                </span>
              )}
              {transaction.prolongedBy?.number && (
                <span>
                  <TwoArrowsIcon boxSize="14px" mt="-2px" />{" "}
                  <StyledSmallLink to={`/transactions/${transaction.prolongedBy.id}`}>
                    {transaction.prolongedBy.number}
                  </StyledSmallLink>
                </span>
              )}
            </StyledIconsWrapper>
          )}
        </StyledHeaderGrid>
        <Flex w="100%">
          <StyledBackButton onClick={() => history.push("/transactions")}>
            <ArrowLeftIcon mr="10px" />
            {t("transaction:Transactions")}
          </StyledBackButton>
          {fromPath && (
            <StyledSecondBackButton onClick={() => history.push(fromPath)}>
              <ArrowLeftIcon mr="10px" />
              {t("transaction:360 company view")}
            </StyledSecondBackButton>
          )}
        </Flex>
      </PageHeader>
      <PageButtons>
        {isTransactionDisabled ? (
          <>
            {canTransactionBeActivated && (
              <Button design="primary" onClick={handleActivateAction}>
                {t("transaction:Activate")}
              </Button>
            )}
          </>
        ) : (
          <StyledActionsContainer>
            <Dropdown
              onClose={() => setIsResendClicked(false)}
              padding="10px 0"
              placement="right"
              trigger={
                <Button design="secondary">
                  {t("transaction:Actions")} <SelectIcon ml="10px" />
                </Button>
              }
            >
              {transaction.type === TRANSACTION_TYPES.ORDER.value && (
                <StyledActionLink onClick={handleConvertAction}>{t("transaction:Convert")}</StyledActionLink>
              )}
              <StyledActionLink onClick={handleEditAction}>{t("transaction:Edit")}</StyledActionLink>
              <StyledActionLink disabled={isResendClicked} onClick={handleResendAction}>
                {t("transaction:Resend")}
              </StyledActionLink>
              {transaction.type === TRANSACTION_TYPES.FIXED.value && (
                <StyledActionLink onClick={handleRollAction}>{t("transaction:Roll")}</StyledActionLink>
              )}
              {transaction.type !== TRANSACTION_TYPES.ORDER.value && (
                <StyledActionLink onClick={handleCloseAction}>{t("transaction:Close")}</StyledActionLink>
              )}
              <StyledActionLink onClick={handleCancelAction}>
                {isTransactionRolledTo
                  ? t("transaction:Cancel roll")
                  : isTransactionConvertedFrom
                  ? t("transaction:Cancel conversion")
                  : t("transaction:Cancel")}
              </StyledActionLink>
            </Dropdown>
          </StyledActionsContainer>
        )}
      </PageButtons>

      <StyledColumnsContainer isDisabled={isTransactionClosed || isTransactionRolledFrom}>
        <div>
          <TransactionDetails commentUpdate={commentUpdate} transaction={transaction} />
          {/* Order transactions can't have settlements */}
          {transaction.type !== TRANSACTION_TYPES.ORDER.value && (
            <Settlements
              fromCurrency={transaction.from.currency}
              isDisabled={isTransactionDisabled}
              settlements={transaction?.settlements?.sort(
                (a, b) => getMilisecondsFromTimestamp(b.date) - getMilisecondsFromTimestamp(a.date)
              )}
              transactionId={transaction.id}
              transactionType={transaction.type}
            />
          )}
          {/* Only Fixed Rate transactions can have margin calls */}
          {transaction.type === TRANSACTION_TYPES.FIXED.value && (
            <MarginCalls
              currency={determineTransactionCurrency(transaction)}
              ignoreMarginCallRisk={transaction.ignoreMarginCallRisk || false}
              isDisabled={isTransactionDisabled}
              isWithGMC={isWithGMC}
              marginCalls={marginCalls}
              onIgnoreMarginCallRiskUpdate={async (newValue) => {
                const result = await riskIgnoreUpdate(newValue);
                if (result) {
                  toast({
                    type: "success",
                    message: t("transaction:Margin call settings have been updated."),
                  });
                }
              }}
              transactionCurrency={transaction.from.currency}
              transactionId={transaction.id}
            />
          )}
        </div>
        <div>
          {/* Only Fixed Rate transactions can have hedges & margins */}
          {transaction.type === TRANSACTION_TYPES.FIXED.value && (
            <>
              <Hedges
                currency={transaction.from.currency}
                hedges={transaction.hedges}
                isDisabled={isTransactionDisabled}
                partiallyHedgedInfo={partiallyHedgedInfo}
                transactionId={transaction.id}
              />
              <Margins
                isDisabled={isTransactionDisabled}
                margins={transaction.margins}
                transactionId={transaction.id}
              />
            </>
          )}
          {/* Order transactions can't have operations */}
          {transaction.type !== TRANSACTION_TYPES.ORDER.value && <Operations operations={operations} />}
          {transaction && <Commitments transaction={transaction} />}
        </div>
      </StyledColumnsContainer>
      <Modal
        confirmText="Yes"
        design="primary"
        isOpen={showConfirmationModal}
        minWidth={420}
        onClose={handleModalClose}
        onConfirm={() => {
          confirmableAction && confirmableAction();
          handleModalClose();
        }}
      >
        <StyledModalContent>
          <div>
            <DangerIcon boxSize="48px" mb="20px" />
          </div>
          Are you sure?
        </StyledModalContent>
      </Modal>
    </>
  );
}
