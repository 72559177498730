import React, { useMemo, useState } from "react";
import { Table } from "components/Table/Table";
import { InputLeftElement, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Button } from "components/Button/Button";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { PageButtons } from "components/PageButtons/PageButtons";
import { useMarginCalls } from "hooks/useMarginCalls";
import { DateCell } from "helpers/tableCells";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { GlobalMarginCall, MarginCall, MarginCallExtended } from "interfaces/data";
import { useTransaction } from "hooks/useTransaction";
import { useToast } from "hooks/useToast";
import { StyledTableContainer } from "views/Company360/Main/components/margin.styled";
import {
  CommentCell,
  CompanyCell,
  CurrencyCell,
  EditCell,
  IdCell,
  isGmc,
  RemainingCell,
  RemainingRatioCell,
  StatusCell,
  TransactionIdsCell,
} from "./components/cells";
import { useRates } from "hooks/useRates";
import { ActiveClosedSwitch } from "./components/ActiveClosedSwitch";
import { PageHeader } from "./components/PageHeader";
import { SearchIcon } from "../../theme/icons";

export function MarginCalls() {
  const { t } = useTranslation();
  const { rates } = useRates();

  const { marginCallInTransactionUpdate } = useTransaction({
    skipFetching: true,
  });

  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "callDateDesc",
        label: t("company:Call Date"),
        field: "callDate",
        order: "desc",
      },
      {
        value: "modifiedDesc",
        label: t("company:Recently updated"),
        field: "modifiedAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("company:Company name (A-Z)"),
        field: "transaction.company.name",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("company:Company name (Z-A)"),
        field: "transaction.company.name",
        order: "desc",
      },
      {
        value: "createdDesc",
        label: t("company:Creation date (newest first)"),
        field: "createdAt",
        order: "desc",
      },
      {
        value: "createdAsc",
        label: t("company:Creation date (oldest first)"),
        field: "createdAt",
        order: "asc",
      },
    ],
    [t]
  );

  const [showClosed, setShowClosed] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortingOption, setSortingOption] = useState(sortingOptions[0]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const history = useHistory();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const toast = useToast();

  const filtersAnd = useMemo(() => [`isClosed:${showClosed ? "yes" : "no"}`], [showClosed]);

  const { marginCalls, loading, pageCount, refetch } = useMarginCalls({
    phrase: debouncedSearchQuery,
    fields: "transaction.company.name,transaction.number,id",
    orderBy: sortingOption.field,
    filtersAnd,
    orderDirection: sortingOption.order,
    offset,
    limit,
  });

  const handleCommentUpdate = async ({
    marginCall,
    comment,
  }: {
    marginCall: MarginCallExtended | GlobalMarginCall;
    comment: string;
  }) => {
    if (isGmc(marginCall)) return;
    const transactionId = marginCall.transaction.id;
    const marginCallId = marginCall.id;
    try {
      await marginCallInTransactionUpdate(transactionId, marginCallId, {
        tableComment: comment,
      });

      await refetch();

      toast({
        type: "success",
        message: t("Margin Call comment has been updated."),
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          type: "error",
          message: t(error.message),
        });
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "transaction.company.name",
        Cell: CompanyCell,
      },
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("Transactions IDs"),
        accessor: "globalTransactionsIds",
        Cell: TransactionIdsCell,
      },
      {
        Header: t("Call date"),
        accessor: "callDate",
        Cell: DateCell,
      },
      {
        Header: t("Call rate"),
        accessor: "callRate",
        Cell: (arg: any) => formatNumberToString(arg.value, 4),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: CurrencyCell,
      },
      {
        Header: t("Type"),
        accessor: "transaction.dealType",
      },
      {
        Header: t("Trans. rate"),
        accessor: "transaction.clientRate",
        Cell: (arg: any) => formatRate(arg.value),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Base Qty.")}
          </Text>
        ),
        accessor: "transaction.from.quantity",
        Cell: (arg: any) => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {formatNumberToString(arg.row.original.isGMC === "yes" ? arg.row.original.transQuantity : arg.value)}{" "}
            {arg.row.original.transaction.from.currency}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("Remaining Qty.")}
          </Text>
        ),
        accessor: "remainingQty",
        Cell: (arg: any) => RemainingCell(arg, rates),
      },
      {
        Header: t("Trans. date"),
        accessor: "transaction.agreement",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="center" whiteSpace="nowrap">
            {t("Paid / Remaining")}
          </Text>
        ),
        accessor: "remainingRatio",
        Cell: (arg: any) => RemainingRatioCell(arg, rates),
      },
      {
        Header: t("Comments"),
        accessor: "tableComment",
        Cell: (arg: any) => CommentCell(arg, handleCommentUpdate),
      },
      {
        Header: t("Status"),
        accessor: "paid",
        Cell: (arg: any) => StatusCell(arg, rates),
      },
      {
        Header: "",
        accessor: "edit",
        Cell: EditCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rates]
  );

  const handleTableUpdate = ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  };

  const handleSearchInputUpdate = (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value);
  const handleSortByUpdate = (newSelectedOption: TableSortOption) => setSortingOption(newSelectedOption);
  const navigateToMarginCallAdd = () => history.push("/calls/margin-calls/add");

  if (!marginCalls) return null;

  return (
    <>
      <PageHeader title="Margin Calls" />
      <PageButtons>
        <Button design="primary" onClick={navigateToMarginCallAdd}>
          {t("New Margin Call")}
        </Button>
      </PageButtons>
      <StyledContainer>
        <StyledTableControls>
          <ActiveClosedSwitch setShowClosed={setShowClosed} showClosed={showClosed} />
          <StyledInputGroup>
            <InputLeftElement children={<SearchIcon />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search")} value={searchQuery} />
          </StyledInputGroup>
          <TableSortSelect
            onSortByChange={handleSortByUpdate}
            options={sortingOptions}
            selectedOption={sortingOption}
          />
        </StyledTableControls>
        <StyledTableContainer>
          <Table
            columns={columns}
            data={marginCalls}
            getRowProps={(rowInfo) => {
              const marginCall = rowInfo.original as MarginCall;
              return {
                className: [
                  marginCall.isClosed === "yes" ? `closed-row` : "",
                  marginCall.isGMC === "yes" ? "global-row" : "",
                ].join(" "),
              };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUpdate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledTableContainer>
      </StyledContainer>
    </>
  );
}
