import { z } from "zod";
import { TimestampSchema } from "./common";

export const HedgeSchema = z.object({
  id: z.string(),
  createdAt: TimestampSchema.optional(),
  modifiedAt: TimestampSchema.optional(),
  start: TimestampSchema,
  end: TimestampSchema,
  institution: z.string(),
  points: z.number(),
  quantity: z.number(),
  leftQuantity: z.number(),
  rolledQuantity: z.number().optional(),
  security: z.number(),
  comment: z.string().optional(),
  createdFromId: z.string().optional(),
  createdFromData: z.array(z.object({ hedgeId: z.string(), quantity: z.number() })).optional(),
});

export const HedgeWithOptionalIdSchema = HedgeSchema.partial({ id: true });
