import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalMarginCall, MarginFinalNotice } from "interfaces/data";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { formatDate } from "helpers/date";
import styled from "@emotion/styled";
import { Input } from "components/Input/Input";
import { createFromGlobalMarginCall } from "helpers/finalNoticeFactory";
import { useFirebase } from "hooks/useFirebase";
import { useQuery } from "react-query";

const StyledFieldsLayout = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
`;

const StyledLabel = styled.div`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  display: flex;
  margin-top: 13px;
  justify-content: flex-end;
  font-weight: 600;
  line-height: 110%;
`;

const StyledParagraph = styled.p`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-size: ${(props) => props.theme.fontSizes["xs-sm"]};
  display: flex;
  margin-top: 13px;
  font-weight: 600;
  line-height: 110%;
`;

interface MarginCallFinalModalProps {
  onSave: (finalNotice: MarginFinalNotice) => void;
  onClose: () => void;
  marginCall: GlobalMarginCall;
  isDisabled: boolean;
}

export const GlobalMarginCallFinalModal: React.FC<MarginCallFinalModalProps> = ({
  marginCall,
  onSave,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { db } = useFirebase();
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();
  const { data: finalNotice } = useQuery("finalNoticeFromMarginCall", () => createFromGlobalMarginCall(marginCall, db));

  if (!finalNotice) return null;
  const isDisabled = props.isDisabled || finalNotice.finalNoticeQuantity.quantity <= 0;

  return (
    <>
      <Modal
        bodyProps={{ padding: "30px 70px" }}
        confirmText={t("marginCall:Send")}
        design="primary"
        isDisabled={isDisabled}
        isOpen
        justifyButtons="flex-end"
        minWidth={600}
        onClose={onClose}
        onConfirm={onConfirmationOpen}
        title={`${t("marginCall:Send Final Notice")} - ${marginCall.id}`}
      >
        <StyledFieldsLayout>
          <StyledLabel>{t("marginCall:Transactions")}</StyledLabel>
          <StyledParagraph>{finalNotice.transactions.map((t) => t.number).join(", ")}</StyledParagraph>

          <StyledLabel>{t("marginCall:Current Date")}</StyledLabel>
          <Input isDisabled type="date" value={formatDate(finalNotice.createdAt)} />

          <StyledLabel>{t("marginCall:Call date")}</StyledLabel>
          <Input isDisabled type="date" value={formatDate(finalNotice.callDate)} />

          <StyledLabel>{t("marginCall:Call rate")}</StyledLabel>
          <Input isDisabled value={formatRate(finalNotice.callRate, 4)} />

          {marginCall.clientRate && (
            <>
              <StyledLabel>{t("marginCall:Client rate")}</StyledLabel>
              <Input isDisabled value={formatRate(marginCall.clientRate, 4)} />
            </>
          )}

          <StyledLabel>{t("marginCall:Transaction Quantity")}</StyledLabel>
          <Input
            isDisabled
            rightAddon={finalNotice.transactionsQuantity.currency}
            value={`${formatNumberToString(finalNotice.transactionsQuantity.quantity)}`}
          />

          <StyledLabel>{t("marginCall:Paid")}</StyledLabel>
          <Input
            isDisabled
            rightAddon={finalNotice.paidMargin?.currency}
            value={`${formatNumberToString(finalNotice.paidMargin?.quantity ?? 0)}`}
          />

          <StyledLabel>{t("marginCall:Call Quantity")}</StyledLabel>
          <Input
            isDisabled
            rightAddon={finalNotice.marginCallQuantity.currency}
            value={`${formatNumberToString(finalNotice.marginCallQuantity.quantity)}`}
          />

          <StyledLabel>{t("marginCall:Final Notice Quantity")}</StyledLabel>
          <Input
            isDisabled
            rightAddon={finalNotice.finalNoticeQuantity.currency}
            value={`${formatNumberToString(finalNotice.finalNoticeQuantity.quantity)}`}
          />

          <StyledLabel>{t("marginCall:Account number")}</StyledLabel>
          <Input
            isDisabled
            rightAddon={finalNotice.bankAccount?.currency}
            value={`${finalNotice.bankAccount?.number}`}
          />
        </StyledFieldsLayout>
      </Modal>

      {/* CONFIRMATION MODAL */}
      <Modal
        confirmText={t("Yes")}
        design="primary"
        isChild
        isDisabled={isDisabled || finalNotice.finalNoticeQuantity.quantity <= 0}
        isOpen={isConfirmationOpen}
        minWidth={420}
        onClose={onConfirmationClose}
        onConfirm={() => onSave(finalNotice)}
        title={t("marginCall:Send Final Notice")}
      >
        {t("marginCall:Are you sure you want to send Final Notice?")}
      </Modal>
    </>
  );
};
