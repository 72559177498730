import { Box, Textarea } from "@chakra-ui/react";
import React, { useCallback, useRef, useState } from "react";
import { Button } from "components/Button/Button";
import { useHotkeys } from "hooks/useHotkeys";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { Input } from "components/Input/Input";
import { StyledEditContainer } from "./Comment.styled";
import { ApproveIcon } from "../../../theme/icons";

type EditCommentProps = {
  comment?: string;
  onEditClose: () => void;
  onEdit: (comment: string) => void;
  asBox?: boolean;
  alignToRight?: boolean;
};

export const EditComment: React.FC<EditCommentProps> = ({
  onEdit,
  onEditClose,
  comment,
  asBox = true,
  alignToRight = false,
}) => {
  const [editComment, setEditComment] = useState(comment || "");
  const editRef = useRef(null);

  useOnClickOutside(editRef, onEditClose);
  useHotkeys("Escape", onEditClose);
  useHotkeys("Enter", () => onEdit(editComment));

  const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEditComment(e.target.value);
  }, []);

  if (!asBox) {
    return (
      <StyledEditContainer alignToRight={alignToRight} ref={editRef}>
        <Textarea name="comment" onChange={handleCommentChange} value={editComment} width="100%" />
        <Button design="primary" height="100%" ml="11px" onClick={() => onEdit(editComment)} p="18px">
          <ApproveIcon />
        </Button>
      </StyledEditContainer>
    );
  }

  return (
    <Box alignItems="center" display="flex" ref={editRef} width="100%">
      <Input
        containerProps={{
          width: "100%",
        }}
        fullWidth
        name="comment"
        onChange={handleCommentChange}
        value={editComment}
        width="100%"
      />
      <Button design="primary" height="100%" maxWidth={50} ml="11px" onClick={() => onEdit(editComment)} p="18px">
        <ApproveIcon />
      </Button>
    </Box>
  );
};
