import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Flex, Grid } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Heading/Heading";
import { CompanyInfo } from "./CompanyInfo/CompanyInfo";
import { EditCompanyInfo } from "./CompanyInfo/EditCompanyInfo";
import { Details } from "./Details/Details";
import { EditDetails } from "./Details/EditDetails";
import { CompanyAddress } from "./CompanyAddress/CompanyAddress";
import { EditCompanyAddress } from "./CompanyAddress/EditCompanyAddress";
import { OtherInfo } from "./OtherInfo/OtherInfo";
import { EditOtherInfo } from "./OtherInfo/EditOtherInfo";
import { Contacts } from "./Contacts/Contacts";
import { EditContacts } from "./Contacts/EditContacts";
import { BankAccounts } from "./BankAccounts/BankAccounts";
import { EditBankAccounts } from "./BankAccounts/EditBankAccounts";
import { EditBox } from "components/EditBox/EditBox";
import { useCompany } from "hooks/useCompany";
import { Spinner } from "components/Spinner/Spinner";
import { Button } from "components/Button/Button";
import { BankAccount, BaseCompany, Company as ICompany } from "interfaces/data";
import { ContactAddress } from "./ContactAddress/ContactAddress";
import { EditContactAddress } from "./ContactAddress/EditContactAddress";
import { StyledBackButton, StyledThreeColumnsContainer } from "components/ItemsListElements/ItemsListElements";
import styled from "@emotion/styled";
import { useMail } from "hooks/useMail";
import { useToast } from "hooks/useToast";
import { AGREEMENT_MAIL } from "helpers/mails";
import { PageHeader } from "components/PageHeader/PageHeader";
import { PageButtons } from "components/PageButtons/PageButtons";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { DeactivatedBox } from "../Companies/Companies";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ACTIVATE_COMPANY_GUARD, ADD_COMPANY_GUARD } from "helpers/userRoles";
import { Modal } from "components/Modal/Modal";
import { useLastActivity } from "hooks/useLastActivity";
import { ReversePayment } from "./ReversePayment/ReversePayment";
import { EditReversePayment } from "./ReversePayment/EditReversePayment";
import { StyledActionLink, StyledActionsContainer } from "../Transactions/Transactions";
import { Dropdown } from "components/Dropdown/Dropdown";
import { SelectIcon } from "theme/icons/SelectIcon";
import { DangerIcon } from "theme/icons/DangerIcon";
import { AddIcon } from "theme/icons/AddIcon";
import { ArrowLeftIcon } from "theme/icons";

enum CompanySections {
  companyInfo = "company-info",
  details = "details",
  contactAddress = "contact-address",
  companyAddress = "company-address",
  other = "other",
  contacts = "contacts",
  bankAccounts = "bank-accounts",
  reversePayment = "reverse-payment",
}

const emptyBankAccount: BankAccount = {
  number: "",
  name: "",
  swift: "",
  currency: "PLN",
  country: "PL",
};

const CompanyThreeColumnsContainer = styled(StyledThreeColumnsContainer)`
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr minmax(500px, 1fr);
  }
`;

const StyledModalContent = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  padding-top: 60px;
  padding-bottom: 20px;
`;

const handleRemove =
  (name: keyof BaseCompany) =>
  (index: number, { handler, data }: { handler: Function; data: BaseCompany }) => {
    const value = _cloneDeep(data[name]);

    if (Array.isArray(value)) {
      value.splice(index, 1);
    }

    handler({
      target: {
        name,
        value,
      },
      persist: () => {
        return;
      },
    });
  };

const handleAdd =
  (name: keyof BaseCompany) =>
  (item: any, { handler, data }: { handler: Function; data: BaseCompany }) => {
    const currentValue = _cloneDeep(data[name]);
    const clonedItem = _cloneDeep(item);

    const value = Array.isArray(currentValue) ? [clonedItem, ...currentValue] : [clonedItem];

    handler({
      target: {
        name,
        value,
      },
      persist: () => {
        return;
      },
    });
  };

export const Company: React.FC = () => {
  const { id, section } = useParams<{ id: string; section: CompanySections }>();
  const { company, loading, update, errors, setCompany, clearErrors } = useCompany({ id });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmableAction, setConfirmableAction] = useState<() => any>();
  const handleModalClose = useCallback(() => {
    setShowConfirmationModal(false);
    setConfirmableAction(() => {
      return;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const { t } = useTranslation();
  const [editedSection, setEditedSection] = useState<CompanySections | null>(section);
  const sendAgreement = useMail();
  const toast = useToast();

  const { addNewVisitedCompaniesInfo } = useLastActivity();

  useEffect(() => {
    if (company) {
      addNewVisitedCompaniesInfo(company);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (editedSection) {
      history.replace(`/companies/${id}/edit/${editedSection}`);
    } else if (editedSection === null) {
      history.replace(`/companies/${id}/preview`);
    }
  }, [editedSection, history, id]);

  const cancelSectionEdit = useCallback((initialData: ICompany) => {
    setEditedSection(null);
    setCompany(() => initialData);
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSectionEdit = useCallback(
    (section: CompanySections | null) => () => {
      setEditedSection(section);
    },
    []
  );

  const submitSectionEdit = useCallback(
    (newCompany: ICompany) => {
      setEditedSection(null);
      update(newCompany);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update, handleSectionEdit, setCompany]
  );

  const setAgreementAction = useCallback(() => {
    setConfirmableAction(() => () => {
      sendAgreement(AGREEMENT_MAIL, id);
      toast({
        type: "success",
        message: t("company:Agreement successfully sent."),
      });
    });
    setShowConfirmationModal(true);
  }, [sendAgreement, id, toast, t]);

  if (loading) {
    return <Spinner />;
  }

  if (!company) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("company:Details")}</Heading>
        <Flex w="100%">
          <StyledBackButton onClick={() => history.push(`/companies/${company?.id}`)}>
            <ArrowLeftIcon mr="10px" />
            {company.name}
          </StyledBackButton>
        </Flex>
      </PageHeader>
      <PageButtons>
        <RestrictAccess area={ACTIVATE_COMPANY_GUARD}>
          <StyledActionsContainer>
            <Dropdown
              padding="10px 0"
              placement="right"
              trigger={
                <Button design="secondary">
                  {t("transaction:Actions")} <SelectIcon ml="10px" />
                </Button>
              }
            >
              {company.isDeactivated ? (
                <StyledActionLink onClick={() => history.push(`/companies/${company?.id}/activate`)}>
                  {t("company:Activate")}
                </StyledActionLink>
              ) : (
                <>
                  <StyledActionLink
                    onClick={() => {
                      setAgreementAction();
                    }}
                  >
                    {t("company:Send agreement")}
                  </StyledActionLink>
                  <StyledActionLink onClick={() => history.push(`/companies/${company?.id}/deactivate`)}>
                    {t("company:Deactivate")}
                  </StyledActionLink>
                </>
              )}
              <StyledActionLink onClick={() => history.push(`/companies/${company?.id}/delete`)}>
                {t("company:Delete")}
              </StyledActionLink>
            </Dropdown>
          </StyledActionsContainer>
        </RestrictAccess>
      </PageButtons>
      <CompanyThreeColumnsContainer>
        {/* FIRST COLUMN */}
        <div>
          <Grid rowGap="20px">
            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.companyInfo}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.companyInfo}
                editView={EditCompanyInfo}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.companyInfo}
                name={
                  <div>
                    {t("company:Company info")}
                    {company.isDeactivated && <DeactivatedBox>{t("company:deactivated")}</DeactivatedBox>}
                  </div>
                }
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.companyInfo)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <CompanyInfo company={company} />
              </EditBox>
            </ActiveUserBorder>

            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.details}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.details}
                editView={EditDetails}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.details}
                name={t("company:Details")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.details)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <Details company={company} />
              </EditBox>
            </ActiveUserBorder>

            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.reversePayment}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.reversePayment}
                editView={EditReversePayment}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.reversePayment}
                name={t("company:Reverse Payment Limit")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.reversePayment)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <ReversePayment company={company} />
              </EditBox>
            </ActiveUserBorder>
          </Grid>
        </div>

        {/* SECOND COLUMN */}
        <div>
          <Grid rowGap="20px">
            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.companyAddress}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.companyAddress}
                editView={EditCompanyAddress}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.companyAddress}
                name={t("company:Company address")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.companyAddress)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <CompanyAddress company={company} />
              </EditBox>
            </ActiveUserBorder>

            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.contactAddress}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.contactAddress}
                editView={EditContactAddress}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.contactAddress}
                name={t("company:Contact address")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.contactAddress)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <ContactAddress company={company} />
              </EditBox>
            </ActiveUserBorder>

            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.other}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.other}
                editView={EditOtherInfo}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.other}
                name={t("company:Other")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.other)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <OtherInfo company={company} />
              </EditBox>
            </ActiveUserBorder>
          </Grid>
        </div>

        {/* THIRD COLUMN */}
        <div>
          <Grid rowGap="20px">
            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.contacts}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.contacts}
                editView={EditContacts}
                editViewProps={{
                  onContactRemove: handleRemove("contacts"),
                  onContactAdd: handleAdd("contacts"),
                }}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.contacts}
                name={t("company:Contacts")}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.contacts)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <Contacts company={company} />
              </EditBox>
            </ActiveUserBorder>

            <ActiveUserBorder collection="companies" id={id} sections={`edit/${CompanySections.bankAccounts}`}>
              <EditBox
                data={company}
                disabled={!!editedSection && editedSection !== CompanySections.bankAccounts}
                editView={EditBankAccounts}
                editViewProps={{
                  onAccountRemove: handleRemove("bankAccounts"),
                }}
                errors={errors}
                isEditDisabled={company.isDeactivated}
                isEditing={editedSection === CompanySections.bankAccounts}
                name={({ handleChange, data }) => (
                  <>
                    {t("company:Bank account")}
                    {editedSection === CompanySections.bankAccounts && (
                      <Button
                        color="sk-purple"
                        design="link"
                        fontWeight="bold"
                        height="auto"
                        letterSpacing={0}
                        onClick={() =>
                          handleAdd("bankAccounts")(emptyBankAccount, {
                            handler: handleChange,
                            data,
                          })
                        }
                        px={0}
                      >
                        <AddIcon mr="10px" />
                        {t("company:Add new account")}
                      </Button>
                    )}
                  </>
                )}
                onCancel={cancelSectionEdit}
                onEdit={handleSectionEdit(CompanySections.bankAccounts)}
                onSave={submitSectionEdit}
                restrict={ADD_COMPANY_GUARD}
              >
                <BankAccounts company={company} />
              </EditBox>
            </ActiveUserBorder>
          </Grid>
        </div>
      </CompanyThreeColumnsContainer>
      <Modal
        confirmText="Yes"
        design="primary"
        isOpen={showConfirmationModal}
        minWidth={420}
        onClose={handleModalClose}
        onConfirm={() => {
          confirmableAction && confirmableAction();
          handleModalClose();
        }}
      >
        <StyledModalContent>
          <div>
            <DangerIcon boxSize="48px" mb="20px" />
          </div>
          Are you sure?
        </StyledModalContent>
      </Modal>
    </>
  );
};
