import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const PlusIcon = createIcon({
  displayName: "PlusIcon",
  path: (
    <g fill="none">
      <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" stroke="currentColor" />
    </g>
  ),
  viewBox: "0 0 24 24",
});
