import React from "react";
import InputMask from "react-input-mask";
import { BaseContact } from "../../../interfaces/data";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { Select } from "../../../components/Select/Select";
import { CONTACT_ID_TYPES } from "../../../helpers/options";

export function EditIdDetails({ data, handleChange, errors }: EditViewProps<BaseContact>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["idDetails.pesel"]}
        id="pesel"
        label={t("contact:PESEL")}
        name="idDetails.pesel"
        onChange={handleChange}
        type="text"
        value={data.idDetails?.pesel}
        withError={!!errors?.["idDetails.pesel"]}
      />
      <Select
        error={errors?.["idDetails.idType"]}
        id="idType"
        label={t("contact:ID Type")}
        name="idDetails.idType"
        onChange={handleChange}
        options={CONTACT_ID_TYPES}
        value={data.idDetails?.idType}
      />
      <Input
        error={errors?.["idDetails.number"]}
        id="number"
        label={t("contact:Number")}
        name="idDetails.number"
        onChange={handleChange}
        type="text"
        value={data.idDetails?.number}
        withError={!!errors?.["idDetails.number"]}
      />
      <InputMask
        mask="99.99.9999"
        // @ts-ignore Missing typing from @types/react-input-mask
        maskPlaceholder="dd.mm.yyyy"
        onChange={handleChange}
        value={data.idDetails?.expirationDate}
      >
        <Input
          error={errors?.["idDetails.expirationDate"]}
          id="expirationDate"
          label={t("contact:Expiration Date")}
          name="idDetails.expirationDate"
          type="text"
          withError={!!errors?.["idDetails.expirationDate"]}
        />
      </InputMask>
    </TwoColumnsLayout>
  );
}
