import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "components/Heading/Heading";
import { PageHeader } from "components/PageHeader/PageHeader";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { isCompanyDeactivated, isInactive, isNotFixedRate, isOrderExpired } from "helpers/transaction";
import { useTransactions } from "hooks/useTransactions";
import { TotalTransactions } from "./TotalTransactions";
import { TopCompanies } from "./TopCompanies";
import { TotalCharge } from "./TotalCharge";
import { Spinner } from "components/Spinner/Spinner";
import { useNbpRates } from "hooks/useNbpRates";
import { Orders } from "./Orders";
import { ExchangeRateSimulation } from "./ExchangeRateSimulation/ExchangeRateSimulation";

export const RiskPanel: React.FC = () => {
  const { t } = useTranslation();

  const { transactions, loading } = useTransactions({
    phrase: "",
    fields: "number",
    orderBy: "number",
    orderDirection: "desc",
    offset: 0,
    limit: 9999999,
  });

  const { rates: nbpRates, loading: nbpRatesLoading } = useNbpRates();
  const [transactionFilter, setTransactionFilter] = useState<any>();

  const activeTransactions = useMemo(() => {
    if (!transactions || transactions.length === 0) return [];
    return transactions.filter(
      (transaction) => !isInactive(transaction) && !isNotFixedRate(transaction) && !isCompanyDeactivated(transaction)
    );
  }, [transactions]);

  const orderTransactions = useMemo(() => {
    if (!transactions || transactions.length === 0) return [];
    return transactions.filter(
      (transaction) =>
        !isInactive(transaction) &&
        transaction.type === "Order" &&
        !isCompanyDeactivated(transaction) &&
        !isOrderExpired(transaction)
    );
  }, [transactions]);

  if (loading || nbpRatesLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("sidebar:Risk")}</Heading>
      </PageHeader>

      <TwoColumnsLayout>
        <TotalTransactions
          nbpRates={nbpRates}
          onTransactionFilterUpdate={setTransactionFilter}
          transactionFilter={transactionFilter}
          transactions={activeTransactions}
        />
        <TopCompanies nbpRates={nbpRates} transactionFilter={transactionFilter} transactions={activeTransactions} />
      </TwoColumnsLayout>
      <TotalCharge nbpRates={nbpRates} transactions={activeTransactions} />
      <TwoColumnsLayout>
        <Orders nbpRates={nbpRates} transactions={orderTransactions} />
        <ExchangeRateSimulation nbpRates={nbpRates} transactions={activeTransactions} />
      </TwoColumnsLayout>
    </>
  );
};
