export const inputErrors: { [key: string]: string } = {
  // Generic errors
  required: "This field is required.",
  unique: "This field must be unique.",

  // Company errors
  isNIPValid: "Incorrect NIP.",
  isREGONValid: "Incorrect REGON number.",

  // Contact errors
  isPESELValid: "Incorrect PESEL number.",
  typeError: "Field value has incorrect type.",

  // Hedges errors
  min: "Has to be positive number",
  isDateBeforeEndDate: "Invalid date.",
  isEndDateValid: "Invalid date.",
  isQuantityMoreThanZero: "This field is required.",
  isPointNumeric: "This field is required.",

  // Login errors
  invalidEmail: "Invalid e-mail. Check your e-mail address.",
  wrongPassword: "Wrong password. Please try again",
  userNotFound: "User not found or has been deleted.",
  tooManyRequests: "Too many unsuccessful login attempts. Please try again later.",
  weakPassword: "Password is too weak. Please setup strong password.",
  expiredActionCode: "Verification code expired. Please request reset password again.",
  invalidActionCode: "Verification code is invalid.",
  samePasswords: "Both passwords must be the same.",
  missingCode: "Verification code missing. Please follow e-mail link",
  isDateSameOrAfter: "Invalid date.",

  // Bank account errors
  isBankAccountNumberValid: "Bank account number is not valid.",
};
