import * as yup from "yup";

export const settlementSchema = yup.object().shape({
  date: yup.object(),
  type: yup.string().trim(),
  quantity: yup.number().required(),
  fwdPoints: yup.number(),
  rollback: yup.number(),
  points: yup.number(),
  comment: yup.string().trim(),
  contacts: yup.array().of(
    yup.object().shape({
      quantity: yup.number(),
      hedgeId: yup.string().trim(),
    })
  ),
});
