import { MarginCallExtended } from "interfaces/data";
import { UseMarginCalls } from "interfaces/fetchers";
import { useSearch } from "./useSearch";
import { z } from "zod";
import { MarginCallSchema } from "schemas/marginCall";
import { TransactionSchema } from "schemas/transaction";

export const useMarginCalls = ({
  phrase,
  fields,
  orderBy,
  orderDirection,
  filtersAnd,
  offset,
  limit,
}: UseMarginCalls) => {
  const { data, totalCount, pageCount, loading, error, refetch } = useSearch<MarginCallExtended>({
    collection: "margin-calls",
    phrase,
    fields,
    orderBy,
    orderDirection,
    filtersAnd,
    offset,
    limit,
    skipAutoUpdate: true,
  });

  const schema = z.array(
    MarginCallSchema.extend({
      transaction: TransactionSchema.omit({ marginCalls: true }),
    })
  );

  const marginCalls = schema.parse(data);

  return { marginCalls, totalCount, pageCount, loading, error, refetch };
};
