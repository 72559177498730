import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Modal } from "components/Modal/Modal";
import { formatNumberToString } from "helpers/formatNumber";
import { Company, GlobalMarginCall, Margin } from "interfaces/data";
import {
  getMarginCallBalance,
  getSumsOfCurrenciesInMarginCall,
  withdrawMarginFromMarginCall,
} from "helpers/marginCall";
import { useNbpRates } from "hooks/useNbpRates";
import { StyledLabel, StyledValue } from "./MarginCallSettlementModal.styled";
import { MarginList } from "./components/MarginList";
import { WithdrawQuantity } from "./components/WithdrawQuantity";
import { formatDate } from "../../../helpers/date";
import { firestore } from "firebase";
import { Input } from "../../../components/Input/Input";

interface GlobalMarginCallMarginWithdrawModalProps {
  company: Company;
  marginCall: GlobalMarginCall;
  onClose: () => void;
  onSave: (updatedCompany: Company) => void;
}

interface Withdrawal {
  currency: string;
  amount: number;
}

export const StyledWarning = styled.div`
  margin: 10px 0;
  color: ${(props) => props.theme.colors["sk-red"]};
  font-size: 13px;
  font-weight: 900;
`;

export const GlobalMarginCallMarginWithdrawModal: React.FC<GlobalMarginCallMarginWithdrawModalProps> = ({
  company,
  marginCall,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [selectedMarginId, setSelectedMarginId] = useState<string | null>(null);
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [quantity, setQuantity] = useState<number>();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [date, setDate] = useState(new Date());
  const [comment, setComment] = useState<string>();
  const { rates: nbpRates } = useNbpRates();

  // filter out margins that are not in the margin call
  const margins = useMemo(() => {
    return (
      company.globalMargins?.filter(
        (globalMargin) => globalMargin.marginCallId === marginCall.id && globalMargin.left.quantity
      ) || []
    );
  }, [company, marginCall]);

  const selectedMargin = useMemo(() => {
    return margins.find((margin) => margin.id === selectedMarginId) || null;
  }, [margins, selectedMarginId]);

  const coveredValueInCurrencies = getSumsOfCurrenciesInMarginCall(marginCall);
  const maxValidQuantity = selectedMargin ? Number(selectedMargin.left.quantity) : 0;

  // if there is only one margin assigned - automatically select this margin
  useEffect(() => {
    if (margins.length === 1) {
      handleMarginSelect(margins[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSaveAction() {
    onSave(company);
    setIsConfirmed(false);
  }

  function handleMarginSettlement() {
    if (!selectedMargin || !quantity) return;

    withdrawMarginFromMarginCall(marginCall, selectedMargin, Number(quantity), date, comment);
    setWithdrawals([...withdrawals, { currency: selectedMargin.from.currency, amount: Number(quantity) }]);
    setComment("");
    setDate(new Date());
  }

  function getMarginLeftQuantity(marginId: string) {
    if (!marginCall) return 0;
    return margins ? margins.find((margin) => margin.id === marginId)?.left.quantity ?? 0 : 0;
  }

  function handleMarginSelect(margin: Margin) {
    if (selectedMargin && selectedMargin.id === margin.id) {
      setSelectedMarginId(null);
      setQuantity(undefined);
    } else {
      setSelectedMarginId(margin.id);
      setQuantity(getMarginLeftQuantity(margin.id));
    }
  }

  if (!nbpRates) return null;

  return (
    <>
      <Modal
        confirmText={t("Confirm")}
        design="danger"
        isOpen={isConfirmed}
        onClose={() => setIsConfirmed(false)}
        onConfirm={handleSaveAction}
        title={t("Withdraw margin")}
      >
        <Box mb={"20px"}>{t("company:Are you sure that you want to withdraw margin?")}</Box>
      </Modal>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isOpen
        justifyButtons="flex-end"
        minWidth={840}
        onClose={() => onClose()}
        onConfirm={() => setIsConfirmed(true)}
        title={t(`marginCall:{{marginCallId}} - withdraw`, {
          marginCallId: marginCall.id,
        })}
      >
        <MarginList
          marginReferences={marginCall.margins}
          margins={margins}
          onSelect={handleMarginSelect}
          selectedMargin={selectedMargin}
        />

        {selectedMargin && (
          <>
            <WithdrawQuantity
              amount={Number(quantity)}
              currency={selectedMargin.from.currency}
              isQuantityValid={Number(quantity) > 0 && Number(quantity) <= maxValidQuantity}
              isWithdrawValid={Number(quantity) > 0 && Number(quantity) <= maxValidQuantity && Boolean(comment)}
              onValueChange={setQuantity}
              onWithdrawClick={handleMarginSettlement}
            />
            <Box display="flex" mb="15px">
              <Box flex={0.33} mr="20px">
                <Input
                  id="date"
                  label={t("transaction:Date")}
                  labelProps={{ pl: "15px" }}
                  name="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const date = new Date(e.target.value);
                    setDate(date);
                  }}
                  type="date"
                  value={formatDate(firestore.Timestamp.fromDate(date))}
                />
              </Box>
              <Box flex={1}>
                <Input
                  error={!(comment === undefined || Boolean(comment)) ? "error" : undefined}
                  label={"Comment"}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setComment(e.target.value);
                  }}
                  value={comment}
                />
              </Box>
            </Box>
          </>
        )}

        <Box display="flex" justifyContent="flex-end">
          {Boolean(withdrawals.length) && (
            <Box display="inline-block">
              <StyledLabel>Withdrawn</StyledLabel>
              <StyledValue withdrawn>
                -{formatNumberToString(withdrawals.reduce((sum, withdrawal) => sum + withdrawal.amount, 0))}
                &nbsp;{withdrawals[0].currency}
              </StyledValue>
            </Box>
          )}
        </Box>

        <Box display="flex" justifyContent="flex-end">
          {coveredValueInCurrencies.map((value, i) => (
            <Box display="inline-block" key={i} ml="20px">
              <StyledLabel>{value.currency}</StyledLabel>
              <StyledValue>{formatNumberToString(value.sum)}</StyledValue>
            </Box>
          ))}
          <Box display="inline-block" ml="20px">
            <StyledLabel>Balance</StyledLabel>
            <StyledValue>{formatNumberToString(getMarginCallBalance(marginCall, nbpRates))} PLN</StyledValue>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
