import app from "firebase/app";
import { useCallback, useState } from "react";
import { BaseProfile, Profile } from "../interfaces/data";
import { useDatabase } from "./useDatabase";
import firebase, { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { useValidation } from "./useValidation";
import { profileSchema } from "../validations/profile";
import { config } from "../Firebase";
import { useAuth } from "./useAuth";
import { useToast } from "./useToast";
import { useTranslation } from "react-i18next";

const COLLECTION = "profiles";

export const useProfiles = () => {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { validate, errors } = useValidation(profileSchema);
  const { db, auth } = useFirebase();
  const { token, logout, profile: currentUserProfile } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  const [fetchProfiles] = useDatabase("profiles", (collection: any) => {
    collection.get().then((snap: firestore.DocumentData) => {
      const profiles: Profile[] = snap.docs.map((doc: any) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });

      setLoading(false);
      setProfiles(profiles);
    });
  });

  const updateApplicationCustomClaims = useCallback(
    async (userId: string) => {
      await fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/users/${userId}/applicationCustomClaims`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    [token]
  );

  const create = useCallback(
    async (newProfile: BaseProfile) => {
      // We are using secondary, "createProfileApp" firebase to make sure that firebase is not automatically
      // logging current user to newly created account. We create new user account with email and random password,
      // then we reset password and finally we create and profile and add link to the user account

      setLoading(true);
      const collection = db.collection(COLLECTION);
      const isValid = await validate(newProfile);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const createProfileApp = await app.initializeApp(config, "createProfileApp");

      try {
        const { user } = await createProfileApp
          .auth()
          .createUserWithEmailAndPassword(newProfile.email, Math.random().toString(36).slice(2));
        if (!user) {
          return false;
        }
        newProfile.userId = user.uid;
        createProfileApp.auth().sendPasswordResetEmail(newProfile.email);
        const result = await collection.add(newProfile);
        await updateApplicationCustomClaims(user.uid);
        return Boolean(result);
      } finally {
        createProfileApp.delete();
        setLoading(false);
      }
    },
    [db, validate, updateApplicationCustomClaims]
  );

  const update = useCallback(
    async (newProfile: Profile) => {
      setLoading(true);

      const { id, ...data } = newProfile;
      const collection = db.collection(COLLECTION);
      const isValid = await validate(newProfile);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const user = auth.currentUser;
      // if update is for current user and mail has been updated - update email in firebase
      if (user && user.uid === data.userId && user.email !== data.email) {
        await user.updateEmail(data.email);
      }

      return collection
        .doc(id)
        .update(data)
        .then(async () => {
          await updateApplicationCustomClaims(data.userId);
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useProfiles", e);
          setLoading(false);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateApplicationCustomClaims]
  );

  const remove = useCallback(async (profile: Profile) => {
    setLoading(true);

    const collection = db.collection(COLLECTION);

    return Promise.all([
      collection.doc(profile.id).delete(),
      fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/users/delete/${profile.userId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    ])
      .then((responses) => {
        // Logout user if unauthorized
        if (responses[1].status === 401) {
          logout();
          return false;
        }

        // Logout user if he will delete himself from the list
        if (currentUserProfile?.userId === profile.userId) {
          logout();
          return true;
        }
      })

      .then(() => {
        setLoading(false);
        return true;
      })
      .catch((e) => {
        console.error("useProfiles", e);
        toast({
          type: "error",
          message: t("validation:User could not be deleted from firebase"),
        });
        setLoading(false);
        return false;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCompanies = async (profile: Profile) => {
    const { companies } = profile;

    if (!companies?.length) return [];

    const collection = db.collection("companies");
    const snapshot = await collection.where(firebase.firestore.FieldPath.documentId(), "in", companies).get();

    const returnArray: { id: string; name: string }[] = [];
    snapshot.forEach((doc) => {
      const { id } = doc;
      const data = doc.data();

      returnArray.push({ id, name: data.name });
    });

    return returnArray;
  };

  return {
    profiles,
    loading,
    errors,
    fetch: fetchProfiles,
    create,
    update,
    remove,
    first: undefined,
    last: undefined,
    fetchCompanies,
  };
};
