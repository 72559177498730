import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "components/Checkbox/Checkbox";
import { SpecialFilterTypes } from "../Transactions";
import { BoxIcon } from "../../../theme/icons";

interface SpecialFilterProps {
  specialFilters: Array<SpecialFilterTypes>;
  onChange?: (newSpecialFilter: Array<SpecialFilterTypes>) => void;
}

export const SpecialFilter: React.FC<SpecialFilterProps> = ({ specialFilters, onChange }) => {
  const { t } = useTranslation();

  const [filters, setFilters] = useState<Array<SpecialFilterTypes>>([]);

  const handleSpecialFiltersUpdate = useCallback(
    (filterKey: SpecialFilterTypes) => () => {
      const newSpecialFilters = specialFilters.includes(filterKey)
        ? specialFilters.filter((key) => key !== filterKey)
        : [...specialFilters, filterKey];
      setFilters(newSpecialFilters);
    },
    [specialFilters]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(filters);
    }
  }, [filters, onChange]);

  const RISK = "risk";
  const HEDGE_DEADLINE = "hedge-deadline";
  const PARTIALLY_HEDGED = "partially-hedged";
  const TRANSACTION_DEADLINE = "transaction-deadline";

  return (
    <>
      <Checkbox
        checkboxSize="small"
        isChecked={specialFilters.includes(RISK)}
        mb="32px"
        ml="auto"
        onChange={handleSpecialFiltersUpdate(RISK)}
      >
        <BoxIcon color="sk-red" mr="5px" mt="-3px" />
        {t(`transaction:Risk`)}
      </Checkbox>

      <Checkbox
        checkboxSize="small"
        isChecked={specialFilters.includes(HEDGE_DEADLINE)}
        mb="32px"
        ml="22px"
        onChange={handleSpecialFiltersUpdate(HEDGE_DEADLINE)}
      >
        <BoxIcon color="sk-purple" mr="5px" mt="-3px" />
        {t(`transaction:Hedge deadline`)}
      </Checkbox>

      <Checkbox
        checkboxSize="small"
        isChecked={specialFilters.includes(PARTIALLY_HEDGED)}
        mb="32px"
        ml="22px"
        onChange={handleSpecialFiltersUpdate(PARTIALLY_HEDGED)}
      >
        <BoxIcon color="sk-blue" mr="5px" mt="-3px" />
        {t(`transaction:Partially hedged`)}
      </Checkbox>

      <Checkbox
        checkboxSize="small"
        isChecked={specialFilters.includes(TRANSACTION_DEADLINE)}
        mb="32px"
        ml="22px"
        onChange={handleSpecialFiltersUpdate(TRANSACTION_DEADLINE)}
      >
        <BoxIcon color="sk-yellow" mr="5px" mt="-3px" />
        {t(`transaction:Transaction deadline`)}
      </Checkbox>
    </>
  );
};
