import { useFirebase } from "./useFirebase";
import { Company, Margin, Transaction } from "interfaces/data";

export interface MarginMigrationCommand {
  fromMargin: Margin;
  toMargin: Margin;
  transactionId: string;
  companyId: string;
}

export const useMarginMigration = () => {
  const { db } = useFirebase();

  async function findTransaction(transactionId: string) {
    const transaction = await db.collection("transactions").doc(transactionId).get();
    return transaction.data() as Transaction;
  }

  async function findCompany(companyId: string) {
    const company = await db.collection("companies").doc(companyId).get();
    return company.data() as Company;
  }

  const migrateMargin = async ({ fromMargin, toMargin, transactionId, companyId }: MarginMigrationCommand) => {
    // 1. find transaction
    const transaction = await findTransaction(transactionId);
    if (!transaction) throw new Error(`Transaction ${transactionId} not found`);

    // 2. find index of fromMargin in transaction margins
    if (!transaction.margins?.length) throw new Error(`Margin ${fromMargin.id} not found`);
    const fromMarginIndex = transaction.margins?.findIndex((margin) => margin.id === fromMargin.id);

    // 3. save transaction in db without fromMargin
    await db
      .collection("transactions")
      .doc(transactionId)
      .update({
        margins: [...transaction.margins.slice(0, fromMarginIndex), ...transaction.margins.slice(fromMarginIndex + 1)],
      });

    // 4. find company
    const company = await findCompany(companyId);

    // 5. check if toMargin already exists in company
    if (company.globalMargins?.find((margin) => margin.id === toMargin.id)) {
      throw new Error(`Margin ${toMargin.id} already exists in company ${companyId}`);
    }

    // 6. save company in db
    await db
      .collection("companies")
      .doc(companyId)
      .update({
        globalMargins: [...(company.globalMargins ?? []), toMargin],
      });
  };

  return { migrateMargin };
};
