import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Margin } from "interfaces/data";
import { useToast } from "hooks/useToast";
import { Modal } from "components/Modal/Modal";
import { useCompany } from "hooks/useCompany";
import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { formatNumberToString } from "helpers/formatNumber";
import { Checkbox } from "components/Checkbox/Checkbox";
import { StyledMarginsList, StyledMarginsListItem, StyledShowAllButton } from "./MarginMove.styled";

export function GlobalMarginMergeModal({
  companyId,
  marginId,
  handleClose,
}: {
  companyId: string;
  marginId: string;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { company, globalMarginUpdateUnsafe, globalMarginCancelUnsafe } = useCompany({
    id: companyId,
  });
  const [selectedMarginId, setSelectedMarginId] = useState<string | undefined>();
  const [isSending, setIsSending] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const numberOfCollapse = 5;

  const editedMargin = useMemo(
    () => company?.globalMargins?.find((margin) => margin.id === marginId),
    [company, marginId]
  );

  const selectedMargin = useMemo(
    () => company?.globalMargins?.find((margin) => margin.id === selectedMarginId),
    [company, selectedMarginId]
  );

  const handleOnSaveAction = useCallback(
    async (editedMargin: Margin) => {
      setIsSending(true);
      try {
        if (!selectedMargin) {
          throw new Error();
        }
        const clonedEditedMargin = _cloneDeep(selectedMargin);
        clonedEditedMargin.from.quantity =
          Number(clonedEditedMargin.from.quantity) + Number(editedMargin.from.quantity);
        clonedEditedMargin.to.quantity = Number(clonedEditedMargin.to.quantity) + Number(editedMargin.to.quantity);
        clonedEditedMargin.left.quantity =
          Number(clonedEditedMargin.left.quantity) + Number(editedMargin.left.quantity);

        await globalMarginCancelUnsafe(editedMargin.id);
        await globalMarginUpdateUnsafe(clonedEditedMargin);

        setIsSending(false);
        toast({
          type: "success",
          message: t("margin:Global margin has been moved successfully"),
        });
        handleClose();
      } catch (error) {
        setIsSending(false);
        if (error instanceof Error) {
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        }
      }
    },
    [globalMarginCancelUnsafe, globalMarginUpdateUnsafe, handleClose, t, toast, selectedMargin]
  );

  const visibleAvailableMargins = useMemo(() => {
    if (isExpanded) {
      return (
        company?.globalMargins?.filter(
          (gm) => gm.from.currency === editedMargin?.from.currency && gm.id !== marginId
        ) || []
      );
    }
    return (
      company?.globalMargins
        ?.filter((gm) => gm.from.currency === editedMargin?.from.currency && gm.id !== marginId)
        .slice(0, numberOfCollapse) || []
    );
  }, [company, isExpanded, editedMargin, marginId]);

  if (!editedMargin) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isDisabled={isSending || !selectedMarginId}
      isOpen
      justifyButtons="flex-end"
      minWidth={840}
      onClose={handleClose}
      onConfirm={() => handleOnSaveAction(editedMargin)}
      title={t("margin:Merge global variation margin with another")}
    >
      Merge to global margin:
      <StyledMarginsList>
        {visibleAvailableMargins.map((globalMargin) => {
          return (
            <StyledMarginsListItem
              key={globalMargin.id}
              onClick={(e) => {
                setSelectedMarginId(globalMargin.id);
                e.stopPropagation();
              }}
            >
              <Box display="flex">
                <Checkbox
                  checkboxSize="small"
                  isChecked={selectedMarginId === globalMargin.id}
                  isReadOnly
                  mr="10px"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
                <Box fontWeight="bold" pr="15px">
                  Id: {globalMargin.id}
                </Box>
                <Box pr="15px">
                  Qty: {globalMargin.from != null ? formatNumberToString(globalMargin.from.quantity) : ""}
                  {globalMargin.from.currency}
                </Box>
              </Box>
            </StyledMarginsListItem>
          );
        })}
        {(
          company?.globalMargins?.filter(
            (gm) => gm.from.currency === editedMargin?.from.currency && gm.id !== marginId
          ) || []
        ).length > numberOfCollapse &&
          !isExpanded && (
            <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
              {t("Show all")}
            </StyledShowAllButton>
          )}
      </StyledMarginsList>
    </Modal>
  );
}
