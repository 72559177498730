import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { Modal } from "components/Modal/Modal";
import { InputWithLabel } from "components/InputWithLabel/InputWithLabel";
import { SelectWithLabel } from "components/SelectWithLabel/SelectWithLabel";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { Dropdown } from "components/Dropdown/Dropdown";
import { useFirebase } from "hooks/useFirebase";
import { Hedge, Settlement, Transaction } from "interfaces/data";
import { DEAL_TYPE_SELL, DELIVERY_TYPES, getQuantityLeft } from "helpers/transaction";
import { formatDate, getTimestamp, transformTimestamp } from "helpers/date";
import { formatNumber, formatNumberToString } from "helpers/formatNumber";
import { Box } from "@chakra-ui/react";
import { Input } from "components/Input/Input";
import { TextareaWithLabel } from "components/TextareaWithLabel/TextareaWithLabel";
import {
  SelectedHedgeButton,
  SelectedHedgeLabel,
  SelectedHedgeListLabel,
  StyledAddButtonContainer,
  StyledClearButton,
  StyledCommentContainer,
  StyledDropdownItem,
  StyledHedgeSelectContainer,
  StyledLabel,
  StyledLabeledInput,
  StyledLinkPlaceholder,
  StyledLinksContainer,
  StyledSelectedHedgesList,
  StyledSelectedHedgesListItem,
  StyledSeparator,
  StyledSingleInfoContainer,
  StyledThreeFieldsGrid,
} from "./SettlementFormModal.styled";
import { calculateFwdForHedgeWithParents, calculatePointsForSettlement } from "helpers/settlement";
import { Button } from "components/Button/Button";
import { Checkbox } from "components/Checkbox/Checkbox";
import styled from "@emotion/styled";
import { useCompany } from "hooks/useCompany";
import dayjs from "dayjs";
import { activeHedges, isHedgePast } from "helpers/hedge";
import { AddIcon, RemoveIcon, ReverseIcon, SelectIcon } from "theme/icons";

const deliveries = Object.values(DELIVERY_TYPES);

const filteredAndSortedHedges = (transaction: Transaction, selectedHedgesArray: Array<HedgeWithRollback>) =>
  activeHedges(transaction.hedges || [], true)
    .filter((hedge) => {
      return selectedHedgesArray.findIndex((selectedHedge) => selectedHedge.id === hedge.id) === -1;
    })
    // Descending sort based on hedge end date
    .sort((hedgeA, hedgeB) => {
      return hedgeB.end.seconds - hedgeA.end.seconds;
    }) || [];

export const StyledCheckbox = styled.div`
  margin-top: 30px;
  text-align: right;
`;
interface SettlementFormModalProps {
  type: "add" | "edit";
  title: string;
  initialSettlement: Partial<Settlement>;
  transaction: Transaction;
  sendEmail?: boolean;
  sendEmailChange?: (newSendEmail: boolean) => void;
  errors: any;
  onSave: (newSettlement: Partial<Settlement>) => void;
  onClose: () => void;
  isDisabled?: boolean;
}

export interface HedgeWithRollback extends Hedge {
  rollback?: number;
}

export const SettlementFormModal: React.FC<SettlementFormModalProps> = ({
  type,
  title,
  initialSettlement,
  transaction,
  sendEmail = false,
  sendEmailChange,
  errors,
  onSave,
  onClose,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { timestampFrom } = useFirebase();
  const [settlement, setSettlement] = useState(initialSettlement);
  const [fromQuantity, setFromQuantity] = useState<number>(
    (initialSettlement.quantity || 0) * transaction.clientRate || 0
  );
  const [isFromQuantityDisabled, setIsFromQuantityDisabled] = useState(transaction.dealType === DEAL_TYPE_SELL);
  const [isHedgeDropdownOpen, setIsHedgeDropdownOpen] = useState(false);
  const [selectedHedge, setSelectedHedge] = useState<Hedge>();
  const [editedHedge, setEditedHedge] = useState<HedgeWithRollback>();
  const [selectedHedgesArray, setSelectedHedgesArray] = useState<Array<HedgeWithRollback>>([]);
  const [correctionRequired, setCorrectionRequired] = useState(false);
  const [reversePaymentLimitLeft, setReversePaymentLimitLeft] = useState(0);
  const [reversePaymentPossible, setReversePaymentPossible] = useState(false);

  const { company, getReversePaymentLimitLeft } = useCompany({
    id: transaction.company.id,
  });

  const handleUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setSettlement((oldEditedData) => {
      const newEditedData = { ...oldEditedData };
      const value = e.target.type === "number" ? Number(e.target.value) : e.target.value;
      if (e.target.name === "isReversePayment") {
        _set(newEditedData, "isReversePayment", e.target.checked);
      } else {
        _set(newEditedData, e.target.name, value);
      }
      return newEditedData;
    });
  }, []);

  const handleHedgeUpdate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditedHedge((oldSelectedHedge) => {
      const newSelectedHedge = { ...oldSelectedHedge };
      const value = e.target.type === "number" ? Number(e.target.value) : e.target.value;
      _set(newSelectedHedge, e.target.name, value);
      return newSelectedHedge as HedgeWithRollback;
    });
  }, []);

  const handleDateUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      setSettlement((oldEditedData) => {
        const newEditedData = { ...oldEditedData };
        const date = new Date(e.target.value);
        _set(newEditedData, e.target.name, timestampFrom(date));
        return newEditedData;
      });
    },
    [timestampFrom]
  );

  const quantityLeft = useMemo((): number => {
    const sumOfAllHedges = selectedHedgesArray.reduce((sum, hedge) => sum + (Number(hedge?.quantity) || 0), 0);
    return formatNumber((settlement.quantity || 0) - sumOfAllHedges);
  }, [settlement.quantity, selectedHedgesArray]);

  const isQtyValid = useMemo((): boolean => {
    if (!editedHedge || !selectedHedge) return true;

    // Because .toFixed returns a string we need to convert it back to Number type
    const editedHedgeQty = Number(Number(editedHedge.quantity).toFixed(2));
    return (
      editedHedgeQty <= Number(Number(settlement.quantity).toFixed(2)) &&
      editedHedgeQty <= Number(Number(selectedHedge.quantity).toFixed(2)) &&
      editedHedgeQty <= Number(Number(quantityLeft).toFixed(2))
    );
  }, [settlement.quantity, editedHedge, selectedHedge, quantityLeft]);

  const isSettlementQtyValid = useMemo((): boolean => {
    if (settlement.quantity == null) return true;
    if (type === "edit") {
      return formatNumber(settlement.quantity) <= getQuantityLeft(transaction) + Number(initialSettlement.quantity);
    }
    return Number(settlement.quantity) > 0;
  }, [initialSettlement.quantity, settlement.quantity, transaction, type]);

  const handleHedgeSelect = useCallback(
    (hedgeId?: string) => () => {
      const selectedHedge = transaction?.hedges?.find((hedge) => hedge.id === hedgeId);
      if (selectedHedge && hedgeId) {
        setSelectedHedge(selectedHedge);
        const newEditedHedge = _cloneDeep(selectedHedge);
        newEditedHedge.quantity = Math.min(selectedHedge.leftQuantity, quantityLeft);
        newEditedHedge.points = calculateFwdForHedgeWithParents(transaction.hedges || [], hedgeId).toNumber();
        setEditedHedge(newEditedHedge);
        setIsHedgeDropdownOpen(false);
      }
    },
    [transaction, quantityLeft]
  );

  const handleAddHedgeToList = useCallback(() => {
    if (selectedHedge && editedHedge) {
      setSelectedHedgesArray((selectedHedgesArray) => {
        const newSelectedHedgesArray = _cloneDeep(selectedHedgesArray);
        newSelectedHedgesArray.push(editedHedge);
        return newSelectedHedgesArray;
      });
      setSelectedHedge(undefined);
      setEditedHedge(undefined);
    }
  }, [selectedHedge, editedHedge]);

  const handleRemoveHedgeFromList = useCallback(
    (hedgeId: string) => () => {
      setSelectedHedgesArray((selectedHedgesArray) => {
        return _cloneDeep(selectedHedgesArray).filter((selectedHedge) => selectedHedge.id !== hedgeId);
      });
    },
    []
  );

  const getHedgeLabel = useCallback(
    (hedge: Hedge) => (
      <SelectedHedgeLabel>
        <span>{hedge.id}</span>
        <span>{hedge.institution}</span>
        <span>
          {transformTimestamp(hedge.start)}
          {" > "}
          {transformTimestamp(hedge.end)}
        </span>
        <span>
          <b>{formatNumberToString(hedge.quantity)}</b>
        </span>
        <span>{formatNumberToString(hedge.leftQuantity)}</span>
      </SelectedHedgeLabel>
    ),
    []
  );

  const getHedgeWithRollbackLabel = useCallback(
    (hedge: HedgeWithRollback) => (
      <SelectedHedgeListLabel>
        <span>{hedge.id}</span>
        <span>{hedge.institution}</span>
        <span>
          Qty: <b>{formatNumberToString(hedge.quantity)}</b>
        </span>
        <span>
          Fwd: <b>{hedge.points}</b>
        </span>
        <span>
          Roll: <b>{hedge.rollback}</b>
        </span>
      </SelectedHedgeListLabel>
    ),
    []
  );

  // update settlement when hedges are added to the list:
  useEffect(() => {
    const updatedSettlement = _cloneDeep(settlement);

    // // points
    const { points, rollback, fwdPoints } = calculatePointsForSettlement(selectedHedgesArray);

    // hedges
    const newHedges = selectedHedgesArray.map((selectedHedge) => ({
      hedgeId: selectedHedge.id,
      quantity: selectedHedge.quantity,
      fwd: selectedHedge.points,
      roll: selectedHedge.rollback || 0,
    }));

    // update NewSettlement
    updatedSettlement.points = Number(points) || 0;
    updatedSettlement.rollback = Number(rollback) || 0;
    updatedSettlement.fwdPoints = Number(fwdPoints) || 0;
    updatedSettlement.hedges = newHedges || [];
    setSettlement(updatedSettlement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHedgesArray]);

  useEffect(() => {
    if (transaction && isFromQuantityDisabled) {
      setFromQuantity(formatNumber((settlement.quantity || 0) * transaction.clientRate));
    }
  }, [settlement.quantity, transaction, isFromQuantityDisabled]);

  useEffect(() => {
    if (settlement.type === "Net") {
      setCorrectionRequired(false);
      sendEmailChange && sendEmailChange(false);
    }
  }, [sendEmailChange, settlement.type]);

  const handleFromQuantityUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (transaction) {
        const updatedSettlement = _cloneDeep(settlement);
        updatedSettlement.quantity = Number(e.target.value) / transaction.clientRate;

        setFromQuantity(Number(e.target.value));
        setSettlement(updatedSettlement);
      }
    },
    [transaction, settlement]
  );

  const handleClear = useCallback(() => {
    // Clear settlemen quantity, current selected hedge quantity, and hedges list if something was selected
    setSettlement({ ...settlement, quantity: 0 });
    setFromQuantity(0);
    setEditedHedge((prevHedge) => ({
      ...(prevHedge as HedgeWithRollback),
      quantity: 0,
    }));
    setSelectedHedgesArray([]);
  }, [settlement]);

  const handleMax = useCallback(() => {
    if (type === "edit" && initialSettlement.quantity) {
      setSettlement({
        ...settlement,
        quantity: getQuantityLeft(transaction) + Number(initialSettlement.quantity),
      });
    } else {
      setSettlement({ ...settlement, quantity: getQuantityLeft(transaction) });
    }
    setFromQuantity(formatNumber(getQuantityLeft(transaction) * transaction.clientRate));
  }, [initialSettlement.quantity, settlement, transaction, type]);

  const handleReverseActiveInput = useCallback(() => {
    setIsFromQuantityDisabled(!isFromQuantityDisabled);
  }, [isFromQuantityDisabled]);

  const availableHedgesList = useMemo(
    () =>
      filteredAndSortedHedges(transaction, selectedHedgesArray).map((hedge) => (
        <StyledDropdownItem
          design="link"
          key={hedge.id}
          muted={isHedgePast(hedge)}
          onClick={handleHedgeSelect(hedge.id)}
        >
          {getHedgeLabel(hedge)}
        </StyledDropdownItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedHedgesArray, transaction, quantityLeft]
  );

  const hedgeQtyError = useMemo(
    () => (Boolean(editedHedge && Number(editedHedge.quantity) <= 0 && selectedHedge) || !isQtyValid ? " " : undefined),
    [editedHedge, isQtyValid, selectedHedge]
  );

  // FOR EDIT - display list of used hedges
  useEffect(() => {
    if (transaction && initialSettlement && type === "edit") {
      const usedHedges =
        transaction.hedges?.filter((hedge) =>
          initialSettlement.hedges?.find((settlementHedge) => settlementHedge.hedgeId === hedge.id)
        ) || [];

      setSelectedHedgesArray(
        usedHedges.map((usedHedge) => {
          const values = initialSettlement.hedges?.find((settlementHedge) => settlementHedge.hedgeId === usedHedge.id);
          if (!values) return usedHedge;
          return {
            ...usedHedge,
            quantity: Number(values.quantity),
            points: Number(values.fwd),
            rollback: Number(values.roll),
          };
        })
      );
    }
  }, [initialSettlement, transaction, type]);

  // If quantity has been update and settlement is Physical, we need to send correction email
  useEffect(() => {
    if (settlement.quantity !== initialSettlement.quantity && type === "edit" && settlement.type === "Physical") {
      setCorrectionRequired(true);
      sendEmailChange && sendEmailChange(true);
    } else {
      setCorrectionRequired(false);
    }
  }, [settlement, initialSettlement, setCorrectionRequired, type, sendEmailChange]);

  // Get first hedge from array and make it selected by default
  useEffect(() => {
    handleHedgeSelect(filteredAndSortedHedges(transaction, selectedHedgesArray)[0]?.id)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // After adding hedge to the list, get first hedge from array and make it selected
  // Also disable quantity if some hedge will be added to the list
  useEffect(() => {
    if (selectedHedgesArray.length) {
      isFromQuantityDisabled && handleReverseActiveInput();
      handleHedgeSelect(filteredAndSortedHedges(transaction, selectedHedgesArray)[0]?.id)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHedgesArray.length]);

  // Update hedge quantity to max if quantity and from quantity will change
  useEffect(() => {
    if (quantityLeft && editedHedge) {
      const quantityParsed = Number(quantityLeft.toFixed(2));
      const selectedHedgeQuantity = editedHedge.leftQuantity;
      const quantity = quantityParsed > selectedHedgeQuantity ? selectedHedgeQuantity : quantityParsed;

      setEditedHedge((prevState) => ({
        ...(prevState as HedgeWithRollback),
        quantity,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityLeft]);

  useEffect(() => {
    const asyncReversePaymentValidCheck = async () => {
      if (company) {
        const newReversePaymentLimitLeft = await getReversePaymentLimitLeft(transaction.from.currency);
        if (type === "edit" && initialSettlement.isReversePayment) {
          setReversePaymentLimitLeft(newReversePaymentLimitLeft + Number(initialSettlement.quantity));
        } else {
          setReversePaymentLimitLeft(newReversePaymentLimitLeft);
        }
      }
    };
    asyncReversePaymentValidCheck();
  }, [
    company,
    getReversePaymentLimitLeft,
    initialSettlement.isReversePayment,
    initialSettlement.quantity,
    transaction.from.currency,
    type,
  ]);

  useEffect(() => {
    const newReversePaymentPossible = (settlement.quantity || 0) <= reversePaymentLimitLeft;
    if (!newReversePaymentPossible) {
      setSettlement((oldEditedData: any) => {
        const newEditedData = { ...oldEditedData };
        _set(newEditedData, "isReversePayment", false);
        return newEditedData;
      });
    }
    setReversePaymentPossible(newReversePaymentPossible);
  }, [reversePaymentLimitLeft, settlement.quantity]);

  if (!transaction || !company) {
    return null;
  }

  const addHedgeBtnDisabled = (editedHedge && Number(editedHedge.quantity) <= 0) || !selectedHedge || !isQtyValid;

  const saveButtonDisabled = !isSettlementQtyValid || isDisabled || quantityLeft < 0;

  return (
    <>
      <Modal
        confirmText={t("Save")}
        design="primary"
        isDisabled={saveButtonDisabled}
        isOpen
        justifyButtons="flex-end"
        minWidth={899}
        onClose={onClose}
        onConfirm={() => {
          if (
            transaction.start &&
            settlement.date &&
            dayjs(getTimestamp(transaction.start)).isAfter(dayjs(getTimestamp(settlement.date)), "day")
          ) {
            setShowConfirmation(true);
          } else {
            onSave(settlement);
          }
        }}
        title={title}
      >
        <TwoColumnsLayout withPaddingBottom>
          <InputWithLabel
            error={errors?.["date"]}
            id="date"
            label={t("settlement:Settlement date")}
            name="date"
            onChange={handleDateUpdate}
            type="date"
            value={formatDate(settlement.date)}
            withError={!!errors?.["date"]}
          />
          <SelectWithLabel
            error={errors?.["type"]}
            id="type"
            label={t("settlement:Delivery type")}
            name="type"
            onChange={handleUpdate}
            options={deliveries}
            value={settlement.type}
          />
        </TwoColumnsLayout>
        <TwoColumnsLayout withPaddingBottom>
          <InputWithLabel
            autoFocus={transaction.dealType === DEAL_TYPE_SELL}
            enableDataCopy={!isFromQuantityDisabled || Boolean(selectedHedgesArray.length)}
            error={!isSettlementQtyValid && !isDisabled && isFromQuantityDisabled ? " " : undefined}
            formatNumberOnBlur
            id="quantity"
            isDisabled={!isFromQuantityDisabled || Boolean(selectedHedgesArray.length)}
            label={t(`settlement:Quantity {{currency}}`, {
              currency: transaction.from.currency,
            })}
            name="quantity"
            onChange={handleUpdate}
            type="number"
            value={settlement.quantity || ""}
          />

          <InputWithLabel
            id="settlementStatus"
            isDisabled
            label={t(`settlement:Settlement status`)}
            name="settlementStatus"
            type="text"
            value="Settled"
          />
        </TwoColumnsLayout>
        <TwoColumnsLayout withPaddingBottom>
          <InputWithLabel
            autoFocus={transaction.dealType !== DEAL_TYPE_SELL}
            enableDataCopy={isFromQuantityDisabled}
            error={!isSettlementQtyValid && !isFromQuantityDisabled ? " " : undefined}
            formatNumberOnBlur
            id="fromQuantity"
            isDisabled={isFromQuantityDisabled}
            label={t(`settlement:Quantity {{currency}}`, {
              currency: transaction.to.currency,
            })}
            name="fromQuantity"
            onChange={handleFromQuantityUpdate}
            type="number"
            value={fromQuantity || ""}
          />
          <Box mt="14px">
            <button onClick={handleReverseActiveInput}>
              <ReverseIcon ml="-20px" mr="20px" />
            </button>
            <StyledClearButton onClick={handleClear}>{t(`settlement:clear`)}</StyledClearButton>
            <Box display="inline-block" ml="20px">
              <StyledClearButton onClick={handleMax}>{t(`settlement:max`)}</StyledClearButton>
            </Box>
            <Box display="inline-block" fontSize="12px" ml="30px">
              <Checkbox
                isChecked={Boolean(settlement.isReversePayment)}
                isDisabled={!reversePaymentPossible}
                name="isReversePayment"
                onChange={handleUpdate}
              >
                Send first
              </Checkbox>
            </Box>
          </Box>
        </TwoColumnsLayout>
        {settlement.type === "Net" && (
          <TwoColumnsLayout withPaddingBottom>
            <InputWithLabel
              formatNumberOnBlur
              formatNumberPrecision={4}
              id="closedRate"
              label={t(`settlement:Closed rate`)}
              name="closedRate"
              onChange={handleUpdate}
              type="number"
              value={settlement.closedRate}
            />
          </TwoColumnsLayout>
        )}
        <StyledSeparator />

        {/* Settement popup for SPOT transaction should have no middle section with Hedges - as there are no Hedges in SPOT */}
        {transaction.type === "FR" && (
          <>
            <TwoColumnsLayout>
              <StyledSingleInfoContainer>
                <div>{t(`settlement:Quantity left`)}:</div>
                <div>{`${formatNumberToString(quantityLeft)} ${transaction.from.currency}`}</div>
              </StyledSingleInfoContainer>
              <StyledSingleInfoContainer>
                <div>{t(`settlement:Points`)}:</div>
                <div>{settlement.points?.toFixed(4)}</div>
              </StyledSingleInfoContainer>
            </TwoColumnsLayout>

            {/* HEDGE SELECT */}
            <StyledHedgeSelectContainer>
              <div>{t("hedge:Hedge")}</div>
              <Dropdown
                isDisabled={!isSettlementQtyValid}
                isOpen={isHedgeDropdownOpen}
                marginTop="-5px !important"
                maxHeight="230px"
                maxWidth="720px"
                onClose={() => setIsHedgeDropdownOpen(false)}
                overflowX="auto"
                padding="0"
                placement="left"
                trigger={
                  <SelectedHedgeButton onClick={() => setIsHedgeDropdownOpen(!isHedgeDropdownOpen)}>
                    {selectedHedge ? getHedgeLabel(selectedHedge) : ""}
                    <SelectIcon ml="auto" />
                  </SelectedHedgeButton>
                }
                width="720px"
                zIndex={1404}
              >
                {availableHedgesList}
              </Dropdown>
            </StyledHedgeSelectContainer>

            <StyledThreeFieldsGrid>
              <StyledLabeledInput>
                <StyledLabel>{t(`settlement:Qty.`)}</StyledLabel>
                <Input
                  error={hedgeQtyError}
                  formatNumberOnBlur
                  id="quantity"
                  isDisabled={!selectedHedge}
                  name="quantity"
                  onChange={handleHedgeUpdate}
                  type="number"
                  value={editedHedge?.quantity}
                />
              </StyledLabeledInput>
              <StyledLabeledInput>
                <StyledLabel>{t(`settlement:Fwd.`)}</StyledLabel>
                <Input
                  formatNumberOnBlur
                  formatNumberPrecision={4}
                  id="points"
                  isDisabled
                  name="points"
                  onChange={handleHedgeUpdate}
                  step="0.1"
                  type="number"
                  value={editedHedge?.points}
                />
              </StyledLabeledInput>
              <StyledLabeledInput>
                <StyledLabel>{t(`settlement:Roll.`)}</StyledLabel>
                <Input
                  formatNumberOnBlur
                  formatNumberPrecision={4}
                  id="rollback"
                  isDisabled={!selectedHedge}
                  name="rollback"
                  onChange={handleHedgeUpdate}
                  step="0.1"
                  type="number"
                  value={editedHedge?.rollback}
                />
              </StyledLabeledInput>
            </StyledThreeFieldsGrid>

            <StyledAddButtonContainer>
              <Button design="primary" isDisabled={addHedgeBtnDisabled} ml="auto" onClick={handleAddHedgeToList}>
                <AddIcon mr="10px" />
                {t("Add")}
              </Button>
            </StyledAddButtonContainer>

            <StyledSelectedHedgesList>
              {selectedHedgesArray.map((selectedHedge) => (
                <StyledSelectedHedgesListItem key={selectedHedge.id}>
                  {getHedgeWithRollbackLabel(selectedHedge)}
                  <button onClick={handleRemoveHedgeFromList(selectedHedge.id)}>
                    <RemoveIcon mt="-2px" />
                  </button>
                </StyledSelectedHedgesListItem>
              ))}
            </StyledSelectedHedgesList>
          </>
        )}
        <StyledCommentContainer>
          <TextareaWithLabel
            error={errors?.["comment"]}
            id="comment"
            label={t("settlement:Comment")}
            labelProps={{
              paddingRight: "10px",
            }}
            name="comment"
            onChange={handleUpdate}
            value={settlement?.comment}
            withError={!!errors?.["comment"]}
          />
          <StyledLinksContainer>
            <StyledLinkPlaceholder>{t("settlement:Hedge history")}</StyledLinkPlaceholder>
            <StyledLinkPlaceholder>{t("settlement:Visualisation")}</StyledLinkPlaceholder>
          </StyledLinksContainer>
        </StyledCommentContainer>
        {transaction.type === "FR" && settlement.type === "Physical" && (
          <StyledCheckbox>
            <Checkbox
              fontSize="15px"
              fontWeight="bold"
              isChecked={sendEmail}
              isDisabled={correctionRequired}
              onChange={(e) => typeof sendEmailChange === "function" && sendEmailChange(e.target.checked)}
            >
              {t(`transaction:${type === "edit" ? "Send e-mail correction" : "Send e-mail confirmation"}`)}
            </Checkbox>
          </StyledCheckbox>
        )}
      </Modal>
      <Modal
        design="danger"
        isChild
        isOpen={showConfirmation}
        minWidth={550}
        onClose={() => setShowConfirmation(false)}
        onConfirm={() => onSave(settlement)}
        title={t(`hedge:Invalid settlement date`)}
      >
        {t("transaction:Are you sure you want to settle transaction before the transaction start date?")}
      </Modal>
    </>
  );
};
