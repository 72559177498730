import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

interface SearchResultProps {
  title: string;
  leftSecondayContent?: Array<string>;
  rightSecondayContent?: Array<string>;
  url: string;
  onClick: () => void;
}

export const StyledSearchResult = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background: white;
  padding: 12px;
  min-height: 64px;
`;

export const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  margin-bottom: 8px;
`;
export const StyledSecondaryLeftText = styled.div`
  color: #718096;
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
`;

export const StyledSecondaryRightText = styled(StyledSecondaryLeftText)`
  margin-left: auto;
  padding-left: 10px;
  > span {
    padding-left: 10px;
  }
`;

export const SearchResult: React.FC<SearchResultProps> = ({
  title,
  leftSecondayContent,
  rightSecondayContent,
  url,
  onClick,
}) => {
  return (
    <Link onClick={onClick} to={url}>
      <StyledSearchResult>
        <StyledTitle>{title}</StyledTitle>
        <Box display="flex">
          <StyledSecondaryLeftText>{leftSecondayContent}</StyledSecondaryLeftText>
          <StyledSecondaryRightText>
            {rightSecondayContent?.map((content, i) => (
              <span key={i}>{content}</span>
            ))}
          </StyledSecondaryRightText>
        </Box>
      </StyledSearchResult>
    </Link>
  );
};
