import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { BankAccount } from "../interfaces/data";
import { useValidation } from "./useValidation";
import { bankAccountSchema } from "../validations/bankAccount";
import { useLogs } from "./useLogs";

const COLLECTION = "bankAccounts";

export const useBankAccounts = () => {
  const [bankAccounts, setBankAccounts] = useState<Array<BankAccount>>([]);
  const [loading, setLoading] = useState(true);
  const { validate, errors, clearErrors } = useValidation(bankAccountSchema);
  const { db } = useFirebase();
  const { log } = useLogs();

  const fetch = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      collection.get().then((snap: firestore.DocumentData) => {
        const bankAccounts = [] as Array<BankAccount>;
        snap.forEach((doc: any) => {
          bankAccounts.push({ id: doc.id, ...doc.data() });
        });
        setBankAccounts(bankAccounts);
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const save = useCallback(
    async (newBankAccount: BankAccount) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      const isValid = await validate(newBankAccount);

      if (!isValid) {
        setLoading(false);
        return false;
      }
      return collection
        .add(newBankAccount)
        .then(async (docRef) => {
          if (newBankAccount.isDefault) {
            const batch = db.batch();
            const oldDefaultBankAccountsRef = collection
              .where("isDefault", "==", true)
              .where("currency", "==", newBankAccount.currency);

            const snap = await oldDefaultBankAccountsRef.get();

            if (snap) {
              snap.forEach((doc) => {
                if (docRef.id !== doc.id) {
                  batch.update(doc.ref, {
                    isDefault: false,
                  });
                }
              });
            }

            await batch.commit();
          }
          await log({
            action: "create",
            item: {
              collection: "bankAccounts",
              id: newBankAccount.name,
            },
            newData: JSON.stringify(newBankAccount),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useBankAccounts", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async (newBankAccount: BankAccount) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);
      const { id, ...data } = newBankAccount;

      const isValid = await validate(data);

      if (!isValid) {
        setLoading(false);
        return false;
      }

      const oldBankAccount = await collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.data() as BankAccount;
          }
        });

      return collection
        .doc(id)
        .update(data)
        .then(async () => {
          if (newBankAccount.isDefault) {
            const batch = db.batch();
            const oldDefaultBankAccountsRef = collection
              .where("isDefault", "==", true)
              .where("currency", "==", newBankAccount.currency);

            const snap = await oldDefaultBankAccountsRef.get();

            if (snap) {
              snap.forEach((doc) => {
                if (doc.id !== newBankAccount.id) {
                  batch.update(doc.ref, {
                    isDefault: false,
                  });
                }
              });
            }

            await batch.commit();
          }
          await log({
            action: "edit",
            item: {
              collection: "bankAccounts",
              id: newBankAccount.name,
            },
            oldData: JSON.stringify({ ...oldBankAccount, id }),
            newData: JSON.stringify(newBankAccount),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useBankAccounts", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const remove = useCallback(
    async (bankAccount: BankAccount) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      return collection
        .doc(bankAccount.id)
        .delete()
        .then(async () => {
          await log({
            action: "delete",
            item: {
              collection: "bankAccounts",
              id: bankAccount.name,
            },
            oldData: JSON.stringify(bankAccount),
          });
          setLoading(false);
          return true;
        })
        .catch((e) => {
          console.error("useBankAccounts", e);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bankAccounts,
    refetch: fetch,
    loading,
    save,
    update,
    remove,
    errors,
    clearErrors,
  };
};
