import { NbpRateRecord, Transaction } from "interfaces/data";
import dayjs from "dayjs";
import { getMilisecondsFromTimestamp } from "./date";
import { calculateFwdForHedgeWithParents } from "./settlement";

export interface TransactionCommitment {
  hedgesProfit: number;
  spreadProfit: number;
  clientLoss: number;
}

export const transactionCommitment = (
  transaction: Transaction,
  nbpRates: NbpRateRecord | undefined
): TransactionCommitment => {
  const transactionFromCurrency = transaction.from.currency;
  const transactionToCurrency = transaction.to.currency;
  let hedgesProfit = 0;
  let spreadProfit = 0;
  let clientLoss = 0;

  // 0. current date
  const date = dayjs();

  // 1. count spread
  const { ourRate, clientRate } = transaction;
  const spread = transaction.dealType === "Sell" ? ourRate - clientRate : clientRate - ourRate;

  // 2. count client loss
  const nbpRate = nbpRates?.rates[transactionFromCurrency] ?? 0;
  const clientLossSpread = transaction.dealType === "Sell" ? clientRate - nbpRate : nbpRate - clientRate;

  // 3. iterate over hedges to calculate hedges and spread profit
  if (transaction.hedges?.length) {
    const hedgesIntoConsideration = transaction.hedges.filter((hedge) => {
      const hedgeStart = dayjs(getMilisecondsFromTimestamp(hedge.start));
      const hedgeEnd = dayjs(getMilisecondsFromTimestamp(hedge.end));
      return hedgeStart.isBefore(date.endOf("day")) && hedgeEnd.isSameOrAfter(date.startOf("day"));
    });

    hedgesIntoConsideration.forEach((hedge) => {
      // 1. calculate points per day
      const hedgeStart = dayjs(getMilisecondsFromTimestamp(hedge.start));
      const hedgeEnd = dayjs(getMilisecondsFromTimestamp(hedge.end));
      const daysInHedge = hedgeEnd.diff(hedgeStart, "day");
      const pointsPerDay = hedge.points / daysInHedge;

      // 2. calculate points till date
      const pointsTillDate = pointsPerDay * date.diff(hedgeStart, "day");

      // 3. calculate profit
      const rate = transactionToCurrency === "PLN" ? 1 : nbpRates?.rates[transactionFromCurrency];
      const profit = pointsTillDate * (rate ?? 0) * hedge.leftQuantity;
      const rolledPoints =
        calculateFwdForHedgeWithParents(transaction.hedges ?? [], hedge.id).toNumber() - hedge.points;
      const rolledProfit = rolledPoints * (rate ?? 0) * hedge.leftQuantity;

      // 4. summarize everything
      hedgesProfit += profit + rolledProfit;
      spreadProfit += spread * (rate ?? 0) * hedge.leftQuantity;
      clientLoss += clientLossSpread * (rate ?? 0) * hedge.leftQuantity;
    });
  }

  return {
    hedgesProfit,
    spreadProfit,
    clientLoss: clientLoss < 0 ? clientLoss : 0,
  };
};
