import { z } from "zod";
import { TimestampSchema } from "./common";

export const SettlementSchema = z.object({
  id: z.string(),
  createdAt: TimestampSchema.optional(),
  modifiedAt: TimestampSchema.optional(),
  date: TimestampSchema,
  type: z.enum(["Net", "Physical"]),
  quantity: z.number(),
  fwdPoints: z.number(),
  rollback: z.number(),
  points: z.number(),
  comment: z.string().optional(),
  paymentComment: z.string().optional(),
  isReversePayment: z.boolean().optional(),
  isSent: z.boolean().optional(),
  isDone: z.boolean().optional(),
  isReversePaymentOk: z.boolean().optional(),
  closedRate: z.number().optional(),
  hedges: z.array(
    z.object({
      hedgeId: z.string(),
      quantity: z.number(),
      fwd: z.number(),
      roll: z.number(),
    })
  ),
});

export const SettlementWithOptionalIdSchema = SettlementSchema.partial({ id: true });
