import { z } from "zod";
import { CurrencySchema, TimestampSchema, YesNoSchema } from "./common";

export const MarginCallSchema = z.object({
  id: z.string(),
  createdAt: TimestampSchema.optional(),
  modifiedAt: TimestampSchema.optional(),
  createdFromId: z.string().optional(),
  callDate: TimestampSchema.optional(),
  callRate: z.number(),
  clientRate: z.number().optional(),
  transQuantity: z.number(),
  quantity: z.number(),
  isClosed: YesNoSchema.optional(),
  isGMC: YesNoSchema.optional(),
  totalPaidMargin: z
    .object({
      quantity: z.number(),
      currency: CurrencySchema,
    })
    .optional(),
  globalTransactionsIds: z.array(z.object({ id: z.string(), number: z.number() })).optional(),
  isPaid: z.boolean().optional(),
  comment: z.string().optional(),
  tableComment: z.string().optional(),
  margins: z
    .array(
      z.object({
        marginId: z.string(),
        isGlobal: z.boolean().optional(),
        quantity: z.number(),
        currency: CurrencySchema,
      })
    )
    .optional(),
  finalNoticeSentAt: TimestampSchema.optional(),
});

export const GlobalMarginCallSchema = MarginCallSchema.extend({
  currency: CurrencySchema,
  dealType: z.enum(["Sell", "Buy"]),
});

export type ZodMarginCall = z.infer<typeof MarginCallSchema>;

export const OptionalIDMarginCallSchema = MarginCallSchema.partial({
  id: true,
});
export type ZodOptionalIDMarginCall = z.infer<typeof OptionalIDMarginCallSchema>;
