import React, { useCallback, useMemo, useState } from "react";
import { Settlement } from "../../interfaces/data";
import { useHistory, useParams } from "react-router-dom";
import { useTransaction } from "../../hooks/useTransaction";
import { useTranslation } from "react-i18next";
import { SettlementHedgesFormModal } from "./SettlementFormModal/SettlementHedgesFormModal";
import { useToast } from "../../hooks/useToast";

export function SettlementEditHedges() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, settlementId } = useParams<{
    id: string;
    settlementId: string;
  }>();
  const { transaction, settlementUpdate, settlementErrors } = useTransaction({
    id,
  });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const editedSettlement = useMemo(() => {
    if (transaction) {
      return transaction.settlements?.find((settlement) => settlement.id === settlementId);
    }
  }, [settlementId, transaction]);

  const handleOnSaveAction = useCallback(
    (newSettlement: Settlement) => {
      if (!editedSettlement) return;
      setIsSending(true);
      settlementUpdate(newSettlement, editedSettlement)
        .then((data) => {
          setIsSending(false);
          if (data) {
            toast({
              type: "success",
              message: t("settlement:Settlement has been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`settlement:${error.message}`),
          });
        });
    },
    [editedSettlement, settlementUpdate, toast, t, handleClose]
  );

  if (!transaction || !editedSettlement) return null;

  return (
    <SettlementHedgesFormModal
      errors={settlementErrors}
      initialSettlement={editedSettlement}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t(`settlement:Edit hedges for settlement {{settlementId}}`, {
        settlementId: settlementId,
        transactionNumber: transaction.number,
      })}
      transaction={transaction}
      type="edit"
    />
  );
}
