import styled from "@emotion/styled";

export const StyledStatisticDivider = styled.hr`
  width: 5px;
  height: 80px;
  background: #ececec;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
`;

export const StyledStatisticsSection = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 150px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
`;

export const StyledStatisticItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`;

export const StyledStatisticItemTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
  color: #818181;
`;

export const StyledStatisticItemMain = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  text-align: center;
  color: #252527;
  margin-top: 10px;

  svg {
    * {
      font-size: 16px !important;
    }
  }
  #gauge-chart1 {
    width: 300px !important;
  }
`;
