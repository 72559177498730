import { Contact } from "../interfaces/data";
import { UseContacts } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useContacts = ({
  phrase,
  fields,
  orderBy,
  orderDirection,
  offset,
  limit,
  skipAutoUpdate,
  isLazy,
}: UseContacts) => {
  const {
    data: contacts,
    totalCount,
    pageCount,
    loading,
    error,
    remove,
    refetch,
  } = useSearch<Contact>({
    collection: "contacts",
    phrase,
    fields,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate,
    isLazy,
  });

  return { contacts, totalCount, pageCount, loading, error, remove, refetch };
};
