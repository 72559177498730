import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import { StyledClearButton } from "./shared.styled";
import { Input } from "../../../components/Input/Input";
import dayjs from "dayjs";

interface AgreementDateFilterProps {
  agreementDateFilter?: string;
  onChange?: (newAgreementDateFilter: string) => void;
  defaultOpen?: boolean;
  header: string;
  clearAllFlag?: boolean;
  setClearAllFlag?: (isClear: boolean) => void;
}

export const AgreementDateFilter: React.FC<AgreementDateFilterProps> = ({
  agreementDateFilter = "",
  onChange,
  clearAllFlag = false,
  setClearAllFlag,
}) => {
  const { t } = useTranslation();
  const [defaultStartDate = "", defaultEndDate = ""] = agreementDateFilter.split(",");
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    if (clearAllFlag && setClearAllFlag) {
      setEndDate("");
      setStartDate("");
      setClearAllFlag(false);
    }
  }, [clearAllFlag, setClearAllFlag]);

  useEffect(() => {
    if (typeof onChange === "function") {
      let _startDate = startDate;
      let _endDate = endDate;
      const yearsMarginNumber = 100;
      if (startDate === "" && endDate === "") {
        onChange("");
        return;
      }
      if (startDate === "") {
        _startDate = dayjs(new Date()).subtract(yearsMarginNumber, "year").format("YYYY-MM-DD");
      }
      if (endDate === "") {
        _endDate = dayjs(new Date()).add(yearsMarginNumber, "year").format("YYYY-MM-DD");
      }
      onChange(`${_startDate},${_endDate}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <>
      <Box alignItems="center" display="flex" justifyContent="space-between" mb="20px">
        <Box mr="10px">
          <Input
            borderColor={startDate !== "" ? "sk-purple" : "sk-light-gray"}
            id="startDate"
            label={t("transaction:From")}
            name="startDate"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setStartDate(e.target.value);
            }}
            topLabel
            type="date"
            value={startDate}
            w={160}
          />
        </Box>
        <Input
          borderColor={endDate !== "" ? "sk-purple" : "sk-light-gray"}
          id="endDate"
          label={t("transaction:To")}
          name="endDate"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEndDate(e.target.value);
          }}
          topLabel
          type="date"
          value={endDate}
          w={160}
        />
      </Box>
      <StyledClearButton
        onClick={() => {
          setStartDate("");
          setEndDate("");
        }}
      >
        {t("transaction:clear")}
      </StyledClearButton>
    </>
  );
};
