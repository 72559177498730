import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Margin } from "interfaces/data";
import { useTransaction } from "hooks/useTransaction";
import { useToast } from "hooks/useToast";
import { Modal } from "components/Modal/Modal";
import { useCompany } from "hooks/useCompany";
import { Box } from "@chakra-ui/react";
import _cloneDeep from "lodash.clonedeep";
import { AutoCompleteSelect } from "components/AutoCompleteSelect/AutoCompleteSelect";
import { Input } from "components/Input/Input";
import { transformTimestamp } from "helpers/date";
import { formatNumberToString } from "helpers/formatNumber";
import { CURRENCIES } from "helpers/options";
import { Checkbox } from "components/Checkbox/Checkbox";
import { StyledMarginsList, StyledMarginsListItem, StyledShowAllButton } from "./MarginMove.styled";
import { getWithdrawValue } from "helpers/margin";

export function MarginMoveModal({
  transactionId,
  marginId,
  handleClose,
}: {
  transactionId: string;
  marginId: string;
  handleClose: () => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const {
    transaction,
    marginCancelUnsafe,
    marginUpdateUnsafe,
    update: transactionUpdate,
  } = useTransaction({
    id: transactionId,
  });
  const { company, globalMarginAdd, globalMarginUpdateUnsafe } = useCompany({
    id: transaction?.company.id,
  });
  const [isSending, setIsSending] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [selectedMarginId, setSelectedMarginId] = useState<string>("create_new");
  const [quantity, setQuantity] = useState<number>(0);

  const numberOfCollapse = 5;

  const editedMargin = useMemo(() => {
    const margin = transaction?.margins?.find((margin) => margin.id === marginId);
    if (margin) {
      const withdrawn = getWithdrawValue(margin);
      setQuantity(margin.from.quantity - withdrawn);
    }
    return margin;
  }, [marginId, transaction]);

  const handleOnSaveAction = useCallback(
    async (newMargin: Margin) => {
      setIsSending(true);
      try {
        // Substract from local margin and if it would be left with 0, cancel it
        const selectedMargin = company?.globalMargins?.find((m) => m.id === selectedMarginId);
        if (!editedMargin || (selectedMargin && selectedMargin.from.currency !== editedMargin?.from.currency)) {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`margin:can't move margin`),
          });
          return;
        }

        if (editedMargin.marginCallId) {
          const marginCallToUnlink = transaction?.marginCalls?.find((mc) => mc.id === editedMargin.marginCallId);
          if (marginCallToUnlink && transaction) {
            const marginInfoToUnlink = marginCallToUnlink.margins?.findIndex((m) => m.marginId === marginId);
            if (marginInfoToUnlink !== undefined && marginInfoToUnlink > -1) {
              marginCallToUnlink.margins?.splice(marginInfoToUnlink, 1);
              delete editedMargin.marginCallId;
              editedMargin.left.quantity = editedMargin.from.quantity;
              transactionUpdate(transaction);
            }
          }
        }

        if (quantity === Number(newMargin?.from.quantity)) {
          await marginCancelUnsafe(newMargin.id);
        } else {
          const clonedEditedMargin = _cloneDeep(editedMargin);
          clonedEditedMargin.from.quantity -= quantity;
          clonedEditedMargin.to.quantity -= quantity;
          clonedEditedMargin.left.quantity -= quantity;
          await marginUpdateUnsafe(clonedEditedMargin);
        }
        // Add the quantity to the selected margin or create new global margin
        if (selectedMarginId === "create_new") {
          const clonedEditedMargin = _cloneDeep(editedMargin);
          clonedEditedMargin.from.quantity = quantity;
          clonedEditedMargin.to.quantity = quantity;
          clonedEditedMargin.left.quantity = quantity;
          clonedEditedMargin.createdFromMovement = true;
          await globalMarginAdd(clonedEditedMargin);
        } else {
          if (!selectedMargin) return;
          const clonedSelectedMargin = _cloneDeep(selectedMargin);
          clonedSelectedMargin.from.quantity += quantity;
          clonedSelectedMargin.to.quantity += quantity;
          clonedSelectedMargin.left.quantity += quantity;
          globalMarginUpdateUnsafe(clonedSelectedMargin);
        }
        setIsSending(false);
        toast({
          type: "success",
          message: t("margin:Margin has been moved successfully"),
        });
        handleClose();
      } catch (error) {
        setIsSending(false);
        if (error instanceof Error) {
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        }
      }
    },
    [
      globalMarginAdd,
      marginCancelUnsafe,
      handleClose,
      t,
      toast,
      company,
      editedMargin,
      globalMarginUpdateUnsafe,
      marginUpdateUnsafe,
      quantity,
      selectedMarginId,
      marginId,
      transaction,
      transactionUpdate,
    ]
  );

  const availableMargins = useMemo(() => {
    if (!company) return [];
    const globalMargins =
      company.globalMargins?.filter(
        (globalMargin) => globalMargin.left.quantity > 0 && globalMargin.from.currency === editedMargin?.from.currency
      ) || [];
    return [...globalMargins] as Array<Margin>;
  }, [company, editedMargin]);

  const visibleAvailableMargins = useMemo(() => {
    if (isExpanded) {
      return availableMargins;
    }
    return availableMargins.slice(0, numberOfCollapse);
  }, [availableMargins, isExpanded]);

  if (!editedMargin) return null;

  return (
    <Modal
      confirmText={t("Save")}
      design="primary"
      isDisabled={isSending}
      isOpen
      justifyButtons="flex-end"
      minWidth={840}
      onClose={handleClose}
      onConfirm={() => handleOnSaveAction(editedMargin)}
      title={t("margin:Move local variation margin to global")}
    >
      <StyledMarginsListItem
        key={"create_new"}
        onClick={(e) => {
          setSelectedMarginId("create_new");
          e.stopPropagation();
        }}
      >
        <Box display="flex">
          <Checkbox
            checkboxSize="small"
            isChecked={selectedMarginId === "create_new"}
            isReadOnly
            mr="10px"
            onClick={(e) => {
              e.preventDefault();
            }}
          />
          <Box fontSize={20}>Create new global margin</Box>
        </Box>
      </StyledMarginsListItem>
      <Box mt={10}>Or move to existing global margin:</Box>
      <StyledMarginsList>
        {visibleAvailableMargins.map((margin) => (
          <StyledMarginsListItem
            key={margin.id}
            onClick={(e) => {
              setSelectedMarginId(margin.id);
              e.stopPropagation();
            }}
          >
            <Box display="flex">
              <Checkbox
                checkboxSize="small"
                isChecked={selectedMarginId === margin.id}
                isReadOnly
                mr="10px"
                onClick={(e) => {
                  e.preventDefault();
                }}
              />
              <Box pr="15px" textAlign="right" w="100px">
                {transformTimestamp(margin.date)}
              </Box>
              <Box fontWeight="bold" pr="15px">
                Qty: {margin.left != null ? formatNumberToString(margin.left.quantity) : ""} /{" "}
                {formatNumberToString(margin.from.quantity)}
                {margin.from.currency}
              </Box>
              <Box>{margin.id}</Box>
            </Box>
          </StyledMarginsListItem>
        ))}
        {availableMargins.length > numberOfCollapse && !isExpanded && (
          <StyledShowAllButton onClick={() => setExpanded((prevState) => !prevState)}>
            {t("Show all")}
          </StyledShowAllButton>
        )}
      </StyledMarginsList>

      <Box display="flex" mb="10px">
        <Input
          error={quantity < 0 || quantity > editedMargin.from.quantity ? "error" : undefined}
          formatNumberOnBlur
          hideErrorMessage
          id="from.quantity"
          label="Quantity"
          name="from.quantity"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuantity(Number(e.target.value))}
          topLabel
          type="number"
          value={quantity}
          withError={quantity < 0 || quantity > editedMargin.from.quantity}
        />
        <Box ml="10px">
          <AutoCompleteSelect
            id="from.currency"
            isDisabled
            name="from.currency"
            options={CURRENCIES}
            value={editedMargin?.from.currency}
            width="85px"
          />
        </Box>
      </Box>
    </Modal>
  );
}
