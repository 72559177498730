import React, { useCallback, useMemo, useState } from "react";
import _uniqBy from "lodash.uniqby";
import { Table } from "components/Table/Table";
import { Box, InputLeftElement, Text, useDisclosure } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import { Button } from "components/Button/Button";
import { useContacts } from "hooks/useContacts";
import useDebounce from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { TableSortOption, TableSortSelect } from "components/TableSortSelect/TableSortSelect";
import { Company, Contact } from "interfaces/data";
import { Modal } from "components/Modal/Modal";
import { useToast } from "hooks/useToast";
import { PageHeader } from "components/PageHeader/PageHeader";
import { PageButtons } from "components/PageButtons/PageButtons";
import { useLogs } from "hooks/useLogs";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_COMPANY_GUARD } from "helpers/userRoles";
import { DeleteIcon } from "@chakra-ui/icons";
import { SearchIcon } from "theme/icons";

const StyledButtonContainer = styled.div`
  text-align: right;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const StyledDeleteButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 13px;
  width: 15px;
  line-height: 110%;
  margin-top: 3px;
  svg {
    margin-top: -5px;
  }
`;

function createDeleteCell(openModal: () => void, setDeleteRow: React.Dispatch<React.SetStateAction<Contact | null>>) {
  return (arg: any) => {
    return (
      <RestrictAccess area={ADD_COMPANY_GUARD}>
        <StyledButtonContainer>
          <StyledDeleteButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDeleteRow(arg.row.original);
              openModal();
            }}
          >
            <DeleteIcon />
          </StyledDeleteButton>
        </StyledButtonContainer>
      </RestrictAccess>
    );
  };
}

export function Contacts() {
  const { t } = useTranslation();

  const NameCell = useCallback((arg: any) => {
    const { firstName, lastName } = arg.row.original;
    return (
      <Text as="span" fontSize="15px" fontWeight="900">
        {firstName} {lastName}
      </Text>
    );
  }, []);

  const CompanyCell = useCallback(
    (arg: any) => {
      const companies: Company[] | undefined = arg.value;
      const uniqueCompanies = companies && companies.length > 0 ? _uniqBy(companies, "name") : null;

      return uniqueCompanies
        ? uniqueCompanies.map((company, i) => {
            const entity = company.contacts?.find((c: any) => c.id === arg.row.original.id)?.type;
            return (
              <React.Fragment key={`${company.id}_${i}`}>
                <StyledLink
                  style={{ display: "block", whiteSpace: "nowrap" }}
                  to={`/companies/${String(company.id).trim()}`}
                >
                  {company.name} - {entity}
                  {i < uniqueCompanies.length - 1 && ", "}
                </StyledLink>
              </React.Fragment>
            );
          })
        : "-";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = useCallback(() => {
    onClose();
    setDeleteRow(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortingOptions: Array<TableSortOption> = useMemo(
    () => [
      {
        value: "modifiedDesc",
        label: t("contact:Recently updated"),
        field: "modifiedAt",
        order: "desc",
      },
      {
        value: "nameAsc",
        label: t("contact:Contact name (A-Z)"),
        field: "firstName",
        order: "asc",
      },
      {
        value: "nameDesc",
        label: t("contact:Contact name (Z-A)"),
        field: "firstName",
        order: "desc",
      },
      {
        value: "createdDesc",
        label: t("contact:Creation date (newest first)"),
        field: "createdAt",
        order: "desc",
      },
      {
        value: "createdAsc",
        label: t("contact:Creation date (oldest first)"),
        field: "createdAt",
        order: "asc",
      },
    ],
    [t]
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortingOption, setSortingOption] = useState(sortingOptions[0]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(25);
  const history = useHistory();
  const [deleteRow, setDeleteRow] = useState<Contact | null>(null);
  const toast = useToast();
  const debouncedSearchQuery = useDebounce(searchQuery, 400);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { log } = useLogs();

  const handleRemove = useCallback(() => {
    if (deleteRow) {
      remove(deleteRow?.id).then(async () => {
        await log({
          action: "delete",
          item: {
            collection: "contacts",
            id: deleteRow.id,
            name: `${deleteRow.firstName} ${deleteRow.lastName}`,
          },
          oldData: JSON.stringify(deleteRow),
        });
        handleClose();
        toast({
          type: "success",
          message: t("contact:Contact successfully deleted."),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRow]);

  const columns = useMemo(
    () => [
      {
        Header: t("contact:Name"),
        accessor: "firstName",
        Cell: NameCell,
      },
      {
        Header: t("contact:Email"),
        accessor: "email",
      },
      {
        Header: t("contact:Phone"),
        accessor: "phone",
        Cell: (arg: any) => (
          <Text as="span" whiteSpace="nowrap">
            {arg.value}
          </Text>
        ),
      },
      {
        Header: t("contact:Company associated"),
        accessor: "companies",
        Cell: CompanyCell,
      },
      {
        Header: "",
        accessor: "delete",
        id: "deleteAction",
        Cell: createDeleteCell(onOpen, setDeleteRow),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const { contacts, loading, pageCount, remove } = useContacts({
    phrase: debouncedSearchQuery,
    fields: "firstName,phone,email,companies[].name",
    orderBy: sortingOption.field,
    orderDirection: sortingOption.order,
    offset,
    limit,
  });

  const handleTableUdpate = useCallback(({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
    setOffset(pageIndex * pageSize);
    setLimit(pageSize);
  }, []);

  const handleSeachInputUdpate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const handleSortByUpdate = useCallback((newSelectedOption: TableSortOption) => {
    setSortingOption(newSelectedOption);
  }, []);

  const navigateToContactAdd = useCallback(() => {
    history.push("/contacts/add");
  }, [history]);

  if (contacts) {
    return (
      <>
        <PageHeader>
          <Heading type="h1">{t("contact:Contacts")}</Heading>
        </PageHeader>
        <PageButtons>
          <RestrictAccess area={ADD_COMPANY_GUARD}>
            <Button design="primary" onClick={navigateToContactAdd}>
              {t("contact:New contact")}
            </Button>
          </RestrictAccess>
        </PageButtons>
        <StyledContainer>
          <StyledTableControls>
            <StyledInputGroup>
              <InputLeftElement children={<SearchIcon />} />
              <StyledInput onChange={handleSeachInputUdpate} placeholder={t("Search")} value={searchQuery} />
            </StyledInputGroup>
            <TableSortSelect
              onSortByChange={handleSortByUpdate}
              options={sortingOptions}
              selectedOption={sortingOption}
            />
          </StyledTableControls>
          <Table
            columns={columns}
            data={contacts}
            getRowProps={(rowInfo) => {
              const contact = rowInfo.original as Contact;
              return {
                url: `/contacts/${contact.id}`,
              };
            }}
            initialPageSize={25}
            loading={loading}
            onStateUpdate={handleTableUdpate}
            pageCount={pageCount}
            pageSizeOptions={[25, 50, 100]}
          />
        </StyledContainer>
        <Modal
          confirmText={t("contact:Yes, delete it")}
          design="danger"
          isOpen={isOpen}
          onClose={handleClose}
          onConfirm={handleRemove}
          title={t(`contact:Delete contact {{fullname}}`, {
            fullname: `${deleteRow?.firstName} ${deleteRow?.lastName}`,
          })}
        >
          <Box mb={"20px"}>
            {t("contact:This action can't be undone. Are you sure you want to delete this contact?")}
          </Box>
        </Modal>
      </>
    );
  }
  return null;
}
