import { z } from "zod";
import { TimestampSchema } from "./common";

export const RatesSchema = z.object({
  createdAt: TimestampSchema,
  modifiedAt: TimestampSchema,
  effectiveDate: z.string(),
  no: z.string(),
  rates: z.record(z.number()),
});

export type Rates = z.infer<typeof RatesSchema>;
