import React, { PropsWithChildren, useState } from "react";
import { Transaction } from "interfaces/data";
import TransactionContext from "./transactionContext";

const TransactionContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [transaction, setTransaction] = useState<Transaction>();

  return (
    <TransactionContext.Provider
      value={{
        transaction,
        setTransaction,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export default TransactionContextProvider;
