import styled from "@emotion/styled";

export const StyledMarginsList = styled.div`
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  border-radius: 16px;
  height: 235px;
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
`;

export const StyledMarginsListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  padding: 5px 20px;
  min-height: 40px;
  font-size: 14px;
  button {
    display: none;
    margin-left: auto;
  }
  &:hover {
    background-color: rgba(91, 77, 190, 0.2);
    color: ${(props) => props.theme.colors["sk-dark"]};
  }
  &:hover button {
    display: block;
  }
`;

export const StyledShowAllButton = styled.button`
  font-size: 13px;
  text-align: center;
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
  width: 100%;
`;

export const StyledLabel = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-right: 5px;
`;

interface WithdrawalProps {
  withdrawn?: boolean;
  addition?: boolean;
  ml?: string;
}

export const StyledValue = styled.span<WithdrawalProps>`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 110%;
  white-space: nowrap;
  margin-left: ${(props) => {
    if (props.ml) {
      return props.ml;
    } else {
      return "auto";
    }
  }};
  color: ${(props) => {
    if (props.withdrawn) {
      return props.theme.colors["sk-red"];
    }
    if (props.addition) {
      return props.theme.colors["sk-green"];
    }
    return props.theme.colors["sk-dark"];
  }};
`;
