import { useFirebase } from "./useFirebase";
import { UseDatabase } from "../interfaces/hooks";
import { useCallback } from "react";

export const useDatabase: UseDatabase = (collection, builder) => {
  const { db } = useFirebase();

  const runQuery = useCallback(() => {
    if (typeof builder === "function") {
      builder(db.collection(collection));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [runQuery];
};
