import * as yup from "yup";
import { bankAccountNumberValidation } from "./shared";

export const bankAccountSchema = yup.object().shape({
  number: bankAccountNumberValidation.trim(),
  name: yup.string().required().trim(),
  swift: yup.string().required().trim(),
  currency: yup.string().required().trim(),
  country: yup.string().nullable().trim(),
  isDefault: yup.boolean().nullable(),
});
