import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

import { LabeledText } from "components/LabeledText/LabeledText";
import {
  determineTransactionCurrency,
  getMarginQuantity,
  getQuantityLeft,
  getRateShift,
  getTransactionTypeLabel,
  MARGIN_TYPES,
  TRANSACTION_TYPES,
} from "helpers/transaction";
import { Transaction, TransactionType } from "interfaces/data";
import { transformTimestamp } from "helpers/date";
import { Comment } from "../Comment/Comment";
import { EditComment } from "../Comment/EditComment";
import { Decimal } from "decimal.js";
import { useNbpRates } from "hooks/useNbpRates";
import { formatNumber, formatNumberToString, formatRate } from "helpers/formatNumber";
import { ActiveUserBorder } from "components/ActiveUserBorder/ActiveUserBorder";
import { Box } from "@chakra-ui/react";

export const StyledContainer = styled.div<{
  bgColor?: string;
  borderColor?: string;
}>`
  border: 1px solid ${(props) => props.borderColor || props.theme.colors["sk-light-gray"]};
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  padding: 30px;
  background: ${(props) => props.bgColor || "white"};
`;

export const StyledSectionHeader = styled.div`
  display: flex;
`;

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr 1fr;
  grid-row-gap: 20px;
`;

export const StyledSectionHeading = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  min-width: 120px;
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

export const StyledSectionComment = styled.div`
  font-size: 14px;
  line-height: 110%;
  min-height: 21px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  color: ${(props) => props.theme.colors["sk-gray"]};
  position: relative;
  height: 100%;
`;

export const StyledRateShit = styled.span<{
  isRisky?: boolean;
}>`
  color: ${(props) => props.theme.colors[props.isRisky ? "sk-red" : "sk-dark"]};
`;

interface TransactionDetailsProps {
  transaction: Transaction;
  commentUpdate: (comment: string) => Promise<void>;
}

export const TransactionDetails: React.FC<TransactionDetailsProps> = ({ transaction, commentUpdate }) => {
  const [isEditingComment, setIsEditingComment] = useState(false);
  const { t } = useTranslation();
  const { rates } = useNbpRates();

  const paidInitialMargin = useMemo(
    () =>
      transaction?.margins
        ?.filter((margin) => margin.type === MARGIN_TYPES.INITIAL.value)
        .reduce((sum, margin) => {
          // subtract withdrawal operations from margin quantity
          const quantity =
            margin.operations?.reduce(
              (acc, operation) =>
                operation.type === "WITHDRAW" ? acc.sub(new Decimal(operation.value.quantity)) : acc,
              new Decimal(margin.to.quantity)
            ) || new Decimal(margin.to.quantity);
          return sum.plus(quantity);
        }, new Decimal(0)) || new Decimal(0),
    [transaction]
  );

  const paidVariationMargin = useMemo(
    () =>
      transaction?.margins
        ?.filter((margin) => margin.type === MARGIN_TYPES.VARIATION.value)
        .reduce((sum, margin) => sum.plus(new Decimal(margin.to.quantity)), new Decimal(0)) || new Decimal(0),
    [transaction]
  );

  const sumOfMargins = useMemo(
    () => paidInitialMargin.plus(paidVariationMargin),
    [paidInitialMargin, paidVariationMargin]
  );

  const rateShift = useMemo(() => {
    if (!transaction || !rates || transaction.type !== TRANSACTION_TYPES.FIXED.value) return "-";

    const rateShiftValue = getRateShift(transaction, rates);

    return <StyledRateShit isRisky={rateShiftValue < -3}>{rateShiftValue}%</StyledRateShit>;
  }, [transaction, rates]);

  const handleCommentUpdate = useCallback(
    (comment: string) => {
      commentUpdate(comment).then(() => {
        setIsEditingComment(false);
      });
    },
    [commentUpdate]
  );

  let bgColor, borderColor;
  if (transaction.type === TRANSACTION_TYPES.SPOT.value) {
    bgColor = "#eaf6ff";
    borderColor = "#d5eeff";
  }
  if (transaction.type === TRANSACTION_TYPES.ORDER.value) {
    bgColor = "#fff9e5";
    borderColor = "#fff4cc";
  }

  return (
    <>
      <ActiveUserBorder collection="transactions" id={transaction.id} sections={["edit", "roll", "cancel", "convert"]}>
        <StyledContainer bgColor={bgColor} borderColor={borderColor}>
          <StyledSectionHeading>
            <StyledSectionName>{t("transaction:Details")}</StyledSectionName>
            <StyledSectionComment>
              {isEditingComment ? (
                <EditComment
                  comment={transaction.comment}
                  onEdit={handleCommentUpdate}
                  onEditClose={() => setIsEditingComment(false)}
                />
              ) : (
                <Comment comment={transaction.comment} onEdit={() => setIsEditingComment(true)} />
              )}
            </StyledSectionComment>
          </StyledSectionHeading>

          <br />
          <StyledContent>
            <LabeledText
              label={t("transaction:Transaction type")}
              layout="horizontal"
              value={getTransactionTypeLabel(transaction.type)}
            />
            <LabeledText
              label={t("transaction:Currency date")}
              layout="horizontal"
              value={
                transaction.type === (TRANSACTION_TYPES.SPOT.value as TransactionType)
                  ? transformTimestamp(transaction.currencyDate)
                  : "-"
              }
            />
            <LabeledText
              label={t("transaction:Expiration date")}
              layout="horizontal"
              value={
                transaction.type === (TRANSACTION_TYPES.ORDER.value as TransactionType)
                  ? transformTimestamp(transaction.expiration)
                  : "-"
              }
            />
            <LabeledText bold label={t("transaction:Type")} layout="horizontal" value={transaction.dealType} />
            <LabeledText label={t("transaction:For currency")} layout="horizontal" value={transaction.to.currency} />
            <LabeledText
              label={t("transaction:Agreement date")}
              layout="horizontal"
              value={transformTimestamp(transaction.agreement)}
            />
            <LabeledText
              bold
              label={t("transaction:Base Qty")}
              layout="horizontal"
              value={formatNumberToString(transaction.from.quantity)}
            />
            <LabeledText
              isSmallValue
              label={t("transaction:Our rate")}
              layout="horizontal"
              value={formatRate(transaction.ourRate, 4)}
            />
            <LabeledText
              label={t("transaction:End date")}
              layout="horizontal"
              value={transformTimestamp(transaction.end)}
            />
            <LabeledText bold label={t("transaction:What")} layout="horizontal" value={transaction.from.currency} />
            <LabeledText
              bold
              label={t("transaction:Remaining quantity")}
              layout="horizontal"
              value={formatNumberToString(getQuantityLeft(transaction))}
            />
            <LabeledText
              label={t("transaction:Start date")}
              layout="horizontal"
              value={transformTimestamp(transaction.start)}
            />
            <LabeledText
              bold
              label={t("transaction:Client Rate")}
              layout="horizontal"
              value={formatRate(transaction.clientRate, 4)}
            />
            <LabeledText
              label={t("transaction:Paid initial margin")}
              layout="horizontal"
              value={formatNumberToString(Number(paidInitialMargin))}
            />
            <LabeledText
              label={t("transaction:Last update")}
              layout="horizontal"
              value={transformTimestamp(transaction.modifiedAt)}
            />
            <LabeledText
              breakWords={false}
              label={t("transaction:Initial Margin {{currency}}", {
                currency: determineTransactionCurrency(transaction),
              })}
              layout="horizontal"
              value={getMarginQuantity(transaction)}
            />
            <LabeledText
              label={t("transaction:Paid variation margin")}
              layout="horizontal"
              value={formatNumberToString(Number(paidVariationMargin))}
            />
            <LabeledText
              label={t("transaction:Quantity")}
              layout="horizontal"
              value={formatNumberToString(transaction.to.quantity)}
            />
            <LabeledText label={t("transaction:Rate shift")} layout="horizontal" value={rateShift} />
            <LabeledText
              label={t("transaction:Settled")}
              layout="horizontal"
              value={formatNumberToString(formatNumber(transaction.from.quantity - getQuantityLeft(transaction)))}
            />
            <LabeledText
              label={t("transaction:Sum of margins")}
              layout="horizontal"
              value={formatNumberToString(Number(sumOfMargins))}
            />
          </StyledContent>
        </StyledContainer>
      </ActiveUserBorder>
      <Box h="30px" />
    </>
  );
};
