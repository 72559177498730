import React, { useMemo } from "react";
import { Transaction } from "interfaces/data";
import { StyledCollapsableSmallContainer, StyledSectionHeader, StyledSectionName } from "./shared.styled";
import { useTranslation } from "react-i18next";
import { CurrencyCell } from "helpers/tableCells";
import { Text } from "@chakra-ui/react";
import { Table } from "components/Table/Table";
import { useTransactionCommitment } from "hooks/useTransactionCommitment";

interface CommitmentsProps {
  transaction: Transaction;
}

export const Commitments: React.FC<CommitmentsProps> = ({ transaction }) => {
  const { t } = useTranslation();
  const { spreadProfit, hedgesProfit, clientLoss } = useTransactionCommitment(transaction);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Spread Profit")}
          </Text>
        ),
        accessor: "spreadProfit",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Hedges Profit")}
          </Text>
        ),
        accessor: "hedgesProfit",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
            {t("transaction:Client Loss")}
          </Text>
        ),
        accessor: "clientLoss",
        Cell: CurrencyCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <StyledCollapsableSmallContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("transaction:Commitments")}</StyledSectionName>
      </StyledSectionHeader>
      <Table
        columns={columns}
        data={[
          {
            spreadProfit: {
              quantity: spreadProfit,
              currency: "PLN",
            },
            hedgesProfit: {
              quantity: hedgesProfit,
              currency: "PLN",
            },
            clientLoss: {
              quantity: clientLoss < 0 ? clientLoss : 0,
              currency: "PLN",
            },
          },
        ]}
        paginated={false}
      />
    </StyledCollapsableSmallContainer>
  );
};
