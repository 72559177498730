import { InputGroup, InputRightElement } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const StyledSearchContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
`;

export const StyledInputGroup = styled(InputGroup)`
  width: 223px;
  margin-left: 20px;
  margin-top: 50px;
  svg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-left: 20px;
    color: #aaa9af;
  }
`;

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]} !important;
  border-radius: 16px;
  padding-left: 55px;
  padding-right: 40px;
  height: 42px;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
  font-size: 13px;
  ::placeholder {
    color: #aaa9af;
    opacity: 1;
  }
`;

export const StyledSearchResultsContainer = styled.div`
  position: relative;
  margin: 55px 20px 20px 20px;
  width: calc(100% - 40px);
`;

export const StyledSearchResultsSection = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background: white;
  padding: 30px;
  position: relative;
  bottom: 30px;
`;

export const StyledEmptySearchResultsSection = styled.div`
  border: 1px solid ${({ theme }) => theme.colors["sk-light-gray"]};
  border-radius: 4px;
  background: white;
  padding: 30px;
  position: relative;
  bottom: 30px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 300px;
    text-align: center;
  }
  > div h6 {
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 20px;
  }
  > div > div {
    text-align: left;
    padding-left: 38px;
  }
  > div > div ul {
    list-style: none;
  }
`;

export const StyledSectionHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  margin-bottom: 15px;
`;

export const StyledSearchResults = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 15px;
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StyledInputRightElement = styled(InputRightElement)`
  cursor: pointer;
  svg {
    width: 14px;
    height: 14px;
    margin-top: 5px;
    margin-right: 20px;
    color: #aaa9af;
  }
`;
