import React from "react";
import { Application } from "interfaces/data";
import { Input } from "components/Input/Input";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "components/EditBox/EditBox";
import { COUNTRIES } from "helpers/options";
import { Select } from "components/Select/Select";

export function EditContactAddress({ data, handleChange, errors }: EditViewProps<Application>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["contactAddress.industry"]}
        id="street"
        label={t("application:Street")}
        name="contactAddress.street"
        onChange={handleChange}
        type="text"
        value={data.contactAddress?.street}
        withError={!!errors?.["contactAddress.street"]}
      />
      <Input
        error={errors?.["contactAddress.number"]}
        id="number"
        label={t("application:Apt number")}
        name="contactAddress.number"
        onChange={handleChange}
        type="text"
        value={data.contactAddress?.number}
        withError={!!errors?.["contactAddress.number"]}
      />
      <Input
        error={errors?.["contactAddress.code"]}
        id="code"
        label={t("application:Code")}
        name="contactAddress.code"
        onChange={handleChange}
        type="text"
        value={data.contactAddress?.code}
        withError={!!errors?.["contactAddress.code"]}
      />
      <Input
        error={errors?.["contactAddress.city"]}
        id="city"
        label={t("application:City")}
        name="contactAddress.city"
        onChange={handleChange}
        type="text"
        value={data.contactAddress?.city}
        withError={!!errors?.["contactAddress.city"]}
      />
      <Select
        error={errors?.["contactAddress.country"]}
        id="country"
        label={t("application:Country")}
        name="contactAddress.country"
        onChange={handleChange}
        options={COUNTRIES}
        value={data.contactAddress?.country}
      />
    </TwoColumnsLayout>
  );
}
