import { useState, useEffect, useCallback } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { NbpRateRecord } from "interfaces/data";

const COLLECTION = "rates";

export const useNbpRates = () => {
  const [rates, setRates] = useState<NbpRateRecord>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState();
  const { db } = useFirebase();

  const fetch = useCallback(
    () => {
      const collection = db.collection(COLLECTION);
      collection
        .orderBy("effectiveDate", "desc")
        .limit(1)
        .get()
        .then((snap: firestore.DocumentData) => {
          const npbRates = snap.docs[0].data();
          npbRates.rates.PLN = 1;
          setRates(npbRates);
        })
        .catch((e) => {
          console.error("useNbpRates", e);
          setErrors(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      fetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getRate = (currency: string) => {
    if (!rates) return 0;
    return rates.rates[currency];
  };

  return { rates, loading, errors, refetch: fetch, getRate };
};
