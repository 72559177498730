import React, { useCallback, useRef, useState } from "react";
import { Box, BoxProps, InputProps, Text, Tooltip } from "@chakra-ui/react";
import { StyledCopyButton, StyledGroup, StyledInput, StyledLabel } from "./Input.styled";
import { inputErrors } from "./Input.errors";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { chakraStyles } from "theme/chakraStyles";
import { InputRightAddon } from "../InputWithLabel/InputRightAddon";
import Decimal from "decimal.js";
import { formatNumberToString } from "helpers/formatNumber";
import { DatePicker } from "../DatePicker/DatePicker";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { CopyIcon } from "@chakra-ui/icons";
import isPropValid from "@emotion/is-prop-valid";

type StyledContainerProps = BoxProps & {
  fullWidth?: boolean;
};

const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<StyledContainerProps>`
  ${(props) => props.fullWidth && `grid-column: 1 / 3;`}
`;

type Props = InputProps & {
  error?: string;
  hideErrorMessage?: boolean;
  label?: string;
  withError?: boolean;
  fullWidth?: boolean;
  containerProps?: BoxProps;
  labelProps?: BoxProps;
  withRightAddon?: boolean;
  withLeftAddon?: boolean;
  rightAddon?: string;
  topLabel?: boolean;
  formatNumberOnBlur?: boolean;
  formatNumberPrecision?: number;
  enableDateClear?: boolean;
  enableDataCopy?: boolean;
};

export const Input: React.FC<Props> = ({
  error,
  hideErrorMessage = false,
  label,
  withError = false,
  fullWidth,
  containerProps = {},
  labelProps = {},
  withRightAddon = false,
  withLeftAddon = false,
  rightAddon,
  topLabel = false,
  formatNumberOnBlur = false,
  formatNumberPrecision = 2,
  enableDateClear = false,
  value,
  onFocus,
  onBlur,
  onChange,
  enableDataCopy,
  ...props
}) => {
  const hasError = !!error;
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const copyToClipboard = useCopyToClipboard();
  const borderRadiusProps = {
    roundedRight: withRightAddon || rightAddon ? "0" : "base",
    roundedLeft: withLeftAddon ? "0" : "base",
  };

  const [isFocused, setIsFocused] = useState(false);
  const displayNumberAsFormattedString = formatNumberOnBlur && !isFocused;

  const handleOnFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof onFocus === "function") onFocus(e);
      if (formatNumberOnBlur) setIsFocused(true);
    },
    [onFocus, formatNumberOnBlur]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof onBlur === "function") onBlur(e);
      if (formatNumberOnBlur) {
        setIsFocused(false);
        if (typeof onChange === "function") {
          e.target.value = new Decimal(Number(e.target.value)).toDecimalPlaces(formatNumberPrecision).toString();
          onChange(e);
        }
      }
    },
    [onBlur, formatNumberOnBlur, formatNumberPrecision, onChange]
  );

  if (props.type === "date") {
    return (
      <DatePicker
        enableDateClear={enableDateClear}
        error={error}
        isDisabled={props.isDisabled}
        label={label}
        name={props.name}
        onChange={onChange}
        value={value}
        withError={withError}
      />
    );
  }

  return (
    <StyledContainer {...containerProps} fullWidth={fullWidth}>
      <StyledGroup>
        <StyledInput
          _disabled={chakraStyles.disabled}
          _focus={{
            borderColor: "sk-light-gray",
          }}
          borderColor="sk-light-gray"
          errorBorderColor="sk-red"
          fontSize="xs-sm"
          fontWeight="semibold"
          height="42px"
          isInvalid={hasError}
          isRequired
          ref={inputRef}
          topLabel={topLabel}
          {...borderRadiusProps}
          {...props}
          onBlur={handleOnBlur}
          onChange={onChange}
          onFocus={handleOnFocus}
          type={displayNumberAsFormattedString ? "string" : props.type}
          value={
            displayNumberAsFormattedString && value ? formatNumberToString(String(value), formatNumberPrecision) : value
          }
        />
        {rightAddon && <InputRightAddon hasError={hasError} rightAddon={rightAddon} />}
        {label && (
          <Box as={StyledLabel} {...labelProps}>
            {label}
          </Box>
        )}
        {enableDataCopy && (
          <StyledCopyButton
            onClick={() => {
              const inputVal = inputRef?.current?.value.toString();
              copyToClipboard(inputVal);
            }}
            rightAddon={Boolean(rightAddon)}
          >
            <Tooltip aria-label="A copy tooltip" hasArrow label={t("helpers:Copy")} placement="top">
              <CopyIcon />
            </Tooltip>
          </StyledCopyButton>
        )}
      </StyledGroup>
      {withError && !hideErrorMessage && (
        <Text
          alignItems="center"
          color="sk-red"
          display="flex"
          fontSize="xs-sm"
          fontWeight="bold"
          height="20px"
          mb="0px"
          ml="20px"
          mt="10px"
        >
          {error && t(`validation:${inputErrors[error] || ""}`)}
        </Text>
      )}
    </StyledContainer>
  );
};
