import React, { PropsWithChildren } from "react";
import { Avatar, AvatarGroup, Box } from "@chakra-ui/react";
import { useActiveUsers } from "hooks/useActiveUsers";
import styled from "@emotion/styled";
import { getAvatarColor } from "helpers/activeUsers";

interface ActiveUserBorderProps extends PropsWithChildren {
  sections?: string | Array<string>;
  collection: string;
  id: string;
}

const StyledAvatarGroup = styled(AvatarGroup)`
  position: absolute;
  top: -17px;
  right: -17px;
  z-index: 2;
`;

const StyledBox = styled(Box)`
  position: relative;
  border-radius: 3px;
  border-width: 3px;
`;

export const ActiveUserBorder: React.FC<ActiveUserBorderProps> = ({ sections, collection, id, children }) => {
  const _sections = Array.isArray(sections) ? sections : [sections];

  const { activeUsers } = useActiveUsers();
  const otherUsers = activeUsers.filter((activity) => {
    return new RegExp(_sections.map((section) => `/${collection}/${id}/${section}`).join("|")).test(activity.url);
  });

  if (!otherUsers || otherUsers.length === 0) {
    return <>{children}</>;
  }

  const [{ color, name }] = otherUsers;
  const borderColor = color || getAvatarColor(name);

  return (
    <StyledBox borderColor={borderColor}>
      <StyledAvatarGroup max={otherUsers.length > 2 ? 1 : 2} size="sm">
        {otherUsers.map((user) => (
          <Avatar key={user.id} name={user.name} size="sm" />
        ))}
      </StyledAvatarGroup>
      {children}
    </StyledBox>
  );
};
