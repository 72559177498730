import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Company } from "../../../interfaces/data";
import { ContactPersonSection } from "./ContactPersonSection";
import { NoteSection } from "./NoteSection";
import { AboutCompanySection } from "./AboutCompanySection";
import { BankAccountsSection } from "./BankAccountsSection";
import { ScoringSection } from "./ScoringSection";
import { SourceSection } from "./SourceSection";
import { DeactivatedBox } from "../../Companies/Companies";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";

interface RightSidebarProps {
  company: Company;
  notesUpdate: (comment: string) => Promise<void>;
}

const Container = styled.div`
  position: relative;
  background: #ffffff;
  padding: 20px;
  margin-right: -50px;
  z-index: 4;
`;

const CompanyName = styled(Link)`
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  text-decoration-line: underline;
  color: #5b4dbe;
`;

export const RightSidebar: React.FC<RightSidebarProps> = ({ company, notesUpdate }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <CompanyName to={`/companies/${company.id}/preview`}>{company.name}</CompanyName>
      {company.isDeactivated && (
        <Box ml="-20px" mt="10px">
          <DeactivatedBox>{t("company:deactivated")}</DeactivatedBox>
        </Box>
      )}
      <NoteSection company={company} notesUpdate={notesUpdate} />
      <AboutCompanySection company={company} />
      <BankAccountsSection company={company} />
      <ContactPersonSection contacts={company.contacts} />
      <SourceSection contacts={company.contacts} />
      <ScoringSection company={company} />
    </Container>
  );
};
