import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const EditIcon = createIcon({
  displayName: "EditIcon",
  path: (
    <g fill="none">
      <path d="M11.8334 7.0943L8.3667 3.61389" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.33333 13.6205L1 14.4907L1.86667 10.14L10.5333 1.4386L14 4.91915L5.33333 13.6205Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: "0 0 15 15",
});
