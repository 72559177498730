export const formatMobilePhone = (phoneNumber: string) =>
  phoneNumber
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})/g, "$1 ")
    .split("")
    .reverse()
    .join("")
    .trim();
