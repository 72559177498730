import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table } from "components/Table/Table";
import { Company, Transaction } from "interfaces/data";
import { StyledButton, StyledCollapsibleContainer, StyledSectionHeader, StyledSectionName } from "../../shared.styled";
import { DateCell, DateCellTransactionEndDate } from "helpers/tableCells";
import { StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { initialTypeFilterValue, TypeFilter } from "./Filters/TypeFilter";
import { formatNumberToString } from "helpers/formatNumber";
import { getQuantityLeft, hasDeadline } from "helpers/transaction";
import { getTransactionRowClassNames, StyledTableContainer, TypeCell } from "views/Transactions/Transactions";
import { useNbpRates } from "hooks/useNbpRates";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_TRANSACTION } from "helpers/userRoles";
import { Button } from "components/Button/Button";
import { calculateQuantitiesAverages, calculateQuantitiesSummary } from "helpers/360view";
import { useGMC } from "hooks/useGMC";
import { NoteIcon } from "theme/icons/NoteIcon";
import { AddIcon } from "theme/icons/AddIcon";
import { Rate } from "../../../../components/Rate/Rate";

interface TransactionsProps {
  transactions?: Array<Transaction>;
  settledTransactions?: Array<Transaction>;
  company?: Company;
  isOrder?: boolean;
  disableNav?: boolean;
}

const StyledLeftCell = styled.div`
  text-align: left;
  width: 100%;
  white-space: nowrap;
`;

const StyledCenterCell = styled.div`
  text-align: center;
  width: 100%;
  white-space: nowrap;
`;

export const StyledAddbutton = styled.button`
  text-align: center;
  color: ${(props) => props.theme.colors["sk-purple"]};
  margin-left: 20px;
  font-weight: bold;
  font-size: 14px;

  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.colors["sk-gray"]};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 16px;
  padding: 4px;
  margin-right: auto;
`;

export const StyledButtonContainerWithLink = styled.div`
  display: flex;
  margin-right: auto;
`;

export const StyledButtonContainerWrapper = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 16px;
  padding: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
`;

export const BoldText = styled.span`
  font-weight: 900;
`;

const CurrencyPairCell = (arg: any) => {
  const transaction = arg.row.original as Transaction;
  if (!transaction) return "";

  return `${transaction.from.currency}/${transaction.to.currency}`;
};

const SettlementRatioCell = (arg: any) => {
  const transaction = arg.row.original as Transaction;
  if (!transaction) return "";

  const quantityLeft = getQuantityLeft(transaction);

  return (
    <StyledCenterCell>
      {formatNumberToString(Number(transaction.from.quantity) - quantityLeft)} {transaction.from.currency} /{" "}
      <BoldText>
        {formatNumberToString(quantityLeft)} {transaction.from.currency}
      </BoldText>
    </StyledCenterCell>
  );
};

const OrderQtyCell = (arg: any) => {
  const transaction = arg.row.original as Transaction;
  if (!transaction) return "";

  const quantityLeft = getQuantityLeft(transaction);

  return (
    <StyledCenterCell>
      {formatNumberToString(quantityLeft)} {transaction.from.currency}
    </StyledCenterCell>
  );
};

const RateWeightedAverageFooterCell = (arg: any) => {
  const transactions = arg.rows.map((row: any) => row.original) as Array<Transaction>;
  if (!transactions.length) return "";

  const buyTransactions = transactions.filter((transaction) => transaction.dealType === "Buy");
  const sellTransactions = transactions.filter((transaction) => transaction.dealType === "Sell");
  const quantitiesBuyAverages = calculateQuantitiesAverages(buyTransactions);
  const quantitiesSellAverages = calculateQuantitiesAverages(sellTransactions);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      whiteSpace="nowrap"
      width="100%"
    >
      <Box alignItems="flex-end" display="flex" flexDirection="column" whiteSpace="nowrap">
        {quantitiesBuyAverages && quantitiesBuyAverages.length
          ? quantitiesBuyAverages
              .sort((a, b) => a.currency.localeCompare(b.currency))
              .map((q) => (
                <Box key={q.currency}>
                  {q.settled} {q.currency}
                </Box>
              ))
          : ""}
      </Box>
      <Box alignItems="flex-end" display="flex" flexDirection="column" ml={5} whiteSpace="nowrap">
        {quantitiesSellAverages && quantitiesSellAverages.length
          ? quantitiesSellAverages
              .sort((a, b) => a.currency.localeCompare(b.currency))
              .map((q) => (
                <Box key={q.currency}>
                  - {q.settled} {q.currency}
                </Box>
              ))
          : ""}
      </Box>
    </Box>
  );
};

const SettlementSummaryFooterCell = (arg: any) => {
  const transactions = arg.rows.map((row: any) => row.original) as Array<Transaction>;
  if (!transactions.length) return "";
  const buyTransactions = transactions.filter((transaction) => transaction.dealType === "Buy");
  const sellTransactions = transactions.filter((transaction) => transaction.dealType === "Sell");
  const quantitiesBuyAverages = calculateQuantitiesSummary(buyTransactions);
  const quantitiesSellAverages = calculateQuantitiesSummary(sellTransactions);

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      whiteSpace="nowrap"
      width="100%"
    >
      <Box alignItems="flex-end" display="flex" flexDirection="column" ml={4} whiteSpace="nowrap">
        {quantitiesBuyAverages && quantitiesBuyAverages.length ? (
          <>
            {quantitiesBuyAverages
              .sort((a, b) => a.currency.localeCompare(b.currency))
              .map((q) => (
                <Box key={q.currency}>
                  {q.remaining} {q.currency}
                </Box>
              ))}
          </>
        ) : (
          ""
        )}
      </Box>
      <Box alignItems="flex-end" display="flex" flexDirection="column" ml={4} whiteSpace="nowrap">
        {quantitiesSellAverages && quantitiesSellAverages.length ? (
          <>
            {quantitiesSellAverages
              .sort((a, b) => a.currency.localeCompare(b.currency))
              .map((q) => (
                <Box key={q.currency}>
                  - {q.remaining} {q.currency}
                </Box>
              ))}
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export const Transactions: React.FC<TransactionsProps> = ({
  transactions = [],
  settledTransactions = [],
  company,
  isOrder,
  disableNav = false,
}) => {
  const { rates } = useNbpRates();
  const [typeFilter, setTypeFilter] = useState(initialTypeFilterValue);
  const [isExpanded, setExpanded] = useState(!isOrder);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const [showSettled, setShowSettled] = useState(false);
  const { findAllForTransaction } = useGMC();

  const handleTransactionAdd = useCallback(() => {
    history.push(`/companies/${id}/add-transaction`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOrderAdd = useCallback(() => {
    history.push(`/companies/${id}/add-order`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const numberOfCollapse = 5;

  const filteredTransactions = useMemo(() => {
    const selectedTransactions = showSettled ? settledTransactions : transactions;
    return selectedTransactions
      .sort((a) => (a.type === "Order" ? 0 : -1))
      .filter((transaction) => typeFilter.includes(transaction.type));
  }, [settledTransactions, showSettled, transactions, typeFilter]);

  const visibleTransactions = useMemo(() => {
    if (isExpanded) {
      return filteredTransactions;
    }
    return filteredTransactions.slice(0, numberOfCollapse);
  }, [filteredTransactions, isExpanded]);

  function IdCell(arg: any) {
    const { comment } = arg.row.original;
    return (
      <Box display="flex" whiteSpace="nowrap">
        {arg.value}
        {comment && (
          <Box alignItems="center" display="flex" ml="5px" title={comment}>
            <NoteIcon height="11px" />
          </Box>
        )}
      </Box>
    );
  }

  const columns = useMemo(
    () =>
      [
        {
          accessor: "number",
          Header: () => <StyledLeftCell>{t("transaction:ID")}</StyledLeftCell>,
          Cell: IdCell,
        },
        {
          accessor: "type",
          Header: () => <StyledLeftCell>{t("transaction:Trans type")}</StyledLeftCell>,
          Cell: TypeCell,
          Footer: () => !showSettled && <StyledLeftCell>{t("transaction:AVG Price / Remaining Qty")}</StyledLeftCell>,
        },
        {
          accessor: "agreement",
          Header: () => <StyledLeftCell>{t("transaction:Transaction date")}</StyledLeftCell>,
          Cell: DateCell,
        },
        {
          accessor: "dealType",
          Header: () => <StyledLeftCell>{t("transaction:Type")}</StyledLeftCell>,
        },
        {
          Header: () => <StyledCenterCell>{t("transaction:Client Rate")}</StyledCenterCell>,
          accessor: "clientRate",
          Cell: (arg: any) => (
            <StyledCenterCell>
              <Rate fixed={4} value={arg.value} />
            </StyledCenterCell>
          ),
          Footer: !showSettled && RateWeightedAverageFooterCell,
        },
        !isOrder
          ? {
              Header: () => <StyledCenterCell>{t("transaction:Base Qty")}</StyledCenterCell>,
              accessor: "from.quantity",
              Cell: (arg: any) => (
                <StyledCenterCell>
                  {formatNumberToString(arg.value)} {arg.row.original.from.currency}
                </StyledCenterCell>
              ),
            }
          : null,
        {
          Header: () => (
            <StyledCenterCell>
              {isOrder ? t("transaction:Qty") : t("transaction:Settlement Qty / Remaining Qty.")}
            </StyledCenterCell>
          ),
          accessor: "SettlementRatio",
          Cell: isOrder ? OrderQtyCell : SettlementRatioCell,
          Footer: !showSettled && SettlementSummaryFooterCell,
        },
        {
          accessor: "currencyPair",
          Cell: CurrencyPairCell,
          Header: () => <StyledLeftCell>{t("transaction:Currency Pair")}</StyledLeftCell>,
        },
        {
          accessor: "end",
          Cell: DateCellTransactionEndDate,
          Header: () => <StyledLeftCell>{t("transaction:Trans. end date")}</StyledLeftCell>,
        },
      ].filter((column) => column !== null),
    [showSettled, isOrder, t]
  );

  if (!rates) return null;

  return (
    <StyledCollapsibleContainer>
      <StyledSectionHeader>
        <StyledSectionName>{isOrder ? t("transaction:Orders") : t("transaction:Transactions")}</StyledSectionName>
        {!isOrder && !disableNav && (
          <StyledButtonContainer>
            <Button
              borderRadius="12px"
              design={!showSettled ? "primary" : "ghost"}
              height="32px"
              mr="4px"
              onClick={() => setShowSettled(false)}
              opacity={!showSettled ? 1 : 0.5}
            >
              Open
            </Button>
            <Button
              borderRadius="12px"
              design={showSettled ? "primary" : "ghost"}
              height="32px"
              onClick={() => setShowSettled(true)}
              opacity={showSettled ? 1 : 0.5}
            >
              Settled
            </Button>
            <Link to={`/companies/${id}/transactions`}>
              <Button borderRadius="12px" design="ghost" height="32px" opacity={0.5}>
                All
              </Button>
            </Link>
          </StyledButtonContainer>
        )}
        <StyledTableControls>
          {!isOrder && <TypeFilter onChange={(newTypeFilter) => setTypeFilter(newTypeFilter)} />}
          <RestrictAccess area={ADD_TRANSACTION}>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated)} onClick={handleOrderAdd}>
              <AddIcon mr="10px" />
              New order
            </StyledAddbutton>
          </RestrictAccess>
          {!isOrder && (
            <RestrictAccess area={ADD_TRANSACTION}>
              <StyledAddbutton disabled={Boolean(company?.isDeactivated)} onClick={handleTransactionAdd}>
                <AddIcon mr="10px" />
                New transaction
              </StyledAddbutton>
            </RestrictAccess>
          )}
        </StyledTableControls>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={visibleTransactions}
          displayFooter
          getCellProps={(cellInfo) => {
            return cellInfo.column.id === "company.name" && hasDeadline(cellInfo.row.original)
              ? { className: "deadline-cell" }
              : {};
          }}
          getRowProps={(rowInfo) => {
            const transaction = rowInfo.original as Transaction;
            const globalMarginCalls = findAllForTransaction(transaction);
            return {
              className: getTransactionRowClassNames(transaction, rates, globalMarginCalls),
              url: `/transactions/${transaction.id}`,
              fromPath: window.location.pathname,
            };
          }}
          paginated={false}
        />
      </StyledTableContainer>
      {filteredTransactions.length > numberOfCollapse && (
        <ButtonContainer>
          <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
            {isExpanded ? t("Collapse") : t("Expand")}
          </StyledButton>
        </ButtonContainer>
      )}
    </StyledCollapsibleContainer>
  );
};
