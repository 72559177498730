import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SearchIcon = createIcon({
  displayName: "SearchIcon",
  path: (
    <g fill="none">
      <path d="M16 16L13 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
      <path
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      />
    </g>
  ),
  viewBox: "0 0 17 17",
});
