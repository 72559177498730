import { SettlementSchema } from "schemas/settlement";
import { timestampFromMap } from "helpers/timestamp";

export const fixSettlement = (settlement: any) => {
  const result = SettlementSchema.safeParse(settlement);
  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    return settlement;
  }

  const fixedSettlement = {
    ...settlement,
    id: `${settlement.id}`,
    date: timestampFromMap(settlement.date),
    quantity: Number(settlement.quantity),
    fwdPoints: Number(settlement.fwdPoints),
    rollback: Number(settlement.rollback),
    points: Number(settlement.points),
    hedges: settlement.hedges.map((hedge: any) => ({
      hedgeId: `${hedge.hedgeId}`,
      quantity: Number(hedge.quantity),
      fwd: Number(hedge.fwd),
      roll: Number(hedge.roll),
    })),
  };

  if ("comment" in fixedSettlement) {
    fixedSettlement.comment = `${fixedSettlement.comment}`;
  }
  if ("paymentComment" in fixedSettlement) {
    fixedSettlement.paymentComment = `${fixedSettlement.paymentComment}`;
  }
  if ("isReversePayment" in fixedSettlement) {
    fixedSettlement.isReversePayment = Boolean(fixedSettlement.isReversePayment);
  }
  if ("isSent" in fixedSettlement) {
    fixedSettlement.isSent = Boolean(fixedSettlement.isSent);
  }
  if ("isDone" in fixedSettlement) {
    fixedSettlement.isDone = Boolean(fixedSettlement.isDone);
  }
  if ("isReversePaymentOk" in fixedSettlement) {
    fixedSettlement.isReversePaymentOk = Boolean(fixedSettlement.isReversePaymentOk);
  }
  if ("closedRate" in fixedSettlement) {
    fixedSettlement.closedRate = Number(fixedSettlement.closedRate);
  }

  return fixedSettlement;
};
