import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFirebase } from "hooks/useFirebase";
import { useTransaction } from "hooks/useTransaction";
import { getTimestamp } from "helpers/date";
import dayjs from "dayjs";
import { sumLQtyOfHedges } from "helpers/hedge";
import { useToast } from "hooks/useToast";
import { HedgeFormModal } from "./HedgeFormModal/HedgeFormModal";
import { useInstitutions } from "hooks/useInstitutions";
import { Hedge } from "interfaces/data";

export function HedgeRoll() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, hedgesIds } = useParams<{ id: string; hedgesIds: string }>();
  const { transaction, hedgeRoll, hedgeErrors } = useTransaction({ id });
  const { timestamp, timestampFrom } = useFirebase();
  const [isSending, setIsSending] = useState(false);

  const { institutions } = useInstitutions();

  const rolledHedgesNumber = useMemo(() => hedgesIds.split(",").length, [hedgesIds]);

  const prolongedHedge = useMemo(() => {
    if (hedgesIds && transaction) {
      const rolledHedgesIdsArray = hedgesIds.split(",");
      const rolledHedges = rolledHedgesIdsArray.map((id) => transaction.hedges?.find((hedge) => hedge.id === id));
      const startDate = rolledHedges[0]?.end || timestamp();
      const transactionEndDate = dayjs(getTimestamp(transaction.end || timestamp()));
      const isTransactionEndSoon = dayjs(getTimestamp(startDate)).add(2, "day").isAfter(transactionEndDate);
      const endDate = isTransactionEndSoon
        ? transaction.end || timestamp()
        : timestampFrom(dayjs(getTimestamp(startDate)).add(2, "day").toDate());

      const institution = rolledHedges[0]?.institution || "Velocity";

      const security = institutions.find((i) => i.name === institution)?.securityDeposit || 5;

      return {
        start: startDate,
        end: endDate,
        institution: institution,
        quantity: sumLQtyOfHedges(rolledHedges),
        security: Number(security),
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hedgesIds, transaction]);

  const maxQuantity = useMemo(() => {
    if (hedgesIds && transaction) {
      const rolledHedgesIdsArray = hedgesIds.split(",");
      const rolledHedges = rolledHedgesIdsArray.map((id) => transaction.hedges?.find((hedge) => hedge.id === id));
      return sumLQtyOfHedges(rolledHedges);
    }
  }, [hedgesIds, transaction]);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newHedge: Partial<Hedge>) => {
      if (!transaction) return;
      setIsSending(true);

      let rolledHedgesData;

      const rolledHedgesIdsArray = hedgesIds.split(",");
      if (rolledHedgesNumber > 1) {
        const rolledHedges = rolledHedgesIdsArray.map((id) => transaction.hedges?.find((hedge) => hedge.id === id));

        rolledHedgesData = rolledHedges
          .map((hedge) => {
            if (!hedge) return null;
            return {
              hedgeId: hedge.id,
              quantity: hedge.leftQuantity,
            };
          })
          .filter(Boolean) as Array<{
          hedgeId: string;
          quantity: number;
        }>;
      } else {
        rolledHedgesData = [
          {
            hedgeId: rolledHedgesIdsArray[0],
            quantity: newHedge.quantity || 0,
          },
        ];
      }

      hedgeRoll(newHedge as Hedge, hedgesIds, rolledHedgesData)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("hedge:Hedge has been rolled successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`hedge:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hedgeRoll, history, hedgesIds, id, transaction]
  );

  if (!transaction || !hedgesIds || !prolongedHedge) {
    return null;
  }

  return (
    <HedgeFormModal
      errors={hedgeErrors}
      initialHedge={prolongedHedge}
      isDisabled={isSending}
      maxQuantity={maxQuantity}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={
        rolledHedgesNumber === 1
          ? t(`hedge:Roll hedge {{hedgesId}}`, {
              hedgesId: hedgesIds,
            })
          : t(`hedge:Roll {{number}} hedges {{hedgesId}}`, {
              number: rolledHedgesNumber,
              hedgesId: hedgesIds,
            })
      }
      transaction={transaction}
      type={rolledHedgesNumber > 1 ? "roll-multiple" : "roll"}
    />
  );
}
