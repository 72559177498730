import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { hasPreviousRowRepeatedFields, NumberCell } from "helpers/tableCells";
import {
  getSumOfHedgesLeft,
  getSumOfInitialMargins,
  getSumOfVariableMargins,
  getWeightedAverageFromObject,
} from "helpers/transaction";
import { NbpRateRecord, Transaction, WeightedAverages } from "interfaces/data";
import { Text } from "@chakra-ui/react";
import {
  StyledButton,
  StyledButtonContainer,
  StyledContainer,
  StyledSectionHeader,
  StyledSectionName,
} from "./shared.styled";
import styled from "@emotion/styled";
import { formatNumberToString } from "helpers/formatNumber";
import { ValueCell } from "./Orders";

export function CurrencyCell(arg: any) {
  if (hasPreviousRowRepeatedFields(arg, ["currency"])) {
    return "";
  }
  return (
    <Text as="span" fontSize="15px" fontWeight="900">
      {arg.value}
    </Text>
  );
}

interface TotalTransactionsProps {
  transactions: Array<Transaction>;
  nbpRates?: NbpRateRecord;
  transactionFilter?: {
    currency: string;
    dealType: string;
  };
  onTransactionFilterUpdate: (
    transactionFilter:
      | {
          currency: string;
          dealType: string;
        }
      | undefined
  ) => void;
}

interface TransactionData {
  currency: string;
  avg: { val: Number; weight: Number }[];
  type: "Buy" | "Sell";
  transQty: Number;
  hedgedQty: Number;
  initialMargin: Number;
  variableMargin: Number;
}

export const TotalTransactions: React.FC<TotalTransactionsProps> = ({
  transactions,
  nbpRates,
  transactionFilter,
  onTransactionFilterUpdate,
}) => {
  const { t } = useTranslation();
  const collapseSize = 8;
  const [isExpanded, setExpanded] = useState(false);

  const StyledTableContainer = styled.div`
    tr.selected-transaction-filter td {
      background: #dedede;
      font-weight: 900;
    }
  `;

  const totalTransactionsData = useMemo(() => {
    if (!transactions || transactions.length === 0 || !nbpRates) return [];

    const transactionsPairCurrencies = Array.from(
      new Set(transactions.map((transaction) => `${transaction.from.currency}/${transaction.to.currency}`))
    ).sort((a, b) => a.localeCompare(b));

    // prepare object with currencies as keys and counter objects as values
    const transactionsData = transactionsPairCurrencies
      .map((currency) => currency)
      .reduce(
        (acc, curr) => (
          (acc[curr] = {
            Buy: {
              currency: curr,
              avg: [],
              type: "Buy",
              transQty: 0,
              hedgedQty: 0,
              initialMargin: 0,
              variableMargin: 0,
            },
            Sell: {
              currency: curr,
              avg: [],
              type: "Sell",
              transQty: 0,
              hedgedQty: 0,
              initialMargin: 0,
              variableMargin: 0,
            },
            // eslint-disable-next-line no-sequences
          }),
          acc
        ),
        {} as { [key: string]: { [typeKey: string]: TransactionData } }
      );

    transactions.forEach((transaction) => {
      // determine transaction currency
      const {
        from: { currency: fromCurrency },
        to: { currency: toCurrency },
      } = transaction;
      const currency = `${fromCurrency}/${toCurrency}`;
      const type = transaction.dealType;

      // transactions in PLN are not considered for risk panel
      if (fromCurrency === "PLN") return;

      // transaction is same currency are wrong
      if (fromCurrency === toCurrency) return;

      const hedgesLeft = Number(getSumOfHedgesLeft(transaction));

      // increase proper counters for given currency
      transactionsData[currency][type].avg = [
        {
          weight: Number(transaction.clientRate),
          val: hedgesLeft,
        },
        ...transactionsData[currency][type].avg,
      ];

      transactionsData[currency][type].transQty =
        Number(transactionsData[currency][type].transQty) + Number(transaction.from.quantity);

      transactionsData[currency][type].hedgedQty = Number(transactionsData[currency][type].hedgedQty) + hedgesLeft;

      transactionsData[currency][type].initialMargin =
        Number(transactionsData[currency][type].initialMargin) +
        getSumOfInitialMargins(transaction) * Number(nbpRates.rates[fromCurrency]);

      transactionsData[currency][type].variableMargin =
        Number(transactionsData[currency][type].variableMargin) +
        -(Number(getSumOfVariableMargins(transaction, nbpRates)) * Number(nbpRates.rates[fromCurrency]));
    });

    const separatedCurrencies = Object.values(transactionsData)
      .filter((transactionData: any) => transactionData["Sell"].transQty !== 0 || transactionData["Buy"].transQty !== 0)
      .sort((a: any, b: any) => {
        const aValue = (Number(a["Sell"].transQty) + Number(a["Buy"].transQty)) * nbpRates.rates[a["Sell"].currency];
        const bValue = (Number(b["Sell"].transQty) + Number(b["Buy"].transQty)) * nbpRates.rates[b["Sell"].currency];

        return aValue > bValue ? -1 : 1;
      });

    const separatedTypes: Array<TransactionData> = [];
    separatedCurrencies.forEach((currency) => Object.values(currency).forEach((type) => separatedTypes.push(type)));

    return separatedTypes;
  }, [nbpRates, transactions]);

  const visibleTotalTransactionsData = useMemo(() => {
    if (isExpanded) {
      return totalTransactionsData;
    }
    return totalTransactionsData.slice(0, collapseSize);
  }, [totalTransactionsData, isExpanded]);

  const columns = useMemo(
    () => [
      {
        Header: t("risk:Currency"),
        accessor: "currency",
        Cell: CurrencyCell,
      },
      {
        Header: t("risk:Avarage"),
        accessor: "avg",
        Cell: (arg) => (
          <Text as="span" whiteSpace="nowrap">
            {getWeightedAverageFromObject({
              values: arg.value.map((v: WeightedAverages) => v.val),
              weights: arg.value.map((w: WeightedAverages) => w.weight),
            })}
          </Text>
        ),
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Hedged Qty.")}
          </Text>
        ),
        accessor: "hedgedQty",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Trans. Qty.")}
          </Text>
        ),
        accessor: "transQty",
        Cell: ValueCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Initial margin")}
          </Text>
        ),
        accessor: "initialMargin",
        Cell: NumberCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Variation margin")}
          </Text>
        ),
        accessor: "variableMargin",
        Cell: NumberCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("risk:Total margin")}
          </Text>
        ),
        accessor: "totalMargin",
        Cell: (arg) => {
          const data = arg.row.original as any;
          return (
            <Text as="span" display="block" textAlign="right" whiteSpace="nowrap">
              {formatNumberToString(Number(data.initialMargin) + Number(data.variableMargin))}
            </Text>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  return (
    <StyledContainer>
      <StyledSectionHeader>
        <StyledSectionName>{t("risk:Total transactions")}</StyledSectionName>
      </StyledSectionHeader>
      <StyledTableContainer>
        <Table
          columns={columns}
          data={visibleTotalTransactionsData}
          getRowProps={(rowInfo) => {
            const row = rowInfo.original;
            const isRowCurrentlySelected =
              transactionFilter &&
              row.currency === transactionFilter.currency &&
              row.type === transactionFilter.dealType;
            return {
              className: isRowCurrentlySelected ? "selected-transaction-filter" : "",
              onClick: () => {
                if (isRowCurrentlySelected) {
                  onTransactionFilterUpdate(undefined);
                } else {
                  onTransactionFilterUpdate({
                    currency: row.currency,
                    dealType: row.type,
                  });
                }
              },
            };
          }}
          paginated={false}
        />
        {totalTransactionsData.length > collapseSize && (
          <StyledButtonContainer>
            <StyledButton onClick={() => setExpanded((prevState) => !prevState)}>
              {isExpanded ? t("Collapse") : t("Expand")}
            </StyledButton>
          </StyledButtonContainer>
        )}
      </StyledTableContainer>
    </StyledContainer>
  );
};
