import styled from "@emotion/styled";

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: 1fr auto;

  @media (min-width: 1600px) {
    grid-template-columns: 2fr 1fr;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
