import { useCallback } from "react";

import { UseResetPassword } from "../interfaces/hooks";
import { useAuth } from "./useAuth";
import { useFirebase } from "./useFirebase";

export const useResetPassword: UseResetPassword = () => {
  const { auth } = useFirebase();
  const { logout } = useAuth();

  const requestResetPassword = useCallback((email: string) => {
    return auth.sendPasswordResetEmail(email).catch((error: firebase.FirebaseError) => {
      console.error("usePasswordReset:", error.code, error.message);
      return error;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmResetPassword = useCallback((code: string, password: string) => {
    return auth
      .confirmPasswordReset(code, password)
      .then(() => {
        // When password will be changed, sign out current user for security reasons
        // And allow him to login again with new password
        return logout("/login?reset-password=success");
      })
      .catch((error: firebase.FirebaseError) => {
        console.error("usePasswordReset:", error.code, error.message);
        return error;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    requestResetPassword,
    confirmResetPassword,
  };
};
