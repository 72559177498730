import React, { createContext, PropsWithChildren, useCallback, useState } from "react";
import _cloneDeep from "lodash.clonedeep";
import { ActivityInfo } from "../components/Sidebar/LastActivityWidget";
import { useAuth } from "../hooks/useAuth";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Company, Transaction } from "../interfaces/data";
import { formatNumberToString } from "../helpers/formatNumber";

export interface LastActivityContextType {
  visitedTransactionsInfo: Array<ActivityInfo>;
  addNewVisitedTransactionsInfo: (transaction: Transaction) => void;
  visitedCompaniesInfo: Array<ActivityInfo>;
  addNewVisitedCompaniesInfo: (company: Company) => void;
}

export const LastActivityContext = createContext({} as LastActivityContextType);

export const LastActivityContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { profile } = useAuth();

  const [localStorageVisitedTransactionsInfo, setLocalStorageVisitedTransactionsInfo] = useLocalStorage(
    `visitedTransactionsInfo-${profile?.id}`,
    [] as Array<ActivityInfo>
  );
  const [localStorageVisitedCompaniesInfo, setLocalStorageVisitedCompaniesInfo] = useLocalStorage(
    `visitedCompaniesInfo-${profile?.id}`,
    [] as Array<ActivityInfo>
  );

  const [visitedTransactionsInfo, setVisitedTransactionsInfo] = useState(localStorageVisitedTransactionsInfo);
  const [visitedCompaniesInfo, setVisitedCompaniesInfo] = useState(localStorageVisitedCompaniesInfo);

  const addNewVisitedTransactionsInfo = useCallback(
    (transaction: Transaction) => {
      const newVisitedTransactionsInfo = _cloneDeep(
        visitedTransactionsInfo.filter((activity: ActivityInfo) => activity.id !== transaction.id) ||
          ([] as Array<ActivityInfo>)
      );

      newVisitedTransactionsInfo.unshift({
        id: transaction.id,
        title: String(transaction.number),
        url: `/transactions/${transaction.id}/`,
        info: `${transaction.dealType[0]}, ${formatNumberToString(transaction.from.quantity)} ${
          transaction.from.currency
        }, ${transaction.clientRate}, ${transaction.company.name}`,
      });

      const updatedVisitedTransactionsInfo = newVisitedTransactionsInfo.slice(0, 5);

      setLocalStorageVisitedTransactionsInfo(updatedVisitedTransactionsInfo);
      setVisitedTransactionsInfo(updatedVisitedTransactionsInfo);
    },
    [setLocalStorageVisitedTransactionsInfo, visitedTransactionsInfo]
  );

  const addNewVisitedCompaniesInfo = useCallback(
    (company: Company) => {
      const newVisitedCompaniesInfo = _cloneDeep(
        visitedCompaniesInfo.filter((activity: ActivityInfo) => activity.id !== company.id) ||
          ([] as Array<ActivityInfo>)
      );

      const companyContact = (company.contacts?.filter((contact) => contact.type === "authorized person") || [])[0];

      newVisitedCompaniesInfo.unshift({
        id: company.id,
        title: company.name,
        url: `/companies/${company.id}/`,
        info: companyContact ? `${companyContact.firstName} ${companyContact.lastName}` : "",
      });
      const updatedVisitedCompaniesInfo = newVisitedCompaniesInfo.slice(0, 5);

      setLocalStorageVisitedCompaniesInfo(updatedVisitedCompaniesInfo);
      setVisitedCompaniesInfo(updatedVisitedCompaniesInfo);
    },
    [setLocalStorageVisitedCompaniesInfo, visitedCompaniesInfo]
  );

  return (
    <LastActivityContext.Provider
      value={{
        visitedTransactionsInfo,
        addNewVisitedTransactionsInfo,
        visitedCompaniesInfo,
        addNewVisitedCompaniesInfo,
      }}
    >
      {children}
    </LastActivityContext.Provider>
  );
};
