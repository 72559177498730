// ROLES
import { timestampFromMap } from "./timestamp";
import { Contact } from "schemas/contact";

export const AUTHORIZED_PERSON = "authorized person";
export const LEGAL_REPRESENTANT = "legal representant";
export const BENEFICIARY = "beneficiary";
export const SALES = "sales";
export const EXTERNAL = "external";

// STATUSES
export const CONTACT_ACTIVE = "Active";
export const CONTACT_INACTIVE = "Inactive";

// ID_TYPES
export const DRIVING_LICENSE = "Prawo jazdy";
export const PASSPORT = "Paszport";
export const ID = "Dowód osobisty";
export const PERMANENT_RESIDENCE_CARD = "Karta stałego pobytu";

export const fixContactTimestamps = (contact: Contact) => {
  if (contact.createdAt) {
    contact.createdAt = timestampFromMap(contact.createdAt);
  }
  if (contact.modifiedAt) {
    contact.modifiedAt = timestampFromMap(contact.modifiedAt);
  }

  return contact;
};
