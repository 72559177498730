import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTransaction } from "../../hooks/useTransaction";
import { useToast } from "../../hooks/useToast";
import { Modal } from "../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";
import { useMail } from "../../hooks/useMail";
import { SETTLEMENT_MAIL } from "../../helpers/mails";

export function SettlementCancel() {
  const history = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const { id, settlementId } = useParams<{
    id: string;
    settlementId: string;
  }>();
  const { transaction, settlementCancel } = useTransaction({ id });
  const sendCancellation = useMail();
  const [isSending, setIsSending] = useState(false);

  const isSettlementNet = useMemo(() => {
    if (!transaction || !transaction.settlements || !settlementId) return false;
    const settlement = transaction.settlements.find((settlement) => settlementId === settlement.id);
    return settlement?.type === "Net";
  }, [transaction, settlementId]);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const deleteSettlement = useCallback(() => {
    settlementCancel(settlementId)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("settlement:Settlement has been canceled successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`settlement:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });
  }, [settlementCancel, setIsSending, settlementId, handleClose, toast, t]);

  const handleCancelAction = useCallback(() => {
    setIsSending(true);

    if (transaction?.type === "FR" && !isSettlementNet) {
      sendCancellation(SETTLEMENT_MAIL, String(transaction?.id), {
        settlement: settlementId,
        cancel: "yes",
      }).then(() => {
        deleteSettlement();
      });
    } else {
      deleteSettlement();
    }
  }, [transaction?.type, transaction?.id, isSettlementNet, sendCancellation, settlementId, deleteSettlement]);

  const editedSettlement = useMemo(() => {
    if (transaction) {
      return transaction.settlements?.find((settlement) => settlement.id === settlementId);
    }
  }, [settlementId, transaction]);

  useEffect(() => {
    if (!transaction || !transaction.settlements || !editedSettlement) return;

    const maxSettlementId = Math.max(...transaction.settlements.map((item) => Number(item.id?.split("-")[1])), 0);

    // Only most recent Settlement can be canceled
    if (maxSettlementId !== Number(editedSettlement.id.split("-")[1])) {
      handleClose();
      toast({
        type: "error",
        message: t("settlement:Only most recent Settlement can be canceled."),
      });
      return;
    }

    // Settlement can be canceled only no heges are used
    if (editedSettlement.hedges && editedSettlement.hedges.length > 0) {
      handleClose();
      toast({
        type: "error",
        message: t("settlement:This Settlement cannot be canceled, because it uses hedges. Remove all hedges first."),
      });
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, editedSettlement]);

  if (!transaction || !editedSettlement) return null;

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={t(`settlement:Cancel settlement {{settlementId}}`, {
        settlementId,
      })}
    >
      <Box mb={"20px"}>
        {t(`settlement:This action can't be undone. Are you sure you want to cancel settlement {{settlementId}}?`, {
          settlementId,
        })}
      </Box>
    </Modal>
  );
}
