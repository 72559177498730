import styled from "@emotion/styled";

export const StyledButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  max-width: 80px;
`;

export const StyledIconContainer = styled.span`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors["sk-blue"]};
`;

export const StyledTableContainer = styled.div`
  tr.closed-row {
    opacity: 0.5;
  }
  tr.global-row {
    outline: 5px solid ${({ theme }) => theme.colors["sk-blue"]};
    outline-offset: -5px;
    background: ${(props) => `${props.theme.colors["sk-blue"]}25`};
    > * {
      font-weight: 700;
    }
  }
  tr td:last-child {
    width: 60px;
  }
`;
