import React from "react";
import { useTranslation } from "react-i18next";
import { BaseContact } from "../../../interfaces/data";
import { ContactAddress } from "../../Contact/ContactAddress/ContactAddress";
import styled from "@emotion/styled";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { findCountry } from "../../../helpers/findCountry";
import { capitalizeFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { Box } from "@chakra-ui/react";

interface SingleContactProps {
  contact: BaseContact;
  showAddress?: boolean;
  showOther?: boolean;
  showCitizenship?: boolean;
  hidePersonalDetails?: boolean;
}

export const StyledAddressHeader = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
`;

export function SingleContact({
  contact,
  showAddress,
  showOther,
  showCitizenship,
  hidePersonalDetails,
}: SingleContactProps) {
  const { t } = useTranslation();

  return (
    <Box pt="20px">
      <TwoColumnsLayout>
        {!hidePersonalDetails && (
          <>
            <LabeledText label={t("contact:Email")} value={contact.email} />
            <LabeledText label={t("contact:Phone")} value={contact.phone} />
          </>
        )}
        <LabeledText label={t("contact:Country")} value={findCountry(contact.address?.country)} />
        {showCitizenship && <LabeledText label={t("contact:Citizenship")} value={contact?.citizenship} />}
        {!hidePersonalDetails && (
          <>
            <LabeledText label={t("contact:PESEL")} value={contact.idDetails?.pesel} />
            <LabeledText label={t("contact:ID Type")} value={contact.idDetails?.idType} />
            <LabeledText label={t("contact:ID number")} value={contact.idDetails?.number} />
            <LabeledText label={t("contact:Expiration date")} value={contact.idDetails?.expirationDate} />
          </>
        )}
        <LabeledText label={t("contact:Role")} value={capitalizeFirstLetter(contact.type)} />
        {showOther && <LabeledText label={t("contact:Other")} value={contact.other} />}
      </TwoColumnsLayout>
      {showAddress && (
        <>
          <StyledAddressHeader>{t("contact:Address")}</StyledAddressHeader>
          <ContactAddress contact={contact} />
        </>
      )}
    </Box>
  );
}
