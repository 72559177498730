import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  button {
    outline: none;
  }
`;

export const StyledPageLink = styled.button`
  height: 30px;
  width: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 8px;
  font-weight: bold;
  &.current {
    background: ${(props) => props.theme.colors["sk-purple"]};
    border: 1px solid ${(props) => props.theme.colors["sk-purple"]};
    color: white;
  }
`;

export const StyledTruncatedDots = styled.div`
  height: 30px;
  width: 30px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelect = styled.select`
  margin: 5px 5px 5px 30px;
  padding: 0 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 8px;
`;

export const StyledNavigationButton = styled.button`
  height: 30px;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  border-radius: 8px;
  margin: 5px;
  padding: 0 15px;
  font-weight: bold;
  &:disabled {
    opacity: 0.5;
  }
`;
