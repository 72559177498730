import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Company } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";
import { findCountry } from "../../../helpers/findCountry";

interface CompanyAddressProps {
  company: Company;
}

export function CompanyAddress({ company }: CompanyAddressProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("company:Street")} value={company.companyAddress?.street} />
      <LabeledText label={t("company:Apt number")} value={company.companyAddress?.number} />
      <LabeledText label={t("company:Code")} value={company.companyAddress?.code} />
      <LabeledText label={t("company:City")} value={company.companyAddress?.city} />
      <LabeledText label={t("company:Country")} value={findCountry(company.companyAddress?.country)} />
    </TwoColumnsLayout>
  );
}
