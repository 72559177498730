import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/Table/Table";
import { formatNumberToString, formatRate } from "../../../../../../helpers/formatNumber";
import { Transaction } from "../../../../../../interfaces/data";
import { StyledTableContainer } from "../../../../../Transactions/Transactions";
import theme from "../../../../../../theme/theme";
import { transformTimestamp } from "../../../../../../helpers/date";
import { determineTransactionCurrencyPair, getQuantityLeft } from "../../../../../../helpers/transaction";

interface TransactionTableProps {
  transactions: Array<Transaction>;
  isLoading: boolean;
  setSelectedTransactions: Dispatch<SetStateAction<Array<Transaction>>>;
}

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
  text-decoration: underline;
`;

const StyledTableWrapper = styled(StyledTableContainer)`
  max-height: 760px;
  overflow-y: auto;
`;

export const BoldText = styled.span`
  font-weight: 900;
`;

const IdCell = (arg: any) => {
  const transaction = arg.row.original as Transaction;
  return (
    <StyledLink to={`/transactions/${transaction.id}`}>
      <Box as="span" display="block" whiteSpace="nowrap">
        {transaction.number}
      </Box>
    </StyledLink>
  );
};

const SettlementRatioCell = (arg: any) => {
  const transaction = arg.row.original as Transaction;
  if (!transaction) return "";

  const quantityLeft = getQuantityLeft(transaction);

  return (
    <>
      {formatNumberToString(Number(transaction.from.quantity) - quantityLeft)} {transaction.from.currency} /{" "}
      <BoldText>
        {formatNumberToString(quantityLeft)} {transaction.from.currency}
      </BoldText>
    </>
  );
};

export const TransactionTable: React.FC<TransactionTableProps> = ({
  transactions,
  isLoading,
  setSelectedTransactions,
}) => {
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState([""]);
  const [selectedDealType, setSelectedDealType] = useState("");
  const [enableAllFields, setEnableAllFIelds] = useState(true);
  const { t } = useTranslation();

  const shouldShowAllRowSelect =
    new Set(transactions.map((transaction) => determineTransactionCurrencyPair(transaction).join())).size === 1;

  const columns = useMemo(
    () => [
      {
        Header: t("transaction:ID"),
        accessor: "number",
        Cell: IdCell,
      },
      {
        Header: t("transaction:Trans date"),
        accessor: "agreement",
        Cell: (arg: any) => transformTimestamp(arg.value),
      },
      {
        Header: t("transaction:Type"),
        accessor: "dealType",
      },
      {
        Header: t("transaction:Client Rate"),
        accessor: "clientRate",
        Cell: (arg: any) => formatRate(arg.value),
      },
      {
        Header: t("transaction:Base Qty"),
        accessor: "from.quantity",
        Cell: (arg: any) => (
          <>
            {formatNumberToString(arg.value)} {arg.row.original.from.currency}
          </>
        ),
      },
      {
        Header: t("transaction:Currency Pair"),
        accessor: "from",
        Cell: (arg: any) => <>{determineTransactionCurrencyPair(arg.row.original)}</>,
      },
      {
        Header: t("transaction:Settlement Qty / Remaining Qty."),
        accessor: "settlement",
        Cell: SettlementRatioCell,
      },
      {
        Header: t("transaction:Trans. end date"),
        accessor: "end",
        Cell: (arg: any) => transformTimestamp(arg.value),
      },
    ],
    [t]
  );

  const setSelectedRow = (selectedRows: Array<boolean>) => {
    const selectedTransactions = transactions.filter((t, index) => selectedRows[index]);
    setSelectedTransactions(selectedTransactions);
    if (selectedTransactions.length) {
      setSelectedCurrencyPair(determineTransactionCurrencyPair(selectedTransactions[0]));
      setSelectedDealType(selectedTransactions[0].dealType);
      setEnableAllFIelds(false);
    } else {
      setEnableAllFIelds(true);
    }
  };

  const isRowActive = (transaction: Transaction) => {
    if (
      (determineTransactionCurrencyPair(transaction).toString() === selectedCurrencyPair.toString() &&
        transaction.dealType === selectedDealType) ||
      enableAllFields
    ) {
      return null;
    }
    return "inactive-row";
  };

  return (
    <StyledTableWrapper>
      <Box backgroundColor={theme.colors["sk-purple"]} h="12px" />
      <Box backgroundColor="white" fontSize="18px" fontWeight="bold" padding="18px 30px 5px 30px">
        {t("Company:Transactions")}
      </Box>
      <Table
        columns={columns}
        data={transactions}
        getRowProps={(rowInfo) => {
          const transaction = rowInfo.original as Transaction;
          return {
            className: isRowActive(transaction),
          };
        }}
        loading={isLoading}
        multipleRowSelect
        paginated={false}
        setRowsSelected={setSelectedRow}
        shouldShowAllRowSelect={shouldShowAllRowSelect}
      />
    </StyledTableWrapper>
  );
};
