import React from "react";
import { LabeledText } from "../../../components/LabeledText/LabeledText";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { Application } from "../../../interfaces/data";
import { useTranslation } from "react-i18next";

interface CompanyInfoProps {
  application: Application;
}

export function CompanyInfo({ application }: CompanyInfoProps) {
  const { t } = useTranslation();
  return (
    <TwoColumnsLayout>
      <LabeledText label={t("application:Name")} value={application.name} />
      <LabeledText label={t("application:Legal entity")} value={application.legalEntity} />
      <LabeledText label={t("application:NIP")} value={application.nip} />
      <LabeledText label={t("application:REGON")} value={application.regon} />
      <LabeledText label={t("application:KRS")} value={application.krs} />
      <LabeledText label={t("application:Phone")} value={application.phone} />
    </TwoColumnsLayout>
  );
}
