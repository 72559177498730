import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Icon, InputLeftElement, Text } from "@chakra-ui/react";
import dayjs from "dayjs";

import { createFirestoreTimestamp, getTimestamp } from "helpers/date";
import { CurrencyCell } from "helpers/tableCells";
import useDebounce from "hooks/useDebounce";

import {
  StyledContainer,
  StyledInput,
  StyledInputGroup,
  StyledTableControls,
} from "components/ItemsListElements/ItemsListElements";
import { Heading } from "components/Heading/Heading";
import { PageHeader } from "components/PageHeader/PageHeader";
import { Table } from "components/Table/Table";

import { useOperations } from "hooks/useOperations";
import { Link } from "react-router-dom";

const sortByInitialValue = {
  id: "date",
  desc: "desc",
};

export const Operations: React.FC = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [sort, setSort] = useState(sortByInitialValue);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const debouncedSearchQuery = useDebounce(searchQuery, 400);

  const DateCell = ({ value }: { value: any }) =>
    dayjs(getTimestamp(createFirestoreTimestamp(value))).format("DD/MM/YYYY HH:mm:ss");

  const IdCell = (arg: any) => {
    const { transaction, companyId } = arg.row.original;
    const link = transaction ? `/transactions/${transaction.id}` : `/companies/${companyId}`;

    return (
      <Link to={link}>
        <Box d="flex" whiteSpace="nowrap">
          {arg.value}
        </Box>
      </Link>
    );
  };

  const CompanyCell = ({ value }: { value: any }) => (
    <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
      {value}
    </Text>
  );

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("transaction:Company"),
        accessor: "company",
        Cell: CompanyCell,
      },
      {
        Header: t("transaction:Creation date"),
        accessor: "date",
        Cell: DateCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {t("transaction:From Quantity")}
          </Text>
        ),
        accessor: "from",
        Cell: CurrencyCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="left" whiteSpace="nowrap" width="100%">
            {t("transaction:To Quantity")}
          </Text>
        ),
        accessor: "to",
        Cell: CurrencyCell,
      },
      {
        Header: t("transaction:Operation type"),
        accessor: "status",
      },
    ],
    [t]
  );

  const { operations, loading, pageCount } = useOperations({
    phrase: debouncedSearchQuery,
    fields: "company, date, from, to, status",
    orderBy: sort.id,
    orderDirection: sort.desc ? "desc" : "asc",
    offset,
    limit,
  });

  const handleTableUpdate = useCallback(
    ({
      sortBy,
      pageSize,
      pageIndex,
    }: {
      sortBy: Array<{ id: string; desc: string }>;
      pageSize: number;
      pageIndex: number;
    }) => {
      if (sortBy.length) {
        setSort(sortBy[0]);
      } else {
        setSort(sortByInitialValue);
      }
      setOffset(pageIndex * pageSize);
      setLimit(pageSize);
    },
    []
  );

  const handleSearchInputUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value),
    []
  );

  const renderDetails = React.useCallback(
    () => (
      <Box d="flex" fontSize="10px" justifyContent="space-around" ml="200px" mr="200px">
        <div>
          <Text fontSize="13px" fontWeight="bold" mb="10px">
            {t("settings:Old data")}
          </Text>
        </div>
        <div>
          <Text fontSize="13px" fontWeight="bold" mb="10px">
            {t("settings:New data")}
          </Text>
        </div>
      </Box>
    ),
    [t]
  );

  return (
    <>
      <PageHeader>
        <Heading type="h1">{t("transaction:Operations")}</Heading>
      </PageHeader>
      <StyledContainer>
        <StyledTableControls>
          <StyledInputGroup>
            <InputLeftElement children={<Icon name="search" />} />
            <StyledInput onChange={handleSearchInputUpdate} placeholder={t("Search") as string} value={searchQuery} />
          </StyledInputGroup>
        </StyledTableControls>
        <Table
          columns={columns}
          data={operations}
          disableSortBy={false}
          initialPageSize={20}
          loading={loading}
          onStateUpdate={handleTableUpdate}
          pageCount={pageCount}
          pageSizeOptions={[20, 50, 100]}
          renderRowSubComponent={renderDetails}
        />
      </StyledContainer>
    </>
  );
};
