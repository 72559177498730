import { Box, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "components/Button/Button";
import { Input } from "components/Input/Input";
import { StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { SearchBox } from "components/SearchBox/SearchBox";
import { Select } from "components/Select/Select";
import { SimplePagination } from "components/SimplePagination/SimplePagination";
import { Table } from "components/Table/Table";
import { actions } from "helpers/actions";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import { getTimestamp } from "helpers/date";
import { convertActionToVerb, convertCollectionToNoun, getLogItemIdentifier } from "helpers/logs";
import { useLogs } from "hooks/useLogs";
import { Company, Log, Profile } from "interfaces/data";
import { StyledPanel } from "../Settings";
import { LogDetailsData } from "./LogDetailsData";

const StyledLinkContainer = styled.div`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

const DateCell = (arg: any) => {
  const { timestamp } = arg.row.original as Log;
  return dayjs(getTimestamp(timestamp)).format("DD/MM/YYYY HH:mm:ss");
};

const NameCell = (arg: any) => {
  const { user } = arg.row.original as Log;
  return (
    <>
      {user.name} {user.surname}
    </>
  );
};

const CompanyCell = (arg: any) => {
  const { company } = arg.row.original as Log;
  return company ? (
    <StyledLinkContainer>
      <Link to={`/companies/${company.id}`}>{company.name}</Link>
    </StyledLinkContainer>
  ) : (
    "-"
  );
};

export function LogsHistory() {
  const perPage = 25;
  const [searchboxKey, setSearchboxKey] = useState(1);
  const [selectedUser, setSelectedUser] = useState<Profile>();
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [selectedAction, setSelectedAction] = useState<string>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [paginationData, setPaginationData] = useState({
    startAfter: undefined,
    endBefore: undefined,
  });
  const { logs, firstLogDoc, lastLogDoc, fetch, hasPrev, hasNext } = useLogs();
  const { t } = useTranslation();

  const ActivityCell = useCallback(
    (arg: any) => {
      const { action, item, actionDetails } = arg.row.original as Log;
      return (
        <>
          {t(`settings:${convertActionToVerb(action)}`)} {t(`settings:${convertCollectionToNoun(item?.collection)}`)}{" "}
          {getLogItemIdentifier(arg.row.original)} {actionDetails ? `(${actionDetails})` : ""}
        </>
      );
    },
    [t]
  );

  const UrlCell = useCallback(
    (arg: any) => {
      const { url } = arg.row.original as Log;
      if (!url) return "-";
      return (
        <StyledLinkContainer>
          <Link to={url}>{t("settings:View")}</Link>
        </StyledLinkContainer>
      );
    },
    [t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("settings:Date"),
        accessor: "timestamp",
        Cell: DateCell,
      },
      {
        Header: t("settings:Member"),
        accessor: "user.name",
        Cell: NameCell,
      },
      {
        Header: t("settings:Company"),
        accessor: "company",
        Cell: CompanyCell,
      },
      {
        Header: t("settings:Activity"),
        accessor: "action",
        Cell: ActivityCell,
      },
      {
        Header: t("settings:Link"),
        accessor: "url",
        Cell: UrlCell,
      },
      {
        Header: () => (
          <Text as="span" display="block" textAlign="right">
            {t("settings:Details")}
          </Text>
        ),
        id: "show-details",
        Cell: ({ row }: any) => (
          <StyledLinkContainer>
            <Text as="span" display="block" textAlign="right" {...row.getToggleRowExpandedProps()}>
              {t(`settings:Details`)}
            </Text>
          </StyledLinkContainer>
        ),
      },
    ],
    [ActivityCell, UrlCell, t]
  );

  const renderDetails = React.useCallback(
    ({ row }: { row: any }) => (
      <Box display="flex" fontSize="10px" justifyContent="space-around" ml="200px" mr="200px">
        <div>
          <Text fontSize="13px" fontWeight="bold" mb="10px">
            {t("settings:Old data")}
          </Text>
          {row.original.oldData ? <LogDetailsData data={row.original.oldData} /> : "-"}
        </div>
        <div>
          <Text fontSize="13px" fontWeight="bold" mb="10px">
            {t("settings:New data")}
          </Text>
          {row.original.newData ? <LogDetailsData data={row.original.newData} /> : "-"}
        </div>
      </Box>
    ),
    [t]
  );

  useEffect(() => {
    const _startDate = startDate ? dayjs(startDate).toDate() : undefined;
    const _endDate = endDate ? dayjs(endDate).add(1, "day").toDate() : undefined;
    fetch({
      startDate: _startDate,
      endDate: _endDate,
      userId: selectedUser?.userId,
      companyId: selectedCompany?.id,
      limit: perPage,
      startAfter: paginationData.startAfter,
      endBefore: paginationData.endBefore,
      action: selectedAction,
    });
  }, [fetch, endDate, startDate, selectedUser, selectedAction, paginationData, selectedCompany]);

  const getNextPage = useCallback(() => {
    if (lastLogDoc) {
      setPaginationData({
        startAfter: lastLogDoc,
        endBefore: undefined,
      });
    }
  }, [lastLogDoc]);

  const getPrevPage = useCallback(() => {
    if (firstLogDoc) {
      setPaginationData({
        endBefore: firstLogDoc,
        startAfter: undefined,
      });
    }
  }, [firstLogDoc]);

  const handleResetFilters = useCallback(() => {
    setSelectedUser(undefined);
    setSelectedCompany(undefined);
    setSelectedAction("");
    setStartDate(undefined);
    setEndDate(undefined);
    setPaginationData({
      endBefore: undefined,
      startAfter: undefined,
    });
    setSearchboxKey(searchboxKey + 1);
  }, [searchboxKey]);

  if (!logs) return null;

  return (
    <StyledPanel>
      <StyledTableControls>
        <Box mt="30px" width="358px">
          <SearchBox
            collection="profiles"
            fields="name,surname"
            item={(profile: Profile) => `${profile.name} ${profile.surname}`}
            key={"user" + searchboxKey}
            label="Select user"
            onSelect={(profile: Profile) => {
              setSelectedUser(profile);
              setPaginationData({
                endBefore: undefined,
                startAfter: undefined,
              });
            }}
            orderBy="name"
            persist={(profile: Profile) => `${profile.name} ${profile.surname}`}
            placeholder="Select user"
          />
        </Box>
        <Box ml="10px" mt="30px" width="358px">
          <SearchBox
            collection="companies"
            fields="name"
            item={(company: Company) => company.name}
            key={"company" + searchboxKey}
            label="Select company"
            onSelect={(company: Company) => {
              setSelectedCompany(company);
              setPaginationData({
                endBefore: undefined,
                startAfter: undefined,
              });
            }}
            orderBy="name"
            persist={(company: Company) => company.name}
            placeholder="Select company"
          />
        </Box>
        <Box alignItems="center" display="flex" justifyContent="space-between" ml="auto" mt="30px" pl="10px">
          <Input
            id="startDate"
            label={t("transaction:From")}
            name="startDate"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setStartDate(e.target.value);
              setPaginationData({
                endBefore: undefined,
                startAfter: undefined,
              });
            }}
            topLabel
            type="date"
            value={startDate}
            w={200}
          />
          <Box ml="10px">
            <Input
              id="endDate"
              label={t("transaction:To")}
              name="endDate"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEndDate(e.target.value);
                setPaginationData({
                  endBefore: undefined,
                  startAfter: undefined,
                });
              }}
              topLabel
              type="date"
              value={endDate}
              w={200}
            />
          </Box>
          <Box ml="10px">
            <Select
              label="Type"
              minWidth="200px"
              name="action"
              onChange={(e: any) => {
                setSelectedAction(e.target.value);
                setPaginationData({
                  endBefore: undefined,
                  startAfter: undefined,
                });
              }}
              options={actions.map((action) => {
                return { value: action, label: capitalizeFirstLetter(action) };
              })}
              placeholder="Any"
              value={selectedAction}
            />
          </Box>
        </Box>
        <Button design="secondary" ml="10px" mt="30px" onClick={handleResetFilters}>
          {t("settings:Reset filters")}
        </Button>
      </StyledTableControls>
      <Table columns={columns} data={logs} paginated={false} renderRowSubComponent={renderDetails} />
      <Box display="flex" justifyContent="center" mb="20px" mt="20px">
        <SimplePagination hasNext={hasNext} hasPrev={hasPrev} onNextPage={getNextPage} onPrevPage={getPrevPage} />
      </Box>
    </StyledPanel>
  );
}
