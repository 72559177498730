import { BaseProfile, UserRole } from "interfaces/data";
import { useCallback, useEffect, useState } from "react";
import { UseAuth } from "interfaces/hooks";
import { User } from "interfaces/models";
import { useLocalStorage } from "./useLocalStorage";
import { useFirebase } from "./useFirebase";
import i18n from "i18n";
import { firestore } from "firebase";

const mapUserAttributes = (user: firebase.User): User => ({
  uid: user.uid,
  email: user.email,
  emailVerified: user.emailVerified,
  profile: null,
});

export const useAuth: UseAuth = (options = { skipAuthCheck: true }) => {
  const { auth, db } = useFirebase();
  const [isLogged, setIsLogged] = useLocalStorage("isLogged", false);
  const [cachedUser, setCachedUser] = useLocalStorage("user", {});
  const [cachedProfile, setCachedProfile] = useLocalStorage("profile", {});
  const [cachedToken, setCachedToken] = useLocalStorage("userToken", {});
  const [profile, setProfile] = useState(cachedProfile);
  const [user, setUser] = useState<User>(cachedUser as User);
  const [token, setToken] = useState(cachedToken);
  const [_skipAuthCheck] = useState(options.skipAuthCheck);

  const fetchProfile = useCallback((uid: string) => {
    return new Promise((resolve, reject) => {
      db.collection("profiles")
        .where("userId", "==", uid)
        .onSnapshot((snap: firestore.DocumentData) => {
          if (snap.empty) {
            console.error("useProfile:", "Can't find profile with", uid);
            return reject();
          }

          const doc: firestore.DocumentData = snap.docs?.[0] || snap;
          const profile = {
            id: doc.id,
            ...doc.data(),
          };

          i18n.changeLanguage(profile.lang || "en");
          setProfile(profile);
          setCachedProfile(profile);

          return resolve(profile);
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(
    async (data: { email: string; password: string; keepLogged: boolean }) => {
      const { email, password, keepLogged } = data;
      // Difference between local or session
      // https://firebase.google.com/docs/auth/web/auth-state-persistence#supported_types_of_auth_state_persistence
      return auth
        .setPersistence(keepLogged ? "local" : "session")
        .then(() =>
          auth
            .signInWithEmailAndPassword(email, password)
            .then((authInfo) => {
              if (!authInfo.user) {
                throw Error("User doesn't exists");
              }

              const user = mapUserAttributes(authInfo.user);
              setIsLogged(true);
              setUser(user);
              setCachedUser(user);

              return fetchProfile(user.uid) as Promise<BaseProfile>;
            })
            .then((user: BaseProfile) => {
              const { role } = user;
              if (role === UserRole.DISABLED) {
                logout("/login");
                return null;
              }
              window.location.replace(role === UserRole.ADMIN || role === UserRole.DEALER ? "dashboard" : "companies");
              return null;
            })
        )
        .catch((error: firebase.FirebaseError) => error);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchProfile, setCachedUser, setIsLogged]
  );

  const logout = async (redirectPath = "/") => {
    setIsLogged(false);
    setCachedUser({});
    setCachedProfile({});
    setCachedToken({});

    return auth
      .signOut()
      .catch((error: firebase.FirebaseError) => console.error("useAuth:", error.code, error.message))
      .finally(() => window.location.replace(redirectPath));
  };

  useEffect(() => {
    if (isLogged) {
      window.document.body.classList.add("loggedIn");
    } else {
      window.document.body.classList.remove("loggedIn");
    }
  }, [isLogged]);

  useEffect(() => {
    if (!_skipAuthCheck) {
      // User is signed in or token was refreshed.
      auth.onIdTokenChanged((authUser) => {
        if (authUser === null) {
          setIsLogged(false);
          setCachedUser({});
          setCachedProfile({});
          setCachedToken({});
        } else {
          const userData: any = authUser.toJSON();

          const userToken = userData && userData.stsTokenManager && userData.stsTokenManager.accessToken;
          const user = mapUserAttributes(userData);
          setUser(user);
          setCachedUser(user);

          if (token !== userToken) {
            setCachedToken(userToken);
            setToken(userToken);
          }

          fetchProfile(user.uid);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_skipAuthCheck]);

  return {
    ...user,
    profile,
    token,
    login,
    logout,
    isLogged,
  };
};
