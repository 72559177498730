import React from "react";
import styled from "@emotion/styled";
import { formatNumberToString } from "../../../helpers/formatNumber";

interface LabeledValueProps {
  label: string;
  value: number;
  precistion?: number;
}

const StyledContainer = styled.div`
  display: flex;
`;

export const StyledLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-gray"]};
  margin-bottom: 20px;
  margin-right: 5px;
`;

export const StyledValue = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 110%;
  word-wrap: break-word;
  white-space: normal;
  color: ${(props) => props.theme.colors["sk-dark"]};
`;

export const LabeledValue: React.FunctionComponent<LabeledValueProps> = ({ label, value, precistion = 4 }) => {
  return (
    <StyledContainer>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue>{value ? formatNumberToString(value, precistion) : "-"}</StyledValue>
    </StyledContainer>
  );
};
