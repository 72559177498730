import { Payment } from "../interfaces/data";
import { UseCommissionFeeCompanies } from "../interfaces/fetchers";
import { useSearch } from "./useSearch";

export const useCommissionFeeCompanies = ({
  phrase,
  fields,
  filtersAndBetween,
  orderBy,
  orderDirection,
  offset,
  limit,
}: UseCommissionFeeCompanies) => {
  const {
    data: commissionFeeCompanies,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  } = useSearch<Payment>({
    collection: "commission-fees/companies",
    phrase,
    fields,
    filtersAndBetween,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate: true,
  });

  return {
    commissionFeeCompanies,
    totalCount,
    pageCount,
    loading,
    error,
    refetch,
  };
};
