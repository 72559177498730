import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";

// TODO: add rest of the headings when they appear in project

interface HeadingProps extends PropsWithChildren {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const StyledH1 = styled.h1`
  font-weight: 700;
  font-size: 42px;
  line-height: 110%;
`;

export const Heading: React.FunctionComponent<HeadingProps> = ({ type, children }) => {
  if (type === "h1") {
    return <StyledH1>{children}</StyledH1>;
  } else {
    return <>{children}</>;
  }
};
