import { UseApplications } from "interfaces/fetchers";
import { useSearch } from "./useSearch";
import { Application } from "interfaces/data";

export const useApplications = ({
  phrase,
  fields,
  orderBy,
  orderDirection,
  offset,
  limit,
  skipAutoUpdate,
  isLazy,
}: UseApplications) => {
  const {
    data: applications,
    totalCount,
    pageCount,
    loading,
    error,
    remove,
    refetch,
  } = useSearch<Application>({
    collection: "applications",
    phrase,
    fields,
    orderBy,
    orderDirection,
    offset,
    limit,
    skipAutoUpdate,
    isLazy,
  });

  return {
    applications,
    totalCount,
    pageCount,
    loading,
    error,
    remove,
    refetch,
  };
};
