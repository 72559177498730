import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ContactsIcon = createIcon({
  displayName: "ContactsIcon",
  path: (
    <g fill="none">
      <path
        d="M13.8 1H2.06667C1.47756 1 1 1.47756 1 2.06667V15.9333C1 16.5224 1.47756 17 2.06667 17H13.8C14.3891 17 14.8667 16.5224 14.8667 15.9333V2.06667C14.8667 1.47756 14.3891 1 13.8 1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.6666 4.2002H4.19995V8.46686H11.6666V4.2002Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M17 3.1333V5.26663"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M17 7.3999V9.53324"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
  viewBox: "0 0 19 18",
});
