import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../hooks/useToast";
import { useTransaction } from "../../../hooks/useTransaction";
import { Modal } from "../../../components/Modal/Modal";
import { Box } from "@chakra-ui/react";

export function MarginCancelLocal() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, transactionId, marginId } = useParams<{
    id: string;
    transactionId: string;
    marginId: string;
  }>();
  const toast = useToast();
  const { transaction, marginCancel } = useTransaction({ id: transactionId });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/companies/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelAction = useCallback(() => {
    setIsSending(true);
    marginCancel(marginId)
      .then((success) => {
        if (success) {
          toast({
            type: "success",
            message: t("margin:Margin has been canceled successfully."),
          });
        }
      })
      .catch((error) => {
        toast({
          type: "error",
          message: t(`margin:${error.message}`),
        });
      })
      .finally(() => {
        setIsSending(false);
        handleClose();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marginCancel, marginId, history, id]);

  const editedMargin = useMemo(() => {
    if (transaction) {
      return transaction.margins?.find((margin) => margin.id === marginId);
    }
  }, [marginId, transaction]);

  if (!transaction || !editedMargin) {
    return null;
  }

  return (
    <Modal
      confirmText={t("Yes")}
      design="danger"
      isDisabled={isSending}
      isOpen
      onClose={handleClose}
      onConfirm={handleCancelAction}
      title={t(`margin:Cancel margin {{marginId}}`, { marginId })}
    >
      <Box mb={"20px"}>
        {t(`margin:This action can't be undone. Are you sure you want to cancel margin {{marginId}}?`, { marginId })}
      </Box>
    </Modal>
  );
}
