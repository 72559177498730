import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const BoxIcon = createIcon({
  displayName: "BoxIcon",
  path: (
    <g fill="none">
      <rect fill="currentColor" height="12" rx="4" width="12" />
    </g>
  ),
  viewBox: "0 0 12 12",
});
