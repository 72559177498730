import { useTranslation } from "react-i18next";
import { useToast } from "./useToast";
import { unsecuredCopyToClipboard } from "../helpers/unsecuredCopyToClipboard";

export const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const toast = useToast();

  return async (textToCopy?: string) => {
    try {
      if (textToCopy) {
        if (window.isSecureContext && navigator.clipboard) {
          await navigator.clipboard.writeText(textToCopy);
        } else {
          unsecuredCopyToClipboard(textToCopy);
        }
        toast({
          message: t("helpers:Successfully copied text to clipboard."),
          type: "success",
        });
      } else {
        throw new Error(t("helpers:Copying failed."));
      }
    } catch (e) {
      console.warn(e);
      toast({
        message: t("helpers:Copying failed."),
        type: "error",
      });
    }
  };
};
