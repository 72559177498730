import React, { useCallback, useEffect } from "react";
import _cloneDeep from "lodash.clonedeep";
import { BaseCompany } from "../../../interfaces/data";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { CURRENCIES } from "../../../helpers/options";
import { AutoCompleteSelect } from "../../../components/AutoCompleteSelect/AutoCompleteSelect";
import { formatDate } from "../../../helpers/date";
import { useFirebase } from "../../../hooks/useFirebase";

export function EditReversePayment({ data, handleChange, errors }: EditViewProps<BaseCompany>) {
  const { t } = useTranslation();
  const { timestampFrom } = useFirebase();

  const handleDateUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const eventCopy = _cloneDeep(e);
      const date = new Date(eventCopy.target.value);
      // @ts-ignore
      eventCopy.target.value = timestampFrom(date);
      handleChange(eventCopy);
    },
    [handleChange, timestampFrom]
  );

  useEffect(() => {
    if (
      data.reversePayment &&
      Number(data.reversePayment.limit.quantity) > 0 &&
      (data.reversePayment.limit.currency == null || data.reversePayment.limit.currency === "")
    ) {
      handleChange({
        target: {
          name: "reversePayment.limit.currency",
          value: "EUR",
        },
        persist: () => {
          return;
        },
      });
    }
  }, [data.reversePayment, handleChange]);

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.["reversePayment.limit.quantity"]}
        formatNumberOnBlur
        id="limit"
        label={t("company:Limit")}
        name="reversePayment.limit.quantity"
        onChange={handleChange}
        type="number"
        value={data.reversePayment?.limit.quantity || 0}
        withError={!!errors?.["reversePayment.limit.quantity"]}
      />
      <AutoCompleteSelect
        error={errors?.["reversePayment.limit.currency"]}
        id="currency"
        label={t("company:Currency")}
        name="reversePayment.limit.currency"
        onChange={handleChange}
        options={CURRENCIES}
        value={data.reversePayment?.limit.currency}
        withError={!!errors?.["reversePayment.limit.currency"]}
      />
      <Input
        error={errors?.["reversePayment.date"]}
        id="date"
        label={t("settlement:Date")}
        name="reversePayment.date"
        onChange={handleDateUpdate}
        type="date"
        value={formatDate(data.reversePayment?.date)}
        withError={!!errors?.["reversePayment.date"]}
      />
    </TwoColumnsLayout>
  );
}
