import * as yup from "yup";

export const limitSchema = yup.object().shape({
  limit: yup.number().required().positive(),
});

export const questionSchema = yup.object().shape({
  title: yup.string().required().trim(),
  weight: yup.number().required().positive(),
});
