import { z } from "zod";
import { CurrencyValueSchema, TimestampSchema } from "./common";

export const MarginOperationSchema = z.object({
  id: z.number(),
  type: z.enum(["WITHDRAW", "ADD"]),
  value: CurrencyValueSchema,
  oldMarginValue: CurrencyValueSchema,
  newMarginValue: CurrencyValueSchema,
  date: TimestampSchema,
  comment: z.string().optional(),
});

export const MarginSchema = z.object({
  id: z.string(),
  createdAt: TimestampSchema.optional(),
  modifiedAt: TimestampSchema.optional(),
  date: TimestampSchema,
  type: z.enum(["IM", "VM"]),
  from: CurrencyValueSchema,
  to: CurrencyValueSchema,
  left: CurrencyValueSchema,
  marginCallId: z.string().optional(),
  operations: z.array(MarginOperationSchema).optional(),
  comment: z.string().optional(),
  createFromMovement: z.boolean().optional(),
});
