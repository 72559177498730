import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Margin } from "../../interfaces/data";
import { useTransaction } from "../../hooks/useTransaction";
import { useToast } from "../../hooks/useToast";
import { MarginFormModal } from "./MarginFormModal/MarginFormModal";

export function MarginEdit() {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, marginId } = useParams<{ id: string; marginId: string }>();
  const toast = useToast();
  const { transaction, marginUpdate, marginErrors } = useTransaction({ id });
  const [isSending, setIsSending] = useState(false);

  const handleClose = useCallback(() => {
    history.push(`/transactions/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOnSaveAction = useCallback(
    (newMargin: Margin) => {
      setIsSending(true);
      marginUpdate(newMargin)
        .then((isSaved) => {
          setIsSending(false);
          if (isSaved) {
            toast({
              type: "success",
              message: t("margin:Margin has been updated successfully."),
            });
            handleClose();
          }
        })
        .catch((error) => {
          setIsSending(false);
          toast({
            type: "error",
            message: t(`margin:${error.message}`),
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [marginUpdate, history, id]
  );

  const editedMargin = useMemo(() => {
    if (transaction) {
      return transaction.margins?.find((margin) => margin.id === marginId);
    }
  }, [marginId, transaction]);

  if (!transaction || !editedMargin) return null;

  return (
    <MarginFormModal
      errors={marginErrors}
      initialMargin={editedMargin}
      isDisabled={isSending}
      onClose={handleClose}
      onSave={handleOnSaveAction}
      title={t("margin:Edit margin {{marginId}}", { marginId: marginId })}
      type="edit"
    />
  );
}
