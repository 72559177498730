import styled from "@emotion/styled";

export const TwoColumnsLayout = styled.div<{
  withPaddingBottom?: boolean;
  leftEmpty?: boolean;
  skipColumnGap?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  padding-bottom: ${(props) => props.withPaddingBottom && "10px"};
  ${(props) =>
    !props.skipColumnGap &&
    `
    grid-column-gap: 40px;
  `}
  ${(props) =>
    props.leftEmpty &&
    `
    &:before {
      content: '';
    }
  `}
`;
