import React from "react";
import { formatNumberToString } from "helpers/formatNumber";
import { MarginInfo } from "../GlobalMarginsMigration";
import { getRemainingValue } from "helpers/margin";

export function RemainingCell(arg: any) {
  const margin = arg.row.original as MarginInfo;
  const { from } = margin;

  return (
    <>
      {formatNumberToString(getRemainingValue(margin))} {from.currency}
    </>
  );
}
