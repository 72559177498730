import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const TickIcon = createIcon({
  displayName: "TickIcon",
  path: (
    <path
      d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM5.25 8.55L2.7 6L3.75 4.95L5.25 6.45L8.25 3.45L9.3 4.5L5.25 8.55Z"
      fill="currentColor"
    />
  ),
  viewBox: "0 0 12 12",
});
