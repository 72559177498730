import { useCallback, useEffect, useState } from "react";
import { firestore } from "firebase";
import { useFirebase } from "./useFirebase";
import { CommissionFee } from "../interfaces/data";

const COLLECTION = "commissionFees";

export const useCommissionFee = (
  {
    skipFetching,
    id,
  }: {
    skipFetching?: boolean;
    id?: string;
  } = { skipFetching: false }
) => {
  const [fee, setFee] = useState<CommissionFee>();
  const [loading, setLoading] = useState(true);
  const { db, timestamp, timestampFrom } = useFirebase();

  const fetch = useCallback(
    (id: string) => {
      const collection = db.collection(COLLECTION);
      collection
        .doc(id)
        .get()
        .then((snap: firestore.DocumentData) => {
          const fee = snap.data();
          setLoading(false);
          if (fee) {
            setFee({ ...fee, id });
          } else {
            setFee(undefined);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const find = useCallback(
    async (path: string, value: string) => {
      const collection = db.collection(COLLECTION);

      return collection
        .where(path, "==", value)
        .get()
        .then((snap: firestore.DocumentData) => {
          if (!snap.empty) {
            return snap.docs[0].data();
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const update = useCallback(
    async ({ id, fee, expiration }: { id: string; fee?: number; expiration?: Date }) => {
      setLoading(true);

      const collection = db.collection(COLLECTION);

      const newFee = {
        modifiedAt: timestamp(),
      } as any;

      if (fee) newFee.fee = fee;
      if (expiration) newFee.expiration = timestampFrom(expiration);

      return collection
        .doc(id)
        .update(newFee)
        .then(() => {
          return true;
        })
        .catch((e) => {
          console.error("useCommissionFee", e);
          return false;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clear = async ({ id, field }: { id: string; field: "fee" | "expiration" }) => {
    setLoading(true);
    const collection = db.collection(COLLECTION);
    const newFee = {
      modifiedAt: timestamp(),
    } as any;

    if (field === "fee") {
      newFee.fee = null;
    } else {
      newFee.expiration = null;
    }

    return collection
      .doc(id)
      .update(newFee)
      .then(() => {
        return true;
      })
      .catch((e) => {
        console.error("useCommissionFee", e);
        return false;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const save = useCallback(
    async ({
      contactId,
      companyId,
      fee,
      expiration,
    }: {
      contactId: string;
      companyId: string;
      fee?: number;
      expiration?: Date;
    }) => {
      setLoading(true);
      const collection = db.collection(COLLECTION);

      const newFee = {
        company: {
          _ref: db.collection("companies").doc(companyId),
        },
        contact: {
          _ref: db.collection("contacts").doc(contactId),
        },
        createdAt: timestamp(),
        modifiedAt: timestamp(),
      } as any;

      if (fee) newFee.fee = fee;
      if (expiration) newFee.expiration = timestampFrom(expiration);

      return collection
        .add(newFee)
        .then((doc) => {
          setLoading(false);
          return doc.id;
        })
        .catch((e) => {
          setLoading(false);
          console.error("useCommissionFee", e);
          return false;
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!skipFetching && id) {
      fetch(id);
    }
  }, [fetch, id, skipFetching]);

  return {
    fee,
    update,
    save,
    fetch,
    loading,
    find,
    clear,
  };
};
