import styled from "@emotion/styled";

export const StyledContainer = styled.div<{ disabled?: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  min-height: 77px;
  padding-top: 23px;
  padding-bottom: 23px;
  &:last-of-type {
    border-bottom: none;
    margin-bottom: -30px !important;
  }
`;

export const StyledCollapseHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const StyledCollapseContent = styled.div`
  padding-top: 17px;
  position: relative;
`;

export const StyledEditButton = styled.button`
  color: ${(props) => props.theme.colors["sk-gray"]};
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  position: absolute;
  top: 12px;
  right: 0;
  svg {
    margin-right: 10px;
    margin-top: -5px;
  }
`;
