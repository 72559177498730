import { GlobalMarginCall } from "interfaces/data";
import { UseMarginCalls } from "interfaces/fetchers";
import { useSearch } from "./useSearch";
import { z } from "zod";
import { GlobalMarginCallSchema } from "schemas/marginCall";
import { CompanySchema } from "schemas/company";

export const useGlobalMarginCalls = ({
  phrase,
  fields,
  orderBy,
  orderDirection,
  filtersAnd,
  offset,
  limit,
}: UseMarginCalls) => {
  const { data, totalCount, pageCount, loading, error, refetch } = useSearch<GlobalMarginCall>({
    collection: "global-margin-calls",
    phrase,
    fields,
    orderBy,
    orderDirection,
    filtersAnd,
    offset,
    limit,
    skipAutoUpdate: true,
  });

  const schema = z.array(
    GlobalMarginCallSchema.extend({
      company: CompanySchema.omit({ globalMarginCalls: true, contacts: true, bankAccounts: true }),
    })
  );

  const marginCalls = schema.parse(data);

  return { marginCalls, totalCount, pageCount, loading, error, refetch };
};
