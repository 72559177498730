import { z } from "zod";
import { BankAccountSchema, CurrencyValueSchema, TimestampSchema, YesNoSchema } from "./common";
import { SettlementSchema } from "./settlement";
import { MarginCallSchema } from "./marginCall";
import { HedgeSchema } from "./hedge";
import { MarginSchema } from "./margin";

const TransactionCompanySchema = z.object({
  name: z.string(),
  id: z.string(),
  isDeactivated: YesNoSchema.optional(),
});

const TransactionStatusSchema = z.enum(["closed", "rolled", "created", "partiallySettled", "settled"]);

const DashboardCommentsSchema = z.record(
  z.enum(["unhedged", "soonToEnd", "marginCall", "rollHedge", "initialMargin"]),
  z.string()
);

export const TransactionSchema = z.object({
  id: z.string(),
  company: TransactionCompanySchema,
  type: z.enum(["FR", "Order", "Spot"]),
  number: z.number(),
  clientRate: z.number(),
  ourRate: z.number(),
  dealType: z.enum(["Sell", "Buy"]),
  from: CurrencyValueSchema,
  to: CurrencyValueSchema,
  leftQuantity: z.number().optional(),
  createdAt: TimestampSchema,
  modifiedAt: TimestampSchema.optional(),
  end: TimestampSchema.optional(),
  systemDate: TimestampSchema.optional(),
  currencyDate: TimestampSchema.optional(),
  start: TimestampSchema.optional(),
  expiration: TimestampSchema.optional(),
  initialMargin: z.number().optional(),
  status: TransactionStatusSchema,
  agreement: TimestampSchema.optional(),
  account: BankAccountSchema.optional(),
  comment: z.string().optional(),
  dashboardComments: DashboardCommentsSchema.optional(),
  settlements: z.array(SettlementSchema).optional(),
  marginCalls: z.array(MarginCallSchema).optional(),
  hedges: z.array(HedgeSchema).optional(),
  margins: z.array(MarginSchema).optional(),
  ignoreMarginCallRisk: z.boolean().optional(),
  createdFrom: z.object({ number: z.number(), id: z.string() }).optional(),
  convertedFrom: z.object({ number: z.number(), id: z.string() }).optional(),
  convertedTo: z.object({ number: z.number(), id: z.string() }).optional(),
  prolongedBy: z.object({ number: z.number(), id: z.string() }).optional(),
});
