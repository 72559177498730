import firebase from "firebase";
import { Contact, ContactSchema } from "schemas/contact";
import { fixContactTimestamps } from "helpers/contact";
import { isAddressEmpty } from "./fixCompany";

const COLLECTION = "contacts";

export const fixContact = (contact: any): Contact => {
  const result = ContactSchema.safeParse(contact);

  if (result.success) {
    // eslint-disable-next-line no-console
    console.log("all good, no need for fix");
    ContactSchema.parse(contact);
    return contact;
  }

  const fixedContact = {
    ...contact,
    firstName: `${contact.firstName}`,
    lastName: `${contact.lastName}`,
    phone: `${contact.phone}`,
    idDetails: {
      pesel: `${contact.idDetails.pesel}`,
      idType: `${contact.idDetails.idType}`,
      number: `${contact.idDetails.number}`,
      expirationDate: `${contact.idDetails.expirationDate}`,
    },
  };

  if (fixedContact.address && isAddressEmpty(fixedContact.address)) {
    // @ts-ignore
    fixedContact.address = firebase.firestore.FieldValue.delete();
  }

  return fixedContact;
};

export const saveContact = async (contact: any, db: firebase.firestore.Firestore, noValidate = false) => {
  try {
    if (!noValidate) {
      ContactSchema.parse(contact);
    }
    await db.collection(COLLECTION).doc(contact.id).update(fixContactTimestamps(contact));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored contact", contact);
    console.error(error);
  }
};

export const deleteContact = async (contact: any, db: firebase.firestore.Firestore) => {
  try {
    // eslint-disable-next-line no-console
    console.log(`deleting contact ${contact.id}...`);
    await db.collection(COLLECTION).doc(contact.id).delete();
    // eslint-disable-next-line no-console
    console.log("deleted contact", contact);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log("errored contact", contact);
    console.error(error);
  }
};

export const batchDelete = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  ids.forEach((id) => {
    const ref = db.collection(COLLECTION).doc(id);
    batch.delete(ref);
  });
  await batch.commit();
};

export const batchFix = async (ids: string[], db: firebase.firestore.Firestore) => {
  const batch = db.batch();
  for (const id of ids) {
    const ref = db.collection(COLLECTION).doc(id);
    const contact = await getContact(id, db);
    batch.update(ref, fixContactTimestamps(contact));
  }
  await batch.commit();
};

export const getContact = async (id: string, db: firebase.firestore.Firestore) => {
  const doc = await db.collection(COLLECTION).doc(id).get();
  return { ...doc.data(), id } as Contact;
};
