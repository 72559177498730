import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "./useAuth";
import { useToast } from "./useToast";
import { TransactionType } from "../interfaces/data";
import { TRANSACTION_TYPES } from "../helpers/transaction";
import { FIXED_RATE_MAIL, ORDER_MAIL, SPOT_MAIL } from "../helpers/mails";

export const useMail = () => {
  const { token, logout } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  return useCallback(
    (type: string, id: string, queries?: any) => {
      const query = Object.keys(queries || {})
        .map((key) => `${key}=${queries[key]}`)
        .join("&");

      return fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/mails/send/${type}/${id}?${query}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: Response) => {
          // Logout user if unauthorized
          if (response.status === 401) {
            return logout();
          }
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t("validation:Email could not be sent"),
          });
          console.error("useMail", error);
        });
    },
    [token, t, toast, logout]
  );
};

export const mapTransactionTypeToEmailType = (type: TransactionType) => {
  switch (type) {
    case TRANSACTION_TYPES.FIXED.value:
      return FIXED_RATE_MAIL;
    case TRANSACTION_TYPES.ORDER.value:
      return ORDER_MAIL;
    case TRANSACTION_TYPES.SPOT.value:
      return SPOT_MAIL;
    default:
      return FIXED_RATE_MAIL;
  }
};
