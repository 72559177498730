import React from "react";
import { Box } from "@chakra-ui/react";
import { MarginCallExtended } from "../MarginCalls";
import { Link } from "react-router-dom";

export function TransactionIdsCell(arg: any) {
  const margin = arg.row.original as MarginCallExtended;
  if (margin.isGMC !== "yes" || margin?.globalTransactionsIds?.length === 0) {
    return "-";
  }
  return (
    <Box>
      {margin.globalTransactionsIds?.map((gmc) => (
        <Link key={gmc.id} to={`/transactions/${gmc.id}`}>
          <Box as="span" display="block" whiteSpace="nowrap">
            #{gmc.number}
          </Box>
        </Link>
      ))}
    </Box>
  );
}
