import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const MoreFiltersIcon = createIcon({
  displayName: "MoreFiltersIcon",
  path: (
    <g fill="none">
      <path d="M3 11.5V9.5" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 3.5V0.5" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3 9.5C4.10457 9.5 5 8.60457 5 7.5C5 6.39543 4.10457 5.5 3 5.5C1.89543 5.5 1 6.39543 1 7.5C1 8.60457 1.89543 9.5 3 9.5Z"
        stroke="#252527"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 0.5V2.5" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 8.5V11.5" stroke="#252527" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10 6.5C11.1046 6.5 12 5.60457 12 4.5C12 3.39543 11.1046 2.5 10 2.5C8.89543 2.5 8 3.39543 8 4.5C8 5.60457 8.89543 6.5 10 6.5Z"
        stroke="#252527"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: "0 0 13 12",
});
