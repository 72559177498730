import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "components/Table/Table";
import { Box, Text } from "@chakra-ui/react";
import { StyledContainer, StyledSectionHeader, StyledSectionName } from "../shared.styled";
import { DateCell } from "helpers/tableCells";
import { formatNumberToString, formatRate } from "helpers/formatNumber";
import { useHistory, useParams } from "react-router-dom";
import { Company, Margin, Transaction } from "interfaces/data";
import theme from "theme/theme";
import { StyledTableControls } from "components/ItemsListElements/ItemsListElements";
import { RestrictAccess } from "components/RestrictAccess/RestrictAccess";
import { ADD_MARGIN_CALLS } from "helpers/userRoles";
import {
  calculateTotalSum,
  calculateTotalSumByCurrency,
  convertGlobalMarginsToTableRows,
  convertLocalMarginsToTableRows,
} from "helpers/360view";
import { MarginCallExtended } from "./MarginCalls";
import { getMarginCallBalance } from "helpers/marginCall";
import { determineTransactionCurrency } from "helpers/transaction";
import {
  StyledAddbutton,
  StyledLink,
  StyledLinkContainer,
  StyledTableContainer,
  StyledTableLabel,
  StyledTotalCurrenciesRow,
  StyledTotalRow,
  StyledVMTableContainer,
} from "./VariationMargins.styled";
import { EditableComment } from "views/Payments/EditableComment";
import { useToast } from "hooks/useToast";
import { StyledButtonContainer } from "./Transactions/Transactions";
import { Button } from "components/Button/Button";
import { getWithdrawValue } from "helpers/margin";
import { transactionCommitment } from "helpers/transactionCommitment";
import { StyledTag } from "views/MarginCalls/components/styles";
import { AddIcon, ChevronDownIcon, ChevronUpIcon, MinusIcon, RepeatIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { RemoveIcon } from "theme/icons/RemoveIcon";
import { EditIcon } from "theme/icons/EditIcon";

export interface VariationMarginInfo {
  id: string;
  transactionId?: string;
  date?: Timestamp;
  quantity: number;
  withdrawn?: number;
  assignedToMc?: number;
  remainingQty: number;
  currency: string;
  marginCallId?: string;
  comment?: string;
}

interface VariationMarginProps {
  transactions?: Array<Transaction>;
  company?: Company;
  nbpRates: any;
  marginCalls: Array<MarginCallExtended>;
  globalMarginCommentUpdate: (marginId: string, newComment: string) => Promise<boolean>;
}

function QuantityCell(arg: any) {
  const row = arg.row.original as VariationMarginInfo;
  return (
    <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
      {formatNumberToString(row.quantity)}
    </Text>
  );
}

function QuantityRemainingRatioCell(arg: any) {
  const row = arg.row.original as VariationMarginInfo;
  return (
    <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
      {formatNumberToString(row.quantity)}
    </Text>
  );
}

export const VariationMargins: React.FC<VariationMarginProps> = ({
  transactions,
  company,
  nbpRates,
  marginCalls,
  globalMarginCommentUpdate,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const [showWithdrawn, setShowWithdrawn] = useState(false);

  const [selectedRow, setSelectedRow] = useState<VariationMarginInfo>();

  const IdCell = (arg: any) => {
    const transaction = arg.row.original;
    const transactionId = transaction.transactionId;
    if (transactionId === "-") return <span>{arg.value}</span>;
    return (
      <StyledLink
        to={{
          pathname: `/transactions/${transactionId}`,
          state: { fromPath: window.location.pathname },
        }}
      >
        {transaction.marginCallId || transaction.id}
      </StyledLink>
    );
  };

  const handleCommentUpdate = useCallback(
    async ({ marginId, newComment }: { marginId: string; newComment: string }) => {
      await globalMarginCommentUpdate(marginId, newComment)
        .then(() => {
          toast({
            type: "success",
            message: t("Global margin comment has been updated."),
          });
        })
        .catch((error) => {
          toast({
            type: "error",
            message: t(error.message),
          });
        });
    },
    [globalMarginCommentUpdate, t, toast]
  );

  const CommentCell = useCallback(
    (arg: any) => {
      const margin = arg.row.original as Margin;
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex={0}
        >
          <EditableComment
            comment={arg.value}
            minWidth="90px"
            onSave={(newComment) =>
              handleCommentUpdate({
                marginId: margin.id,
                newComment,
              })
            }
          />
        </div>
      );
    },
    [handleCommentUpdate]
  );

  const handleCancel = useCallback(() => {
    if (!selectedRow) return;

    const transactionId = selectedRow.transactionId;

    if (transactionId === "-") {
      history.push(`/companies/${id}/global-margins/${selectedRow.id}/cancel`);
    } else {
      history.push(`/companies/${id}/transactions/${transactionId}/margins/${selectedRow.id}/cancel`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleMerge = useCallback(() => {
    if (!selectedRow) return;

    const transactionId = selectedRow.transactionId;

    if (transactionId === "-") {
      history.push(`/companies/${id}/margins/${selectedRow.id}/merge`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleEdit = useCallback(() => {
    if (!selectedRow) return;

    const transactionId = selectedRow.transactionId;

    if (transactionId === "-") {
      history.push(`/companies/${id}/global-margins/${selectedRow.id}/edit`);
    } else {
      history.push(`/companies/${id}/transactions/${transactionId}/margins/${selectedRow.id}/edit`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleMove = useCallback(() => {
    if (!selectedRow) return;

    const transactionId = selectedRow.transactionId;

    if (transactionId === "-") {
      history.push(`/companies/${id}/margins/${selectedRow.id}/move`);
    } else {
      history.push(`/companies/${id}/transactions/${transactionId}/margins/${selectedRow.id}/move`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleWithdraw = useCallback(() => {
    if (!selectedRow) return;

    const transactionId = selectedRow.transactionId;

    if (transactionId === "-") {
      history.push(`/companies/${id}/global-margins/${selectedRow.id}/withdraw`);
    } else {
      history.push(`/companies/${id}/transactions/${transactionId}/margins/${selectedRow.id}/withdraw`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow, id]);

  const handleGlobalMarginAdd = useCallback(() => {
    history.push(`/companies/${id}/global-margins/add`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "id",
        Cell: IdCell,
      },
      {
        Header: t("company:Date "),
        accessor: "date",
        Cell: DateCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const globalColumns = useMemo(
    () => [
      ...columns,
      {
        Header: () => (
          <Text as="span" display="block" textAlign="center" whiteSpace="nowrap" width="100%">
            {t("company:Quantity")}
          </Text>
        ),
        accessor: "quantity",
        Cell: QuantityCell,
      },
      {
        Header: () => (
          <Text as="div" textAlign="right" whiteSpace="nowrap" width="100%">
            {t("company:Remaining")}
          </Text>
        ),
        accessor: "remainingQty",
        Cell: (arg: any) => (
          <Text as="div" fontWeight="900" textAlign="right" width="100%">
            {formatNumberToString(arg.value || arg.row.original.quantity - (arg.row.original.withdrawn ?? 0))}
          </Text>
        ),
      },
      {
        Header: t("company:Currency"),
        accessor: "currency",
      },
      {
        Header: t("Comments"),
        accessor: "comment",
        Cell: CommentCell,
      },
    ],
    [CommentCell, columns, t]
  );

  const vmColumns = useMemo(
    () => [
      ...columns,
      {
        Header: () => (
          <Text as="div" textAlign="center" whiteSpace="nowrap" width="100%">
            {t("company:Quantity")}
          </Text>
        ),
        accessor: "quantityRemainingRatio",
        Cell: QuantityRemainingRatioCell,
      },
      {
        Header: () => (
          <Text as="div" textAlign="right" whiteSpace="nowrap" width="100%">
            {t("Remaining")}
          </Text>
        ),
        accessor: "remainingQty",
        Cell: (arg: any) => {
          const value = arg.value;
          const quantity = arg.row.original.quantity || 0;
          const withdrawn = arg.row.original.withdrawn || 0;
          return (
            <Text as="div" fontWeight="900" textAlign="right" width="100%">
              {formatNumberToString(value || quantity - withdrawn)}
            </Text>
          );
        },
      },
      {
        Header: t("company:Currency"),
        accessor: "currency",
      },
      {
        Header: t("Assigned to MC"),
        accessor: "assignedToMc",
        Cell: (arg) => (
          <>
            {formatNumberToString(arg.value)} {arg.row.original.currency}
          </>
        ),
      },
      {
        Header: "",
        id: "show-mc",
        Cell: ({ row }: any) => (
          <>
            {row.original.marginCallId && (
              <StyledLinkContainer onClick={(e) => e.stopPropagation()}>
                <Text as="span" display="block" textAlign="right" {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ChevronUpIcon boxSize="24px" /> : <ChevronDownIcon boxSize="24px" />}
                </Text>
              </StyledLinkContainer>
            )}
          </>
        ),
      },
    ],
    [columns, t]
  );

  const initialMargins = useMemo(() => {
    if (!transactions || !nbpRates) return { requested: 0, paid: 0 };

    return transactions.reduce(
      (acc, transaction) => {
        const { requested, paid } = acc;
        const { status, initialMargin = 0, leftQuantity = 0 } = transaction;
        const transactionCurrency = transaction.from.currency;
        const rate = transactionCurrency === "PLN" ? 1 : nbpRates.rates[transactionCurrency];

        if (status === "rolled" || status === "settled" || status === "closed") return acc;

        const transactionInitialMargin = (Number(leftQuantity).valueOf() * Number(initialMargin).valueOf()) / 100;

        const paidTransactionMargins =
          transaction.margins
            ?.filter((margin) => margin.type === "IM")
            .reduce((sum, margin) => sum + Number(margin.to.quantity).valueOf(), 0) || 0;

        return {
          requested: requested + transactionInitialMargin * rate,
          paid: paid + paidTransactionMargins * rate,
        };
      },
      { requested: 0, paid: 0 }
    );
  }, [nbpRates, transactions]);

  const globalVariationMarginsRows = useMemo(() => {
    if (!company || !company.globalMargins) return [];
    return convertGlobalMarginsToTableRows(
      company.globalMargins.filter((margin) =>
        showWithdrawn
          ? getWithdrawValue(margin) === Number(margin.from.quantity)
          : getWithdrawValue(margin) !== Number(margin.from.quantity)
      ),
      nbpRates
    );
  }, [company, nbpRates, showWithdrawn]);

  const variationMarginsRows = useMemo(() => {
    if (!transactions || !nbpRates) return [];
    return convertLocalMarginsToTableRows(transactions, nbpRates, "VM", showWithdrawn);
  }, [nbpRates, transactions, showWithdrawn]);

  const paperLoss =
    transactions?.reduce((clientLoss, transaction) => {
      if (transaction.status === "settled" || transaction.status === "rolled") {
        return clientLoss;
      }
      const commitment = transactionCommitment(transaction, nbpRates);

      return clientLoss + commitment.clientLoss;
    }, 0) || 0;

  const totalSumRow = calculateTotalSum(
    globalVariationMarginsRows.length > 0
      ? globalVariationMarginsRows[globalVariationMarginsRows.length - 1].remainingQty
      : 0,
    variationMarginsRows.length > 0 ? variationMarginsRows[variationMarginsRows.length - 1].remainingQty : 0,
    initialMargins.paid - initialMargins.requested,
    paperLoss
  );

  const totalSumsByCurrency = calculateTotalSumByCurrency(globalVariationMarginsRows, variationMarginsRows);

  const renderMCdetails = React.useCallback(
    ({ row }: { row: any }) => {
      const marginCall = marginCalls.find((mc) => mc.id === row.original.marginCallId) as MarginCallExtended;

      if (!marginCall) return null;

      let status;
      const currency = determineTransactionCurrency(marginCall.transaction);
      const paid = getMarginCallBalance(marginCall, nbpRates, currency);

      if (marginCall.isClosed === "yes") {
        status = <b>closed</b>;
      } else {
        if (paid === 0) {
          status = <StyledTag color="sk-red">unpaid</StyledTag>;
        } else if (paid >= marginCall.quantity) {
          status = <StyledTag color="sk-light-green">ok</StyledTag>;
        } else {
          status = <StyledTag color="sk-yellow">partially</StyledTag>;
        }
      }

      return (
        <table className="mc-table">
          <thead>
            <tr>
              <td>ID</td>
              <td>{t("marginCall:Client rate")}</td>
              <td>{t("marginCall:MC rate")}</td>
              <td>{t("marginCall:Trans Id")}</td>
              <td>{t("marginCall:Type")}</td>
              <td>{t("marginCall:Paid / Remaining Qty.")}</td>
              <td>{t("marginCall:Status")}</td>
            </tr>
          </thead>
          <tbody>
            <tr className={marginCall.isGMC === "yes" ? "global-row" : ""}>
              <td>
                <StyledLink
                  to={{
                    pathname: `/transactions/${marginCall.transaction.id}`,
                    state: { fromPath: window.location.pathname },
                  }}
                >
                  <Text whiteSpace="nowrap">{marginCall.id}</Text>
                </StyledLink>
              </td>
              <td>{formatRate(marginCall.transaction.clientRate)}</td>
              <td>{marginCall.callRate}</td>
              <td className="transactions-row">
                {marginCall.isGMC === "yes"
                  ? marginCall?.globalTransactionsIds?.map((transaction) => `#${transaction.number}; `)
                  : `#${marginCall.transaction.number}`}
              </td>
              <td>{marginCall.transaction.dealType}</td>
              <td>
                {formatNumberToString(paid)} /{" "}
                {marginCall.quantity - paid < 0 ? 0 : formatNumberToString(marginCall.quantity - paid)} {currency}
              </td>
              <td>{status}</td>
            </tr>
          </tbody>
        </table>
      );
    },
    [marginCalls, nbpRates, t]
  );

  if (!transactions) return null;

  return (
    <StyledContainer id="vmargins">
      <StyledSectionHeader>
        <StyledSectionName>{t("company:Variation margins")}</StyledSectionName>
        <StyledButtonContainer>
          <Button
            borderRadius="12px"
            design={!showWithdrawn ? "primary" : "ghost"}
            height="32px"
            mr="4px"
            onClick={() => setShowWithdrawn(false)}
            opacity={!showWithdrawn ? 1 : 0.5}
          >
            Open
          </Button>
          <Button
            borderRadius="12px"
            design={showWithdrawn ? "primary" : "ghost"}
            height="32px"
            onClick={() => setShowWithdrawn(true)}
            opacity={showWithdrawn ? 1 : 0.5}
          >
            Withdrawn
          </Button>
        </StyledButtonContainer>
        <RestrictAccess area={ADD_MARGIN_CALLS}>
          <StyledTableControls>
            <StyledAddbutton
              disabled={Boolean(company?.isDeactivated) || !selectedRow?.id.startsWith("g") || !selectedRow}
              onClick={handleMerge}
            >
              <TriangleDownIcon mr="10px" />
              Merge
            </StyledAddbutton>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated) || !selectedRow} onClick={handleEdit}>
              <EditIcon mr="10px" />
              Edit
            </StyledAddbutton>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated) || !selectedRow} onClick={handleMove}>
              <RepeatIcon mr="10px" />
              Move
            </StyledAddbutton>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated) || !selectedRow} onClick={handleWithdraw}>
              <MinusIcon mr="10px" />
              Withdraw
            </StyledAddbutton>
            <StyledAddbutton disabled={Boolean(company?.isDeactivated) || !selectedRow} onClick={handleCancel}>
              <RemoveIcon mr="10px" />
              Cancel
            </StyledAddbutton>

            <StyledAddbutton disabled={Boolean(company?.isDeactivated)} onClick={handleGlobalMarginAdd}>
              <AddIcon mr="10px" />
              Add
            </StyledAddbutton>
          </StyledTableControls>
        </RestrictAccess>
      </StyledSectionHeader>
      {globalVariationMarginsRows.length > 0 && (
        <div id="global-margins">
          <StyledTableLabel>
            <Box backgroundColor={theme.colors["sk-purple"]} display="inline-block" h="10px" mr="8px" w="10px" />
            {t("company:Global Variation Margins")}
          </StyledTableLabel>
          <StyledTableContainer>
            <Table
              columns={globalColumns}
              data={globalVariationMarginsRows}
              getRowProps={(rowInfo) => {
                const row = rowInfo.original;
                if (["Currency subtotal", "Total in PLN"].includes(row.id)) {
                  return { className: "total-row" };
                }
              }}
              onRowSelected={setSelectedRow}
              paginated={false}
              selectableRows
            />
          </StyledTableContainer>
        </div>
      )}
      {variationMarginsRows.length > 1 && (
        <>
          <StyledTableLabel>
            <Box backgroundColor={theme.colors["sk-purple"]} display="inline-block" h="10px" mr="8px" w="10px" />
            {t("company:Local Variation Margins")}
          </StyledTableLabel>
          <StyledVMTableContainer>
            <Table
              columns={vmColumns}
              data={variationMarginsRows}
              getRowProps={(rowInfo) => {
                const row = rowInfo.original;
                if (["Currency subtotal", "Total in PLN", "VM & GM / Sum of MCs"].includes(row.id)) {
                  return { className: "total-row" };
                } else if (row.id === "Total balance of local and global margins") {
                  return { className: "total-row total-balance-row" };
                }
              }}
              onRowSelected={setSelectedRow}
              paginated={false}
              renderRowSubComponent={renderMCdetails}
              selectableRows
            />
          </StyledVMTableContainer>
        </>
      )}

      {totalSumRow.id ? (
        <StyledTotalRow>
          <span>{totalSumRow.id}</span>
          <span>
            {totalSumRow.quantity} / {totalSumRow.paperLoss}
          </span>
        </StyledTotalRow>
      ) : null}

      {totalSumsByCurrency ? (
        <StyledTotalCurrenciesRow>
          <span>VMs:</span>
          <span>
            {Object.keys(totalSumsByCurrency).map(
              (currency) => `${formatNumberToString(totalSumsByCurrency[currency])} ${currency}; `
            )}
          </span>
        </StyledTotalCurrenciesRow>
      ) : null}

      {totalSumRow.id ? (
        <StyledTotalCurrenciesRow>
          <span>Return:</span>
          <span>{totalSumRow.return}</span>
        </StyledTotalCurrenciesRow>
      ) : null}
    </StyledContainer>
  );
};
