import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const CancelIcon = createIcon({
  displayName: "CancelIcon",
  path: (
    <g fill="none">
      <path
        d="M6.98145 11.5C10.019 11.5 12.4814 9.03757 12.4814 6C12.4814 2.96243 10.019 0.5 6.98145 0.5C3.94388 0.5 1.48145 2.96243 1.48145 6C1.48145 9.03757 3.94388 11.5 6.98145 11.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.98145 4L8.98145 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.98145 4L4.98145 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: "0 0 13 12",
});
