import React, { ReactNode } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs as ChakraTabs } from "@chakra-ui/react";
import styled from "@emotion/styled";

interface TabsProps {
  tabs: Array<ReactNode>;
  tabIndex?: number;
  handleTabsChange?: (newIndex: number) => any;
  children: Array<ReactNode>;
}

export const StyledTab = styled(Tab)`
  padding: 20px 30px;
  font-weight: bold;
  font-size: 14px;
  line-height: 110%;
  color: #818181;
  border: none;
  transition: none;

  &[aria-selected="true"] {
    color: #252527;
    padding-bottom: 17px;
    border-bottom: 3px solid #5b4dbe;
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  padding-top: 30px;
`;

export const Tabs: React.FC<TabsProps> = ({ tabs, tabIndex, handleTabsChange, children }) => {
  return (
    <ChakraTabs index={tabIndex} onChange={handleTabsChange} variant="enclosed">
      <TabList>
        {tabs.map((tab, index) => (
          <StyledTab key={index}>{tab}</StyledTab>
        ))}
      </TabList>
      <TabPanels>
        {children.map((tabContent, index) => (
          <StyledTabPanel key={index}>{tabContent}</StyledTabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};
