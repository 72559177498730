import { useCallback, useState } from "react";
import { useAuth } from "./useAuth";

export const useEmailPassword = () => {
  const { token, logout } = useAuth();

  const [isSending, setIsSending] = useState(false);

  const updateEmailPassword = useCallback(
    (email: string, password: string) => {
      setIsSending(true);
      return fetch(`${process.env.REACT_APP_DEV_FUNCTIONS_DOMAIN}/api/mails/config/credentials`, {
        method: "PUT",
        body: JSON.stringify({ user: email, password }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: Response) => {
          // Logout user if unauthorized
          if (response.status === 401) {
            logout();
            return false;
          }
          return true;
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setIsSending(false);
        });
    },
    [logout, token]
  );

  return {
    updateEmailPassword,
    isSending,
  };
};
