import React from "react";
import { Link } from "react-router-dom";
import { MarginInfo } from "../GlobalMarginsMigration";
import styled from "@emotion/styled";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors["sk-purple"]};
`;

export function TransactionLinkCell(arg: any) {
  const margin = arg.row.original as MarginInfo;
  const value = arg.value;

  return <StyledLink to={`/transactions/${margin.transaction.id}`}>{value}</StyledLink>;
}
