import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { TwoColumnsLayout } from "components/TwoColumnsLayout/TwoColumnsLayout";
import { useAuth } from "hooks/useAuth";
import { useProfiles } from "hooks/useProfiles";
import { useToast } from "hooks/useToast";
import { PasswordReset } from "./PasswordReset";
import _set from "lodash.set";
import { Button } from "components/Button/Button";

import styled from "@emotion/styled";
import { Profile } from "interfaces/data";
import _cloneDeep from "lodash.clonedeep";
import { Input } from "components/Input/Input";
import { LANGUAGES } from "helpers/options";
import { Select } from "components/Select/Select";
import { Box } from "@chakra-ui/react";

export const StyledContainer = styled.div<{ disabled?: boolean }>`
  background: white;
  padding: 30px;
  margin-bottom: 20px;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

export const StyledSectionName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: ${(props) => props.theme.colors["sk-dark"]};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export function Account() {
  const { t } = useTranslation();
  const { profile } = useAuth();
  const [editedProfile, setEditedProfile] = useState(profile);
  const { update, errors } = useProfiles();
  const toast = useToast();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setEditedProfile((oldEditedData: any) => {
      const oldData = _cloneDeep(oldEditedData);
      return _set(oldData, e.target.name, e.target.value);
    });
  }, []);

  const handleSave = useCallback(
    async (newProfile: Profile) => {
      if (!newProfile) return;
      try {
        const result = await update(newProfile);
        if (result) {
          toast({
            type: "success",
            message: t("settings:Your account has been updated successfully."),
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          toast({
            type: "error",
            message: t(`settings:${error.message}`),
          });
        }
      }
    },
    [t, toast, update]
  );

  if (!editedProfile) return null;

  return (
    <TwoColumnsLayout>
      <div>
        <StyledContainer>
          <StyledSectionName>{t("settings:Profile")}</StyledSectionName>
          <TwoColumnsLayout>
            <Input
              error={errors?.["name"]}
              id="name"
              label={t("settings:First name")}
              name="name"
              onChange={handleChange}
              type="text"
              value={editedProfile.name}
              withError={!!errors?.["name"]}
            />
            <Input
              error={errors?.["surname"]}
              id="surname"
              label={t("settings:Last name")}
              name="surname"
              onChange={handleChange}
              type="text"
              value={editedProfile.surname}
              withError={!!errors?.["surname"]}
            />
            <Input
              error={errors?.["email"]}
              id="email"
              label={t("settings:Email")}
              name="email"
              onChange={handleChange}
              type="email"
              value={editedProfile.email}
              withError={!!errors?.["email"]}
            />
            <Input id="role" isDisabled name="role" type="text" value={editedProfile.role} />
          </TwoColumnsLayout>
          <Box height="30px" />
          <StyledSectionName>{t("settings:Language")}</StyledSectionName>
          <Select
            error={errors?.["lang"]}
            id="lang"
            label={t("settings:Language")}
            name="lang"
            onChange={handleChange}
            options={LANGUAGES}
            value={editedProfile.lang}
          />
          <Button design="primary" mt="30px" onClick={() => handleSave(editedProfile)}>
            {t("Save")}
          </Button>
        </StyledContainer>
      </div>
      <div>
        <StyledContainer>
          <StyledSectionName>{t("settings:Password")}</StyledSectionName>
          <PasswordReset />
        </StyledContainer>
      </div>
    </TwoColumnsLayout>
  );
}
