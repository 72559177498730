import React, { PropsWithChildren } from "react";
import { StyledNavigationItemContainer, StyledNavigationText } from "./Navigation.styled";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";

type Props = PropsWithChildren & {
  isActive?: boolean;
  href: string;
  icon: React.ReactNode;
};

export const NavigationItem: React.FC<Props> = ({ isActive, children, icon, href }) => {
  return (
    <Link to={href}>
      <StyledNavigationItemContainer isActive={isActive}>
        {icon}
        <StyledNavigationText>{children}</StyledNavigationText>
        <ChevronRightIcon height="20px" marginRight="auto" width="20px" />
      </StyledNavigationItemContainer>
    </Link>
  );
};
