import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const EmptyFolderIcon = createIcon({
  displayName: "EmptyFolderIcon",
  path: (
    <g fill="none">
      <path
        d="M20.3762 9.60191C20.5436 9.76927 20.763 9.85305 20.9822 9.85305C21.2014 9.85305 21.4208 9.76927 21.5882 9.60191L22.1942 8.99591L22.8002 9.60191C22.9676 9.76927 23.187 9.85305 23.4062 9.85305C23.6254 9.85305 23.8448 9.76927 24.0122 9.60191C24.3469 9.2672 24.3469 8.72463 24.0122 8.38991L23.4062 7.78391L24.0122 7.17791C24.3469 6.8432 24.3469 6.30062 24.0122 5.96591C23.6775 5.6312 23.1349 5.6312 22.8002 5.96591L22.1942 6.57191L21.5882 5.96591C21.2535 5.6312 20.7109 5.6312 20.3762 5.96591C20.0415 6.30062 20.0415 6.8432 20.3762 7.17791L20.9822 7.78391L20.3762 8.38991C20.0413 8.72484 20.0413 9.2672 20.3762 9.60191Z"
        fill="currentColor"
      />
      <path
        d="M31.6228 12.0697C32.0962 12.0697 32.4799 11.6859 32.4799 11.2125C32.4799 10.7391 32.0962 10.3554 31.6228 10.3554C31.1494 10.3554 30.7656 10.7391 30.7656 11.2125C30.7656 11.6859 31.1494 12.0697 31.6228 12.0697Z"
        fill="currentColor"
      />
      <path
        d="M29.0512 2.64104C29.5246 2.64104 29.9084 2.25729 29.9084 1.7839C29.9084 1.31051 29.5246 0.926758 29.0512 0.926758C28.5778 0.926758 28.1941 1.31051 28.1941 1.7839C28.1941 2.25729 28.5778 2.64104 29.0512 2.64104Z"
        fill="currentColor"
      />
      <path
        d="M43.6228 14.641H19.1203L17.4525 11.1814C17.43 11.1344 17.4032 11.0897 17.3726 11.0479C16.6393 10.0437 15.4502 9.44458 14.1921 9.44458H4.64163C2.50413 9.44458 0.765625 11.1531 0.765625 13.2531V42.3649C0.765625 44.389 2.31362 46.0478 4.30434 46.3032C4.67313 47.8172 6.03705 48.9267 7.62277 48.9267H41.9085C43.5975 48.9267 44.9966 47.6965 45.2775 46.0866C46.8107 45.5155 47.9085 44.0687 47.9085 42.3651V18.9267C47.9085 16.5638 45.9857 14.641 43.6228 14.641ZM46.1942 42.3651C46.1942 43.6202 45.1412 44.641 43.8467 44.641H13.7856C13.3123 44.641 12.9285 45.0248 12.9285 45.4982C12.9285 45.9715 13.3123 46.3553 13.7856 46.3553H43.3845C43.0873 46.8653 42.5404 47.2124 41.9087 47.2124H7.62277C6.99877 47.2124 6.44184 46.8692 6.14184 46.3553H9.35891C9.83227 46.3553 10.2161 45.9715 10.2161 45.4982C10.2161 45.0248 9.83227 44.641 9.35891 44.641H4.82741C3.53291 44.641 2.47991 43.6202 2.47991 42.3651V13.2531C2.47991 12.0983 3.44955 11.1589 4.64163 11.1589H14.1919C14.8804 11.1589 15.5303 11.4728 15.9448 12.0023L17.217 14.641H12.7656C12.2923 14.641 11.9085 15.0248 11.9085 15.4982C11.9085 15.9715 12.2923 16.3553 12.7656 16.3553H43.6228C45.0407 16.3553 46.1942 17.5088 46.1942 18.9267V42.3651Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: "0 0 48 49",
});
