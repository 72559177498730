import React from "react";
import { Input } from "../../../components/Input/Input";
import { TwoColumnsLayout } from "../../../components/TwoColumnsLayout/TwoColumnsLayout";
import { useTranslation } from "react-i18next";
import { EditViewProps } from "../../../components/EditBox/EditBox";
import { BaseCompany } from "../../../interfaces/data";
import { Select } from "../../../components/Select/Select";
import { LEGAL_ENTITIES } from "../../../helpers/options";

export function EditCompanyInfo({ data, handleChange, errors }: EditViewProps<BaseCompany>) {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  return (
    <TwoColumnsLayout>
      <Input
        error={errors?.name}
        id="name"
        label={t("company:Name")}
        name="name"
        onChange={handleChange}
        type="text"
        value={data.name}
        withError={!!errors?.name}
      />
      <Select
        error={errors?.legalEntity}
        id="legalEntity"
        label={t("company:Legal entity")}
        name="legalEntity"
        onChange={handleChange}
        options={LEGAL_ENTITIES}
        value={data.legalEntity}
      />
      <Input
        error={errors?.nip}
        id="nip"
        label={t("company:NIP")}
        name="nip"
        onChange={handleChange}
        type="text"
        value={data.nip}
        withError={!!errors?.nip}
      />
      <Input
        error={errors?.regon}
        id="regon"
        label={t("company:REGON")}
        name="regon"
        onChange={handleChange}
        type="text"
        value={data.regon}
        withError={!!errors?.regon}
      />
      <Input
        error={errors?.krs}
        id="krs"
        label={t("company:KRS")}
        name="krs"
        onChange={handleChange}
        type="text"
        value={data.krs}
        withError={!!errors?.krs}
      />
      <Input
        error={errors?.phone}
        id="phone"
        label={t("company:Phone")}
        name="phone"
        onChange={handleChange}
        type="text"
        value={data.phone}
        withError={!!errors?.phone}
      />
    </TwoColumnsLayout>
  );
}
