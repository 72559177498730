import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { BoxProps, Popover, PopoverContent, PopoverTrigger as OrigPopoverTrigger } from "@chakra-ui/react";

export const PopoverTrigger: React.FC<PropsWithChildren> = OrigPopoverTrigger;

interface DropdownProps extends BoxProps {
  trigger: React.ReactNode;
  placement?: "left" | "right";
  isOpen?: boolean;
  onClose?: () => void;
  isDisabled?: boolean;
}

const StyledContent = styled(PopoverContent)`
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors["sk-light-gray"]};
  box-sizing: border-box;
  box-shadow: 10px 10px 34px rgba(112, 112, 112, 0.04);
  border-radius: 16px;
`;

const StyledTrigger = styled.div<{
  isDisabled?: boolean;
}>`
  opacity: ${(props) => (props.isDisabled ? "0.5" : "1")};
  ${(props) => props.isDisabled && "cursor: not-allowed;"}
`;

export const Dropdown = ({
  trigger,
  placement = "left",
  isOpen,
  onClose,
  zIndex = 4,
  children,
  width = "227px",
  padding = "20px",
  isDisabled = false,
}: DropdownProps) => {
  return (
    <Popover
      closeOnBlur
      closeOnEsc
      isOpen={isOpen}
      onClose={onClose}
      placement={placement === "right" ? "bottom-end" : "bottom-start"}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <StyledTrigger>{trigger}</StyledTrigger>
      </PopoverTrigger>
      {!isDisabled && (
        <StyledContent padding={padding} width={width} zIndex={zIndex}>
          {children}
        </StyledContent>
      )}
    </Popover>
  );
};
